import {Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {QuizMakerService} from '../../../../core/editor/quiz-maker.service';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {EditorQuestionsService} from '../../../../core/editor/editor-questions.service';
import {AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {EditorQuizCreatorService} from '../../../../core/editor/editor-quiz-creator.service';
import {DomSanitizer} from '@angular/platform-browser';
import {FileHandle} from '../../../../core/drag-and-drop/drag-and-drop.directive';
import {DragAndDropService} from '../../../../core/drag-and-drop/drag-and-drop.service';
import {FreeTextQuiz} from '../../../../core/editor/types/FreeTextQuiz/FreeTextQuiz';
import {FreeTextQuizEditor} from '../../../../core/editor/types/FreeTextQuiz/FreeTextQuizEditor';
import {EditorResource} from '../../../../core/rest/editor-resource.service';
import {Subject, Subscription, takeUntil} from 'rxjs';
import {QuizTranslation} from '../../../../core/api-types/quizTranslation';
import {FreeTextQuestionValidatorService} from '../../../validation/free-text-question-validator.service';
import {QuizExplanationForm} from '../../../../core/editor/types/QuizExplanationForm';
import {QuizQuestionForm} from '../../../../core/editor/types/QuizQuestionForm';
import {QuizMediaEditor} from '../../../../core/editor/types/QuizMediaEditor';
import {QuestionType} from '../../../../core/editor/question.type';
import {QuizTypeName} from '../../../../core/editor/types/QuizType';
import {ToastrService} from 'ngx-toastr';
import {filter, take} from 'rxjs/operators';
import {Quiz} from '../../../../core/api-types/quiz';
import {TranslationService} from '../../../../core/rest/translation.service';
import {FeaturesService} from '../../../../core/features/features.service';
import {PasteTextPopupComponent} from '../../../paste-text-popup/paste-text-popup.component';
import {PopupService} from '../../../../components/popup/popup.service';
import {Tag} from '../../../../core/api-types/Tag';
import {SelectedTag} from '../../../../core/api-types/SelectedTag';
import {SelectedNewTag} from '../../../../core/api-types/SelectedNewTag';
import {FreeTextQuizFormGroup} from './FreeTextQuizFormGroup';

@Component({
    selector: 'app-free-text-quiz',
    templateUrl: './free-text-quiz.component.html',
    styleUrls: ['./free-text-quiz.component.scss']
})
export class FreeTextQuizComponent implements OnInit, OnDestroy {
    @Input() queryParams: { filterText: string; sort: string; filterLanguage?: string; };

    public quiz: FreeTextQuiz;
    public quizQuestionMedia: QuizMediaEditor;
    public quizExplanationMedia: QuizMediaEditor;
    public quizTypeData: QuestionType;
    public selectedLearningPackageId: string;
    public questionFileDrop: boolean = false;
    public explanationFileDrop: boolean = false;
    public questionHovering = false;
    public explanationHovering = false;
    public languages: string[];
    public activeQuizSubscription = new Subscription();
    public activeTranslationQuiz: QuizTranslation;
    public formDataChangedSubscription = new Subscription();
    public quizQuestionMediaEditorChangedSubscription = new Subscription();
    public quizExplanationMediaEditorChangedSubscription = new Subscription();
    public learningPackageAvailableTagsSubscription = new Subscription();

    public availableTags: Tag[];
    public selectedAvailableTags: SelectedTag[] = [];
    public selectedNewTags: SelectedNewTag[] = [];

    public alreadyTranslated: boolean = false;
    public advancedPanelOpen: boolean = false;

    @ViewChildren('answersForm') answersForm: QueryList<ElementRef>;

    constructor(public quizMakerService: QuizMakerService,
                public editorQuizService: EditorQuestionService,
                private sanitizer: DomSanitizer,
                public dragAndDropService: DragAndDropService,
                public editorLearningPackageService: EditorLearningPackageService,
                public editorQuizzesService: EditorQuestionsService,
                private popupService: PopupService,
                public featuresService: FeaturesService,
                private router: Router,
                private editorQuizCreatorService: EditorQuizCreatorService,
                private editorService: EditorResource,
                private translationService: TranslationService,
                public validator: FreeTextQuestionValidatorService,
                private activatedRoute: ActivatedRoute,
                private toastr: ToastrService) {
    }

    public formData: FormGroup<FreeTextQuizFormGroup> = this.createFormGroup();

    public newAnswer = new FormControl<string>('');
    public disableNewAnswerInput = false;

    public isDisabled: boolean = false;

    private createFormGroup(): FormGroup<FreeTextQuizFormGroup> {
        return new FormGroup<FreeTextQuizFormGroup>({
            question: new FormControl<string>('', this.validator.questionFormValidation),
            explanation: new FormControl<string>('', this.validator.explanationFormValidation),
            answer: new FormControl<string>('', this.validator.answerTextFormValidation),
            answers: new FormArray<FormControl<string>>([]),
            sourceUrl: new FormControl<string>('', this.validator.sourceUrl),
            sourceLabel: new FormControl<string>('', this.validator.sourceLabel)
        }, {validators: this.validator.sourceUrlRequired});
    }

    private enable(): void {
        this.formData.enable();
        this.isDisabled = false;
    }

    private disable(): void {
        this.formData.disable();
        this.isDisabled = true;
    }

    public translate: () => Promise<void> = async () => {

        return new Promise(resolve => {
            this.disable();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            this.editorQuizService.activeQuestionMainLanguage$.pipe(take(1)).subscribe(async (questionTranslation: QuizTranslation) => {

                this.translationService.translateQuestion(questionTranslation, this.activeTranslationQuiz.language)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((translatedQuiz: Quiz | undefined) => {
                        if (translatedQuiz !== undefined) {
                            this.formData.get('question').setValue(translatedQuiz.quiz.text);
                            this.formData.get('explanation').setValue(translatedQuiz.explanation?.text);
                            this.formData.get('answer').setValue(translatedQuiz.answer);
                            this.formData.get('sourceUrl').setValue(translatedQuiz.sourceUrl);
                            this.formData.get('sourceLabel').setValue(translatedQuiz.sourceLabel);

                            this.setQuizAnswersToForm(translatedQuiz);

                            this.quizQuestionMedia.mediaUrl = questionTranslation.quiz.mediaUrl;
                            this.quizQuestionMedia.imageUrl = questionTranslation.quiz.imageUrl;
                            this.quizQuestionMedia.type = questionTranslation.quiz.mediaType;

                            this.quizExplanationMedia.mediaUrl = questionTranslation.explanation?.mediaUrl;
                            this.quizExplanationMedia.imageUrl = questionTranslation.explanation?.imageUrl;
                            this.quizExplanationMedia.type = questionTranslation.explanation?.mediaType;

                            this.alreadyTranslated = true;
                        }

                        this.enable();
                        resolve();
                    });
            });
        });
    };

    public ngOnInit(): void {
        this.selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        if (this.selectedLearningPackageId !== undefined) {
            this.editorService.getLearningPackageLanguages(this.selectedLearningPackageId).subscribe((languages: string[]) => this.languages = languages);
        }
        this.activeQuizSubscription = this.editorQuizService.activeTranslationQuiz$.pipe(filter(quiz => quiz !== undefined)).subscribe((quiz: QuizTranslation) => {
            this.availableTags = undefined;
            this.selectedNewTags = [];
            this.learningPackageAvailableTagsSubscription = this.editorLearningPackageService.learningPackageAvailableTags$.subscribe((availableTags: Tag[]) => this.availableTags = availableTags);
            if (quiz !== undefined && quiz.quizType === QuizTypeName.FREE_TEXT_TYPE) {
                this.activeTranslationQuiz = quiz;
                this.quiz = new FreeTextQuiz(quiz.id, quiz.quizId, quiz.quiz, quiz.answer, quiz.answers as string[], quiz?.explanation, quiz.sourceUrl, quiz.sourceLabel, quiz.originalContentHash);
                this.quizQuestionMedia = new QuizMediaEditor('question', this.quiz.quiz?.mediaType, this.quiz.quiz?.mediaUrl, this.quiz.quiz?.imageUrl);
                this.quizExplanationMedia = new QuizMediaEditor('explanation', this.quiz.explanation?.mediaType, this.quiz.explanation?.mediaUrl, this.quiz.explanation?.imageUrl);
                this.disableNewAnswerInput = quiz.answers.length === 20;
                this.selectedAvailableTags = quiz.tags !== undefined && quiz.tags.length > 0 ? quiz.tags.map(tag => new SelectedTag(tag)) : [];
            }
            this.quizTypeData = this.editorQuizService.quizTypes.find(quizType => quizType.type === this.quiz.quizType);
            this.clearQuizForm();
            this.cleanFiles();
            this.setQuizDataToForm();

            this.formData.valueChanges.subscribe(() => {
                this.editorQuizzesService.setQuestionDataChanged(true);
            });
        });

        this.newAnswer.valueChanges.subscribe((data: string) => {
            this.createNewAnswer(data);
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.formDataChangedSubscription = this.editorQuizzesService.formDataChanged.subscribe(async (status: boolean) => {
            if (status) {
                await this.saveCurrentQuizState(this.formData);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizQuestionMediaEditorChangedSubscription = this.editorQuizzesService.quizQuestionMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizQuestionMedia = this.editorQuizzesService.getQuestionQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizQuestionMediaEditorChanged.next(false);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizExplanationMediaEditorChangedSubscription = this.editorQuizzesService.quizExplanationMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizExplanationMedia = this.editorQuizzesService.getExplanationQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizExplanationMediaEditorChanged.next(false);
            }
        });
    }

    private destroy$ = new Subject();

    public ngOnDestroy(): void {
        this.activeQuizSubscription.unsubscribe();
        this.formDataChangedSubscription.unsubscribe();
        this.quizQuestionMediaEditorChangedSubscription.unsubscribe();
        this.quizExplanationMediaEditorChangedSubscription.unsubscribe();
        this.learningPackageAvailableTagsSubscription.unsubscribe();

        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public async changeLanguage(language: string): Promise<void> {
        if (this.editorQuizzesService.quizSaved.value) {
            this.alreadyTranslated = false;
            void this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                        language: language
                    },
                    queryParamsHandling: 'merge'
                });
        } else {
            const url = `/editor/lernpakete/${this.selectedLearningPackageId}/fragen/${this.quiz.quizId}?language=${language}`;
            await this.editorQuizzesService.openCancelQuestion(url);
        }
    }

    public async filesDropped(files: FileHandle[], type: 'question' | 'explanation'): Promise<void> {
        const file = files[0];
        const fileAllowed = this.dragAndDropService.isFileAllowed(file.file);
        if (!fileAllowed.error) {
            if (type === 'question') {
                this.quizQuestionMedia.imageFile = file;
                this.questionFileDrop = true;
                if (this.quizQuestionMedia.type === undefined) {
                    this.quizQuestionMedia.type = 'image';
                }
            }

            if (type === 'explanation') {
                this.quizExplanationMedia.imageFile = file;
                this.explanationFileDrop = true;
                if (this.quizExplanationMedia.type === undefined) {
                    this.quizExplanationMedia.type = 'image';
                }
            }
            this.editorQuizzesService.setQuestionDataChanged(true);
        } else {
            for (const errorText of fileAllowed.text) {
                this.toastr.error(errorText);
            }
        }
    }

    public async removeMedia(type: 'question' | 'explanation'): Promise<void> {
        if (type === 'question') {
            this.quizQuestionMedia = new QuizMediaEditor('question', undefined);
            this.questionFileDrop = false;
        } else {
            this.quizExplanationMedia = new QuizMediaEditor('explanation', undefined);
            this.explanationFileDrop = false;
        }

        this.editorQuizzesService.setQuestionDataChanged(true);
    }

    public keytab(event: KeyboardEvent): void {
        event.preventDefault();
    }

    public getAnswersControls(): AbstractControl[] {
        const answers = this.formData.get('answers') as UntypedFormArray;
        return answers.controls;
    }

    public checkDuplicatedAnswer(answerText: string, alternativeAnswer?: boolean): boolean {
        const answersFormData = this.formData.get('answers') as UntypedFormArray;
        const answers = answersFormData.controls.map((control: AbstractControl) => control.value as string);
        if (alternativeAnswer) {
            return answerText.trim().length > 0 && (answers.filter(answer => answer.trim() === answerText.trim()).length > 1
                || this.formData.controls.answer.value.trim() === answerText.trim());
        }
        return answerText.trim().length > 0 && answers.filter(answer => answer.trim() === answerText.trim()).length >= 1;
    }

    public removeAnswer(answerIndex: number): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        control.removeAt(answerIndex);
        this.disableNewAnswerInput = false;
    }

    public cancelQuiz(): void {
        if (this.editorQuizzesService.formDataChanged.value) {
            this.editorQuizzesService.quizSaved.next(true);
            this.editorQuizzesService.setQuestionDataChanged(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${this.selectedLearningPackageId}/fragen`], {queryParams: this.queryParams});
        }
    }

    public async saveQuiz(data: FormGroup<FreeTextQuizFormGroup>): Promise<void> {
        if (this.validator.validateFullQuiz(this.formData)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuiz(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);

            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    private buildChangedQuiz(data: FormGroup<FreeTextQuizFormGroup>): FreeTextQuizEditor {
        const answers = data.get('answers')?.value as string[];
        const answer = data.get('answer').value;

        const value = this.editorQuizzesService.quizQuestionMediaEditorChanged.value;
        const questionMedia = value ? this.editorQuizzesService.getQuestionQuizMediaEditor() : this.quizQuestionMedia;
        const quizQuestionForm = new QuizQuestionForm(data.get('question').value, questionMedia);

        const explanationMedia = this.editorQuizzesService.quizExplanationMediaEditorChanged.value ? this.editorQuizzesService.getExplanationQuizMediaEditor() : this.quizExplanationMedia;
        const quizExplanationForm = new QuizExplanationForm(data.get('explanation').value, explanationMedia);

        const sourceUrl = data.controls.sourceUrl.value;
        const sourceLabel = data.controls.sourceLabel.value;

        return new FreeTextQuizEditor(answers, answer, quizExplanationForm, quizQuestionForm, this.selectedAvailableTags, this.selectedNewTags, sourceUrl, sourceLabel);
    }

    public translationNotExist(quizzes: QuizTranslation[]): boolean {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return !quizzes.find(quiz => quiz.language === this.activeTranslationQuiz.language);
    }

    public async saveAndCreateNewQuiz(data: FormGroup<FreeTextQuizFormGroup>): Promise<void> {
        if (this.validator.validateFullQuiz(this.formData)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuizAndAddNew(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);

            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    private clearQuizForm(): void {
        this.formData = this.createFormGroup();
    }

    private setQuizDataToForm(): void {
        this.formData.controls['question'].setValue(this.quiz.quiz.text);
        this.formData.controls['answer'].setValue(this.quiz.answer);
        if (this.quiz?.explanation?.text !== undefined) {
            this.formData.controls['explanation'].setValue(this.quiz.explanation.text);
        }
        this.setQuizAnswersToForm(this.quiz);
        this.formData.get('sourceUrl').setValue(this.quiz.sourceUrl);
        this.formData.get('sourceLabel').setValue(this.quiz.sourceLabel);
    }

    private setQuizAnswersToForm(question: Quiz): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        control.clear();
        if (question.answers !== undefined) {
            for (const answer of question.answers) {
                control.push(new UntypedFormControl(answer, this.validator.answerTextFormValidation));
            }
        }
    }

    private async saveCurrentQuizState(data: FormGroup<FreeTextQuizFormGroup>): Promise<void> {
        const quizEditor = this.buildChangedQuiz(data);
        const quizData = await this.editorQuizCreatorService.createQuizData(quizEditor, this.quiz, this.quizQuestionMedia, this.quizExplanationMedia);
        this.editorQuizzesService.setEditingQuizFormData(quizData, this.validator.validateFullQuiz(this.formData));
        if (this.editorQuizzesService.quizSaved.value) {
            this.editorQuizzesService.quizSaved.next(false);
        }
    }


    private createNewAnswer(data: string): void {
        if (data !== undefined && data.length > 0) {
            const control = this.formData.get('answers') as UntypedFormArray;
            const controlLength = control.controls.length;

            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (control && controlLength < 20) {
                control.push(new FormControl<string>(data, this.validator.answerTextFormValidation));

                setTimeout(() => {
                    document.getElementById(`Alternative_Answar_${(controlLength).toString()}`).focus();
                });

                this.newAnswer.setValue('');
                if (controlLength >= 19) {
                    this.disableNewAnswerInput = true;
                }
            } else {
                this.disableNewAnswerInput = true;
            }
        }
    }

    private cleanFiles(): void {
        this.questionFileDrop = undefined;
        this.explanationFileDrop = undefined;
    }

    public async openImageAndMediaPopup(quizMediaElement: 'question' | 'explanation'): Promise<void> {
        if (quizMediaElement === 'question') {
            this.editorQuizzesService.setQuestionQuizMediaEditor(this.quizQuestionMedia);
        } else {
            this.editorQuizzesService.setExplanationQuizMediaEditor(this.quizExplanationMedia);
        }
        this.editorQuizzesService.openImageAndMediaPopup(quizMediaElement);
    }

    public startPasteFlow(): void {
        void this.popupService.open(PasteTextPopupComponent, this.quizTypeData);
    }
}
