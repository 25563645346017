import {Component, Input} from '@angular/core';
import {Quiz} from '../../../../../../../../core/api-types/quiz';

@Component({
    selector: 'app-true-false-question',
    templateUrl: './true-false-question.component.html',
    styleUrls: ['./true-false-question.component.scss']
})
export class TrueFalseQuestionComponent {
    @Input()
    public questionPreview: Quiz;
}
