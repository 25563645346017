import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {OrganizationSettingsComponent} from '../app-organisation/organization-settings/organization-settings.component';
import {OrganizationMangerService} from '../app-organisation/organization-manager/organization-manger.service';

@Injectable()
export class OrganizationAdminManagersGuard implements CanDeactivate<OrganizationSettingsComponent> {
    public component: Object;
    public route: ActivatedRouteSnapshot;

    constructor(private organizationMangerService: OrganizationMangerService) {
    }

    public async canDeactivate(component: OrganizationSettingsComponent,
                               route: ActivatedRouteSnapshot,
                               state: RouterStateSnapshot,
                               nextState: RouterStateSnapshot): Promise<boolean> {
        return await this.organizationMangerService.checkIfOrganizationSettingIsSaved(nextState.url);
    }

}
