import {Component, Input, SimpleChanges} from '@angular/core';
import {ALL_PAGE_AMOUNT, ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {Router} from '@angular/router';

@Component({
    selector: 'app-table-pagination',
    templateUrl: './table-pagination.component.html',
    styleUrls: ['./table-pagination.component.scss']
})
export class TablePaginationComponent {
    @Input() paginationParams: ListPaginationRequest;
    @Input() totalDataAmount: number;
    @Input() navigateUrl: string[];

    public numberOfPages: number = 1;
    public pageAmountOptions: { id: string; name: string; }[] = [
        {
            id: '25',
            name: '25'
        },
        {
            id: '50',
            name: '50'
        },
        {
            id: '100',
            name: '100'
        },
        {
            id: ALL_PAGE_AMOUNT,
            name: 'Alle'
        }];

    constructor(private router: Router) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (changes['totalDataAmount'] || changes['paginationParams']) {
            if (this.paginationParams.pageAmount === ALL_PAGE_AMOUNT) {
                this.numberOfPages = 1;
            } else {
                const numberOfPagesValue = Math.ceil(this.totalDataAmount / parseInt(this.paginationParams.pageAmount));
                this.numberOfPages = numberOfPagesValue === 0 ? 1 : numberOfPagesValue;
            }
            if (this.paginationParams.pageNumber > this.numberOfPages || this.paginationParams.pageNumber === 0) {
                this.redirectWithPaginationParameter(1, this.paginationParams.pageAmount);
            }
        }
    }

    public redirectWithPaginationParameter(pageNumber: number, pageAmount: string): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(this.navigateUrl, {
            queryParams: {
                pageNumber: pageNumber,
                pageAmount: pageAmount
            },
            queryParamsHandling: 'merge'
        });
    }

}
