import {Injectable} from '@angular/core';
import {map, switchMap, take} from 'rxjs/operators';
import {firstValueFrom, Observable} from 'rxjs';
import {UserRoles} from '../api-types/roles';
import {OrganizationService} from '../organization.service';
import {AuthUntilUserRoleService} from './auth-until-user-role.service';

// TODO CLEANUP TASK: Can we remove/cleanup this service? Here we have a Promise/Observable mixture
@Injectable({
    providedIn: 'root'
})
export class AuthUntilUserOrganizationRoleService {
    private userRolesForOrganization: { organizationId: string; roles: string[]; };

    constructor(private authUntilUserRoleService: AuthUntilUserRoleService,
                private organizationService: OrganizationService) {
    }

    public async hasRoles(allowRoles: string[], roles: string[], organization: { [organization: string]: string[]; }): Promise<boolean> {
        if (allowRoles !== undefined && allowRoles.length > 0) {
            if (roles !== undefined && roles.length > 0) {
                if (roles.includes(UserRoles.SUPER_ADMINISTRATOR)) {
                    roles = [UserRoles.SUPER_ADMINISTRATOR, UserRoles.SUPERVISOR, UserRoles.CONTENT_CREATOR, UserRoles.ORGANIZATION_ADMIN];
                }
                return roles.some(role => allowRoles.includes(role));
            }
            const activeOrganizationId: string = await firstValueFrom(this.organizationService.activeOrganizationId$);
            const userOrganizationRoles = organization[activeOrganizationId];
            return userOrganizationRoles === undefined ? false : userOrganizationRoles.some(role => allowRoles.includes(role));
        }
        return true;
    }

    public hasSupervisorRole(): Observable<boolean> {
        const userRole = UserRoles.SUPERVISOR;
        return this.checkUserRoles(userRole);
    }

    public hasContentCreatorRole(): Observable<boolean> {
        const userRole = UserRoles.CONTENT_CREATOR;
        return this.checkUserRoles(userRole);
    }

    public hasOrganisationAdminRole(): Observable<boolean> {
        const userRole = UserRoles.ORGANIZATION_ADMIN;
        return this.checkUserRoles(userRole);
    }


    private checkUserRoles(searchElement: UserRoles): Observable<boolean> {
        return this.authUntilUserRoleService.userOrganizationRoles$.pipe(
            take(1),
            switchMap((data: { roles: string []; organization: { [organization: string]: string[]; }; }) => {
                const roles$ = this.getOrganizationRoles(data);
                return roles$.pipe(map (roles => roles.includes(searchElement)));
            })
        );
    }

    private getOrganizationRoles(userOrganizationRoles: { roles: string []; organization: { [organization: string]: string[]; }; }): Observable<string[]> {
        return this.organizationService.activeOrganizationId$.pipe(map(activeOrganizationId => {
            let roles: string[] = [];

            if (this.userRolesForOrganization?.organizationId === activeOrganizationId) {
                return this.userRolesForOrganization.roles;
            }

            if (activeOrganizationId !== undefined && userOrganizationRoles !== undefined) {
                const activeOrganizationRoles = userOrganizationRoles.organization[activeOrganizationId];
                if (userOrganizationRoles.roles.includes(UserRoles.SUPER_ADMINISTRATOR)) {
                    roles = [UserRoles.SUPER_ADMINISTRATOR, UserRoles.SUPERVISOR, UserRoles.CONTENT_CREATOR, UserRoles.ORGANIZATION_ADMIN];
                } else {
                    if (activeOrganizationRoles !== undefined) {
                        if (activeOrganizationRoles.includes(UserRoles.SUPERVISOR)) {
                            roles.push(UserRoles.SUPERVISOR);
                        }
                        if (activeOrganizationRoles.includes(UserRoles.CONTENT_CREATOR)) {
                            roles.push(UserRoles.CONTENT_CREATOR);
                        }
                        if (activeOrganizationRoles.includes(UserRoles.ORGANIZATION_ADMIN)) {
                            roles.push(UserRoles.ORGANIZATION_ADMIN);
                        }
                    }
                }
                this.userRolesForOrganization = {organizationId: activeOrganizationId, roles: roles};
            }
            return roles;
        }));
    }
}
