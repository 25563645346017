<div class="micromate-card">
    <h1 i18n>Lerngruppen</h1>

    <div class="micromate-table" *ngIf="groups !== undefined">
        <div class="table-container table-container-header">
            <ng-container>
                <div class="row-header" i18n>Lerngruppen</div>
                <div class="row-header">
                    <div i18n>Engagement</div>
                    <p class="micromate-text-tooltip" i18n>Durchschnittlich beantwortete Fragen pro Lernender pro Tag /
                        Vergleich zur
                        Vorwoche</p>
                </div>
                <div class="row-header">
                    <div i18n>Lernstand</div>
                    <p class="micromate-text-tooltip" i18n>Erfolgsrate der beantwortete Fragen / Vergleich zur
                        Vorwoche</p>
                </div>
                <div class="row-header" i18n>Lernende</div>
            </ng-container>
        </div>
        <div class="table-container-data">
            <div class="table-container table-content" *ngFor="let group of groups">
                <div class="row row-name">
                    <div class="row-sub row-title" (click)="openGroupOverview(group.groupId)">
                        {{group.groupName}}
                    </div>
                    <div
                        class="row-only-mobile-email {{group.isEngagementIncreasePositiv ? 'green-kpi-color' : 'red-kpi-color'}}">
                        {{group.currentEngagement}} /
                        <app-analytics-data
                            [data]="getValue(group.engagementIncrease, group.isEngagementIncreasePositiv)"></app-analytics-data>
                    </div>
                </div>
                <div class="row row-only-web">
                    {{group.currentEngagement}} /
                    <app-analytics-data
                        [data]="getValue(group.engagementIncrease, group.isEngagementIncreasePositiv)"></app-analytics-data>
                </div>
                <div
                    class="row row-only-web">
                    {{group.currentSuccessRatio !== undefined ? group.currentSuccessRatio + '%' : '-' }} /
                    <app-analytics-data
                        [data]="getValue(group.successRatioIncrease, group.isSuccessRatioIncreasePositiv)"></app-analytics-data>
                </div>
                <div class="row group-info">
                    <div class="micromate-group-button"
                         *ngIf="(featuresService.hasFeaturePersonalInsights() | async)"
                         [routerLink]="[group.groupId, 'lernziele']">
                        <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#user-friends-light"></use>
                        </svg>
                        {{group?.numberOfLearner ? group.numberOfLearner : 0}}
                    </div>
                    <div class="micromate-group-info" *ngIf="!(featuresService.hasFeaturePersonalInsights() | async)">
                        <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#user-friends-light"></use>
                        </svg>
                        {{group?.numberOfLearner ? group.numberOfLearner : 0}}
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
