import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {EditorResource} from '../../../core/rest/editor-resource.service';
import {MultilanguageService} from '../../../core/editor/multilanguage.service';
import {Popup} from '../../../components/popup/Popup';
import {NewLearningPackagePopupData} from './NewLearningPackagePopupData';
import {NewLearningPackageFormData} from './NewLearningPackageFormData';
import {PopupService} from '../../../components/popup/popup.service';
import {NewLeaningPackage} from './NewLeaningPackage';
import {FormCustomValidation} from '../../../core/form-custom-validation';

@Component({
    selector: 'app-new-learning-package-popup',
    templateUrl: './new-learning-package-popup.component.html',
    styleUrls: ['./new-learning-package-popup.component.scss']
})
export class NewLearningPackagePopupComponent implements Popup<NewLearningPackagePopupData>, OnInit {
    @Input()
    public data: NewLearningPackagePopupData;

    public newPackageMode: boolean = true;
    public availableLanguageList: { key: string; name: string; }[];
    public saveButtonClick = false;
    public cancelButtonClick = false;
    public nameInvalid = false;
    public formData = new FormGroup<NewLearningPackageFormData>({
        name: new FormControl<string>('', [FormCustomValidation.trimmedRequired, FormCustomValidation.trimmedMaxLengthValidator(30)]),
        introduction: new FormControl<string>('', [FormCustomValidation.trimmedMaxLengthValidator(500)]),
        language: new FormControl<string>('', [Validators.required])
    });

    constructor(private editorService: EditorResource,
                private router: Router,
                public multilanguageService: MultilanguageService,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.availableLanguageList = this.multilanguageService.getAvailableLanguagesForLearningPackage().map(language => {
            return {key: `${language.language}-${language.local}`, name: language.name};
        });
        if (this.data.learningPackageId !== undefined && this.data.learningPackageId.length > 0) {
            this.newPackageMode = false;
            this.editorService.getOneLearningPackage(this.data.learningPackageId).subscribe(learningPackages => {
                const existingLanguage = learningPackages.map(learningPackage => learningPackage.language);
                this.availableLanguageList = this.availableLanguageList.filter(language => !existingLanguage.includes(language.key));
                this.formData.controls['language'].setValue(this.availableLanguageList.map(language => language.key).includes('de-CH') ? 'de-CH' : this.availableLanguageList[0].key);
            });
        } else {
            this.formData.controls['language'].setValue('de-CH');
            this.newPackageMode = true;
        }
        this.formData.controls['name'].valueChanges.subscribe(() => {
            this.cancelButtonClick = false;
            this.saveButtonClick = false;
            this.nameInvalid = false;
        });
    }

    public clickNewLearningPackage: () => Promise<void> = async () => {
        this.nameInvalid = false;
        return new Promise(resolve => {
            const learningPackage: NewLeaningPackage = {
                name: this.formData.controls.name.getRawValue().trim(),
                introduction: this.formData.controls.introduction.getRawValue().trim(),
                language: this.formData.controls.language.getRawValue(),
            };
            if (this.newPackageMode) {
                this.createNewLearningPackage(learningPackage, resolve);
            } else {
                this.createNewLearningPackageTranslation(learningPackage, resolve);
            }
        });
    };

    private createNewLearningPackage(learningPackage: NewLeaningPackage, resolve: () => void): void {
        this.editorService.createNewLearningPackage(learningPackage).subscribe((newLearningPackageId: string) => {
            this.popupService.close();
            this.formData.controls['name'].setValue('');
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${newLearningPackageId}/einstellungen`]);
            resolve();
        }, () => {
            this.nameInvalid = true;
            resolve();
        });
    }

    private createNewLearningPackageTranslation(learningPackage: NewLeaningPackage, resolve: () => void): void {
        this.editorService.createNewLearningPackageTranslation(learningPackage, this.data.learningPackageId).subscribe(() => {
            this.popupService.close();
            this.formData.controls['name'].setValue('');
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${this.data.learningPackageId}/einstellungen`], {
                queryParams: {
                    language: learningPackage.language
                }
            });
            resolve();
        }, () => {
            this.nameInvalid = true;
            resolve();
        });
    }

    public cancel(): void {
        this.popupService.close();
    }
}
