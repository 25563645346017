import {Component, Input} from '@angular/core';
import {Popup} from '../popup/Popup';
import {ConfirmationPopupData} from './ConfirmationPopupData';
import {PopupService} from '../popup/popup.service';
import {ConfirmationPopupResult} from './ConfirmationPopupResult';

@Component({
    selector: 'app-confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent implements Popup<ConfirmationPopupData> {
    @Input()
    public data: ConfirmationPopupData;

    constructor(private popupService: PopupService) {
    }

    public cancel(): void {
        this.popupService.close<ConfirmationPopupResult>('Cancel');
    }

    public confirm: () => Promise<void> = async () => {
        if (this.data.confirmationAction !== undefined) {
            await this.data.confirmationAction();
        }

        this.popupService.close<ConfirmationPopupResult>('Ok');
    };
}
