import {Injectable} from '@angular/core';
import {LearnerChannels} from '../api-types/learnerChannels';
import {LearnerChannel} from '../api-types/learnerChannel';

@Injectable({
    providedIn: 'root'
})
export class MultilanguageService {

    public languagesList: { language: string; local: string; name: string; }[] = [
        {language: 'af', local: 'ZA', name: 'Afrikaans (Südafrika)'},
        {language: 'sq', local: 'AL', name: 'Albanisch'},
        {language: 'ar', local: 'SA', name: 'Arabisch'},
        {language: 'hy', local: 'AM', name: 'Armenisch'},
        {language: 'az', local: 'Latn-AZ', name: 'Aserbaidschan'},
        {language: 'as', local: 'IN', name: 'Assamesisch'},
        {language: 'eu', local: 'ES', name: 'Baskisch'},
        {language: 'bn', local: 'IN', name: 'Bengali (Indien)'},
        {language: 'be', local: 'BY', name: 'Belarussisch'},
        {language: 'my', local: 'MM', name: 'Birmanisch'},
        {language: 'bg', local: 'BG', name: 'Bulgarisch'},
        {language: 'da', local: 'DK', name: 'Dänisch'},
        {language: 'de', local: 'DE', name: 'Deutsch (Deutschland)'},
        {language: 'de', local: 'LI', name: 'Deutsch (Liechtenstein)'},
        {language: 'de', local: 'LU', name: 'Deutsch (Luxemburg)'},
        {language: 'de', local: 'CH', name: 'Deutsch (Schweiz)'},
        {language: 'de', local: 'AT', name: 'Deutsch (Österreich)'},
        {language: 'en', local: 'US', name: 'Englisch (USA)'},
        {language: 'en', local: 'AU', name: 'Englisch (Australien)'},
        {language: 'en', local: 'BZ', name: 'Englisch (Belize)'},
        {language: 'en', local: 'CA', name: 'Englisch (Kanada)'},
        {language: 'en', local: 'IN', name: 'Englisch (Indien)'},
        {language: 'en', local: 'IE', name: 'Englisch (Irland)'},
        {language: 'en', local: 'JM', name: 'Englisch (Jamaika)'},
        {language: 'en', local: 'MY', name: 'Englisch (Malaysia)'},
        {language: 'en', local: 'NZ', name: 'Englisch (Neuseeland)'},
        {language: 'en', local: 'PH', name: 'Englisch (Philippinen)'},
        {language: 'en', local: 'SG', name: 'Englisch (Singapur)'},
        {language: 'en', local: 'ZA', name: 'Englisch (Südafrika)'},
        {language: 'en', local: 'TT', name: 'Englisch (Trinidad)'},
        {language: 'en', local: 'GB', name: 'Englisch (Vereinigtes Königreich)'},
        {language: 'en', local: 'ZW', name: 'Englisch (Zimbabwe)'},
        {language: 'et', local: 'EE', name: 'Estnisch'},
        {language: 'fo', local: 'FO', name: 'Faroese'},
        {language: 'fi', local: 'FI', name: 'Finnish'},
        {language: 'fr', local: 'FR', name: 'Französisch (Frankreich)'},
        {language: 'fr', local: 'BE', name: 'Französisch (Belgien)'},
        {language: 'fr', local: 'CA', name: 'Französisch (Kanada)'},
        {language: 'fr', local: 'LU', name: 'Französisch (Luxemburg)'},
        {language: 'fr', local: 'MC', name: 'Französisch (Monaco)'},
        {language: 'fr', local: 'CH', name: 'Französisch (Schweiz)'},
        {language: 'gl', local: 'ES', name: 'Galizisch'},
        {language: 'gd', local: 'GB', name: 'Gälisch (Schottland)'},
        {language: 'ka', local: 'GE', name: 'Georgisch'},
        {language: 'el', local: 'GR', name: 'Griechisch'},
        {language: 'gu', local: 'IN', name: 'Gujarati'},
        {language: 'he', local: 'IL', name: 'Hebräisch'},
        {language: 'hi', local: 'IN', name: 'Hindi'},
        {language: 'is', local: 'IS', name: 'Icelandic'},
        {language: 'id', local: 'ID', name: 'Indonesisch'},
        {language: 'it', local: 'IT', name: 'Italienisch (Italien)'},
        {language: 'it', local: 'CH', name: 'Italienisch (Schweiz)'},
        {language: 'lv', local: 'LV', name: 'Lettisch'},
        {language: 'lt', local: 'LT', name: 'Litauisch'},
        {language: 'ja', local: 'JP', name: 'Japanisch'},
        {language: 'kn', local: 'IN', name: 'Kannada'},
        {language: 'zh', local: 'TW', name: 'Kantonesisch (Taiwan)'},
        {language: 'kk', local: 'KZ', name: 'Kasachisch'},
        {language: 'ca', local: 'ES', name: 'Katalanisch'},
        {language: 'km', local: 'KH', name: 'Khmer'},
        {language: 'ky', local: 'KG', name: 'Kirgisistan'},
        {language: 'ko', local: 'KR', name: 'Koreanisch'},
        {language: 'hr', local: 'HR', name: 'Kroatin'},
        {language: 'lo', local: 'LA', name: 'Laos'},
        {language: 'ml', local: 'IN', name: 'Malayalam'},
        {language: 'mt', local: 'MT', name: 'Maltesisch'},
        {language: 'mr', local: 'IN', name: 'Marathi'},
        {language: 'ms', local: 'MY', name: 'Mlaiisch'},
        {language: 'mn', local: 'MN', name: 'Mongolisch'},
        {language: 'mk', local: 'MK', name: 'Mazedonisch'},
        {language: 'nl', local: 'NL', name: 'Niederländisch (Niederlande)'},
        {language: 'nl', local: 'BE', name: 'Niederländisch (Belgien)'},
        {language: 'nb', local: 'NO', name: 'Norwegisch'},
        {language: 'or', local: 'IN', name: 'Oriya'},
        {language: 'fa', local: 'IR', name: 'Persisch'},
        {language: 'fil', local: 'PH', name: 'Philippinisch'},
        {language: 'pl', local: 'PL', name: 'Polnisch'},
        {language: 'pt', local: 'PT', name: 'Portugiesisch (Portugal)'},
        {language: 'pt', local: 'BR', name: 'Portugiesisch (Brasilien)'},
        {language: 'pa', local: 'IN', name: 'Punjabi'},
        {language: 'rm', local: 'CH', name: 'Rätoromanisch'},
        {language: 'ro', local: 'RO', name: 'Romänisch'},
        {language: 'ru', local: 'RU', name: 'Russisch'},
        {language: 'se', local: 'NO', name: 'Samisch'},
        {language: 'sa', local: 'IN', name: 'Sanskrit'},
        {language: 'sv', local: 'SE', name: 'Schwedisch'},
        {language: 'sr', local: 'Latn-CS', name: 'Serbokroatisch'},
        {language: 'sl', local: 'SI', name: 'Slowenisch'},
        {language: 'sk', local: 'SK', name: 'Slowakisch'},
        {language: 'wen', local: 'DE', name: 'Sorbisch'},
        {language: 'es', local: 'ES', name: 'Spanish (Spanien)'},
        {language: 'es', local: 'AR', name: 'Spanish (Argentinien)'},
        {language: 'es', local: 'BO', name: 'Spanish (Bolivien)'},
        {language: 'es', local: 'CL', name: 'Spanish (Chile)'},
        {language: 'es', local: 'CO', name: 'Spanish (Kolumbien)'},
        {language: 'es', local: 'CR', name: 'Spanish (Costa Rica)'},
        {language: 'es', local: 'EC', name: 'Spanish (Ecuador)'},
        {language: 'es', local: 'SV', name: 'Spanish (El Salvador)'},
        {language: 'es', local: 'GT', name: 'Spanisch (Guatemala)'},
        {language: 'es', local: 'HN', name: 'Spanisch (Honduras)'},
        {language: 'es', local: 'MX', name: 'Spanisch (Mexico)'},
        {language: 'es', local: 'NI', name: 'Spanisch (Nicaragua)'},
        {language: 'es', local: 'PA', name: 'Spanisch (Panama)'},
        {language: 'es', local: 'PY', name: 'Spanisch (Paraguay)'},
        {language: 'es', local: 'PE', name: 'Spanisch (Peru)'},
        {language: 'es', local: 'PR', name: 'Spanisch (Puerto Rico)'},
        {language: 'es', local: 'VE', name: 'Spanisch (Dominikanische Republik)'},
        {language: 'es', local: 'UY', name: 'Spanisch (Uruguay)'},
        {language: 'es', local: 'DO', name: 'Spanisch (Venezuela)'},
        {language: 'st', local: 'ZA', name: 'Sutu'},
        {language: 'sw', local: 'KE', name: 'Swahili'},
        {language: 'tg', local: 'Cyrl-TJ', name: 'Tadschikisch'},
        {language: 'tg', local: 'Cyrl-TJ', name: 'Tamil'},
        {language: 'ta', local: 'IN', name: 'Tataren'},
        {language: 'te', local: 'IN', name: 'Telugu'},
        {language: 'th', local: 'TH', name: 'Thai'},
        {language: 'bo', local: 'CN', name: 'Tibetisch'},
        {language: 'cs', local: 'CZ', name: 'Tschechisch'},
        {language: 'ts', local: 'ZA', name: 'Tsonga'},
        {language: 'tn', local: 'ZA', name: 'Tswana'},
        {language: 'tk', local: 'TM', name: 'Turkmenen'},
        {language: 'tr', local: 'TR', name: 'Türkisch'},
        {language: 'xh', local: 'ZA', name: 'Xhosa'},
        {language: 'zu', local: 'ZA', name: 'Zulu'},
        {language: 'uk', local: 'UA', name: 'Ukrainisch'},
        {language: 'hu', local: 'HU', name: 'Ungarisch'},
        {language: 'ur', local: 'PK', name: 'Urdu (Pakistan)'},
        {language: 'uz', local: 'Latn-UZ', name: 'Usbekisch (Latein)'},
        {language: 'cy', local: 'GB', name: 'Walisisch'},
        {language: 'ven', local: 'ZA', name: 'Venda'},
        {language: 'vi', local: 'VN', name: 'Vietnamesisch'},
        {language: 'gsw', local: 'CH', name: 'Swiss German (Schweiz)'},
        {language: 'gsw', local: 'FR ', name: 'Swiss German (Frankreich)'},
        {language: 'gsw', local: 'LI ', name: 'Swiss German (Liechtenstein)'},
    ];

    public createShortName(language: string): string {
        const languageData = language.split('-');
        const availableLanguage = this.languagesList.filter(languageInfo => languageInfo.language === languageData[0]);
        if (availableLanguage.length === 1) {
            return availableLanguage[0].language.toUpperCase();
        }
        const languageLocalInfo = availableLanguage.find(languageInfo => languageInfo.local === languageData[1]);
        return languageLocalInfo !== undefined ? `${languageLocalInfo.language.toUpperCase()} (${languageLocalInfo.local})` : availableLanguage[0].language.toUpperCase();
    }

    public getLearnerLanguages(channels: LearnerChannels): string {
        const languages = this.getLanguagesFromChannels(channels);
        if (languages.length > 0) {
            const newLanguages = languages.map((localeInfo: string) => {
                const locale = localeInfo.split('-');
                const localeFromList = this.languagesList.find(languageData => languageData.language === locale[0] && languageData.local === locale[1]);
                if (localeFromList !== undefined) {
                    return localeFromList.name;
                }
                const languageFromList = this.languagesList.find(languageData => languageData.language === locale[0]);
                if (languageFromList !== undefined) {
                    return languageFromList.name.split(' ')[0];
                }
                return localeInfo;
            });
            return newLanguages.join(', ');
        }
        return '-';
    }

    public getLanguageNameForLocale(locale: string): string {
        const languageData = locale.split('-');
        const languages = this.languagesList.filter(languageInfo => languageInfo.language === languageData[0]);
        if (languages.length > 1) {
            const languageLocation = languages.find(languageInfo => languageInfo.local === languageData[1]);
            return languageLocation !== undefined ? languageLocation.name : languages[0].name;
        }
        return languages[0].name;
    }

    // We do not allow creating Learning package in Swiss German languages as this is not possible that user by himself set this language in Teams or Zitadel
    public getAvailableLanguagesForLearningPackage(): { language: string; local: string; name: string; }[] {
        return this.languagesList.filter(language => language.language !== 'gsw');
    }

    private getLanguagesFromChannels(channels: LearnerChannels): string[] {
        const locales: string[] = [];
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!channels) {
            return locales;
        }
        for (const [, value] of Object.entries(channels)) {
            const locale = (value as unknown as LearnerChannel)?.locale;
            if (locale !== undefined) {
                locales.push(locale);
            }
        }
        return locales;
    }
}
