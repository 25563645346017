import {Component, OnInit} from '@angular/core';
import {NewResourcePopupService} from '../new-resource-popup.service';
import {PopupService} from '../../../../components/popup/popup.service';
import {ResourceUploadError} from '../ResourceuploadError';

@Component({
    selector: 'app-result-new-resource-popup-page',
    templateUrl: './result-new-resource-popup-page.component.html',
    styleUrls: ['./result-new-resource-popup-page.component.scss']
})
export class ResultNewResourcePopupPageComponent implements OnInit {
    public errors: ResourceUploadError;
    public name: string;

    constructor(public popupService: PopupService,
                private newResourcePopupService: NewResourcePopupService) {
    }

    public ngOnInit(): void {
        this.errors = this.newResourcePopupService.getUploadFileErrors();
        this.name = this.newResourcePopupService.getResourceName();
    }
}
