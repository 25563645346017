import {ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {EditorQuestionsService} from '../../core/editor/editor-questions.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {mobileMediaMatch} from '../../global-variables';
import {MediaMatcher} from '@angular/cdk/layout';
import {EditorQuestionService} from '../../core/editor/editor-question.service';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {LearningPackageQuizzesOverview} from '../../core/api-types/learningPackageQuizzesOverview';
import {QuizOverview} from '../../core/api-types/quizOverview';
import {SortOption, SortType} from '../../core/editor/types/Sort';
import {PopupService} from '../../components/popup/popup.service';
import {Tag} from '../../core/api-types/Tag';
import {QuestionParamsFormGroup} from './QuestionParamsFormGroup';
import {QuestionParamsFormGroupData} from './QuestionParamsFormGroupData';
import {QuestionParamsData} from './QuestionParamsData';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {LearningPackageQuestionsService} from './learning-package-questions.service';

@Component({
    selector: 'app-learnpackages-quizzes',
    animations: [
        trigger('move', [
            state('true', style({height: 'fit-content', width: '100%'})),
            state('false', style({height: 'fit-content', width: '400px'})),
            transition('* => *', animate('300ms ease-out'))
        ]),
        trigger('slideInOutLeft', [
            state('true', style({width: '100%'})),
            state('false', style({width: '400px'})),
            transition('* => *', animate('300ms ease-out'))
        ]),
        trigger('slideInOutRight', [
            state('true', style({width: 0})),
            state('false', style({display: 'block', width: '100%'})),
            transition('* => *', animate('300ms ease-out'))
        ]),
        trigger('slideMobileInOutLeft', [
            state('true', style({width: '100%'})),
            state('false', style({width: 0, display: 'none'})),
            transition('* => *', animate('300ms ease-out'))
        ]),
        trigger('slideMobileInOutRight', [
            state('true', style({width: 0})),
            state('false', style({display: 'block', width: '100%'})),
            transition('* => *', animate('300ms ease-out'))
        ])
    ],
    templateUrl: './learning-packages-quizzes.component.html',
    styleUrls: ['./learning-packages-quizzes.component.scss']
})
export class LearningPackagesQuizzesComponent implements OnInit, OnDestroy {
    public mobileQuery: MediaQueryList;
    private mobileQueryListener: () => void;

    public selectedQuizId$: Observable<string>;
    public allTranslationQuizzes: QuizOverview[];
    public learningPackage: LearningPackageQuizzesOverview;
    public listOfQuizzes: QuizOverview[];
    public filteredQuizzes: QuizOverview[];
    public activeLearningPackageSubscription = new Subscription();
    public learningPackageAvailableTagsSubscription = new Subscription();
    public formDataSubscription = new Subscription();
    public activeLearningPackageId: string;
    public learningPackageHasTranslation: boolean;

    public filterText = new FormControl<string>('');
    public sort = new FormControl<SortType>('creationDate');
    public filterLanguage = new FormControl<boolean>(false);
    public filterFeedback = new FormControl<boolean>(false);
    public filterTags = new FormControl<Tag[]>([]);
    private paramsSubscription = new Subscription();
    public formData = new FormGroup<QuestionParamsFormGroup>({
        filterText: this.filterText,
        sort: this.sort,
        filterLanguage: this.filterLanguage,
        filterFeedback: this.filterFeedback,
        tags: this.filterTags
    });
    public queryParams: QuestionParamsData;
    public availableTags: Tag[];

    public tagsDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'label',
        enableCheckAll: false,
        allowSearchFilter: true,
        searchPlaceholderText: $localize`Tags suchen`,
        noDataAvailablePlaceholderText: $localize`Keine Tag verfügbar`
    };
    private static NO_TAGS_FILTER = $localize`Fragen ohne Tag anzeigen`;

    constructor(public editorQuizzesService: EditorQuestionsService,
                public changeDetectorRef: ChangeDetectorRef,
                private popupService: PopupService,
                public media: MediaMatcher,
                public editorQuizService: EditorQuestionService,
                public editorLearningPackageService: EditorLearningPackageService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public learningPackageQuizzesService: LearningPackageQuestionsService) {
        this.mobileQuery = media.matchMedia(mobileMediaMatch);
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        // IE and Edge support only this! Also not deprecated according to https://github.com/microsoft/TypeScript/issues/32210
        this.mobileQuery.addListener(this.mobileQueryListener);
    }

    @HostListener('window:beforeunload', ['$event'])
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    unloadHandler($event) {
        if (!this.editorQuizzesService.quizSaved.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return $event.returnValue = 'Your changes will not be saved';
        }

    }

    public hasQuestions$ = this.learningPackageQuizzesService.questionsPresentation$.pipe(map(questions => questions !== undefined && questions.length > 0));

    public ngOnInit(): void {
        this.selectedQuizId$ = this.editorQuizService.selectedQuizId$;
        this.activeLearningPackageSubscription = this.editorLearningPackageService.activeLearningPackage$.pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter((learningPackages: LearningPackageQuizzesOverview[]) => !!learningPackages),
        ).subscribe((learningPackages: LearningPackageQuizzesOverview[]) => {
            this.activeLearningPackageId = learningPackages[0].learningPackageId;
            this.allTranslationQuizzes = learningPackages.map(learningPackage => learningPackage.workingVersionQuizzes).reduce((acc, val) => acc.concat(val), []);
            this.learningPackage = learningPackages.find(learningPackage => learningPackage.mainLearningPackage);
            this.listOfQuizzes = this.learningPackage.workingVersionQuizzes;

            this.learningPackageAvailableTagsSubscription = combineLatest([this.editorLearningPackageService.learningPackageAvailableTags$, this.activatedRoute.queryParamMap]).subscribe(([availableTags, params]: [Tag[], ParamMap]) => {
                if (availableTags !== undefined) {
                    this.availableTags = [...availableTags, LearningPackagesQuizzesComponent.getNoFilterTag()];
                    this.checkIfLearningPackageHasTranslation(learningPackages);
                    this.setParams(params);
                    this.filterAndSortQuizzes(this.formData.getRawValue());
                }
            });
        });
        this.formDataSubscription = this.formData.valueChanges.subscribe((data: QuestionParamsFormGroupData) => {
            this.setQueryParams(data);
            if (this.listOfQuizzes.length > 0 && this.availableTags !== undefined) {
                this.filterAndSortQuizzes(data);
            }
            this.queryParams = this.editorQuizzesService.getQueryParams(data, this.learningPackageHasTranslation);
        });
    }

    private static getNoFilterTag(): Tag {
        return {
            id: LearningPackagesQuizzesComponent.NO_TAGS_FILTER,
            label: LearningPackagesQuizzesComponent.NO_TAGS_FILTER
        } as Tag;
    }

    private setParams(params: ParamMap): void {
        this.readSortParam(params);
        this.readFilterTextParam(params);
        if (this.learningPackageHasTranslation) {
            this.readFilterLanguageParam(params);
        }
        this.readFilterFeedbackParam(params);
        this.readFilterTagsParam(params);
    }

    private filterAndSortQuizzes(data: QuestionParamsFormGroupData): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        this.filterQuizzesByText(!data.filterText ? '' : data.filterText);
        if (this.learningPackageHasTranslation) {
            this.filterQuizzesByLanguage(data.filterLanguage);
        }
        this.filterQuizzesByFeedback(data.filterFeedback);
        this.filterQuestionsByTags(data.tags);
        this.learningPackageQuizzesService.setQuestions(this.filteredQuizzes, data.sort);
    }

    private setQueryParams(data: QuestionParamsFormGroupData): void {
        const queryParams = this.editorQuizzesService.createQuestionParamsData(data, this.learningPackageHasTranslation);
        this.learningPackageQuizzesService.updateUrl(queryParams);
    }

    private readFilterFeedbackParam(params: ParamMap): void {
        const filterFeedbackParam = params.get('filterFeedback');
        const filterFeedbackParamValue = filterFeedbackParam === 'active';
        if (filterFeedbackParamValue !== this.filterFeedback.value) {
            this.filterFeedback.setValue(filterFeedbackParamValue);
        }
    }

    private readFilterLanguageParam(params: ParamMap): void {
        const filterLanguageParam = params.get('filterLanguage');
        const filterLanguageParamValue = filterLanguageParam === 'active';
        if (filterLanguageParamValue !== this.filterLanguage.value) {
            this.filterLanguage.setValue(filterLanguageParamValue);
        }
    }

    private readFilterTagsParam(params: ParamMap): void {
        const filterTagsParam = params.getAll('tags');
        // eslint-disable-next-line no-null/no-null
        if (filterTagsParam !== null && this.availableTags !== undefined) {
            const tags = this.availableTags.filter(tag => filterTagsParam.includes(tag.id));
            if (tags !== this.filterTags.getRawValue()) {
                this.filterTags.setValue(tags);
            }
        }

    }

    private readFilterTextParam(params: ParamMap): void {
        const textFilterParam = params.get('filterText');
        if (textFilterParam !== this.filterText.value) {
            this.filterText.setValue(textFilterParam);
        }
    }

    private readSortParam(params: ParamMap): void {
        const sortingParam = params.get('sort') as SortType;
        if (sortingParam !== this.sort.value) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            this.sort.setValue(!sortingParam ? SortOption.CREATION_DATE : sortingParam);
        }
    }

    private checkIfLearningPackageHasTranslation(learningPackages: LearningPackageQuizzesOverview[]): void {
        this.learningPackageHasTranslation = learningPackages.length > 1;
        if (this.learningPackageHasTranslation) {
            this.formData.addControl('filterLanguage', this.filterLanguage);
        }
    }

    public ngOnDestroy(): void {
        this.activeLearningPackageSubscription.unsubscribe();
        this.paramsSubscription.unsubscribe();
        this.formDataSubscription.unsubscribe();
        this.learningPackageAvailableTagsSubscription.unsubscribe();
    }

    private filterQuizzesByText(textFilter: string): void {
        if (textFilter.length === 0) {
            this.filteredQuizzes = this.listOfQuizzes;
        } else {
            const filteredTranslationQuizzes = this.allTranslationQuizzes.filter(quiz => {
                if (this.learningPackageQuizzesService.isFilterInQuestionOrExplanation(quiz, textFilter)) {
                    return true;
                }
                if (quiz.tags !== undefined && quiz.tags.length > 0) {
                    const tagLabels = quiz.tags.map(tag => this.availableTags.find(availableTag => availableTag.id === tag)?.label).filter(tag => tag !== undefined);
                    return tagLabels.some(tagLabel => tagLabel.toLowerCase().includes(textFilter.toLowerCase()));
                }
                return this.learningPackageQuizzesService.filterAnswers(quiz, textFilter);
            });
            this.filteredQuizzes = this.listOfQuizzes.filter(quiz => filteredTranslationQuizzes.map(quizData => quizData.quizId).includes(quiz.quizId));
        }
    }

    private filterQuizzesByFeedback(feedbackFilter: boolean): void {
        if (feedbackFilter) {
            this.filteredQuizzes = this.filteredQuizzes.filter(quiz => quiz.hasOpenFeedback);
        }
    }

    private filterQuizzesByLanguage(languageFilter: boolean): void {
        if (languageFilter) {
            this.filteredQuizzes = this.filteredQuizzes.filter(quiz => quiz.haveTranslations === false);
        }
    }

    private filterQuestionsByTags(tags: Tag[]): void {
        if (tags !== undefined && tags.length > 0) {
            const tagIds = tags.map(tag => tag.id);
            const noTagsFilterActive = tagIds.some(tagId => tagId === LearningPackagesQuizzesComponent.NO_TAGS_FILTER);
            this.filteredQuizzes = this.filteredQuizzes.filter(quiz => {
                if (quiz.tags === undefined || quiz.tags.length === 0) {
                    return noTagsFilterActive;
                }
                return quiz.tags.some(tag => tagIds.includes(tag));
            });
        }
    }

    public openQuizzesOverview(): void {
        const data = this.formData.getRawValue();
        if (this.activeLearningPackageId !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${this.activeLearningPackageId}/fragen`],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: this.editorQuizzesService.getQueryParams(data, this.learningPackageHasTranslation)
                });
        }
    }

    public createNewQuiz(): void {
        this.editorQuizService.newQuiz$.next(undefined);
        const data = this.formData.getRawValue();
        if (this.activeLearningPackageId !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${this.activeLearningPackageId}/fragen/neue-frage`],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: this.editorQuizzesService.getQueryParams(data, this.learningPackageHasTranslation)
                });
        }
    }

    public getFeedbackFilterTitle(filterFeedbackActive: boolean): string {
        return filterFeedbackActive ? $localize`Offenes Feedback-Filter aktiv` : $localize`Offenes Feedback-Filter inaktiv`;
    }

    public getLanguageFilterTitle(filterLanguageActive: boolean): string {
        return filterLanguageActive ? $localize`Übersetzungsfilter aktiv` : $localize`Übersetzungsfilter inaktiv`;
    }

    public clearSearch(): void {
        this.filterText.setValue('');
    }

    public shouldShowTagFilter(): boolean {
        if (this.availableTags === undefined) {
            return false;
        }
        const tags = [...this.availableTags].filter(tag => tag.id !== LearningPackagesQuizzesComponent.NO_TAGS_FILTER);
        return tags !== undefined && tags.length > 0;
    }
}
