import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {TimeSplitMode} from '../rest/analytics-rest/TimeSplitMode';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsGroupLearningPackageService implements OnDestroy {
    public paramsSubscription = new Subscription();
    public activeLearnPackageId = new BehaviorSubject<string>(undefined);
    public activeLearnPackageId$ = this.activeLearnPackageId.asObservable();
    public timePeriod = new BehaviorSubject<TimeSplitMode>(TimeSplitMode.Weekly);

    constructor(private activatedRoute: ActivatedRoute) {
    }

    public getActivatedRouteParams(): void {
        this.paramsSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
            const activeLearnPackageId = params.get('learningPackageId');
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            this.activeLearnPackageId.next(!activeLearnPackageId ? undefined : activeLearnPackageId);
            const timePeriod = params.get('timePeriod') as TimeSplitMode;
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            this.timePeriod.next(!timePeriod ? TimeSplitMode.Weekly : timePeriod);
        });
    }

    public unSubscribeActiveRoute(): void {
        this.paramsSubscription.unsubscribe();
    }

    public getTimePeriod(): TimeSplitMode {
        return this.timePeriod.value;
    }

    public ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

}
