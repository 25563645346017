import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';
import {User} from '../api-types/user';
import {UserRoles} from '../api-types/roles';

// TODO CLEANUP TASK: Can we remove/cleanup this service?
@Injectable({
    providedIn: 'root'
})
export class AuthUntilUserRoleService {
    public userOrganizationRoles = new BehaviorSubject<{ roles: string []; organization: { [organization: string]: string[]; }; }>(undefined);
    public userOrganizationRoles$ = this.userOrganizationRoles.asObservable();

    constructor(private authService: AuthService) {
        this.authService.authentication$.subscribe((authentication: User) => {
            this.userOrganizationRoles.next({
                roles: authentication?.roles,
                organization: authentication?.organisations
            });
        });
    }

    public hasSuperAdminRole(): Observable<boolean> {
        return this.userOrganizationRoles$.pipe(
            take(1),
            filter((data: { roles: string []; organization: { [organization: string]: string[]; }; }) => {
                return data.roles !== undefined && data.roles.length > 0;
            }),
            switchMap((data: { roles: string []; organization: { [organization: string]: string[]; }; }) => {
                return of(data.roles.includes(UserRoles.SUPER_ADMINISTRATOR));
            })
        );
    }
}
