import {Component} from '@angular/core';
import {AuthService} from '../../auth.service';

@Component({
    templateUrl: './auth-error.component.html',
    styleUrls: ['./auth-error.component.scss']
})
export class AuthErrorComponent  {
    constructor(private authService: AuthService) {
    }

    public login(): void {
        void this.authService.startLoginFlow();
    }
}
