<div class="micromate-card">
    <div class="micromate-card-header">
        <h1>Ressourcen</h1>

        <app-button (click)="showNewResourcePopup()" [disabled]="hasReachedResourceLimit">
            <!-- TODO We should create a better button component including an icon? An icon button maybe? TBD -->
            <div class="micromate-button-content">
                <svg aria-hidden="true" focusable="false" viewBox="0 0 384 512"
                     class="micromate-button-text-icon">
                    <use href="./assets/icons.svg#plus-light"></use>
                </svg>
                <div i18n class="micromate-button-title">Neue Ressource</div>
            </div>
        </app-button>
    </div>

    <div class="micromate-resources-content">
        <p class="micromate-resources-content-limitation-info" i18n>Es sind maximal 10 Ressourcen pro Lernpaket erlaubt.</p>
        <div class="micromate-resources">
            <div class="micromate-table" *ngIf="hasNoResources === false">
                <div class="table-container table-container-header">
                    <ng-container>
                        <div class="row-header" i18n>Name</div>
                        <div class="row-header" i18n>Erstellungsdatum</div>
                        <div class="row-header" i18n>Anzahl Artikel</div>
                        <div class="row-header" i18n>Q&A</div>
                        <div class="row-header" i18n>Verarbeitung</div>
                        <div class="row-header" i18n>Actions</div>
                    </ng-container>
                </div>
                <div class="table-container-data">
                    <div class="table-container table-content"
                         *ngFor="let resource of resources; trackBy:trackByResource">
                        <div class="row row-name">{{resource.name}}</div>
                        <div class="row row-only-web">{{resource.creationDate | date :'short'}}</div>
                        <div class="row">{{resource.numberOfSelections}}</div>
                        <div class="row">
                            <app-icon icon="dot-circle-regular" class="mm-icon mm-icon-success"
                                      *ngIf="resource.state === 'active'" i18n-title
                                      title="Ressource für Q&A aktiviert"></app-icon>
                            <app-icon icon="dot-circle-regular" class="mm-icon mm-icon-inactive"
                                      *ngIf="resource.state === 'inactive'" i18n-title
                                      title="Ressource für Q&A deaktiviert"></app-icon>
                        </div>
                        <div class="row">
                            <div class="micromate-editor-table-icon-element" *ngIf="isResourceReady(resource)"
                                 title="Verarbeitung abgeschlossen" i18n-title>
                                <app-icon [icon]="'circle-check'" class="mm-icon mm-icon-success"></app-icon>
                            </div>
                            <div class="micromate-resource-progress" *ngIf="!isResourceReady(resource)"
                                 title="In Verarbeitung" i18n-title>
                                <app-icon [icon]="'hourglass-clock'" class="mm-icon mm-icon-inprogress"></app-icon>
                                <div class="micromate-resource-progress-value">
                                    {{calculateResourceProgress(resource)}}%
                                </div>
                            </div>
                        </div>
                        <div class="row row-dropdown" tabindex="-1">
                            <app-dropdown-button>
                                <app-dropdown-button-option
                                    (click)="openResource(resource.id)"
                                    i18n-text text="Öffnen"
                                    icon="arrow-up-right-from-square"></app-dropdown-button-option>
                                <app-dropdown-button-option
                                    (click)="removeResource(resource.id)"
                                    i18n-text text="Löschen"
                                    icon="trash-light"></app-dropdown-button-option>
                                <app-dropdown-button-option
                                    (click)="activate(resource.id)"
                                    *ngIf="resource.state === 'inactive'"
                                    icon="circle-play-light"
                                    i18n-text text="Q&A aktivieren"></app-dropdown-button-option>
                                <app-dropdown-button-option
                                    *ngIf="resource.state === 'active'"
                                    (click)="deactivate(resource.id)"
                                    icon="circle-pause-light"
                                    i18n-text text="Q&A deaktivieren"></app-dropdown-button-option>
                                <app-dropdown-button-option
                                    *ngIf="isSuperAdmin$ | async"
                                    (click)="openResourceHtml(resource.id)"
                                    icon="file-code"
                                    i18n-text text="Resource-HTML anzeigen"></app-dropdown-button-option>
                            </app-dropdown-button>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="hasNoResources"
                 class="micromate-empty-table" i18n>
                Keine Ressourcen vorhanden
            </div>
        </div>
    </div>
</div>
