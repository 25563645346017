import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiHistoryParamsService implements OnDestroy {
    public paramsSubscription = new Subscription();
    public errorMessages = new BehaviorSubject<boolean>(false);
    public errorMessages$ = this.errorMessages.asObservable();

    constructor(private activatedRoute: ActivatedRoute) {
    }

    public getApiHistoryParams(): void {
        this.paramsSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
            const errorMessagesInfo = params.get('errorApis');
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            this.errorMessages.next(!errorMessagesInfo ? false : errorMessagesInfo === 'true');
        });
    }

    public ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }
}
