import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {OverlayService} from '../../../../../components/overlay/overlay.service';
import {BehaviorSubject, combineLatest, Observable, startWith, Subscription} from 'rxjs';
import {Tag} from '../../../../../core/api-types/Tag';
import {SelectedTag} from '../../../../../core/api-types/SelectedTag';
import {SelectedNewTag} from '../../../../../core/api-types/SelectedNewTag';
import {map} from 'rxjs/operators';
import {EditorQuestionsService} from '../../../../../core/editor/editor-questions.service';
import {FormCustomValidation} from '../../../../../core/form-custom-validation';

@Component({
    selector: 'app-tags-dropdown',
    templateUrl: './tags-dropdown.component.html',
    styleUrls: ['./tags-dropdown.component.scss']
})
export class TagsDropdownComponent implements OnInit, OnDestroy, OnChanges {
    @Input()
    public availableTags$: Observable<Tag[]>;
    public filteredAvailableTags$: Observable<Tag[]>;

    @Input()
    public selectedAvailableTags: SelectedTag[];
    private selectedAvailableTagsSubject: BehaviorSubject<SelectedTag[]>;
    private selectedAvailableTags$: Observable<SelectedTag[]>;
    public availableSelectedTags$: Observable<Tag[]>;

    @Input()
    public selectedNewTags: SelectedNewTag[];
    private selectedNewTagsSubject: BehaviorSubject<SelectedNewTag[]>;
    private selectedNewTags$: Observable<SelectedNewTag[]>;

    @Output()
    public selectedAvailableTagsChange = new EventEmitter<SelectedTag[]>();

    @Output()
    public selectedNewTagsChange = new EventEmitter<SelectedNewTag[]>();

    public filterText = new FormControl<string>('', [FormCustomValidation.trimmedMaxLengthValidator(50)]);
    public filteredAvailableTags: Tag[] = [];
    public shouldShowNewTag$: Observable<boolean>;
    public hasAnyTags$: Observable<boolean>;
    public filteredSelectedTags$: Observable<(Tag | SelectedNewTag)[]>;
    @ViewChild('filterInput') filterInput: ElementRef<HTMLInputElement>;

    private overlayServiceOpenSubscription = new Subscription();
    private filterValueChangesSubscription = new Subscription();

    constructor(private overlayService: OverlayService,
                private editorQuizzesService: EditorQuestionsService) {
    }

    public ngOnInit(): void {
        this.selectedAvailableTagsSubject = new BehaviorSubject<SelectedTag[]>(this.selectedAvailableTags);
        this.selectedAvailableTags$ = this.selectedAvailableTagsSubject.asObservable();

        this.selectedNewTagsSubject = new BehaviorSubject<SelectedNewTag[]>(this.selectedNewTags);
        this.selectedNewTags$ = this.selectedNewTagsSubject.asObservable();

        const filterText$ = this.filterText.valueChanges.pipe(
            startWith(this.filterText.value)
        );

        this.filteredAvailableTags$ = combineLatest([this.availableTags$, filterText$, this.selectedAvailableTags$]).pipe(
            map(([tags, filterText, selectedTags]: [Tag[], string, SelectedTag[]]) => tags !== undefined ? tags.filter(tag => !selectedTags.some(selectedTag => selectedTag.id === tag.id) &&
                tag.label.toLowerCase().includes(this.getFilterValue(filterText))) : []
            ));

        this.availableSelectedTags$ = combineLatest([this.selectedAvailableTags$, this.availableTags$]).pipe(
            map(([selectedTags, availableTags]: [SelectedTag[], Tag[]]) =>
                availableTags !== undefined ? selectedTags.map(selectedTag => availableTags.find(tag => tag.id === selectedTag.id)).filter(selectedTag => selectedTag !== undefined) : []
            ));

        this.filteredSelectedTags$ = combineLatest([this.availableSelectedTags$, this.selectedNewTags$, filterText$]).pipe(
            map(([availableSelectedTags, selectedNewTags, filterText]: [Tag[], SelectedNewTag[], string]) =>
                [...availableSelectedTags, ...selectedNewTags].filter(tag => tag.label.toLowerCase().includes(this.getFilterValue(filterText))).sort((a, b) => a.label.localeCompare(b.label))));

        this.hasAnyTags$ = combineLatest([this.availableSelectedTags$, this.filteredSelectedTags$]).pipe(map(([filteredAvailableSelectedTags, filteredSelectedTags]: [Tag[], (Tag | SelectedNewTag)[]]) => {
                return filteredAvailableSelectedTags !== undefined && filteredSelectedTags !== undefined;
            }
        ));

        this.shouldShowNewTag$ = combineLatest([this.filteredAvailableTags$, this.filteredSelectedTags$, filterText$]).pipe(
            map(([filteredAvailableTags, filteredSelectedTags, filterText]: [Tag[], (Tag | SelectedNewTag)[], string]) => {
                if (filterText.length > 0) {
                    const text = this.getFilterValue(filterText);
                    const filteredAvailableTag = filteredAvailableTags.find(tag => tag.label.toLowerCase() === text);
                    const filteredSelectedNewTag = filteredSelectedTags.find(tag => tag.label.toLowerCase() === text);
                    return filteredAvailableTag === undefined && filteredSelectedNewTag === undefined;
                }
                return false;
            })
        );

        this.overlayServiceOpenSubscription = this.overlayService.isOpen$.subscribe(isOpen => {
            if (isOpen) {
                // Set focus after opening dropdown have to be triggered when dropdown is open and visible, so we need setTimeout to provide that it wille be triggered in proper moment
                setTimeout(() => {
                    this.setFocusToInput();
                }, 0);
            } else {
                this.filterText.setValue('');
            }
        });
    }

    public ngOnChanges(): void {
        if (this.selectedAvailableTagsSubject !== undefined && this.selectedNewTagsSubject !== undefined) {
            this.selectedAvailableTagsSubject.next(this.selectedAvailableTags);
            this.selectedNewTagsSubject.next(this.selectedNewTags);
        }
    }

    private getFilterValue(filterText: string): string {
        return this.removeSpacesInText(filterText).toLowerCase();
    }

    private removeSpacesInText(text: string): string {
        return text.trim().replace(/\s+/g, ' ');
    }

    public ngOnDestroy(): void {
        this.overlayServiceOpenSubscription.unsubscribe();
        this.filterValueChangesSubscription.unsubscribe();
    }

    public formatFilterTagLabel(tag: string): string {
        const filterText = this.filterText.value.trim();
        const startIndex = tag.toLowerCase().indexOf(filterText.toLowerCase());
        if (startIndex !== -1) {
            const filteredPart = tag.slice(startIndex, startIndex + filterText.length);
            return tag.replace(filteredPart, `<strong>${filteredPart}</strong>`);
        }
        return tag;
    }

    public unselectTag(unselectedTag: Tag | SelectedNewTag): void {
        if ((unselectedTag as Tag).id !== undefined) {
            const selectedTags = [...this.selectedAvailableTags];
            const indexToRemove = selectedTags.findIndex(tag => tag.id === (unselectedTag as Tag).id);
            if (indexToRemove !== -1) {
                selectedTags.splice(indexToRemove, 1);
            }
            this.selectedAvailableTags = selectedTags;
            this.emitSelectedAvailableTags();
        } else {
            const newSelectedNewTags = [...this.selectedNewTags];
            const indexToRemove = newSelectedNewTags.findIndex(tag => tag.label === unselectedTag.label);
            if (indexToRemove !== -1) {
                newSelectedNewTags.splice(indexToRemove, 1);
            }
            this.selectedNewTags = newSelectedNewTags;
            this.emitSelectedNewTags();
        }
        this.detectChangesAndSetFocusToInput();
    }

    public selectTag(tagId: string): void {
        this.selectedAvailableTags.push(new SelectedTag(tagId));
        this.selectedAvailableTagsSubject.next(this.selectedAvailableTags);
        this.detectChangesAndSetFocusToInput();
        this.emitSelectedAvailableTags();
    }

    public createNewTag(): void {
        const label = this.removeSpacesInText(this.filterText.value);
        this.selectedNewTags.push(new SelectedNewTag(label));
        this.selectedNewTagsSubject.next(this.selectedNewTags);
        this.filterText.setValue('');
        this.detectChangesAndSetFocusToInput();
        this.emitSelectedNewTags();
    }

    private setFocusToInput(): void {
        this.filterInput.nativeElement.focus();
    }

    private detectChangesAndSetFocusToInput(): void {
        this.setFocusToInput();
        this.editorQuizzesService.setQuestionDataChanged(true);
    }

    private emitSelectedAvailableTags(): void {
        this.selectedAvailableTagsChange.emit([...this.selectedAvailableTags]);
    }

    private emitSelectedNewTags(): void {
        this.selectedNewTagsChange.emit([...this.selectedNewTags]);
    }
}
