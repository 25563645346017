import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ResourceHelper} from '../resource-helper.service';
import {ListPagination} from '../../api-types/listPagination';
import {OrganizationLearner} from '../../api-types/organizationLearner';
import {OrganizationAdminOverview} from '../../api-types/organizationAdminOverview';
import {OrganizationAdminSettings} from '../../../app-organisation/OrganizationAdminSettings';
import {AddLearnerValidatorErrors} from '../../api-types/AddLearnerValidatorErrors';
import {OrganizationAdminManager} from '../../api-types/organizationManager';
import {OrganizationActiveLearner} from '../../api-types/organizationActiveLearner';
import {OrganizationLearnerBasicInfo} from '../../api-types/OrganizationLearnerBasicInfo';
import {OrganizationLearnersListPaginationRequest} from '../../../app-organisation/organisation-learners-overview/OrganizationLearnersListPaginationRequest';
import {OrganizationLearnerToAdd} from '../../api-types/OrganizationLearnerToAdd';

@Injectable({
    providedIn: 'root'
})
export class OrganizationAdminPanelResource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public getOrganizationSettings(): Observable<OrganizationAdminOverview> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<OrganizationAdminOverview>(`${url}/admin/settings`)));
    }

    public async saveOrganizationSettings(settings: OrganizationAdminSettings): Promise<string | { notUniqueOrganizationCode: boolean; }> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<string | { notUniqueOrganizationCode: boolean; }>(`${url}/admin/settings`, settings))));
    }

    public getOrganizationCode(): Observable<{ organizationCode: string; }> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ organizationCode: string; }>(`${url}/admin/settings/organization-code`)));
    }

    public getAllLearners(listPaginationRequest: OrganizationLearnersListPaginationRequest): Observable<ListPagination<OrganizationLearner>> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<ListPagination<OrganizationLearner>>(`${url}/admin/learner?filter=${listPaginationRequest.filter}&pageNumber=${listPaginationRequest.pageNumber}&pageAmount=${listPaginationRequest.pageAmount}&organizationStatus=${listPaginationRequest.organizationStatusFilter}`)));
    }

    public async createLearners(data: OrganizationLearnerToAdd[]): Promise<string | AddLearnerValidatorErrors> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<string | AddLearnerValidatorErrors>(`${url}/admin/learner`, data))));
    }

    public async removeLearner(learnerId: string): Promise<string> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.delete<string>(`${url}/admin/learner/${learnerId}`))));
    }

    public getLearnerInfo(learnerId: string): Observable<OrganizationLearnerBasicInfo> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<OrganizationLearnerBasicInfo>(`${url}/admin/learner/${learnerId}`)));
    }

    public async getOrganizationManagers(): Promise<OrganizationAdminManager[]> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<OrganizationAdminManager[]>(`${url}/admin/managers`))));
    }

    public async getOrganizationActiveLearners(): Promise<OrganizationActiveLearner[]> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<OrganizationActiveLearner[]>(`${url}/admin/active-learners`))));
    }

    public async addOrganizationManagers(managers: OrganizationAdminManager[]): Promise<string> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<string>(`${url}/admin/managers`, managers))));
    }
}
