<app-popup-container>
    <app-popup-content>
        <div class="mm-popup-content-media">
            <div class="mm-popup-content-media-element">

                <div class="mm-popup-content-media-image"
                     (dragenter)="imageHovering = true"
                     (dragleave)="imageHovering = false"
                     (drop)="imageHovering = false"
                     appDragAndDrop
                     (files)="filesDropped($event)">
                    <div class="micromate-editor-drag-area" *ngIf="(dragAndDropService.dragOnPage$ | async)" i18n
                         [class.micromate-editor-drag-area-active]="imageHovering">
                        Bild hier ablegen
                    </div>

                    <div class="micromate-editor-drop-down"
                         [class.micromate-editor-drop-down-preview]="quizMediaEditor.imageUrl !== undefined || quizMediaEditor.imageFile !== undefined">
                        <div class="micromate-editor-dropzone"
                             *ngIf="quizMediaEditor.imageUrl === undefined && !quizMediaEditor.imageFile"
                             (click)="fileInput.click()">
                            <input hidden type="file" #fileInput (change)="uploadFile($event.target)">
                            <div class="micromate-editor-dropzone-text-wrapper">
                                <div class="micromate-editor-dropzone-centered">
                                    <svg aria-hidden="true" focusable="false" viewBox="-60 0 640 512"
                                         class="micromate-editor-dropzone-centered-icon">
                                        <use href="./assets/icons.svg#image-light"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="micormate-editor-drop-preview"
                             *ngIf="quizMediaEditor.imageUrl !== undefined || quizMediaEditor.imageFile !== undefined">
                            <img
                                [src]="imageFileDrop? quizMediaEditor?.imageFile.url: quizMediaEditor?.imageUrl !== undefined? quizMediaEditor.imageUrl: quizMediaEditor?.imageFile.url"
                                class="micormate-editor-drop-preview-image">
                            <div class="micormate-editor-drop-preview-remove" (click)="removeImage()">
                                <svg aria-hidden="true" focusable="false" viewBox="0 0 320 512"
                                     class="image-close-button-svg">
                                    <use href="./assets/icons.svg#times-light"></use>
                                </svg>
                            </div>
                            <div class="micormate-editor-drop-preview-link">
                                <a [href]="imageFileDrop? quizMediaEditor.imageFile.url: quizMediaEditor.imageUrl!== undefined? quizMediaEditor.imageUrl: quizMediaEditor.imageFile.url"
                                   target="_blank">
                                    <svg aria-hidden="true" focusable="false" viewBox="0 0 512 512"
                                         class="image-link-button-svg">
                                        <use href="./assets/icons.svg#external-link-regular"></use>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="mm-popup-content-media-plus">
                <svg aria-hidden="true" focusable="false" viewBox="0 0 384 512"
                     class="mm-popup-content-media-plus-icon"
                     [class.mm-popup-content-media-plus-icon-active]="(quizMediaEditor.imageUrl !== undefined || quizMediaEditor.imageFile !== undefined) && quizMediaEditor.mediaUrl !== undefined">
                    <use href="./assets/icons.svg#plus-light"></use>
                </svg>
            </div>

            <div class="mm-popup-content-media-element">
                <div class="mm-popup-content-media-audio-and-video">
                    <div *ngIf="!runningMediaValidation && !invalidMedia" class="mm-popup-content-media-preview"
                         [class.mm-popup-content-media-audio-and-video-icons-active]="quizMediaEditor?.mediaUrl !== undefined && quizMediaEditor.mediaUrl !== null && quizMediaEditor.mediaUrl.length > 0">
                        <img *ngIf="quizMediaEditor.type === undefined || quizMediaEditor.type === 'image'"
                             src="/assets/media/media-import-icons.svg"
                             alt="Micromate media types icons"
                             class="mm-popup-content-media-icons">

                        <ng-container
                            *ngIf="quizMediaEditor.mediaUrl !== undefined && quizMediaEditor.mediaUrl.length > 0 && quizMediaEditor.type === 'movie'">
                            <video *ngIf="videoLink.display === 'video'" controls
                                   class="mm-popup-content-media-preview-video">
                                <source [src]="quizMediaEditor.mediaUrl">
                                Ihr Browser unterstützt kein HTML-Video.
                            </video>
                            <app-iframe-media
                                *ngIf="videoLink.display === 'iframe'"
                                class="mm-popup-content-media-video"
                                [mediaLink]="quizMediaEditor.mediaUrl"></app-iframe-media>
                        </ng-container>

                        <div class="mm-popup-content-media-icons"
                             *ngIf="quizMediaEditor.mediaUrl !== undefined && quizMediaEditor.mediaUrl.length > 0 && quizMediaEditor.type === 'audio'">
                            <svg aria-hidden="true" focusable="false" viewBox="0 -50 576 650"
                                 class="mm-popup-content-media-icon">
                                <use href="./assets/icons.svg#volume-up-light"></use>
                            </svg>
                        </div>

                        <app-iframe-media
                            *ngIf="quizMediaEditor.mediaUrl !== undefined && quizMediaEditor.mediaUrl.length > 0 && quizMediaEditor.type === 'website'"
                            class="mm-popup-content-media-website"
                            [mediaLink]="quizMediaEditor.mediaUrl"></app-iframe-media>
                    </div>
                    <div
                        *ngIf="!runningMediaValidation && !invalidMedia && (quizMediaEditor.mediaUrl === undefined || quizMediaEditor.type === 'audio')"
                        class="mm-popup-content-media-input">
                        <div class="mm-popup-content-media-input-element"
                             *ngIf="quizMediaEditor.mediaUrl === undefined || quizMediaEditor.type === 'image'">
                            <input type="text" name="mediaUrl" class="micormate-form-input"
                                   placeholder="Video-, Audio- oder Webseiten-URL" i18n-placeholder
                                   [formControl]="medaUrlFormControl"
                                   [class.micormate-form-input-validation-error]="notSupportedMedia || invalidWebsiteMedia || medaUrlFormControl.errors">
                            <div
                                *ngIf="medaUrlFormControl.errors!== null || (invalidUrl && (videoLink.display === 'iframe'))"
                                class="micormate-form-validation-error" i18n>
                                Der eingegebene Wert muss mit https:// beginnen und eine gültige URL sein
                            </div>
                            <div *ngIf="notSupportedMedia" class="micormate-form-validation-error" i18n>
                                Die URL ist nicht erreichbar oder das Dateiformat wird nicht unterstützt
                            </div>
                            <div *ngIf="invalidWebsiteMedia" class="micormate-form-validation-error" i18n>
                                Die URL ist nicht erreichbar, zeigt nicht auf eine HTML-Seite oder die Website
                                unterbindet die Verwendung.
                            </div>
                        </div>

                        <audio controls class="mm-popup-content-media-audio"
                               *ngIf="quizMediaEditor.mediaUrl !== undefined && quizMediaEditor.mediaUrl.length > 0 && quizMediaEditor.type === 'audio'">
                            <source [src]="quizMediaEditor.mediaUrl">
                            Ihr Browser unterstützt kein HTML-Audio.
                        </audio>
                    </div>
                    <div *ngIf="runningMediaValidation" class="micormate-form-input-spinner-element">
                        <div class="micormate-form-input-spinner">
                        </div>
                    </div>
                    <div *ngIf="invalidMedia && quizMediaEditor.type === 'movie'"
                         class="mm-popup-content-media-invalid">
                        Es ist ein Fehler aufgetreten. Das Video ist derzeit nicht verfügbar.
                    </div>
                    <div *ngIf="invalidMedia && quizMediaEditor.type === 'audio'"
                         class="mm-popup-content-media-invalid">
                        Es ist ein Fehler aufgetreten. Audio ist derzeit nicht verfügbar.
                    </div>
                    <div *ngIf="invalidMedia && quizMediaEditor.type === 'website'"
                         class="mm-popup-content-media-invalid">
                        Es ist ein Fehler aufgetreten. Website ist derzeit nicht verfügbar.
                    </div>
                    <div class="mm-popup-content-media-remove"
                         *ngIf="quizMediaEditor.mediaUrl !== undefined && quizMediaEditor.mediaUrl.length > 0"
                         (click)="removeMedia()">
                        <svg aria-hidden="true" focusable="false" viewBox="0 0 320 512"
                             class="image-close-button-svg">
                            <use href="./assets/icons.svg#times-light"></use>
                        </svg>
                    </div>
                    <div class="mm-popup-content-media-link"
                         *ngIf="quizMediaEditor.mediaUrl !== undefined && quizMediaEditor.mediaUrl.length > 0">
                        <a [href]="quizMediaEditor.mediaUrl" target="_blank">
                            <svg aria-hidden="true" focusable="false" viewBox="0 0 512 512"
                                 class="image-link-button-svg">
                                <use href="./assets/icons.svg#external-link-regular"></use>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="discardMedia()"
                type="secondary" i18n>
                Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [action]="saveMedia"
                [disabled]="!mediaChanged || runningMediaValidation ||  notSupportedMedia || invalidWebsiteMedia|| medaUrlFormControl.errors || invalidUrl"
                i18n>Übernehmen
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
