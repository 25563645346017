import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {distinctUntilChanged, filter, map} from 'rxjs/operators';
import {BreadcrumbsItem} from './breadcrumbsItem';
import {BreadcrumbService} from './breadcrumb.service';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, AfterViewChecked {
    public breadcrumbs: BreadcrumbsItem[] = [];

    @ViewChild('breadCrumbsContainer') private breadCrumbsContainer: ElementRef;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private breadcrumbService: BreadcrumbService) {
    }

    public async ngOnInit(): Promise<void> {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            map(event => (event as NavigationEnd).url.split('?')[0]),
            distinctUntilChanged())
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            .subscribe(async () => {
            await this.parseActivatedRoute();
        });

        await this.parseActivatedRoute();
    }

    private async parseActivatedRoute(): Promise<void> {
        const urlParts: string[] = [];
        const urlSegments: ActivatedRoute[] = BreadcrumbComponent.getAllRoutes(this.activatedRoute.root);

        const breadcrumbsItems = urlSegments.filter(x => x.snapshot.data.breadcrumbType !== undefined)
            .map(async urlSegment => {
                const routeSnapshot = urlSegment.snapshot;
                const breadcrumbType = routeSnapshot.data.breadcrumbType as string;
                return await this.createBreadcrumbItem(routeSnapshot, urlParts, breadcrumbType);
            });

        this.breadcrumbs = (await Promise.all<BreadcrumbsItem>(breadcrumbsItems)).filter(item => item !== undefined);
    }

    private static getAllRoutes(currentRoute: ActivatedRoute): ActivatedRoute[] {
        const urlSegments: ActivatedRoute[] = [];
        do {
            const currentRouteChildren = currentRoute.children.filter(childRoute => childRoute.outlet === 'primary')[0];
            if (currentRouteChildren !== undefined) {
                urlSegments.push(currentRouteChildren);
            }
            currentRoute = currentRouteChildren;
        } while (currentRoute !== undefined);
        return urlSegments;
    }

    private async createBreadcrumbItem(routeSnapshot: ActivatedRouteSnapshot, urlParts: string[], breadCrumbType: string): Promise<BreadcrumbsItem> {
        let urlPart: string;
        let label: string;
        switch (routeSnapshot.data.breadcrumbType) {
            case 'none':
                break;
            case 'static':
                urlPart = routeSnapshot.url[0].path;
                urlParts.push(urlPart);
                label = routeSnapshot.data.breadcrumbLabel as string;
                return new BreadcrumbsItem(label, urlPart, urlParts.join('/'));
            case 'dynamic':
                urlPart = routeSnapshot.url[0].path;
                urlParts.push(urlPart);
                label = await this.breadcrumbService.getDynamicLabel(routeSnapshot.data.breadcrumbKey as string, urlParts);
                return new BreadcrumbsItem(label, urlPart, urlParts.filter(url => urlParts.indexOf(url) < (urlParts.length - 1)).join('/'));
            default:
                throw new Error(`breadcrumpType with value ${breadCrumbType} does not exist`);
        }
    }

    public ngAfterViewChecked(): void {
        // NativeElement can be any
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        this.breadCrumbsContainer.nativeElement.scrollLeft = this.breadCrumbsContainer.nativeElement.scrollWidth;
    }
}
