import {Component, Input} from '@angular/core';
import {PopupService} from '../../../components/popup/popup.service';
import {ConfirmationPopupResult} from '../../../components/confirmation-popup/ConfirmationPopupResult';
import {Popup} from '../../../components/popup/Popup';
import {takeUntil} from 'rxjs';
import {filter} from 'rxjs/operators';
import {DeleteTagPopupData} from './DeleteTagPopupData';
import {EditorResource} from '../../../core/rest/editor-resource.service';

@Component({
    selector: 'app-delete-tag-popup',
    templateUrl: './delete-tag-popup.component.html',
    styleUrls: ['./delete-tag-popup.component.scss']
})
export class DeleteTagPopupComponent implements Popup<DeleteTagPopupData> {
    @Input()
    public data: DeleteTagPopupData;

    public hasError: boolean = false;

    constructor(private popupService: PopupService,
                private editorRestService: EditorResource) {
    }

    public cancel(): void {
        this.popupService.close<ConfirmationPopupResult>('Cancel');
    }

    public confirm: () => Promise<void> = async () => {
        this.hasError = false;
        return new Promise(resolve => {
            this.editorRestService.deleteLearningPackageTag(this.data.selectedLearningPackageId, this.data.tag.id)
                .pipe(takeUntil(this.popupService.isPopupOpen$.pipe(filter(isOpen => !isOpen))))
                .subscribe(() => {
                    this.popupService.close<ConfirmationPopupResult>('Ok');
                    resolve();
                });
        });
    };
}
