<div *ngIf="(editorQuizService.activeQuiz$ | async) !== undefined && quiz !== undefined">
    <app-multiple-choice-text-quiz *ngIf="quiz[0]?.quizType === 'MultipleTextChoice'"
                                   [queryParams]="queryParams"
    ></app-multiple-choice-text-quiz>

    <app-single-choice-text-quiz *ngIf="quiz[0]?.quizType === 'SingleTextChoice'"
                                 [queryParams]="queryParams"
    ></app-single-choice-text-quiz>

    <app-free-text-quiz *ngIf="quiz[0]?.quizType === 'FreeText'"
                        [queryParams]="queryParams"
    ></app-free-text-quiz>

    <app-true-false-quiz *ngIf="quiz[0]?.quizType === 'TrueFalse'"
                         [queryParams]="queryParams"
    ></app-true-false-quiz>

    <app-reflection-free-text-question *ngIf="quiz[0]?.quizType === 'ReflectionFreeText'"
                        [queryParams]="queryParams"
    ></app-reflection-free-text-question>
</div>


<div *ngIf="(editorQuizzesService.newQuizPage | async) && (editorQuizService.activeQuiz$ | async) === undefined">
    <h1 i18n>Neue Frage</h1>
    <div>
        <div class="micromate-new-quiz-title" i18n>Standardfragen</div>
        <div class="micromate-new-quiz">
            <app-card *ngFor="let quizType of quizStandardTypes"
                      class="mm-new-quiz"
                      [title]="quizType.title"
                      [icon]="quizType.icon"
                      (cardClicked)="createNewQuiz(quizType.type)"></app-card>
        </div>
    </div>
    <div>
        <div class="micromate-new-quiz-title" i18n>Reflexionsfragen</div>
        <div class="micromate-new-quiz">
            <app-card *ngFor="let quizType of quizReflectionTypes"
                      [title]="quizType.title"
                      [icon]="quizType.icon"
                      (cardClicked)="createNewQuiz(quizType.type)"></app-card>
        </div>
    </div>

</div>
