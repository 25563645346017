export interface LearnerOrganization {
    organizationId: string;
    organizationGroupIds: string[];
    status: LearnerStatus;
    statusChangedDate?: Date;
    organizationName?: string;
}

export type LearnerStatus = 'active' | 'deactivated';

export const LEARNER_STATE_ACTIVE = 'active';
export const LEARNER_STATE_DEACTIVATED = 'deactivated';
