<div class="mm-import-resource">
    <div class="mm-spinner-element" *ngIf="(resourceWizardService.spinnerVisible$ | async)">
        <app-spinner class="mm-spinner"></app-spinner>
        <div class="mm-spinner-element-message" i18n>Das Einlesen der Ressource kann einige Minuten dauern…</div>
    </div>

    <app-import-new-resource-popup-page *ngIf="resourceWizardService.currentStep === 'import'"></app-import-new-resource-popup-page>
    <app-new-resource-settings-popup-page *ngIf="resourceWizardService.currentStep === 'settings'"></app-new-resource-settings-popup-page>
    <app-result-new-resource-popup-page *ngIf="resourceWizardService.currentStep === 'result'"></app-result-new-resource-popup-page>
</div>
