import {Quiz} from '../../../api-types/quiz';
import {QuizType, QuizTypeName} from '../QuizType';
import {QuizQuestion} from '../QuizQuestion';

export class ReflectionFreeTextQuestion implements Quiz {
    public quizType: QuizType = QuizTypeName.REFLECTION_FREE_TEXT_TYPE;

    constructor(public id: string,
                public quizId: string,
                public quiz: QuizQuestion,
                public originalContentHash?: string) {
    }

    public sourceUrl?: string;
    public sourceLabel?: string;
}

