import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-analytics-data',
    templateUrl: './analytics-data.component.html',
    styleUrls: ['./analytics-data.component.scss']
})
export class AnalyticsDataComponent implements OnInit {

    @Input() data: { value: number; positive?: boolean; };

    public formattedData: string;

    public ngOnInit(): void {
        if (this.data?.value !== undefined) {
            if (this.data.value === 0) {
                this.formattedData = '+0%';
            } else {
                this.formattedData = this.data.positive ? `+${this.data.value.toString()}%` : `${this.data.value.toString()}%`;
            }
        } else {
            this.data.positive = undefined;
            this.formattedData = '-';
        }
    }

}
