import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription, tap} from 'rxjs';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {TablePaginationParamsService} from '../../core/table-pagination-params.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {PopupService} from '../../components/popup/popup.service';
import {InboxFeedbackPopupComponent} from './inbox-feedback-popup/inbox-feedback-popup.component';
import {InboxFeedbackPopupData} from './inbox-feedback-popup/InboxFeedbackPopupData';
import {InboxService} from './inbox.service';
import {take} from 'rxjs/operators';
import {InboxDto} from '../../core/api-types/InboxDto';
import {ListPagination} from '../../core/api-types/listPagination';

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit, OnDestroy {
    public learningPackageInbox$: Observable<ListPagination<InboxDto>>;
    public routerSubscription$ = new Subscription();
    public selectedLearningPackageId: string;

    @ViewChild('tableContentRef')
    private tableContentRef: ElementRef;

    constructor(private editorLearningPackageService: EditorLearningPackageService,
                private editorRestService: EditorResource,
                public tablePaginationParamsService: TablePaginationParamsService,
                private router: Router,
                private activeRoute: ActivatedRoute,
                private popupService: PopupService,
                public inboxService: InboxService) {
    }

    public async ngOnInit(): Promise<void> {
        this.tablePaginationParamsService.updateFromActivatedRouteParams();
        this.learningPackageInbox$ = this.inboxService.learningPackageInbox$.pipe(
            tap(() => {
                    if (this.tableContentRef !== undefined) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        this.tableContentRef.nativeElement.scrollTop = 0;
                    }
                }
            ));

        await this.handlePopup();
    }

    private async handlePopup(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.routerSubscription$ = this.router.events.subscribe(async events => {
            if (events instanceof NavigationEnd) {
                await this.openPopupPopupIfNeeded();
            }
        });

        await this.openPopupPopupIfNeeded();
    }

    public ngOnDestroy(): void {
        this.routerSubscription$.unsubscribe();
        this.popupService.isPopupOpen$.pipe(take(1)).subscribe(value => {
            if (value) {
                this.popupService.close('redirect');
            }
        });
    }

    public getNavigationUrl(): string[] {
        const currentActiveLearningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        return ['editor', 'lernpakete', currentActiveLearningPackageId, 'inbox'];
    }

    private async openPopupPopupIfNeeded(): Promise<void> {
        const lastSegmentPath: string = this.activeRoute.snapshot?.url[0]?.path;
        if (lastSegmentPath !== undefined) {
            switch (lastSegmentPath) {
                case 'feedback':
                    await this.openFeedbackPopup();
                    break;
                default:
                    throw new Error(`Unknown path: ${lastSegmentPath}`);
            }
        }
    }

    public async openFeedbackPopup(): Promise<void> {
        const feedbackId = this.activeRoute.snapshot.params.feedbackId as string;
        const inboxFeedbackPopupData = new InboxFeedbackPopupData(feedbackId);
        const result = await this.popupService.open<InboxFeedbackPopupData>(InboxFeedbackPopupComponent, inboxFeedbackPopupData, new PopupOptions());
        if (result !== 'redirect') {
            void this.router.navigate(this.getNavigationUrl(), {
                queryParams: this.tablePaginationParamsService.paginationParams.getValue(),
                queryParamsHandling: 'merge'
            });
        }
    }

    public openFeedback(feedbackId: string): void {
        void this.router.navigate([...this.getNavigationUrl(), 'feedback', feedbackId], {
            queryParams: this.tablePaginationParamsService.paginationParams.getValue(),
            replaceUrl: true,
            queryParamsHandling: 'merge'
        });

    }

}
