import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, UrlTree} from '@angular/router';
import {FeaturesService} from './features.service';

@Injectable({
    providedIn: 'root'
})

export class FeatureGuard implements CanActivate {

    constructor(private router: Router,
                private featuresService: FeaturesService) {
    }

    public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        const expectedFeatures = next.data?.mustHave as string[];
        const assertFeatures = await this.featuresService.hasExpectedFeatures(expectedFeatures);

        if (!assertFeatures) {
            return this.router.parseUrl('/');
        }

        return true;
    }
}
