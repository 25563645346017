<app-popup-container>
    <app-popup-content>
        <div *ngIf="data.allowToDelete" class="mm-delete-tag-content" i18n>
            Möchtest du den Tag <strong>{{data.tag.label}}</strong> definitiv löschen?
        </div>

        <app-error-box
            class="mm-delete-tag-error-box"
            *ngIf="!data.allowToDelete"
            header="Tag kann nicht gelöscht werden" i18n-header
            content="Der Tag kann nur gelöscht werden, wenn er in der veröffentlichten Version nicht verwendet wird. Entferne den Tag von den Fragen, veröffentliche das Lernpaket und lösche den Tag erneut."
            i18n-content></app-error-box>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>
                Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                *ngIf="data.allowToDelete"
                [action]="confirm"
                [disabled]="hasError" i18n>
                Löschen
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>

