import {Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {QuizMakerService} from '../../../../core/editor/quiz-maker.service';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {AbstractControl, FormArray, FormControl, FormGroup, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {EditorQuestionsService} from '../../../../core/editor/editor-questions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EditorQuizCreatorService} from '../../../../core/editor/editor-quiz-creator.service';
import {FileHandle} from '../../../../core/drag-and-drop/drag-and-drop.directive';
import {DragAndDropService} from '../../../../core/drag-and-drop/drag-and-drop.service';
import {DomSanitizer} from '@angular/platform-browser';
import {QuizQuestionForm} from '../../../../core/editor/types/QuizQuestionForm';
import {QuizExplanationForm} from '../../../../core/editor/types/QuizExplanationForm';
import {SingleTextChoiceQuizEditor} from '../../../../core/editor/types/SingleTextChoice/SingleTextChoiceQuizEditor';
import {SingleTextChoiceQuiz} from '../../../../core/editor/types/SingleTextChoice/SingleTextChoiceQuiz';
import {SingleTextAnswersQuizEditor} from '../../../../core/editor/types/SingleTextChoice/SingleTextAnswersQuizEditor';
import {EditorResource} from '../../../../core/rest/editor-resource.service';
import {Answer} from '../../../../core/api-types/answer';
import {Subject, Subscription, takeUntil} from 'rxjs';
import {QuizTranslation} from '../../../../core/api-types/quizTranslation';
import {SingleChoiceTextQuestionValidatorService} from '../../../validation/single-choice-text-question-validator.service';
import {QuizMediaEditor} from '../../../../core/editor/types/QuizMediaEditor';
import {QuestionType} from '../../../../core/editor/question.type';
import {QuizTypeName} from '../../../../core/editor/types/QuizType';
import {ToastrService} from 'ngx-toastr';
import {filter, take} from 'rxjs/operators';
import {TranslationService} from '../../../../core/rest/translation.service';
import {FeaturesService} from '../../../../core/features/features.service';
import {Quiz} from '../../../../core/api-types/quiz';
import {PasteTextPopupComponent} from '../../../paste-text-popup/paste-text-popup.component';
import {PopupService} from '../../../../components/popup/popup.service';
import {Tag} from '../../../../core/api-types/Tag';
import {SelectedTag} from '../../../../core/api-types/SelectedTag';
import {SelectedNewTag} from '../../../../core/api-types/SelectedNewTag';
import {SingleChoiceTextQuizFormGroup} from './SingleChoiceTextQuizFormGroup';
import {SingleChoiceTextQuizAnswerFormGroup} from './SingleChoiceTextQuizAnswerFormGroup';

@Component({
    selector: 'app-single-choice-text-quiz',
    templateUrl: './single-choice-text-quiz.component.html',
    styleUrls: ['./single-choice-text-quiz.component.scss']
})
export class SingleChoiceTextQuizComponent implements OnInit, OnDestroy {
    @Input() queryParams: { filterText: string; sort: string; filterLanguage?: string; };

    public quiz: SingleTextChoiceQuiz;
    public quizQuestionMedia: QuizMediaEditor;
    public quizExplanationMedia: QuizMediaEditor;
    public selectedLearningPackageId: string;
    public quizTypeData: QuestionType;
    public questionFileDrop: boolean = false;
    public explanationFileDrop: boolean = false;
    public questionHovering = false;
    public explanationHovering = false;
    public languages: string[];
    public activeQuizSubscription = new Subscription();
    public activeTranslationQuiz: QuizTranslation;
    public formDataChangedSubscription = new Subscription();
    public quizQuestionMediaEditorChangedSubscription = new Subscription();
    public quizExplanationMediaEditorChangedSubscription = new Subscription();
    public learningPackageAvailableTagsSubscription = new Subscription();

    public availableTags: Tag[];
    public selectedAvailableTags: SelectedTag[] = [];
    public selectedNewTags: SelectedNewTag[] = [];

    public alreadyTranslated: boolean = false;

    @ViewChildren('answersForm') answersForm: QueryList<ElementRef>;

    public newAnswer = new FormControl<string>('');
    public disableNewAnswerInput = false;
    public advancedPanelOpen: boolean = false;

    constructor(public quizMakerService: QuizMakerService,
                public editorLearningPackageService: EditorLearningPackageService,
                public editorQuizService: EditorQuestionService,
                public featuresService: FeaturesService,
                public editorQuizzesService: EditorQuestionsService,
                private popupService: PopupService,
                private router: Router,
                private editorQuizCreatorService: EditorQuizCreatorService,
                public dragAndDropService: DragAndDropService,
                private sanitizer: DomSanitizer,
                private editorService: EditorResource,
                private translationService: TranslationService,
                public validator: SingleChoiceTextQuestionValidatorService,
                private activatedRoute: ActivatedRoute,
                private toastr: ToastrService) {
    }

    public formData: FormGroup<SingleChoiceTextQuizFormGroup> = this.createFormGroup();

    private createFormGroup(): FormGroup<SingleChoiceTextQuizFormGroup> {
        return new FormGroup<SingleChoiceTextQuizFormGroup>({
            question: new FormControl<string>('', this.validator.questionFormValidation),
            answers: new FormArray<FormGroup<SingleChoiceTextQuizAnswerFormGroup>>([]),
            sourceUrl: new FormControl<string>('', this.validator.sourceUrl),
            sourceLabel: new FormControl<string>('', this.validator.sourceLabel)
        }, {validators: this.validator.sourceUrlRequired});
    }

    public ngOnInit(): void {
        this.selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        if (this.selectedLearningPackageId !== undefined) {
            this.editorService.getLearningPackageLanguages(this.selectedLearningPackageId).subscribe((languages: string[]) => this.languages = languages);
        }
        this.activeQuizSubscription = this.editorQuizService.activeTranslationQuiz$.pipe(filter(quiz => quiz !== undefined)).subscribe((quiz: QuizTranslation) => {
            this.availableTags = undefined;
            this.selectedNewTags = [];
            this.learningPackageAvailableTagsSubscription = this.editorLearningPackageService.learningPackageAvailableTags$.subscribe((availableTags: Tag[]) => this.availableTags = availableTags);
            if (quiz !== undefined && quiz.quizType === QuizTypeName.SINGLE_CHOICE_TYPE) {
                this.activeTranslationQuiz = quiz;
                this.quiz = new SingleTextChoiceQuiz(quiz.id, quiz.quizId, quiz.quiz, quiz.answers as Answer[], quiz.explanation, quiz.sourceUrl, quiz.sourceLabel, quiz.originalContentHash);
                this.quizQuestionMedia = new QuizMediaEditor('question', this.quiz.quiz?.mediaType, this.quiz.quiz?.mediaUrl, this.quiz.quiz?.imageUrl);
                this.quizExplanationMedia = new QuizMediaEditor('explanation', this.quiz.explanation?.mediaType, this.quiz.explanation?.mediaUrl, this.quiz.explanation?.imageUrl);
                this.disableNewAnswerInput = quiz.answers.length === 5;
                this.selectedAvailableTags = quiz.tags !== undefined && quiz.tags.length > 0 ? quiz.tags.map(tag => new SelectedTag(tag)) : [];
            }
            this.quizTypeData = this.editorQuizService.quizTypes.find(quizType => quizType.type === this.quiz.quizType);
            this.clearQuizForm();
            this.cleanFiles();
            this.setQuizDataToForm();

            this.formData.valueChanges.subscribe(() => {
                this.editorQuizzesService.setQuestionDataChanged(true);
            });
        });

        this.newAnswer.valueChanges.subscribe((data: string) => {
            this.createNewAnswer(data);
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.formDataChangedSubscription = this.editorQuizzesService.formDataChanged.subscribe(async (status: boolean) => {
            if (status) {
                await this.saveCurrentQuizState(this.formData);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizQuestionMediaEditorChangedSubscription = this.editorQuizzesService.quizQuestionMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizQuestionMedia = this.editorQuizzesService.getQuestionQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizQuestionMediaEditorChanged.next(false);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizExplanationMediaEditorChangedSubscription = this.editorQuizzesService.quizExplanationMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizExplanationMedia = this.editorQuizzesService.getExplanationQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizExplanationMediaEditorChanged.next(false);
            }
        });
    }

    private destroy$ = new Subject();

    public ngOnDestroy(): void {
        this.activeQuizSubscription.unsubscribe();
        this.formDataChangedSubscription.unsubscribe();
        this.quizQuestionMediaEditorChangedSubscription.unsubscribe();
        this.quizExplanationMediaEditorChangedSubscription.unsubscribe();
        this.learningPackageAvailableTagsSubscription.unsubscribe();

        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public async changeLanguage(language: string): Promise<void> {
        if (this.editorQuizzesService.quizSaved.value) {
            this.alreadyTranslated = false;
            void this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                        language: language
                    },
                    queryParamsHandling: 'merge'
                });
        } else {
            const url = `/editor/lernpakete/${this.selectedLearningPackageId}/fragen/${this.quiz.quizId}?language=${language}`;
            await this.editorQuizzesService.openCancelQuestion(url);
        }
    }

    public isDisabled: boolean = false;

    private enable(): void {
        this.formData.enable();
        this.isDisabled = false;
    }

    private disable(): void {
        this.formData.disable();
        this.isDisabled = true;
    }

    public translate: () => Promise<void> = () => {
        return new Promise(resolve => {
            this.disable();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            this.editorQuizService.activeQuestionMainLanguage$.pipe(take(1)).subscribe(async (questionTranslation: QuizTranslation) => {
                this.translationService.translateQuestion(questionTranslation, this.activeTranslationQuiz.language)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((translatedQuestion: Quiz | undefined) => {
                        if (translatedQuestion !== undefined) {
                            this.formData.get('question').setValue(translatedQuestion.quiz.text);

                            this.updateFormFromQuestion(translatedQuestion as SingleTextChoiceQuiz);

                            this.quizQuestionMedia.mediaUrl = questionTranslation.quiz.mediaUrl;
                            this.quizQuestionMedia.imageUrl = questionTranslation.quiz.imageUrl;
                            this.quizQuestionMedia.type = questionTranslation.quiz.mediaType;

                            this.quizExplanationMedia.mediaUrl = questionTranslation.explanation?.mediaUrl;
                            this.quizExplanationMedia.imageUrl = questionTranslation.explanation?.imageUrl;
                            this.quizExplanationMedia.type = questionTranslation.explanation?.mediaType;

                            this.alreadyTranslated = true;
                        }

                        this.enable();
                        resolve();
                    });
            });
        });
    };

    public async filesDropped(files: FileHandle[], type: 'question' | 'explanation'): Promise<void> {
        const file = files[0];
        const fileAllowed = this.dragAndDropService.isFileAllowed(file.file);
        if (!fileAllowed.error) {
            if (type === 'question') {
                this.quizQuestionMedia.imageFile = file;
                this.questionFileDrop = true;
                if (this.quizQuestionMedia.type === undefined) {
                    this.quizQuestionMedia.type = 'image';
                }
            }

            if (type === 'explanation') {
                this.quizExplanationMedia.imageFile = file;
                this.explanationFileDrop = true;
                if (this.quizExplanationMedia.type === undefined) {
                    this.quizExplanationMedia.type = 'image';
                }
            }
            this.editorQuizzesService.setQuestionDataChanged(true);
        } else {
            for (const errorText of fileAllowed.text) {
                this.toastr.error(errorText);
            }
        }
    }

    public async removeMedia(type: 'question' | 'explanation'): Promise<void> {
        if (type === 'question') {
            this.quizQuestionMedia = new QuizMediaEditor('question', undefined);
            this.questionFileDrop = false;
        } else {
            this.quizExplanationMedia = new QuizMediaEditor('explanation', undefined);
            this.explanationFileDrop = false;
        }


        this.editorQuizzesService.setQuestionDataChanged(true);
    }

    public keytab(event: KeyboardEvent): void {
        event.preventDefault();
    }

    public getAnswersControls(): AbstractControl[] {
        const answers = this.formData.get('answers') as UntypedFormArray;
        return answers.controls;
    }

    public removeAnswer(answerIndex: number): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        control.removeAt(answerIndex);
        this.disableNewAnswerInput = false;
    }

    public setAsCorrectAnswer(answerIndex: number): void {
        const answers = this.formData.get('answers') as UntypedFormArray;
        const answer = answers.controls[answerIndex] as UntypedFormGroup;
        const isCorrectAnswer = answer.controls['isCorrectAnswer'].value as boolean;
        if (isCorrectAnswer) {
            for (const answerData of answers.controls) {
                if (answers.controls.indexOf(answerData) !== answerIndex) {
                    const data = answerData as UntypedFormGroup;
                    data.controls['isCorrectAnswer'].setValue(false);
                }
            }
        }
    }

    public cancelQuiz(): void {
        if (this.editorQuizzesService.formDataChanged.value) {
            this.editorQuizzesService.quizSaved.next(true);
            this.editorQuizzesService.setQuestionDataChanged(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${this.selectedLearningPackageId}/fragen`],
                {queryParams: this.queryParams});
        }
    }

    public translationNotExist(quizzes: QuizTranslation[]): boolean {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return !quizzes.find(quiz => quiz.language === this.activeTranslationQuiz.language);
    }

    public async saveQuiz(data: FormGroup<SingleChoiceTextQuizFormGroup>): Promise<void> {
        if (this.validator.validateFullQuiz(data)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuiz(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);
            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    public async saveAndCreateNevQuiz(data: FormGroup<SingleChoiceTextQuizFormGroup>): Promise<void> {
        if (this.validator.validateFullQuiz(data)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuizAndAddNew(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);
            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    private static getExplanation(explanationText: string): string {
        let explanation: string;
        if (explanationText !== undefined) {
            const explanationTrim = explanationText.trim();
            explanation = explanationTrim.length > 0 ? explanationTrim : undefined;
        }
        return explanation;
    }

    public addExplanationToAnswer(answerId: number): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        const answerControl = control.controls[answerId] as UntypedFormGroup;
        answerControl.addControl('explanation', new FormControl<string>('', this.validator.explanationFormValidation));

        setTimeout(() => {
            document.getElementById(`Explanation_${answerId}`).focus();
        }, 0);
    }

    private clearQuizForm(): void {
        this.formData = this.createFormGroup();
    }

    private setQuizDataToForm(): void {
        this.formData.controls['question'].setValue(this.quiz.quiz.text);
        this.updateFormFromQuestion(this.quiz);
        this.formData.get('sourceUrl').setValue(this.quiz.sourceUrl);
        this.formData.get('sourceLabel').setValue(this.quiz.sourceLabel);
    }

    private updateFormFromQuestion(question: SingleTextChoiceQuiz): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        control.clear();
        if (question?.answers !== undefined) {
            for (const answer of question.answers) {
                control.push(this.createAnswerFormGroup(answer.answerText, answer?.isCorrectAnswer, answer?.explanation));
            }

            if (control.length < 3) {
                this.createEmptyAnswers(control.length);
            }
        } else {
            this.createEmptyAnswers();
        }

        this.updateNewAnswerDisabledState();
    }

    private createAnswerFormGroup(answerText?: string, isCorrectAnswer?: boolean, explanation?: string): UntypedFormGroup {
        const control = new FormGroup<SingleChoiceTextQuizAnswerFormGroup>({
            isCorrectAnswer: new FormControl<boolean>((isCorrectAnswer !== undefined ? isCorrectAnswer : false), this.validator.isCorrectAnswerValidation),
            answerText: new FormControl<string>((answerText !== undefined ? answerText : ''), this.validator.answerTextFormValidation)
        });
        if (explanation !== undefined) {
            control.addControl('explanation', new FormControl<string>(explanation, this.validator.explanationFormValidation));
        }
        return control;
    }

    private createEmptyAnswers(numberOfExistingAnswers?: number): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        for (let index = (numberOfExistingAnswers !== undefined ? numberOfExistingAnswers : 0); index < 3; index++) {
            if (index === 0) {
                control.push(this.createAnswerFormGroup(undefined, true));
            } else {
                control.push(this.createAnswerFormGroup());
            }
        }
    }

    private async saveCurrentQuizState(data: FormGroup<SingleChoiceTextQuizFormGroup>): Promise<void> {
        const quizEditor = this.buildChangedQuiz(data);
        const quizData = await this.editorQuizCreatorService.createQuizData(quizEditor, this.quiz, this.quizQuestionMedia, this.quizExplanationMedia);
        this.editorQuizzesService.setEditingQuizFormData(quizData, this.validator.validateFullQuiz(this.formData));
        if (this.editorQuizzesService.quizSaved.value) {
            this.editorQuizzesService.quizSaved.next(false);
        }
    }

    private buildChangedQuiz(data: FormGroup<SingleChoiceTextQuizFormGroup>): SingleTextChoiceQuizEditor {
        const answers = data.get('answers')?.value as SingleTextAnswersQuizEditor[];

        const singleTextAnswers = answers.map(value =>
            new SingleTextAnswersQuizEditor(value.isCorrectAnswer, value.answerText, SingleChoiceTextQuizComponent.getExplanation(value.explanation)));

        const questionMedia = this.editorQuizzesService.quizQuestionMediaEditorChanged.value ? this.editorQuizzesService.getQuestionQuizMediaEditor() : this.quizQuestionMedia;
        const quizQuestionForm = new QuizQuestionForm(data.get('question').value, questionMedia);

        const explanationMedia = this.editorQuizzesService.quizExplanationMediaEditorChanged.value ? this.editorQuizzesService.getExplanationQuizMediaEditor() : this.quizExplanationMedia;
        const quizExplanationForm = new QuizExplanationForm(undefined, explanationMedia);

        const sourceUrl = data.controls.sourceUrl.value;
        const sourceLabel = data.controls.sourceLabel.value;

        return new SingleTextChoiceQuizEditor(singleTextAnswers, quizQuestionForm, this.selectedAvailableTags, this.selectedNewTags, quizExplanationForm, sourceUrl, sourceLabel);
    }

    private createNewAnswer(data: string): void {
        if (data !== undefined && data.length > 0) {
            const control = this.formData.get('answers') as UntypedFormArray;
            const controlLength = control.controls.length;

            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (control && controlLength < 5) {
                control.push(this.createAnswerFormGroup(data));

                setTimeout(() => {
                    document.getElementById(`Answar_${(controlLength).toString()}`).focus();
                });

                this.newAnswer.setValue('');
            }
        }

        this.updateNewAnswerDisabledState();
    }

    private updateNewAnswerDisabledState(): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        this.disableNewAnswerInput = control.controls.length >= 5;
    }

    private cleanFiles(): void {
        this.questionFileDrop = undefined;
        this.explanationFileDrop = undefined;
    }

    public async openImageAndMediaPopup(quizMediaElement: 'question' | 'explanation'): Promise<void> {
        if (quizMediaElement === 'question') {
            this.editorQuizzesService.setQuestionQuizMediaEditor(this.quizQuestionMedia);
        } else {
            this.editorQuizzesService.setExplanationQuizMediaEditor(this.quizExplanationMedia);
        }
        this.editorQuizzesService.openImageAndMediaPopup(quizMediaElement);
    }

    public startPasteFlow(): void {
        void this.popupService.open(PasteTextPopupComponent, this.quizTypeData);
    }
}
