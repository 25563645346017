import {Component, OnDestroy, OnInit} from '@angular/core';
import {LearningGroupResource} from '../../core/rest/learning-group-resource.service';
import {Router} from '@angular/router';
import {AnalyticsResource} from '../../core/rest/analytics-rest/analytics-resource.service';
import {AnalyticsGroupOverview} from '../../core/viewmodel/analyticsGroupOverview';
import {OrganizationService} from '../../core/organization.service';
import {TimeSplitMode} from '../../core/rest/analytics-rest/TimeSplitMode';
import {Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {FeaturesService} from '../../core/features/features.service';

@Component({
    selector: 'app-analytics-group-overview',
    templateUrl: './analytics-group-overview.component.html',
    styleUrls: ['./analytics-group-overview.component.scss']
})
export class AnalyticsGroupOverviewComponent implements OnInit, OnDestroy {
    public groups: AnalyticsGroupOverview[];
    public activeOrganizationId$ = new Subscription();

    constructor(public featuresService: FeaturesService,
                private groupRestService: LearningGroupResource,
                private router: Router,
                private analyticsRestService: AnalyticsResource,
                private organizationService: OrganizationService,) {
    }

    public ngOnInit(): void {
        this.activeOrganizationId$ = this.organizationService.activeOrganizationId$.pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter((activeOrganizationId: string) => !!activeOrganizationId),
            switchMap(() => this.analyticsRestService.getOrganizationAnalyticsGroupsOverviews())
        ).subscribe((groups: AnalyticsGroupOverview[]) => this.groups = groups);
    }

    public ngOnDestroy(): void {
        this.activeOrganizationId$.unsubscribe();
    }

    public openGroupOverview(groupId: string): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['analytics', 'lerngruppen', groupId], {
            queryParams: {
                timePeriod: TimeSplitMode.Weekly
            }
        });
    }

    public getValue(value: number, positive?: boolean): { value: number; positive?: boolean; } {
        return {value: value, positive: positive};
    }

}
