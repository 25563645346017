import {GroupActivationTag} from './GroupActivationTag';

export class GroupLearningPackageEditSetting {
    public groupActivationTags: GroupActivationTag[];

    constructor(public id: string,
                public name: string,
                public learningGoal: number,
                public manualActivation: boolean) {
    }
}
