<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Lernende hinzufügen" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content class="mm-add-learner-to-group-popup-content">
        <form [formGroup]="formData" class="micormate-form"
              *ngIf="this.availableLearners !== undefined">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Lernende
                </label>
                <ng-multiselect-dropdown
                    [settings]="learnerDropdownSettings"
                    [data]="availableLearners"
                    formControlName="learners"
                    [placeholder]="'Lernende auswählen'">
                </ng-multiselect-dropdown>
            </div>
        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>
                Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [disabled]="formData.value.learners.length === 0"
                [action]="activateLearnersForGroup" i18n>
                Einfügen
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>


