import {Component, Input} from '@angular/core';
import {Quiz} from '../../../../../../../../core/api-types/quiz';

@Component({
    selector: 'app-free-text-question',
    templateUrl: './free-text-question.component.html',
    styleUrls: ['./free-text-question.component.scss']
})
export class FreeTextQuestionComponent {
    @Input()
    public questionPreview: Quiz;
}
