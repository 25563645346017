<div class="mm-expansion-panel-title-box" (click)="panelOpen = !panelOpen">
    <div class="mm-expansion-panel-title-box-content">
        <div class="mm-expansion-panel-title">{{title}}</div>
        <app-icon icon="angle-down-light" *ngIf="!panelOpen"
                  class="mm-expansion-panel-title-box-content-icon"></app-icon>
        <app-icon icon="angle-up-light" *ngIf="panelOpen"
                  class="mm-expansion-panel-title-box-content-icon"></app-icon>
    </div>
</div>
<div class="mm-expansion-panel-content"
     [class.mm-expansion-panel-content-open]="panelOpen">
    <ng-content></ng-content>
</div>
