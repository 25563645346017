import {Component, Input, OnInit} from '@angular/core';
import {QuestionValidationResult} from '../../../../../../core/editor/types/QuestionConversion/QuestionValidationResult';
import {QuestionValidatorService} from '../../../../../validation/question-validator.service';

@Component({
    selector: 'app-question-validation-errors',
    templateUrl: './question-validation-errors.component.html',
    styleUrls: ['./question-validation-errors.component.scss']
})
export class QuestionValidationErrorsComponent implements OnInit {
    @Input() activeInvalidQuestion: QuestionValidationResult;
    public answersErrors: { answerText: string[]; explanationText?: string[]; }[] = [];

    constructor(public validator: QuestionValidatorService) {
    }

    public ngOnInit(): void {
        this.answersErrors = this.getAnswersErrors();
    }

    public getAnswersErrors(): { answerText: string[]; explanationText?: string[]; }[] {
        if (this.activeInvalidQuestion.propertyErrors.answer !== undefined) {
            const answersErrors = Object.values(this.activeInvalidQuestion.propertyErrors.answers).map((answer: string[]) => {
                return {answerText: answer};
            });
            answersErrors.unshift({answerText: this.activeInvalidQuestion.propertyErrors.answer});
            return answersErrors;
        }

        const answers = this.activeInvalidQuestion.propertyErrors.answers;
        if (answers !== undefined) {
            return Object.values(answers).map((answer: { answerText: string[]; explanation?: string[]; }) => {
                return {answerText: answer['answerText'], explanationText: answer['explanation']};
            });
        }
        return [];
    }

}
