import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EditorQuestionsService} from '../../../../core/editor/editor-questions.service';
import {ActivatedRoute, Router} from '@angular/router';
import {QuizMediaEditor} from '../../../../core/editor/types/QuizMediaEditor';
import {ReflectionFreeTextQuestion} from '../../../../core/editor/types/ReflectionFreeTextQuestion/ReflectionFreeTextQuestion';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../../../core/rest/editor-resource.service';
import {QuizTranslation} from '../../../../core/api-types/quizTranslation';
import {Subject, Subscription, takeUntil} from 'rxjs';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ReflectionFreeTextQuestionValidatorService} from '../../../validation/reflection-free-text-question-validator.service';
import {QuizQuestionForm} from '../../../../core/editor/types/QuizQuestionForm';
import {ReflectionFreeTextQuestionEditor} from '../../../../core/editor/types/ReflectionFreeTextQuestion/ReflectionFreeTextQuestionEditor';
import {EditorQuizCreatorService} from '../../../../core/editor/editor-quiz-creator.service';
import {QuizMakerService} from '../../../../core/editor/quiz-maker.service';
import {FileHandle} from '../../../../core/drag-and-drop/drag-and-drop.directive';
import {DragAndDropService} from '../../../../core/drag-and-drop/drag-and-drop.service';
import {QuestionType} from '../../../../core/editor/question.type';
import {ToastrService} from 'ngx-toastr';
import {filter, take} from 'rxjs/operators';
import {TranslationService} from '../../../../core/rest/translation.service';
import {FeaturesService} from '../../../../core/features/features.service';
import {Quiz} from '../../../../core/api-types/quiz';
import {PopupService} from '../../../../components/popup/popup.service';
import {Tag} from '../../../../core/api-types/Tag';
import {SelectedTag} from '../../../../core/api-types/SelectedTag';
import {SelectedNewTag} from '../../../../core/api-types/SelectedNewTag';

@Component({
    selector: 'app-reflection-free-text-question',
    templateUrl: './reflection-free-text-question.component.html',
    styleUrls: ['./reflection-free-text-question.component.scss']
})
export class ReflectionFreeTextQuestionComponent implements OnInit, OnDestroy {
    @Input() queryParams: { filterText: string; sort: string; filterLanguage?: string; };

    public quiz: ReflectionFreeTextQuestion;
    public quizQuestionMedia: QuizMediaEditor;
    public selectedLearningPackageId: string;
    public languages: string[];
    public activeQuizSubscription = new Subscription();
    public activeTranslationQuiz: QuizTranslation;
    public quizTypeData: QuestionType;
    public questionFileDrop: boolean = false;
    public formDataChangedSubscription = new Subscription();
    public quizQuestionMediaEditorChangedSubscription = new Subscription();
    public learningPackageAvailableTagsSubscription = new Subscription();
    public questionHovering = false;

    public availableTags: Tag[];
    public selectedAvailableTags: SelectedTag[] = [];
    public selectedNewTags: SelectedNewTag[] = [];

    public alreadyTranslated: boolean = false;

    constructor(public quizMakerService: QuizMakerService,
                public editorQuizzesService: EditorQuestionsService,
                public featuresService: FeaturesService,
                private router: Router,
                private popupService: PopupService,
                public editorLearningPackageService: EditorLearningPackageService,
                private editorService: EditorResource,
                public editorQuizService: EditorQuestionService,
                public validator: ReflectionFreeTextQuestionValidatorService,
                private editorQuizCreatorService: EditorQuizCreatorService,
                private activatedRoute: ActivatedRoute,
                private translationService: TranslationService,
                public dragAndDropService: DragAndDropService,
                private toastr: ToastrService) {
    }

    public formData = new UntypedFormGroup({
        question: new UntypedFormControl('', this.validator.questionFormValidation)
    });

    public isDisabled: boolean = false;

    private enable(): void {
        this.formData.enable();
        this.isDisabled = false;
    }

    private disable(): void {
        this.formData.disable();
        this.isDisabled = true;
    }

    public translate: () => Promise<void> = async () => {

        return new Promise(resolve => {
            this.disable();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            this.editorQuizService.activeQuestionMainLanguage$.pipe(take(1)).subscribe(async (questionTranslation: QuizTranslation) => {

                this.translationService.translateQuestion(questionTranslation, this.activeTranslationQuiz.language)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((translatedQuiz: Quiz | undefined) => {
                        if (translatedQuiz !== undefined) {
                            this.formData.get('question').setValue(translatedQuiz.quiz.text);

                            this.quizQuestionMedia.mediaUrl = questionTranslation.quiz.mediaUrl;
                            this.quizQuestionMedia.imageUrl = questionTranslation.quiz.imageUrl;
                            this.quizQuestionMedia.type = questionTranslation.quiz.mediaType;

                            this.alreadyTranslated = true;
                        }

                        this.enable();
                        resolve();
                    });
            });
        });
    };

    public ngOnInit(): void {
        this.selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        if (this.selectedLearningPackageId !== undefined) {
            this.editorService.getLearningPackageLanguages(this.selectedLearningPackageId).subscribe((languages: string[]) => this.languages = languages);
        }
        this.activeQuizSubscription = this.editorQuizService.activeTranslationQuiz$.pipe(filter(quiz => quiz !== undefined)).subscribe((quiz: QuizTranslation) => {
            this.availableTags = undefined;
            this.selectedNewTags = [];
            this.learningPackageAvailableTagsSubscription = this.editorLearningPackageService.learningPackageAvailableTags$.subscribe((availableTags: Tag[]) => this.availableTags = availableTags);
            if (quiz !== undefined) {
                this.activeTranslationQuiz = quiz;
                this.quiz = new ReflectionFreeTextQuestion(quiz.id, quiz.quizId, quiz.quiz, quiz.originalContentHash);
                this.quizQuestionMedia = new QuizMediaEditor('question', this.quiz.quiz?.mediaType, this.quiz.quiz?.mediaUrl, this.quiz.quiz?.imageUrl);
                this.selectedAvailableTags = quiz.tags !== undefined && quiz.tags.length > 0 ? quiz.tags.map(tag => new SelectedTag(tag)) : [];
            }
            this.quizTypeData = this.editorQuizService.quizTypes.find(quizType => quizType.type === this.quiz.quizType);
            this.clearQuizForm();
            this.cleanFiles();
            this.setQuizDataToForm();

            this.formData.valueChanges.subscribe(() => {
                this.editorQuizzesService.setQuestionDataChanged(true);
            });
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.formDataChangedSubscription = this.editorQuizzesService.formDataChanged.subscribe(async (status: boolean) => {
            if (status) {
                await this.saveCurrentQuizState(this.formData);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizQuestionMediaEditorChangedSubscription = this.editorQuizzesService.quizQuestionMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizQuestionMedia = this.editorQuizzesService.getQuestionQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizQuestionMediaEditorChanged.next(false);
            }
        });

        this.editorQuizzesService.tagsChanged$();
    }

    public translationNotExist(quizzes: QuizTranslation[]): boolean {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return !quizzes.find(quiz => quiz.language === this.activeTranslationQuiz.language);
    }

    public async changeLanguage(language: string): Promise<void> {
        if (this.editorQuizzesService.quizSaved.value) {
            this.alreadyTranslated = false;
            void this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                        language: language
                    },
                    queryParamsHandling: 'merge'
                });
        } else {
            const url = `/editor/lernpakete/${this.selectedLearningPackageId}/fragen/${this.quiz.quizId}?language=${language}`;
            await this.editorQuizzesService.openCancelQuestion(url);
        }
    }

    public cancelQuiz(): void {
        if (this.editorQuizzesService.formDataChanged.value) {
            this.editorQuizzesService.quizSaved.next(true);
            this.editorQuizzesService.setQuestionDataChanged(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${this.selectedLearningPackageId}/fragen`], {queryParams: this.queryParams});
        }
    }

    private clearQuizForm(): void {
        this.formData = new UntypedFormGroup({
            question: new UntypedFormControl('', this.validator.questionFormValidation)
        });
    }

    private cleanFiles(): void {
        this.questionFileDrop = undefined;
    }

    private setQuizDataToForm(): void {
        this.formData.controls['question'].setValue(this.quiz.quiz.text);
    }

    public async saveQuiz(data: UntypedFormGroup): Promise<void> {
        if (this.validator.validateFullQuiz(this.formData)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuiz(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);

            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    public async saveAndCreateNewQuiz(data: UntypedFormGroup): Promise<void> {
        if (this.validator.validateFullQuiz(this.formData)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuizAndAddNew(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);

            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    private async saveCurrentQuizState(data: UntypedFormGroup): Promise<void> {
        const quizEditor = this.buildChangedQuiz(data);
        const quizData = await this.editorQuizCreatorService.createQuizData(quizEditor, this.quiz, this.quizQuestionMedia, undefined);
        this.editorQuizzesService.setEditingQuizFormData(quizData, this.validator.validateFullQuiz(this.formData));
        if (this.editorQuizzesService.quizSaved.value) {
            this.editorQuizzesService.quizSaved.next(false);
        }
    }

    private buildChangedQuiz(data: UntypedFormGroup): ReflectionFreeTextQuestionEditor {
        const value = this.editorQuizzesService.quizQuestionMediaEditorChanged.value;
        const questionMedia = value ? this.editorQuizzesService.getQuestionQuizMediaEditor() : this.quizQuestionMedia;
        const quizQuestionForm = new QuizQuestionForm(data.get('question').value as string, questionMedia);
        return new ReflectionFreeTextQuestionEditor(quizQuestionForm, this.selectedAvailableTags, this.selectedNewTags);
    }

    public async filesDropped(files: FileHandle[]): Promise<void> {
        const file = files[0];
        const fileAllowed = this.dragAndDropService.isFileAllowed(file.file);
        if (!fileAllowed.error) {
            this.quizQuestionMedia.imageFile = file;
            this.questionFileDrop = true;
            if (this.quizQuestionMedia.type === undefined) {
                this.quizQuestionMedia.type = 'image';
            }
            this.editorQuizzesService.setQuestionDataChanged(true);
        } else {
            for (const errorText of fileAllowed.text) {
                this.toastr.error(errorText);
            }
        }
    }

    private destroy$ = new Subject();

    public ngOnDestroy(): void {
        this.activeQuizSubscription.unsubscribe();
        this.formDataChangedSubscription.unsubscribe();
        this.quizQuestionMediaEditorChangedSubscription.unsubscribe();
        this.learningPackageAvailableTagsSubscription.unsubscribe();

        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public keytab(event: KeyboardEvent): void {
        event.preventDefault();
    }

    public async openImageAndMediaPopup(): Promise<void> {
        this.editorQuizzesService.setQuestionQuizMediaEditor(this.quizQuestionMedia);
        this.editorQuizzesService.openImageAndMediaPopup('question');
    }

    public async removeMedia(): Promise<void> {
        this.quizQuestionMedia = new QuizMediaEditor('question', undefined);
        this.questionFileDrop = false;
        this.editorQuizzesService.setQuestionDataChanged(true);
    }
}
