import {Injectable} from '@angular/core';
import {TranslationRessource} from './translation-ressource.service';
import {ToastrService} from 'ngx-toastr';
import {Quiz} from '../api-types/quiz';
import {Observable, tap} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    constructor(private translationRessource: TranslationRessource,
                private toastService: ToastrService) {
    }

    public translateQuestion(question: Quiz, targetLocale: string): Observable<Quiz | undefined> {
        return this.translationRessource.translateQuestion(question, targetLocale)
            .pipe(tap(result => {
                if (!result.hasTranslation) {
                    this.toastService.error('Übersetzung nicht möglich.');
                }
            }), map(result => result.question));
    }

}
