import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, switchMap} from 'rxjs';
import {FeedbackDto, FeedbackRatingType, FeedbackStatusType} from '../../../app-editor/quiz-makers/components/feedback-question/FeedbackDto';
import {HttpClient} from '@angular/common/http';
import {ResourceHelper} from '../resource-helper.service';

@Injectable({
    providedIn: 'root'
})
export class EditorFeedbackResource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public getQuestionFeedbacks(learningPackageId: string, questionId: string, onlyOpenFeedback: boolean): Observable<string[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<string[]>(`${url}/editor/learningpackage/${learningPackageId}/question/${questionId}/feedbacks?onlyOpenFeedback=${onlyOpenFeedback}`)));
    }

    public getFeedback(learningPackageId: string, feedbackId: string): Observable<FeedbackDto> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<FeedbackDto>(`${url}/editor/learningpackage/${learningPackageId}/feedback/${feedbackId}`)));
    }

    public async changeFeedbackState(learningPackageId: string, feedbackId: string, status: FeedbackStatusType, rating?: FeedbackRatingType): Promise<void> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.put<void>(`${url}/editor/learningpackage/${learningPackageId}/feedback/${feedbackId}`, {
            status: status,
            rating: rating
        }))));

    }
}
