import {ParamMap} from '@angular/router';

export class QueryParamService {
    public static getQueryParamValueString(params: ParamMap, key: string, defaultValue?: string): string {
        const value = params.get(key);
        // eslint-disable-next-line no-null/no-null
        return value !== undefined && value !== null && value.length > 0 ? value : defaultValue;
    }

    public static getQueryParamValueInt(params: ParamMap, key: string, defaultValue?: number): number {
        const value = params.get(key);
        // eslint-disable-next-line no-null/no-null
        return value !== undefined && value !== null && value.length > 0 ? parseInt(value) : defaultValue;
    }

    public static getQueryParamValue(params: ParamMap, key: string, type: 'string' | 'number'): string | number | undefined {
        const value = params.get(key);
        // eslint-disable-next-line no-null/no-null
        if (value === undefined || value === null || value.length === 0) {
            return undefined;
        }

        switch (type) {
            case 'string':
                return value;
            case 'number':
                return parseInt(value);
            default:
                return undefined;
        }
    }
}
