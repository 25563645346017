<div class="micromate-card">
    <div class="micromate-card-header">
        <h1 i18n>Einstellungen</h1>
        <app-button type="primary" (click)="saveSettings()"
                    [disabled]="(organizationAdminSettingService.organizationAdminSettingSaved$ | async) || (!formData.value.channelMicrosoftTeams && !formData.value.channelLernMicromate)"
                    i18n>Speichern
        </app-button>
    </div>


    <div class="micromate-card-content" *ngIf="organizationAdminOverview !== undefined">
        <form [formGroup]="formData">
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>
                    Beitritt über Organisationscode:
                </div>
                <app-switch-toggle formControlName="joinWithOrganizationCode"></app-switch-toggle>
            </div>
            <div class="micromate-organization-info"
                 *ngIf="formData.value.joinWithOrganizationCode && organizationCode">
                <div class="micromate-organization-info-title" i18n>
                    Organisationscode:
                </div>
                <div class="micromate-organization-info-data">
                    {{organizationCode}}
                    <app-button [action]="generateNewOrganizationCode"
                                class="mm-organization-settings-button"
                                type="secondary"
                                icon="arrows-rotate-light"></app-button>
                    <app-button (click)="copyOrganizationCode()"
                                class="mm-organization-settings-button"
                                type="secondary"
                                title="Organisationscode kopieren"
                                i18n-title
                                icon="copy-light"></app-button>
                </div>
            </div>
            <div class="micromate-organization-info"
                 *ngIf="formData.value.joinWithOrganizationCode && organizationCode">
                <div class="micromate-organization-info-title" i18n>
                    Registrierungslink:
                </div>
                <div class="micromate-organization-info-data">
                    <a href="{{createRegistrationLink()}}"
                       target="_blank">{{createRegistrationLink()}}</a>
                    <app-button (click)="copyRegistrationLink()"
                                class="mm-organization-settings-button"
                                type="secondary"
                                title="Registrierungslink kopieren"
                                i18n-title
                                icon="copy-light"></app-button>
                </div>
            </div>

            <div class="micromate-organization-overview-group">
                <ng-container
                    *ngIf="organizationAdminOverview.domains !== undefined && organizationAdminOverview.domains.length > 0">
                    <div class="micromate-organization-info">
                        <div class="micromate-organization-info-title" i18n>
                            Nur Lernende mit verifizierter Domain zulassen:
                        </div>
                        <app-switch-toggle
                            [disabled]="!organizationAdminOverview.domainVerification && !organizationAdminOverview.learnerDomainsValid"
                            formControlName="domainVerification"></app-switch-toggle>
                    </div>
                    <app-warning-box class="mm-domain-settings-warning-box"
                                     *ngIf="!organizationAdminOverview.domainVerification && !organizationAdminOverview.learnerDomainsValid"
                                     content="Diese Option kann nur aktiviert werden, wenn keine aktiven Benutzer eine unverifizierte Domain verwenden."
                                     i18n-content></app-warning-box>
                </ng-container>

                <div class="micromate-organization-info">
                    <div class="micromate-organization-info-title" i18n>
                        Verifizierte Domains:
                    </div>
                    <div class="micromate-organization-info-data">
                        {{organizationAdminOverview.domains !== undefined && organizationAdminOverview.domains.length > 0 ? organizationAdminOverview.domains.join(', ') : '-'}}
                    </div>
                </div>
            </div>

            <div class="micromate-organization-overview-group" *ngIf="organizationAdminOverview.licence !== undefined">
                <div class="micromate-organization-info">
                    <div class="micromate-organization-info-title micromate-organization-info-title-align-top" i18n>
                        Aktive Kanäle:
                    </div>
                    <div class="mm-organization-channels">
                        <div class="micromate-organization-info">
                            <div class="micromate-organization-info-title" i18n>
                                Microsoft Teams:
                            </div>
                            <app-switch-toggle formControlName="channelMicrosoftTeams"></app-switch-toggle>
                        </div>
                        <div class="micromate-organization-info">
                            <div class="micromate-organization-info-title" i18n>
                                Learn Micromate (Web):
                            </div>
                            <app-switch-toggle formControlName="channelLernMicromate"></app-switch-toggle>
                        </div>
                        <app-error-box class="mm-organization-info-error-box"
                                       *ngIf="!formData.value.channelMicrosoftTeams && !formData.value.channelLernMicromate"
                                       header="Kein Kanal ausgewählt" i18n-header
                                       content="Es muss mindestens ein Kanal aktiv sein." i18n-content>
                        </app-error-box>
                    </div>
                </div>
                <div class="micromate-organization-info"
                     *ngIf="formData.value.channelMicrosoftTeams || formData.value.channelLernMicromate">
                    <div class="micromate-organization-info-title" i18n>
                        Hauptkanal:
                    </div>
                    <select class="micromate-single-selection" formControlName="mainChannel"
                            [class.micromate-single-selection-disabled]="!formData.value.channelMicrosoftTeams || !formData.value.channelLernMicromate">
                        <option *ngIf="formData.value.channelMicrosoftTeams" [ngValue]="'microsoft-teams'"
                                class="micromate-single-selection-option" i18n>
                            Microsoft Teams
                        </option>
                        <option *ngIf="formData.value.channelLernMicromate" [ngValue]="'learn-micromate'"
                                class="micromate-single-selection-option" i18n>
                            Learn Micromate (Web)
                        </option>
                    </select>
                </div>
            </div>

            <div class="micromate-organization-overview-group"
                 *ngIf="organizationAdminOverview.features.ai.type === 'custom-llm'">
                <div *ngIf="isSuperAdmin$ | async; else aiFeatureOverview"
                     [formGroup]="formData.controls.aiCredential">
                    <div class="micromate-organization-info">
                        <div class="micromate-organization-info-title" i18n>
                            Large Language Model:
                        </div>
                        <select class="micromate-single-selection" formControlName="languageModel">
                            <option [ngValue]="'OpenAI'"
                                    class="micromate-single-selection-option" i18n>
                                OpenAI
                            </option>
                            <option [ngValue]="'AzureOpenAI'"
                                    class="micromate-single-selection-option" i18n>
                                Azure OpenAI
                            </option>
                        </select>
                    </div>
                    <app-warning-box class="mm-organization-ai-credential-warning-box"
                                     *ngIf="formData.controls.aiCredential.controls.languageModel.getRawValue() === 'OpenAI' && formData.controls.aiCredential.controls.apiKey.getRawValue().length === 0"
                                     content="Wenn kein API-Key angegeben ist, sind alle AI-Funktionen deaktiviert."
                                     i18n-content></app-warning-box>
                    <app-warning-box class="mm-organization-ai-credential-warning-box"
                                     *ngIf="formData.controls.aiCredential.controls.languageModel.getRawValue() === 'AzureOpenAI' && !isAzureOpenAICredentialValid()"
                                     content="Die AI-Funktionen sind deaktiviert, wenn nicht alle Konfigurationsparameter eingetragen sind."
                                     i18n-content></app-warning-box>
                    <app-warning-box class="mm-organization-ai-credential-warning-box"
                                     *ngIf="isLanguageModelCredentialChanged()"
                                     content="Micromate Super-Admin Warnung: Überprüfen Sie, ob die Konfiguration nicht mit anderen Organisationen geteilt wird. Wenn dieser Wert gemeinsam genutzt wird, müssen die anderen Organisationen direkt in der Datenbank repariert werden. Nach einem Wechsel des Large Language Models müssen sämtliche Ressourcen neu hochgeladen warden, da die Embeddings je nach Plattform nicht übereinstimmen!"
                                     i18n-content></app-warning-box>
                    <div class="micromate-organization-info">
                        <div class="micromate-organization-info-title" i18n>
                            API-Key:
                        </div>
                        <div class="micromate-organization-info-data mm-organization-admin-input">
                            <input type="text" class="micormate-form-input" formControlName="apiKey">
                        </div>
                    </div>
                    <ng-container
                        *ngIf="formData.controls.aiCredential.controls.languageModel.getRawValue() === 'AzureOpenAI'">
                        <div class="micromate-organization-info">
                            <div class="micromate-organization-info-title" i18n>
                                API-URL:
                            </div>
                            <div class="micromate-organization-info-data mm-organization-admin-input">
                                <input type="text" class="micormate-form-input" formControlName="apiUrl">
                            </div>
                        </div>
                        <div class="micromate-organization-info">
                            <div class="micromate-organization-info-title" i18n>
                                Bereitstellungsname Completion Model:
                            </div>
                            <div class="micromate-organization-info-data mm-organization-admin-input">
                                <input type="text" class="micormate-form-input"
                                       formControlName="completionModelDeploymentName">
                            </div>
                        </div>
                        <div class="micromate-organization-info">
                            <div class="micromate-organization-info-title" i18n>
                                Bereitstellungsname Embedding Model:
                            </div>
                            <div class="micromate-organization-info-data mm-organization-admin-input">
                                <input type="text" class="micormate-form-input"
                                       formControlName="embeddingModelDeploymentName">
                            </div>
                        </div>
                    </ng-container>
                </div>

                <ng-template #aiFeatureOverview>
                    <div>
                        <div class="micromate-organization-info">
                            <div class="micromate-organization-info-title" i18n>
                                Large Language Model:
                            </div>
                            <div class="micromate-organization-info-data"
                                 *ngIf="formData.controls.aiCredential.controls.languageModel.getRawValue() === 'OpenAI'"
                                 i18n>
                                OpenAI
                            </div>
                            <div class="micromate-organization-info-data"
                                 *ngIf="formData.controls.aiCredential.controls.languageModel.getRawValue() === 'AzureOpenAI'"
                                 i18n>
                                Azure OpenAI
                            </div>
                        </div>

                        <div class="micromate-organization-info">
                            <div class="micromate-organization-info-title" i18n>
                                API-Key:
                            </div>
                            <div class="micromate-organization-info-data">
                                {{getAiFeatureOverviewValue(formData.controls.aiCredential.controls.apiKey.getRawValue())}}
                            </div>
                        </div>

                        <div class="micromate-organization-info"
                             *ngIf="formData.controls.aiCredential.controls.languageModel.getRawValue() === 'AzureOpenAI'">
                            <div class="micromate-organization-info-title" i18n>
                                API-URL:
                            </div>
                            <div class="micromate-organization-info-data">
                                {{getAiFeatureOverviewValue(formData.controls.aiCredential.controls.apiUrl.getRawValue())}}
                            </div>
                        </div>

                        <div class="micromate-organization-info"
                             *ngIf="formData.controls.aiCredential.controls.languageModel.getRawValue() === 'AzureOpenAI'">
                            <div class="micromate-organization-info-title" i18n>
                                Bereitstellungsname Completion Model:
                            </div>
                            <div class="micromate-organization-info-data">
                                {{getAiFeatureOverviewValue(formData.controls.aiCredential.controls.completionModelDeploymentName.getRawValue())}}
                            </div>
                        </div>

                        <div class="micromate-organization-info"
                             *ngIf="formData.controls.aiCredential.controls.languageModel.getRawValue() === 'AzureOpenAI'">
                            <div class="micromate-organization-info-title" i18n>
                                Bereitstellungsname Embedding Model:
                            </div>
                            <div class="micromate-organization-info-data">
                                {{getAiFeatureOverviewValue(formData.controls.aiCredential.controls.embeddingModelDeploymentName.getRawValue())}}
                            </div>
                        </div>
                    </div>
                </ng-template>
            </div>


            <div class="micromate-organization-overview-group" *ngIf="organizationAdminOverview.licence !== undefined">
                <div class="micromate-organization-info">
                    <div class="micromate-organization-info-title" i18n>
                        Erworbene Lizenzen:
                    </div>
                    <div class="micromate-organization-info-data"
                         [class.micromate-organization-unlimited-available-licence]="organizationAdminOverview.licence.availableLicence === undefined">
                        {{organizationAdminOverview.licence.licence === undefined ? '&#8734;' : organizationAdminOverview.licence.licence}}
                    </div>
                </div>

                <div class="micromate-organization-info">
                    <div class="micromate-organization-info-title" i18n>
                        Verwendete Lizenzen:
                    </div>
                    <div class="micromate-organization-info-data">
                        {{organizationAdminOverview.licence.usedLicence}}
                    </div>
                </div>

                <div class="micromate-organization-info">
                    <div class="micromate-organization-info-title" i18n>
                        Verfügbare Lizenzen:
                    </div>
                    <div class="micromate-organization-info-data"
                         [class.micromate-organization-negative-available-licence]="organizationAdminOverview.licence.availableLicence !== undefined && organizationAdminOverview.licence.availableLicence<0"
                         [class.micromate-organization-unlimited-available-licence]="organizationAdminOverview.licence.availableLicence === undefined">
                        {{organizationAdminOverview.licence.availableLicence === undefined ? '&#8734;' : organizationAdminOverview.licence.availableLicence}}
                    </div>
                </div>
            </div>

            <div
                class="micromate-organization-info micromate-organization-overview-group micromate-organization-info-hint">
                <div class="micromate-organization-info-hint-text" i18n>
                    Für den Erwerb von zusätzlichen Lizenzen kontaktieren Sie
                </div>
                <a href="mailto:hello@micromate.ai">hello@micromate.ai</a>.
            </div>
        </form>
    </div>
</div>
