import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OrganizationSetting} from '../../core/api-types/organizationSetting';
import {Features} from '../../core/features/Features';
import {CancelSavingPopupData} from '../../components/cancel-saving-popup/CancelSavingPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {CancelSavePopupResult} from '../../components/cancel-saving-popup/CancelSavePopupResult';
import {CancelSavingPopupComponent} from '../../components/cancel-saving-popup/cancel-saving-popup.component';
import {PopupService} from '../../components/popup/popup.service';
import {OrganizationResources} from '../../core/rest/organization-resources.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class OrganizationSettingService {
    public organizationSettingSaved = new BehaviorSubject<boolean>(true);
    public organizationSettingSaved$ = this.organizationSettingSaved.asObservable();

    public organizationSetting: OrganizationSetting;

    constructor(private popupService: PopupService,
                private organizationRestService: OrganizationResources,
                private router: Router,
                private toastr: ToastrService) {
    }

    public setOrganizationSettingSavedStatus(value: boolean): void {
        this.organizationSettingSaved.next(value);
    }

    public async checkIfOrganizationSettingIsSaved(url?: string): Promise<boolean> {
        if (this.organizationSettingSaved.value) {
            return true;
        }
        const cancelSavingPopupData = new CancelSavingPopupData(await this.getAllowToSave());
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<CancelSavingPopupData, CancelSavePopupResult>(CancelSavingPopupComponent, cancelSavingPopupData, popupOptions);
        if (popupServiceResult === 'Save') {
            await this.saveSettings(url);
        } else if (popupServiceResult === 'Discard') {
            this.redirect(url);
        }
        return false;
    }


    private async getAllowToSave(): Promise<boolean> {
        const settings = this.getSetting();
        if (settings.domains !== undefined) {
            const data = await this.organizationRestService.changeDomainsForOrganization(settings.organizationId, settings.domains);
            return data.validationResult === 'valid' ? this.validateDomains(settings.domains) : false;
        }
        return true;
    }

    public async saveSettings(url: string): Promise<void> {
        if (this.organizationSetting.features !== undefined) {
            try {
                await this.organizationRestService.saveOrganizationFeatures(this.organizationSetting.organizationId, this.organizationSetting.features);
            } catch (e) {
                this.toastr.error($localize`Features konnten nicht geändert werden`);
            }
        }
        await this.saveLicence(url);
    }

    private async saveLicence(url: string): Promise<void> {
        if (this.organizationSetting.licence !== undefined) {
            try {
                await this.organizationRestService.saveLicenceForOrganization(this.organizationSetting.organizationId, this.organizationSetting.licence);
            } catch (e) {
                this.toastr.error($localize`Licence konnten nicht wurde geändert`);
            }
        }
        await this.saveDomains(url);
    }

    private async saveDomains(url: string): Promise<void> {
        if (this.organizationSetting.domains !== undefined) {
            try {
                await this.organizationRestService.changeDomainForOrganization(this.organizationSetting.organizationId, this.organizationSetting.domains);
                this.toastr.success($localize`Änderungen gespeichert`);
            } catch (e) {
                this.toastr.error($localize`Der Beitrittsmodus konnten nicht wurde geändert`);
            }
        } else {
            this.toastr.success($localize`Änderungen gespeichert`);
        }
        this.redirect(url);
    }

    private redirect(url: string): void {
        this.setOrganizationSettingSavedStatus(true);
        this.setSetting(undefined, undefined, undefined, undefined);
        void this.router.navigate([url]);
    }

    public setSetting(organizationId: string, features: Features, domains: string[], licence: number): void {
        this.organizationSetting = {
            organizationId: organizationId,
            features: features,
            domains: domains,
            licence: licence
        };
    }

    public getSetting(): OrganizationSetting {
        return this.organizationSetting;
    }

    public validateDomains(domains: string[]): boolean {
        const regex = /^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/gm;
        return domains.every(domain => domain.match(regex));
    }

    public getDomains(domains: string): string[] {
        if (domains !== undefined) {
            return domains.split(',').map(domain => domain.trim().toLowerCase()).filter(domain => domain.length > 0);
        }
        return undefined;
    }
}
