<div class="mm-editor-question-feedback">
    <div class="mm-editor-question-feedback-header">
        <h2 i18n>Feedbacks</h2>
        <app-icon icon="message-pen-light" class="mm-editor-question-feedback-header-icon"></app-icon>
    </div>
    <div class="mm-editor-question-feedback-filters">
        <label class="mm-editor-question-feedback-filters-button"
               [title]="getFeedbackFilterTitle(openFeedbackFilter.value)">
            <input type="checkbox" class="mm-editor-question-feedback-filters-button-input"
                   [formControl]="openFeedbackFilter">
            <p *ngIf="!openFeedbackFilter.getRawValue()" i18n>nur offene anzeigen</p>
            <p *ngIf="openFeedbackFilter.getRawValue()" i18n>alle anzeigen</p>
        </label>
    </div>
    <app-feedback-preview *ngIf="numberOfPages !== undefined && numberOfPages > 0"
                          [feedback]="selectedFeedback$ | async"
                          (reopenClicked)="reopenFeedback($event)"
                          (acceptClicked)="acceptFeedback($event)"
                          class="mm-editor-question-feedback-preview"></app-feedback-preview>
    <div class="mm-editor-question-feedback-no-feedback" i18n
         *ngIf="numberOfPages === undefined || numberOfPages === 0">
        Kein Feedback vorhanden
    </div>


    <app-pagination class="mm-feedback-pagination"
        [pageNumber]="pageNumber"
                    [numberOfPages]="numberOfPages"
                    [testPosition]="'middle'"
                    (redirectToNextEvent)="navigateToFeedback($event)"></app-pagination>
</div>
