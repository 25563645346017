import {Component, Input, OnInit} from '@angular/core';
import {LearningPackageResource} from '../../../core/rest/learning-package-resource.service';
import {Organization} from '../../../core/api-types/organization';
import {OrganizationResources} from '../../../core/rest/organization-resources.service';
import {FormControl, FormGroup} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {AdminService} from '../../../core/admin/admin.service';
import {LearningPackageForOrganization} from './LearningPackageForOrganization';
import {Popup} from '../../../components/popup/Popup';
import {LearningPackageOrganizationPopupData} from './LearningPackageOrganizationPopupData';
import {PopupService} from '../../../components/popup/popup.service';
import {LearningPackageOrganizationPopupResult} from './LearningPackageOrganizationPopupResult';

@Component({
    selector: 'app-learning-package-organization-connection',
    templateUrl: './learning-package-organization-connection.component.html',
    styleUrls: ['./learning-package-organization-connection.component.scss']
})
export class LearningPackageOrganizationConnectionComponent implements Popup<LearningPackageOrganizationPopupData>, OnInit {
    @Input()
    public data: LearningPackageOrganizationPopupData;

    public learningPackageId: string;
    public availableOrganizations: Organization[];
    public allOrganizations: Organization[];
    public formData = new FormGroup<{ organizations: FormControl<LearningPackageForOrganization[]>; }>({
        organizations: new FormControl<LearningPackageForOrganization[]>([])
    });

    public organizationsDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: '_id',
        textField: 'name',
        enableCheckAll: false,
        allowSearchFilter: true,
        searchPlaceholderText: 'Organisation suchen',
        noDataAvailablePlaceholderText: 'Keine Organisation verfügbar',
        closeDropDownOnSelection: false
    };

    constructor(private learninPackageService: LearningPackageResource,
                private organizationRestService: OrganizationResources,
                private adminService: AdminService,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.learningPackageId = this.data.learningPackageId;
        this.organizationRestService.getOrganizations().subscribe(allOrganizationsData => {
            this.allOrganizations = allOrganizationsData;
            this.learninPackageService.getAvailableOrganizations(this.learningPackageId).subscribe(assignedOrganizationsData => {
                this.availableOrganizations = assignedOrganizationsData;
                this.formData.setValue({organizations: this.availableOrganizations.map(data => new LearningPackageForOrganization(data._id, data.name))});
            });
        });
    }

    private createLearPackageOrganizationData(organizations: LearningPackageForOrganization[]): { organizationId: string; available: boolean; }[] {
        const learPackageOrganizationData = organizations.map(data => {
            return {organizationId: data._id, available: true};
        });
        for (const availableOrganization of this.availableOrganizations) {
            if (learPackageOrganizationData.find(data => data.organizationId === availableOrganization._id) === undefined) {
                learPackageOrganizationData.push({organizationId: availableOrganization._id, available: false});
            }
        }
        return learPackageOrganizationData;
    }

    public activateLearningPackageForOrganization: () => Promise<void> = async () => {
        return new Promise(resolve => {
            const organizations = this.formData.value.organizations;
            this.learninPackageService.activateLearningPackageForOrganizations(this.learningPackageId, this.createLearPackageOrganizationData(organizations)).subscribe(
                () => {
                    this.popupService.close<LearningPackageOrganizationPopupResult>('connectedOrganization');
                    this.formData.controls['organizations'].setValue([]);
                    resolve();
                }
            );
        });
    };

    public cancel(): void {
        this.popupService.close();
    }
}
