import {Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LearningPackage} from '../api-types/learningPackage';
import {Organization} from '../api-types/organization';
import {ResourceHelper} from './resource-helper.service';
import {environment} from '../../../environments/environment';
import {LearningPackageGroupOverview} from '../api-types/learningPackageGroupOverview';
import {ListPaginationRequest} from '../api-types/listPaginationRequest';
import {ListPagination} from '../api-types/listPagination';
import {LearningPackageAdminOverview} from '../api-types/learningPackageAdminOverview';
import {GroupLearningPackageSettings} from '../api-types/GroupLearningPackageSettings';

@Injectable({
    providedIn: 'root'
})
export class LearningPackageResource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public getAllActive(listPaginationRequest: ListPaginationRequest): Observable<ListPagination<LearningPackageAdminOverview>> {
        return this.http.get<ListPagination<LearningPackageAdminOverview>>(`${environment.serverUrl}/api/website/admin/learningpackage?filter=${listPaginationRequest.filter}&pageNumber=${listPaginationRequest.pageNumber}&pageAmount=${listPaginationRequest.pageAmount}`);
    }

    public getOneForAssigment(learningPackageId: string): Observable<LearningPackage> {
        return this.http.get<LearningPackage>(`${environment.serverUrl}/api/website/admin/learningpackage/${learningPackageId}`);
    }

    public getAvailableOrganizations(learningPackageId: string): Observable<Organization[]> {
        return this.http.get<Organization[]>(`${environment.serverUrl}/api/website/admin/learningpackage/${learningPackageId}/organizations`);
    }

    public activateLearningPackageForOrganizations(learningPackageId: string, organizations: { organizationId: string; available: boolean; }[]): Observable<string> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.http.post(`${environment.serverUrl}/api/website/admin/learningpackage/${learningPackageId}`, organizations, {responseType: 'text'});
    }

    public getAllActiveForGroup(groupId: string): Observable<LearningPackageGroupOverview[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<LearningPackageGroupOverview[]>(`${url}/learningpackage/info/group/${groupId}`)));
    }

    public getAllAvailableForGroup(groupId: string): Observable<{ _id: string; name: string; }[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ _id: string; name: string; }[]>(`${url}/learningpackage/info/group/${groupId}/available`)));
    }

    public activateLearningPackageForGroup(groupId: string, learningPackages: string, groupLearningPackageSettings: GroupLearningPackageSettings): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/learningpackage/info/group/${groupId}`, {
            learningPackageId: learningPackages,
            groupLearningPackageSettings: groupLearningPackageSettings
        }, {responseType: 'text'})));
    }

    public deactivateLearningPackageForGroup(groupId: string, learningPackageId: string): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/learningpackage/info/group/${groupId}/deactivate`, {learningPackageId: learningPackageId}, {responseType: 'text'})));
    }
}
