import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Organization} from '../../core/api-types/organization';
import {OrganizationResources} from '../../core/rest/organization-resources.service';
import {AdminService} from '../../core/admin/admin.service';
import {Router} from '@angular/router';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {ListPagination} from '../../core/api-types/listPagination';
import {TablePaginationParamsService} from '../../core/table-pagination-params.service';
import {combineLatest, Subscription} from 'rxjs';
import {OrganizationService} from '../../core/organization.service';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {GroupService} from '../../core/group.service';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {PopupService} from '../../components/popup/popup.service';
import {ToastrService} from 'ngx-toastr';
import {OrganizationFormPopupResult} from './organization-form/OrganizationFormPopupResult';
import {OrganizationFormComponent} from './organization-form/organization-form.component';

@Component({
    selector: 'app-organization',
    templateUrl: './organization.component.html',
    styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit, OnDestroy {
    public organizations: Organization[];
    public paginationParams: ListPaginationRequest;
    public listPagination: ListPagination<Organization>;
    public navigationUrl: string[] = ['administration', 'organisationen'];
    public subscription = new Subscription();
    public paginationParams$ = new Subscription();
    public getOrganizationsList$ = new Subscription();

    @ViewChild('tableContentRef') tableContentRef: ElementRef;

    constructor(private organizationRestService: OrganizationResources,
                private adminService: AdminService,
                private router: Router,
                private tablePaginationParamsService: TablePaginationParamsService,
                private organizationService: OrganizationService,
                private editorLearningPackageService: EditorLearningPackageService,
                private groupService: GroupService,
                private popupService: PopupService,
                private toastr: ToastrService) {
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.paginationParams$.unsubscribe();
        this.tablePaginationParamsService.unSubscribeActiveRoute();
        this.getOrganizationsList$.unsubscribe();
    }

    public ngOnInit(): void {
        this.tablePaginationParamsService.updateFromActivatedRouteParams();
        this.paginationParams$ = this.tablePaginationParamsService.paginationParams$.subscribe((paginationParams: ListPaginationRequest) => {
            this.getOrganization(paginationParams);
        });


        this.subscription = combineLatest([this.tablePaginationParamsService.paginationParams$, this.adminService.addedNewOrganization$]).subscribe(([paginationParams, status]: [ListPaginationRequest, boolean]) => {
            if (status) {
                this.getOrganization(paginationParams);
            }
        });
    }

    private getOrganization(paginationParams: ListPaginationRequest): void {
        this.paginationParams = paginationParams;
        this.getOrganizationsList$ = this.organizationRestService.getOrganizationsList(this.paginationParams).subscribe((listPagination: ListPagination<Organization>) => {
            this.listPagination = listPagination;
            this.organizations = listPagination.data;
            if (this.tableContentRef !== undefined) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.tableContentRef.nativeElement.scrollTop = 0;
            }
        });
    }

    public async openNewOrganizationForm(): Promise<void> {
        const popupOptions = new PopupOptions();
        const result = await this.popupService.open<undefined, OrganizationFormPopupResult>(OrganizationFormComponent, undefined, popupOptions);
        if (result === 'creationError') {
            this.toastr.error($localize`Organisation hinzugefügt fehlgeschlagen`);
        }
    }

    public redirectToOrganizationCoinfig(organizationId: string): void {
        void this.router.navigate(['administration', 'organisationen', organizationId]);
    }

    public setActiveOrganization(organizationId: string): void {
        this.organizationService.setActiveOrganization(organizationId);
        this.editorLearningPackageService.cleanActiveLearningPackage();
        this.groupService.cleanActiveGroup();
        void this.router.navigate(['/']);
    }

}
