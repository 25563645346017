import {QuizQuestion} from '../editor/types/QuizQuestion';
import {QuizType} from '../editor/types/QuizType';
import {Answer} from './answer';
import {QuizExplanation} from '../editor/types/QuizExplanation';

export class QuizTranslation {
    constructor(public leaningPackageId: string,
                public mainLeaningPackageId: string,
                public mainLanguage: string,
                public language: string,
                public id: string,
                public quizId: string,
                public quiz: QuizQuestion,
                public quizType: QuizType,
                public tags: string[],
                public answer?: string,
                public answers?: Answer[] | string[],
                public explanation?: QuizExplanation,
                public positiveAnswer?: Answer,
                public negativeAnswer?: Answer,
                public sourceUrl?: string,
                public sourceLabel?: string,
                public originalContentHash?: string) {
    }
}
