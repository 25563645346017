import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {GroupSettingsService} from './group-settings.service';
import {LearningGroupResource} from '../../core/rest/learning-group-resource.service';
import {Group} from '../../core/api-types/group';
import {GroupService} from '../../core/group.service';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FormCustomValidation} from '../../core/form-custom-validation';

@Component({
    selector: 'app-group-setting',
    templateUrl: './group-setting.component.html',
    styleUrls: ['./group-setting.component.scss']
})
export class GroupSettingComponent implements OnInit, OnDestroy {
    public group: Group;
    private activeGroup$ = new Subscription();

    public formData = new FormGroup<{ name: FormControl<string>; }>({
        name: new FormControl<string>('', [FormCustomValidation.trimmedRequired, FormCustomValidation.trimmedMaxLengthValidator(50)]),
    });

    constructor(public groupSettingsService: GroupSettingsService,
                private groupRestService: LearningGroupResource,
                private groupService: GroupService,
                private toastr: ToastrService) {
    }

    @HostListener('window:beforeunload', ['$event'])
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    unloadHandler($event) {
        if (!this.groupSettingsService.groupSettingSaved.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return $event.returnValue = 'Your changes will not be saved';
        }
    }

    public ngOnDestroy(): void {
        this.activeGroup$.unsubscribe();
    }

    public ngOnInit(): void {
        this.activeGroup$ = this.groupService.activeGroup$.subscribe((activeGroup: Group) => {
            if (activeGroup !== undefined) {
                this.group = activeGroup;
                this.formData.controls['name'].setValue(this.group.name);
                this.formData.get('name').valueChanges.subscribe((name: string) => {
                    if (name !== this.group.name) {
                        const newGroup: Group = this.group;
                        newGroup.name = name;
                        this.groupSettingsService.setGroup(newGroup);
                        this.groupSettingsService.setGroupSettingSavedStatus(false);
                    }

                });
            }
        });
    }

    public editGroupName(group: { name: string; }): void {
        if (!this.groupSettingsService.groupSettingSaved.value) {
            const groupName = group.name.trim();
            this.groupRestService.editGroup(this.group._id, groupName).subscribe(
                () => {
                    this.toastr.success($localize`Lerngruppe wurde gespeichert`);
                    this.formData.controls.name.setValue(groupName);
                    this.groupSettingsService.setGroupSettingSavedStatus(true);
                    this.groupSettingsService.setGroup(undefined);
                }, () => {
                    this.toastr.error($localize`Könnte nicht gespeichert werden`);
                });
        }
    }
}
