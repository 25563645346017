import {Component, OnDestroy, OnInit} from '@angular/core';
import {filter, switchMap, takeWhile} from 'rxjs/operators';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {Observable, Subscription, tap, timer} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Resource} from '../../core/api-types/Resource';
import {AiRessource} from '../../core/rest/ai-ressource.service';
import {Router} from '@angular/router';
import {NewResourcePopupComponent} from './new-resource-popup/new-resource-popup.component';
import {PopupService} from '../../components/popup/popup.service';
import {NewResourcePopupService} from './new-resource-popup/new-resource-popup.service';
import {ConfirmationPopupData} from '../../components/confirmation-popup/ConfirmationPopupData';
import {ConfirmationPopupComponent} from '../../components/confirmation-popup/confirmation-popup.component';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {ConfirmationPopupResult} from '../../components/confirmation-popup/ConfirmationPopupResult';
import {UpdateResourceRequestDto} from '../../core/rest/update-resource-request.dto';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit, OnDestroy {
    public selectedLearningPackageId: string;
    public resources: Resource[];
    public hasNoResources: boolean = false;
    public hasReachedResourceLimit: boolean = true;
    public isSuperAdmin$: Observable<boolean> = this.authUntilUserRoleService.hasSuperAdminRole();

    private resourceSubscriptionTriggered = false;
    public selectedLearningPackageId$ = new Subscription();
    public resourcesSubscription$ = new Subscription();

    constructor(public editorLearningPackageService: EditorLearningPackageService,
                private editorService: EditorResource,
                private toastr: ToastrService,
                private aiResource: AiRessource,
                private router: Router,
                private authUntilUserRoleService: AuthUntilUserRoleService,
                private popupService: PopupService,
                private resourceWizardService: NewResourcePopupService) {
    }

    public ngOnInit(): void {
        this.selectedLearningPackageId$ = this.editorLearningPackageService.selectedLearningPackageId$.pipe(
            filter((selectedLearningPackageId: string) => selectedLearningPackageId !== undefined))
            .subscribe((selectedLearningPackageId: string) => {
                this.hasNoResources = false;
                this.selectedLearningPackageId = selectedLearningPackageId;
                this.getResources(selectedLearningPackageId);
            });
    }

    private getResources(selectedLearningPackageId: string): void {
        if (this.resourceSubscriptionTriggered) {
            this.resourcesSubscription$.unsubscribe();
        }
        this.resourceSubscriptionTriggered = true;
        this.resourcesSubscription$ = timer(0, 2000).pipe(
            switchMap(() => this.aiResource.getResources(selectedLearningPackageId)),
            tap((resources: Resource[]) => {
                this.hasNoResources = resources.length === 0;
                this.hasReachedResourceLimit = resources.length === 10;
            }),
            takeWhile((resources: Resource[]) => {
                return resources.length > 0 && resources.some(resource => !this.isResourceReady(resource));
            }, true)
        ).subscribe(resources => {
            this.resources = resources;
        });
    }

    public ngOnDestroy(): void {
        this.selectedLearningPackageId$.unsubscribe();
        this.resourcesSubscription$.unsubscribe();
    }

    public showNewResourcePopup(): void {
        if (!this.hasReachedResourceLimit) {
            this.resourceWizardService.currentStep = 'import';
            const popupOptions = new PopupOptions('custom', 'closableWithoutEsc', 1000, 600);
            void this.popupService.open(NewResourcePopupComponent, undefined, popupOptions);
        }
    }

    public isResourceReady(resource: Resource): boolean {
        return resource.indexState === 'indexed' && resource.questionGenerationState === 'generated';
    }

    public calculateResourceProgress(resource: Resource): number {
        const number = 0.67 * resource.questionGenerationProgress + 0.33 * resource.indexingProgress;
        return Math.round(number);
    }

    public async removeResource(resourceId: string): Promise<void> {
        const confirmationPopupData = new ConfirmationPopupData('Möchtest du diese Ressource wirklich löschen?', 'Löschen', 'Abbrechen');
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<ConfirmationPopupData, ConfirmationPopupResult>(ConfirmationPopupComponent, confirmationPopupData, popupOptions);
        if (popupServiceResult === 'Ok') {
            await this.handleRemovingResource(resourceId);
        }
    }

    private async handleRemovingResource(resourceId: string): Promise<void> {
        const selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        await this.aiResource.deleteResource(selectedLearningPackageId, resourceId);
        this.editorLearningPackageService.reloadSelectedLearningPackage();
    }

    public async activate(resourceId: string): Promise<void> {
        const selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        await this.aiResource.updateResource(selectedLearningPackageId, resourceId, new UpdateResourceRequestDto('active'));
        this.editorLearningPackageService.reloadSelectedLearningPackage();
    }

    public async deactivate(resourceId: string): Promise<void> {
        const selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        await this.aiResource.updateResource(selectedLearningPackageId, resourceId, new UpdateResourceRequestDto('inactive'));
        this.editorLearningPackageService.reloadSelectedLearningPackage();
    }

    public openResourceHtml(resourceId: string): void {
        const url = `${environment.learnUrl}/resources/${resourceId}?format=micromate-html`;
        window.open(url.toString(), '_blank');
    }

    public async openResource(resourceId: string): Promise<void> {
        const url = `${environment.learnUrl}/resources/${resourceId}`;
        window.open(url.toString(), '_blank');
    }

    public trackByResource(index: number, item: Resource): string {
        return item.id;
    }
}
