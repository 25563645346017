<app-popup-container *ngIf="data !== undefined">
    <app-popup-header>
        <app-popup-header-title [title]="'Organisation ' + data.name" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <form [formGroup]="formData" class="micormate-form">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Organisationsname
                    <span class="micormate-form-label-required">*</span>
                </label>
                <input type="text" formControlName="name"
                       class="micormate-form-input micormate-form-input-organization-name"
                       [class.micormate-form-input-validation-error]="formData.value.name.length > 50">
                <div *ngIf="formData.value.name.length > 50" class="micormate-form-validation-error" i18n>Maximal 50
                    Zeichen
                </div>
                <div *ngIf="!isNameValid" class="micormate-form-validation-error" i18n>Eine Organisation mit dem Namen
                    existiert bereits
                </div>
            </div>
        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [action]="renameOrganization"
                [disabled]="!formData.valid || formData.controls.name.getRawValue().trim() === data.name"
                i18n>Speichern
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
