<div class="micromate-app-page-navigation" *ngIf="(userRoleService.hasSuperAdminRole() | async)">
    <a [routerLink]="[navigateTo(options.url)]"
       *ngFor="let options of (navigationService.activeAdminAppPageNavigation$ | async)"
       [class.micromate-app-page-navigation-options-active]="(navigationService.activeAppPageNavigationItem$ | async) === options.url"
       class="micromate-app-page-navigation-options">
        <app-icon [icon]="options.icon" class="micromate-app-page-navigation-options-icon"></app-icon>
        <div class="micromate-app-page-navigation-options-title">{{options.label}}</div>
    </a>
</div>
<div class="micromate-app-page-navigation" *ngIf="!(userRoleService.hasSuperAdminRole() | async)">
    <a [routerLink]="[navigateTo(options.url)]"
       *ngFor="let options of (navigationService.activeAppPageNavigation$ | async)"
       [class.micromate-app-page-navigation-options-active]="(navigationService.activeAppPageNavigationItem$ | async) === options.url"
       class="micromate-app-page-navigation-options">
        <app-icon [icon]="options.icon" class="micromate-app-page-navigation-options-icon"></app-icon>
        <div class="micromate-app-page-navigation-options-title">{{options.label}}</div>
    </a>
</div>

