<div class="micromate-card" *ngIf="organization !== undefined">
    <div class="mm-organization-setting-title">
        <h1 i18n>Einstellungen {{organization.name}}</h1>
        <div class="mm-organization-setting-buttons">
            <app-button type="primary" [icon]="'repeat-light'"
                        (click)="setActiveOrganization()">
            </app-button>
            <app-button type="primary" (click)="saveChanges()"
                        [disabled]="(organizationSettingServiceService.organizationSettingSaved$ | async) || invalidDomains || invalidLearnersDomains || invalidEmptyDomains"
                        i18n>
                Speichern
            </app-button>
        </div>
    </div>

    <div class="micromate-card-content">
        <div class="micromate-card-header">
            <h2 i18n>Features</h2>
        </div>
        <form [formGroup]="featuresFormData" class="micormate-form micormate-form-organization-features">
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>
                    Benachrichtigungen:
                </div>
                <app-switch-toggle formControlName="notifications" (change)="changedSetting()"></app-switch-toggle>
            </div>

            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>
                    Rangliste anzeigen:
                </div>
                <app-switch-toggle formControlName="scoreboardRanking" (change)="changedSetting()"></app-switch-toggle>
            </div>

            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>
                    Personen Insights:
                </div>
                <app-switch-toggle formControlName="personalInsights" (change)="changedSetting()"></app-switch-toggle>
            </div>

            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>
                    Pseudonym standardmässig verwenden:
                </div>
                <app-switch-toggle formControlName="usePseudonymByDefault"
                                   (change)="changedSetting()"></app-switch-toggle>
            </div>

            <div class="micromate-organization-info" [formGroup]="featuresFormData.controls.ai">
                <div class="micromate-organization-info-title" i18n>
                    AI Power:
                </div>
                <select class="micromate-single-selection mm-organization-info-data-single-selection"
                        formControlName="type">
                    <option [ngValue]="'off'"
                            class="micromate-single-selection-option" i18n>
                        Aus
                    </option>
                    <option [ngValue]="'micromate-llm'"
                            class="micromate-single-selection-option" i18n>
                        Micromate LLM
                    </option>
                    <option [ngValue]="'custom-llm'"
                            class="micromate-single-selection-option" i18n>
                        Custom LLM
                    </option>
                </select>
            </div>
        </form>

        <div class="micromate-organization-mode micromate-organization-settings-group">
            <div class="micromate-organization-info">
                <label class="micromate-organization-info-title" i18n>Domains:
                </label>
                <div class="micromate-organization-info-data micromate-organization-info-data-textarea">
                <textarea type="text" class="micormate-form-textarea"
                          [(ngModel)]="domains" (change)="changedSetting()" (ngModelChange)="changedDomains()"
                          [class.micormate-form-input-validation-error]="(domains.length > 0 && invalidDomains) || invalidLearnersDomains || invalidEmptyDomains"></textarea>
                    <div *ngIf="domains.length > 0 && invalidDomains" class="micormate-form-validation-error" i18n>
                        Falscher Eingabeformat
                    </div>
                    <div *ngIf="invalidLearnersDomains" class="micormate-form-validation-error" i18n>
                        Die Organisation lässt nur Lernende mit einer verifizierten Domain zu. Aktuell haben nicht alle
                        bereits erfassten Lernenden eine der eingetragenen Domains.
                    </div>
                    <div *ngIf="invalidEmptyDomains" class="micormate-form-validation-error" i18n>
                        Da die Organisation nur Lernende mit einer verifizierten Domain zulässt, muss mindestens eine
                        Domain angegeben werden.
                    </div>
                </div>
            </div>
            <app-warning-box class="mm-add-organization-settings-warning-box"
                             *ngIf="organization.domainsInAnotherOrganization !== undefined && organization.domainsInAnotherOrganization.length > 0">
                <div class="mm-add-organization-settings-warning">
                    <div i18n>Domains, die bereits in anderen Organisationen verwendet werden:</div>
                    <ul class="mm-add-organization-settings-warning-list">
                        <li *ngFor="let info of organization.domainsInAnotherOrganization">
                            {{info.domain}}: {{info.organizationsNames.join(', ')}}
                        </li>
                    </ul>
                </div>
            </app-warning-box>
            <app-warning-box class="mm-add-organization-settings-warning-box"
                             *ngIf="organization.learnersWithDomainInAnotherOrganizations !== undefined && organization.learnersWithDomainInAnotherOrganizations.length > 0">
                <div class="mm-add-organization-settings-warning">
                    <div i18n>Organisationen, die Lernende mit denselben Domänen umfassen:</div>
                    <ul class="mm-add-organization-settings-warning-list">
                        <li *ngFor="let info of organization.learnersWithDomainInAnotherOrganizations">
                            <span i18n>Organisation</span> {{info.organizationName}}: {{info.learnerIdentifiers.join(', ')}}
                        </li>
                    </ul>
                </div>
            </app-warning-box>
        </div>

        <div class="micromate-organization-licence micromate-organization-settings-group">
            <div class="micromate-organization-info">
                <label class="micromate-organization-info-title" i18n>Anzahl Lizenzen:
                </label>
                <div class="micromate-organization-info-data micromate-organization-info-data-textarea">
                    <input type="number" class="micormate-form-input"
                           [(ngModel)]="licence" (change)="changedSetting()">
                </div>
            </div>
        </div>

        <div class="mm-organization-setting-action-buttons">
            <app-button type="dangerous" (click)="removeOrganizationEvents()" i18n>
                Alle Events löschen
            </app-button>
            <app-button type="primary" (click)="renameOrganization()" i18n>
                Organisationsname anpassen
            </app-button>
        </div>

    </div>
</div>
