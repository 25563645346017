<div class="micromate-card">
    <div class="micromate-card-header">
        <h1>Lernpakete</h1>
        <div class="micromate-button">
            <app-button type="primary"
                        [icon]="'plus-light'"
                        (click)="createNewLearningPackage()" i18n>Neues Lernpaket
            </app-button>
        </div>
    </div>

    <div
        *ngIf="listPagination !== undefined && ((paginationParams.filter.length === 0 && learningPackages.length > 0) || (paginationParams.filter.length > 0) || !learningPackagesListRefreshed) ">
        <app-table-search [paginationParams]="paginationParams" [navigateUrl]="navigationUrl"
                          [totalDataAmount]="listPagination?.totalDataAmount"></app-table-search>
        <div class="micromate-table" *ngIf="learningPackages !== undefined">
            <div class="table-container table-container-header">
                <ng-container>
                    <div class="row-header" i18n>Name</div>
                    <div class="row-header" i18n>Ersteller</div>
                    <div class="row-header" i18n>Erstellungsdatum</div>
                    <div class="row-header" i18n>Letztes Update</div>
                    <div class="row-header" i18n>Sprache</div>
                    <div class="row-header" i18n>Actions</div>
                    <div class="row-header" i18n></div>
                </ng-container>
            </div>
            <div class="table-container-data" *ngIf="learningPackages.length > 0; else emptyTable">
                <div class="table-container table-content"
                     *ngFor="let learningPackage of learningPackages; let i = index;">
                    <div class="row row-title"
                         [routerLink]="[learningPackage.learningPackageId]">{{learningPackage.name}}</div>
                    <div class="row row-only-web">{{learningPackage.ownerContentCreator}}</div>
                    <div class="row row-only-web">{{learningPackage.creationDate | date:'short'}}</div>
                    <div class="row row-only-web">{{learningPackage.lastUpdateDate | date:'short'}}</div>
                    <div class="row row-only-web">
                        <strong>{{multilanguageService.createShortName(learningPackage.mainLanguage)}}</strong>{{getLanguages(learningPackage?.languages)}}
                    </div>
                    <div class="row row-dropdown" tabindex="-1">
                        <app-dropdown-button>
                            <app-dropdown-button-option
                                (click)="createNewLearningTranslationPackage(learningPackage.learningPackageId)"
                                i18n-text text="Neue Übersetzung"
                                icon="plus-light"></app-dropdown-button-option>
                            <app-dropdown-button-option
                                (click)="deleteLearningPackage(learningPackage)"
                                i18n-text text="Löschen"
                                icon="trash-light"></app-dropdown-button-option>
                        </app-dropdown-button>
                    </div>

                    <div class="row row-status">
                        <div class="micromate-editor-table-icon"
                             [title]="getLeanPackageStatusText(learningPackage.status)"
                             [class.micromate-learnpackage-status-published]="learningPackage.status === 'published'"
                             [class.micromate-learnpackage-status-open-changes]="learningPackage.status === 'open changes'"
                             [class.micromate-learnpackage-status-not-published]="learningPackage.status === 'not published'">
                            <svg viewBox="0 0 512 512">
                                <use href="./assets/icons.svg#dot-circle-regular"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #emptyTable>
                <div class="table-empty-filter-results" i18n>
                    keine Suchresultate
                </div>
            </ng-template>
        </div>
        <app-table-pagination [paginationParams]="paginationParams"
                              [totalDataAmount]="listPagination.totalDataAmount"
                              [navigateUrl]="navigationUrl"></app-table-pagination>
    </div>

    <div
        *ngIf="learningPackages !== undefined && learningPackages.length === 0 && paginationParams.filter.length === 0 && learningPackagesListRefreshed"
        class="micromate-empty-table">
        Es sind noch keine Lernpakete vorhanden
    </div>

</div>
