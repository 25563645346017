import {Injectable} from '@angular/core';
import {EditorLearningPackageService} from './editor-learning-package.service';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class QuizMakerService {

    constructor(private editorLearningPackageService: EditorLearningPackageService, private router: Router) {
    }

    public openQuizzesOverview(): void {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.editorLearningPackageService.selectedLearningPackageId.subscribe(learningPackageId => this.router.navigate([`/editor/lernpakete/${learningPackageId}/fragen`]));
    }
}
