import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, concat, defer, Observable, of, Subscription} from 'rxjs';
import {ImportWizardService} from '../import-wizard.service';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {distinctUntilChanged, filter, map, take} from 'rxjs/operators';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {MultilanguageService} from '../../../../core/editor/multilanguage.service';
import {ImportableQuestion} from '../../../../core/editor/types/QuestionConversion/ImportableQuestion';
import {QuestionType} from '../../../../core/editor/question.type';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-question-summary-page',
    templateUrl: './question-summary-page.component.html',
    styleUrls: ['./question-summary-page.component.scss']
})
export class QuestionSummaryPageComponent implements OnInit, OnDestroy {
    public displayedQuestionsForDefaultLocale$: Observable<ImportableQuestion[]>;
    public numberOfQuestions$: Observable<number>;
    public hasSomeQuestionsToImport$: Observable<boolean>;
    public localeNotSpecified: boolean = false;
    public defaultLocale: string;
    public importButtonDisabled: boolean = false;
    public selectAll: boolean = true;
    public indeterminate: boolean = false;
    public searchFormControl: FormControl<string> = new FormControl<string>('');
    public showAlreadyImportedQuestions: FormControl<boolean> = this.importWizardService.showAlreadyImportedQuestions;
    public searchFormControlValue$ = concat(
        defer(() => of(this.searchFormControl.value)),
        this.searchFormControl.valueChanges
    );
    public alreadyImportedQuestionsFromControlChange$ = new Subscription();

    constructor(public importWizardService: ImportWizardService,
                private editorQuestionService: EditorQuestionService,
                private editorLearningPackageService: EditorLearningPackageService,
                private multiLanguageService: MultilanguageService) {
    }

    public ngOnInit(): void {
        this.getDefaultLanguage();
        this.searchFormControl.setValue(this.importWizardService.getSearchValue());

        this.displayedQuestionsForDefaultLocale$ = combineLatest([this.importWizardService.displayedSummaryQuestions$, this.searchFormControlValue$]).pipe(
            map(([questions, search]: [ImportableQuestion[], string]) => {
                const filterQuestions = this.importWizardService.filterQuestions(search, questions);
                this.updateSelectState();
                return filterQuestions;
            })
        );
        this.numberOfQuestions$ = this.displayedQuestionsForDefaultLocale$.pipe(map(questions => questions?.length));
        this.hasSomeQuestionsToImport$ = this.importWizardService.displayedSummaryQuestions$.pipe(map(questions => questions?.length > 0));

        this.alreadyImportedQuestionsFromControlChange$ = this.importWizardService.showAlreadyImportedQuestions$.pipe(
            distinctUntilChanged(),
            filter(value => value))
            .subscribe(() => this.importWizardService.resetActiveStateForAlreadyImportedQuestions());
    }

    private getDefaultLanguage(): void {
        this.editorLearningPackageService.defaultLocaleSubject$.pipe(take(1)).subscribe(defaultLanguage => {
            this.defaultLocale = this.multiLanguageService.getLanguageNameForLocale(defaultLanguage);
            this.localeNotSpecified = this.importWizardService.getQuestionLocales().length === 0;
        });
    }

    public findIconForQuizType(questionType: string): QuestionType {
        return this.editorQuestionService.quizTypes.find(x => x.type === questionType);
    }

    public changeQuestionActiveStatus(questionToRemove: ImportableQuestion): void {
        this.importWizardService.toggleQuestionActiveStatus(questionToRemove);
    }

    private updateSelectState(): void {
        if (this.importWizardService.isAllQuestionActive()) {
            this.selectAll = true;
            this.indeterminate = false;
        } else {
            this.selectAll = false;
            this.indeterminate = !this.importWizardService.isAllQuestionInActive();
        }
    }

    public async import(): Promise<void> {
        if (!this.importButtonDisabled) {
            this.importButtonDisabled = true;
            await this.importWizardService.importQuestions();
        }
    }

    public navigateToQuestion(question: ImportableQuestion): void {
        this.importWizardService.setSearchValue(this.searchFormControl.getRawValue());
        this.importWizardService.selectQuestionForPreview(question);
        this.importWizardService.currentStep = 'question-preview';
    }

    public selectAllQuestion(event: MouseEvent): void {
        if (this.indeterminate) {
            this.selectAll = false;
            this.indeterminate = false;
        } else {
            this.selectAll = !this.selectAll;
        }
        (event.target as HTMLInputElement).checked = this.selectAll;
        (event.target as HTMLInputElement).indeterminate = this.indeterminate;
        this.importWizardService.toggleAllQuestionActiveStatus(this.selectAll);
    }

    public clearSearch(): void {
        this.searchFormControl.setValue('');
        this.showAlreadyImportedQuestions.setValue(true);
    }

    public shouldShowAlreadyImportedQuestionsToggle(questions: ImportableQuestion[]): boolean {
        return questions.some(data => data.alreadyImported);
    }

    public showAllQuestions(): void {
        this.importWizardService.setAlreadyImportedQuestionsFromControl(false);
    }

    public ngOnDestroy(): void {
        this.alreadyImportedQuestionsFromControlChange$.unsubscribe();
    }

    public goToImportTag(): void {
        this.importWizardService.currentStep = 'import-tag';
    }
}
