<div class="micromate-card">
    <h1 i18n>Lernende</h1>

    <div *ngIf="listPagination !== undefined">
        <app-table-search [paginationParams]="paginationParams" [navigateUrl]="navigationUrl" [totalDataAmount]="listPagination?.totalDataAmount"></app-table-search>
        <div class="micromate-table" *ngIf="learners !== undefined">
            <div class="table-container table-container-header">
                <ng-container>
                    <div class="row-header" i18n>Name</div>
                    <div class="row-header" i18n>Benutzer</div>
                    <div class="row-header" i18n>Installationsdatum</div>
                    <div class="row-header" i18n>Sprache</div>
                    <div class="row-header" i18n></div>
                </ng-container>
            </div>
            <div *ngIf="learners.length > 0; else emptyTable" class="table-container-data" #tableContentRef>
                <div class="table-container table-content" *ngFor="let learner of learners">
                    <div class="row row-name">
                        <div class="row-sub micromate-table-link">
                            {{learner?.displayName ? learner.displayName : '-'}}
                        </div>
                        <div class="row-only-mobile-email">
                            {{learner.identifier ? learner.identifier : '-'}}
                        </div>
                    </div>
                    <div class="row row-only-web">{{learner?.identifier ? learner.identifier : '-'}}</div>
                    <div class="row row-only-web">{{learner?.installationDate ? (learner.installationDate | date :'short')  :'-'}}</div>
                    <div class="row row-only-web">{{multilanguageService.getLearnerLanguages(learner.channels)}}</div>
                    <div class="row group-info">
                        <div class="micromate-group-button" (click)="redirectToLearnerInfo(learner._id)">
                            <svg class="micromate-group-form-icon" viewBox="-75 0 640 512">
                                <use href="./assets/icons.svg#info-circle-light"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <ng-template #emptyTable>
                <div class="table-empty-filter-results" i18n>
                    keine Suchresultate
                </div>
            </ng-template>
        </div>
        <app-table-pagination [paginationParams]="paginationParams"
                              [totalDataAmount]="listPagination.totalDataAmount"
                              [navigateUrl]="navigationUrl"></app-table-pagination>
    </div>

</div>
