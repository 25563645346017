<div class="micromate-group-learnpakete-title">
    <h1 i18n>Lernpakete</h1>
    <div class="micromate-button" *ngIf="selectedGroupId !== undefined"
         (click)="openLearningPackageActivationPopup()">
        <div class="micromate-buttont-text">
            <svg aria-hidden="true" focusable="false" viewBox="0 0 384 512"
                 class="micromate-buttont-text-icon">
                <use href="./assets/icons.svg#plus-light"></use>
            </svg>
            <div i18n class="micromate-button-title">Lernpakete hinzufügen</div>
        </div>
    </div>
</div>

<div class="micromate-table" *ngIf="learningPackages !== undefined && learningPackages.length > 0">
    <div class="table-container table-container-header">
        <ng-container>
            <div class="row-header" i18n>Name</div>
            <div class="row-header" i18n>Aktivierung</div>
            <div class="row-header" i18n>Lernziel</div>
            <div class="row-header" i18n>Sprache</div>
            <div class="row-header" i18n></div>
        </ng-container>
    </div>
    <div class="table-container-data">
        <div class="table-container table-content" *ngFor="let learningPackage of learningPackages">
            <div class="row row-name">
                <div class="row-sub micromate-table-link">
                    {{learningPackage.name}}
                </div>
                <div class="row-only-mobile-email">
                    {{getActivatedDate(learningPackage._id) | date:'short'}}
                </div>
                <div class="row-only-mobile-email">
                    {{getLearningGoal(learningPackage._id)}}
                </div>
            </div>
            <div class="row row-only-web">{{getActivatedDate(learningPackage._id) | date:'short'}}</div>
            <div class="row row-only-web">{{getLearningGoal(learningPackage._id)}}</div>
            <div class="row row-only-web">
                <strong>{{multilanguageService.createShortName(learningPackage.mainLanguage)}}</strong>{{learningPackage.language}}
            </div>
            <div class="row group-info">
                <div class="micromate-group-button" [routerLink]="[learningPackage._id]">
                    <app-icon icon="cog-light" class="micromate-group-form-app-icon"></app-icon>
                </div>
                <div class="micromate-group-button" (click)="openLearningPackageDeactivationPopup(learningPackage)">
                    <app-icon icon="trash-light" class="micromate-group-form-app-icon"></app-icon>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="micromate-empty-table" *ngIf="learningPackages !== undefined && learningPackages.length === 0">
    Keine Lernpakete vorhanden
</div>
