<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Hilfe" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <div class="mm-popup-content-element">
            <h2 i18n>Formatierung</h2>
            <p i18n>Fragen, Antworten und Erklärungen können mit folgendem Markdown formatiert werden:</p>
            <ul>
                <li>
                    <div class="mm-popup-content-help-media">
                        <div class="mm-popup-content-help-media-info" i18n>Fett:</div>
                        <div>**fett**</div>
                    </div>

                </li>
                <li>
                    <div class="mm-popup-content-help-media">
                        <div class="mm-popup-content-help-media-info" i18n>Kursiv:</div>
                        <div class="mm-help-quiz-italic">_kursiv_</div>
                    </div>
                </li>
                <li>
                    <div class="mm-popup-content-help-media">
                        <div class="mm-popup-content-help-media-info" i18n>Link:</div>
                        <div class="mm-help-quiz-link">
                            <p class="mm-help-quiz-link-text">[Name]</p>(URL)
                        </div>
                    </div>
                </li>
            </ul>
            <p i18n>In Erklärungen können auch Listen dargestellt werden:</p>
            <ul class="mm-popup-content-list-no">
                <li> - Element 1</li>
                <li> - Element 2</li>
            </ul>
        </div>

        <div>
            <h2 i18n>Medien</h2>
            <h3 i18n>Bilder</h3>
            <ul>
                <li>
                    <div class="mm-popup-content-help-media">
                        <div class="mm-popup-content-help-media-info" i18n>Dateigrösse:</div>
                        <div>maximal 4MB</div>
                    </div>
                </li>
                <li>
                    <div class="mm-popup-content-help-media">
                        <div class="mm-popup-content-help-media-info" i18n>Dateityp:</div>
                        <div>JPG, JPEG, PNG, GIF</div>
                    </div>
                </li>
            </ul>
            <h3 i18n>Audio</h3>
            <p i18n>Um ein Audio hinzuzufügen, muss die URL-Link der öffentlich zugänglichen Audiodatei angegeben
                werden:</p>
            <ul>
                <li>
                    <div class="mm-popup-content-help-media">
                        <div class="mm-popup-content-help-media-info" i18n>Dateityp:</div>
                        <div>MP3, WAV, OGG</div>
                    </div>
                </li>
            </ul>
            <h3 i18n>Video</h3>
            <p i18n>Um ein Video hinzuzufügen, muss die URL-Link der Videodatei angegeben werden: </p>
            <ul>
                <li>
                    <div class="mm-popup-content-help-media-title" i18n>öffentlich verfügbare Videodatei</div>
                    <ul>
                        <li>
                            <div class="mm-popup-content-help-media">
                                <div class="mm-popup-content-help-media-info" i18n>Dateityp:</div>
                                <div>MP4, WebM, OGG</div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li>
                    <div class="mm-popup-content-help-media-title" i18n>YouTube</div>
                    <ul>
                        <li>
                            <div>URL-Link des YouTube-Videos kopieren und einfügen</div>
                        </li>
                    </ul>
                </li>
                <li>
                    <div class="mm-popup-content-help-media-title" i18n>Google Drive</div>
                    <ol>
                        <li>
                            <div>Video im Google Drive öffnen</div>
                        </li>
                        <li>
                            <div>Oben rechts auf die drei Punkte (weitere Aktionen) klicken</div>
                        </li>
                        <li>
                            <div>Auf <strong>Freigeben</strong> klicken und <strong>Jeder, der über den Link
                                verfügt</strong> auswählen
                            </div>
                        </li>
                        <li>
                            <div>Im Menü <strong>In neuem Fenster öffnen</strong> anklicken</div>
                        </li>
                        <li>
                            <div>URL des neuen Fensters kopieren und einfügen</div>
                        </li>

                    </ol>
                </li>
                <li>
                    <div class="mm-popup-content-help-media-title" i18n>OneDrive</div>
                    <ol>
                        <li>
                            <div>Im OneDrive zum Video navigieren und dieses auswählen</div>
                        </li>
                        <li>
                            <div>Oben auf der Seite die Option <strong>Einbetten</strong> und dann
                                <strong>Generieren</strong> auswählen
                            </div>
                        </li>
                        <li>
                            <div>Die URL aus dem SRC-Tag kopieren (src="beispiel-url") und diese einfügen</div>
                        </li>
                    </ol>
                </li>
                <li>
                    <div class="mm-popup-content-help-media-title" i18n>Dartfish</div>
                    <ol>
                        <li>
                            <div>Auf Dartfish zum Video navigieren</div>
                        </li>
                        <li>
                            <div>Auf <strong>Veröffentlichen</strong> und dann auf die Registerkarte
                                <strong>Einbetten</strong> klicken
                            </div>
                        </li>
                        <li>
                            <div>Die komplette URL aus dem SRC-Tag kopieren (src="<strong>beispiel-url</strong>") und
                                diese einfügen
                            </div>
                        </li>
                    </ol>
                </li>
            </ul>
            <p><strong>Hinweis:</strong> Aktuell können keine Videos aus <strong>Sharepoint</strong> verwendet werden.
            </p>
        </div>
    </app-popup-content>
</app-popup-container>
