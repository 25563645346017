import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {OrganizationService} from '../../core/organization.service';
import {Observable, Subscription} from 'rxjs';
import {OrganizationAdminPanelResource} from '../../core/rest/organization-admin/organization-admin-panel-resource.service';
import {OrganizationAdminOverview} from '../../core/api-types/organizationAdminOverview';
import {FormControl, FormGroup} from '@angular/forms';
import {OrganizationAdminSettingService} from '../organization-admin-setting.service';
import {OrganizationAdminSettings} from '../OrganizationAdminSettings';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {OrganizationSettingsFormGroup} from './OrganizationSettingsFormGroup';
import {
    ORGANIZATION_CHANNEL_LEARN_MICROMATE,
    ORGANIZATION_CHANNEL_MICROSOFT_TEAMS,
    OrganizationChannelTypes
} from '../../core/api-types/organizationChannelTypes';
import {OrganizationSettingsFormData} from './OrganizationSettingsFormData';
import {environment} from '../../../environments/environment';
import {LanguageModel} from '../../core/features/OrganizationAICredential';
import {OrganizationAITypes} from '../../core/features/OrganizationAITypes';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';

@Component({
    selector: 'app-organization-overview',
    templateUrl: './organization-settings.component.html',
    styleUrls: ['./organization-settings.component.scss']
})
export class OrganizationSettingsComponent implements OnInit, OnDestroy {
    public organizationAdminOverview: OrganizationAdminOverview;
    public activeOrganizationObservable = new Subscription();
    public isSuperAdmin$: Observable<boolean> = this.authUntilUserRoleService.hasSuperAdminRole();

    public formData = new FormGroup<OrganizationSettingsFormGroup>({
        joinWithOrganizationCode: new FormControl<boolean>(false),
        domainVerification: new FormControl<boolean>(false),
        channelMicrosoftTeams: new FormControl<boolean>(false),
        channelLernMicromate: new FormControl<boolean>(false),
        mainChannel: new FormControl<OrganizationChannelTypes>(ORGANIZATION_CHANNEL_MICROSOFT_TEAMS),
        aiCredential: new FormGroup({
            languageModel: new FormControl<LanguageModel>(LanguageModel.OpenAI),
            apiKey: new FormControl<string>(''),
            apiUrl: new FormControl<string>(''),
            completionModelDeploymentName: new FormControl<string>(''),
            embeddingModelDeploymentName: new FormControl<string>('')
        })
    });
    public organizationCode: string;
    public formDataObservable = new Subscription();
    public channelMicrosoftTeamsObservable = new Subscription();
    public channelLernMicromateObservable = new Subscription();
    public organizationCodeGenerationSubscription = new Subscription();
    public aiCredentialSubscription = new Subscription();

    constructor(private organizationService: OrganizationService,
                private organizationAdminPanelRestService: OrganizationAdminPanelResource,
                public organizationAdminSettingService: OrganizationAdminSettingService,
                private toastr: ToastrService,
                private authUntilUserRoleService: AuthUntilUserRoleService) {
    }

    @HostListener('window:beforeunload', ['$event'])
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    public unloadHandler($event): string {
        if (!this.organizationAdminSettingService.organizationAdminSettingSaved.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return $event.returnValue = 'Your changes will not be saved';
        }
    }

    public ngOnInit(): void {
        this.activeOrganizationObservable = this.organizationService.activeOrganizationId$.subscribe(activeOrganizationId => {
            if (activeOrganizationId !== undefined) {
                this.organizationAdminPanelRestService.getOrganizationSettings().subscribe(organization => {
                    this.organizationAdminOverview = organization;
                    this.organizationAdminOverview.activeChannels = this.organizationAdminOverview.activeChannels === undefined ? [] : this.organizationAdminOverview.activeChannels;
                    this.setFormData(organization);
                    this.organizationCode = organization.code;
                    this.subscribeFormChanges();
                });
            }
        });
    }

    private setFormData(organization: OrganizationAdminOverview): void {
        this.formData.setValue({
            joinWithOrganizationCode: organization.joinWithOrganizationCode,
            domainVerification: organization.domainVerification,
            channelMicrosoftTeams: this.getChannelStatus(organization.activeChannels, ORGANIZATION_CHANNEL_MICROSOFT_TEAMS),
            channelLernMicromate: this.getChannelStatus(organization.activeChannels, ORGANIZATION_CHANNEL_LEARN_MICROMATE),
            mainChannel: organization.mainChannel !== undefined ? organization.mainChannel : ORGANIZATION_CHANNEL_MICROSOFT_TEAMS,
            aiCredential: this.getAiCredential(organization)
        });
    }

    private getAiCredential(organization: OrganizationAdminOverview): { languageModel: LanguageModel; apiKey: string; apiUrl: string; completionModelDeploymentName: string; embeddingModelDeploymentName: string; } {
        return {
            languageModel: organization.features.ai.credential?.languageModel !== undefined ? organization.features.ai.credential.languageModel : LanguageModel.OpenAI,
            apiKey: this.getAICredentialValue(organization.features.ai.credential?.apiKey),
            apiUrl: this.getAICredentialValue(organization.features.ai.credential?.apiUrl),
            completionModelDeploymentName: this.getAICredentialValue(organization.features.ai.credential?.completionModelDeploymentName),
            embeddingModelDeploymentName: this.getAICredentialValue(organization.features.ai.credential?.embeddingModelDeploymentName)
        };
    }

    private getAICredentialValue(value: string): string {
        return value !== undefined ? value : '';
    }

    private subscribeFormChanges(): void {
        this.channelMicrosoftTeamsObservable = this.formData.controls.channelMicrosoftTeams.valueChanges.subscribe(value => this.changeMainChannelValue(value, this.formData.value.channelLernMicromate, ORGANIZATION_CHANNEL_MICROSOFT_TEAMS, ORGANIZATION_CHANNEL_LEARN_MICROMATE));

        this.channelLernMicromateObservable = this.formData.controls.channelLernMicromate.valueChanges.subscribe(value =>
            this.changeMainChannelValue(value, this.formData.value.channelMicrosoftTeams, ORGANIZATION_CHANNEL_LEARN_MICROMATE, ORGANIZATION_CHANNEL_MICROSOFT_TEAMS));

        this.aiCredentialSubscription = this.formData.controls.aiCredential.controls.languageModel.valueChanges.subscribe(() => {
            this.formData.controls.aiCredential.controls.apiKey.setValue('');
            this.formData.controls.aiCredential.controls.apiUrl.setValue('');
            this.formData.controls.aiCredential.controls.completionModelDeploymentName.setValue('');
            this.formData.controls.aiCredential.controls.embeddingModelDeploymentName.setValue('');
        });

        this.formDataObservable = this.formData.valueChanges.subscribe((value: OrganizationSettingsFormData) => {
            if (this.isValueChanged(value)) {
                this.setOrganizationAdminSettingChange();
            } else {
                this.organizationAdminSettingService.setOrganizationAdminSettingSavedStatus(true);
            }
        });
    }

    private isValueChanged(value: OrganizationSettingsFormData): boolean {
        const joinWithOrganizationCodeChanged = value.joinWithOrganizationCode !== this.organizationAdminOverview.joinWithOrganizationCode;
        const domainVerificationChanged = value.domainVerification !== this.organizationAdminOverview.domainVerification;
        const activeChannels = this.organizationAdminSettingService.getActiveChannels(value);
        const activeChannelsChanged = activeChannels.length !== this.organizationAdminOverview.activeChannels.length || activeChannels.some(channel => !this.organizationAdminOverview.activeChannels.includes(channel));
        const mainChannelChanged = value.mainChannel !== this.organizationAdminOverview.mainChannel;
        const aiCredentialChanged = this.isAICredentialChanged(value);
        return joinWithOrganizationCodeChanged || domainVerificationChanged || activeChannelsChanged || mainChannelChanged || aiCredentialChanged;
    }

    private isAICredentialChanged(value: OrganizationSettingsFormData): boolean {
        const languageModelChanged = value.aiCredential.languageModel !== this.organizationAdminOverview.features.ai.credential?.languageModel;
        const apiKeyChanged = this.isCredentialValueChanged(this.organizationAdminOverview.features.ai.credential?.apiKey, value.aiCredential.apiKey);
        const apiUrlChanged = this.isCredentialValueChanged(this.organizationAdminOverview.features.ai.credential?.apiUrl, value.aiCredential.apiUrl);
        const embeddingModelDeploymentNameChanged = this.isCredentialValueChanged(this.organizationAdminOverview.features.ai.credential?.embeddingModelDeploymentName, value.aiCredential.embeddingModelDeploymentName);
        const completionModelDeploymentNameChanged = this.isCredentialValueChanged(this.organizationAdminOverview.features.ai.credential?.completionModelDeploymentName, value.aiCredential.completionModelDeploymentName);
        return this.organizationAdminOverview.features.ai.type === OrganizationAITypes.CustomLlm && (languageModelChanged || apiKeyChanged || apiUrlChanged || embeddingModelDeploymentNameChanged || completionModelDeploymentNameChanged);
    }

    private isCredentialValueChanged(enteredValue: string, value: string): boolean {
        return !(enteredValue === undefined && value === '') && value !== enteredValue;
    }

    private changeMainChannelValue(currentChannelActivationValue: boolean, anotherChannelActivationValue: boolean, currentChannel: OrganizationChannelTypes, anotherChannel: OrganizationChannelTypes): void {
        if (!currentChannelActivationValue && anotherChannelActivationValue) {
            if (this.formData.value.mainChannel === currentChannel) {
                this.formData.controls.mainChannel.setValue(anotherChannel);
            }
        }
        if (currentChannelActivationValue && !anotherChannelActivationValue) {
            this.formData.controls.mainChannel.setValue(currentChannel);
        }
    }

    private getChannelStatus(activeChannels: OrganizationChannelTypes[], organizationChannel: OrganizationChannelTypes): boolean {
        return activeChannels !== undefined && activeChannels.includes(organizationChannel);
    }

    public copyOrganizationCode(): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigator.clipboard.writeText(this.organizationAdminOverview.code);
        this.toastr.success($localize`Organisationscode kopiert`);
    }

    public copyRegistrationLink(): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigator.clipboard.writeText(this.createRegistrationLink());
        this.toastr.success($localize`Registrierungslink kopiert`);
    }

    public createRegistrationLink(): string {
        return `${environment.registrationLink}?orgCode=${this.organizationCode}`;
    }

    public generateNewOrganizationCode: () => Promise<void> = async () => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises,no-async-promise-executor
        return new Promise((resolve) => {
            this.organizationCodeGenerationSubscription = this.organizationAdminPanelRestService.getOrganizationCode().subscribe(data => {
                this.organizationCode = data.organizationCode;
                this.setOrganizationAdminSettingChange();
                resolve();
            });
        });
    };

    public async saveSettings(): Promise<void> {
        const organizationSettings = new OrganizationAdminSettings(
            this.formData.value.joinWithOrganizationCode,
            this.organizationCode,
            this.formData.value.domainVerification,
            this.organizationAdminSettingService.getActiveChannels(this.formData.getRawValue()),
            this.formData.value.mainChannel,
            this.organizationAdminSettingService.getNewAiCredential(this.formData.controls.aiCredential.getRawValue())
        );
        try {
            await this.organizationAdminPanelRestService.saveOrganizationSettings(organizationSettings);
            this.toastr.success($localize`Änderungen gespeichert`);
            this.organizationAdminSettingService.setOrganizationAdminSettingSavedStatus(true);
            await this.organizationService.refreshActiveOrganizationInfo();
        } catch (e) {
            const error = e as HttpErrorResponse;
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (error?.error?.notUniqueOrganizationCode === true) {
                this.toastr.error($localize`Fehler: Einstellungen konnten nicht gespeichert werden.`);
            } else {
                this.toastr.error($localize`Einstellungen konnten nicht geändert werden`);
            }
        }
    }

    public ngOnDestroy(): void {
        this.activeOrganizationObservable.unsubscribe();
        this.formDataObservable.unsubscribe();
        this.organizationCodeGenerationSubscription.unsubscribe();
        this.channelLernMicromateObservable.unsubscribe();
        this.channelMicrosoftTeamsObservable.unsubscribe();
        this.aiCredentialSubscription.unsubscribe();
    }

    private setOrganizationAdminSettingChange(): void {
        this.organizationAdminSettingService.setOrganizationAdminSettingSavedStatus(false);
        this.organizationAdminSettingService.setOrganizationAdminSetting(this.formData.getRawValue(), this.organizationCode);
    }

    public isAzureOpenAICredentialValid(): boolean {
        const validApiKey = this.formData.controls.aiCredential.controls.apiKey.getRawValue().trim().length > 0;
        const validApiUrl = this.formData.controls.aiCredential.controls.apiUrl.getRawValue().trim().length > 0;
        const validCompletionModelDeploymentName = this.formData.controls.aiCredential.controls.completionModelDeploymentName.getRawValue().length > 0;
        const validEmbeddingModelDeploymentName = this.formData.controls.aiCredential.controls.embeddingModelDeploymentName.getRawValue().length > 0;
        return validApiKey && validApiUrl && validCompletionModelDeploymentName && validEmbeddingModelDeploymentName;
    }

    public isLanguageModelCredentialChanged(): boolean {
        const currentLanguageModel = this.organizationAdminOverview.features.ai?.credential?.languageModel;
        if (currentLanguageModel === undefined) {
            return false;
        }
        return this.formData.controls.aiCredential.controls.languageModel.getRawValue() !== currentLanguageModel;
    }

    public getAiFeatureOverviewValue(data: string): string {
        return data === undefined || data.trim().length === 0 ? '-' : data;
    }
}
