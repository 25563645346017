import { Component } from '@angular/core';

@Component({
  selector: 'app-popup-footer-buttons',
  templateUrl: './popup-footer-buttons.component.html',
  styleUrls: ['./popup-footer-buttons.component.scss']
})
export class PopupFooterButtonsComponent {

}
