<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Lernpakete hinzufügen" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content class="mm-learning-package-activation-popup-content">
        <form [formGroup]="formData" class="micormate-form mm-learning-package-activation-popup-content-form"
              *ngIf="this.availableLearningPackages !== undefined">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Lernpaket:
                </label>
                <ng-multiselect-dropdown
                    class="single-selection"
                    [settings]="learningPackageDropdownSettings"
                    [data]="availableLearningPackages"
                    formControlName="learningPackages"
                    [placeholder]="'Lernpakete auswählen'">
                </ng-multiselect-dropdown>
            </div>

            <div *ngIf="formData.value.learningPackages.length > 0 && manualActivationTags !== undefined"
                 class="mm-learning-package-activation-popup-content-switchers">
                <div class="micormate-form-element mm-app-learning-goals-slider-form-element"
                >
                    <app-learning-goals-slider
                        [startLearningGoal]="learningGoal"
                        [form]="true"
                        (newLearningGoal)="changedLearningGoal($event)"></app-learning-goals-slider>
                </div>

                <app-tag-activation
                    [manualActivation]="manualActivation"
                    [manualActivationTags]="manualActivationTags"
                    [form]="true"
                    (activationTagChanged)="manualActivationChanged($event)">
                </app-tag-activation>
            </div>
        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>
                Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [disabled]="formData.value.learningPackages.length === 0"
                [action]="activateLearningPackageForGroup" i18n>
                Einfügen
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>



