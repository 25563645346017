import {QuizEditor} from '../QuizEditor';
import {QuizExplanationForm} from '../QuizExplanationForm';
import {QuizQuestionForm} from '../QuizQuestionForm';
import {SelectedTag} from '../../../api-types/SelectedTag';
import {SelectedNewTag} from '../../../api-types/SelectedNewTag';

export class FreeTextQuizEditor implements QuizEditor {
    constructor(public answers: string[],
                public answer: string,
                public explanation: QuizExplanationForm,
                public question: QuizQuestionForm,
                public selectedAvailableTags: SelectedTag[],
                public selectedNewTags: SelectedNewTag[],
                public sourceUrl?: string,
                public sourceLabel?: string) {
    }
}
