import {Component, Input} from '@angular/core';
import {QuizQuestion} from '../../../../../core/editor/types/QuizQuestion';
import {MediaData} from './components/question-media-preview/MediaData';
import {Quiz} from '../../../../../core/api-types/quiz';

@Component({
    selector: 'app-question-preview',
    templateUrl: './question-preview.component.html',
    styleUrls: ['./question-preview.component.scss']
})
export class QuestionPreviewComponent {
    @Input()
    public questionPreview: Quiz;

    public selectedAvailableTags = [];
    public availableTags = [];

    public hasQuestionMedia(question: QuizQuestion): boolean {
        return question.mediaType !== undefined && (question.mediaUrl !== undefined || question.imageUrl !== undefined);
    }

    public getMediaData(question: QuizQuestion): MediaData {
        return new MediaData(question.mediaType, question.mediaUrl, question.imageUrl);
    }
}
