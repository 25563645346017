<app-popup-container>
    <app-popup-header>
        <app-popup-header-title *ngIf="newPackageMode" title="Neues Lernpaket" i18n-title></app-popup-header-title>
        <app-popup-header-title *ngIf="!newPackageMode" title="Neue Übersetzung" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <form [formGroup]="formData" class="micormate-form">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Name:
                    <span class="micormate-form-label-required">*</span>
                </label>
                <input type="text" formControlName="name" class="micormate-form-input"
                       [class.micormate-form-input-validation-error]="formData.controls.name.errors?.trimmedMaxLength || nameInvalid">
                <div *ngIf="formData.controls.name.errors?.trimmedMaxLength" class="micormate-form-validation-error"
                     i18n>Maximal 30
                    Zeichen
                </div>
                <div *ngIf="nameInvalid" class="micormate-form-validation-error" i18n>Es existiert bereits ein Lernpaket
                    mit diesem Namen
                </div>
            </div>

            <div class="micormate-form-element">
                <label class="micormate-form-label micormate-form-textarea-label" i18n>Beschreibung:
                </label>
                <textarea type="text" formControlName="introduction" class="micormate-form-textarea"
                          [class.micormate-form-input-validation-error]="formData.controls.introduction.errors?.trimmedMaxLength "></textarea>
                <div *ngIf="formData.controls.introduction.errors?.trimmedMaxLength"
                     class="micormate-form-validation-error" i18n>
                    Maximal 500 Zeichen
                </div>
            </div>

            <div class="micormate-form-element">
                <label class="micormate-form-label micormate-form-textarea-label" i18n>Sprache:
                    <span class="micormate-form-label-required">*</span>
                </label>
                <select formControlName="language" class="micromate-single-selection">
                    <option *ngFor="let language of availableLanguageList"
                            [ngValue]="language.key">{{language.name}}</option>
                </select>
            </div>
        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [action]="clickNewLearningPackage"
                [disabled]="!formData.valid"
                i18n>Speichern
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
