import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {GroupService} from '../../../core/group.service';
import {LearnerResource} from '../../../core/rest/learner-resource.service';
import {LearningGroupResource} from '../../../core/rest/learning-group-resource.service';
import {LernerForGroup} from './LernerForGroup';
import {PopupService} from 'src/app/components/popup/popup.service';
import {Popup} from '../../../components/popup/Popup';
import {AddLearnerToGroupPopupData} from './AddLearnerToGroupPopupData';

@Component({
    selector: 'app-add-learner-to-group-popup',
    templateUrl: './add-learner-to-group-popup.component.html',
    styleUrls: ['./add-learner-to-group-popup.component.scss']
})
export class AddLearnerToGroupPopupComponent implements Popup<AddLearnerToGroupPopupData>, OnInit {
    @Input()
    public data: AddLearnerToGroupPopupData;

    public availableLearners: { _id: string; name: string; }[];
    public formData = new FormGroup<{ learners: FormControl<LernerForGroup[]>; }>({
        learners: new FormControl<LernerForGroup[]>([])
    });

    public learnerDropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: '_id',
        textField: 'name',
        enableCheckAll: false,
        allowSearchFilter: true,
        searchPlaceholderText: 'Lernenden  suchen',
        noDataAvailablePlaceholderText: 'Keine Lernenden  verfügbar'
    };

    constructor(private learnerRestService: LearnerResource,
                private groupService: GroupService,
                private groupRestService: LearningGroupResource,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.learnerRestService.getAllAvailableForGroup(this.data.groupId).subscribe(learnersData => {
            this.availableLearners = learnersData.map(learner => {
                return {
                    _id: learner._id,
                    name: learner.displayName !== undefined ? learner.displayName : learner.identifier
                };
            });
        });
    }

    public activateLearnersForGroup: () => Promise<void> = async () => {
        return new Promise(resolve => {
            const learners = this.formData.value.learners;
            this.groupRestService.addLearners(this.data.groupId, learners.map(learner => learner._id)).subscribe(() => {
                this.groupService.selectedGroupId.next(this.data.groupId);
                this.popupService.close();
                this.formData.controls['learners'].setValue([]);
                resolve();
            });
        });
    };

    public cancel(): void {
        this.popupService.close();
    }
}
