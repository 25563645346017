<div class="micromate-card" *ngIf="learner !== undefined">
    <div class="micromate-card-title">
        <h1 i18n>{{learner?.displayName ? learner.displayName : learner.email}}</h1>
    </div>

    <div class="micromate-card-content">
        <div class="micromate-learner-info">
            <div class="micromate-learner-info-title" i18n>
                E-Mail:
            </div>
            <div class="micromate-learner-info-data">
                {{learner.email ?? '-'}}
            </div>
        </div>
        <div class="micromate-learner-info">
            <div class="micromate-learner-info-title" i18n>
                Benutzer:
            </div>
            <div class="micromate-learner-info-data">
                {{learner.identifier ?? '-'}}
            </div>
        </div>


        <div class="micromate-learner-info">
            <div class="micromate-learner-info-title" i18n>
                Installationsdatum:
            </div>
            <div class="micromate-learner-info-data">
                {{learner?.installationDate ? (learner.installationDate | date :'short') : '-'}}
            </div>
        </div>

        <div class="micromate-learner-info">
            <div class="micromate-learner-info-title" i18n>
                Sprache:
            </div>
            <div class="micromate-learner-info-data">
                {{multilanguageService.getLearnerLanguages(learner?.channels)}}
            </div>
        </div>

        <div class="micromate-learner-info-group" *ngIf="learner.channels !== undefined">
            <div class="micromate-learner-info">
                <div class="micromate-learner-info-title" i18n>
                    Profilseiten:
                </div>
                <div class="micromate-learner-info-data micromate-learner-info-data-multi">
                    <a [href]="getProfileUrl() +'/learn/badges?learnerid=' + learner._id" target="_blank"
                       class="micromate-learner-info-data-profile-link">
                        Badges
                    </a>
                    <a [href]="getProfileUrl() +'/learn/lernfortschritt?learnerid=' + learner._id" target="_blank"
                       class="micromate-learner-info-data-profile-link">
                        Lernfortschritt
                    </a>
                    <a [href]="getProfileUrl() +'/learn/rangliste?learnerid=' + learner._id" target="_blank"
                       class="micromate-learner-info-data-profile-link">
                        Rangliste
                    </a>
                </div>
            </div>
        </div>

        <div class="micromate-learner-info-group">
            <div *ngIf="learner.organizations !== undefined; else noOrganization">
                <div class="micromate-learner-info">
                    <div class="micromate-learner-info-title" i18n>
                        Aktive Organisation:
                    </div>
                    <div class="micromate-learner-info-data">
                        {{activeOrganization ? activeOrganization.organizationName : '-'}}
                    </div>
                </div>

                <div class="micromate-learner-info"
                     *ngIf="inactiveOrganization !== undefined && inactiveOrganization.length > 0">
                    <div class="micromate-learner-info-title" i18n>
                        Weitere Organisationen:
                    </div>
                    <div class="micromate-learner-info-data micromate-learner-info-data-list">
                        <div *ngFor="let organization of inactiveOrganization">
                            {{organization.organizationName}}
                        </div>
                    </div>
                </div>
            </div>


            <ng-template #noOrganization>
                <div class="micromate-learner-info-title" i18n>
                    Lernender gehört zu keiner Organisation.
                </div>
            </ng-template>
        </div>
    </div>
</div>
