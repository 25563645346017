<div class="micromate-card">
    <div class="micromate-card-header">
        <h1 i18n>Inbox</h1>
    </div>
    <div *ngIf="learningPackageInbox$ | async as inboxData">
        <div>
            <table class="micromate-table"
                   *ngIf="inboxData.data !== undefined && inboxData.data !== null && inboxData.data.length > 0; else emptyTable">
                <tr class="table-container table-container-header">
                    <th class="row-header" i18n>Verfasser</th>
                    <th class="row-header" i18n>Nachricht</th>
                    <th class="row-header" i18n>Zeit</th>
                </tr>
                <div class="table-container-data" #tableContentRef>
                    <tr class="table-container table-content" *ngFor="let inboxElement of inboxData.data">
                        <td class="row row-only-web">
                            <app-text-overflow-ellipsis [text]="inboxElement.creator"></app-text-overflow-ellipsis>
                        </td>
                        <td class="row row-title"
                            (click)="openFeedback(inboxElement.itemId)">
                            <app-text-overflow-ellipsis [text]="inboxElement.description"></app-text-overflow-ellipsis>
                        </td>
                        <td class="row">
                            <app-text-overflow-ellipsis
                                [text]="inboxElement.creationDate | date:'short'"></app-text-overflow-ellipsis>
                        </td>
                    </tr>
                </div>
            </table>
            <ng-template #emptyTable>
                <app-message-box class="mm-inbox-empty-message"
                                 type='warning' [showIcon]="false"
                                 header="Die Inbox ist leer"
                                 i18n-header></app-message-box>
            </ng-template>
            <app-table-pagination
                *ngIf="inboxData.data  !== undefined && inboxData.data !== null && inboxData.data.length > 0"
                [paginationParams]="tablePaginationParamsService.paginationParams.getValue()"
                [totalDataAmount]="inboxData.totalDataAmount"
                [navigateUrl]="getNavigationUrl()"></app-table-pagination>
        </div>

    </div>
</div>
