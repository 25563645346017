<button class="mm-button" [attr.disabled]="(disabled || isActionInProgress) ? true : null"
        type="button"
        (click)="execute()"
        [class.mm-button-style-primary]="type === 'primary'"
        [class.mm-button-style-secondary]="type === 'secondary'"
        [class.mm-button-style-ai]="type === 'ai'"
        [class.mm-button-icon-style-secondary-dark]="type === 'secondary-dark'"
        [class.mm-button-icon-style-transparent]="type === 'transparent'"
        [class.mm-button-icon-style-dangerous]="type === 'dangerous'">
    <app-icon *ngIf="icon !== undefined && !isActionInProgress" [icon]="icon" class="mm-button-icon"></app-icon>
    <app-spinner [style]="type" *ngIf="isActionInProgress" class="mm-button-spinner"></app-spinner>
    <ng-content></ng-content>
</button>
