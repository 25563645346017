<div class="mm-import-questions">
    <div class="micromate-spinner-element" *ngIf="(importWizardService.spinnerVisible$ | async)">
        <app-spinner class="micromate-spinner"></app-spinner>
    </div>

    <app-csv-file-wizard-import-page
        *ngIf="importWizardService.currentStep ==='csv-question-import'"></app-csv-file-wizard-import-page>
    <app-ressource-wizard-import
        *ngIf="importWizardService.currentStep ==='ressource-question-import'"></app-ressource-wizard-import>
    <app-learning-package-wizard-import
        *ngIf="importWizardService.currentStep ==='learning-package-import'"></app-learning-package-wizard-import>
</div>

