import {ChangeDetectorRef, Component} from '@angular/core';
import {NavigationService} from '../../core/navigation.service';
import {AppNavigationItem} from '../../core/api-types/app-navigation-item';
import {Router} from '@angular/router';
import {mobileMediaMatch} from '../../global-variables';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthUntilUserOrganizationRoleService} from '../../core/authentication/auth-until-user-organization-role.service';
import {AuthService} from '../../core/authentication/auth.service';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';

@Component({
    selector: 'app-app-navigation',
    templateUrl: './app-navigation.component.html',
    styleUrls: ['./app-navigation.component.scss']
})
export class AppNavigationComponent {

    public mobileQuery: MediaQueryList;
    private readonly mobileQueryListener: () => void;

    constructor(public navigationService: NavigationService,
                public authService: AuthService,
                public userRoleService: AuthUntilUserRoleService,
                public userOrganizationRoleService: AuthUntilUserOrganizationRoleService,
                private router: Router,
                public media: MediaMatcher,
                public changeDetectorRef: ChangeDetectorRef) {
        this.mobileQuery = media.matchMedia(mobileMediaMatch);
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        // IE and Edge support only this! Also not deprecated according to https://github.com/microsoft/TypeScript/issues/32210
        this.mobileQuery.addListener(this.mobileQueryListener);
    }

    public showAppPageNavigation(appNavigationItem: AppNavigationItem): void {
        if (this.mobileQuery.matches && this.navigationService.mobileNavigationOpen$.getValue()) {
            this.navigationService.activeAppNavigationItem$.next(appNavigationItem.url);
            if (appNavigationItem.appPageNavigation !== undefined) {
                this.navigationService.activeAppPageNavigation$.next(appNavigationItem.appPageNavigation);
            }
            this.navigationService.mobileNavigationDisplay$.next(this.navigationService.activeAppNavigationItem$.getValue().length > 0);
        }
    }


    public getUrl(appNavigationItem: AppNavigationItem, organizationAdmin: boolean): string[] {
        if (this.mobileQuery.matches && this.navigationService.mobileNavigationOpen$.getValue()) {
            return this.redirect(`${appNavigationItem.url}`, organizationAdmin);
        } else {
            if (appNavigationItem.appPageNavigation !== undefined) {
                return this.redirect(`${appNavigationItem.url}/${appNavigationItem.appPageNavigation[0].url}`, organizationAdmin);
            }
            return this.redirect(`${appNavigationItem.url}`, organizationAdmin);

        }
    }

    private redirect(url: string, organizationAdmin: boolean): string[] {
        const navigateUrl: string[] = [];
        if (organizationAdmin) {
            navigateUrl.push('organisation');
        }
        navigateUrl.push(url);
        return navigateUrl;
    }
}
