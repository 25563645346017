import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Learner} from '../../core/api-types/learner';
import {LearnerResource} from '../../core/rest/learner-resource.service';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {ListPagination} from '../../core/api-types/listPagination';
import {TablePaginationParamsService} from '../../core/table-pagination-params.service';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {Subscription} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'app-learner-overview',
    templateUrl: './learner-overview.component.html',
    styleUrls: ['./learner-overview.component.scss']
})
export class LearnerOverviewComponent implements OnInit, OnDestroy {
    public learners: Learner[];
    public paginationParams: ListPaginationRequest;
    public listPagination: ListPagination<Learner>;
    public navigationUrl: string[] = ['administration', 'lernende'];
    public subscription = new Subscription();

    @ViewChild('tableContentRef') tableContentRef: ElementRef;

    constructor(private learnerRestService: LearnerResource,
                public multilanguageService: MultilanguageService,
                private tablePaginationParamsService: TablePaginationParamsService,
                private router: Router) {
    }

    public ngOnInit(): void {
        this.tablePaginationParamsService.updateFromActivatedRouteParams();

        this.subscription = this.tablePaginationParamsService.paginationParams$.subscribe((paginationParams: ListPaginationRequest) => {
            this.paginationParams = paginationParams;
            this.learnerRestService.getAllForPagination(this.paginationParams).subscribe((listPagination: ListPagination<Learner>) => {
                this.listPagination = listPagination;
                this.learners = listPagination.data;
                if (this.tableContentRef !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    this.tableContentRef.nativeElement.scrollTop = 0;
                }
            });
        });
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.tablePaginationParamsService.unSubscribeActiveRoute();
    }

    public redirectToLearnerInfo(organizationId: string): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['administration', 'lernende', organizationId]);
    }


}
