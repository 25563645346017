<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 2228.833 2073.333"
     *ngIf="logo === 'mirosoft-teams-logo'">
    <path fill="#5059C9"
          d="M1554.637,777.5h575.713c54.391,0,98.483,44.092,98.483,98.483c0,0,0,0,0,0v524.398	c0,199.901-162.051,361.952-361.952,361.952h0h-1.711c-199.901,0.028-361.975-162-362.004-361.901c0-0.017,0-0.034,0-0.052V828.971	C1503.167,800.544,1526.211,777.5,1554.637,777.5L1554.637,777.5z"/>
    <circle fill="#5059C9" cx="1943.75" cy="440.583" r="233.25"/>
    <circle fill="#7B83EB" cx="1218.083" cy="336.917" r="336.917"/>
    <path fill="#7B83EB"
          d="M1667.323,777.5H717.01c-53.743,1.33-96.257,45.931-95.01,99.676v598.105	c-7.505,322.519,247.657,590.16,570.167,598.053c322.51-7.893,577.671-275.534,570.167-598.053V877.176	C1763.579,823.431,1721.066,778.83,1667.323,777.5z"/>
    <path opacity=".1"
          d="M1244,777.5v838.145c-0.258,38.435-23.549,72.964-59.09,87.598	c-11.316,4.787-23.478,7.254-35.765,7.257H667.613c-6.738-17.105-12.958-34.21-18.142-51.833	c-18.144-59.477-27.402-121.307-27.472-183.49V877.02c-1.246-53.659,41.198-98.19,94.855-99.52H1244z"/>
    <path opacity=".2"
          d="M1192.167,777.5v889.978c-0.002,12.287-2.47,24.449-7.257,35.765	c-14.634,35.541-49.163,58.833-87.598,59.09H691.975c-8.812-17.105-17.105-34.21-24.362-51.833	c-7.257-17.623-12.958-34.21-18.142-51.833c-18.144-59.476-27.402-121.307-27.472-183.49V877.02	c-1.246-53.659,41.198-98.19,94.855-99.52H1192.167z"/>
    <path opacity=".2"
          d="M1192.167,777.5v786.312c-0.395,52.223-42.632,94.46-94.855,94.855h-447.84	c-18.144-59.476-27.402-121.307-27.472-183.49V877.02c-1.246-53.659,41.198-98.19,94.855-99.52H1192.167z"/>
    <path opacity=".2"
          d="M1140.333,777.5v786.312c-0.395,52.223-42.632,94.46-94.855,94.855H649.472	c-18.144-59.476-27.402-121.307-27.472-183.49V877.02c-1.246-53.659,41.198-98.19,94.855-99.52H1140.333z"/>
    <path opacity=".1"
          d="M1244,509.522v163.275c-8.812,0.518-17.105,1.037-25.917,1.037	c-8.812,0-17.105-0.518-25.917-1.037c-17.496-1.161-34.848-3.937-51.833-8.293c-104.963-24.857-191.679-98.469-233.25-198.003	c-7.153-16.715-12.706-34.071-16.587-51.833h258.648C1201.449,414.866,1243.801,457.217,1244,509.522z"/>
    <path opacity=".2"
          d="M1192.167,561.355v111.442c-17.496-1.161-34.848-3.937-51.833-8.293	c-104.963-24.857-191.679-98.469-233.25-198.003h190.228C1149.616,466.699,1191.968,509.051,1192.167,561.355z"/>
    <path opacity=".2"
          d="M1192.167,561.355v111.442c-17.496-1.161-34.848-3.937-51.833-8.293	c-104.963-24.857-191.679-98.469-233.25-198.003h190.228C1149.616,466.699,1191.968,509.051,1192.167,561.355z"/>
    <path opacity=".2"
          d="M1140.333,561.355v103.148c-104.963-24.857-191.679-98.469-233.25-198.003	h138.395C1097.783,466.699,1140.134,509.051,1140.333,561.355z"/>
    <linearGradient id="a" gradientUnits="userSpaceOnUse" x1="198.099" y1="1683.0726" x2="942.2344" y2="394.2607"
                    gradientTransform="matrix(1 0 0 -1 0 2075.3333)">
        <stop offset="0" stop-color="#5a62c3"/>
        <stop offset=".5" stop-color="#4d55bd"/>
        <stop offset="1" stop-color="#3940ab"/>
    </linearGradient>
    <path fill="url(#a)"
          d="M95.01,466.5h950.312c52.473,0,95.01,42.538,95.01,95.01v950.312c0,52.473-42.538,95.01-95.01,95.01	H95.01c-52.473,0-95.01-42.538-95.01-95.01V561.51C0,509.038,42.538,466.5,95.01,466.5z"/>
    <path fill="#FFF" d="M820.211,828.193H630.241v517.297H509.211V828.193H320.123V727.844h500.088V828.193z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 80.01 74.72" *ngIf="logo === 'micromate-logo'">
    <defs>
        <style>.cls-1 {
            fill: url(#linear-gradient);
        }

        .cls-2 {
            fill: #f9ba41;
        }

        .cls-3 {
            fill: #fece4c;
        }

        .cls-4 {
            fill: #fcc445;
        }

        .cls-5 {
            fill: #f3a031;
        }

        .cls-6 {
            fill: #f8b13a;
        }

        .cls-7 {
            fill: #ee9842;
        }

        .cls-8 {
            fill: #f5a836;
        }

        .cls-9 {
            fill: #f4ba5d;
        }</style>
        <linearGradient id="linear-gradient" x1="64.45" y1="53" x2="22.74" y2="35.21" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#fdca2d"/>
            <stop offset="0.43" stop-color="#fbbd29"/>
            <stop offset="1" stop-color="#f7a521"/>
        </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
            <path class="cls-1"
                  d="M79.4,74.68H42.87A.88.88,0,0,1,42,73.49q2.22-6.1,4.45-12.22Q43.24,53.46,40,45.64L33.52,61.26,38,73.48a.88.88,0,0,1-.83,1.19H.61a.61.61,0,0,1-.58-.8l24-72.13L24.51.45a.66.66,0,0,1,1.25,0L40,36,54.25.43a.66.66,0,0,1,1.25,0l.43,1.29L80,73.88A.61.61,0,0,1,79.4,74.68Z"/>
            <path class="cls-2" d="M69,40.93l-30.94,0L54.25.43a.67.67,0,0,1,1.25,0Q62.25,20.7,69,40.93Z"/>
            <path class="cls-3"
                  d="M72.19,50.53Q76.09,62.2,80,73.88a.6.6,0,0,1-.58.8H42.87a.92.92,0,0,1-.66-.29,1,1,0,0,1-.17-.9q2.22-6.1,4.45-12.22Z"/>
            <path class="cls-4"
                  d="M79.08,40.93H38.31a.18.18,0,0,0-.16.25L51.82,74.42a.17.17,0,0,0,.3,0L79.5,41.82A.55.55,0,0,0,79.08,40.93Z"/>
            <path class="cls-5"
                  d="M7.27,52.15Q3.64,63,0,73.88a.72.72,0,0,0,.05.48.84.84,0,0,0,1,.32h36.1a.89.89,0,0,0,.62-.25.93.93,0,0,0,.21-.94q-2.22-6.11-4.45-12.22L8,49.94C7.83,50.49,7.57,51.24,7.27,52.15Z"/>
            <path class="cls-6"
                  d="M11,40.92H41.66a.18.18,0,0,0,.18-.25C36.66,27.46,26.35,1.23,25.76.42A.84.84,0,0,0,25.15,0a.69.69,0,0,0-.64.45Z"/>
            <path class="cls-7" d="M33.52,61.26l.39,1.07L29,74.68H28Z"/>
            <path class="cls-8"
                  d="M1,40.9H41.72a.18.18,0,0,1,.17.24L28.22,74.38a.18.18,0,0,1-.3.05L.54,41.79A.54.54,0,0,1,1,40.9Z"/>
            <path class="cls-9" d="M52,74.68h1.31Q62.88,63,72.48,51.38c-.1-.28-.19-.57-.29-.85Z"/>
        </g>
    </g>
</svg>
