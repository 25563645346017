import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ResourceHelper} from './resource-helper.service';
import {Resource} from '../api-types/Resource';
import {DocumentState} from '../api-types/DocumentState';
import {Quiz} from '../api-types/quiz';
import {QuizTypeName} from '../editor/types/QuizType';
import {UpdateResourceRequestDto} from './update-resource-request.dto';
import {GeneratedQuestionResponseDto} from './generated-question-response.dto';

@Injectable({
    providedIn: 'root'
})
export class AiRessource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public getResources(learningPackageId: string, state?: DocumentState): Observable<Resource[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(baseUrl => {
            const url = `${baseUrl}/ai/learningpackage/${learningPackageId}/resources`;
            if (state !== undefined) {
                return this.http.get<Resource[]>(url, {params: {state: state}});
            }
            return this.http.get<Resource[]>(url);
        }));
    }

    public getQuestionsForDocument(learningPackageId: string, resourceId: string): Observable<GeneratedQuestionResponseDto[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<GeneratedQuestionResponseDto[]>(`${url}/ai/learningpackage/${learningPackageId}/resources/${resourceId}/questions`)));
    }

    public importResource(learningPackageId: string, name: string, file: File): Observable<{ resourceId: string; }> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<{ resourceId: string; }>(`${url}/ai/learningpackage/${learningPackageId}/resource`, file, {
            params: {
                name: name
            }, headers: {
                'Content-Type': file.type
            }
        })));
    }

    public async deleteResource(learningPackageId: string, resourceId: string): Promise<void> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.delete<void>(`${url}/ai/learningpackage/${learningPackageId}/resources/${resourceId}`))));
    }

    public generateQuestionFromText(text: string, questionType: QuizTypeName, locale: string): Observable<Quiz> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<Quiz>(`${url}/ai/questions/generate/text`, {text: text, questionType: questionType}, {params: {locale: locale}})));
    }

    public async updateResource(learningPackageId: string, resourceId: string, dto: UpdateResourceRequestDto): Promise<void> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.put<void>(`${url}/ai/learningpackage/${learningPackageId}/resources/${resourceId}`, dto))));
    }
}

