<div class="micromate-editor-question-card" *ngFor="let question of questionsPresentation, let i = index"
     [class.micromate-editor-question-card-draft]="question.isDraft"
     [class.micromate-editor-question-card-ready]="!question.isDraft"
     [class.micromate-editor-question-card-reflection]="question.quizType === 'ReflectionFreeText'"
     [class.micromate-editor-question-card-active]="(editorQuestionService.selectedQuizId$ | async)!== undefined && (editorQuestionService.selectedQuizId$ | async) === question.quizId"
     (click)="openQuestion(question.quizId)">
    <div class="micromate-editor-question-card-question-and-tags">
        <div class="micromate-editor-question-card-question">
            {{question?.quiz?.text}}
        </div>
        <app-tags-list class="micromate-editor-question-card-tags"
                       *ngIf="availableTags !== undefined && question.selectedTags !== undefined"
                       size="small"
                       [availableTags]="availableTags"
                       [(selectedAvailableTags)]="question.selectedTags"
                       [(selectedNewTags)]="selectedNewTags"></app-tags-list>
    </div>

    <div class="micromate-editor-question-card-info">
        <div
            class="micromate-editor-question-card-info-element micromate-editor-question-card-info-element-icon"
            [class.micromate-editor-question-card-info-element-no-border]="question.quizType === 'ReflectionFreeText' && !learningPackageHasTranslation">
            <app-icon [icon]="findQuestionType(question.quizType).icon"
                      [title]="findQuestionType(question.quizType).title"
                      class="micromate-buttont-text-icon"
                      [class.micromate-buttont-text-icon-reflection]="question.quizType === 'ReflectionFreeText'"></app-icon>
        </div>
        <div *ngIf="question.quizType !== 'ReflectionFreeText'"
             class="micromate-editor-question-card-info-element micromate-editor-question-card-info-element-ranking"
             title="Platzierung" i18n-title>
            <div class="micromate-editor-question-card-info-element-ranking-place">
                {{question?.rankingPlace !== undefined ? question.rankingPlace : '-'}}
            </div>
            <app-icon icon="ranking-star-light"
                      class="micromate-editor-question-card-info-element-ranking-icon">
            </app-icon>
        </div>
        <div class="micromate-editor-question-card-info-element mm-editor-question-card-info-element-settings"
             [class.mm-editor-question-card-info-element-settings-with-border]="question.quizType !== 'ReflectionFreeText'">
            <app-icon icon="language-light"
                      *ngIf="learningPackageHasTranslation"
                      [title]="getTranslationsStatusTitle(question.haveTranslations)"
                      class="mm-editor-question-card-info-element-settings-icon mm-editor-question-card-info-element-settings-icon-language"
                      [class.mm-editor-question-card-info-element-settings-icon-inactive]="!question.haveTranslations">
            </app-icon>
            <div class="mm-editor-question-card-info-element-settings-icon-separator"
                 *ngIf="learningPackageHasTranslation"></div>
            <app-icon icon="message-pen-light"
                      class="mm-editor-question-card-info-element-settings-icon mm-editor-question-card-info-element-settings-icon-feedback"
                      [title]="getFeedbackStatusTitle(question.hasOpenFeedback)"
                      [class.mm-editor-question-card-info-element-settings-icon-inactive]="!question.hasOpenFeedback">
            </app-icon>
        </div>
    </div>
</div>
