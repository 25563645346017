<div class="micormate-editor-drop-preview-icon" *ngIf="mediaType !== undefined">
    <svg aria-hidden="true" focusable="false" viewBox="0 0 512 512"
         class="image-editor-drop-preview-icon-svg"
         *ngIf="mediaType === 'movie'">
        <use href="./assets/icons.svg#film-alt-light"></use>
    </svg>
    <svg aria-hidden="true" focusable="false" viewBox="0 0 576 512"
         class="image-editor-drop-preview-icon-svg"
         *ngIf="mediaType === 'audio'">
        <use href="./assets/icons.svg#volume-up-light"></use>
    </svg>
    <svg aria-hidden="true" focusable="false" viewBox="0 0 512 512"
         class="image-editor-drop-preview-icon-svg"
         *ngIf="mediaType === 'image'">
        <use href="./assets/icons.svg#image-light"></use>
    </svg>
    <svg aria-hidden="true" focusable="false" viewBox="0 0 512 512"
         class="image-editor-drop-preview-icon-svg"
         *ngIf="mediaType === 'website'">
        <use href="./assets/icons.svg#globe-pointer-light"></use>
    </svg>
</div>
