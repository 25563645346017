import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth.service';

@Component({
    templateUrl: './code-exchange.component.html',
    styleUrls: ['./code-exchange.component.scss']
})
export class CodeExchangeComponent implements OnInit {
    constructor(private authService: AuthService) {
    }

    public ngOnInit(): void {
       void this.authService.startCodeExchangeFlow();
    }
}
