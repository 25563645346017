import {Component, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {OverlayService} from './overlay.service';

@Component({
    selector: 'app-overlay',
    templateUrl: './overlay.component.html',
    styleUrls: ['./overlay.component.scss']
})
export class OverlayComponent implements OnInit {
    @ViewChild('overlayContent', {static: true, read: ViewContainerRef})
    public viewContainerRef: ViewContainerRef;

    public right: number;
    public top: number;

    constructor(public overlayService: OverlayService) {
    }

    public ngOnInit(): void {
        this.overlayService.initialize(this);
    }

    public setCallingElementSize(callingElementSize: DOMRect): void {
        this.right = window.innerWidth - callingElementSize.right;
        this.top = callingElementSize.bottom;
    }
}
