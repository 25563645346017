import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {OrganizationResources} from '../../../core/rest/organization-resources.service';
import {LearnerResource} from '../../../core/rest/learner-resource.service';
import {OrganizationApi} from '../../../core/api-types/organizationApi';
import {AdminService} from '../../../core/admin/admin.service';
import {HttpErrorResponse} from '@angular/common/http';
import {OrganizationFormGroup} from './OrganizationFormGroup';
import {Popup} from '../../../components/popup/Popup';
import {PopupService} from '../../../components/popup/popup.service';
import {OrganizationFormPopupResult} from './OrganizationFormPopupResult';
import {FormCustomValidation} from '../../../core/form-custom-validation';

@Component({
    selector: 'app-organization-form',
    templateUrl: './organization-form.component.html',
    styleUrls: ['./organization-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OrganizationFormComponent implements Popup, OnInit {
    @Input()
    public data: undefined;

    public isNameValid = true;

    public formData = new FormGroup<OrganizationFormGroup>({
        name: new FormControl<string>('', [FormCustomValidation.trimmedRequired, FormCustomValidation.trimmedMaxLengthValidator(50)]),
        createDemoLearningPackage: new FormControl<boolean>(true)
    });

    constructor(private learnerRestService: LearnerResource,
                private organizationRestService: OrganizationResources,
                private adminService: AdminService,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.formData.valueChanges.subscribe(() => this.isNameValid = true);
    }

    public saveOrganization: () => Promise<void> = async () => {
        return new Promise(resolve => {
            const organization = this.formData.getRawValue();
            const newOrganization = new OrganizationApi(organization.name.trim(), organization.createDemoLearningPackage);
            this.organizationRestService.createOrganization(newOrganization).subscribe(
                () => {
                    this.popupService.close<OrganizationFormPopupResult>();
                    this.clearForm();
                    resolve();
                },
                (error: HttpErrorResponse) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    if (error.error.toString().includes('Eine Organisation mit dem Namen') === true) {
                        this.isNameValid = false;
                        resolve();
                    } else {
                        this.popupService.close<OrganizationFormPopupResult>('creationError');
                        this.clearForm();
                        resolve();
                    }
                });
        });
    };

    public cancel(): void {
        this.popupService.close();
    }

    private clearForm(): void {
        this.formData.controls['name'].setValue('');
    }
}
