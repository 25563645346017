import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatePeriodDataPointDto} from './DatePeriodDataPointDto';
import {KPIsDto} from './KPIsDto';
import {TimeSplitMode} from './TimeSplitMode';
import {ResourceHelper} from '../resource-helper.service';
import {firstValueFrom, Observable, switchMap} from 'rxjs';
import {LearnerLearningGoalsOverview} from '../../api-types/learnerLearningGoalsOverview';
import {GroupLearningPackageOverview} from '../../api-types/groupLearningPackageOverview';
import {ListPagination} from '../../api-types/listPagination';
import {ListPaginationRequest} from '../../api-types/listPaginationRequest';
import {AnalyticsGroupOverview} from '../../viewmodel/analyticsGroupOverview';
import {OrganizationAnalyticsOverview} from './OrganizationAnalyticsOverview';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsResource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public async getEngagementForPreviousTimePeriod(groupId: string, chartDataTimeSplitMode: TimeSplitMode, learningPackageId: string): Promise<DatePeriodDataPointDto[]> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<DatePeriodDataPointDto[]>(`${url}/analytics/engagement-for-previous-time-period/group/${groupId}?chartDataTimeSplitMode=${chartDataTimeSplitMode}&learningPackageId=${learningPackageId}`))));
    }

    public async getSuccessRatioForPreviousTimePeriod(groupId: string, chartDataTimeSplitMode: TimeSplitMode, learningPackageId: string): Promise<DatePeriodDataPointDto[]> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<DatePeriodDataPointDto[]>(`${url}/analytics/success-ratio-for-previous-time-period/group/${groupId}?chartDataTimeSplitMode=${chartDataTimeSplitMode}&learningPackageId=${learningPackageId}`))));
    }

    public async getActiveLearnersForPreviousTimePeriod(groupId: string, chartDataTimeSplitMode: TimeSplitMode, learningPackageId: string): Promise<DatePeriodDataPointDto[]> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<DatePeriodDataPointDto[]>(`${url}/analytics/active-learners-for-previous-time-period/group/${groupId}?chartDataTimeSplitMode=${chartDataTimeSplitMode}&learningPackageId=${learningPackageId}`))));

    }

    public async getKPIs(groupId: string, learningPackageId: string): Promise<KPIsDto> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<KPIsDto>(`${url}/analytics/kpis/group/${groupId}?learningPackageId=${learningPackageId}`))));
    }

    public getLearnersLearningPackagesGoalsOverview(groupId: string, listPaginationRequest: ListPaginationRequest): Observable<ListPagination<LearnerLearningGoalsOverview>> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<ListPagination<LearnerLearningGoalsOverview>>(`${url}/analytics/group/${groupId}/learners-learning-goals?filter=${listPaginationRequest.filter}&pageNumber=${listPaginationRequest.pageNumber}&pageAmount=${listPaginationRequest.pageAmount}`)));
    }

    public getGroupLearningPackagesOverview(groupId: string): Observable<GroupLearningPackageOverview[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<GroupLearningPackageOverview[]>(`${url}/analytics/group/${groupId}/learning-packages`)));
    }

    public getOrganizationAnalyticsGroupsOverviews(): Observable<AnalyticsGroupOverview[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<AnalyticsGroupOverview[]>(`${url}/analytics/groups`)));
    }

    public async getAnalyticsGroupOverview(groupId: string, learningPackageId: string): Promise<AnalyticsGroupOverview> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<AnalyticsGroupOverview>(`${url}/analytics/groups/${groupId}?learningPackageId=${learningPackageId}`))));
    }

    public getOrganizationAnalyticsOverview(): Observable<OrganizationAnalyticsOverview> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<OrganizationAnalyticsOverview>(`${url}/analytics`)));
    }
}
