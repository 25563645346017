<div class="mm-feedback" *ngIf="feedback">
    <div class="mm-feedback-data">
        <div class="mm-feedback-data-and-actions">
            <div class="mm-feedback-header">
                <h3 class="mm-feedback-title">
                    {{getFeedbackTopic(feedback.feedbackCategory)}}
                </h3>

                <div class="mm-feedback-info">
                    {{feedback.creationDate | date:'short'}} - {{feedback.creator.name}}
                    - {{multilanguageService.createShortName(feedback.locale)}}
                </div>
            </div>
            <div class="mm-feedback-actions">
                <div class="mm-feedback-actions-box">
                    <div class="mm-feedback-actions-ranking"
                         [@widthAnimation]="showRating ? 'expanded' : 'collapsed'">
                        <app-icon icon="thumbs-up-light"
                                  title="Hilfreiches Feedback" i18n-title
                                  (click)="setPositiveRanking()"
                                  class="mm-feedback-actions-icon mm-feedback-actions-icon-thumbs-up"></app-icon>
                        <app-icon icon="thumbs-down-light"
                                  title="Nicht hilfreiches Feedback" i18n-title
                                  (click)="setNegativeRanking()"
                                  class="mm-feedback-actions-icon mm-feedback-actions-icon-thumbs-down"></app-icon>
                    </div>
                    <div class="mm-feedback-actions-status">
                        <app-icon icon="square-light"
                                  *ngIf="isFeedbackStatusOpen()"
                                  title="Als erledigt markieren" i18n-title
                                  (click)="acceptFeedback()"
                                  class="mm-feedback-actions-icon"
                                  [class.mm-feedback-actions-icon-inactive]="showRating"></app-icon>
                        <app-icon icon="square-check-light"
                                  *ngIf="isFeedbackStatusAccept()"
                                  title="Als nicht erledigt markieren" i18n-title
                                  (click)="reopenFeedback()"
                                  class="mm-feedback-actions-icon"></app-icon>
                    </div>
                </div>
            </div>
        </div>

        <div class="mm-feedback-text">{{feedback.feedback}}</div>
    </div>

</div>
