import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {LearningPackageResource} from '../../../core/rest/learning-package-resource.service';
import {GroupService} from '../../../core/group.service';
import {LearningPackageForGroup} from './LearningPackageForGroup';
import {Subscription} from 'rxjs';
import {LearningGroupResource} from '../../../core/rest/learning-group-resource.service';
import {GroupActivationTag} from '../../../core/api-types/GroupActivationTag';
import {GroupLearningPackageSettings} from '../../../core/api-types/GroupLearningPackageSettings';
import {PopupService} from 'src/app/components/popup/popup.service';
import {Popup} from '../../../components/popup/Popup';
import {AddLearningPackageToGroupPopupData} from './AddLearningPackageToGroupPopupData';

@Component({
    selector: 'app-learning-package-activate-component',
    templateUrl: './learning-package-activate-component.component.html',
    styleUrls: ['./learning-package-activate-component.component.scss']
})
export class LearningPackageActivateComponentComponent implements Popup<AddLearningPackageToGroupPopupData>, OnInit, OnDestroy {
    @Input()
    public data: AddLearningPackageToGroupPopupData;

    public availableLearningPackages: { _id: string; name: string; }[];
    public formData = new FormGroup<{ learningPackages: FormControl<LearningPackageForGroup[]>; }>({
        learningPackages: new FormControl<LearningPackageForGroup[]>([])
    });

    public learningPackageDropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: '_id',
        textField: 'name',
        enableCheckAll: false,
        allowSearchFilter: true,
        searchPlaceholderText: 'Lernpaket suchen',
        noDataAvailablePlaceholderText: 'Keine Lernpakete verfügbar',
        closeDropDownOnSelection: true
    };
    public learningGoal: number;

    public formDataValueChanges$ = new Subscription();

    public manualActivationTags: GroupActivationTag[];
    public changedManualActivationTags: GroupActivationTag[];
    public manualActivation: boolean = false;

    constructor(private learningPackageService: LearningPackageResource,
                private groupService: GroupService,
                private groupRestService: LearningGroupResource,
                private popupService: PopupService) {
    }

    public ngOnDestroy(): void {
        this.formDataValueChanges$.unsubscribe();
    }

    public ngOnInit(): void {
        this.learningPackageService.getAllAvailableForGroup(this.data.groupId).subscribe(learningPackagesData => {
            this.availableLearningPackages = learningPackagesData;
        });

        this.formDataValueChanges$ = this.formData.valueChanges.subscribe(data => {
            this.manualActivation = false;
            this.manualActivationTags = undefined;
            if (data.learningPackages.length > 0) {
                const learningPackageId = data.learningPackages[0]._id;
                this.groupRestService.getLearningPackageActivationTags(this.data.groupId, learningPackageId).subscribe(tags => this.manualActivationTags = tags);
            }
        });
    }

    public activateLearningPackageForGroup: () => Promise<void> = async () => {
        return new Promise(resolve => {
            const learningPackages = this.formData.value.learningPackages;
            const groupLearningPackageSettings = new GroupLearningPackageSettings(this.learningGoal, this.manualActivation);
            if (this.manualActivation) {
                groupLearningPackageSettings.groupActivationTags = this.changedManualActivationTags;
            }
            this.learningPackageService.activateLearningPackageForGroup(this.data.groupId, learningPackages[0]._id, groupLearningPackageSettings).subscribe(() => {
                this.groupService.selectedGroupId.next(this.data.groupId);
                this.popupService.close();
                this.formData.controls['learningPackages'].setValue([]);
                resolve();
            });
        });
    };

    public cancel(): void {
        this.popupService.close();
    }

    public changedLearningGoal(learningGoal: number): void {
        this.learningGoal = learningGoal;
    }

    public manualActivationChanged(event: { manualActivation: boolean; changedManualActivationTags: GroupActivationTag[]; }): void {
        this.manualActivation = event.manualActivation;
        this.changedManualActivationTags = event.changedManualActivationTags;
    }

}
