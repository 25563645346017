import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ResourceUploadError} from './ResourceuploadError';

export type ImportStep = 'import' | 'settings' | 'result';

@Injectable({
    providedIn: 'root'
})

export class NewResourcePopupService {
    public currentStep: ImportStep;
    private resourceFile: File;
    private resourceName: string;
    private uploadFileErrors: ResourceUploadError;

    public spinnerVisibleSubject = new BehaviorSubject<boolean>(false);
    public spinnerVisible$: Observable<boolean> = this.spinnerVisibleSubject.asObservable();

    public showSpinner(): void {
        this.spinnerVisibleSubject.next(true);
    }

    public hideSpinner(): void {
        this.spinnerVisibleSubject.next(false);
    }

    public setResourceFile(file: File): void {
        this.resourceFile = file;
    }

    public getResourceFile(): File {
        return this.resourceFile;
    }

    public setResourceName(name: string): void {
        this.resourceName = name;
    }

    public getResourceName(): string {
        return this.resourceName;
    }

    public setUploadFileErrors(errors: ResourceUploadError): void {
        this.uploadFileErrors = errors;
    }

    public getUploadFileErrors(): ResourceUploadError {
        return this.uploadFileErrors;
    }
}
