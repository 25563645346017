<div class="micromate-drag-and-drop"
     i18n-title title="Keine Datei ausgewählt">
    <div class="micromate-droparea"
         (dragenter)="hoverDragAndDrop = true"
         (dragover)="onDragOver($event)"
         (drop)="onDrop($event)"
         (dragleave)="onDragLeave($event)"
         [class.micromate-droparea-hover]="hoverDragAndDrop">
        <input type="file" [accept]="acceptFileType" class="micromate-browse-file"
               (change)="onSelectedFile($event.currentTarget)"/>
        <p class="micromate-browse-file-text" i18n>
            Klicken und Datei auswählen
            <br/> oder<br/>
            Datei hier ablegen </p>
    </div>
</div>
