import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {LearnerLearningGoalsOverview} from '../../core/api-types/learnerLearningGoalsOverview';
import {GroupLearningPackageOverview} from '../../core/api-types/groupLearningPackageOverview';
import {AnalyticsResource} from '../../core/rest/analytics-rest/analytics-resource.service';
import {Group} from '../../core/api-types/group';
import {GroupService} from '../../core/group.service';
import {combineLatest, Subscription} from 'rxjs';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {ListPagination} from '../../core/api-types/listPagination';
import {TablePaginationParamsService} from '../../core/table-pagination-params.service';
import {ToastrService} from 'ngx-toastr';
import {filter} from 'rxjs/operators';
import {AnalyticsAdminRestService} from '../../core/rest/analytics-rest/analytics-admin-rest.service';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';

@Component({
    selector: 'app-analytics-group-learning-goals',
    templateUrl: './analytics-group-learning-goals.component.html',
    styleUrls: ['./analytics-group-learning-goals.component.scss']
})
export class AnalyticsGroupLearningGoalsComponent implements OnInit, OnDestroy {
    public learnerLearningGoalsOverview: LearnerLearningGoalsOverview[];
    public groupLearningPackages: GroupLearningPackageOverview[];
    private viewModelObservable = new Subscription();
    public paginationParams: ListPaginationRequest;
    public listPagination: ListPagination<LearnerLearningGoalsOverview>;
    public navigationUrl: string[] = ['analytics', 'lerngruppen', '', 'lernziele'];
    public activeGroup: Group;
    public allowExportFile = true;

    @ViewChild('tableContentRef') tableContentRef: ElementRef;

    constructor(private analyticsRestService: AnalyticsResource,
                private analyticsAdminRestService: AnalyticsAdminRestService,
                private groupService: GroupService,
                private tablePaginationParamsService: TablePaginationParamsService,
                public userRoleService: AuthUntilUserRoleService,
                private toastr: ToastrService) {
    }

    public ngOnInit(): void {
        this.tablePaginationParamsService.updateFromActivatedRouteParams();

        this.viewModelObservable = combineLatest([this.groupService.activeGroup$, this.tablePaginationParamsService.paginationParams$]).pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter(([group]: [Group, ListPaginationRequest]) => !!group))
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            .subscribe(async ([group, paginationParams]: [Group, ListPaginationRequest]) => {
                this.activeGroup = group;
                this.navigationUrl = ['analytics', 'lerngruppen', this.activeGroup._id, 'lernziele'];

                this.analyticsRestService.getGroupLearningPackagesOverview(this.activeGroup._id).subscribe(groupLearningPackageOverview => this.groupLearningPackages = groupLearningPackageOverview);

                this.analyticsRestService.getLearnersLearningPackagesGoalsOverview(this.activeGroup._id, paginationParams).subscribe((learnerLearningGoalsOverview: ListPagination<LearnerLearningGoalsOverview>) => {
                    this.paginationParams = paginationParams;
                    this.listPagination = learnerLearningGoalsOverview;
                    this.learnerLearningGoalsOverview = learnerLearningGoalsOverview.data;
                    if (this.tableContentRef !== undefined) {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        this.tableContentRef.nativeElement.scrollTop = 0;
                    }
                });
            });
    }

    public ngOnDestroy(): void {
        this.viewModelObservable.unsubscribe();
        this.tablePaginationParamsService.unSubscribeActiveRoute();
    }

    public getLearnerGoalsForLearningPackage(learningPackageId: string, learnerLearningGoalsOverview: LearnerLearningGoalsOverview): boolean {
        return learnerLearningGoalsOverview.learningGoals.find(learningGoal => learningGoal.learningPackageId === learningPackageId).learningGoalReached;
    }

    public addLearningPackageColumn(): string {
        if (this.groupLearningPackages !== undefined) {
            const learningPackageColumns = this.groupLearningPackages.map(() => '100px');
            return learningPackageColumns.join(' ');
        }
    }

    public exportLearningGoals(): void {
        if (this.allowExportFile) {
            this.allowExportFile = false;
            if (this.activeGroup !== undefined) {
                this.analyticsAdminRestService.getLearningGoalsExport(this.activeGroup._id).then((data) => {
                    this.downloadFile(data.csvData, this.activeGroup.name);
                    setTimeout(() => {
                        this.allowExportFile = true;
                    }, 3000);
                }).catch(e => {
                    const error = e as HttpErrorResponse;
                    this.toastr.error(error.error as string);
                    this.allowExportFile = true;
                });
            }
        }
    }

    private downloadFile(data: string, name: string): void {
        const blob = new Blob([`\ufeff${data}`], {type: 'text/csv;charset=utf-8;'});
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        const filename = `Lernziele-${name.replace(' ', '-')}.csv`;
        dwldLink.setAttribute('download', filename);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
}
