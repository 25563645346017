import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrganizationAdminService {
    public addedNewLearner: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public addedNewLearner$ = this.addedNewLearner.asObservable();
}
