<div class="mm-question-preview-area">
    <p class="mm-question" [innerHTML]="questionPreview.quiz.text | markdown"></p>
    <app-media-preview *ngIf="hasQuestionMedia(questionPreview.quiz)"
                       [mediaData]="getMediaData(questionPreview.quiz)"></app-media-preview>

    <app-single-choice-question
        *ngIf="questionPreview.quizType === 'SingleTextChoice'"
        [questionPreview]="questionPreview">
    </app-single-choice-question>
    <app-multiple-choice-question
        *ngIf="questionPreview.quizType === 'MultipleTextChoice'"
        [questionPreview]="questionPreview">
    </app-multiple-choice-question>
    <app-free-text-question
        *ngIf="questionPreview.quizType === 'FreeText'"
        [questionPreview]="questionPreview">
    </app-free-text-question>
    <app-true-false-question
        *ngIf="questionPreview.quizType === 'TrueFalse'"
        [questionPreview]="questionPreview">
    </app-true-false-question>
</div>
