import {Injectable} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {shareReplay, switchMap} from 'rxjs/operators';
import {OrganizationAdminPanelResource} from '../../core/rest/organization-admin/organization-admin-panel-resource.service';
import {OrganizationLearnerBasicInfo} from '../../core/api-types/OrganizationLearnerBasicInfo';

@Injectable({
    providedIn: 'root'
})
export class OrganizationLearnerInfoAdminService {

    constructor(private router: Router,
                private organizationAdminPanelRestService: OrganizationAdminPanelResource) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.params.learnerId !== undefined) {
                    const learnerId = path.snapshot.params.learnerId as string;
                    this.selectedLearnerId.next(learnerId);
                }
            }
        });
    }

    public selectedLearnerId = new BehaviorSubject<string>(undefined);
    public selectedLearnerId$ = this.selectedLearnerId.asObservable();

    public selectedLearner$: Observable<OrganizationLearnerBasicInfo> = this.selectedLearnerId$.pipe(
        switchMap((learnerId: string) => {
            if (learnerId !== undefined) {
                return this.organizationAdminPanelRestService.getLearnerInfo(learnerId);
            }
            return of(undefined);
        }),
        shareReplay(1)
    );

    public refreshLearnerInfo(): void {
        this.selectedLearnerId.next(this.selectedLearnerId.value);
    }
}
