<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Organisation Events Löschen" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <app-warning-box>
            <div class="mm-popup-content-warning-message" i18n>
                Um alle Events aus einer Organisation zu entfernen, gib den Namen der Organisation
                <strong>{{data.name}}</strong> ein.
            </div>
        </app-warning-box>
        <form [formGroup]="formData" class="micormate-form">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Organisationsname
                    <span class="micormate-form-label-required">*</span>
                </label>
                <input type="text" formControlName="name" class="micormate-form-input"
                       [class.micormate-form-input-validation-error]="formData.value.name.length > 50">
                <div *ngIf="formData.value.name.length > 50" class="micormate-form-validation-error" i18n>Maximal 50
                    Zeichen
                </div>
            </div>

        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [action]="removeOrganizationEvents"
                [disabled]="!formData.valid || formData.value.name !== data.name" i18n>
                Speichern
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>

