import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {HttpErrorResponse} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {OrganizationAdminPanelResource} from '../../../core/rest/organization-admin/organization-admin-panel-resource.service';
import {OrganizationAdminService} from '../../../core/ogranizationAdmin/organization-admin.service';
import {OrganizationLearnerToAdd} from '../../../core/api-types/OrganizationLearnerToAdd';
import {Popup} from '../../../components/popup/Popup';
import {OrganizationAddLearnerPopupData} from './OrganizationAddLearnerPopupData';
import {PopupService} from '../../../components/popup/popup.service';
import {AddLearnerValidatorErrors} from '../../../core/api-types/AddLearnerValidatorErrors';
import {
    ORGANIZATION_CHANNEL_MICROSOFT_TEAMS,
    OrganizationChannelTypes
} from '../../../core/api-types/organizationChannelTypes';

@Component({
    selector: 'app-organization-add-learner-form',
    templateUrl: './organization-add-learner-form.component.html',
    styleUrls: ['./organization-add-learner-form.component.scss']
})

export class OrganizationAddLearnerFormComponent implements Popup<OrganizationAddLearnerPopupData>, OnInit {
    public data: OrganizationAddLearnerPopupData;

    public errors: AddLearnerValidatorErrors;

    public learnerEmails = new FormControl<string>('', Validators.email);
    public learnersToAdd: OrganizationLearnerToAdd[] = [];
    public singleEmail: boolean = false;
    public channel: OrganizationChannelTypes = ORGANIZATION_CHANNEL_MICROSOFT_TEAMS;
    public showSendRegistrationMail: boolean = false;
    public sendRegistrationMailForm = new FormControl<boolean>(true);

    constructor(private organizationAdminPanelRestService: OrganizationAdminPanelResource,
                private organizationAdminService: OrganizationAdminService,
                private toastr: ToastrService,
                public popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.channel = this.data.mainChannel;
        this.learnerEmails.valueChanges.subscribe(learnerEmails => {
            this.singleEmail = learnerEmails === undefined || learnerEmails.length === 0 ? false : learnerEmails.split(/[;,]/).length === 1;
            this.errors = undefined;
        });
    }

    public validateAddEmailsButton(): boolean {
        const email = this.getRawValue();
        if (this.singleEmail) {
            return this.validateSingleLearnerEmail(email, true);
        }
        return email.length > 0;
    }

    public addEmailsOnEnter(event?: KeyboardEvent): void {
        event.preventDefault();
        this.addEmails();
    }

    public addEmails(): void {
        const rawValue = this.getRawValue();
        if (this.singleEmail) {
            if (this.validateSingleLearnerEmail(rawValue, true)) {
                this.addEmailsToList();
            }
        } else {
            if (rawValue.length > 0) {
                this.addEmailsToList();
            }
        }
    }

    public getRawValue(): string {
        return this.learnerEmails.getRawValue().trim();
    }

    private addEmailsToList(): void {
        const newLearnersToAdd = this.getRawValue().split(/[;,]/).map(learnerEmail => new OrganizationLearnerToAdd(this.trimAndLowerCaseEmail(learnerEmail), this.channel));
        for (const newLearnerToAdd of newLearnersToAdd) {
            if (newLearnerToAdd.email.length > 0 && this.learnersToAdd.find(learner => learner.email.toLowerCase() === newLearnerToAdd.email) === undefined) {
                this.learnersToAdd.unshift(newLearnerToAdd);
            }
        }
        this.setShowSendRegistrationMail();
        this.learnerEmails.setValue('');
        this.learnerEmails.untouched;
    }

    private trimAndLowerCaseEmail(learnerEmail: string): string {
        return learnerEmail.trim().toLowerCase();
    }

    public validateSaveButton(): boolean {
        if (this.learnersToAdd.length > 0) {
            const validLearnersToAdd = this.learnersToAdd.every(learner => this.validateSingleLearnerEmail(learner.email));
            if (validLearnersToAdd) {
                if ((this.errors === undefined || Object.values(this.errors).every(value => value === false))) {
                    return true;
                }
            }
        }
        return false;
    }

    public validateSingleLearnerEmail(learnerEmail: string, inInputField: boolean = false): boolean {
        return learnerEmail !== undefined && learnerEmail.length > 0 && this.validateEmail(learnerEmail) && this.validateDuplicates(learnerEmail, inInputField) && this.validateDomain(learnerEmail);
    }

    public removeLearner(learnerIndex: number): void {
        this.learnersToAdd = this.learnersToAdd.slice(0, learnerIndex).concat(this.learnersToAdd.slice(learnerIndex + 1));
        this.clearForm();
    }

    public clearForm(): void {
        this.errors = undefined;
        this.learnerEmails.setValue('');
        this.setShowSendRegistrationMail();
    }

    public validateEmail(email: string): boolean {
        const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g;
        return regex.test(email);
    }

    public validateDuplicates(email: string, inInputField: boolean = false): boolean {
        const organizationLearnerToAdds = this.learnersToAdd.filter(learner => learner.email === email);
        if (inInputField) {
            return organizationLearnerToAdds.length === 0;
        }
        return organizationLearnerToAdds.length === 1;
    }

    public validateDomain(email: string): boolean {
        if (this.data.domainVerification) {
            const domains = this.data.domains;
            if (domains !== undefined && domains.length > 0) {
                const emailDomain = email.split('@');
                return domains.includes(emailDomain[1]);
            }
        }
        return true;
    }

    public changeChannelForLearner(learnerIndex: number): void {
        const currentChannel = this.learnersToAdd[learnerIndex].mainChannel;
        this.learnersToAdd[learnerIndex].mainChannel = this.data.activeChannels.filter(activeChannel => activeChannel !== currentChannel)[0];
        this.setShowSendRegistrationMail();
    }

    private setShowSendRegistrationMail(): void {
        this.showSendRegistrationMail = this.learnersToAdd.length > 0 && !this.learnersToAdd.every(learner => learner.mainChannel === ORGANIZATION_CHANNEL_MICROSOFT_TEAMS);
    }

    public changeChannel(): void {
        const currentChannel = this.channel;
        this.channel = this.data.activeChannels.filter(activeChannel => activeChannel !== currentChannel)[0];
    }

    public getInvalidLearnersList(firstListOfLearners: string[], secondListOfLearners: string[]): string[] {
        return [...new Set([...firstListOfLearners, ...secondListOfLearners])];
    }

    public saveOrganization: () => Promise<void> = async () => {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises,no-async-promise-executor
        return new Promise(async (resolve) => {
            if (this.validateSaveButton()) {
                this.popupService.setIsClosable('disable');
                try {
                    const shouldSendRegistrationMail = this.showSendRegistrationMail ? this.sendRegistrationMailForm.getRawValue() : undefined;
                    const learners = this.learnersToAdd.map(learner => {
                        learner.email = this.trimAndLowerCaseEmail(learner.email);
                        learner.sendRegistrationMail = shouldSendRegistrationMail;
                        return learner;
                    });
                    await this.organizationAdminPanelRestService.createLearners(learners);
                    this.organizationAdminService.addedNewLearner.next(true);
                    this.clearForm();
                    this.popupService.close();
                    this.toastr.success('Lernende wurden hinzugefügt');
                    resolve();
                } catch (e) {
                    const error = e as HttpErrorResponse;
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    this.errors = error.error?.validationErrors as AddLearnerValidatorErrors;
                    this.popupService.setIsClosable('closable');
                    resolve();
                }
            }
        });
    };
}

