import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivationStart, Router} from '@angular/router';
import {EditorResource} from '../rest/editor-resource.service';
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {distinctUntilChanged, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {EditorLearningPackageService} from './editor-learning-package.service';
import {QuizTranslation} from '../api-types/quizTranslation';
import {LearningPackageQuizzesOverview} from '../api-types/learningPackageQuizzesOverview';
import {QuizTypeName} from './types/QuizType';
import {QuestionType} from './question.type';
import {QueryParamService} from '../QueryParamService';

@Injectable({
    providedIn: 'root'
})
export class EditorQuestionService {

    constructor(public router: Router,
                private editorService: EditorResource,
                private editorLearningPackageService: EditorLearningPackageService,
                private activatedRoute: ActivatedRoute) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.params.quizId !== undefined) {
                    const quizId = path.snapshot.params.quizId as string;
                    this.selectedQuizId$.pipe(take(1)).subscribe(selectedLearningPackage => {
                        if (selectedLearningPackage !== quizId) {
                            this.selectedQuizId$.next(quizId);
                        }
                        this.newQuiz$.next(undefined);
                    });
                } else {
                    this.selectedQuizId$.next(undefined);
                }
            }
        });
    }

    public selectedQuizId$ = new BehaviorSubject<string>(undefined);
    public newQuiz$ = new BehaviorSubject<QuizTranslation>(undefined);

    public activeQuiz$ = combineLatest([this.editorLearningPackageService.activeLearningPackage$, this.selectedQuizId$, this.newQuiz$])
        .pipe(map(([activeLearningPackage, selectedQuizId, newQuiz]: [LearningPackageQuizzesOverview[], string, QuizTranslation]) => {
            if (activeLearningPackage !== undefined) {
                if (selectedQuizId !== undefined) {
                    const mainLanguage = activeLearningPackage.find(packageData => packageData.mainLearningPackage).language;
                    const quizzes: QuizTranslation[] = activeLearningPackage.map(activeLearningPackageData => {
                        const quiz = activeLearningPackageData.workingVersionQuizzes.find(quizData => quizData.quizId === selectedQuizId);
                        if (quiz !== undefined) {
                            return new QuizTranslation(
                                activeLearningPackageData._id,
                                activeLearningPackageData.learningPackageId,
                                mainLanguage,
                                activeLearningPackageData.language,
                                quiz?.id,
                                quiz.quizId,
                                quiz.quiz,
                                quiz.quizType,
                                quiz?.tags,
                                quiz?.answer,
                                quiz?.answers,
                                quiz?.explanation,
                                quiz?.positiveAnswer,
                                quiz?.negativeAnswer,
                                quiz?.sourceUrl,
                                quiz?.sourceLabel,
                                quiz?.originalContentHash
                            );
                        }
                        return undefined;
                    });
                    return quizzes.filter(item => item);
                }
                if (newQuiz !== undefined) {
                    return [newQuiz];
                }
            }
            return undefined;
        }), shareReplay(1));

    public activeLanguage$ = this.activatedRoute.queryParamMap.pipe(map(params => {
        return QueryParamService.getQueryParamValue(params, 'language', 'string');
    }), distinctUntilChanged());

    public activeTranslationQuiz$ = combineLatest([this.activeQuiz$, this.activeLanguage$])
        .pipe(switchMap(([activeQuiz, language]: [QuizTranslation[], string]) => {
            if (activeQuiz !== undefined && activeQuiz.length > 0) {
                if (language === undefined) {
                    language = activeQuiz[0].mainLanguage;
                }
                const quiz = activeQuiz.find(quizData => quizData.language === language);
                if (quiz !== undefined) {
                    return of(quiz);
                }

                return this.editorService.createNewQuizTranslation(activeQuiz[0].mainLeaningPackageId, activeQuiz[0].quizId, activeQuiz[0].quizType, language);
            }
            return of(undefined);
        }), shareReplay(1));


    public activeQuestionMainLanguage$ = combineLatest([this.activeQuiz$, this.editorLearningPackageService.defaultLocaleSubject$])
        .pipe((map(([activeQuestion, mainLanguage]) => {
            return activeQuestion.find(translation => translation.language === mainLanguage);
        })));

    public standardQuestionTypes: QuestionType[] = [
        {
            type: QuizTypeName.SINGLE_CHOICE_TYPE,
            title: 'Single Choice',
            icon: 'single-choice',
            questionType: 'standard'
        },
        {
            type: QuizTypeName.MULTIPLE_CHOICE_TYPE,
            title: 'Multiple Choice',
            icon: 'multiple-choice',
            questionType: 'standard'
        },
        {
            type: QuizTypeName.TRUE_FALSE_TYPE,
            title: 'Richtig / Falsch',
            icon: 'true-false',
            questionType: 'standard'
        },
        {
            type: QuizTypeName.FREE_TEXT_TYPE,
            title: 'Freitext',
            icon: 'free-text',
            questionType: 'standard'
        }
    ];

    public quizTypes: QuestionType[] = [
        ...this.standardQuestionTypes,
        {
            type: QuizTypeName.REFLECTION_FREE_TEXT_TYPE,
            title: 'Freitext Reflexion',
            icon: 'reflection-free-text',
            questionType: 'reflection'
        }
    ];
}


