import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, switchMap} from 'rxjs';
import {Learner} from '../api-types/learner';
import {HttpClient} from '@angular/common/http';
import {Group} from '../api-types/group';
import {ResourceHelper} from './resource-helper.service';
import {ListPaginationRequest} from '../api-types/listPaginationRequest';
import {ListPagination} from '../api-types/listPagination';
import {GroupActivationTag} from '../api-types/GroupActivationTag';
import {GroupLearningPackageEditSetting} from '../api-types/groupLearningPackageEditSetting';

@Injectable({
    providedIn: 'root'
})
export class LearningGroupResource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public getOrganizationGroups(): Observable<Group[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<Group[]>(`${url}/groups`)));
    }

    public getDefaultOrganizationGroups(): Observable<Group> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<Group>(`${url}/groups/default`)));
    }

    public getAllLearnersInGroup(groupId: string, listPaginationRequest: ListPaginationRequest): Observable<ListPagination<Learner>> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<ListPagination<Learner>>(`${url}/groups/${groupId}/learners?filter=${listPaginationRequest.filter}&pageNumber=${listPaginationRequest.pageNumber}&pageAmount=${listPaginationRequest.pageAmount}`)));
    }

    public getGroup(groupId: string): Observable<Group> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<Group>(`${url}/groups/${groupId}`)));
    }

    public createNewGroup(groupName: string): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/groups/new`, {name: groupName}, {responseType: 'text'})));
    }

    public addLearners(groupId: string, learners: string[]): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/learners/group/${groupId}`, learners, {responseType: 'text'})));
    }

    public removeLearnerForGroup(groupId: string, learnerId: string): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.delete(`${url}/groups/${groupId}/learner/${learnerId}`, {responseType: 'text'})));
    }

    public updateLearningPackageSettings(groupId: string, learningPackageId: string, learningPackageSetting: GroupLearningPackageEditSetting): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/groups/${groupId}/learning-package/${learningPackageId}`, learningPackageSetting, {responseType: 'text'})));
    }

    public getLearningPackageSettings(groupId: string, learningPackageId: string): Observable<GroupLearningPackageEditSetting> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<GroupLearningPackageEditSetting>(`${url}/groups/${groupId}/learning-package/${learningPackageId}/settings`)));
    }

    public editGroup(groupId: string, groupName: string): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/groups/${groupId}/edit`, {name: groupName}, {responseType: 'text'})));
    }

    public async removeGroup(groupId: string): Promise<void> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.delete<void>(`${url}/groups/${groupId}`))));
    }

    public getLearningPackageActivationTags(groupId: string, learningPackageId: string): Observable<GroupActivationTag[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<GroupActivationTag[]>(`${url}/groups/${groupId}/learning-package/${learningPackageId}/activation-tags`)));
    }
}
