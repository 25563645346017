<app-popup-container>
    <app-popup-wizard-header header="Wähle ein Lernpaket" i18n-header></app-popup-wizard-header>

    <app-popup-content>
        <app-info-box i18n-content
                      content="Es werden nur Lernpakete angezeigt, die Inhalte in einer der Sprache des aktiven Lernpaketes enthalten. Es werden nur Inhalte in den jeweiligen Sprachen importiert."></app-info-box>

        <div class="mm-resource-list" *ngIf="!hasNoLearningPackages">
            <app-card type="flat" *ngFor="let learningPackage of learningPackages$ | async"
                      class="mm-resource-list-card"
                      [title]="learningPackage.name"
                      [description]="learningPackage.numberOfQuestions + (learningPackage.numberOfQuestions === 1 ? ' Frage' :' Fragen')"
                      (cardClicked)="getLearningPackageQuestions(learningPackage.id)"
                      icon="box-taped-thin"></app-card>
        </div>
        <div *ngIf="hasNoLearningPackages" class="mm-learning-packages-empty-list" i18n>Es wurden keine Lernpakete gefunden, welche importiert werden können.</div>
    </app-popup-content>
</app-popup-container>
