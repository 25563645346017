import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {SelectedNewTag} from '../../../../core/api-types/SelectedNewTag';
import {Tag} from '../../../../core/api-types/Tag';
import {SelectedTag} from '../../../../core/api-types/SelectedTag';
import {EditorQuestionsService} from '../../../../core/editor/editor-questions.service';

@Component({
    selector: 'app-tags-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnChanges, OnInit {
    @Input()
    public availableTags: Tag[];

    @Input()
    public selectedAvailableTags: SelectedTag[];

    @Input()
    public selectedNewTags: SelectedNewTag[];

    @Input()
    public allowRemoveTag: boolean = false;

    @Input()
    public size: 'normal' | 'small' = 'normal';

    @Output()
    public selectedAvailableTagsChange = new EventEmitter<SelectedTag[]>();

    @Output()
    public selectedNewTagsChange = new EventEmitter<SelectedNewTag[]>();

    public questionTags: (Tag | SelectedNewTag)[];

    constructor(private editorQuizzesService: EditorQuestionsService) {
    }

    public ngOnInit(): void {
        this.setSelectedTags();
    }

    public unselectTag(unselectedTag: Tag | SelectedNewTag): void {
        if ((unselectedTag as Tag).id !== undefined) {
            const selectedTags = [...this.selectedAvailableTags];
            const indexToRemove = selectedTags.findIndex(tag => tag.id === (unselectedTag as Tag).id);
            if (indexToRemove !== -1) {
                selectedTags.splice(indexToRemove, 1);
            }
            this.selectedAvailableTags = selectedTags;
            this.selectedAvailableTagsChange.emit(this.selectedAvailableTags);
        } else {
            const newSelectedNewTags = [...this.selectedNewTags];
            const indexToRemove = newSelectedNewTags.findIndex(tag => tag.label === unselectedTag.label);
            if (indexToRemove !== -1) {
                newSelectedNewTags.splice(indexToRemove, 1);
            }
            this.selectedNewTags = newSelectedNewTags;
            this.selectedNewTagsChange.emit(this.selectedNewTags);
        }
        this.editorQuizzesService.setQuestionDataChanged(true);
    }

    private setSelectedTags(): void {
        const availableTags = this.selectedAvailableTags.map(selectedTag => this.availableTags.find(tag => tag.id === selectedTag.id)).filter(selectedTag => selectedTag !== undefined);
        this.questionTags = [...availableTags, ...this.selectedNewTags].sort((a, b) => a.label.localeCompare(b.label));
    }

    public ngOnChanges(): void {
        this.setSelectedTags();
    }
}
