import {Component, OnDestroy, OnInit} from '@angular/core';
import {GroupService} from '../../core/group.service';
import {LearningPackageResource} from '../../core/rest/learning-package-resource.service';
import {Group} from '../../core/api-types/group';
import {LearningGroupResource} from '../../core/rest/learning-group-resource.service';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {LearningPackageGroupOverview} from '../../core/api-types/learningPackageGroupOverview';
import {firstValueFrom, Subscription} from 'rxjs';
import {ConfirmationPopupData} from '../../components/confirmation-popup/ConfirmationPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {ConfirmationPopupResult} from '../../components/confirmation-popup/ConfirmationPopupResult';
import {ConfirmationPopupComponent} from '../../components/confirmation-popup/confirmation-popup.component';
import {PopupService} from '../../components/popup/popup.service';
import {LearningPackageActivateComponentComponent} from './learning-package-activate-component/learning-package-activate-component.component';
import {AddLearningPackageToGroupPopupData} from './learning-package-activate-component/AddLearningPackageToGroupPopupData';

@Component({
    selector: 'app-group-learnpackage-overview',
    templateUrl: './group-learning-package-overview.component.html',
    styleUrls: ['./group-learning-package-overview.component.scss']
})
export class GroupLearningPackageOverviewComponent implements OnInit, OnDestroy {
    public learningPackages: LearningPackageGroupOverview[];
    public activeGroup: Group;
    public selectedGroupId: string;
    private selectedGroupId$ = new Subscription();

    constructor(public groupService: GroupService,
                private learningPackageService: LearningPackageResource,
                private groupRestService: LearningGroupResource,
                public multilanguageService: MultilanguageService,
                private popupService: PopupService) {
    }

    public ngOnDestroy(): void {
        this.selectedGroupId$.unsubscribe();
    }

    public ngOnInit(): void {
        this.selectedGroupId$ = this.groupService.selectedGroupId$.subscribe(selectedGroupId => {
            if (selectedGroupId !== undefined) {
                this.selectedGroupId = selectedGroupId;
                this.groupRestService.getGroup(selectedGroupId).subscribe(group => {
                    this.activeGroup = group;
                    this.learningPackageService.getAllActiveForGroup(group._id).subscribe(data => {
                        this.learningPackages = data.filter(learningPackage => learningPackage.mainLearningPackage).map(learningPackage => this.getLearningPackagesLanguages(learningPackage, data));
                    });
                });
            }
        });
    }

    public openLearningPackageActivationPopup(): void {
        const addLearningPackageToGroupPopupData = new AddLearningPackageToGroupPopupData(this.selectedGroupId);
        void this.popupService.open<AddLearningPackageToGroupPopupData>(LearningPackageActivateComponentComponent, addLearningPackageToGroupPopupData, new PopupOptions());
    }

    public async openLearningPackageDeactivationPopup(learningPackage: { _id: string; name: string; }): Promise<void> {
        const text = `Möchtest du das Lernpaket <strong>${learningPackage.name}</strong> für die Lerngruppe
        <strong>${this.activeGroup.name}</strong> wirklich deaktivieren?`;
        const confirmationPopupData = new ConfirmationPopupData(text, 'Löschen', 'Abbrechen', async () => {
            await firstValueFrom(this.learningPackageService.deactivateLearningPackageForGroup(this.activeGroup._id, learningPackage._id));
            this.groupService.selectedGroupId.next(this.activeGroup._id);
        });
        const popupOptions = new PopupOptions('small');
        await this.popupService.open<ConfirmationPopupData, ConfirmationPopupResult>(ConfirmationPopupComponent, confirmationPopupData, popupOptions);
    }

    public getActivatedDate(learningPackageId: string): Date {
        return this.activeGroup.learningPackages.find(learningPackage => learningPackage.id === learningPackageId)?.activatedDate;
    }

    public getLearningGoal(learningPackageId: string): string {
        const learningGoals = this.activeGroup.learningPackages.find(learningPackage => learningPackage.id === learningPackageId)?.learningGoals;
        return learningGoals !== undefined ? `${learningGoals}%` : '-';
    }

    private getLearningPackagesLanguages(learningPackage: LearningPackageGroupOverview, data: LearningPackageGroupOverview[]): LearningPackageGroupOverview {
        learningPackage.mainLanguage = learningPackage.language;

        const learningPackages = data.filter(learningPackageInfo => learningPackageInfo.learningPackageId === learningPackage.learningPackageId && !learningPackageInfo.mainLearningPackage).map(learningPackageInfo => this.multilanguageService.createShortName(learningPackageInfo.language));

        learningPackage.language = learningPackages.length > 0 ? `, ${learningPackages.join(', ')}` : '';
        return learningPackage;
    }
}
