import {Component, Input, OnInit} from '@angular/core';
import {EditorQuestionsService} from '../../core/editor/editor-questions.service';
import {ConfirmationPopupData} from '../confirmation-popup/ConfirmationPopupData';
import {PopupOptions} from '../popup/PopupOptions';
import {ConfirmationPopupResult} from '../confirmation-popup/ConfirmationPopupResult';
import {ConfirmationPopupComponent} from '../confirmation-popup/confirmation-popup.component';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {Router} from '@angular/router';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {PopupService} from '../popup/popup.service';
import {map, switchMap} from 'rxjs/operators';
import {QuizTranslation} from '../../core/api-types/quizTranslation';
import {EditorQuestionService} from '../../core/editor/editor-question.service';
import {HelpQuizComponent} from '../../app-editor/learnpackages-quizzes/popup/help-quiz/help-quiz.component';
import {QuestionAnalyticsComponent} from '../../app-editor/learnpackages-quizzes/popup/question-analytics/question-analytics.component';
import {LearningPackageQuestionsService} from '../../app-editor/learnpackages-quizzes/learning-package-questions.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'editor-drop-down',
    templateUrl: './editor-drop-down.component.html',
    styleUrls: ['./editor-drop-down.component.scss']
})
export class EditorDropDownComponent implements OnInit {
    @Input() language: string;
    @Input() translationNotExist: boolean;
    @Input() showAnalytics: boolean;
    @Input() disabled: boolean;
    public openQuizOptionDropDown: boolean = false;

    public learningPackageId: string;
    public question$: Observable<QuizTranslation>;

    constructor(public editorQuizzesService: EditorQuestionsService,
                private editorService: EditorResource,
                private router: Router,
                private editorLearningPackageService: EditorLearningPackageService,
                private popupService: PopupService,
                private editorQuestionService: EditorQuestionService,
                private learningPackageQuizzesService: LearningPackageQuestionsService) {
    }

    public ngOnInit(): void {
        this.question$ = this.editorLearningPackageService.selectedLearningPackageId.pipe(
            map(learningPackageId => this.learningPackageId = learningPackageId),
            switchMap(() => this.editorQuestionService.activeTranslationQuiz$)
        );
    }

    public async openDeleteQuizPopup(quiz: QuizTranslation): Promise<void> {
        this.openQuizOptionDropDown = false;
        const language = quiz.language;
        const mainLanguage = quiz.mainLanguage;
        const text = language === mainLanguage ? $localize`Möchtest du diese Frage wirklich löschen?` : $localize`Möchtest du die Übersetzung dieser Frage wirklich löschen?`;
        const confirmationPopupData = new ConfirmationPopupData(text, 'Löschen', 'Abbrechen');
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<ConfirmationPopupData, ConfirmationPopupResult>(ConfirmationPopupComponent, confirmationPopupData, popupOptions);
        if (popupServiceResult === 'Ok') {
            this.deleteQuiz(this.learningPackageId, quiz.quizId, language, mainLanguage);
        }
    }

    private deleteQuiz(learningPackageId: string, quizId: string, language: string, mainLanguage: string): void {
        const quizIdToRedirect = this.learningPackageQuizzesService.getQuestionIdToRedirectAfterDeletion(language, mainLanguage, quizId);
        this.editorService.deleteQuiz(learningPackageId, quizId, language).subscribe(() => {
            this.editorQuizzesService.quizSaved.next(true);
            this.editorLearningPackageService.selectedLearningPackageId.next(learningPackageId);
            const queryParams = this.editorQuizzesService.filters.value;
            const url = quizIdToRedirect === undefined ? `/editor/lernpakete/${learningPackageId}/fragen` : `/editor/lernpakete/${learningPackageId}/fragen/${quizIdToRedirect}`;
            void this.router.navigate([url], {queryParams: queryParams});
        });
    }

    public openHelpQuizPopup(): void {
        this.openQuizOptionDropDown = false;
        void this.popupService.open(HelpQuizComponent, undefined, new PopupOptions());
    }

    public openQuestionAnalyticsPopup(): void {
        this.openQuizOptionDropDown = false;
        void this.popupService.open(QuestionAnalyticsComponent, undefined, new PopupOptions());
    }

    public toggle(): void {
        if (!this.disabled) {
            this.openQuizOptionDropDown = !this.openQuizOptionDropDown;
        }
    }
}
