import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-iframe-media',
    templateUrl: './iframe-media.component.html',
    styleUrls: ['./iframe-media.component.scss']
})
export class IframeMediaComponent {
    @Input() mediaLink: string;
}
