export const environment = {
    production: true,
    serverUrl: 'https://bot.micromate.ai',
    iamCodeExchangeUrl: 'https://manage.micromate.ai/code-exchange',
    iamLogoutUrl: 'https://manage.micromate.ai/',
    authErrorUrl: 'https://manage.micromate.ai/auth-error',
    learnUrl: 'https://learn.micromate.ai',
    clientid: '184276607492423937@micromate',
    zitadelProjectId: '122147021183311927',
    registrationLink: 'https://learn.micromate.ai/registration'
};
