import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {QuizEditionComponent} from '../app-editor/learnpackages-quizzes/quiz-edition/quiz-edition.component';
import {EditorQuestionsService} from './editor/editor-questions.service';

@Injectable()
export class DeactivateQuizEditorGuard implements CanDeactivate<QuizEditionComponent> {
    public component: Object;
    public route: ActivatedRouteSnapshot;

    constructor(private editorQuizzesService: EditorQuestionsService) {
    }

    public async canDeactivate(component: QuizEditionComponent,
                               route: ActivatedRouteSnapshot,
                               state: RouterStateSnapshot,
                               nextState: RouterStateSnapshot): Promise<boolean> {
        return await this.editorQuizzesService.checkIfQuizIsSaved(nextState.url);
    }

}
