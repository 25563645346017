<div class="mm-question-advanced">
    <app-expansion-panel title="Erweitert" i18n-title>
        <div class="mm-question-info">
            <div class="mm-question-info-title" i18n>Quellen-URL:</div>
            <div class="micormate-form-element">
                <input type="text" [formControl]="sourceUrl" class="micormate-form-input"
                       [class.micormate-form-input-validation-error]="sourceUrl.errors?.maxlength">
                <div *ngIf="sourceUrl.errors?.maxlength"
                     class="micormate-form-validation-error" i18n>Maximal 2048
                    Zeichen
                </div>
                <div *ngIf="sourceUrl.errors?.validUrl"
                     class="micormate-form-validation-error" i18n>Falsches Linkformat
                </div>
            </div>
        </div>
        <div class="mm-question-info">
            <div class="mm-question-info-title" i18n>Quellenbezeichnung:</div>
            <div class="micormate-form-element">
                <input type="text" [formControl]="sourceLabel" class="micormate-form-input"
                       [class.micormate-form-input-validation-error]="sourceLabel.errors?.maxlength">
                <div *ngIf="sourceLabel.errors?.maxlength"
                     class="micormate-form-validation-error" i18n>Maximal 100
                    Zeichen
                </div>
                <div *ngIf="sourceLabel.errors?.mustSourceUrl"
                     class="micormate-form-validation-error" i18n>Quellenbezeichnung muss zusammen mit einer Quellen-URL angegeben werden
                </div>
            </div>
        </div>
    </app-expansion-panel>
</div>
