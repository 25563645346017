import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MediaData} from './MediaData';
import {VideoLink} from '../../../../../../image-and-media-popup/videoLinkFormats/VideoLink';
import {DefaultVideo} from '../../../../../../image-and-media-popup/videoLinkFormats/DefaultVideo';
import {VideoDisplay} from '../../../../../../image-and-media-popup/videoLinkFormats/VideoFormat';
import {MovieService} from '../../../../../../image-and-media-popup/movie.service';
import {EditorResource} from '../../../../../../../core/rest/editor-resource.service';

@Component({
    selector: 'app-media-preview',
    templateUrl: './media-preview.component.html',
    styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnInit, OnChanges {
    @Input() mediaData: MediaData;

    public videoLink: VideoLink = DefaultVideo.createVideoLink();
    public invalidMedia: boolean;

    constructor(private videoService: MovieService,
                private editorRestService: EditorResource) {
    }

    public ngOnInit(): void {
        if (this.mediaData.mediaUrl !== undefined) {
            this.validateMedia();
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        const currentValue = changes['mediaData'].currentValue as MediaData;
        const previousValue = changes['mediaData'].previousValue as MediaData;
        if (previousValue !== undefined && (currentValue.mediaType !== previousValue.mediaType || currentValue.mediaUrl !== previousValue.mediaUrl)) {
            this.invalidMedia = undefined;
            this.validateMedia();
        }
    }

    private validateMedia(): void {
        if (this.mediaData.mediaType === 'website') {
            this.editorRestService.validateWebsiteMedia(this.mediaData.mediaUrl).subscribe(validationResult => this.invalidMedia = !validationResult.isValid);
        }
        if (this.mediaData.mediaType === 'movie' || this.mediaData.mediaType === 'audio') {
            this.validateAudioAndVideo();
        }
    }

    private validateAudioAndVideo(): void {
        this.videoLink = this.videoService.getVideoType(this.mediaData.mediaUrl);

        if (this.videoLink.display === VideoDisplay.IFRAME) {
            this.invalidMedia = false;
        }

        if (this.videoLink.display === VideoDisplay.VIDEO) {
            const videoElement = document.createElement('video');
            // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
            videoElement.onerror = (() => {
                if (videoElement.error.code === 4) {
                    this.invalidMedia = true;
                }
                videoElement.remove();
            });

            videoElement.addEventListener('loadedmetadata', () => {
                this.invalidMedia = false;
                videoElement.remove();
            });
            videoElement.src = this.mediaData.mediaUrl;
        }
    }
}
