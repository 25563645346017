import {Component, Input} from '@angular/core';
import {MediaData} from '../question-media-preview/MediaData';
import {QuizExplanation} from '../../../../../../../core/editor/types/QuizExplanation';

@Component({
    selector: 'app-explanation-question-preview',
    templateUrl: './explanation-question-preview.component.html',
    styleUrls: ['./explanation-question-preview.component.scss']
})
export class ExplanationQuestionPreviewComponent {
    @Input() explanation: QuizExplanation;
    @Input() showExplanationText: boolean = true;

    public hasQuestionMedia(): boolean {
        return this.explanation.mediaType !== undefined && (this.explanation.mediaUrl !== undefined || this.explanation.imageUrl !== undefined);
    }

    public getMediaData(): MediaData {
        return new MediaData(this.explanation.mediaType, this.explanation.mediaUrl, this.explanation.imageUrl);
    }
}
