import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {OrganizationAdminService} from '../../core/admin/organization-admin.service';
import {OrganizationSettingService} from './organization-setting.service';
import {OrganizationResources} from '../../core/rest/organization-resources.service';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup} from '@angular/forms';
import {FeaturesFormGroupType} from './FeaturesFormGroupType';
import {Subscription} from 'rxjs';
import {OrganizationService} from '../../core/organization.service';
import {SuperAdminOrganizationSettings} from '../../core/api-types/superAdminOrganizationSettings';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {RemoveOrganizationEventsPopupComponent} from './remove-organization-events/remove-organization-events-popup.component';
import {OrganizationEventRemovalPopupResult} from './remove-organization-events/OrganizationEventRemovalPopupResult';
import {PopupService} from '../../components/popup/popup.service';
import {OrganizationEventRemovalPopupData} from './remove-organization-events/OrganizationEventRemovalPopupData';
import {Features} from '../../core/features/Features';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {GroupService} from '../../core/group.service';
import {Router} from '@angular/router';
import {RenameOrganizationComponent} from './rename-organization/rename-organization.component';
import {OrganizationRenamePopupResult} from './rename-organization/OrganizationRenamePopupResult';
import {RenameOrganizationPopupData} from './rename-organization/RenameOrganizationPopupData';
import {OrganizationAITypes} from '../../core/features/OrganizationAITypes';

@Component({
    selector: 'app-organization-setting',
    templateUrl: './organization-setting.component.html',
    styleUrls: ['./organization-setting.component.scss']
})
export class OrganizationSettingComponent implements OnInit, OnDestroy {
    public organization: SuperAdminOrganizationSettings;
    public featuresFormData = new FormGroup<FeaturesFormGroupType>({
        notifications: new FormControl<boolean>(false),
        scoreboardRanking: new FormControl<boolean>(false),
        ai: new FormGroup<{ type: FormControl<OrganizationAITypes>; configurationId?: FormControl<string>; }>({
            type: new FormControl<OrganizationAITypes>(OrganizationAITypes.Off),
            configurationId: new FormControl<string>(undefined)
        }),
        personalInsights: new FormControl<boolean>(true),
        usePseudonymByDefault: new FormControl<boolean>(true)
    });
    public domains: string;
    public invalidDomains: boolean = false;
    public invalidLearnersDomains: boolean = false;
    public invalidEmptyDomains: boolean = false;
    public licence: number;
    private selectedOrganizationSubscription = new Subscription();
    private valueChangesSubscription = new Subscription();

    constructor(private organizationAdminService: OrganizationAdminService,
                public organizationSettingServiceService: OrganizationSettingService,
                private organizationRestService: OrganizationResources,
                private toastr: ToastrService,
                private organizationService: OrganizationService,
                private popupService: PopupService,
                private editorLearningPackageService: EditorLearningPackageService,
                private groupService: GroupService,
                private router: Router) {
    }

    @HostListener('window:beforeunload', ['$event'])
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    public unloadHandler($event): string {
        if (!this.organizationSettingServiceService.organizationSettingSaved.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return $event.returnValue = 'Your changes will not be saved';
        }
    }

    public ngOnInit(): void {
        this.selectedOrganizationSubscription = this.organizationAdminService.selectedOrganization$.subscribe((selectedOrganization: SuperAdminOrganizationSettings) => {
            if (selectedOrganization !== undefined) {
                this.organization = selectedOrganization;
                this.featuresFormData.patchValue(this.organization.features);
                this.featuresFormData.controls.ai.controls.configurationId.setValue(this.organization.features.ai.type === OrganizationAITypes.CustomLlm ? this.organization.features.ai?.configurationId : undefined);
                this.licence = this.organization?.licence;
                this.domains = this.organization.domains !== undefined ? this.organization.domains.join(', ') : '';

                this.valueChangesSubscription = this.featuresFormData.controls.ai.valueChanges.subscribe(value => {
                    if (value.type !== this.organization.features.ai.type) {
                        this.changedSetting();
                    }
                });
            }
        });
    }

    public ngOnDestroy(): void {
        this.selectedOrganizationSubscription.unsubscribe();
        this.valueChangesSubscription.unsubscribe();
    }

    public changedSetting(): void {
        if (this.domains !== undefined) {
            this.changedDomains();
        } else {
            this.invalidDomains = false;
        }
        this.setOrganizationSetting();
    }

    public changedDomains(): void {
        this.invalidLearnersDomains = false;
        this.invalidEmptyDomains = false;
        const domains = this.organizationSettingServiceService.getDomains(this.domains);
        if (domains.length === 0) {
            this.invalidDomains = false;
        } else {
            if (this.organizationSettingServiceService.validateDomains(domains)) {
                this.invalidDomains = false;
            }
        }
        this.organizationSettingServiceService.setOrganizationSettingSavedStatus(false);
    }

    public async removeOrganizationEvents(): Promise<void> {
        const popupOptions = new PopupOptions();
        const popuData = new OrganizationEventRemovalPopupData(this.organization.id, this.organization.name);
        const result = await this.popupService.open<OrganizationEventRemovalPopupData, OrganizationEventRemovalPopupResult>(RemoveOrganizationEventsPopupComponent, popuData, popupOptions);
        if (result === 'eventsRemoved') {
            this.toastr.success($localize`Alle Events wurden entfernt`);
        }
        if (result === 'removingEventsError') {
            this.toastr.error($localize`Fehler beim Löschen der Events`);
        }
    }

    public async saveChanges(): Promise<void> {
        if (!this.invalidDomains) {
            if (this.organization.features !== this.getFeaturesData()) {
                try {
                    await this.organizationRestService.saveOrganizationFeatures(this.organization.id, this.getFeaturesData());
                } catch (e) {
                    this.toastr.error($localize`Features konnten nicht geändert werden`);
                }
            }
            await this.saveLicence();
        }
    }

    public setActiveOrganization(): void {
        this.organizationService.setActiveOrganization(this.organization.zitadelId);
        this.editorLearningPackageService.cleanActiveLearningPackage();
        this.groupService.cleanActiveGroup();
        void this.router.navigate(['/']);
    }

    public async renameOrganization(): Promise<void> {
        const popupOptions = new PopupOptions();
        const data = new RenameOrganizationPopupData(this.organization.id, this.organization.name);
        const result = await this.popupService.open<RenameOrganizationPopupData, OrganizationRenamePopupResult>(RenameOrganizationComponent, data, popupOptions);
        if (result === 'renameError') {
            this.toastr.error($localize`Organisationsname konnte nicht angepasst werden`);
        } else {
            await this.refreshData();
        }
    }

    private async saveLicence(): Promise<void> {
        // eslint-disable-next-line no-null/no-null
        const licence = this.licence === null ? undefined : this.licence;
        if (this.organization.licence !== licence) {
            try {
                await this.organizationRestService.saveLicenceForOrganization(this.organization.id, licence);
            } catch (e) {
                this.toastr.error($localize`Licence konnten nicht wurde geändert`);
            }
        }
        await this.saveDomains();
    }

    private async saveDomains(): Promise<void> {
        const domains = this.organizationSettingServiceService.getDomains(this.domains);
        if (this.organization.domains !== domains) {
            const data = await this.organizationRestService.changeDomainsForOrganization(this.organization.id, domains);
            if (data.validationResult === 'valid') {
                await this.sendDomains(domains);
            } else {
                this.invalidLearnersDomains = data.validationResult === 'invalidLearnerDomains';
                this.invalidEmptyDomains = data.validationResult === 'emptyDomains';
            }
        } else {
            this.toastr.success($localize`Änderungen gespeichert`);
            await this.refreshData();
        }
    }

    private async sendDomains(domains: string[]): Promise<void> {
        try {
            await this.organizationRestService.changeDomainForOrganization(this.organization.id, domains);
            this.toastr.success($localize`Änderungen gespeichert`);
            await this.refreshData();
        } catch (e) {
            this.toastr.error($localize`Der Beitrittsmodus konnten nicht wurde geändert`);
        }
    }

    private async refreshData(): Promise<void> {
        this.organizationSettingServiceService.setOrganizationSettingSavedStatus(true);
        this.organizationAdminService.refreshOrganizationInfo();
        const activeOrganizationId = this.organizationService.getActiveOrganizationId();
        if (activeOrganizationId === this.organization.zitadelId) {
            await this.organizationService.refreshActiveOrganizationInfo();
        }
    }

    private setOrganizationSetting(): void {
        const features = this.organization.features !== this.getFeaturesData() ? this.getFeaturesData() : undefined;
        const readDomains = this.organizationSettingServiceService.getDomains(this.domains);
        const domains = this.organization.domains !== readDomains ? readDomains : undefined;
        // eslint-disable-next-line no-null/no-null
        const licence = this.licence === null ? undefined : this.licence;

        this.organizationSettingServiceService.setOrganizationSettingSavedStatus(false);
        this.organizationSettingServiceService.setSetting(this.organization.id, features, domains, licence);
    }

    private getFeaturesData(): Features {
        const features = this.featuresFormData.getRawValue();
        // eslint-disable-next-line no-null/no-null
        features.ai.configurationId = features.ai.type === OrganizationAITypes.CustomLlm && features.ai.configurationId !== undefined && features.ai.configurationId !== null ? features.ai.configurationId : undefined;
        return features;
    }

}
