import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ActiveOrganizationResponseDto} from '../../api-types/ActiveOrganizationResponseDto';

@Injectable({
    providedIn: 'root'
})
export class OrganizationAdminRestService {

    constructor(private http: HttpClient) {
    }

    public getActiveOrganizationData(activeOrganizationId: string): Observable<ActiveOrganizationResponseDto> {
        return this.http.get<ActiveOrganizationResponseDto>(`${environment.serverUrl}/api/website/organization/${activeOrganizationId}`
            + '/organization');
    }

    public getOrganizationName(organizationId: string): Promise<{ id: string; zitadelId: string; name: string; }> {
        return this.http.get<{ id: string; zitadelId: string; name: string; }>(`${environment.serverUrl}/api/website/organization/${organizationId}`
            + '/organization/name').toPromise();
    }

    public getAdminOrganizations(): Promise<{ id: string; zitadelId: string; name: string; }[]> {
        return this.http.get<{ id: string; zitadelId: string; name: string; }[]>(`${environment.serverUrl}/api/website/admin/organization`).toPromise();
    }
}
