import {Injectable} from '@angular/core';
import {firstValueFrom, Observable, switchMap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LearningPackage} from '../api-types/learningPackage';
import {LearningPackageEditorOverview} from '../api-types/learningPackageEditorOverview';
import {ResourceHelper} from './resource-helper.service';
import {NewLeaningPackage} from '../../app-editor/learnpackages-overview/new-learning-package-popup/NewLeaningPackage';
import {LearningPackageSettings} from '../api-types/learningPackageSettings';
import {QuizTranslation} from '../api-types/quizTranslation';
import {QuizAnalyticsOverview} from './analytics-rest/QuizAnalyticsOverview';
import {LearningPackageQuizzesOverview} from '../api-types/learningPackageQuizzesOverview';
import {Quiz} from '../api-types/quiz';
import {ConversionResult} from '../editor/types/QuestionConversion/ConversionResult';
import {SourceLearningPackage} from '../api-types/SourceLearningPackage';
import {ImportableQuestion} from '../editor/types/QuestionConversion/ImportableQuestion';
import {ListPaginationRequest} from '../api-types/listPaginationRequest';
import {ListPagination} from '../api-types/listPagination';
import {Tag} from '../api-types/Tag';
import {NewTag} from '../api-types/NewTag';
import {InboxDto} from '../api-types/InboxDto';

@Injectable({
    providedIn: 'root'
})
export class EditorResource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public convertCsvToQuestions(csv: string, fileName: string, learningPackageId: string): Observable<ConversionResult> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.put<ConversionResult>(`${url}/editor/learningpackage/${learningPackageId}/questions/convert-from-csv`, {csv: csv, fileName: fileName})));
    }

    public createNewLearningPackage(learningPackage: NewLeaningPackage): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/editor/learningpackage`, learningPackage, {responseType: 'text'})));
    }

    public createNewLearningPackageTranslation(learningPackage: NewLeaningPackage, activeLearningPackage: string): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/editor/learningpackage/${activeLearningPackage}/translation`, learningPackage, {responseType: 'text'})));
    }

    public async uploadQuiz(learningPackageId: string, quizData: FormData): Promise<string> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/editor/learningpackage/${learningPackageId}/quiz`, quizData, {responseType: 'text'}))));

    }

    public async bulkAddQuestions(learningPackageId: string, questions: Quiz[]): Promise<void> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<void>(`${url}/editor/learningpackage/${learningPackageId}/quiz/bulk`, questions))));
    }

    public getAllLearningPackagesForContentCreator(listPaginationRequest: ListPaginationRequest): Observable<ListPagination<LearningPackageEditorOverview>> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<ListPagination<LearningPackageEditorOverview>>(`${url}/editor`, {
            params: {
                filter: listPaginationRequest.filter,
                pageNumber: listPaginationRequest.pageNumber,
                pageAmount: listPaginationRequest.pageAmount
            }
        })));
    }

    public getOneLearningPackage(learningPackageId: string): Observable<LearningPackage[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<LearningPackage[]>(`${url}/editor/learningpackage/${learningPackageId}`)));
    }

    public getOneLearningPackageQuizzesOverview(learningPackageId: string): Observable<LearningPackageQuizzesOverview[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<LearningPackageQuizzesOverview[]>(`${url}/editor/learningpackage/${learningPackageId}/quizzes-overview`)));
    }

    public getAllContentCreatorsForPackage(learningPackageId: string): Observable<{ id: string; name: string; }[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ id: string; name: string; }[]>(`${url}/editor/learningpackage/${learningPackageId}/content-creator`)));
    }

    public getLearningPackageSettings(learningPackageId: string, language: string): Observable<LearningPackageSettings> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<LearningPackageSettings>(`${url}/editor/learningpackage/${learningPackageId}/settings?language=${language}`)));
    }

    public getLearningPackageLanguages(learningPackageId: string): Observable<string[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<string[]>(`${url}/editor/learningpackage/${learningPackageId}/languages`)));
    }

    public getLearningPackageAvailableTags(learningPackageId: string): Observable<Tag[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<Tag[]>(`${url}/editor/learningpackage/${learningPackageId}/tags`)));
    }

    public async releaseLearningPackage(learningPackageId: string): Promise<{ publishedLearningPackages: string[]; }> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<{ publishedLearningPackages: string[]; }>(`${url}/editor/learningpackage/${learningPackageId}/release`, {}))));
    }

    public async editLearningPackageBasicInfo(learningPackageId: string, data: { learningPackageTranslationId: string; name: string; introduction: string; }): Promise<string> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post(`${url}/editor/learningpackage/${learningPackageId}/edit-name-and-introduction`, data, {responseType: 'text'}))));
    }

    public getLearningPackageStatus(learningPackageId: string): Observable<{ status: string; }> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ status: string; }>(`${url}/editor/learningpackage/${learningPackageId}/status`)));
    }

    public createNewQuiz(learningPackageId: string, quizType: string): Observable<QuizTranslation> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<QuizTranslation>(`${url}/editor/learningpackage/${learningPackageId}/new-quiz`, {quizType: quizType})));
    }

    public createNewQuizTranslation(learningPackageId: string, quizId: string, quizType: string, language: string): Observable<QuizTranslation> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<QuizTranslation>(`${url}/editor/learningpackage/${learningPackageId}/quiz/${quizId}/new-translation`, {
            quizType: quizType,
            language: language
        })));
    }

    public deleteQuiz(learningPackageId: string, quizId: string, language: string): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.delete(`${url}/editor/learningpackage/${learningPackageId}/quiz/${quizId}?language=${language}`, {responseType: 'text'})));
    }

    public getQuizAnalytics(learningPackageId: string, quizId: string, language: string): Observable<QuizAnalyticsOverview> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<QuizAnalyticsOverview>(`${url}/editor/learningpackage/${learningPackageId}/quiz/${quizId}/analytics?language=${language}`)));
    }

    public getLearningPackagesForQuestionImport(learningPackageId: string): Observable<SourceLearningPackage[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<SourceLearningPackage[]>(`${url}/editor/learningpackage/${learningPackageId}/importable-learningpackages`)));
    }

    public getImportableQuestionsForLearningPackage(learningPackageId: string, sourceLearningPackageId: string): Observable<ImportableQuestion[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<ImportableQuestion[]>(`${url}/editor/learningpackage/${learningPackageId}/importable-learningpackages/${sourceLearningPackageId}/questions`)));
    }

    public async getLearningPackageQuizzesExport(learningPackageId: string): Promise<{ csvData: string; }> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ csvData: string; }>(`${url}/editor/learningpackage/${learningPackageId}/quizzes-export`))));
    }

    public deleteLearningPackage(learningPackageId: string): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.delete<string>(`${url}/editor/learningpackage/${learningPackageId}`)));
    }

    public validateWebsiteMedia(media: string): Observable<{ isValid: boolean; }> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<{ isValid: boolean; }>(`${url}/editor/website-media-validation`, {mediaUrl: media})));
    }

    public createNewLearningPackageTag(learningPackageId: string, newTag: NewTag): Observable<{ duplicatedTag?: boolean; }> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.post<{ duplicatedTag?: boolean; }>(`${url}/editor/learningpackage/${learningPackageId}/tag`, newTag)));
    }

    public updateLearningPackageTag(learningPackageId: string, tag: Tag): Observable<{ duplicatedTag?: boolean; }> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.put<{ duplicatedTag?: boolean; }>(`${url}/editor/learningpackage/${learningPackageId}/tag/${tag.id}`, tag)));
    }

    public deleteLearningPackageTag(learningPackageId: string, tagId: string): Observable<string> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.delete<string>(`${url}/editor/learningpackage/${learningPackageId}/tag/${tagId}`)));
    }

    public canDeleteLearningPackageTag(learningPackageId: string, tagId: string): Observable<{ allowToDelete?: boolean; }> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ allowToDelete: boolean; }>(`${url}/editor/learningpackage/${learningPackageId}/tag/${tagId}/deletion-validation`)));
    }

    public getLearningPackageInbox(learningPackageId: string, listPaginationRequest: ListPaginationRequest): Observable<ListPagination<InboxDto>> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<ListPagination<InboxDto>>(`${url}/editor/learningpackage/${learningPackageId}/inbox?filter=${listPaginationRequest.filter}&pageNumber=${listPaginationRequest.pageNumber}&pageAmount=${listPaginationRequest.pageAmount}`)));

    }
}
