import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {DragAndDropService} from './drag-and-drop.service';
import {ToastrService} from 'ngx-toastr';

export interface FileHandle {
    file: File;
    url: SafeUrl;
}

@Directive({
    selector: '[appDragAndDrop]'
})
export class DragAndDropDirective {
    @Output() files: EventEmitter<FileHandle[]> = new EventEmitter<FileHandle[]>();

    @Output() error: EventEmitter<{ error: boolean; text: string[]; }> = new EventEmitter<{ error: boolean; text: string[]; }>();

    @HostBinding('style.background') private background = '#eee';

    constructor(private sanitizer: DomSanitizer, private dragAndDropService: DragAndDropService,
                private toastr: ToastrService) {
    }

    @HostListener('dragover', ['$event'])
    public onDragOver(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        this.dragAndDropService.set(true);
    }

    @HostListener('dragleave', ['$event'])
    public onDragLeave(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
    }

    @HostListener('drop', ['$event'])
    public onDrop(evt: DragEvent): void {
        evt.preventDefault();
        evt.stopPropagation();

        const files: FileHandle[] = [];

        if (evt.dataTransfer.files.length === 1) {
            this.uploadFile(evt, files);
        } else if (evt.dataTransfer.files.length > 1) {
            this.error.emit({error: true, text: ['Nur eine Datei zulässig']});
        }
        this.dragAndDropService.setDragCounter(0);
        this.dragAndDropService.set(false);
    }

    private uploadFile(evt: DragEvent, files: FileHandle[]): void {
        try {
            const file = evt.dataTransfer.files[0];
            const errors: string[] = this.dragAndDropService.validateFile(file);
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
            files.push({file, url});
            if (files.length > 0) {
                this.error.emit({error: errors.length > 0, text: errors});
                this.files.emit(files);
            }
        } catch (e) {
            this.error.emit({error: true, text: ['Datei konnte nicht verarbeitet werden']});
            throw e as Error;
        }
    }
}

