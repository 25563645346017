<app-popup-container>
    <app-popup-wizard-header header="{{getPageTitle()}}"></app-popup-wizard-header>

    <app-popup-content>
        <div class="mm-question-preview" *ngIf="questionPreview !== undefined">
            <div class="mm-question-preview-question-title">
                <div class="mm-question-preview-question-title-info">
                    <app-icon *ngIf="questionPreview.quizType !== undefined"
                              class="micromate-quiz-icon"
                              [title]="quizTypeData.title"
                              [icon]="quizTypeData.icon"></app-icon>
                    <h3 class="micromate-preview-title" i18n>Frage</h3>
                </div>
                <app-editor-language-switcher
                    *ngIf="showLanguageSwitcher"
                    class="micromate-wizard-page-language-switcher"
                    [currentLanguage]="questionPreview.language"
                    [mainLanguage]="mainLocale"
                    [readQuestionsFromSelectedLearningPackage]="false"
                    [availableLanguages]="questionLanguages"
                    (activeLanguage)="changeLanguage($event)"></app-editor-language-switcher>
                <div class="mm-already-imported-question">
                    <app-icon class="mm-already-imported-question-icon"
                              icon="imported-question"
                              *ngIf="defaultLanguageQuestionPreview.alreadyImported"
                              title="Diese Frage wurde bereits importiert" i18n-title></app-icon>
                </div>
            </div>
            <div class="micromate-tags-list">
                <app-tags-list
                    [availableTags]="availableTags"
                    [(selectedAvailableTags)]="selectedAvailableTags"
                    [(selectedNewTags)]="selectedNewTag">
                </app-tags-list>
            </div>
            <app-question-preview
                [questionPreview]="questionPreview"></app-question-preview>
        </div>
    </app-popup-content>

    <app-popup-footer>

        <app-wizard-page-footer
            [isBackVisible]="false"
            [hasCustomContent]="true"
            [isForwardVisible]="false">
            <div class="mm-question-navigation" *ngIf="allQuestionsForDefaultLanguage.length > 0">
                <div *ngIf="allQuestionsForDefaultLanguage.length > 1" class="mm-question-pager">
                    <app-icon-button (click)="previousQuestion()" type="secondary" icon="chevron-left-light"
                                     [disabled]="currentQuestionIndex === 0 "></app-icon-button>
                    <span i18n>{{currentQuestionIndex + 1}} von {{allQuestionsForDefaultLanguage.length}}</span>
                    <app-icon-button (click)="nextQuestion()" type="secondary" icon="chevron-right-light"
                                     [disabled]="currentQuestionIndex === allQuestionsForDefaultLanguage.length - 1"></app-icon-button>
                </div>
                <div class="micromate-import-toggle" *ngIf="defaultLanguageQuestionPreview">
                    <input id="checkbox" type="checkbox" [ngModel]="defaultLanguageQuestionPreview.isActive"
                           class="micromate-import-toggle-input"
                           (click)="toggleQuestionActiveStatus()"/>
                    <label class="micromate-import-label" for="checkbox" i18n>Frage importieren</label>
                </div>
            </div>
            <a class="mm-question-navigation-back-link" (click)="navigateToSummary()">
                zurück zur Importübersicht
                <app-icon icon="chevron-right-light" class="mm-question-navigation-back-link-icon" ></app-icon>
            </a>
        </app-wizard-page-footer>
    </app-popup-footer>
</app-popup-container>
