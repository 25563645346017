import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {OrganizationSettingsComponent} from '../app-organisation/organization-settings/organization-settings.component';
import {OrganizationAdminSettingService} from '../app-organisation/organization-admin-setting.service';

@Injectable()
export class OrganizationAdminSettingsGuard implements CanDeactivate<OrganizationSettingsComponent> {
    public component: Object;
    public route: ActivatedRouteSnapshot;

    constructor(private organizationAdminSettingService: OrganizationAdminSettingService) {
    }

    public async canDeactivate(component: OrganizationSettingsComponent,
                               route: ActivatedRouteSnapshot,
                               state: RouterStateSnapshot,
                               nextState: RouterStateSnapshot): Promise<boolean> {
        return await this.organizationAdminSettingService.checkIfOrganizationSettingIsSaved(nextState.url);
    }

}
