<div class="micromate-card">
    <div class="micromate-card-header">
        <h1 i18n>Lernziele</h1>
        <div class="micromate-button" *ngIf="(userRoleService.hasSuperAdminRole() | async)">
            <div class="micromate-buttont-text" [class.micromate-buttont-text-disabled]="!allowExportFile"
                 (click)="exportLearningGoals()">
                <div i18n class="micromate-button-title">Exportieren</div>
            </div>
        </div>
    </div>

    <div
        *ngIf="listPagination !== undefined && groupLearningPackages !== undefined && ((paginationParams.filter.length === 0 && learnerLearningGoalsOverview.length > 0) || (paginationParams.filter.length > 0)) ">
        <app-table-search [paginationParams]="paginationParams" [navigateUrl]="navigationUrl" [totalDataAmount]="listPagination?.totalDataAmount"></app-table-search>
        <div class="micromate-table">
            <div class="table-container learning-goals-table-row"
                 [ngStyle]="{'grid-template-rows': 'repeat(' + (learnerLearningGoalsOverview.length + 1) + ', auto)'}">
                <div class="table-container table-container-header"
                     [ngStyle]="{'grid-template-columns': 'minmax(200px, auto) ' + addLearningPackageColumn(), 'grid-column': 1}">
                    <ng-container>
                        <div class="row-header" i18n>Lernende</div>
                        <div class="row-header" *ngFor="let learningPackage of groupLearningPackages">
                            {{learningPackage.name}}</div>
                    </ng-container>
                </div>
                <div class="table-container-data" *ngIf="learnerLearningGoalsOverview.length > 0; else emptyTable"
                     #tableContentRef>
                    <div class="table-container table-content"
                         [ngStyle]="{'grid-template-columns': 'minmax(200px, auto) ' + addLearningPackageColumn()}"
                         *ngFor="let learner of learnerLearningGoalsOverview">
                        <div class="row row-title">{{learner.displayName}}</div>
                        <div class="row row-learning-goal" *ngFor="let learningPackage of groupLearningPackages">
                            <div *ngIf="learningPackage?.learningGoal !== undefined">
                                <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"
                                     class="micromate-learning-goal-reached-icon"
                                     *ngIf="getLearnerGoalsForLearningPackage(learningPackage.id, learner)">
                                    <use href="./assets/icons.svg#check-light"></use>
                                </svg>

                                <svg aria-hidden="true" focusable="false" viewBox="0 -15 320 512"
                                     class="micromate-learning-goal-not-reached-icon"
                                     *ngIf="!getLearnerGoalsForLearningPackage(learningPackage.id, learner)">
                                    <use href="./assets/icons.svg#times-light"></use>
                                </svg>
                            </div>
                            <svg aria-hidden="true" focusable="false" viewBox="0 0 384 512"
                                 class="micromate-no-learning-goal"
                                 *ngIf="!learningPackage?.learningGoal">
                                <use href="./assets/icons.svg#minus-light"></use>
                            </svg>
                        </div>
                    </div>
                </div>
                <ng-template #emptyTable>
                    <div class="table-empty-filter-results" i18n>
                        keine Suchresultate
                    </div>
                </ng-template>
            </div>
        </div>
        <app-table-pagination [paginationParams]="paginationParams"
                              [totalDataAmount]="listPagination.totalDataAmount"
                              [navigateUrl]="navigationUrl"></app-table-pagination>
    </div>

    <div
        *ngIf="learnerLearningGoalsOverview !== undefined && groupLearningPackages !== undefined && learnerLearningGoalsOverview.length === 0 && paginationParams.filter !== undefined && paginationParams.filter.length === 0">
        <div class="micromate-empty-table" *ngIf="learnerLearningGoalsOverview.length === 0">
            Keine Lernenden vorhanden
        </div>
        <div class="micromate-empty-table"
             *ngIf="groupLearningPackages.length === 0 && learnerLearningGoalsOverview.length > 0">
            Keine aktiven Lernpakete vorhanden
        </div>
    </div>
</div>
