<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" *ngIf="icon === 'ai'">
    <path
        d="M455 23.6c9.4-9.4 24.6-9.4 33.9 0L520.4 55c9.4 9.4 9.4 24.6 0 33.9L390.3 219.1c-.2-.3-.4-.5-.6-.7l-64-64c-.2-.2-.5-.4-.7-.6L455 23.6zM55.6 423L313.7 164.9c.2 .3 .4 .5 .6 .7l64 64c.2 .2 .5 .4 .7 .6L121 488.4c-9.4 9.4-24.6 9.4-33.9 0L55.6 457c-9.4-9.4-9.4-24.6 0-33.9zM443.7 12.3L44.3 411.7c-15.6 15.6-15.6 40.9 0 56.6l31.4 31.4c15.6 15.6 40.9 15.6 56.6 0L531.7 100.3c15.6-15.6 15.6-40.9 0-56.6L500.3 12.3c-15.6-15.6-40.9-15.6-56.6 0zM104 64c0-4.4-3.6-8-8-8s-8 3.6-8 8v56H32c-4.4 0-8 3.6-8 8s3.6 8 8 8H88v56c0 4.4 3.6 8 8 8s8-3.6 8-8V136h56c4.4 0 8-3.6 8-8s-3.6-8-8-8H104V64zM480 312c-4.4 0-8 3.6-8 8v56H416c-4.4 0-8 3.6-8 8s3.6 8 8 8h56v56c0 4.4 3.6 8 8 8s8-3.6 8-8V392h56c4.4 0 8-3.6 8-8s-3.6-8-8-8H488V320c0-4.4-3.6-8-8-8zM232 32c0-4.4-3.6-8-8-8s-8 3.6-8 8V56H192c-4.4 0-8 3.6-8 8s3.6 8 8 8h24V96c0 4.4 3.6 8 8 8s8-3.6 8-8V72h24c4.4 0 8-3.6 8-8s-3.6-8-8-8H232V32z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'tags-light'">
    <path
        d="M204.1 32c12.7 0 24.9 5.1 33.9 14.1L410.7 218.7c25 25 25 65.5 0 90.5L277.3 442.7c-25 25-65.5 25-90.5 0L14.1 270.1c-9-9-14.1-21.2-14.1-33.9V80C0 53.5 21.5 32 48 32H204.1zM36.7 247.4L209.4 420.1c12.5 12.5 32.8 12.5 45.3 0L388.1 286.6c12.5-12.5 12.5-32.8 0-45.3L215.4 68.7c-3-3-7.1-4.7-11.3-4.7H48c-8.8 0-16 7.2-16 16V236.1c0 4.2 1.7 8.3 4.7 11.3zM308.4 36.9c6.1-6.4 16.2-6.6 22.6-.5l141.3 135c52.8 50.4 52.8 134.7 0 185.1l-124.6 119c-6.4 6.1-16.5 5.9-22.6-.5s-5.9-16.5 .5-22.6l124.6-119c39.6-37.8 39.6-101 0-138.8L308.9 59.6c-6.4-6.1-6.6-16.2-.5-22.6zM104 112a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" *ngIf="icon === 'ellipsis-h-light'">
    <path
        d="M192 256c0 17.7-14.3 32-32 32s-32-14.3-32-32 14.3-32 32-32 32 14.3 32 32zm88-32c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm-240 0c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'square-light'">
    <path
        d="M384 64c17.7 0 32 14.3 32 32V416c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H384zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'square-check-light'">
    <path
        d="M64 64C46.3 64 32 78.3 32 96V416c0 17.7 14.3 32 32 32H384c17.7 0 32-14.3 32-32V96c0-17.7-14.3-32-32-32H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM331.3 203.3l-128 128c-6.2 6.2-16.4 6.2-22.6 0l-64-64c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L192 297.4 308.7 180.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 20 320 512" *ngIf="icon === 'times-light'">
    <path
        d="M193.94 256L296.5 153.44l21.15-21.15c3.12-3.12 3.12-8.19 0-11.31l-22.63-22.63c-3.12-3.12-8.19-3.12-11.31 0L160 222.06 36.29 98.34c-3.12-3.12-8.19-3.12-11.31 0L2.34 120.97c-3.12 3.12-3.12 8.19 0 11.31L126.06 256 2.34 379.71c-3.12 3.12-3.12 8.19 0 11.31l22.63 22.63c3.12 3.12 8.19 3.12 11.31 0L160 289.94 262.56 392.5l21.15 21.15c3.12 3.12 8.19 3.12 11.31 0l22.63-22.63c3.12-3.12 3.12-8.19 0-11.31L193.94 256z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" *ngIf="icon === 'plus-light'">
    <path
        d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" *ngIf="icon === 'user-friends-light'">
    <path
        d="M480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm0-160c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zM192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm0-192c44.1 0 80 35.9 80 80s-35.9 80-80 80-80-35.9-80-80 35.9-80 80-80zm80.1 212c-33.4 0-41.7 12-80.1 12-38.4 0-46.7-12-80.1-12-36.3 0-71.6 16.2-92.3 46.9C7.2 341.3 0 363.4 0 387.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-44.8c0-23.8-7.2-45.9-19.6-64.3-20.7-30.7-56-46.9-92.3-46.9zM352 432c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-44.8c0-16.6 4.9-32.7 14.1-46.4 13.8-20.5 38.4-32.8 65.7-32.8 27.4 0 37.2 12 80.2 12s52.8-12 80.1-12c27.3 0 51.9 12.3 65.7 32.8 9.2 13.7 14.1 29.8 14.1 46.4V432zm271.7-114.9C606.4 291.5 577 278 546.8 278c-27.8 0-34.8 10-66.8 10s-39-10-66.8-10c-13.2 0-26.1 3-38.1 8.1 15.2 15.4 18.5 23.6 20.2 26.6 5.7-1.6 11.6-2.6 17.9-2.6 21.8 0 30 10 66.8 10s45-10 66.8-10c21 0 39.8 9.3 50.4 25 7.1 10.5 10.9 22.9 10.9 35.7V408c0 4.4-3.6 8-8 8H416c0 17.7.3 22.5-1.6 32H600c22.1 0 40-17.9 40-40v-37.3c0-19.9-6-38.3-16.3-53.6z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" *ngIf="icon === 'sitemap-light'">
    <path
        d="M608 352h-32v-97.59c0-16.77-13.62-30.41-30.41-30.41H336v-64h48c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h48v64H94.41C77.62 224 64 237.64 64 254.41V352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32H96v-96h208v96h-32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32h-32v-96h208v96h-32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-480 32v96H32v-96h96zm240 0v96h-96v-96h96zM256 128V32h128v96H256zm352 352h-96v-96h96v96z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" *ngIf="icon === 'boxes-alt-light'">
    <path
        d="M624 224H480V16c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16v208H16c-8.8 0-16 7.2-16 16v256c0 8.8 7.2 16 16 16h608c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zm-112 32v64h-64v-64h64zM288 32h64v64h-64V32zm-96 0h64v64c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V32h64v192H192V32zm-64 224h64v64h-64v-64zm176 224H32V256h64v64c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32v-64h80v224zm304 0H336V256h80v64c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32v-64h64v224z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'analytics-light'">
    <path
        d="M80 352H16c-8.84 0-16 7.16-16 16v128c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V368c0-8.84-7.16-16-16-16zM64 480H32v-96h32v96zm496-288h-64c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm-16 288h-32V224h32v256zM502.77 88.68C510.12 93.24 518.71 96 528 96c26.51 0 48-21.49 48-48S554.51 0 528 0s-48 21.49-48 48c0 5.51 1.12 10.71 2.83 15.64l-89.6 71.68c-7.35-4.57-15.94-7.33-25.23-7.33s-17.88 2.76-25.23 7.33l-89.6-71.68C254.88 58.72 256 53.51 256 48c0-26.51-21.49-48-48-48s-48 21.49-48 48c0 7.4 1.81 14.32 4.8 20.58L68.58 164.8C62.32 161.81 55.4 160 48 160c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-7.4-1.81-14.32-4.8-20.58l96.22-96.22C193.68 94.19 200.6 96 208 96c9.29 0 17.88-2.76 25.23-7.33l89.6 71.68c-1.71 4.93-2.83 10.14-2.83 15.65 0 26.51 21.49 48 48 48s48-21.49 48-48c0-5.51-1.12-10.72-2.83-15.65l89.6-71.67zM528 32c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 224c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zM208 64c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm160 128c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16zm-128 0h-64c-8.84 0-16 7.16-16 16v288c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V208c0-8.84-7.16-16-16-16zm-16 288h-32V224h32v256zm176-160h-64c-8.84 0-16 7.16-16 16v160c0 8.84 7.16 16 16 16h64c8.84 0 16-7.16 16-16V336c0-8.84-7.16-16-16-16zm-16 160h-32V352h32v128z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" *ngIf="icon === 'file-chart-column-light'">
    <path
        d="M365.3 125.3l-106.5-106.5C246.7 6.742 230.5 0 213.5 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.35 28.65 64 64 64H320c35.35 0 64-28.65 64-64V170.5C384 153.5 377.3 137.3 365.3 125.3zM224 34.08c4.477 1.566 8.666 3.846 12.12 7.299l106.5 106.5C346.1 151.3 348.4 155.5 349.9 160H240C231.2 160 224 152.8 224 144V34.08zM352 448c0 17.64-14.36 32-32 32H64c-17.64 0-32-14.36-32-32V64c0-17.64 14.36-32 32-32h128v112C192 170.5 213.5 192 240 192H352V448zM96 352c-8.844 0-16 7.156-16 16v64C80 440.8 87.16 448 96 448s16-7.156 16-16v-64C112 359.2 104.8 352 96 352zM192 224C183.2 224 176 231.2 176 240v192c0 8.844 7.156 16 16 16s16-7.156 16-16v-192C208 231.2 200.8 224 192 224zM288 448c8.844 0 16-7.156 16-16v-128C304 295.2 296.8 288 288 288s-16 7.156-16 16v128C272 440.8 279.2 448 288 448z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'history-light'">
    <path
        d="M20 24h10c6.627 0 12 5.373 12 12v94.625C85.196 57.047 165.239 7.715 256.793 8.001 393.18 8.428 504.213 120.009 504 256.396 503.786 393.181 392.834 504 256 504c-63.926 0-122.202-24.187-166.178-63.908-5.113-4.618-5.354-12.561-.482-17.433l7.069-7.069c4.503-4.503 11.749-4.714 16.482-.454C150.782 449.238 200.935 470 256 470c117.744 0 214-95.331 214-214 0-117.744-95.331-214-214-214-82.862 0-154.737 47.077-190.289 116H164c6.627 0 12 5.373 12 12v10c0 6.627-5.373 12-12 12H20c-6.627 0-12-5.373-12-12V36c0-6.627 5.373-12 12-12zm321.647 315.235l4.706-6.47c3.898-5.36 2.713-12.865-2.647-16.763L272 263.853V116c0-6.627-5.373-12-12-12h-8c-6.627 0-12 5.373-12 12v164.147l84.884 61.734c5.36 3.899 12.865 2.714 16.763-2.646z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" *ngIf="icon === 'users-class-light'">
    <path
        d="M544 224c-44.2 0-80 35.8-80 80s35.8 80 80 80 80-35.8 80-80-35.8-80-80-80zm0 128c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm-304-48c0 44.2 35.8 80 80 80s80-35.8 80-80-35.8-80-80-80-80 35.8-80 80zm128 0c0 26.5-21.5 48-48 48s-48-21.5-48-48 21.5-48 48-48 48 21.5 48 48zM96 384c44.2 0 80-35.8 80-80s-35.8-80-80-80-80 35.8-80 80 35.8 80 80 80zm0-128c26.5 0 48 21.5 48 48s-21.5 48-48 48-48-21.5-48-48 21.5-48 48-48zm468 160h-40c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zm-448 0H76c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zm224 0h-40c-41.9 0-76 35.9-76 80 0 8.8 7.2 16 16 16s16-7.2 16-16c0-26.5 19.8-48 44-48h40c24.2 0 44 21.5 44 48 0 8.8 7.2 16 16 16s16-7.2 16-16c0-44.1-34.1-80-76-80zM64 48c0-8.83 7.19-16 16-16h480c8.81 0 16 7.17 16 16v149.22c11.51 3.46 22.37 8.36 32 15.11V48c0-26.47-21.53-48-48-48H80C53.53 0 32 21.53 32 48v164.33c9.63-6.75 20.49-11.64 32-15.11V48z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" *ngIf="icon === 'tachometer-alt-fast-light'">
    <path
        d="M120 320c0 13.26-10.74 24-24 24s-24-10.74-24-24 10.74-24 24-24 24 10.74 24 24zm168-168c13.26 0 24-10.74 24-24s-10.74-24-24-24-24 10.74-24 24 10.74 24 24 24zm-136 8c-13.26 0-24 10.74-24 24s10.74 24 24 24 24-10.74 24-24-10.74-24-24-24zm282.06 11.56c6.88 5.56 7.94 15.64 2.38 22.5l-97.14 120C347.18 324.7 352 337.74 352 352c0 35.35-28.65 64-64 64s-64-28.65-64-64 28.65-64 64-64c9.47 0 18.38 2.18 26.47 5.88l97.09-119.94c5.56-6.88 15.6-7.92 22.5-2.38zM320 352c0-17.67-14.33-32-32-32s-32 14.33-32 32 14.33 32 32 32 32-14.33 32-32zm160-56c-13.26 0-24 10.74-24 24s10.74 24 24 24 24-10.74 24-24-10.74-24-24-24zm96 24c0 52.8-14.25 102.26-39.06 144.8-5.61 9.62-16.3 15.2-27.44 15.2h-443c-11.14 0-21.83-5.58-27.44-15.2C14.25 422.26 0 372.8 0 320 0 160.94 128.94 32 288 32s288 128.94 288 288zm-32 0c0-141.16-114.84-256-256-256S32 178.84 32 320c0 45.26 12 89.75 34.7 128.68l442.8-.68C532 409.75 544 365.26 544 320z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'ballot-check-light'">
    <path
        d="M112 432h32c17.7 0 32-14.4 32-32v-32c0-17.6-14.3-32-32-32h-32c-17.7 0-32 14.4-32 32v32c0 17.6 14.3 32 32 32zm0-64h32v32h-32v-32zm0-192h32c17.7 0 32-14.4 32-32v-32c0-17.6-14.3-32-32-32h-32c-17.7 0-32 14.4-32 32v32c0 17.6 14.3 32 32 32zm0-64h32v32h-32v-32zM416 0H32C14.3 0 0 14.4 0 32v448c0 17.6 14.3 32 32 32h384c17.7 0 32-14.4 32-32V32c0-17.6-14.3-32-32-32zm0 480H32V32h384v448zM216 144h128c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0 128h128c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0 128h128c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H216c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm-97.4-113.6c2.1 2.1 5.5 2.1 7.6 0l64.2-63.6c2.1-2.1 2.1-5.5 0-7.6l-12.6-12.7c-2.1-2.1-5.5-2.1-7.6 0l-47.6 47.2-20.6-20.9c-2.1-2.1-5.5-2.1-7.6 0l-12.7 12.6c-2.1 2.1-2.1 5.5 0 7.6l36.9 37.4z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'files-light'">
    <path
        d="M160 384c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H304v80c0 17.7 14.3 32 32 32h80c0 .3 0 .5 0 .8V352c0 17.7-14.3 32-32 32H160zM336 57.5L390 112H336V57.5zM160 0C124.7 0 96 28.7 96 64V352c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V144.8c0-12.7-5-24.8-13.9-33.8l-96-96.8C329.1 5.1 316.8 0 304 0H160zM32 112c0-8.8-7.2-16-16-16s-16 7.2-16 16V384c0 70.7 57.3 128 128 128H336c8.8 0 16-7.2 16-16s-7.2-16-16-16H128c-53 0-96-43-96-96V112z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'file-import-light'">
    <path
        d="M497.9 97.98L414.02 14.1c-9-9-21.2-14.1-33.89-14.1H175.99C149.5.1 128 21.6 128 48.09V288H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h248v52.67c0 10.98 6.38 20.55 16.69 24.97 14.93 6.45 26.88-1.61 30.88-5.39l71.72-68.12c5.62-5.33 8.72-12.48 8.72-20.12s-3.09-14.8-8.69-20.11l-71.78-68.16c-8.28-7.8-20.41-9.88-30.84-5.38-10.31 4.42-16.69 13.98-16.69 24.97V288H160V48.09c0-8.8 7.2-16.09 16-16.09h176.04v104.07c0 13.3 10.7 23.93 24 23.93h103.98v304.01c0 8.8-7.2 16-16 16H175.99c-8.8 0-16-7.2-16-16V352H128v112.01c0 26.49 21.5 47.99 47.99 47.99h288.02c26.49 0 47.99-21.5 47.99-47.99V131.97c0-12.69-5.1-24.99-14.1-33.99zM288 245.12L350 304l-62 58.88V245.12zm96.03-117.05V32.59c2.8.7 5.3 2.1 7.4 4.2l83.88 83.88c2.1 2.1 3.5 4.6 4.2 7.4h-95.48z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'layer-group-light'">
    <path
        d="M512 256.01c0-9.98-5.81-18.94-14.77-22.81l-99.74-43.27 99.7-43.26c9-3.89 14.81-12.84 14.81-22.81s-5.81-18.92-14.77-22.79L271.94 3.33c-10.1-4.44-21.71-4.45-31.87-.02L14.81 101.06C5.81 104.95 0 113.9 0 123.87s5.81 18.92 14.77 22.79l99.73 43.28-99.7 43.26C5.81 237.08 0 246.03 0 256.01c0 9.97 5.81 18.92 14.77 22.79l99.72 43.26-99.69 43.25C5.81 369.21 0 378.16 0 388.14c0 9.97 5.81 18.92 14.77 22.79l225.32 97.76a40.066 40.066 0 0 0 15.9 3.31c5.42 0 10.84-1.1 15.9-3.31l225.29-97.74c9-3.89 14.81-12.84 14.81-22.81 0-9.98-5.81-18.94-14.77-22.81l-99.72-43.26 99.69-43.25c9-3.89 14.81-12.84 14.81-22.81zM45.23 123.87l208.03-90.26.03-.02c1.74-.71 3.65-.76 5.45.02l208.03 90.26-208.03 90.27c-1.81.77-3.74.77-5.48 0L45.23 123.87zm421.54 264.27L258.74 478.4c-1.81.77-3.74.77-5.48 0L45.23 388.13l110.76-48.06 84.11 36.49a40.066 40.066 0 0 0 15.9 3.31c5.42 0 10.84-1.1 15.9-3.31l84.11-36.49 110.76 48.07zm-208.03-41.87c-1.81.77-3.74.77-5.48 0L45.23 256 156 207.94l84.1 36.5a40.066 40.066 0 0 0 15.9 3.31c5.42 0 10.84-1.1 15.9-3.31l84.1-36.49 110.77 48.07-208.03 90.25z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'cog-light'">
    <path
        d="M482.696 299.276l-32.61-18.827a195.168 195.168 0 0 0 0-48.899l32.61-18.827c9.576-5.528 14.195-16.902 11.046-27.501-11.214-37.749-31.175-71.728-57.535-99.595-7.634-8.07-19.817-9.836-29.437-4.282l-32.562 18.798a194.125 194.125 0 0 0-42.339-24.48V38.049c0-11.13-7.652-20.804-18.484-23.367-37.644-8.909-77.118-8.91-114.77 0-10.831 2.563-18.484 12.236-18.484 23.367v37.614a194.101 194.101 0 0 0-42.339 24.48L105.23 81.345c-9.621-5.554-21.804-3.788-29.437 4.282-26.36 27.867-46.321 61.847-57.535 99.595-3.149 10.599 1.47 21.972 11.046 27.501l32.61 18.827a195.168 195.168 0 0 0 0 48.899l-32.61 18.827c-9.576 5.528-14.195 16.902-11.046 27.501 11.214 37.748 31.175 71.728 57.535 99.595 7.634 8.07 19.817 9.836 29.437 4.283l32.562-18.798a194.08 194.08 0 0 0 42.339 24.479v37.614c0 11.13 7.652 20.804 18.484 23.367 37.645 8.909 77.118 8.91 114.77 0 10.831-2.563 18.484-12.236 18.484-23.367v-37.614a194.138 194.138 0 0 0 42.339-24.479l32.562 18.798c9.62 5.554 21.803 3.788 29.437-4.283 26.36-27.867 46.321-61.847 57.535-99.595 3.149-10.599-1.47-21.972-11.046-27.501zm-65.479 100.461l-46.309-26.74c-26.988 23.071-36.559 28.876-71.039 41.059v53.479a217.145 217.145 0 0 1-87.738 0v-53.479c-33.621-11.879-43.355-17.395-71.039-41.059l-46.309 26.74c-19.71-22.09-34.689-47.989-43.929-75.958l46.329-26.74c-6.535-35.417-6.538-46.644 0-82.079l-46.329-26.74c9.24-27.969 24.22-53.869 43.929-75.969l46.309 26.76c27.377-23.434 37.063-29.065 71.039-41.069V44.464a216.79 216.79 0 0 1 87.738 0v53.479c33.978 12.005 43.665 17.637 71.039 41.069l46.309-26.76c19.709 22.099 34.689 47.999 43.929 75.969l-46.329 26.74c6.536 35.426 6.538 46.644 0 82.079l46.329 26.74c-9.24 27.968-24.219 53.868-43.929 75.957zM256 160c-52.935 0-96 43.065-96 96s43.065 96 96 96 96-43.065 96-96-43.065-96-96-96zm0 160c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'dot-circle-regular'">
    <path
        d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'trash-light'">
    <path
        d="M440 64H336l-33.6-44.8A48 48 0 0 0 264 0h-80a48 48 0 0 0-38.4 19.2L112 64H8a8 8 0 0 0-8 8v16a8 8 0 0 0 8 8h18.9l33.2 372.3a48 48 0 0 0 47.8 43.7h232.2a48 48 0 0 0 47.8-43.7L421.1 96H440a8 8 0 0 0 8-8V72a8 8 0 0 0-8-8zM171.2 38.4A16.1 16.1 0 0 1 184 32h80a16.1 16.1 0 0 1 12.8 6.4L296 64H152zm184.8 427a15.91 15.91 0 0 1-15.9 14.6H107.9A15.91 15.91 0 0 1 92 465.4L59 96h330z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'tag-light'">
    <path
        d="M32 229.5V80c0-8.8 7.2-16 16-16H197.5c8.5 0 16.6 3.4 22.6 9.4l176 176c12.5 12.5 12.5 32.8 0 45.3L262.6 428.1c-12.5 12.5-32.8 12.5-45.3 0l-176-176L18.7 274.7l22.6-22.6c-6-6-9.4-14.1-9.4-22.6zm-32 0c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80V229.5zM112 168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'tag-solid'">
    <path
        d="M0 80V229.5c0 17 6.7 33.3 18.7 45.3l176 176c25 25 65.5 25 90.5 0L418.7 317.3c25-25 25-65.5 0-90.5l-176-176c-12-12-28.3-18.7-45.3-18.7H48C21.5 32 0 53.5 0 80zm112 32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'check-light'">
    <path
        d="M413.505 91.951L133.49 371.966l-98.995-98.995c-4.686-4.686-12.284-4.686-16.971 0L6.211 284.284c-4.686 4.686-4.686 12.284 0 16.971l118.794 118.794c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-11.314-11.314c-4.686-4.686-12.284-4.686-16.97 0z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
     *ngIf="icon === 'exclamation-triangle-light'">
    <path
        d="M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" *ngIf="icon === 'language-light'">
    <path
        d="M616 96H24c-13.255 0-24 10.745-24 24v272c0 13.255 10.745 24 24 24h592c13.255 0 24-10.745 24-24V120c0-13.255-10.745-24-24-24zM304 384H32V128h272v256zm304 0H336V128h272v256zM91.088 352h10.34a12 12 0 0 0 11.397-8.243l13.508-40.973h67.335l13.508 40.973A12.001 12.001 0 0 0 218.573 352h10.339c8.276 0 14.067-8.18 11.319-15.985l-59.155-168A12 12 0 0 0 169.757 160h-19.513a12 12 0 0 0-11.319 8.014l-59.155 168C77.021 343.82 82.812 352 91.088 352zm60.663-128.991c3.787-10.818 8.113-29.747 8.113-29.747h.541s4.057 18.929 7.572 29.747l17.036 51.38h-50.298l17.036-51.38zM384 212v-8c0-6.627 5.373-12 12-12h68v-20c0-6.627 5.373-12 12-12h8c6.627 0 12 5.373 12 12v20h68c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-15.699c-7.505 24.802-23.432 50.942-44.896 74.842 10.013 9.083 20.475 17.265 30.924 24.086 5.312 3.467 6.987 10.475 3.84 15.982l-3.987 6.976c-3.429 6.001-11.188 7.844-16.993 4.091-13.145-8.5-25.396-18.237-36.56-28.5-11.744 10.454-24.506 20.146-37.992 28.68-5.761 3.646-13.409 1.698-16.791-4.221l-3.972-6.95c-3.197-5.594-1.379-12.672 4.058-16.129 11.382-7.237 22.22-15.428 32.24-24.227-10.026-11.272-18.671-22.562-25.687-33.033-3.833-5.721-2.11-13.48 3.803-17.01l6.867-4.099c5.469-3.264 12.55-1.701 16.092 3.592 6.379 9.531 13.719 18.947 21.677 27.953 15.017-16.935 26.721-34.905 33.549-52.033H396c-6.627 0-12-5.373-12-12z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" *ngIf="icon === 'ranking-star-light'">
    <path
        d="M295.5 15.2C305.7-5.415 334.9-4.753 344.6 15.32L364.5 55.48L408.6 61.84C430.6 65.25 440.1 92.48 423.9 108.7L423.7 108.9L391.8 139.7L399.4 183.7C402.8 205.8 379.9 222.9 359.6 212.7L359.3 212.5L320.2 192L280.4 213C260.1 223.2 237.2 206.1 240.6 184.1L240.7 183.8L248.2 139.1L216.1 108.7C199.9 92.48 209.4 65.25 231.4 61.84L231.6 61.82L275.5 55.48L295.5 15.24L295.5 15.2zM296.8 84.75L245 92.21L282.6 128.8L273.7 180.4L320.2 155.8L366.3 180.1L357.4 128.4L394.9 92.21L343.2 84.75L319.1 37.79L296.8 84.75zM192 368V464C192 490.5 170.5 512 144 512H48C21.49 512 0 490.5 0 464V368C0 341.5 21.49 320 48 320H144C170.5 320 192 341.5 192 368zM32 464C32 472.8 39.16 480 48 480H144C152.8 480 160 472.8 160 464V368C160 359.2 152.8 352 144 352H48C39.16 352 32 359.2 32 368V464zM416 464C416 490.5 394.5 512 368 512H272C245.5 512 224 490.5 224 464V304C224 277.5 245.5 256 272 256H368C394.5 256 416 277.5 416 304V464zM256 464C256 472.8 263.2 480 272 480H368C376.8 480 384 472.8 384 464V304C384 295.2 376.8 288 368 288H272C263.2 288 256 295.2 256 304V464zM592 384C618.5 384 640 405.5 640 432V464C640 490.5 618.5 512 592 512H496C469.5 512 448 490.5 448 464V432C448 405.5 469.5 384 496 384H592zM480 464C480 472.8 487.2 480 496 480H592C600.8 480 608 472.8 608 464V432C608 423.2 600.8 416 592 416H496C487.2 416 480 423.2 480 432V464z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'shuffle'">
    <!--!Font Awesome Pro 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.-->
    <path
        d="M427.3 36.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 112H400 344c-20.1 0-39.1 9.5-51.2 25.6L244 202.7l20 26.7 54.4-72.5c6-8.1 15.5-12.8 25.6-12.8h56 57.4l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80zM124.8 361.6c-3 4-7.8 6.4-12.8 6.4H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c15.1 0 29.3-7.1 38.4-19.2L204 309.3l-20-26.7-59.2 78.9zM404.7 475.3c6.2 6.2 16.4 6.2 22.6 0l80-80c6.2-6.2 6.2-16.4 0-22.6l-80-80c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L457.4 368H400 344c-10.1 0-19.6-4.7-25.6-12.8l-168-224C141.3 119.1 127.1 112 112 112H16c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c5 0 9.8 2.4 12.8 6.4l168 224C304.9 390.5 323.9 400 344 400h56 57.4l-52.7 52.7c-6.2 6.2-6.2 16.4 0 22.6z"/>
</svg>

<svg *ngIf="icon === 'free-text'"
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 125 100"
     id="svg8">
    <g
        id="layer1"
        transform="translate(0,-197)">
        <path
            style="opacity:1;fill:none;fill-opacity:1;fill-rule:nonzero;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers"
            d="m 84.93246,274.99561 -74.684363,-10e-6 c -0.8947565,0 -1.6150835,-0.83106 -1.6150835,-1.86334 v -52.26454 c 0,-1.03229 0.720327,-1.86333 1.6150835,-1.86333 v 0 h 74.684358"
            id="path953"/>
        <path
            style="opacity:1;fill:none;fill-opacity:1;fill-rule:nonzero;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:stroke fill markers"
            d="m 101.03898,219.00439 h 13.79306 c 0.85035,0 1.53494,0.83104 1.53494,1.86333 v 52.26454 c 0,1.03228 -0.68459,1.86334 -1.53494,1.86334 h -13.79306"
            id="path949"/>
        <g
            id="g895"
            transform="matrix(1.1471223,0,0,1.0112245,-1.4230896,-84.410064)">
            <path
                id="path885"
                d="m 17.246143,343.50502 11.715748,-30.58523 11.61086,30.50871"
                style="fill:none;stroke-width:4.00002003;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill"/>
            <path
                id="path889"
                d="M 20.883184,334.63225 H 37.112537"
                style="fill:none;stroke-width:4;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        </g>
        <path
            style="fill:none;stroke-width:4.28347015;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821-31-1"
            transform="matrix(0,1.0120623,-0.86163086,0,281.29822,194.99018)"/>
        <path
            style="fill:none;stroke-width:20.28886604;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821-3-9"
            transform="matrix(0.19042928,0,0,0.20614611,83.578875,159.12155)"/>
        <path
            style="fill:none;stroke-width:20.28886604;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821-3-9-3"
            transform="matrix(0.19042928,0,0,0.20614611,83.578875,244.8749)"/>
    </g>
</svg>

<svg *ngIf="icon === 'multiple-choice'"
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 125 100"
     id="svg8">
    <g
        id="layer1"
        transform="translate(0,-197)">
        <path
            style="fill:none;stroke-width:7.28477097;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 195.70811,212.67644 c -4.9999,4.9999 -10.00007,10.00007 -12.50584,12.55027 -2.50577,2.55021 -2.51689,2.65039 -3.85153,1.3716 -1.33463,-1.27879 -3.97738,-3.92153 -6.6278,-6.57195"
            id="path1016"
            transform="matrix(0.53203861,0,0,0.55513253,9.6667997,98.94452)"/>
        <circle
            style="opacity:1;fill:none;fill-opacity:1;fill-rule:nonzero;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke"
            id="path827-9"
            cx="107.50967"
            cy="220.58679"
            r="13.040178"/>
        <path
            style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821"
            transform="matrix(1.0145254,0,0,1,-0.13336933,6.54799)"/>
        <path
            style="fill:none;stroke-width:7.28477097;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 195.70811,212.67644 c -4.9999,4.9999 -10.00007,10.00007 -12.50584,12.55027 -2.50577,2.55021 -2.51689,2.65039 -3.85153,1.3716 -1.33463,-1.27879 -3.97738,-3.92153 -6.6278,-6.57195"
            id="path1016-0"
            transform="matrix(0.53203861,0,0,0.55513253,9.6667997,148.38938)"/>
        <circle
            style="opacity:1;fill:none;fill-opacity:1;fill-rule:nonzero;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke"
            id="path827-9-9"
            cx="107.50967"
            cy="270.03165"
            r="13.040178"/>
        <path
            style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821-3"
            transform="matrix(0.99484571,0,0,1,0.04701299,55.99285)"/>
        <path
            style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821-31"
            transform="matrix(1.0232719,0,0,1,-0.21353925,31.270422)"/>
    </g>
</svg>

<svg *ngIf="icon === 'reflection-free-text'"
     viewBox="0 0 125 100"
     id="svg8"
     xmlns="http://www.w3.org/2000/svg">
    <g
        id="layer1"
        transform="translate(0,-197)">
        <g
            id="g17890"
            transform="matrix(2.5210955,0,0,2.5210953,-284.86564,-478.41017)"
            style="fill:none;stroke-opacity:1;stroke-width:1.58661192;stroke-dasharray:none">
            <g
                id="g2374"
                transform="matrix(1.0604517,0,0,1.0604474,-8.3559172,-17.562106)"
                style="stroke-width:1.49616916;stroke-dasharray:none">
                <path
                    style="fill:none;fill-opacity:1;fill-rule:nonzero;stroke-width:1.49616916;stroke-linecap:square;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
                    id="path164-5"
                    d="m 125.96657,295.08336 a 14.765623,14.151412 0 0 1 0.48867,-17.56165 14.765623,14.151412 0 0 1 17.9472,-3.57338 14.765623,14.151412 0 0 1 7.74671,15.92194 14.765623,14.151412 0 0 1 -14.39248,10.87945"
                />
                <path
                    style="fill:none;fill-opacity:1;stroke-width:1.49616916;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
                    d="m 134.17675,289.74249 c 1.07857,-0.21224 2.15674,-0.42439 3.14138,-0.25116 0.98463,0.17323 1.87526,0.73164 2.76554,1.28983"
                    id="path3850-3-8"/>
                <ellipse
                    style="fill:none;fill-opacity:0.938619;fill-rule:nonzero;stroke-width:1.49616916;stroke-linecap:square;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
                    id="path3549-1-9"
                    cx="142.07532"
                    cy="283.12332"
                    rx="1.230165"
                    ry="2.1248298"/>
                <ellipse
                    style="fill:none;fill-opacity:0.938619;fill-rule:nonzero;stroke-width:1.49616916;stroke-linecap:square;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:markers stroke fill"
                    id="path3549-6"
                    cx="132.65001"
                    cy="281.98087"
                    rx="1.230165"
                    ry="2.1248298"/>
                <path
                    style="fill:none;fill-opacity:1;stroke-width:1.49616916;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
                    d="m 139.9211,280.67567 c 0.88964,-0.31336 1.77901,-0.62663 2.65587,-0.62654 0.87687,9e-5 1.7409,0.31338 2.60458,0.62654"
                    id="path3850-0-0"/>
                <path
                    style="fill:none;fill-opacity:1;stroke-width:1.49616916;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
                    d="m 129.92456,277.34349 c 0.88964,-0.31336 1.77903,-0.62664 2.65589,-0.62655 0.87686,8e-5 1.7409,0.31338 2.60459,0.62655"
                    id="path3850-5"/>
                <path
                    style="fill:none;fill-opacity:1;stroke-width:1.49616916;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1"
                    d="m 129.1699,288.4886 c -0.19774,0.23453 -0.2552,0.63344 -0.095,0.89185 0.27791,0.44813 0.43014,1.73399 0.2161,2.56941 -0.12291,0.47954 -0.43708,0.92824 -0.82517,1.26181 -0.91303,0.78473 -1.92969,1.69882 -2.42703,2.80952 -0.29188,0.65188 -0.32844,1.40305 -0.25721,2.10672 0.0832,0.8223 0.34174,1.65339 0.78921,2.36536 0.47896,0.76212 1.15842,1.44448 1.95394,1.92136 0.86437,0.51813 1.88847,0.89089 2.91402,0.93181 0.99885,0.0398 2.04386,-0.19421 3.06541,-0.42332 0.88021,-0.19741 1.76265,-0.34626 2.35276,-0.83913 0.26047,-0.23439 0.43429,-0.61522 0.3812,-0.94974 -0.0462,-0.29087 -0.22582,-0.42643 -0.57363,-0.7 0.45845,-0.11818 0.79983,-0.45087 0.96278,-0.82957 0.13492,-0.31363 0.1048,-0.71446 -0.066,-1.01237 -0.1334,-0.23276 -0.67568,-0.47755 -0.67568,-0.47755 0.33148,-0.19947 0.62508,-0.5178 0.75041,-0.87216 0.11253,-0.3183 0.12457,-0.70802 -0.0415,-1.00509 -0.14346,-0.25652 -0.37783,-0.41603 -0.74157,-0.52228 -0.0931,-0.28513 3.31972,-1.30216 5.22686,-1.41126 0.26647,-0.0156 0.61766,-0.48836 0.69406,-0.83319 0.0701,-0.31721 -0.0351,-0.69486 -0.26025,-0.94087 -0.21489,-0.23477 -0.57605,-0.37386 -0.90987,-0.36736 7.9e-4,-0.25406 -8.76066,1.56149 -9.3305,1.65377 -0.342,0.0554 -0.52334,-0.44389 -0.074,-1.28359 0.13662,-0.25528 0.1806,-0.4976 0.18477,-0.70627 0.005,-0.25994 0.0184,-0.51514 -0.0435,-0.86609 -0.0905,-0.51375 -0.2821,-0.98676 -0.55049,-1.42499 -0.24952,-0.40735 -0.42342,-0.75745 -0.96277,-1.08276 0,0 -0.44803,-0.26711 -0.70132,-0.27447 -0.33708,-0.0108 -0.74693,0.0627 -0.95586,0.31038 z"
                    id="path6984-2"/>
            </g>
        </g>
    </g>
</svg>

<svg *ngIf="icon === 'single-choice'"
     xmlns="http://www.w3.org/2000/svg"
     viewBox="0 0 125 100"
     id="svg8">
    <g
        id="layer1"
        transform="translate(0,-196.99999)">
        <path
            style="fill:none;stroke-width:7.28477097;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 195.70811,212.67644 c -4.9999,4.9999 -10.00007,10.00007 -12.50584,12.55027 -2.50577,2.55021 -2.51689,2.65039 -3.85153,1.3716 -1.33463,-1.27879 -3.97738,-3.92153 -6.6278,-6.57195"
            id="path1016"
            transform="matrix(0.53203861,0,0,0.55513253,9.6670253,97.338234)"/>
        <circle
            style="opacity:1;fill:none;fill-opacity:1;fill-rule:nonzero;stroke-width:4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1;paint-order:fill markers stroke"
            id="path827-9"
            cx="107.5099"
            cy="218.9805"
            r="13.040178"/>
        <path
            style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821"
            transform="matrix(1.0232719,0,0,1,-0.21330836,6.5481034)"/>
        <path
            style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821-0"
            transform="matrix(1.0232719,0,0,1,-0.21330836,31.270535)"/>
        <path
            style="fill:none;stroke-width:4;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 93.613956,218.19195 c -28.149079,0 -56.298425,0 -84.4480335,0"
            id="path821-7"
            transform="matrix(1.0232719,0,0,1,-0.21330836,55.992965)"/>
    </g>
</svg>
<svg *ngIf="icon === 'true-false'"
     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 100">
    <g transform="translate(0,-197)">
        <path
            style="fill:none;stroke-width:3.13026754;stroke-linecap:round;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="m 195.70811,212.67644 c -4.9999,4.9999 -10.00007,10.00007 -12.50584,12.55027 -2.50577,2.55021 -2.51689,2.65039 -3.85153,1.3716 -1.33463,-1.27879 -3.97738,-3.92153 -6.6278,-6.57195"
            id="path1016-3-3"
            transform="matrix(1.8355092,0,0,2.0016264,-301.52231,-210.8057)"/>
        <g transform="matrix(2.4480582,0,0,2.4480582,182.24457,-637.10241)">
            <path d="m -42.241112,364.67346 c 3.946398,3.9464 7.941526,7.94152 11.912285,11.91228"
                  style="fill:none;stroke-width:2.45092212;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
            <path
                transform="rotate(90,-36.28497,370.62959)"
                d="m -42.241112,364.67346 c 3.946398,3.9464 7.941526,7.94152 11.912285,11.91228"
                style="fill:none;stroke-width:2.45092212;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"/>
        </g>
        <path
            style="fill:none;stroke-width:12.29185273;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1"
            d="M 3.4017857,292.55877 C 45.892872,250.06769 88.383696,207.57687 130.87426,165.08631"
            transform="matrix(0.4868373,-0.03547657,0.03547657,0.4868373,20.463284,136.98386)"/>
    </g>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="icon === 'book-light'" viewBox="0 0 448 512">
    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
    <path
        d="M0 56C0 25.1 25.1 0 56 0H408c22.1 0 40 17.9 40 40V376c0 19.4-13.7 35.5-32 39.2V496h24c4.4 0 8 3.6 8 8s-3.6 8-8 8H56c-30.9 0-56-25.1-56-56V56zM56 416c-22.1 0-40 17.9-40 40s17.9 40 40 40H400V416H56zm-40 .8C26.2 406.4 40.3 400 56 400h56V16H56C33.9 16 16 33.9 16 56V416.8zM128 16V400H408c13.3 0 24-10.7 24-24V40c0-13.3-10.7-24-24-24H128zm48 120c0-4.4 3.6-8 8-8H376c4.4 0 8 3.6 8 8s-3.6 8-8 8H184c-4.4 0-8-3.6-8-8zm0 80c0-4.4 3.6-8 8-8H376c4.4 0 8 3.6 8 8s-3.6 8-8 8H184c-4.4 0-8-3.6-8-8z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" *ngIf="icon === 'box-taped-thin'" viewBox="0 0 448 512">
    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
    <path
        d="M240 48h3L270 176H178L205 48h3 8 16 8zM160 192v80c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V192h16H432V416c0 26.5-21.5 48-48 48H64c-26.5 0-48-21.5-48-48V192H144h16zm112 0v80c0 8.8-7.2 16-16 16H192c-8.8 0-16-7.2-16-16V192h96zm32-16H286.3L259.4 48h83c19 0 36.2 11.2 43.9 28.5l41.6 93.6c.9 1.9 1.6 3.9 2.2 5.9H304zm-160 0H18c.6-2 1.3-4 2.2-5.9L61.7 76.5C69.4 59.2 86.6 48 105.6 48h83L161.7 176H144zM256 32H192 105.6C80.3 32 57.4 46.9 47.1 70L5.5 163.6c-3.6 8.2-5.5 17-5.5 26V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V189.6c0-9-1.9-17.8-5.5-26L400.9 70c-10.3-23.1-33.2-38-58.5-38H256z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" *ngIf="icon === 'file-spreadsheet-thin'">
    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
    <path
        d="M16 448V64c0-26.5 21.5-48 48-48H204.1c1.3 0 2.6 .1 3.9 .2V136c0 22.1 17.9 40 40 40H367.8c.2 1.3 .2 2.6 .2 3.9V448c0 26.5-21.5 48-48 48H64c-26.5 0-48-21.5-48-48zM358.6 157.3c.9 .9 1.7 1.8 2.4 2.7H248c-13.3 0-24-10.7-24-24V22.9c1 .8 1.9 1.6 2.7 2.4L358.6 157.3zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V179.9c0-12.7-5.1-24.9-14.1-33.9L238.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zM80 384h40v48H96c-8.8 0-16-7.2-16-16V384zm0-16V320h40v48H80zm56 64V384h48v48H136zm0-64V320h48v48H136zm64 64V384h48v48H200zm0-112h48v48H200V320zm64 112V384h40v32c0 8.8-7.2 16-16 16H264zm0-64V320h40v48H264zm56 8v-8V320v-8-8V256c0-17.7-14.3-32-32-32H192 96c-17.7 0-32 14.3-32 32v48 8 8 48 8 8 32c0 17.7 14.3 32 32 32h24 8 8 48 8 8 48 8 8 24c17.7 0 32-14.3 32-32V384v-8zM304 256v48H256 192 128 80V256c0-8.8 7.2-16 16-16h96 96c8.8 0 16 7.2 16 16z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 256 512" *ngIf="icon === 'chevron-right-light'">
    <path
        d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" *ngIf="icon === 'arrows-rotate-light'">
    <path
        d="M69.4 210.6C89.8 126.5 165.6 64 256 64c71.1 0 133.1 38.6 166.3 96H368c-8.8 0-16 7.2-16 16s7.2 16 16 16h80.7H464c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16s-16 7.2-16 16v60.6C408.8 75.5 337.5 32 256 32C149.6 32 60.5 106.2 37.7 205.6C35.5 215.2 43.1 224 53 224c7.9 0 14.6-5.7 16.5-13.4zm373.2 90.9C422.2 385.5 346.4 448 256 448c-71.1 0-133.1-38.6-166.3-96h54.5c8.8 0 16-7.2 16-16s-7.2-16-16-16H63.3 48.2c-8.8 0-16 7.2-16 16v96c0 8.8 7.2 16 16 16s16-7.2 16-16V371.8C103.4 436.6 174.7 480 256 480c106.4 0 195.5-74.2 218.3-173.6c2.2-9.6-5.4-18.4-15.3-18.4c-7.9 0-14.6 5.7-16.5 13.4z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" *ngIf="icon === 'arrows-rotate-regular'">
    <path
        d="M94 187.1C120.8 124.1 183.3 80 256 80c39.7 0 77.8 15.8 105.9 43.9L414.1 176H360c-13.3 0-24 10.7-24 24s10.7 24 24 24H472c13.3 0 24-10.7 24-24V88c0-13.3-10.7-24-24-24s-24 10.7-24 24v54.1L395.9 89.9C358.8 52.8 308.5 32 256 32C163.4 32 83.9 88.2 49.8 168.3c-5.2 12.2 .5 26.3 12.7 31.5s26.3-.5 31.5-12.7zm368 157c5.2-12.2-.4-26.3-12.6-31.5s-26.3 .4-31.5 12.6C391 388.1 328.6 432 256 432c-39.7 0-77.8-15.8-105.9-43.9L97.9 336H152c13.3 0 24-10.7 24-24s-10.7-24-24-24H40c-13.3 0-24 10.7-24 24V424c0 13.3 10.7 24 24 24s24-10.7 24-24V369.9l52.1 52.1C153.2 459.2 203.5 480 256 480c92.5 0 171.8-56 206-135.9z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" *ngIf="icon === 'copy-light'">
    <path
        d="M384 352H224c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H332.1c4.2 0 8.3 1.7 11.3 4.7l67.9 67.9c3 3 4.7 7.1 4.7 11.3V320c0 17.7-14.3 32-32 32zM433.9 81.9L366.1 14.1c-9-9-21.2-14.1-33.9-14.1H224c-35.3 0-64 28.7-64 64V320c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V115.9c0-12.7-5.1-24.9-14.1-33.9zM64 128c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H224c35.3 0 64-28.7 64-64V416H256v32c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V192c0-17.7 14.3-32 32-32h64V128H64z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" *ngIf="icon === 'user-group-crown'">
    <path
        d="M224 0c5.2 0 10.3 1.5 14.7 4.4l32.5 21.6c5.4 3.6 12.4 3.6 17.8 0L324 2.7c2.6-1.8 5.7-2.7 8.9-2.7H336c8.8 0 16 7.2 16 16V128v16c0 70.7-57.3 128-128 128s-128-57.3-128-128V128 16c0-8.8 7.2-16 16-16h3.2c3.2 0 6.2 .9 8.9 2.7l35.1 23.4c5.4 3.6 12.4 3.6 17.8 0L209.3 4.4C213.7 1.5 218.8 0 224 0zm96 43.8l-13.4 8.9c-16.1 10.7-37.1 10.7-53.3 0L224 33.1 194.6 52.7c-16.1 10.7-37.1 10.7-53.3 0L128 43.8V112H320V43.8zM224 240c53 0 96-43 96-96H128c0 53 43 96 96 96zM32 472v8H416v-8c0-75.1-60.9-136-136-136H168C92.9 336 32 396.9 32 472zM0 472c0-92.8 75.2-168 168-168H280c92.8 0 168 75.2 168 168v8c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32v-8zM432 256c-27.7 0-53-10.1-72.6-26.7c1.9-2.9 3.6-6 5.3-9c3.6-6.7 6.8-13.7 9.5-21C388.8 214.5 409.3 224 432 224c44.2 0 80-35.8 80-80s-35.8-80-80-80c-18 0-34.6 6-48 16V42.8C398.5 35.9 414.8 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32H608c-.7-70.8-58.3-128-129.3-128H440c-8.8-11.7-18.8-22.4-29.8-31.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" *ngIf="icon === 'user-crown-light'">
    <path
        d="M238.7 4.4C234.3 1.5 229.2 0 224 0s-10.3 1.5-14.7 4.4L176.9 26.1c-5.4 3.6-12.4 3.6-17.8 0L124 2.7C121.4 .9 118.3 0 115.2 0H112c-8.8 0-16 7.2-16 16V128v16c0 70.7 57.3 128 128 128s128-57.3 128-128V128 16c0-8.8-7.2-16-16-16h-3.2c-3.2 0-6.2 .9-8.9 2.7L288.9 26.1c-5.4 3.6-12.4 3.6-17.8 0L238.7 4.4zM320 112H128V43.8l13.4 8.9c16.1 10.7 37.1 10.7 53.3 0L224 33.1l29.4 19.6c16.1 10.7 37.1 10.7 53.3 0L320 43.8V112zM128 144H320c0 53-43 96-96 96s-96-43-96-96zm40 192H280c75.1 0 136 60.9 136 136v8H32v-8c0-75.1 60.9-136 136-136zm0-32C75.2 304 0 379.2 0 472v8c0 17.7 14.3 32 32 32H416c17.7 0 32-14.3 32-32v-8c0-92.8-75.2-168-168-168H168z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 640 512" *ngIf="icon === 'user-pen-light'">
    <path
        d="M320 128a96 96 0 1 0 -192 0 96 96 0 1 0 192 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM32 480H322.2l-1 3.8c-2.4 9.5-1.8 19.3 1.4 28.2H29.7C13.3 512 0 498.7 0 482.3C0 383.8 79.8 304 178.3 304h91.4c44.2 0 84.6 16.1 115.8 42.7l-22.7 22.7c-25.3-20.9-57.7-33.4-93.1-33.4H178.3c-80 0-145 64.3-146.3 144zM582.8 264c-6.2-6.2-16.4-6.2-22.6 0l-24.8 24.8 40 40L600.2 304c6.2-6.2 6.2-16.4 0-22.6L582.8 264zM406.5 417.7c-2.1 2.1-3.5 4.6-4.2 7.4l-12.3 49 49-12.3c2.8-.7 5.4-2.2 7.4-4.2L552.7 351.4l-40-40L406.5 417.7zM537.5 241.4c18.7-18.7 49.1-18.7 67.9 0l17.4 17.4c18.7 18.7 18.7 49.1 0 67.9L469.1 480.3c-6.2 6.2-13.9 10.5-22.3 12.6l-74.9 18.7c-5.5 1.4-11.2-.2-15.2-4.2s-5.6-9.7-4.2-15.2l18.7-74.9c2.1-8.4 6.5-16.1 12.6-22.3L537.5 241.4z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512" *ngIf="icon === 'user-tie-light'">
    <path
        d="M224 32a96 96 0 1 1 0 192 96 96 0 1 1 0-192zM96 128a128 128 0 1 0 256 0A128 128 0 1 0 96 128zm96 176c-8.8 0-16 7.2-16 16s7.2 16 16 16h9.8l-21.7 65-32.1-69.5c-2.9-6.3-9.5-10-16.3-8.8C56.8 336.5 0 402.3 0 481.3c0 17 13.8 30.7 30.7 30.7H417.3c17 0 30.7-13.8 30.7-30.7c0-79-56.8-144.7-131.8-158.6c-6.8-1.3-13.4 2.5-16.3 8.8L267.9 401l-21.7-65H256c8.8 0 16-7.2 16-16s-7.2-16-16-16H224 192zm17.5 160.5l-9.7-21.1L224 370.6l24.3 72.8-9.7 21.1c-5.7 12.4-23.3 12.4-29.1 0zm-29.1 13.4c.3 .7 .7 1.4 1 2.1H32c.6-58.1 39.5-107.1 92.7-122.8l55.7 120.7zM416 480H266.6c.3-.7 .7-1.4 1-2.1l55.7-120.7c53.2 15.7 92.1 64.6 92.7 122.8z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" *ngIf="icon === 'info-circle-light'">
    <path
        d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-36 344h12V232h-12c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12h48c6.627 0 12 5.373 12 12v140h12c6.627 0 12 5.373 12 12v8c0 6.627-5.373 12-12 12h-72c-6.627 0-12-5.373-12-12v-8c0-6.627 5.373-12 12-12zm36-240c-17.673 0-32 14.327-32 32s14.327 32 32 32 32-14.327 32-32-14.327-32-32-32z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'circle-check'">
    <path
        d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" *ngIf="icon === 'hourglass-clock'">
    <path
        d="M24 0C10.7 0 0 10.7 0 24S10.7 48 24 48h8V67c0 40.3 16 79 44.5 107.5L158.1 256 76.5 337.5C48 366 32 404.7 32 445v19H24c-13.3 0-24 10.7-24 24s10.7 24 24 24H330.8c-18.3-12.9-34.1-29.2-46.3-48H80V445c0-27.6 11-54 30.5-73.5L192 289.9l64.5 64.5c1.5-19.3 6-37.7 13.2-54.7L225.9 256l81.5-81.5C336 146 352 107.3 352 67V48h8c13.3 0 24-10.7 24-24s-10.7-24-24-24H24zM192 222.1l-81.5-81.5C91 121 80 94.6 80 67V48H304V67c0 27.6-11 54-30.5 73.5L192 222.1zM576 368a144 144 0 1 0 -288 0 144 144 0 1 0 288 0zM432 288c8.8 0 16 7.2 16 16v48h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H432c-8.8 0-16-7.2-16-16V304c0-8.8 7.2-16 16-16z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'circle-minus'">
    <path
        d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM160 240c-8.8 0-16 7.2-16 16s7.2 16 16 16H352c8.8 0 16-7.2 16-16s-7.2-16-16-16H160z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'circle-pause-light'">
    <path
        d="M480 256A224 224 0 1 0 32 256a224 224 0 1 0 448 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm224-80V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16zm96 0V336c0 8.8-7.2 16-16 16s-16-7.2-16-16V176c0-8.8 7.2-16 16-16s16 7.2 16 16z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'circle-play-light'">
    <path
        d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm72.7-256L208 329.7V182.3L328.7 256zM212.5 147.5c-7.4-4.5-16.7-4.7-24.3-.5s-12.3 12.3-12.3 20.9V344c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'angle-up-light'">
    <path
        d="M212.7 148.7c6.2-6.2 16.4-6.2 22.6 0l160 160c6.2 6.2 6.2 16.4 0 22.6s-16.4 6.2-22.6 0L224 182.6 75.3 331.3c-6.2 6.2-16.4 6.2-22.6 0s-6.2-16.4 0-22.6l160-160z"/>
</svg>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" *ngIf="icon === 'angle-down-light'">
    <path
        d="M212.7 331.3c6.2 6.2 16.4 6.2 22.6 0l160-160c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4 75.3 148.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l160 160z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'message-pen-light'">
    <path
        d="M192 416c0-17.7-14.3-32-32-32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H309.3c-6.9 0-13.7 2.2-19.2 6.4L192 464V416zM64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h64 32v32 48c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM316.3 147.7c4.9 4.9 4.9 12.9 0 17.8l-8.1 8.1-17.8-17.8 8.1-8.1c4.9-4.9 12.9-4.9 17.8 0zM207.1 239.1l60.7-60.7 17.8 17.8L224.8 257c-2.1 2.1-4.6 3.5-7.4 4.2L198 266l4.9-19.5c.7-2.8 2.2-5.4 4.2-7.4zM275.8 125l-91.4 91.5c-6.1 6.2-10.5 13.9-12.6 22.3l-11.3 45.3c-1.4 5.5 .2 11.2 4.2 15.2s9.7 5.6 15.2 4.2l45.3-11.3c8.4-2.1 16.2-6.5 22.3-12.6l91.4-91.5c17.4-17.4 17.4-45.6 0-63c-17.4-17.4-45.7-17.4-63.1 0z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'message-check-light'">
    <path
        d="M192 416c0-17.7-14.3-32-32-32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H309.3c-6.9 0-13.7 2.2-19.2 6.4L192 464V416zM64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h64 32v32 48c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zM363.3 147.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 241.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'message-minus-light'">
    <path
        d="M192 416c0-17.7-14.3-32-32-32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H448c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H309.3c-6.9 0-13.7 2.2-19.2 6.4L192 464V416zM64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h64 32v32 48c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64zm96 192c-8.8 0-16 7.2-16 16s7.2 16 16 16H352c8.8 0 16-7.2 16-16s-7.2-16-16-16H160z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'inbox-light'">
    <path
        d="M32 416V327.9c0-2.6 .3-5.2 1-7.8l0-.1H134.1l23.2 46.3c5.4 10.8 16.5 17.7 28.6 17.7H326.1c12.1 0 23.2-6.8 28.6-17.7L377.9 320H479l0 .1c.6 2.5 1 5.1 1 7.8V416c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32zM471 288H377.9c-12.1 0-23.2 6.8-28.6 17.7L326.1 352H185.9l-23.2-46.3c-5.4-10.8-16.5-17.7-28.6-17.7H41L90.9 88.2C94.5 74 107.3 64 122 64H390c14.7 0 27.5 10 31 24.2L471 288zM0 327.9V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V327.9c0-5.2-.6-10.4-1.9-15.5l-58-231.9C445 52 419.4 32 390 32H122C92.6 32 67 52 59.9 80.5L1.9 312.4C.6 317.4 0 322.6 0 327.9z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'circle-check-light'">
    <path
        d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM363.3 203.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0L224 297.4l-52.7-52.7c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6l64 64c6.2 6.2 16.4 6.2 22.6 0l128-128z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512" *ngIf="icon === 'circle-exclamation-light'">
    <path
        d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zm0-384c-8.8 0-16 7.2-16 16V272c0 8.8 7.2 16 16 16s16-7.2 16-16V144c0-8.8-7.2-16-16-16zm24 224a24 24 0 1 0 -48 0 24 24 0 1 0 48 0z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'circle-info-light'">
    <path
        d="M256 32a224 224 0 1 1 0 448 224 224 0 1 1 0-448zm0 480A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM208 352c-8.8 0-16 7.2-16 16s7.2 16 16 16h96c8.8 0 16-7.2 16-16s-7.2-16-16-16H272V240c0-8.8-7.2-16-16-16H216c-8.8 0-16 7.2-16 16s7.2 16 16 16h24v96H208zm48-168a24 24 0 1 0 0-48 24 24 0 1 0 0 48z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'arrow-up-right-from-square'">
    <path
        d="M336 0c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L212.7 276.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H336zM64 32C28.7 32 0 60.7 0 96V448c0 35.3 28.7 64 64 64H416c35.3 0 64-28.7 64-64V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H64z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'thumbs-down-light'">
    <path
        d="M288.8 430.3c3.5 12.8 16.7 20.3 29.5 16.8s20.3-16.7 16.8-29.5l-4.5-16.4c-5.5-20.2-13.9-39.3-24.7-56.9c-3.1-4.9-3.2-11.1-.4-16.2s8.2-8.2 14-8.2H448c17.7 0 32-14.3 32-32c0-11.3-5.9-21.3-14.8-27c-7.2-4.6-9.5-13.9-5.3-21.3c2.6-4.6 4.1-10 4.1-15.7c0-12.4-7-23.1-17.3-28.5c-4.2-2.2-7.3-6.1-8.3-10.8s.1-9.5 3-13.2c4.2-5.4 6.7-12.2 6.7-19.5c0-14.2-9.2-26.3-22.1-30.4c-7.8-2.5-12.4-10.6-10.7-18.6c.5-2.2 .7-4.5 .7-6.9c0-17.7-14.3-32-32-32H294.5c-15.8 0-31.2 4.7-44.4 13.4l-38.5 25.7c-9 6-16.6 13.7-22.4 22.6c-4.9 7.4-14.8 9.4-22.2 4.6s-9.4-14.8-4.6-22.2c8.1-12.3 18.7-23.1 31.4-31.6l38.5-25.7c18.4-12.3 40-18.8 62.1-18.8H384c35.3 0 64 28.7 64 64l0 .6c19.1 11.1 32 31.7 32 55.4c0 8.7-1.8 17.1-4.9 24.7C487.9 188.4 496 205.2 496 224c0 6.5-1 12.8-2.8 18.7C504.8 254.3 512 270.3 512 288c0 35.3-28.7 64-64 64H346.4c6.2 13.1 11.3 26.7 15.1 40.9l4.5 16.4c8.1 29.8-9.5 60.6-39.3 68.8s-60.6-9.5-68.8-39.3l-4.5-16.4c-8.9-32.6-29.6-60.8-58.2-79l-3.1-2 8.2-12.9-8.2 12.9c-11.8-7.5-21.7-17.1-29.5-28.2c-5.1-7.2-3.3-17.2 4-22.3s17.2-3.3 22.3 4c5.4 7.7 12.2 14.4 20.4 19.5l3.1 2c35.3 22.4 60.9 57.2 71.9 97.5l4.5 16.4zM32 352H96V128H32V352zM0 352V128c0-17.7 14.3-32 32-32H96c17.7 0 32 14.3 32 32V352c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'thumbs-up-light'">
    <path
        d="M288.8 81.7c3.5-12.8 16.7-20.3 29.5-16.8s20.3 16.7 16.8 29.5l-4.5 16.4c-5.5 20.2-13.9 39.3-24.7 56.9c-3.1 4.9-3.2 11.1-.4 16.2s8.2 8.2 14 8.2H448c17.7 0 32 14.3 32 32c0 11.3-5.9 21.3-14.8 27c-7.2 4.6-9.5 13.9-5.3 21.3c2.6 4.6 4.1 10 4.1 15.7c0 12.4-7 23.1-17.3 28.5c-4.2 2.2-7.3 6.1-8.3 10.8s.1 9.5 3 13.2c4.2 5.4 6.7 12.2 6.7 19.5c0 14.2-9.2 26.3-22.1 30.4c-7.8 2.5-12.4 10.6-10.7 18.6c.5 2.2 .7 4.5 .7 6.9c0 17.7-14.3 32-32 32H294.5c-15.8 0-31.2-4.7-44.4-13.4l-38.5-25.7c-9-6-16.6-13.7-22.4-22.6c-4.9-7.4-14.8-9.4-22.2-4.6s-9.4 14.8-4.6 22.2c8.1 12.3 18.7 23.1 31.4 31.6l38.5 25.7c18.4 12.3 40 18.8 62.1 18.8H384c35.3 0 64-28.7 64-64l0-.6c19.1-11.1 32-31.7 32-55.4c0-8.7-1.8-17.1-4.9-24.7C487.9 323.6 496 306.8 496 288c0-6.5-1-12.8-2.8-18.7C504.8 257.7 512 241.7 512 224c0-35.3-28.7-64-64-64H346.4c6.2-13.1 11.3-26.7 15.1-40.9l4.5-16.4c8.1-29.8-9.5-60.6-39.3-68.8s-60.6 9.5-68.8 39.3l-4.5 16.4c-8.9 32.6-29.6 60.8-58.2 79l-3.1 2c-11.8 7.5-21.7 17.1-29.5 28.2c-5.1 7.2-3.3 17.2 4 22.3s17.2 3.3 22.3-4c5.4-7.7 12.2-14.4 20.4-19.5l3.1-2c35.3-22.4 60.9-57.2 71.9-97.5l4.5-16.4zM32 224H96V448H32V224zM0 224V448c0 17.7 14.3 32 32 32H96c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32H32c-17.7 0-32 14.3-32 32z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" *ngIf="icon === 'chevron-double-left-light'">
    <path
        d="M349.5 475.5l-211.1-211c-4.7-4.7-4.7-12.3 0-17l211.1-211c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L178.1 256l195.5 195.5c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.6-12.3 4.6-17-.1zm-111 0l7.1-7.1c4.7-4.7 4.7-12.3 0-17L50.1 256 245.5 60.5c4.7-4.7 4.7-12.3 0-17l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0l-211.1 211c-4.7 4.7-4.7 12.3 0 17l211.1 211c4.8 4.8 12.4 4.8 17.1.1z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512" *ngIf="icon === 'chevron-left-light'">
    <path
        d="M238.475 475.535l7.071-7.07c4.686-4.686 4.686-12.284 0-16.971L50.053 256 245.546 60.506c4.686-4.686 4.686-12.284 0-16.971l-7.071-7.07c-4.686-4.686-12.284-4.686-16.97 0L10.454 247.515c-4.686 4.686-4.686 12.284 0 16.971l211.051 211.05c4.686 4.686 12.284 4.686 16.97-.001z"></path>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" *ngIf="icon === 'chevron-double-right-light'">
    <path
        d="M34.5 36.5l211.1 211c4.7 4.7 4.7 12.3 0 17l-211.1 211c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L205.9 256 10.5 60.5c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.6-4.6 12.2-4.6 16.9.1zm111 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17L333.9 256 138.5 451.5c-4.7 4.7-4.7 12.3 0 17l7.1 7.1c4.7 4.7 12.3 4.7 17 0l211.1-211c4.7-4.7 4.7-12.3 0-17l-211.1-211c-4.8-4.8-12.4-4.8-17.1-.1z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" *ngIf="icon === 'file-code'">
    <path
        d="M320 480H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32H192V144c0 26.5 21.5 48 48 48H352V448c0 17.7-14.3 32-32 32zM240 160c-8.8 0-16-7.2-16-16V32.5c2.8 .7 5.4 2.1 7.4 4.2L347.3 152.6c2.1 2.1 3.5 4.6 4.2 7.4H240zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V163.9c0-12.7-5.1-24.9-14.1-33.9L254.1 14.1c-9-9-21.2-14.1-33.9-14.1H64zm91.3 299.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-48 48c-6.2 6.2-6.2 16.4 0 22.6l48 48c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L118.6 336l36.7-36.7zm144 25.4l-48-48c-6.2-6.2-16.4-6.2-22.6 0s-6.2 16.4 0 22.6L265.4 336l-36.7 36.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l48-48c6.2-6.2 6.2-16.4 0-22.6z"/>
</svg>
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" *ngIf="icon === 'repeat-light'">
    <path
        d="M512 256c0 88.225-71.775 160-160 160H110.628l68.201 68.201c4.686 4.686 4.686 12.284 0 16.971l-5.656 5.656c-4.686 4.686-12.284 4.686-16.971 0l-98.343-98.343c-4.686-4.686-4.686-12.284 0-16.971l98.343-98.343c4.686-4.686 12.284-4.686 16.971 0l5.656 5.656c4.686 4.686 4.686 12.284 0 16.971L110.628 384H352c70.579 0 128-57.421 128-128 0-28.555-9.403-54.952-25.271-76.268-3.567-4.792-3.118-11.462 1.106-15.686l5.705-5.705c5.16-5.16 13.678-4.547 18.083 1.271C499.935 186.438 512 219.835 512 256zM57.271 332.268C41.403 310.952 32 284.555 32 256c0-70.579 57.421-128 128-128h241.372l-68.201 68.201c-4.686 4.686-4.686 12.284 0 16.971l5.656 5.656c4.686 4.686 12.284 4.686 16.971 0l98.343-98.343c4.686-4.686 4.686-12.284 0-16.971L355.799 5.172c-4.686-4.686-12.284-4.686-16.971 0l-5.656 5.656c-4.686 4.686-4.686 12.284 0 16.971L401.372 96H160C71.775 96 0 167.775 0 256c0 36.165 12.065 69.562 32.376 96.387 4.405 5.818 12.923 6.432 18.083 1.271l5.705-5.705c4.225-4.224 4.674-10.893 1.107-15.685z"/>
</svg>
<svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 515 505" *ngIf="icon === 'imported-question'">
    <path d="m 151.33203,0.0234375 c -27.65703,0 -50.14258,22.4855455 -50.14258,50.1425785 V 200.5957 h 25.07032 V 50.166016 c 0,-13.867692 11.20457,-25.072266 25.07226,-25.072266 h 100.28516 v 87.75 c 0,20.76237 16.84506,37.60742 37.60742,37.60742 h 87.75195 v 85.41992 a 143.40781,143.40781 0 0 1 11.01563,-0.96484 143.40781,143.40781 0 0 1 14.05469,0.74414 V 128.43555 c 0,-9.95026 -3.99552,-19.50918 -11.04688,-26.56055 L 300.27148,11.070312 C 293.22012,4.0189433 283.66316,0.0234375 273.71289,0.0234375 Z M 276.68945,25.486328 c 2.19376,0.54844 4.2299,1.645697 5.79688,3.291016 l 90.80664,90.804686 c 1.64532,1.64532 2.74258,3.60507 3.29101,5.79883 h -87.35937 c -6.89467,0 -12.53516,-5.64244 -12.53516,-12.53711 z M 214.00977,163.02734 c -3.21229,0 -6.42472,1.21378 -8.85352,3.64258 -4.85762,4.85762 -4.85762,12.84942 0,17.70703 l 53.82617,53.82617 H 13.4375 c -6.8946714,0 -12.53515625,5.64048 -12.53515625,12.53516 0,6.89466 5.64048485,12.53516 12.53515625,12.53516 h 245.54492 l -53.82617,53.82617 c -4.85762,4.85761 -4.85762,12.84943 0,17.70703 4.85761,4.85762 12.84943,4.85762 17.70703,0 l 75.21484,-75.21484 c 4.8576,-4.85762 4.8576,-12.84942 0,-17.70703 l -75.21484,-75.21485 c -2.4288,-2.4288 -5.64123,-3.64258 -8.85351,-3.64258 z M 101.18945,288.3457 v 62.67774 c 0,27.65703 22.48555,50.14453 50.14258,50.14453 h 95.25195 a 143.40781,143.40781 0 0 1 -1.99804,-22.85352 143.40781,143.40781 0 0 1 0.19336,-2.21875 h -93.44727 c -13.86769,0 -25.07226,-11.20458 -25.07226,-25.07226 V 288.3457 Z" />
    <path d="m 460.23544,350.12657 c 3.73348,-3.73346 3.73348,-9.87564 0,-13.60911 -3.73349,-3.73347 -9.87563,-3.73347 -13.60911,0 l -70.27361,70.27361 -31.73454,-31.73452 c -3.73348,-3.73348 -9.87564,-3.73348 -13.6091,0 -3.73349,3.73347 -3.73349,9.87564 0,13.60911 l 38.53906,38.53908 c 3.73348,3.73347 9.87565,3.73347 13.60913,0 z"/>
    <circle
    style="fill:none;fill-opacity:1;stroke-width:22;stroke-linecap:round;stroke-linejoin:round;stroke-dasharray:none;stroke-opacity:1;paint-order:markers stroke fill" cx="388.87268" cy="378.315" r="114.22319" />
</svg>
