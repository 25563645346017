<app-popup-container>
    <app-popup-wizard-header header="Wähle eine CSV-Datei" i18n-header></app-popup-wizard-header>

    <app-popup-content>
        <div class="mm-wizard-page-container">

            <app-drag-and-drop class="mm-wizard-page-drag-and-drop"
                               [class.mm-wizard-page-drag-and-drop-small]="invalidFile"
                               acceptFileType=".csv"
                               (files)="sendFile($event)"></app-drag-and-drop>

            <app-error-box *ngIf="invalidFile"
                           class="mm-wizard-page-error-box"
                           i18n-header
                           [header]="questionsValidationErrors === undefined ?  'Bitte korrigiere die erkannten Fehler und lade die CSV-Datei erneut hoch:' : undefined">
                <ul class="micromate-error-box-message-list">
                    <li *ngIf="invalidFileType" class="micromate-error-box-message-list-element" i18n>
                        Falscher Dateityp, nur CSV-Typen zulässig
                    </li>
                    <li *ngIf="invalidFileToManyFiles" class="micromate-error-box-message-list-element" i18n>
                        Mehrere Dateien sind nicht zulässig. Es muss genau eine Datei ausgewählt werden.
                    </li>
                    <li *ngIf="importFileFormatValidationError !== undefined && importFileFormatValidationError.successFactoryFormatError"
                        class="micromate-error-box-message-list-element" i18n>
                        SuccessFactors CSV-Format ist nicht gültig
                    </li>
                    <ng-container
                        *ngIf="importFileFormatValidationError !== undefined && !importFileFormatValidationError.successFactoryFormatError">
                        <li *ngIf="importFileFormatValidationError.noQuestion"
                            class="micromate-error-box-message-list-element" i18n>Es muss mindestens eine Frage
                            vorhanden
                            sein
                        </li>
                        <li *ngIf="importFileFormatValidationError.missingRequiredHeaders.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Die folgenden erforderlichen Spalten müssen angegeben
                            werden:  {{importFileFormatValidationError.missingRequiredHeaders.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.additionalHeaders.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Die folgenden Spalten müssen entfernt
                            werden: {{importFileFormatValidationError.additionalHeaders.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.wrongNumberOfCellsInRow.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Falsche Anzahl an Spalten. Die folgenden Zeilen sind
                            betroffen: {{importFileFormatValidationError.wrongNumberOfCellsInRow.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.emptyHeaders"
                            class="micromate-error-box-message-list-element" i18n>
                            Leere Spaltenüberschriften müssen entfernt werden
                        </li>
                        <li *ngIf="importFileFormatValidationError.languageWithWrongFormat.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Folgende Sprachangaben konnten nicht zugeordnet werden und müssen in einem gültigen Format
                            angegeben werden:  {{importFileFormatValidationError.languageWithWrongFormat.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.languageDifferentThanTranslations.length> 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Einige Fragen sind den folgenden Sprachen zugeordnet, für welche keine Übersetzung des
                            Lernpaketes
                            existiert: {{importFileFormatValidationError.languageDifferentThanTranslations.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.missingQuestionNumberHeaderWhenLanguageSet"
                            class="micromate-error-box-message-list-element" i18n>
                            Da die Spalte “Sprache” vorhanden ist, muss die Spalte “Fragenummer” ebenfalls zwingend
                            definiert werden
                        </li>
                        <li *ngIf="importFileFormatValidationError.wrongQuizType.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Die Spalte “Fragetyp” muss einen gültigen Wert enthalten. Die folgenden Zeilen sind
                            betroffen: {{importFileFormatValidationError.wrongQuizType.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.wrongQuestionMediaType.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Die Spalte “Fragemedia Typ” muss einen gültigen Wert enthalten. Die folgenden Zeilen sind
                            betroffen:   {{importFileFormatValidationError.wrongQuestionMediaType.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.wrongExplanationMediaType.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Die Spalte “Erklaerungsmedia Typ” muss einen gültigen Wert enthalten. Die folgenden Zeilen
                            sind
                            betroffen: {{importFileFormatValidationError.wrongExplanationMediaType.join(', ')}}
                        </li>
                        <ng-container
                            *ngFor="let item of getObjectEntries(importFileFormatValidationError.wrongAnswerCorrectnessValue)">
                            <li *ngIf="item.value.length > 0 "
                                class="micromate-error-box-message-list-element" i18n>
                                Die Spalte “{{item.key}}” muss einen gültigen Wert enthalten. Die folgenden Zeilen sind
                                betroffen: {{item.value.join(', ')}}
                            </li>
                        </ng-container>

                        <li *ngIf="importFileFormatValidationError.missingLanguage.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Die Spalte “Sprache“ muss zwingend definiert sein. leere Einträge. Die folgenden Zeilen sind
                            betroffen: {{importFileFormatValidationError.missingLanguage.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.missingQuestionNumberWhenLanguage.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Die Spalte “Fragenummer“ muss zwingend definiert sein. leere Einträge. Die folgenden Zeilen
                            sind
                            betroffen: {{importFileFormatValidationError.missingQuestionNumberWhenLanguage.join(', ')}}
                        </li>
                        <li *ngIf="importFileFormatValidationError.questionNumberNotUniqueForLanguage.length > 0"
                            class="micromate-error-box-message-list-element" i18n>
                            Jede Frage darf pro Sprache nur einmalig vorhanden sein. Die folgenden Zeilen sind
                            betroffen: {{importFileFormatValidationError.questionNumberNotUniqueForLanguage.join(', ')}}
                        </li>
                    </ng-container>
                </ul>
                <ng-container *ngIf="questionsValidationErrors !== undefined">
                    <div class="micromate-error-box-content-elements">
                        <div class="micromate-error-box-message" i18n>Es wurden <strong>{{numberOfQuestions}}
                            Fragen</strong> eingelesen. Davon sind <strong>{{numberOfValidQuestions}} im korrekten
                            Format</strong>. Korrigiere die folgenden <strong>{{numberOfInvalidQuestions}} fehlerhaften
                            Fragen</strong> und wiederhole den Import.
                        </div>
                        <app-question-validation-errors
                            class="micromate-error-box-question-validation-errors"
                            [activeInvalidQuestion]="invalidQuestions[activeInvalidQuestion]">
                        </app-question-validation-errors>
                        <div class="micromate-error-box-question-validation-errors-buttons"
                             [class.micromate-error-box-question-validation-error-only-next-button]="activeInvalidQuestion === 0"
                             *ngIf="invalidQuestions.length > 1">
                            <app-icon-button (click)="showPreviousQuestion()" type="secondary-dark"
                                             icon="chevron-left-light"
                                             [class.question-validation-error-icon-button-hide]="activeInvalidQuestion === 0"></app-icon-button>
                            <app-icon-button (click)="showNextQuestion()" type="secondary-dark"
                                             icon="chevron-right-light"
                                             [class.question-validation-error-icon-button-hide]="activeInvalidQuestion === (invalidQuestions.length - 1)"></app-icon-button>
                        </div>
                    </div>
                </ng-container>
            </app-error-box>
        </div>

    </app-popup-content>
</app-popup-container>
