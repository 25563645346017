import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavigationComponent} from './navigation/navigation.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HomeComponent} from './home/home.component';
import {LearnerOverviewComponent} from './app-administration/learner-overview/learner-overview.component';
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeCh from '@angular/common/locales/de-CH';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {StyleguideComponent} from './styleguide/styleguide.component';
import {AppNavigationComponent} from './navigation/app-navigation/app-navigation.component';
import {OrganizationComponent} from './app-administration/organization/organization.component';
import {OrganizationFormComponent} from './app-administration/organization/organization-form/organization-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OrganizationSettingsComponent} from './app-organisation/organization-settings/organization-settings.component';
import {LearningGroupsOverviewComponent} from './app-learncenter/learning-groups-overview/learning-groups-overview.component';
import {GroupOverviewComponent} from './app-learncenter/group-overview/group-overview.component';
import {AppPageNavigationComponent} from './navigation/app-page-navigation/app-page-navigation.component';
import {AnalyticsGroupOverviewComponent} from './app-analytics/analytics-group-overview/analytics-group-overview.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {AnalyticsGroupDetailComponent} from './app-analytics/analytics-group-detail/analytics-group-detail.component';
import {ChartsModule} from 'ng2-charts';
import {LearningPackageOverviewComponent} from './app-administration/learn-package-overview/learning-package-overview.component';
import {LearningPackageOrganizationConnectionComponent} from './app-administration/learn-package-overview/learning-package-organization-connection/learning-package-organization-connection.component';
import {GroupLearningPackageOverviewComponent} from './app-learncenter/group-learnpackage-overview/group-learning-package-overview.component';
import {LearningPackageActivateComponentComponent} from './app-learncenter/group-learnpackage-overview/learning-package-activate-component/learning-package-activate-component.component';
import {GroupLearnersOverviewComponent} from './app-learncenter/group-learners-overview/group-learners-overview.component';
import {LearningPackagesComponent} from './app-editor/learnpackages-overview/learning-packages.component';
import {EditorNavigationComponent} from './navigation/editor-navigation/editor-navigation.component';
import {LearningPackageSettingsComponent} from './app-editor/learnpackage-settings/learning-package-settings.component';
import {LearningPackageVersionsComponent} from './app-editor/learning-package-versions/learning-package-versions.component';
import {LearningPackagesQuizzesComponent} from './app-editor/learnpackages-quizzes/learning-packages-quizzes.component';
import {QuizEditionComponent} from './app-editor/learnpackages-quizzes/quiz-edition/quiz-edition.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AnalyticsDataComponent} from './components/analitysc-data/analytics-data.component';
import {QuizzesListOverviewComponent} from './app-editor/learnpackages-quizzes/quizzes-list-overview/quizzes-list-overview.component';
import {NewLearningPackagePopupComponent} from './app-editor/learnpackages-overview/new-learning-package-popup/new-learning-package-popup.component';
import {DeactivateQuizEditorGuard} from './core/deactivate-quiz-editor-guard';
import {NewLearningGroupPopupComponent} from './app-learncenter/learning-groups-overview/new-learning-group-popup/new-learning-group-popup.component';
import {AddLearnerToGroupPopupComponent} from './app-learncenter/group-learners-overview/add-learner-to-group-popup/add-learner-to-group-popup.component';
import {MultipleChoiceTextQuizComponent} from './app-editor/quiz-makers/standard-question/multiple-choice-text-quiz/multiple-choice-text-quiz.component';
import {AutosizeModule} from 'ngx-autosize';
import {SingleChoiceTextQuizComponent} from './app-editor/quiz-makers/standard-question/single-choice-text-quiz/single-choice-text-quiz.component';
import {ToastrModule} from 'ngx-toastr';
import {FreeTextQuizComponent} from './app-editor/quiz-makers/standard-question/free-text-quiz/free-text-quiz.component';
import {TrueFalseQuizComponent} from './app-editor/quiz-makers/standard-question/true-false-quiz/true-false-quiz.component';
import {DragAndDropDirective} from './core/drag-and-drop/drag-and-drop.directive';
import {environment} from '../environments/environment';
import {OrganizationSettingComponent} from './app-administration/organization-setting/organization-setting.component';
import {GroupLearningPackageSettingComponent} from './app-learncenter/group-learning-package-setting/group-learning-package-setting.component';
import {LearningGoalsSliderComponent} from './components/learning-goals-slider/learning-goals-slider.component';
import {GroupLearningPackageSettingsGuard} from './core/group-learning-package-settings-guard';
import {AnalyticsGroupLearningGoalsComponent} from './app-analytics/analytics-group-learning-goals/analytics-group-learning-goals.component';
import {RemoveOrganizationEventsPopupComponent} from './app-administration/organization-setting/remove-organization-events/remove-organization-events-popup.component';
import {GroupSettingComponent} from './app-learncenter/group-setting/group-setting.component';
import {GroupSettingsGuard} from './core/group-settings-guard';
import {LearningPackageSettingsGuard} from './core/learning-package-settings-guard';
import {EditorLanguageSwitcherComponent} from './components/editor-language-switcher/editor-language-switcher.component';
import {TableSearchComponent} from './components/table-search/table-search.component';
import {TablePaginationComponent} from './components/table-pagination/table-pagination.component';
import {OrganizationSettingsGuard} from './core/organization-settings-guard';
import {OrganisationLearnersOverviewComponent} from './app-organisation/organisation-learners-overview/organisation-learners-overview.component';
import {OrganizationAddLearnerFormComponent} from './app-organisation/organisation-learners-overview/organization-add-learner-form/organization-add-learner-form.component';
import {HelpQuizComponent} from './app-editor/learnpackages-quizzes/popup/help-quiz/help-quiz.component';
import {ApiHistoryComponent} from './app-administration/public-api/api-history/api-history.component';
import {ApiInfoComponent} from './app-administration/public-api/api-info/api-info.component';
import {GlobalErrorHandler} from './core/globalErrorHandler/globalErrorHandler';
import {AnalyticsOverviewComponent} from './app-analytics/analytics-overview/analytics-overview.component';
import {ImageAndMediaPopupComponent} from './app-editor/image-and-media-popup/image-and-media-popup.component';
import {LearnerInfoComponent} from './app-administration/learner-info/learner-info.component';
import {EditorDropDownComponent} from './components/editor-drop-down/editor-drop-down.component';
import {QuestionAnalyticsComponent} from './app-editor/learnpackages-quizzes/popup/question-analytics/question-analytics.component';
import {AnalyticsComponent} from './app-administration/analytics/analytics.component';
import {IframeMediaComponent} from './app-editor/image-and-media-popup/iframe-media/iframe-media.component';
import {SafePipe} from './safe.pipe';
import {ReflectionFreeTextQuestionComponent} from './app-editor/quiz-makers/reflection-question/reflection-free-text-question/reflection-free-text-question.component';
import {ReportsComponent} from './app-administration/reports/reports.component';
import {CardComponent} from './components/card/card.component';
import {LearnPackageImportComponent} from './app-editor/learnpackage-import/learn-package-import.component';
import {WizardPageFooterComponent} from './app-editor/learnpackage-import/import-wizards/components/wizard-page-footer/wizard-page-footer.component';
import {ResourcesComponent} from './app-editor/resources/resources.component';
import {ButtonComponent} from './components/button/button.component';
import {IconButtonComponent} from './components/icon-button/icon-button.component';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {UnstableInternetConnectionHttpInterceptor} from './unstable-internet-connection-http-interceptor.service';
import {UnauthorizedHttpInterceptor} from './unauthorized-http-interceptor.service';
import {SwitchToggleComponent} from './components/switch-toggle/switch-toggle.component';
import {ImportWizardComponent} from './app-editor/learnpackage-import/import-wizards/import-wizard.component';
import {QuestionSummaryPageComponent} from './app-editor/learnpackage-import/import-wizards/question-summary-page/question-summary-page.component';
import {CsvFileWizardImportPageComponent} from './app-editor/learnpackage-import/import-wizards/import-questions-page/csv-file-wizard-import-page/csv-file-wizard-import-page.component';
import {QuestionValidationErrorsComponent} from './app-editor/learnpackage-import/import-wizards/import-questions-page/csv-file-wizard-import-page/question-validation-errors-list/question-validation-errors.component';
import {ImportQuestionsComponent} from './app-editor/learnpackage-import/import-wizards/import-questions-page/import-questions.component';
import {RessourceWizardImportComponent} from './app-editor/learnpackage-import/import-wizards/import-questions-page/ressource-wizard-import/ressource-wizard-import.component';
import {InfoBoxComponent} from './components/info-box/info-box.component';
import {QuestionPreviewPageComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview-page.component';
import {SingleChoiceQuestionComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview/question-type-preview/standard-question/single-choice-question/single-choice-question.component';
import {ExplanationQuestionPreviewComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview/components/explanation-question-preview/explanation-question-preview.component';
import {FreeTextQuestionComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview/question-type-preview/standard-question/free-text-question/free-text-question.component';
import {TrueFalseQuestionComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview/question-type-preview/standard-question/treu-false-question/true-false-question.component';
import {MediaPreviewComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview/components/question-media-preview/media-preview.component';
import {MarkdownPipe} from './components/pipes/markdown.pipe';
import {LearningPackageWizardImportComponent} from './app-editor/learnpackage-import/import-wizards/import-questions-page/learning-package-wizard-import/learning-package-wizard-import.component';
import {NewResourcePopupComponent} from './app-editor/resources/new-resource-popup/new-resource-popup.component';
import {PopupComponent} from './components/popup/popup.component';
import {ImportNewResourcePopupPageComponent} from './app-editor/resources/new-resource-popup/import-new-resource-popup/import-new-resource-popup-page.component';
import {PopupWizardContainerComponent} from './components/popup-wizard/popup-wizard-container/popup-wizard-container.component';
import {DragAndDropComponent} from './components/drag-and-drop/drag-and-drop.component';
import {NewResourceSettingsPopupPageComponent} from './app-editor/resources/new-resource-popup/settings-new-resource/new-resource-settings-popup-page.component';
import {ErrorBoxComponent} from './components/error-box/error-box.component';
import {PopupWizardFooterComponent} from './components/popup-wizard/popup-wizard-footer/popup-wizard-footer.component';
import {ResultNewResourcePopupPageComponent} from './app-editor/resources/new-resource-popup/result-new-resource/result-new-resource-popup-page.component';
import {DropdownButtonComponent} from './components/dropdown-button/dropdown-button.component';
import {ConfirmationPopupComponent} from './components/confirmation-popup/confirmation-popup.component';
import {DropdownButtonOptionComponent} from './components/dropdown-button/dropdown-button-option/dropdown-button-option.component';
import {OverlayComponent} from './components/overlay/overlay.component';
import {OverlayChildComponent} from './components/overlay/overlay-child/overlay-child.component';
import {MicromateOAuthStorage} from './core/authentication/MicromateOAuthStorage';
import {DeleteLearningPackageErrorPopupComponent} from './app-editor/learnpackages-overview/delete-learning-package-popup/delete-learning-package-error-popup.component';
import {MicromateEditorImageDropDownComponent} from './app-editor/quiz-makers/components/micromate-editor-image-drop-down/micromate-editor-image-drop-down.component';
import {MediaTypeIconComponent} from './app-editor/quiz-makers/components/media-type-icon/media-type-icon.component';
import {AuthErrorComponent} from './core/authentication/components/auth-error/auth-error.component';
import {CodeExchangeComponent} from './core/authentication/components/code-exchange/code-exchange.component';
import {AuthService} from './core/authentication/auth.service';
import {AuthConfig, OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {PasteTextPopupComponent} from './app-editor/paste-text-popup/paste-text-popup.component';
import {IconComponent} from './components/icon/icon.component';
import {TagsDropdownComponent} from './app-editor/quiz-makers/components/tags-selector/tags-dropdown/tags-dropdown.component';
import {TagsSelectorComponent} from './app-editor/quiz-makers/components/tags-selector/tags-selector.component';
import {TagListComponent} from './app-editor/quiz-makers/components/tags-question/tag-list.component';
import {LearningPackageTagsComponent} from './app-editor/learning-package-tags/learning-package-tags.component';
import {NewTagPopupComponent} from './app-editor/learning-package-tags/new-tag-popup/new-tag-popup.component';
import {EditTagPopupComponent} from './app-editor/learning-package-tags/edit-tag-popup/edit-tag-popup.component';
import {DeleteTagPopupComponent} from './app-editor/learning-package-tags/delete-tag/delete-tag-popup.component';
import {TagActivationComponent} from './components/tag-activation/tag-activation.component';
import {WarningBoxComponent} from './components/warning-box/warning-box.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {TextareaComponent} from './components/textarea-autoresize/textarea.component';
import {CancelSavingPopupComponent} from './components/cancel-saving-popup/cancel-saving-popup.component';
import {OrganizationAdminSettingsGuard} from './core/organization-admin-settings-guard';
import {LogosComponent} from './components/colorful-icon/logos.component';
import {OrganizationManagerComponent} from './app-organisation/organization-manager/organization-manager.component';
import {OrganizationAdminManagersGuard} from './core/organization-admin-managers-guard';
import {OrganizationLearnerInfoComponent} from './app-organisation/organization-learner-info/organization-learner-info.component';
import {MissingRoleComponent} from './core/authentication/components/missing-role/missing-role.component';
import {PopupHeaderComponent} from './components/popup/components/popup-header/popup-header.component';
import {PopupHeaderTitleComponent} from './components/popup/components/popup-header-title/popup-header-title.component';
import {PopupFooterComponent} from './components/popup/components/popup-footer/popup-footer.component';
import {PopupContentComponent} from './components/popup/components/popup-content/popup-content.component';
import {PopupContainerComponent} from './components/popup/components/popup-container/popup-container.component';
import {PopupFooterButtonsComponent} from './components/popup/components/popup-footer-buttons/popup-footer-buttons.component';
import {PopupWizardHeaderComponent} from './components/popup-wizard/popup-wizard-header/popup-wizard-header.component';
import {SourceQuestionPreviewComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview/components/source-question-preview/source-question-preview.component';
import {ExpansionPanelComponent} from './components/expansion-panel/expansion-panel.component';
import {AdvancedInfoComponent} from './app-editor/quiz-makers/components/advanced-info/advanced-info.component';
import {FeedbackPreviewComponent} from './app-editor/feedback-preview/feedback-preview.component';
import {FeedbackQuestionComponent} from './app-editor/quiz-makers/components/feedback-question/feedback-question.component';
import {QuestionPreviewComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview/question-preview.component';
import {MultipleChoiceQuestionComponent} from './app-editor/learnpackage-import/import-wizards/question-preview/question-preview/question-type-preview/standard-question/multiple-choice-questio/multiple-choice-question.component';
import {InboxComponent} from './app-editor/inbox/inbox.component';
import {TextOverflowEllipsisComponent} from './components/text/text-overflow-ellipsis.component';
import {MessageBoxComponent} from './components/message-box/message-box.component';
import {InboxFeedbackPopupComponent} from './app-editor/inbox/inbox-feedback-popup/inbox-feedback-popup.component';
import {PaginationComponent} from './components/pagination/pagination.component';
import {FilterButtonComponent} from './components/filter-button/filter-button.component';
import {RenameOrganizationComponent} from './app-administration/organization-setting/rename-organization/rename-organization.component';
import {ValidationHttpInterceptor} from './validation-http-interceptor.service';
import { PopupActionButtonComponent } from './components/popup/components/popup-action-button/popup-action-button.component';
import { SearchInputComponent } from './components/search-input/search-input.component';
import { ImportTagComponent } from './app-editor/learnpackage-import/import-wizards/import-tag/import-tag.component';
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
registerLocaleData(localeDe);

const authConfig: AuthConfig = {
    scope: `openid profile email offline_access urn:zitadel:iam:user:metadata urn:zitadel:iam:org:domain:primary:micromate.ai urn:zitadel:iam:org:project:id:${environment.zitadelProjectId}:aud`,
    responseType: 'code',
    oidc: true,
    clientId: environment.clientid,
    issuer: 'https://login.micromate.ai',
    redirectUri: environment.iamCodeExchangeUrl,
    postLogoutRedirectUri: environment.iamLogoutUrl,
    userinfoEndpoint: 'https://login.micromate.ai/oidc/v1/userinfo',
    requireHttps: false,
    timeoutFactor: 0.5
};

@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        HomeComponent,
        LearnerOverviewComponent,
        BreadcrumbComponent,
        StyleguideComponent,
        AppNavigationComponent,
        SpinnerComponent,
        OrganizationComponent,
        OrganizationFormComponent,
        OrganizationSettingsComponent,
        LearningGroupsOverviewComponent,
        GroupOverviewComponent,
        AppPageNavigationComponent,
        AnalyticsGroupOverviewComponent,
        AnalyticsGroupDetailComponent,
        LearningPackageOverviewComponent,
        LearningPackageOrganizationConnectionComponent,
        GroupLearningPackageOverviewComponent,
        LearningPackageActivateComponentComponent,
        GroupLearnersOverviewComponent,
        LearningPackagesComponent,
        EditorNavigationComponent,
        LearningPackageSettingsComponent,
        LearningPackageVersionsComponent,
        LearningPackagesQuizzesComponent,
        QuizEditionComponent,
        AnalyticsDataComponent,
        QuizzesListOverviewComponent,
        NewLearningPackagePopupComponent,
        NewLearningGroupPopupComponent,
        AddLearnerToGroupPopupComponent,
        MultipleChoiceTextQuizComponent,
        SingleChoiceTextQuizComponent,
        FreeTextQuizComponent,
        TrueFalseQuizComponent,
        DragAndDropDirective,
        OrganizationSettingComponent,
        GroupLearningPackageSettingComponent,
        LearningGoalsSliderComponent,
        AnalyticsGroupLearningGoalsComponent,
        RemoveOrganizationEventsPopupComponent,
        GroupSettingComponent,
        EditorLanguageSwitcherComponent,
        LearnPackageImportComponent,
        TableSearchComponent,
        TablePaginationComponent,
        OrganisationLearnersOverviewComponent,
        OrganizationAddLearnerFormComponent,
        HelpQuizComponent,
        ApiHistoryComponent,
        ApiInfoComponent,
        AnalyticsOverviewComponent,
        ImageAndMediaPopupComponent,
        LearnerInfoComponent,
        EditorDropDownComponent,
        QuestionAnalyticsComponent,
        AnalyticsComponent,
        IframeMediaComponent,
        SafePipe,
        ReflectionFreeTextQuestionComponent,
        ReportsComponent,
        CardComponent,
        WizardPageFooterComponent,
        ReportsComponent,
        ResourcesComponent,
        ButtonComponent,
        IconButtonComponent,
        SwitchToggleComponent,
        CsvFileWizardImportPageComponent,
        QuestionValidationErrorsComponent,
        ImportWizardComponent,
        ImportQuestionsComponent,
        QuestionSummaryPageComponent,
        InfoBoxComponent,
        RessourceWizardImportComponent,
        QuestionPreviewPageComponent,
        SingleChoiceQuestionComponent,
        ExplanationQuestionPreviewComponent,
        FreeTextQuestionComponent,
        TrueFalseQuestionComponent,
        AuthErrorComponent,
        CodeExchangeComponent,
        MediaPreviewComponent,
        MarkdownPipe,
        LearningPackageWizardImportComponent,
        NewResourcePopupComponent,
        PopupComponent,
        ImportNewResourcePopupPageComponent,
        PopupWizardContainerComponent,
        DragAndDropComponent,
        NewResourceSettingsPopupPageComponent,
        ErrorBoxComponent,
        PopupWizardFooterComponent,
        ResultNewResourcePopupPageComponent,
        DropdownButtonComponent,
        ConfirmationPopupComponent,
        DropdownButtonOptionComponent,
        OverlayComponent,
        OverlayChildComponent,
        DeleteLearningPackageErrorPopupComponent,
        MicromateEditorImageDropDownComponent,
        MediaTypeIconComponent,
        PasteTextPopupComponent,
        IconComponent,
        TagsDropdownComponent,
        TagsSelectorComponent,
        TagListComponent,
        LearningPackageTagsComponent,
        NewTagPopupComponent,
        EditTagPopupComponent,
        DeleteTagPopupComponent,
        TagActivationComponent,
        WarningBoxComponent,
        TextareaComponent,
        CancelSavingPopupComponent,
        LogosComponent,
        OrganizationManagerComponent,
        OrganizationLearnerInfoComponent,
        MissingRoleComponent,
        PopupHeaderComponent,
        PopupHeaderTitleComponent,
        PopupFooterComponent,
        PopupContentComponent,
        PopupContainerComponent,
        PopupFooterButtonsComponent,
        PopupWizardHeaderComponent,
        SourceQuestionPreviewComponent,
        PopupWizardHeaderComponent,
        ExpansionPanelComponent,
        AdvancedInfoComponent,
        FeedbackPreviewComponent,
        FeedbackQuestionComponent,
        QuestionPreviewComponent,
        MultipleChoiceQuestionComponent,
        InboxComponent,
        TextOverflowEllipsisComponent,
        MessageBoxComponent,
        InboxFeedbackPopupComponent,
        PaginationComponent,
        FilterButtonComponent,
        RenameOrganizationComponent,
        PopupActionButtonComponent,
        SearchInputComponent,
        ImportTagComponent
    ],
    imports: [
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [environment.serverUrl],
                sendAccessToken: true,
            },
        }),
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        DragDropModule,
        ChartsModule,
        BrowserAnimationsModule,
        AutosizeModule,
        NgMultiSelectDropDownModule.forRoot(),
        ToastrModule.forRoot({
            maxOpened: 3,
            timeOut: 3000,
            positionClass: 'toast-top-center',
            preventDuplicates: true
        }),
        FormsModule
    ],
    providers: [
        DeactivateQuizEditorGuard,
        GroupLearningPackageSettingsGuard,
        GroupSettingsGuard,
        OrganizationSettingsGuard,
        LearningPackageSettingsGuard,
        OrganizationAdminSettingsGuard,
        OrganizationAdminManagersGuard,
        {
            provide: APP_INITIALIZER,
            useFactory: AuthService.factory,
            deps: [AuthService],
            multi: true
        },
        {
            provide: AuthConfig,
            useValue: authConfig,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnstableInternetConnectionHttpInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ValidationHttpInterceptor,
            multi: true
        },
        {provide: OAuthStorage, useClass: MicromateOAuthStorage},
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        registerLocaleData(localeDeCh, localeDe);
    }
}
