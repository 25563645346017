<app-popup-container>
    <app-popup-wizard-header header="Hinzufügen fehlgeschlagen" i18n-header></app-popup-wizard-header>

    <app-popup-content>
        <app-error-box class="mm-result-new-resource-wizard-page-error-box"
                       *ngIf="errors !== undefined && name !== undefined">
            <div class="mm-result-new-resource-wizard-page-upload-errors">
                <div class="mm-result-new-resource-wizard-page-upload-error-title" i18n>
                    Die Ressource ”{{name}}” konnte nicht hinzugefügt werden
                </div>
                <ul class="mm-result-new-resource-wizard-page-upload-error-list">
                    <li *ngIf="errors.invalidFormat" class="mm-result-new-resource-wizard-page-upload-error" i18n>
                        Die Struktur der HTML-Datei entspricht nicht den Vorgaben des Micromate Resource
                        HTML-Formats.
                    </li>
                    <li *ngIf="errors.noContent" class="mm-result-new-resource-wizard-page-upload-error" i18n>
                        Es konnten keine Inhalte aus dem Dokument extrahiert werden.
                    </li>
                    <li *ngIf="errors.unknownError" class="mm-result-new-resource-wizard-page-upload-error" i18n>
                        Es ist ein unbekannter Fehler aufgetreten. Wende dich an
                        <a href="mailto:hello@micromate.ai">hello@micromate.ai</a>.
                    </li>
                    <li *ngIf="errors.tooManyCharacters" class="mm-result-new-resource-wizard-page-upload-error" i18n>
                        Das Dokument enthält zu viele Zeichen. Es sind maximal 500’000 Zeichen pro Dokument erlaubt.
                    </li>
                </ul>
            </div>
        </app-error-box>
    </app-popup-content>
    <app-popup-footer>
        <app-popup-wizard-footer
            [isBackVisible]="false"
            [hasCustomContent]="false"
            i18n-forwardText forwardText="Schliessen"
            (navigateForwardClicked)="popupService.close()"
            [isForwardDisabled]="false"
            [isForwardVisible]="true">
        </app-popup-wizard-footer>
    </app-popup-footer>
</app-popup-container>
