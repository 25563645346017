<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Neuer Tag" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <form [formGroup]="tagForm" class="mm-new-tag-form">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Bezeichnung
                    <span class="micormate-form-label-required">*</span>
                </label>
                <input type="text" formControlName="label"
                       class="micormate-form-input"
                       [class.micormate-form-input-validation-error]="tagForm.controls.label.errors?.trimmedMaxLength">
                <div *ngIf="tagForm.controls.label.errors?.trimmedMaxLength" class="micormate-form-validation-error"
                     i18n>Maximal 50 Zeichen
                </div>
            </div>

            <div class="micormate-form-element mm-new-tag-form-toggle">
                <div class="micormate-form-label" i18n>
                    Aktivierungstag:
                </div>
                <app-switch-toggle formControlName="activationTag"></app-switch-toggle>
            </div>
        </form>

        <app-error-box
            class="mm-new-tag-error-box"
            *ngIf="hasError"
            header="Fehler beim Speichern" i18n-header
            content="Ein Tag mit dieser Bezeichnung existiert bereits." i18n-content></app-error-box>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [action]="createTag"
                [disabled]="hasError || tagForm.invalid"
                i18n>Speichern
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
