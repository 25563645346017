import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {LearnerOverviewComponent} from './app-administration/learner-overview/learner-overview.component';
import {StyleguideComponent} from './styleguide/styleguide.component';
import {OrganizationComponent} from './app-administration/organization/organization.component';
import {OrganizationSettingsComponent} from './app-organisation/organization-settings/organization-settings.component';
import {LearningGroupsOverviewComponent} from './app-learncenter/learning-groups-overview/learning-groups-overview.component';
import {GroupOverviewComponent} from './app-learncenter/group-overview/group-overview.component';
import {AnalyticsGroupOverviewComponent} from './app-analytics/analytics-group-overview/analytics-group-overview.component';
import {AnalyticsGroupDetailComponent} from './app-analytics/analytics-group-detail/analytics-group-detail.component';
import {LearningPackageOverviewComponent} from './app-administration/learn-package-overview/learning-package-overview.component';
import {GroupLearningPackageOverviewComponent} from './app-learncenter/group-learnpackage-overview/group-learning-package-overview.component';
import {GroupLearnersOverviewComponent} from './app-learncenter/group-learners-overview/group-learners-overview.component';
import {LearningPackagesComponent} from './app-editor/learnpackages-overview/learning-packages.component';
import {LearningPackageSettingsComponent} from './app-editor/learnpackage-settings/learning-package-settings.component';
import {LearningPackageVersionsComponent} from './app-editor/learning-package-versions/learning-package-versions.component';
import {LearningPackagesQuizzesComponent} from './app-editor/learnpackages-quizzes/learning-packages-quizzes.component';
import {DeactivateQuizEditorGuard} from './core/deactivate-quiz-editor-guard';
import {UserRoles} from './core/api-types/roles';
import {OrganizationSettingComponent} from './app-administration/organization-setting/organization-setting.component';
import {GroupLearningPackageSettingComponent} from './app-learncenter/group-learning-package-setting/group-learning-package-setting.component';
import {GroupLearningPackageSettingsGuard} from './core/group-learning-package-settings-guard';
import {AnalyticsGroupLearningGoalsComponent} from './app-analytics/analytics-group-learning-goals/analytics-group-learning-goals.component';
import {GroupSettingsGuard} from './core/group-settings-guard';
import {GroupSettingComponent} from './app-learncenter/group-setting/group-setting.component';
import {LearningPackageSettingsGuard} from './core/learning-package-settings-guard';
import {OrganizationSettingsGuard} from './core/organization-settings-guard';
import {OrganisationLearnersOverviewComponent} from './app-organisation/organisation-learners-overview/organisation-learners-overview.component';
import {ApiHistoryComponent} from './app-administration/public-api/api-history/api-history.component';
import {ApiInfoComponent} from './app-administration/public-api/api-info/api-info.component';
import {AnalyticsOverviewComponent} from './app-analytics/analytics-overview/analytics-overview.component';
import {LearnerInfoComponent} from './app-administration/learner-info/learner-info.component';
import {AnalyticsComponent} from './app-administration/analytics/analytics.component';
import {ReportsComponent} from './app-administration/reports/reports.component';
import {LearnPackageImportComponent} from './app-editor/learnpackage-import/learn-package-import.component';
import {ResourcesComponent} from './app-editor/resources/resources.component';
import {ImportWizardComponent} from './app-editor/learnpackage-import/import-wizards/import-wizard.component';
import {Features} from './core/api-types/features';
import {FeatureGuard} from './core/features/feature.guard';
import {CodeExchangeComponent} from './core/authentication/components/code-exchange/code-exchange.component';
import {AuthErrorComponent} from './core/authentication/components/auth-error/auth-error.component';
import {AuthGuard} from './core/authentication/guards/auth.guard';
import {RoleGuard} from './core/authentication/guards/role.guard';
import {LearningPackageTagsComponent} from './app-editor/learning-package-tags/learning-package-tags.component';
import {OrganizationAdminSettingsGuard} from './core/organization-admin-settings-guard';
import {OrganizationManagerComponent} from './app-organisation/organization-manager/organization-manager.component';
import {OrganizationAdminManagersGuard} from './core/organization-admin-managers-guard';
import {OrganizationLearnerInfoComponent} from './app-organisation/organization-learner-info/organization-learner-info.component';
import {MissingRoleComponent} from './core/authentication/components/missing-role/missing-role.component';
import {InboxComponent} from './app-editor/inbox/inbox.component';

const routes: Routes = [
    {
        path: 'code-exchange',
        component: CodeExchangeComponent,
        data: {
            navigationMode: 'no-navigation'
        }
    },
    {
        path: 'auth-error',
        component: AuthErrorComponent,
        data: {
            navigationMode: 'no-navigation'
        }
    },
    {
        path: 'missing-role',
        component: MissingRoleComponent,
        data: {
            navigationMode: 'no-navigation'
        }
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
            mustBe: [UserRoles.SUPER_ADMINISTRATOR,
                UserRoles.SUPERVISOR,
                UserRoles.CONTENT_CREATOR,
                UserRoles.ORGANIZATION_ADMIN],
            breadcrumbType: 'none'
        }
    },
    {
        path: 'styleguide',
        component: StyleguideComponent,
        canActivate: [AuthGuard, RoleGuard],
        data: {
            mustBe: [UserRoles.SUPER_ADMINISTRATOR],
            breadcrumbType: 'none'
        }
    },
    {
        path: 'administration',
        children: [
            {
                path: '',
                redirectTo: 'lernende',
                pathMatch: 'full'
            },
            {
                path: 'lernende',
                children: [
                    {
                        path: '',
                        component: LearnerOverviewComponent,
                        data: {
                            breadcrumbType: 'none'
                        }
                    },
                    {
                        path: ':learnerId',
                        component: LearnerInfoComponent,
                        data: {
                            breadcrumbType: 'dynamic',
                            breadcrumbKey: 'adminLearnerDetails'
                        }
                    }
                ],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Lernende'
                }
            },
            {
                path: 'organisationen',
                children: [
                    {
                        path: '',
                        component: OrganizationComponent,
                        data: {
                            breadcrumbType: 'none'
                        }
                    },
                    {
                        path: ':organizationId',
                        component: OrganizationSettingComponent,
                        canDeactivate: [OrganizationSettingsGuard],
                        data: {
                            breadcrumbType: 'dynamic',
                            breadcrumbKey: 'adminOrganizationDetails'
                        }
                    }
                ],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Organisationen'
                }
            },
            {
                path: 'lernpakete',
                component: LearningPackageOverviewComponent,
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Lernpakete'
                }
            },
            {
                path: 'analytics',
                component: AnalyticsComponent,
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Analytics'
                }
            },
            {
                path: 'reports',
                component: ReportsComponent,
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Reports'
                }
            },
            {
                path: 'api-history',
                children: [
                    {
                        path: '',
                        component: ApiHistoryComponent,
                        data: {
                            breadcrumbType: 'none'
                        }
                    },
                    {
                        path: ':apiId',
                        component: ApiInfoComponent,
                        data: {
                            breadcrumbType: 'static',
                            breadcrumbLabel: 'API Call Details'
                        }
                    }
                ],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'API History'
                }
            }
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
            mustBe: [UserRoles.SUPER_ADMINISTRATOR],
            breadcrumbType: 'static',
            breadcrumbLabel: 'Administration'
        }
    },
    {
        path: 'lerncenter',
        children: [
            {
                path: '',
                redirectTo: 'lerngruppen',
                pathMatch: 'full'
            },
            {
                path: 'lerngruppen',
                children: [
                    {
                        path: '',
                        component: LearningGroupsOverviewComponent,
                        data: {
                            breadcrumbType: 'none'
                        }
                    },
                    {
                        path: ':lerngruppeId',
                        children: [
                            {
                                path: '',
                                component: GroupOverviewComponent,
                                data: {
                                    breadcrumbType: 'none'
                                }
                            },
                            {
                                path: 'einstellungen',
                                component: GroupSettingComponent,
                                canDeactivate: [GroupSettingsGuard],
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Einstellungen'
                                }
                            },
                            {
                                path: 'lernende',
                                component: GroupLearnersOverviewComponent,
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Lernende'
                                }
                            },
                            {
                                path: 'lernpakete',
                                children: [
                                    {
                                        path: '',
                                        component: GroupLearningPackageOverviewComponent,
                                        data: {
                                            breadcrumbType: 'none'
                                        }
                                    },
                                    {
                                        path: ':learningPackageId',
                                        component: GroupLearningPackageSettingComponent,
                                        canDeactivate: [GroupLearningPackageSettingsGuard],
                                        data: {
                                            breadcrumbType: 'dynamic',
                                            breadcrumbKey: 'groupLearningPackageDetails'
                                        }
                                    }
                                ],
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Lernpakete'
                                }
                            }
                        ],
                        data: {
                            breadcrumbType: 'dynamic',
                            breadcrumbKey: 'learningGroupDetails'
                        }
                    }
                ],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Lerngruppen'
                }
            }
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
            mustBe: [UserRoles.SUPERVISOR],
            breadcrumbType: 'static',
            breadcrumbLabel: 'Lerncenter'
        }
    },
    {
        path: 'editor',
        children: [
            {
                path: '',
                redirectTo: 'lernpakete',
                pathMatch: 'full'
            },
            {
                path: 'lernpakete',
                children: [
                    {
                        path: '',
                        component: LearningPackagesComponent,
                        data: {
                            breadcrumbType: 'none'
                        }
                    },
                    {
                        path: ':learningPackageId',
                        children: [
                            {
                                path: '',
                                redirectTo: 'fragen',
                                pathMatch: 'full'
                            },
                            {
                                path: 'einstellungen',
                                component: LearningPackageSettingsComponent,
                                runGuardsAndResolvers: 'always',
                                canDeactivate: [LearningPackageSettingsGuard],
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Einstellungen'
                                }
                            },
                            {
                                path: 'tags',
                                component: LearningPackageTagsComponent,
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Tags'
                                }
                            },
                            {
                                path: 'inbox',
                                children: [
                                    {
                                        path: '',
                                        component: InboxComponent,
                                        data: {
                                            breadcrumbType: 'none'
                                        }
                                    },
                                    {
                                        path: 'feedback/:feedbackId',
                                        component: InboxComponent,
                                        data: {
                                            breadcrumbType: 'static',
                                            breadcrumbLabel: 'Feedback'
                                        }
                                    }
                                ],
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Inbox'
                                }
                            },
                            {
                                path: 'ressourcen',
                                component: ResourcesComponent,
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Ressourcen'
                                }
                            },
                            {
                                path: 'versionen',
                                component: LearningPackageVersionsComponent,
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Versionen'
                                }
                            },
                            {
                                path: 'import',
                                component: LearnPackageImportComponent,
                                children: [
                                    {
                                        path: 'csv-datei',
                                        component: ImportWizardComponent,
                                        data: {
                                            breadcrumbType: 'static',
                                            breadcrumbLabel: 'CSV-Datei'
                                        }
                                    },
                                    {
                                        path: 'ressource',
                                        component: ImportWizardComponent,
                                        data: {
                                            breadcrumbType: 'static',
                                            breadcrumbLabel: 'Ressource'
                                        }
                                    },
                                    {
                                        path: 'learning-package',
                                        component: ImportWizardComponent,
                                        data: {
                                            breadcrumbType: 'static',
                                            breadcrumbLabel: 'Lernpaket'
                                        }
                                    }
                                ],
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Import'
                                }
                            },
                            {
                                path: 'fragen',
                                children: [
                                    {
                                        path: '',
                                        component: LearningPackagesQuizzesComponent,
                                        data: {
                                            breadcrumbType: 'none'
                                        }
                                    },
                                    {
                                        path: 'neue-frage',
                                        component: LearningPackagesQuizzesComponent,
                                        canDeactivate: [DeactivateQuizEditorGuard],
                                        data: {
                                            breadcrumbType: 'static',
                                            breadcrumbLabel: 'Neue Frage'
                                        }
                                    },
                                    {
                                        path: ':quizId',
                                        component: LearningPackagesQuizzesComponent,
                                        canDeactivate: [DeactivateQuizEditorGuard],
                                        data: {
                                            breadcrumbType: 'static',
                                            breadcrumbLabel: 'Frage'
                                        }
                                    }
                                ],
                                component: LearningPackagesQuizzesComponent,
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Fragen'
                                }
                            }
                        ],
                        data: {
                            breadcrumbType: 'dynamic',
                            breadcrumbKey: 'learningPackageDetails'
                        }
                    }
                ],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Lernpakete'
                }
            }
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
            mustBe: [UserRoles.CONTENT_CREATOR],
            breadcrumbType: 'static',
            breadcrumbLabel: 'Editor'
        }
    },
    {
        path: 'analytics',
        children: [
            {
                path: '',
                redirectTo: 'uebersicht',
                pathMatch: 'full'
            },
            {
                path: 'uebersicht',
                component: AnalyticsOverviewComponent,
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Übersicht'
                }
            },
            {
                path: 'lerngruppen',
                children: [
                    {
                        path: '',
                        component: AnalyticsGroupOverviewComponent,
                        data: {
                            breadcrumbType: 'none'
                        }
                    },
                    {
                        path: ':lerngruppeId',
                        children: [
                            {
                                path: '',
                                component: AnalyticsGroupDetailComponent,
                                data: {
                                    breadcrumbType: 'none'
                                }
                            },
                            {
                                path: 'lernziele',
                                component: AnalyticsGroupLearningGoalsComponent,
                                canActivate: [FeatureGuard],
                                data: {
                                    breadcrumbType: 'static',
                                    breadcrumbLabel: 'Lernziele',
                                    mustHave: [Features.PERSONAL_INSIGHTS]
                                }
                            }
                        ],
                        data: {
                            breadcrumbType: 'dynamic',
                            breadcrumbKey: 'learningGroupDetails'
                        }
                    }
                ],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Lerngruppen'
                }
            }
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
            mustBe: [UserRoles.SUPERVISOR],
            breadcrumbType: 'static',
            breadcrumbLabel: 'Analytics'
        }
    },
    {
        path: 'organisation',
        children: [
            {
                path: '',
                redirectTo: 'lernende',
                pathMatch: 'full'
            },
            {
                path: 'lernende',
                children: [
                    {
                        path: '',
                        component: OrganisationLearnersOverviewComponent,
                        data: {
                            breadcrumbType: 'none'
                        }
                    },
                    {
                        path: ':learnerId',
                        component: OrganizationLearnerInfoComponent,
                        data: {
                            breadcrumbType: 'dynamic',
                            breadcrumbKey: 'organizationAdminLearnerDetails'
                        }
                    }
                ],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Lernende'
                }
            },
            {
                path: 'manager',
                component: OrganizationManagerComponent,
                canDeactivate: [OrganizationAdminManagersGuard],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Manager'
                }
            },
            {
                path: 'einstellungen',
                component: OrganizationSettingsComponent,
                canDeactivate: [OrganizationAdminSettingsGuard],
                data: {
                    breadcrumbType: 'static',
                    breadcrumbLabel: 'Einstellungen'
                }
            }
        ],
        canActivate: [AuthGuard, RoleGuard],
        data: {
            mustBe: [UserRoles.ORGANIZATION_ADMIN],
            breadcrumbType: 'dynamic',
            breadcrumbKey: 'organizationDetails'
        }
    },
    {
        path: '**',
        redirectTo: '/'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {paramsInheritanceStrategy: 'always'})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
