import {Component} from '@angular/core';
import {EditorResource} from '../../../../../core/rest/editor-resource.service';
import {FileFormatValidationError} from '../../../../../core/editor/types/QuestionConversion/FileFormatValidationError';
import {EditorLearningPackageService} from '../../../../../core/editor/editor-learning-package.service';
import {ConversionResult} from '../../../../../core/editor/types/QuestionConversion/ConversionResult';
import {QuestionValidationResult} from '../../../../../core/editor/types/QuestionConversion/QuestionValidationResult';
import {ValidationStateType} from '../../../../../core/editor/types/QuestionConversion/ValidationState';
import {ImportWizardService} from '../../import-wizard.service';

@Component({
    selector: 'app-csv-file-wizard-import-page',
    templateUrl: './csv-file-wizard-import-page.component.html',
    styleUrls: ['./csv-file-wizard-import-page.component.scss']
})
export class CsvFileWizardImportPageComponent {
    public fileToImport: File;
    public fileName: string;
    public conversionResult: ConversionResult;
    public invalidFile: boolean = false;
    public invalidFileType: boolean = false;
    public importFileFormatValidationError: FileFormatValidationError;
    public questionsValidationErrors: QuestionValidationResult[];
    public invalidQuestions: QuestionValidationResult[];
    public numberOfQuestions: number;
    public numberOfValidQuestions: number;
    public numberOfInvalidQuestions: number;
    public activeInvalidQuestion: number = 0;
    public invalidFileToManyFiles: boolean = false;

    constructor(private editorService: EditorResource,
                private editorLearningPackageService: EditorLearningPackageService,
                private importWizardService: ImportWizardService) {
    }

    public sendFile(files: FileList): void {
        this.clearPreviousImport();
        if (files.length > 1) {
            this.invalidFile = true;
            this.invalidFileToManyFiles = true;
        } else {
            this.importWizardService.showSpinner();
            this.fileToImport = files.item(0);
            this.fileName = this.fileToImport.name;
            if (this.fileToImport.type === 'text/csv') {
                this.importCSV();
            } else {
                this.invalidFile = true;
                this.invalidFileType = true;
                this.importWizardService.hideSpinner();
                this.fileToImport = undefined;
            }
        }
    }

    private clearPreviousImport(): void {
        this.invalidFile = false;
        this.invalidFileType = false;
        this.importFileFormatValidationError = undefined;
        this.questionsValidationErrors = undefined;
        this.invalidQuestions = undefined;
        this.activeInvalidQuestion = 0;
        this.invalidFileToManyFiles = false;
    }

    private importCSV(): void {
        const learningPackageId: string = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        const reader = new FileReader();
        reader.readAsText(this.fileToImport);
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        reader.onload = (event: ProgressEvent<FileReader>) => {
            this.editorService.convertCsvToQuestions(event.target.result as string, this.fileToImport.name, learningPackageId)
                .subscribe((conversionResult: ConversionResult) => this.handleImportResult(conversionResult));
        };
    }

    private handleImportResult(conversionResult: ConversionResult): void {
        this.importWizardService.hideSpinner();
        this.fileToImport = undefined;
        this.conversionResult = conversionResult;
        if (conversionResult.isValid) {
            this.checkQuestionValidation(conversionResult);
        } else {
            this.handleInvalidImportFileFormat(conversionResult);
        }
    }

    private checkQuestionValidation(conversionResult: ConversionResult): void {
        if (conversionResult.questions.isValid) {
            this.handleSuccessConversion(conversionResult);
        } else {
            this.handleInvalidQuestions(conversionResult);
        }
    }

    private handleSuccessConversion(conversionResult: ConversionResult): void {
        this.invalidFile = false;
        this.importWizardService.addQuestions(conversionResult.questions.questions);
        this.importWizardService.currentStep = 'questions-summary';
    }

    private handleInvalidQuestions(conversionResult: ConversionResult): void {
        this.invalidFile = true;
        this.numberOfQuestions = conversionResult.questions.validationErrors.length;
        this.invalidQuestions = conversionResult.questions.validationErrors
            .map((question, index) => {
                question.index = index;
                return question;
            }).filter(question => question.validationState === ValidationStateType.Invalid);
        this.numberOfInvalidQuestions = this.invalidQuestions.length;
        this.numberOfValidQuestions = this.numberOfQuestions - this.numberOfInvalidQuestions;
        this.questionsValidationErrors = conversionResult.questions.validationErrors;
    }

    private handleInvalidImportFileFormat(conversionResult: ConversionResult): void {
        this.invalidFile = true;
        this.importFileFormatValidationError = conversionResult.fileFormatValidationErrors;
    }

    public showPreviousQuestion(): void {
        if (this.activeInvalidQuestion !== 0) {
            this.activeInvalidQuestion--;
        }
    }

    public showNextQuestion(): void {
        if (this.activeInvalidQuestion !== (this.invalidQuestions.length - 1)) {
            this.activeInvalidQuestion++;
        }
    }

    public getObjectEntries(object: { [key: string]: number[]; }): { key: string; value: number[]; }[] {
        const entries: { key: string; value: number[]; }[] = [];
        for (const [key, value] of Object.entries(object)) {
            entries.push({key: key, value: value});
        }
        return entries;
    }
}
