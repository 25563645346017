import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ListPaginationRequest} from '../../../core/api-types/listPaginationRequest';
import {ListPagination} from '../../../core/api-types/listPagination';
import {ApiHistory} from '../../../core/api-types/apiHistory';
import {Router} from '@angular/router';
import {TablePaginationParamsService} from '../../../core/table-pagination-params.service';
import {ApiHistoryService} from '../../../core/rest/api-history.service';
import {ApiHistoryParamsService} from '../../../core/admin/api-history-params.service';
import {combineLatest, Subscription} from 'rxjs';

@Component({
    selector: 'app-api-history',
    templateUrl: './api-history.component.html',
    styleUrls: ['./api-history.component.scss']
})
export class ApiHistoryComponent implements OnInit, OnDestroy {
    public apis: ApiHistory[] = [];
    public paginationParams: ListPaginationRequest;
    public listPagination: ListPagination<ApiHistory>;
    public navigationUrl: string[] = ['administration', 'api-history'];
    public errorMessages: boolean = false;
    public paginationParams$ = new Subscription();
    public numberOfCalls: number;

    @ViewChild('tableContentRef') tableContentRef: ElementRef;

    constructor(private router: Router,
                private tablePaginationParamsService: TablePaginationParamsService,
                private apiHistoryService: ApiHistoryService,
                private apiHistoryParamsService: ApiHistoryParamsService) {
    }

    public ngOnInit(): void {
        this.tablePaginationParamsService.updateFromActivatedRouteParams();
        this.apiHistoryParamsService.getApiHistoryParams();

        this.apiHistoryService.getNumberOfCalls().subscribe((data: { value: number; }) => {
            this.numberOfCalls = data.value;
        });

        this.paginationParams$ = combineLatest([this.tablePaginationParamsService.paginationParams$, this.apiHistoryParamsService.errorMessages$]).subscribe(([paginationParams, errorMessages]: [ListPaginationRequest, boolean]) => {
            this.paginationParams = paginationParams;
            this.errorMessages = errorMessages;
            this.paginationParams.errorApis = this.errorMessages;
            this.getApiHistory();
        });
    }

    private getApiHistory(): void {
        this.apiHistoryService.getHistory(this.paginationParams).subscribe((listPagination: ListPagination<ApiHistory>) => {
            this.listPagination = listPagination;
            this.apis = listPagination.data;
            if (this.tableContentRef !== undefined) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.tableContentRef.nativeElement.scrollTop = 0;
            }
        });
    }

    public redirectToApiInfo(apiId: string): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['administration', 'api-history', apiId]);
    }

    public onOnlyErrorMessagesChanged(): void {
        this.paginationParams.errorApis = this.errorMessages;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(this.navigationUrl, {queryParams: this.paginationParams});
    }

    public ngOnDestroy(): void {
        this.tablePaginationParamsService.unSubscribeActiveRoute();
        this.paginationParams$.unsubscribe();
    }

}
