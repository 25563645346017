import {AfterViewInit, Component, ElementRef, forwardRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['./textarea.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextareaComponent),
            multi: true,
        }
    ],
})

export class TextareaComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input()
    public placeholder: string;
    @Input()
    public formControl: FormControl<string>;
    @Input()
    public autoresize: boolean = false;
    @Input()
    public minRow: number = 1;
    @Input()
    public maxRow: number = 1;

    public value: string;
    public formControlValueChange$ = new Subscription();
    @ViewChild('textarea')
    public textarea: ElementRef<HTMLTextAreaElement>;

    private static TEXTAREA_INSIDE_PADDING_IN_PX = 8;
    private static TEXTAREA_SCROLL_SIZE_DIFFERENCE = 2;

    public ngAfterViewInit(): void {
        if (this.autoresize && this.textarea.nativeElement.scrollHeight !== 0) {
            setTimeout(() => this.resize());
        }
    }

    public ngOnInit(): void {
        if (this.formControl !== undefined) {
            this.formControlValueChange$ = this.formControl.valueChanges.subscribe((newValue: string) => {
                if (this.autoresize) {
                    if (newValue.length > 0) {
                        this.resize();
                    } else {
                        this.resetSize();
                    }
                }
            });
        }
    }

    public ngOnDestroy(): void {
        this.formControlValueChange$.unsubscribe();
    }

    private resetSize(): void {
        const heightForNumberOfRow = this.getHeightForNumberOfRow(this.minRow);
        this.setHeightOnTextBox(heightForNumberOfRow);
    }

    private setHeightOnTextBox(heightInPx: number): void {
        this.textarea.nativeElement.style.height = `${heightInPx}px`;
    }

    private resize(): void {
        const maxHeight = this.getHeightForNumberOfRow(this.maxRow);
        this.setHeightOnTextBox(0);
        const scrollHeight = this.textarea.nativeElement.scrollHeight + TextareaComponent.TEXTAREA_SCROLL_SIZE_DIFFERENCE;
        this.setHeightOnTextBox(Math.min(scrollHeight, maxHeight));
    }

    private getHeightForNumberOfRow(numberOfRow: number): number {
        const computedStyle = window.getComputedStyle(this.textarea.nativeElement);
        const lineHeight = parseFloat(computedStyle.getPropertyValue('line-height'));
        return (numberOfRow * lineHeight) + TextareaComponent.TEXTAREA_INSIDE_PADDING_IN_PX;
    }

    public onTouched: (param: string) => void;
    public onChange: (param: string) => void;

    public writeValue(value: string): void {
        this.value = value;
    }

    public registerOnChange(fn: (param: string) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: (param: string) => void): void {
        this.onTouched = fn;
    }

    public onModelChange(value: string): void {
        this.onChange(value);
    }
}
