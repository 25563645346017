import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent {

    @Input()
    public icon: Icons;

}


export type QuestionTypeIcons =
    'free-text'
    | 'multiple-choice'
    | 'single-choice'
    | 'true-false'
    | 'reflection-free-text';
export type FontAwesomeIcons = 'ai'
    | 'tags-light'
    | 'ellipsis-h-light'
    | 'square-light'
    | 'square-check-light'
    | 'hourglass-clock'
    | 'circle-check'
    | 'circle-minus'
    | 'times-light'
    | 'plus-light'
    | 'user-friends-light'
    | 'sitemap-light'
    | 'boxes-alt-light'
    | 'analytics-light'
    | 'file-chart-column-light'
    | 'history-light'
    | 'users-class-light'
    | 'tachometer-alt-fast-light'
    | 'ballot-check-light'
    | 'files-light'
    | 'file-import-light'
    | 'layer-group-light'
    | 'cog-light'
    | 'dot-circle-regular'
    | 'trash-light'
    | 'tag-light'
    | 'tag-solid'
    | 'check-light'
    | 'exclamation-triangle-light'
    | 'language-light'
    | 'ranking-star-light'
    | 'chevron-right-light'
    | 'arrows-rotate-light'
    | 'arrows-rotate-regular'
    | 'copy-light'
    | 'shuffle'
    | 'book-light'
    | 'file-spreadsheet-thin'
    | 'box-taped-thin'
    | 'user-group-crown'
    | 'user-crown-light'
    | 'user-pen-light'
    | 'user-tie-light'
    | 'circle-pause-light'
    | 'circle-play-light'
    | 'info-circle-light'
    | 'angle-up-light'
    | 'angle-down-light'
    | 'message-pen-light'
    | 'message-check-light'
    | 'message-minus-light'
    | 'inbox-light'
    | 'circle-exclamation-light'
    | 'circle-info-light'
    | 'circle-check-light'
    | 'arrow-up-right-from-square'
    | 'thumbs-down-light'
    | 'thumbs-up-light'
    | 'chevron-double-left-light'
    | 'chevron-left-light'
    | 'file-code'
    | 'chevron-double-right-light'
    | 'repeat-light';
export type CustomIcons =
    'imported-question';
export type Icons = QuestionTypeIcons | FontAwesomeIcons | CustomIcons;
