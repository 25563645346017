import {Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {
    OrganizationLearnersListPaginationRequest,
    OrganizationStatusFilter
} from './OrganizationLearnersListPaginationRequest';
import {QueryParamService} from '../../core/QueryParamService';

@Injectable({
    providedIn: 'root'
})
export class OrganizationLearnersTablePaginationParamsService {
    public paramsSubscription = new Subscription();
    public filter = new BehaviorSubject<string>('');
    public filter$ = this.filter.asObservable();

    public pageNumber = new BehaviorSubject<number>(1);
    public pageNumber$ = this.pageNumber.asObservable();

    public pageAmount = new BehaviorSubject<string>('50');

    public organizationStatusFilter = new BehaviorSubject<OrganizationStatusFilter>('active');
    public organizationStatusFilter$ = this.organizationStatusFilter.asObservable();

    public paginationParams = new BehaviorSubject<OrganizationLearnersListPaginationRequest>(new OrganizationLearnersListPaginationRequest('', 1, '50', 'active'));
    public paginationParams$ = this.paginationParams.asObservable();

    constructor(private activatedRoute: ActivatedRoute) {
    }

    public getActivatedRouteParams(): void {
        this.paramsSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
            const activeLanguageInfo = QueryParamService.getQueryParamValueString(params, 'filter', '');
            this.filter.next(activeLanguageInfo);

            const pageNumberInfo = QueryParamService.getQueryParamValueInt(params, 'pageNumber', 1);
            this.pageNumber.next(pageNumberInfo);

            const pageAmountInfo = QueryParamService.getQueryParamValueString(params, 'pageAmount', '50');
            this.pageAmount.next(pageAmountInfo);

            const organizationStatusFilter = QueryParamService.getQueryParamValueString(params, 'organizationStatus', 'active');
            this.organizationStatusFilter.next(organizationStatusFilter as OrganizationStatusFilter);

            this.paginationParams.next(new OrganizationLearnersListPaginationRequest(this.filter.value, this.pageNumber.value, this.pageAmount.value, this.organizationStatusFilter.value));
        });
    }

    public unSubscribeActiveRoute(): void {
        this.paramsSubscription.unsubscribe();
    }

    public getPaginationParams(): ListPaginationRequest {
        return this.paginationParams.value;
    }
}
