import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {OrganizationAdminRestService} from './rest/organization-admin/organization-admin-rest.service';
import {AdminService} from './admin/admin.service';
import {UserRoles} from './api-types/roles';
import {Features} from './features/Features';
import {LocalStorageService} from './storage/local-storage.service';
import {AuthService} from './authentication/auth.service';
import {AuthUntilUserRoleService} from './authentication/auth-until-user-role.service';
import {User} from './api-types/user';
import {ActiveOrganizationResponseDto} from './api-types/ActiveOrganizationResponseDto';

@Injectable({
    providedIn: 'root'
})
export class OrganizationService {

    private activeOrganizationId = new BehaviorSubject<string>(undefined);
    public activeOrganizationId$ = this.activeOrganizationId.asObservable().pipe(filter((activeOrganizationId: string) => activeOrganizationId !== undefined));

    public hasOrganizationId$: Observable<boolean> = this.activeOrganizationId$.pipe(map((activeOrganizationId: string) => activeOrganizationId !== undefined));

    private userOrganizations = new BehaviorSubject<{ id: string; zitadelId: string; name: string; }[]>(undefined);
    public userOrganizations$ = this.userOrganizations.asObservable();

    constructor(private http: HttpClient,
                private localStorageService: LocalStorageService,
                private organizationAdminRestService: OrganizationAdminRestService,
                private authService: AuthService,
                private adminService: AdminService,
                private authUntilUserRoleService: AuthUntilUserRoleService) {
        // TODO CLEANUP TASK: We should not subscribe but only use pipes here
        this.authService.authentication$.pipe(
            filter((authenticated: User) => authenticated !== undefined && this.hasOrganizations(authenticated))
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
        ).subscribe(async (user: User) => {
            if (user.roles !== undefined && user.roles.includes(UserRoles.SUPER_ADMINISTRATOR)) {
                this.userOrganizations.next(await this.organizationAdminRestService.getAdminOrganizations());
            } else {
                const organisations: { id: string; zitadelId: string; name: string; }[] = [];
                for (const organisation of Object.keys(user.organisations)) {
                    organisations.push(await this.organizationAdminRestService.getOrganizationName(organisation));
                }
                this.userOrganizations.next(organisations);
            }
            const localStorageOrganizationId = this.getOrganizationFromLocalStorage();
            if (localStorageOrganizationId !== undefined && this.userOrganizations.value.some(organization => organization.zitadelId === localStorageOrganizationId)) {
                this.activeOrganizationId.next(localStorageOrganizationId);
            } else {
                this.activeOrganizationId.next(this.userOrganizations.value[0].zitadelId);
                this.setOrganizationToLocalStorage(this.activeOrganizationId.value);
            }
        });
        this.adminService.addedNewOrganization$.pipe(
            filter((status: boolean) => status),
            switchMap(() => this.authUntilUserRoleService.hasSuperAdminRole()),
            filter((isSuperAdmin: boolean) => isSuperAdmin)
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
        ).subscribe(async () =>
            this.userOrganizations.next(await this.organizationAdminRestService.getAdminOrganizations()));
    }

    private hasOrganizations(authenticated: User): boolean {
        return Object.entries(authenticated.organisations).length > 0;
    }

    public setActiveOrganization(organization: string): void {
        this.setOrganizationToLocalStorage(organization);
        this.activeOrganizationId.next(organization);
    }

    /**
     * @deprecated Use activeOrganizationId$ instead
     */
    public getActiveOrganizationId(): string {
        return this.activeOrganizationId.value;
    }

    public activeOrganization$ = this.activeOrganizationId$.pipe(
        filter((activeOrganizationId: string) => activeOrganizationId !== undefined),
        switchMap((activeOrganizationId: string) => this.organizationAdminRestService.getActiveOrganizationData(activeOrganizationId)),
        shareReplay(1)
    );

    public organizationFeatures$: Observable<Features> = this.activeOrganization$.pipe(
        switchMap((organization: ActiveOrganizationResponseDto) => of(organization.features)),
        shareReplay(1)
    );

    public getOrganizationFromLocalStorage(): string {
        return this.localStorageService.getItem('activeOrganizationId');
    }

    public setOrganizationToLocalStorage(organizationId: string): void {
        this.localStorageService.setActiveOrganizationId(organizationId);
    }

    // TODO This refresh is somehow wrong
    public async refreshActiveOrganizationInfo(): Promise<void> {
        this.activeOrganizationId.next(this.activeOrganizationId.value);
    }

    public getOrganizationFeatures(): Promise<Features> {
        return this.organizationFeatures$.pipe(take(1)).toPromise();
    }

}
