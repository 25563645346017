import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {GroupService} from '../../core/group.service';
import {LearningGroupResource} from '../../core/rest/learning-group-resource.service';
import {Learner} from '../../core/api-types/learner';
import {Group} from '../../core/api-types/group';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {ListPagination} from '../../core/api-types/listPagination';
import {TablePaginationParamsService} from '../../core/table-pagination-params.service';
import {firstValueFrom, Subscription} from 'rxjs';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {AddLearnerToGroupPopupComponent} from './add-learner-to-group-popup/add-learner-to-group-popup.component';
import {AddLearnerToGroupPopupData} from './add-learner-to-group-popup/AddLearnerToGroupPopupData';
import {PopupService} from '../../components/popup/popup.service';
import {ConfirmationPopupData} from '../../components/confirmation-popup/ConfirmationPopupData';
import {ConfirmationPopupResult} from '../../components/confirmation-popup/ConfirmationPopupResult';
import {ConfirmationPopupComponent} from '../../components/confirmation-popup/confirmation-popup.component';

@Component({
    selector: 'app-group-learners-overview',
    templateUrl: './group-learners-overview.component.html',
    styleUrls: ['./group-learners-overview.component.scss']
})
export class GroupLearnersOverviewComponent implements OnInit, OnDestroy {
    public groupLearners: Learner[];
    public defaultGroup: boolean = true;
    public activeGroup: Group;
    public paginationParams: ListPaginationRequest;
    public listPagination: ListPagination<Learner>;
    public navigationUrl: string[];
    public activeGroup$ = new Subscription();
    public paginationParams$ = new Subscription();

    @ViewChild('tableContentRef') tableContentRef: ElementRef;

    constructor(private groupService: GroupService,
                private groupRestService: LearningGroupResource,
                public multilanguageService: MultilanguageService,
                private tablePaginationParamsService: TablePaginationParamsService,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.tablePaginationParamsService.updateFromActivatedRouteParams();

        this.activeGroup$ = this.groupService.activeGroup$.subscribe((selectedGroup: Group) => {
            this.paginationParams$ = this.tablePaginationParamsService.paginationParams$.subscribe((paginationParams: ListPaginationRequest) => {
                this.paginationParams = paginationParams;
                if (selectedGroup?._id !== undefined) {
                    this.navigationUrl = ['lerncenter', 'lerngruppen', selectedGroup._id, 'lernende'];
                    this.activeGroup = selectedGroup;
                    if (selectedGroup.name !== 'Alle Lernenden') {
                        this.defaultGroup = false;
                    }
                    this.groupRestService.getAllLearnersInGroup(selectedGroup._id, this.paginationParams).subscribe((listPagination: ListPagination<Learner>) => {
                        this.listPagination = listPagination;
                        this.groupLearners = listPagination.data;
                        if (this.tableContentRef !== undefined) {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            this.tableContentRef.nativeElement.scrollTop = 0;
                        }
                    });
                }
            });

        });
    }

    public openAddingLearnerToGroupForm(): void {
        const addLearnerToGroupPopupData = new AddLearnerToGroupPopupData(this.groupService.selectedGroupId.value);
        void this.popupService.open<AddLearnerToGroupPopupData>(AddLearnerToGroupPopupComponent, addLearnerToGroupPopupData, new PopupOptions());
    }

    public async openLearnerRemovingPopup(learner: { _id: string; displayName: string; identifier: string; }): Promise<void> {
        const text = `Möchtest du <strong>${learner.displayName ?? learner.identifier}</strong> wirklich aus der Lerngruppe
        <strong>${this.activeGroup.name}</strong> löschen?`;
        const confirmationPopupData = new ConfirmationPopupData(text, 'Löschen', 'Abbrechen', async () => {
            await firstValueFrom(this.groupRestService.removeLearnerForGroup(this.activeGroup._id, learner._id));
            this.groupService.selectedGroupId.next(this.activeGroup._id);
        });
        const popupOptions = new PopupOptions('small');
        await this.popupService.open<ConfirmationPopupData, ConfirmationPopupResult>(ConfirmationPopupComponent, confirmationPopupData, popupOptions);
    }

    public ngOnDestroy(): void {
        this.activeGroup$.unsubscribe();
        this.tablePaginationParamsService.unSubscribeActiveRoute();
        this.paginationParams$.unsubscribe();
    }
}
