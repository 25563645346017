<app-popup-container>
    <app-popup-wizard-header header="Neue Ressource" i18n-header></app-popup-wizard-header>

    <app-popup-content>
        <form [formGroup]="formData" class="micormate-form">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Name
                    <span class="micormate-form-label-required">*</span>
                </label>
                <input type="text" formControlName="name"
                       class="micormate-form-input micormate-form-input-organization-name"
                       [class.micormate-form-input-validation-error]="formData.value.name.length > 100">
                <div *ngIf="formData.value.name.length > 100" class="micormate-form-validation-error" i18n>
                    Maximal 100 Zeichen
                </div>
            </div>
        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-wizard-footer
            [isBackVisible]="false"
            [hasCustomContent]="false"
            i18n-forwardText forwardText="Hinzufügen"
            (navigateForwardClicked)="uploadResource()"
            [isForwardDisabled]="formData.invalid"
            [isForwardVisible]="true">
        </app-popup-wizard-footer>
    </app-popup-footer>
</app-popup-container>
