import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-warning-box',
  templateUrl: './warning-box.component.html',
  styleUrls: ['./warning-box.component.scss']
})
export class WarningBoxComponent {
    @Input() header: string;
    @Input() content: string;
}
