export interface FeedbackDto {
    id: string;
    creator: FeedbackCreatorDto;
    locale: string;
    creationDate: Date;
    feedback: string;
    feedbackCategory: FeedbackCategoryType;
    learningPackageTranslationId: string;
    learningPackageId: string;
    quizTranslationId?: string;
    quizId?: string;
    rating?: FeedbackRatingType;
    status: FeedbackStatusType;
}

export interface FeedbackCreatorDto {
    name: string;
    identifier: string;
}

export enum FeedbackCategoryType {
    QuestionFaulty = 'questionFaulty',
    QuestionUnintelligible = 'questionUnintelligible',
    QuestionSpelling = 'questionSpelling',
    QuestionIdea = 'questionIdea',
    LearningPackageQuestionSuggestion = 'learningPackageQuestionSuggestion',
    LearningPackageTopicSuggestion = 'learningPackageTopicSuggestion',
    LearningPackageFeedback = 'learningPackageFeedback'
}

export enum FeedbackStatusType {
    Open = 'open',
    Accept = 'accept'
}

export enum FeedbackRatingType {
    Positive = 'positive',
    Negative = 'negative'
}

