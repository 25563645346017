<div class="micromate-home-page">
    <h1 i18n>Willkommen bei Micromate!</h1>

    <div class="micromate-home-page-content">
        <div class="micromate-small-cards">
            <div class="micromate-small-card micromate-small-card-full-flex-width"
                 *ngIf="(userRoleService.hasSupervisorRole() | async)" [routerLink]="['lerncenter']">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#graduation-cap-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title ">
                    <h2 class="micromate-small-card-title-subtext" i18n>Lerncenter</h2>
                </div>
            </div>
            <div class="micromate-small-card micromate-small-card-full-flex-width"
                 *ngIf="(userRoleService.hasContentCreatorRole() | async)" [routerLink]="['editor']">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="-50 0 512 600">
                        <use href="./assets/icons.svg#pencil-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title ">
                    <h2 class="micromate-small-card-title-subtext" i18n>Editor</h2>
                </div>
            </div>
            <div class="micromate-small-card micromate-small-card-full-flex-width micromate-small-card-button"
                 *ngIf="(userRoleService.hasSupervisorRole() | async)" [routerLink]="['analytics']">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 576 512">
                        <use href="./assets/icons.svg#analytics-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title ">
                    <h2 class="micromate-small-card-title-subtext" i18n>Analytics</h2>
                </div>
            </div>
        </div>

        <div class="micromate-home-info">
            <p class="micromate-home-info-text">Das Video gibt einen Einblick in die E-Learning-Experience in Microsoft Teams und zeigt die Hauptfunktionalitäten von Manage Micromate:</p>
        </div>

        <div class="micromate-home-movie video-container">
            <iframe src="https://www.youtube-nocookie.com/embed/O7xb5s-z99Q?rel=0&amp;controls=0"
                    title="YouTube video player" frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen></iframe>
        </div>

    </div>
</div>
