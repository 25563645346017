import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {filter, switchMap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {OrganizationService} from '../../core/organization.service';
import {AnalyticsResource} from '../../core/rest/analytics-rest/analytics-resource.service';
import {OrganizationAnalyticsOverview} from '../../core/rest/analytics-rest/OrganizationAnalyticsOverview';
import {TimestampSentenceCreator} from '../../core/analytics/TimestampSentenceCreator';

@Component({
    selector: 'app-analytics-overview',
    templateUrl: './analytics-overview.component.html',
    styleUrls: ['./analytics-overview.component.scss']
})
export class AnalyticsOverviewComponent implements OnInit, OnDestroy {
    public activeOrganizationId$ = new Subscription();
    public organizationAnalyticsOverview: OrganizationAnalyticsOverview;
    public currentDate: Date;

    constructor(private router: Router,
                private organizationService: OrganizationService,
                private analyticsRestService: AnalyticsResource) {
    }

    public ngOnInit(): void {
        this.currentDate = new Date();
        this.activeOrganizationId$ = this.organizationService.activeOrganizationId$.pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter((activeOrganizationId: string) => !!activeOrganizationId),
            switchMap(() => this.analyticsRestService.getOrganizationAnalyticsOverview())
        ).subscribe((organizationAnalyticsOverview: OrganizationAnalyticsOverview) => this.organizationAnalyticsOverview = organizationAnalyticsOverview);
    }

    public redirectToGroups(): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['analytics', 'lerngruppen']);
    }

    public getTimestampSentence(date: string): string {
        return new TimestampSentenceCreator().create(date, this.currentDate);
    }

    public ngOnDestroy(): void {
        this.activeOrganizationId$.unsubscribe();
    }

    public formatPoints(points: number): string {
        return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    }

}
