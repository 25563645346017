import {Component, Input} from '@angular/core';
import {Icons} from '../icon/icon.component';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-filter-button',
    templateUrl: './filter-button.component.html',
    styleUrls: ['./filter-button.component.scss']
})
export class FilterButtonComponent {
    @Input()
    public control: FormControl;

    @Input()
    public title: string;

    @Input()
    public icon: Icons;

}
