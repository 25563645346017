import {Component, Input} from '@angular/core';
import {Popup} from '../popup/Popup';
import {PopupService} from '../popup/popup.service';
import {CancelSavingPopupData} from './CancelSavingPopupData';
import {CancelSavePopupResult} from './CancelSavePopupResult';

@Component({
    selector: 'app-cancel-saving-popup',
    templateUrl: './cancel-saving-popup.component.html',
    styleUrls: ['./cancel-saving-popup.component.scss']
})
export class CancelSavingPopupComponent implements Popup<CancelSavingPopupData> {
    @Input()
    public data: CancelSavingPopupData;

    constructor(private popupService: PopupService) {
    }

    public close(): void {
        this.popupService.close<CancelSavePopupResult>('Close');
    }

    public discard(): void {
        this.popupService.close<CancelSavePopupResult>('Discard');
    }

    public save(): void {
        this.popupService.close<CancelSavePopupResult>('Save');
    }

}
