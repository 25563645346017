import {ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {NavigationService} from '../core/navigation.service';
import {Router} from '@angular/router';
import {mobileMediaMatch} from '../global-variables';
import {OrganizationService} from '../core/organization.service';
import {take} from 'rxjs/operators';
import {OrganizationAdminRestService} from '../core/rest/organization-admin/organization-admin-rest.service';
import {EditorLearningPackageService} from '../core/editor/editor-learning-package.service';
import {GroupService} from '../core/group.service';
import {Subscription} from 'rxjs';
import {AuthUntilUserOrganizationRoleService} from '../core/authentication/auth-until-user-organization-role.service';
import {AuthService} from '../core/authentication/auth.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnDestroy {
    public showOrganizationList: boolean = false;
    public userOrganizations: { id: string; zitadelId: string; name: string; }[];
    public viewModelObservable = new Subscription();

    public mobileQuery: MediaQueryList;
    private readonly mobileQueryListener: () => void;

    constructor(private changeDetectorRef: ChangeDetectorRef,
                public media: MediaMatcher,
                private elementRef: ElementRef,
                public navigationService: NavigationService,
                private router: Router,
                public organizationService: OrganizationService,
                public authService: AuthService,
                private organizationAdminRestService: OrganizationAdminRestService,
                private editorLearningPackageService: EditorLearningPackageService,
                private groupService: GroupService,
                public userRoleService: AuthUntilUserOrganizationRoleService) {
        this.mobileQuery = media.matchMedia(mobileMediaMatch);
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        // IE and Edge support only this! Also not deprecated according to https://github.com/microsoft/TypeScript/issues/32210
        this.mobileQuery.addListener(this.mobileQueryListener);
    }

    public ngOnDestroy(): void {
        this.viewModelObservable.unsubscribe();
    }

    @HostListener('document:click', ['$event'])
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public closeMobileNavigation(event): void {
        if (!this.mobileQuery.matches) {
            // ElementRef can be any
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access, @typescript-eslint/strict-boolean-expressions
            if (!this.elementRef.nativeElement.contains(event.target)) {
                this.showOrganizationList = false;
            }
        }
    }

    public openMobileNavigation(): void {
        this.navigationService.mobileNavigationOpen$.next(true);
    }

    public navigateToHomePage(): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (this.mobileQuery) {
            this.navigationService.mobileNavigationOpen$.next(false);
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['/']);
    }

    public async openOrganizationList(): Promise<void> {
        this.showOrganizationList = !this.showOrganizationList;
        this.viewModelObservable = this.organizationService.activeOrganizationId$.subscribe(activeOrganizationId => {
            this.organizationService.userOrganizations$.pipe(take(1)).subscribe((organizations: { id: string; zitadelId: string; name: string; }[]) => {
                if (organizations !== undefined && organizations.length > 1) {
                    this.userOrganizations = organizations.filter((organization: { id: string; zitadelId: string; name: string; }) => activeOrganizationId !== organization.zitadelId);
                }
            });
        });

    }

    public setActiveOrganization(organizationId: string): void {
        this.organizationService.setActiveOrganization(organizationId);
        this.editorLearningPackageService.cleanActiveLearningPackage();
        this.groupService.cleanActiveGroup();

        this.userRoleService.hasOrganisationAdminRole().pipe(take(1)).subscribe(assertion => {
            if (this.navigationService.organizationAdminNavigation$.value && assertion) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.router.navigate(['/organisation']);
            } else {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.router.navigate(['/']);
            }
            if (this.navigationService.mobileNavigationOpen$.getValue()) {
                this.showOrganizationList = false;
                this.navigationService.mobileNavigationOpen$.next(false);
            }
        });
    }

    public goToOrganisationAdmin(): void {
        this.userRoleService.hasOrganisationAdminRole().pipe(take(1)).subscribe(assertion => {
            if (assertion) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.router.navigate(['organisation']);
            }
        });
    }

    public navigateToDefaultPage(): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises,@typescript-eslint/no-unsafe-member-access
        this.router.navigate(['/']);
    }

    public logout(): void {
        this.authService.removeSelectedUser();
        this.authService.startLogoutFlow();
    }

}
