<app-popup-container>
    <app-popup-content>
        <div class="mm-delete-learning-package-content" i18n>
            Ein gelöschtes Lernpaket kann nicht wiederhergestellt werden. Möchtest du das Lernpaket <strong>{{
            data.learningPackageName}}</strong> definitiv löschen?
        </div>

        <app-error-box
            class="mm-delete-learning-package-error-box"
            *ngIf="errorOccurred" i18n-header
                       header="Das Lernpaket konnte aus folgenden Gründen nicht gelöscht werden:">
            <ul class="mm-delete-learning-package-error-box-message-list">
                <li *ngIf="deleteError.learningPackageInGroup" class="mm-delete-learning-package-error-box-message-list-element" i18n>
                    Das Lernpaket ist einer oder mehreren Lerngruppen zugeordnet. Deaktiviere das Lernpaket für alle
                    Lerngruppen, um dieses zu löschen.
                </li>
                <li *ngIf="deleteError.learningPackageDifferentOrganization"
                    class="mm-delete-learning-package-error-box-message-list-element" i18n>
                    Das Lernpaket ist mindestens einer weiteren Organisation zugeordnet.
                </li>
            </ul>
        </app-error-box>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>
                Abbrechen</app-popup-action-button>
            <app-popup-action-button
                (click)="confirm()"
                type="primary"
                [disabled]="errorOccurred" i18n>
                Löschen</app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
