<div class="micromate-card" *ngIf="organizationAnalyticsOverview !== undefined">
    <div class="micromate-card-header">
        <h1 i18n>Übersicht</h1>
    </div>

    <div class="micromate-scroll-wrapper" *ngIf="organizationAnalyticsOverview.allTime !== undefined">
        <div class="micromate-small-cards ">
            <div class="micromate-small-card micromate-small-card-full-flex-width">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#ballot-check-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title">
                    <p class="micromate-small-card-title-text">{{formatPoints(organizationAnalyticsOverview.allTime?.totalQuizzes ? organizationAnalyticsOverview.allTime.totalQuizzes : 0)}}</p>
                    <p class="micromate-small-card-title-subtext" i18n>beantwortete Fragen</p>
                </div>
            </div>
            <div class="micromate-small-card micromate-small-card-full-flex-width">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#calender-day-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title">
                    <p class="micromate-small-card-title-text">{{getTimestampSentence(organizationAnalyticsOverview.allTime?.latestQuizAnswerDate)}}</p>
                    <p class="micromate-small-card-title-subtext" i18n>wurde die letzte Frage beantwortet</p>
                </div>
            </div>
            <div class="micromate-small-card micromate-small-card-full-flex-width">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#user-friends-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title ">
                    <p class="micromate-small-card-title-text">{{formatPoints(organizationAnalyticsOverview.allTime?.totalLearners ? organizationAnalyticsOverview.allTime.totalLearners : 0)}}</p>
                    <p class="micromate-small-card-title-subtext" i18n>aktive Lernende</p>
                </div>
            </div>
            <div class="micromate-small-card micromate-small-card-full-flex-width micromate-small-card-button"
                 (click)="redirectToGroups()">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#users-class-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title ">
                    <p class="micromate-small-card-title-text">{{formatPoints(organizationAnalyticsOverview.allTime?.totalGroups ? organizationAnalyticsOverview.allTime.totalGroups : 0)}}</p>
                    <p class="micromate-small-card-title-subtext" i18n>Lerngruppen</p>
                </div>
                <div class="micromate-small-card-icon-button">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#chevron-right-light"></use>
                    </svg>
                </div>
            </div>
        </div>

    </div>

    <ng-container *ngIf="organizationAnalyticsOverview.last30Days !== undefined">
        <div class="micromate-card-header micromate-card-header-block">
            <h2 i18n>Letzte 30 Tage</h2>
        </div>

        <div class="micromate-scroll-wrapper">
            <div class="micromate-small-cards ">
                <div class="micromate-small-card micromate-small-card-full-flex-width">
                    <div class="micromate-small-card-icon">
                        <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#ballot-check-light"></use>
                        </svg>
                    </div>
                    <div class="micromate-small-card-title">
                        <p class="micromate-small-card-title-text">{{formatPoints(organizationAnalyticsOverview.last30Days?.totalQuizzes ? organizationAnalyticsOverview.last30Days.totalQuizzes : 0)}}</p>
                        <p class="micromate-small-card-title-subtext" i18n>beantwortete Fragen</p>
                    </div>
                </div>
                <div class="micromate-small-card micromate-small-card-full-flex-width">
                    <div class="micromate-small-card-icon">
                        <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#user-friends-light"></use>
                        </svg>
                    </div>
                    <div class="micromate-small-card-title ">
                        <p class="micromate-small-card-title-text">{{formatPoints(organizationAnalyticsOverview.last30Days?.activeLearners ? organizationAnalyticsOverview.last30Days.activeLearners : 0)}}</p>
                        <p class="micromate-small-card-title-subtext" i18n>aktive Lernende</p>
                    </div>
                </div>
            </div>

        </div>
    </ng-container>
</div>
