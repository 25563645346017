<div class="micromate-card">
    <div class="micromate-card-header">
        <h1>Lernende</h1>
        <div class="micromate-button" *ngIf="!defaultGroup">
            <app-button type="primary"
                        [icon]="'plus-light'"
                        (click)="openAddingLearnerToGroupForm()" i18n>Lernende hinzufügen
            </app-button>
        </div>
    </div>

    <div
        *ngIf="listPagination !== undefined && ((paginationParams.filter.length === 0 && groupLearners.length > 0) || (paginationParams.filter.length > 0)) ">
        <app-table-search [paginationParams]="paginationParams" [navigateUrl]="navigationUrl"
                          [totalDataAmount]="listPagination?.totalDataAmount"></app-table-search>
        <div class="micromate-table" *ngIf="groupLearners !== undefined">
            <div class="table-container table-container-header">
                <ng-container>
                    <div class="row-header" i18n>Name</div>
                    <div class="row-header" i18n>Benutzer</div>
                    <div class="row-header" i18n>Sprache</div>
                    <div class="row-header" i18n></div>
                </ng-container>
            </div>

            <div class="table-container-data" *ngIf="groupLearners.length > 0; else emptyTable" #tableContentRef>
                <div class="table-container table-content" *ngFor="let member of groupLearners">
                    <div class="row row-name">
                        <div class="row-sub micromate-table-link">
                            {{member?.displayName ? member.displayName : '-'}}
                        </div>
                        <div class="row-only-mobile-email">
                            {{member?.identifier ? member.identifier : '-'}}
                        </div>
                    </div>
                    <div class="row row-only-web">{{member?.identifier ? member.identifier : '-'}}</div>
                    <div class="row row-only-web">{{multilanguageService.getLearnerLanguages(member.channels)}}</div>
                    <div class="row group-info">
                        <div class="micromate-group-button" (click)="openLearnerRemovingPopup(member)"
                             *ngIf="!defaultGroup">
                            <app-icon icon="trash-light" class="micromate-group-form-app-icon"></app-icon>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #emptyTable>
                <div class="table-empty-filter-results" i18n>
                    keine Suchresultate
                </div>
            </ng-template>

        </div>
        <app-table-pagination [paginationParams]="paginationParams"
                              [totalDataAmount]="listPagination.totalDataAmount"
                              [navigateUrl]="navigationUrl"></app-table-pagination>
    </div>
    <div class="micromate-empty-table"
         *ngIf="groupLearners !== undefined && groupLearners.length === 0 && paginationParams.filter !== undefined && paginationParams.filter.length === 0">
        Keine Lernenden vorhanden
    </div>
</div>
