import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EditorLearningPackageService} from './editor-learning-package.service';
import {
    FeedbackDto,
    FeedbackRatingType,
    FeedbackStatusType
} from '../../app-editor/quiz-makers/components/feedback-question/FeedbackDto';
import {EditorFeedbackResource} from '../rest/editor/editor-feedback-resource.service';

@Injectable({
    providedIn: 'root'
})
export class EditorFeedbackService {

    constructor(private editorFeedbackResource: EditorFeedbackResource,
                private editorLearningPackageService: EditorLearningPackageService) {
    }

    public getAllIdsForQuestion(questionId: string, onlyOpenFeedback: boolean): Observable<string[]> {
        const currentActiveLearningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        return this.editorFeedbackResource.getQuestionFeedbacks(currentActiveLearningPackageId, questionId, onlyOpenFeedback);
    }

    public getFeedback(feedbackId: string): Observable<FeedbackDto> {
        const currentActiveLearningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        return this.editorFeedbackResource.getFeedback(currentActiveLearningPackageId, feedbackId);
    }

    public async changeFeedbackState(feedbackId: string, state: FeedbackStatusType, rating?: FeedbackRatingType): Promise<void> {
        const currentActiveLearningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        return this.editorFeedbackResource.changeFeedbackState(currentActiveLearningPackageId, feedbackId, state, rating);
    }
}
