import {Component, Input, OnInit} from '@angular/core';
import {ImportWizardService} from './import-wizard.service';
import {Popup} from '../../../components/popup/Popup';

@Component({
    selector: 'app-import-wizard',
    templateUrl: './import-wizard.component.html',
    styleUrls: ['./import-wizard.component.scss']
})
export class ImportWizardComponent implements Popup, OnInit {
    @Input()
    public data: undefined;

    constructor(public importWizardService: ImportWizardService) {
    }

    public ngOnInit(): void {
        this.importWizardService.clear();
    }

}
