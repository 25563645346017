import {OrganizationChannelTypes} from '../core/api-types/organizationChannelTypes';
import {OrganizationAICredential} from '../core/features/OrganizationAICredential';

export class OrganizationAdminSettings {
    constructor(public joinWithOrganizationCode: boolean,
                public organizationCode: string,
                public domainVerification: boolean,
                public activeChannels: OrganizationChannelTypes[],
                public mainChannel: OrganizationChannelTypes,
                public aiCredential: OrganizationAICredential) {
    }
}
