import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FeaturesService} from '../../core/features/features.service';
import {ImportWizardService} from './import-wizards/import-wizard.service';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {PopupService} from '../../components/popup/popup.service';
import {ImportWizardComponent} from './import-wizards/import-wizard.component';
import {Subscription} from 'rxjs';

@Component({
    templateUrl: './learn-package-import.component.html',
    styleUrls: ['./learn-package-import.component.scss']
})
export class LearnPackageImportComponent implements OnInit, OnDestroy {

    private routerSubscription = new Subscription();

    constructor(public featuresService: FeaturesService,
                private router: Router,
                private activeRoute: ActivatedRoute,
                private importWizardService: ImportWizardService,
                private popupService: PopupService) {
    }

    public ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    public async ngOnInit(): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.routerSubscription = this.router.events.subscribe(async events => {
            if (events instanceof NavigationEnd) {
                await this.openPopupPopupIfNeeded();
            }
        });

        await this.openPopupPopupIfNeeded();
    }

    public importRessource(): Promise<boolean> {
        return this.router.navigate(['ressource'], {relativeTo: this.activeRoute});
    }

    public importCSVFile(): Promise<boolean> {
        return this.router.navigate(['csv-datei'], {relativeTo: this.activeRoute});
    }

    public importLearningPackage(): Promise<boolean> {
        return this.router.navigate(['learning-package'], {relativeTo: this.activeRoute});
    }

    public async startRessourceImportPopup(): Promise<void> {
        this.importWizardService.currentStep = 'ressource-question-import';
        this.importWizardService.importMethod = 'ressource';
        await this.startImportPopup();
    }

    public async startCSVFileImportPopup(): Promise<void> {
        this.importWizardService.currentStep = 'csv-question-import';
        this.importWizardService.importMethod = 'csv-file';
        await this.startImportPopup();
    }

    public async startLearningPackageImportPopup(): Promise<void> {
        this.importWizardService.currentStep = 'learning-package-import';
        this.importWizardService.importMethod = 'learning-package';
        await this.startImportPopup();
    }

    private async startImportPopup(): Promise<void> {
        const popupOptions = new PopupOptions('custom', 'closableWithoutEsc', 1000, 600);
        await this.popupService.open(ImportWizardComponent, undefined, popupOptions);
        void this.router.navigate(['.'], {relativeTo: this.activeRoute});
    }

    private async openPopupPopupIfNeeded(): Promise<void> {
        const lastSegmentPath: string = this.activeRoute.snapshot.firstChild?.url[0].path;
        if (lastSegmentPath !== undefined) {
            switch (lastSegmentPath) {
                case 'ressource':
                    await this.startRessourceImportPopup();
                    break;
                case 'csv-datei':
                    await this.startCSVFileImportPopup();
                    break;
                case 'learning-package':
                    await this.startLearningPackageImportPopup();
                    break;
                default:
                    throw new Error(`Unknown path: ${lastSegmentPath}`);
            }
        }
    }
}
