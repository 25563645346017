import {Injectable, RendererFactory2, TemplateRef} from '@angular/core';
import {OverlayComponent} from './overlay.component';
import {OverlayChildComponent} from './overlay-child/overlay-child.component';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged, map, skip} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class OverlayService {
    private overlayComponent: OverlayComponent;
    private isOpen = new BehaviorSubject<boolean>(false);
    public isOpen$ = this.isOpen.asObservable();
    private positionChangeSubscription = new Subscription();

    private documentClickSubscription: () => void;
    private documentClickEscapeSubscription: () => void;
    private documentScroll: boolean = false;
    private documentScrollSubscription: () => void;

    constructor(private renderer2: RendererFactory2) {
    }

    public initialize(popupComponent: OverlayComponent): void {
        this.overlayComponent = popupComponent;
    }

    public open(options: TemplateRef<unknown>, nativeElement: HTMLElement, isAlreadyOpen: boolean): void {
        if (this.isOpen.value) {
            this.close();
        }

        if (!isAlreadyOpen) {
            this.documentScroll = false;
            this.isOpen.next(true);
            const componentRef = this.overlayComponent.viewContainerRef.createComponent(OverlayChildComponent);
            componentRef.instance.contentTemplate = options;
            componentRef.changeDetectorRef.detectChanges();

            this.overlayComponent.setCallingElementSize(nativeElement.getBoundingClientRect());

            const renderer2 = this.renderer2.createRenderer(undefined, undefined);
            this.documentClickSubscription = renderer2.listen('document', 'click', () => this.close());
            this.documentClickEscapeSubscription = renderer2.listen('document', 'keydown.escape', () => this.close());
            // We recognise when page is scrolling, but we close overlay only when position of button change, should be fixed when dynamic positioning of overlay added (depending on the position on the page, overlay displayed above or below the button)
            this.documentScrollSubscription = renderer2.listen('window', 'scroll', () => this.documentScroll = true);

            this.positionChangeSubscription = this.subscribeForPositionChange(nativeElement).subscribe(() => {
                if (!this.documentScroll) {
                    this.close();
                }
            });
        }
    }

    public close(): void {
        this.isOpen.next(false);
        this.documentClickSubscription();
        this.documentScrollSubscription();
        this.documentClickEscapeSubscription();
        this.overlayComponent.viewContainerRef.clear();
        this.positionChangeSubscription.unsubscribe();
    }

    public subscribeForPositionChange(element: HTMLElement): Observable<unknown> {
        return interval(20).pipe(
            map(() => element.getBoundingClientRect()),
            distinctUntilChanged((previousRect: DOMRect, currentRect: DOMRect) =>
                previousRect.left === currentRect.left && previousRect.top === currentRect.top),
            skip(1)
        );
    }
}
