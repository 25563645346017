<div  class="micromate-navigation"
     [ngClass]="{'micromate-app-navigation-mobile-open': mobileQuery.matches && (navigationService.mobileNavigationOpen$ | async),
    'micromate-app-navigation-mobile-close': mobileQuery.matches && !(navigationService.mobileNavigationOpen$ | async)}">

    <div *ngIf="!mobileQuery.matches" class="micromate-app-navigation-web">
        <app-app-navigation class="micromate-app-navigation-options"
                            *ngIf="authService.authentication$ | async">
        </app-app-navigation>
        <div>
            <a [routerLink]="(userRoleService.hasOrganisationAdminRole() | async) ? ['organisation'] : []"
               *ngIf="(organizationService.hasOrganizationId$ | async) !== undefined  &&
                !(navigationService.organizationAdminNavigation$ | async)"
               class="micromate-navigation-button"
               [class.micromate-navigation-button-disable]="!(userRoleService.hasOrganisationAdminRole() | async)"
               (click)="goToOrganisationAdmin()">
                <svg aria-hidden="true" viewBox="0 0 640 512" focusable="false"
                     class="micromate-navidation-logout-icon">
                    <use href="./assets/icons.svg#sitemap-light"></use>
                </svg>
                <div class="micromate-navidation-logout-title"
                     i18n>{{(organizationService.activeOrganization$ | async)?.name}}</div>
            </a>
            <a [routerLink]="['/']"
               *ngIf="(navigationService.organizationAdminNavigation$ | async)"
               class="micromate-navigation-button micromate-app-navigation-option-admin-back-button">
                <svg class="micromate-navidation-logout-icon" viewBox="0 -50 500 612">
                    <use href="./assets/icons.svg#arrow-alt-left-light"></use>
                </svg>
            </a>
            <div *ngIf="(organizationService.userOrganizations$ | async)?.length > 1"
                 class="micromate-navigation-button"
                 (click)="openOrganizationList()"
                 [class.micromate-navigation-button-active]="showOrganizationList">
                <svg aria-hidden="true" viewBox="-50 -25 725 600" focusable="false"
                     class="micromate-navidation-logout-icon">
                    <use href="./assets/icons.svg#repeat-light"></use>
                </svg>
                <div class="micromate-navigation-organizations-list"
                     *ngIf="showOrganizationList && userOrganizations?.length > 0">
                    <div *ngFor="let organization of userOrganizations"
                         class="micromate-navigation-organizations-list-item"
                         (click)="setActiveOrganization(organization.zitadelId)">
                        {{organization.name}}
                    </div>
                </div>
            </div>
            <div class="micromate-navigation-button" (click)="logout()"
                 *ngIf="authService.authentication$ | async">
                <svg aria-hidden="true" viewBox="0 -60 512 512" focusable="false"
                     class="micromate-navidation-logout-icon">
                    <use href="./assets/icons.svg#sign-out-light"></use>
                </svg>
                <div class="micromate-navidation-logout-title" i18n>Log out</div>
            </div>
        </div>
    </div>


    <div *ngIf="mobileQuery.matches" class="micromate-app-navigation-mobile"
         [class.micromate-app-navigation-mobile-sidenav]="(navigationService.mobileNavigationDisplay$ | async) && authService.authentication$ | async">
        <div>
            <div *ngIf="!(navigationService.mobileNavigationOpen$ | async)"
                 class="micromate-app-navidation-hamburger-button"
                 [class.micromate-app-navidation-hamburger-button-organization]="(navigationService.organizationAdminNavigation$ | async)"
                 (click)="openMobileNavigation()">
                <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"
                     class="micromate-app-navidation-hamburger-button-img">
                    <use href="./assets/icons.svg#bars-light"></use>
                </svg>
            </div>
            <div class="micromate-app-navigation-top-bar" *ngIf="(navigationService.mobileNavigationOpen$ | async)">
                <a (click)="navigateToHomePage()" class="micromate-app-navigation-logo">
                    <img src="../../assets/logo-without-text.svg" class="micromate-app-navigation-logo-img">
                    <div class="micromate-app-navigation-logo-title">
                        Micromate
                    </div>
                </a>
                <svg aria-hidden="true" focusable="false" viewBox="0 0 320 512"
                     (click)="navigationService.mobileNavigationOpen$.next(false)"
                     class="micromate-app-navigation-close-button">
                    <use href="./assets/icons.svg#times-light"></use>
                </svg>
            </div>

            <div *ngIf="(navigationService.mobileNavigationOpen$ | async)" class="micromate-app-navigation-content">
                <app-app-navigation class="micromate-app-navigation-options"
                                    *ngIf="authService.authentication$ | async">
                </app-app-navigation>
                <app-page-navigation
                    *ngIf="(navigationService.mobileNavigationDisplay$ | async) &&
                    (authService.authentication$ | async) &&
                    !(navigationService.editorNavigation$ | async)"></app-page-navigation>
                <app-editor-navigation
                    *ngIf="(navigationService.mobileNavigationDisplay$ | async) &&
                    (authService.authentication$ | async) &&
                    (navigationService.editorNavigation$ | async)"></app-editor-navigation>
            </div>
        </div>


        <div *ngIf="(navigationService.mobileNavigationOpen$ | async)">
            <a [routerLink]="(userRoleService.hasOrganisationAdminRole() | async) ? ['organisation'] : []"
               *ngIf="(organizationService.hasOrganizationId$ | async) !== undefined && !(navigationService.organizationAdminNavigation$ | async)"
               class="micromate-navigation-button"
               [class.micromate-navigation-button-disable]="!(userRoleService.hasOrganisationAdminRole() | async)">
                <svg aria-hidden="true" viewBox="0 0 640 512" focusable="false"
                     class="micromate-navidation-logout-icon">
                    <use href="./assets/icons.svg#sitemap-light"></use>
                </svg>
                <div class="micromate-navidation-logout-title"
                     i18n>{{(organizationService.activeOrganization$ | async)?.name}}</div>
            </a>
            <a [routerLink]="['/']"
               *ngIf="(navigationService.organizationAdminNavigation$ | async)"
               class="micromate-navigation-button micromate-app-navigation-option-admin-back-button">
                <svg class="micromate-navidation-logout-icon micromate-navidation-organization-change-mobile"
                     viewBox="0 -50 448 612">
                    <use href="./assets/icons.svg#arrow-alt-left-light"></use>
                </svg>
            </a>

            <div *ngIf="(organizationService.userOrganizations$ | async)?.length > 1"
                 class="micromate-navigation-button"
                 (click)="openOrganizationList()"
                 [class.micromate-navigation-button-active]="showOrganizationList">
                <svg aria-hidden="true" viewBox="-50 -25 725 600" focusable="false"
                     class="micromate-navidation-logout-icon micromate-navidation-organization-change-mobile">
                    <use href="./assets/icons.svg#repeat-light"></use>
                </svg>
                <div class="micromate-navigation-organizations-list"
                     *ngIf="showOrganizationList && userOrganizations?.length > 0">
                    <div *ngFor="let organization of userOrganizations"
                         class="micromate-navigation-organizations-list-item"
                         (click)="setActiveOrganization(organization.zitadelId)">
                        {{organization.name}}
                    </div>
                </div>
            </div>
            <button (click)="logout()"
                    *ngIf="authService.authentication$ | async"
                    class="micromate-navigation-logout">
                <svg aria-hidden="true" class="micromate-navidation-logout-icon" focusable="false"
                     viewBox="0 0 600 600">
                    <use href="./assets/icons.svg#sign-out-light"></use>
                </svg>
                <div class="micromate-navidation-logout-title" i18n>Log out</div>
            </button>
        </div>
    </div>
</div>
