<div class="micromate-card micromate-card-editor-question">
    <div class="micromate-card-mobile-title">
        <div class="micromate-button micromate-editor-quiz-back-button">
            <div class="come-back-micromate-buttont-text" (click)="quizMakerService.openQuizzesOverview()">
                <svg aria-hidden="true" focusable="false" viewBox="0 0 384 512"
                     class="micromate-buttont-text-icon">
                    <use href="./assets/icons.svg#chevron-left-light"></use>
                </svg>
            </div>
        </div>
        <div class="micromate-editor-quiz-type">
            <h1 i18n>Frage</h1>
            <app-icon *ngIf="quizTypeData !== undefined"
                      class="micromate-editor-quiz-type-icon"
                      [title]="quizTypeData.title"
                      [icon]="quizTypeData.icon"></app-icon>
        </div>

        <div class="micromate-editor-question-actions" *ngIf="activeTranslationQuiz !== undefined">
            <editor-drop-down class="editor-drop-down"
                              [disabled]="isDisabled"
                              [language]="activeTranslationQuiz.language"
                              [translationNotExist]="!translationNotExist((editorQuizService.activeQuiz$ | async))"
                              [showAnalytics]="true"></editor-drop-down>
        </div>
    </div>

    <div class="mm-editor-question-card">
        <div class="mm-editor-question-card-actions">
            <app-editor-language-switcher class="mm-editor-question-card-language-switcher"
                                          [disabled]="isDisabled"
                                          *ngIf="(editorQuizService.newQuiz$ | async) === undefined"
                                          [currentLanguage]="activeTranslationQuiz?.language"
                                          [mainLanguage]="activeTranslationQuiz?.mainLanguage"
                                          (activeLanguage)="changeLanguage($event)"></app-editor-language-switcher>

            <div class="mm-editor-question-card-actions-buttons">
                <div class="mm-editor-question-card-actions-buttons-content"
                     [class.mm-editor-question-actions-new-question]="(editorQuizService.newQuiz$ | async) !== undefined"
                     *ngIf="activeTranslationQuiz !== undefined">
                    <app-button type="ai" (click)="startPasteFlow()"
                                icon="ai"
                                *ngIf="(featuresService.hasFeatureAI() | async) && (editorQuizService.newQuiz$ | async) !== undefined"
                                i18n>Frage aus Text
                    </app-button>
                    <app-button type="ai" [action]="translate"
                                *ngIf="(featuresService.hasFeatureAI() | async) && translationNotExist((editorQuizService.activeQuiz$ | async)) && !alreadyTranslated"
                                i18n>Übersetzen
                    </app-button>
                    <app-tags-selector
                        *ngIf="activeTranslationQuiz?.language === activeTranslationQuiz?.mainLanguage && !translationNotExist(editorQuizService.activeQuiz$ | async)  && availableTags !== undefined"
                        [availableTags$]="editorLearningPackageService.learningPackageAvailableTags$"
                        [(selectedAvailableTags)]="selectedAvailableTags"
                        [(selectedNewTags)]="selectedNewTags"></app-tags-selector>
                </div>
            </div>

        </div>

        <div class="micromate-editor-tags-question">
            <app-tags-list
                *ngIf="availableTags !== undefined"
                [availableTags]="availableTags"
                [(selectedAvailableTags)]="selectedAvailableTags"
                [(selectedNewTags)]="selectedNewTags"
                [allowRemoveTag]="activeTranslationQuiz?.language === activeTranslationQuiz?.mainLanguage && !translationNotExist(editorQuizService.activeQuiz$ | async)"></app-tags-list>
        </div>

        <div *ngIf="quiz !== undefined" class="micromate-editor-quiz-creator">
            <form [formGroup]="formData" id="ngFormOrganization" class="micormate-form-quiz">

                <div class="micormate-form-quiz-element-question"
                     (dragenter)="questionHovering = true"
                     (dragleave)="questionHovering = false"
                     (drop)="questionHovering = false"
                     appDragAndDrop
                     (files)="filesDropped($event, 'question')">
                    <div class="micromate-editor-drag-area"
                         *ngIf="!editorQuizzesService.imageAndMediaPopupOpen.value && (dragAndDropService.dragOnPage$ | async)"
                         [class.micromate-editor-drag-area-active]="questionHovering" i18n>
                        Bild hier ablegen
                    </div>
                    <div class="micormate-form-quiz-element-question-input">
                        <div class="micormate-form-quiz-element micormate-form-quiz-element-question-textarea">
                        <textarea id="question" autosize type="text"
                                  formControlName="question"
                                  placeholder="Frage"
                                  class="micormate-form-quiz-maker-textarea"
                                  (keydown.enter)="keytab($event)"
                                  (keydown.shift.enter)="keytab($event)"
                                  [class.micormate-form-input-validation-error]="validator.validateInput(formData.controls.question)">
                        </textarea>
                        </div>

                        <app-micromate-editor-image-drop-down
                            class="micromate-editor-drop-down"
                            [disabled]="isDisabled"
                            [class.micromate-editor-drop-down-preview]="quizQuestionMedia?.imageUrl !== undefined || quizQuestionMedia?.imageFile !== undefined"
                            [media]="quizQuestionMedia"
                            [fileDrop]="questionFileDrop"
                            (moveMedia)="removeMedia('question')"
                            (openImageAndMediaPopup)="openImageAndMediaPopup('question')">
                        </app-micromate-editor-image-drop-down>
                    </div>

                    <div>
                        <div *ngIf="validator.validateInputRequired(formData.controls.question)"
                             class="micormate-form-validation-error" i18n>
                            Fragetext ist erforderlich
                        </div>
                        <div *ngIf="validator.validateInputLength(formData.controls.question)"
                             class="micormate-form-validation-error" i18n>
                            Maximal {{validator.QUESTION_MAX_LENGTH}} Zeichen
                        </div>
                    </div>
                </div>

                <div #answersForm class="micormate-form-quiz-element micormate-form-quiz-answers"
                     formArrayName="answers">
                    <div *ngFor="let answer of getAnswersControls(); let i = index" [formGroupName]="i"
                         class="micormate-form-quiz-maker-answers">
                        <div class="micormate-form-quiz-answer-element-row">
                            <div class="micormate-form-quiz-answer-element">
                                <div class="micormate-form-quiz-answer-element-icon">
                                    <input [id]="'Checkbox_'+ i" type="checkbox" tabindex="-1"
                                           (change)="!isDisabled && setAsCorrectAnswer(i)"
                                           formControlName="isCorrectAnswer"
                                           [class.micromate-editor-form-quiz-checkbox-active]="answer.get('isCorrectAnswer')?.value">
                                </div>
                            </div>

                            <div class="micormate-form-quiz-answer-element micormate-form-quiz-answer-element-text"
                                 [class.micormate-form-quiz-answer-element-text-remove-button]="getAnswersControls().length <= 3">
                       <textarea autosize type="text" formControlName="answerText"
                                 class="micormate-form-quiz-maker-textarea"
                                 [id]="'Answar_'+ i" placeholder="Antwort"
                                 (keydown.enter)="keytab($event)"
                                 (keydown.shift.enter)="keytab($event)"
                                 [class.micormate-form-input-validation-error]="validator.validateInput(answer.get('answerText'))"></textarea>

                                <div
                                    class="micormate-form-quiz-answer-element-validation-text micormate-form-quiz-answer-element-validation-mobile"
                                    [class.micormate-form-quiz-answer-element-validation-text-explanation]="validator.validateInput(answer.get('answerText'))">
                                    <div
                                        *ngIf="validator.validateInputRequired(answer.get('answerText'))"
                                        class="micormate-form-validation-error" i18n>
                                        Antworttext ist erforderlich
                                    </div>
                                    <div *ngIf="validator.validateInputLength(answer.get('answerText'))"
                                         class="micormate-form-validation-error" i18n>
                                        Maximal {{validator.ANSWER_MAX_LENGTH}} Zeichen
                                    </div>
                                    <div *ngIf="validator.validateDuplicatedAnswer(formData, answer.value.answerText)"
                                         class="micormate-form-validation-error" i18n>
                                        Antwort bereits vorhanden
                                    </div>
                                </div>

                            </div>

                            <div class="micormate-form-quiz-answer-element micormate-form-quiz-answer-explanation"
                                 [class.micormate-form-quiz-answer-explanation-button]="answer.value?.explanation === undefined">
                                <div class="micormate-form-quiz-answer-explanation-button"
                                     [class.micormate-form-quiz-answer-explanation-button-disabled]="isDisabled"
                                     *ngIf="answer.value?.explanation === undefined"
                                     (click)="!isDisabled && addExplanationToAnswer(i)">
                                    <svg aria-hidden="true" focusable="false" viewBox="0 0 352 512"
                                         class="micromate-organization-info-icon">
                                        <use href="./assets/icons.svg#lightbulb-exclamation-light"></use>
                                    </svg>
                                </div>
                                <textarea id="explanation" autosize type="text" formControlName="explanation"
                                          *ngIf="answer.value?.explanation !== undefined"
                                          [id]="'Explanation_'+ i"
                                          class="micormate-form-quiz-maker-textarea micromate-editor-quiz-explanation"
                                          placeholder="Erklärung zur Frage"
                                          (keydown.shift.enter)="keytab($event)"
                                          [class.micormate-form-input-validation-error]="validator.validateInput(answer.get('explanation'))"></textarea>
                                <div
                                    class="micormate-form-quiz-answer-element-validation-text micormate-form-quiz-answer-element-validation-mobile"
                                    *ngIf="validator.validateInputLength(answer.get('explanation'))">
                                    <div *ngIf="validator.validateInputLength(answer.get('explanation'))"
                                         class="micormate-form-validation-error" i18n>
                                        Maximal {{validator.EXPLANATION_MAX_LENGTH}} Zeichen
                                    </div>
                                </div>
                            </div>

                            <div class="micormate-form-quiz-maker-remove-answer-element"
                                 *ngIf="getAnswersControls().length > 3">
                                <div class="micormate-form-quiz-maker-remove-answer"
                                     (click)="removeAnswer(i)">
                                    <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"
                                         class="micromate-organization-info-icon">
                                        <use href="./assets/icons.svg#times-light"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div
                            class="micormate-form-quiz-answer-element-validation-row micormate-form-quiz-answer-element-validation-web">
                            <div class="micormate-form-quiz-answer-element-validation-text"
                                 [class.micormate-form-quiz-answer-element-validation-text-explanation]="answer.get('answerText')?.errors">
                                <div
                                    *ngIf="validator.validateInputRequired(answer.get('answerText')) && answer.get('answerText').touched"
                                    class="micormate-form-validation-error" i18n>
                                    Antworttext ist erforderlich
                                </div>
                                <div *ngIf="validator.validateInputLength(answer.get('answerText'))"
                                     class="micormate-form-validation-error" i18n>
                                    Maximal {{validator.ANSWER_MAX_LENGTH}} Zeichen
                                </div>
                                <div *ngIf="validator.validateDuplicatedAnswer(formData, answer.value.answerText)"
                                     class="micormate-form-validation-error" i18n>
                                    Antwort bereits vorhanden
                                </div>
                            </div>
                            <div
                                class="micormate-form-quiz-answer-element-validation-text micormate-form-quiz-answer-element-validation-explanation"
                                *ngIf="validator.validateInputLength(answer.get('explanation'))">
                                <div *ngIf="validator.validateInputLength(answer.get('explanation'))"
                                     class="micormate-form-validation-error" i18n>
                                    Maximal {{validator.EXPLANATION_MAX_LENGTH}} Zeichen
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="micormate-form-quiz-maker-new-answers" *ngIf="!disableNewAnswerInput && !isDisabled"
                         [class.micormate-form-quiz-maker-new-answers-close-button]="getAnswersControls().length > 3">
                        <div class="micormate-form-quiz-element micormate-form-quiz-element-next-answer">
                       <textarea autosize type="text" [formControl]="newAnswer"
                                 class="micormate-form-quiz-maker-textarea"
                                 placeholder="Neue Antwort"></textarea>
                        </div>
                    </div>


                    <div *ngIf="!validator.validateIfCorrectAnswer(formData)"
                         class="micormate-form-validation-error micormate-form-validation-error-quiz-correct-message"
                         i18n>
                        Mindestens eine Antwort muss als korrekt markiert werden.
                    </div>
                </div>

                <div class="micormate-form-quiz-element-question"
                     (dragenter)="explanationHovering = true"
                     (dragleave)="explanationHovering = false"
                     (drop)="explanationHovering = false"
                     appDragAndDrop
                     (files)="filesDropped($event, 'explanation')">
                    <div class="micromate-editor-drag-area"
                         *ngIf="!editorQuizzesService.imageAndMediaPopupOpen.value && (dragAndDropService.dragOnPage$ | async)"
                         i18n
                         [class.micromate-editor-drag-area-active]="explanationHovering">
                        Bild hier ablegen
                    </div>
                    <div class="micormate-form-quiz-element-question-input">
                        <div class="micormate-form-quiz-element micormate-form-quiz-element-single-explanation">
                            <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"
                                 class="micormate-form-quiz-element-single-explanation-icon">
                                <use href="./assets/icons.svg#lightbulb-exclamation-light"></use>
                            </svg>
                        </div>

                        <app-micromate-editor-image-drop-down
                            class="micromate-editor-drop-down"
                            [disabled]="isDisabled"
                            [class.micromate-editor-drop-down-preview]="quizExplanationMedia?.imageUrl !== undefined || quizExplanationMedia?.imageFile !== undefined"
                            [media]="quizExplanationMedia"
                            [fileDrop]="explanationFileDrop"
                            (moveMedia)="removeMedia('explanation')"
                            (openImageAndMediaPopup)="openImageAndMediaPopup('explanation')">
                        </app-micromate-editor-image-drop-down>
                    </div>
                </div>

                <app-advanced-info [sourceUrl]="formData.controls['sourceUrl']"
                                   [sourceLabel]="formData.controls['sourceLabel']">
                </app-advanced-info>
            </form>


            <div class="micromate-editor-quiz-creator-buttons">
                <app-button type="secondary"
                            [disabled]="!(editorQuizzesService.formDataChanged | async)"
                            (click)="cancelQuiz()">Änderungen verwerfen
                </app-button>
                <div class="micromate-editor-quiz-creator-save-buttons">
                    <app-button type="primary"
                                [disabled]="!(editorQuizzesService.formDataChanged | async) || !validator.validateFullQuiz(formData)"
                                (keydown.enter)="saveQuiz(formData)"
                                (click)="saveQuiz(formData)">Speichern
                    </app-button>
                    <app-button type="primary"
                                [disabled]="!(editorQuizzesService.formDataChanged | async) || !validator.validateFullQuiz(formData)"
                                (keydown.enter)="saveAndCreateNevQuiz(formData)"
                                (click)="saveAndCreateNevQuiz(formData)">Speichern und Neu
                    </app-button>
                </div>
            </div>

        </div>
    </div>

    <app-feedback-question *ngIf="(editorQuizService.newQuiz$ | async) === undefined"></app-feedback-question>
</div>

