import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss']
})
export class IconButtonComponent {

    @Input()
    public type: 'primary' | 'secondary' | 'secondary-dark' = 'primary';

    @Input()
    public icon: 'chevron-left-light' | 'chevron-right-light';

    @Input()
    public disabled: boolean = false;
}
