<div class="mm-popup-wizard-footer"
     [class.mm-popup-wizard-footer-single-end-button]="!isBackVisible && isForwardVisible && !hasCustomContent">
    <ng-content></ng-content>
    <app-popup-action-button
        (click)="navigateBackClicked.emit()"
        type="secondary"
        *ngIf="isBackVisible" i18n>
        Zurück
    </app-popup-action-button>
    <app-popup-action-button
        (click)="navigateForwardClicked.emit()"
        *ngIf="isForwardVisible"
        [disabled]="isForwardDisabled">
        {{forwardText}}</app-popup-action-button>
</div>
