import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-advanced-info',
    templateUrl: './advanced-info.component.html',
    styleUrls: ['./advanced-info.component.scss']
})
export class AdvancedInfoComponent {
    @Input()
    public sourceUrl: FormControl<string>;

    @Input()
    public sourceLabel: FormControl<string>;
}
