<div class="micromate-footer"
     [class.micromate-single-end-button]="!isBackVisible && isForwardVisible && !hasCustomContent">
    <ng-content></ng-content>
    <app-popup-action-button (click)="navigateBackClicked.emit()" type="secondary" *ngIf="isBackVisible" i18n>Zurück
    </app-popup-action-button>
    <app-popup-action-button
        class="mm-popup-action-button-forward"
        (click)="navigateForwardClicked.emit()" *ngIf="isForwardVisible" [disabled]="isForwardDisabled"
        [style.width.px]="forwardWidth !== undefined ? forwardWidth : null">
        {{forwardText}}</app-popup-action-button>
</div>
