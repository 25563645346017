import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {
    FeedbackCategoryType,
    FeedbackDto,
    FeedbackRatingType,
    FeedbackStatusType
} from '../quiz-makers/components/feedback-question/FeedbackDto';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FeedbackAcceptedDto} from './FeedbackAcceptedDto';

@Component({
    selector: 'app-feedback-preview',
    templateUrl: './feedback-preview.component.html',
    styleUrls: ['./feedback-preview.component.scss'],
    animations: [
        trigger('widthAnimation', [
            state('expanded', style({
                width: '72px'
            })),
            state('collapsed', style({
                width: '0'
            })),
            transition('collapsed => expanded', [
                animate('0.3s ease-in-out')
            ])
        ])
    ]
})
export class FeedbackPreviewComponent implements OnChanges {
    @Input()
    public feedback: FeedbackDto;

    @Output()
    public reopenClicked = new EventEmitter<string>();

    @Output()
    public acceptClicked = new EventEmitter<FeedbackAcceptedDto>();

    public showRating = false;

    constructor(public multilanguageService: MultilanguageService) {
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['feedback'] !== undefined) {
            this.showRating = false;
        }
    }

    public getFeedbackTopic(feedbackCategory: FeedbackCategoryType): string {
        switch (feedbackCategory) {
            case FeedbackCategoryType.QuestionFaulty:
                return $localize`Die Aufgabe ist fehlerhaft`;
            case FeedbackCategoryType.QuestionUnintelligible:
                return $localize`Die Aufgabe ist unverständlich`;
            case FeedbackCategoryType.QuestionSpelling:
                return $localize`Diese Aufgabe enthält Rechtschreibfehler`;
            case FeedbackCategoryType.QuestionIdea:
                return $localize`Ich habe eine Idee oder Anmerkung`;
            case FeedbackCategoryType.LearningPackageQuestionSuggestion:
                return $localize`Konkrete Frage vorschlagen`;
            case FeedbackCategoryType.LearningPackageTopicSuggestion:
                return $localize`Neues Inhaltsthema vorschlagen`;
            case FeedbackCategoryType.LearningPackageFeedback:
                return $localize`Feedback zum Lernpaket geben`;
            default:
                throw new Error(`Unknown feedback category ${feedbackCategory} in FeedbackPreviewComponent`);
        }
    }

    public reopenFeedback(): void {
        this.reopenClicked.emit(this.feedback.id);
    }

    public acceptFeedback(): void {
        if (this.feedback.rating === undefined) {
            this.showRating = true;
        } else {
            this.acceptClicked.emit(new FeedbackAcceptedDto(this.feedback.id));
        }
    }

    public setPositiveRanking(): void {
        this.acceptClicked.emit(new FeedbackAcceptedDto(this.feedback.id, FeedbackRatingType.Positive));
        this.showRating = false;
    }

    public setNegativeRanking(): void {
        this.acceptClicked.emit(new FeedbackAcceptedDto(this.feedback.id, FeedbackRatingType.Negative));
        this.showRating = false;
    }

    public isFeedbackStatusOpen(): boolean {
        return this.feedback.status === FeedbackStatusType.Open;
    }

    public isFeedbackStatusAccept(): boolean {
        return this.feedback.status === FeedbackStatusType.Accept;
    }
}
