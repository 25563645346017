<div *ngIf="numberOfPages !== undefined && numberOfPages > 0">
    <div class="mm-pagination-page-switcher ">
        <div *ngIf="title !== undefined" class="mm-pagination-pages"
             [style.order]="testPosition === 'start' ? '1' : '3'" i18n>
            {{title}} {{pageNumber}} von {{numberOfPages}}
        </div>
        <div *ngIf="title === undefined" class="mm-pagination-pages"
             [style.order]="testPosition === 'start' ? '1' : '3'" i18n>
            {{pageNumber}} von {{numberOfPages}}
        </div>
        <div class="mm-pagination-pages-mobile"
             [style.order]="testPosition === 'start' ? '1' : '3'" i18n>
            {{pageNumber}} / {{numberOfPages}}
        </div>
        <div class="mm-pagination-page-switcher-button"
             (click)="pageNumber !== 1 && redirectToNext(1)"
             [class.mm-padding-page-switcher-button-disable]="pageNumber === 1"
             [style.order]="testPosition === 'start' ? '2' : '1'"
             title="erste Seite">
            <app-icon icon="chevron-double-left-light"
                      class="mm-pagination-page-switcher-button-icon"></app-icon>
        </div>
        <div class="mm-pagination-page-switcher-button"
             (click)="pageNumber !== 1 && redirectToNext((pageNumber - 1))"
             [class.mm-padding-page-switcher-button-disable]="pageNumber === 1"
             [style.order]="testPosition === 'start' ? '3' : '2'"
             title="vorherige Seite">
            <app-icon icon="chevron-left-light"
                      class="mm-pagination-page-switcher-button-icon"></app-icon>
        </div>
        <div class="mm-pagination-page-switcher-button"
             (click)="pageNumber !== numberOfPages && redirectToNext((pageNumber + 1))"
             [class.mm-padding-page-switcher-button-disable]="pageNumber === numberOfPages"
             [style.order]="'4'"
             title="nächste Seite">
            <app-icon icon="chevron-right-light"
                      class="mm-pagination-page-switcher-button-icon"></app-icon>
        </div>
        <div class="mm-pagination-page-switcher-button"
             (click)="pageNumber !== numberOfPages && redirectToNext(numberOfPages)"
             [class.mm-padding-page-switcher-button-disable]="pageNumber === numberOfPages"
             [style.order]="'4'"
             title="letzte Seite">
            <app-icon icon="chevron-double-right-light"
                      class="mm-pagination-page-switcher-button-icon"></app-icon>
        </div>
    </div>
</div>
