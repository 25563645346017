import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LearnerResource} from '../../../core/rest/learner-resource.service';
import {OrganizationResources} from '../../../core/rest/organization-resources.service';
import {AdminService} from '../../../core/admin/admin.service';
import {PopupService} from '../../../components/popup/popup.service';
import {Popup} from '../../../components/popup/Popup';
import {RenameOrganizationPopupData} from './RenameOrganizationPopupData';
import {OrganizationNameFormGroup} from './OrganizationNameFormGroup';
import {OrganizationRenamePopupResult} from './OrganizationRenamePopupResult';
import {ValidationError} from '../../../core/api-types/ValidationError';

@Component({
    selector: 'app-rename-organization',
    templateUrl: './rename-organization.component.html',
    styleUrls: ['./rename-organization.component.scss']
})
export class RenameOrganizationComponent implements Popup<RenameOrganizationPopupData>, OnInit {
    @Input()
    public data: RenameOrganizationPopupData;

    public isNameValid = true;

    public formData = new FormGroup<OrganizationNameFormGroup>({
        name: new FormControl<string>('', [Validators.required, Validators.maxLength(50)])
    });

    constructor(private learnerRestService: LearnerResource,
                private organizationRestService: OrganizationResources,
                private adminService: AdminService,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.formData.controls.name.setValue(this.data.name);
        this.formData.valueChanges.subscribe(() => this.isNameValid = true);
    }

    public renameOrganization: () => Promise<void> = async () => {
        return new Promise(resolve => {
            const newName = this.formData.controls.name.getRawValue().trim();
            this.organizationRestService.renameOrganization(this.data.organizationId, newName).subscribe(
                () => {
                    this.popupService.close<OrganizationRenamePopupResult>();
                    this.clearForm();
                    resolve();
                },
                (error: Error) => {
                    if (error instanceof ValidationError) {
                        if (error.validationErrors.some(data => data.code === 'alreadyUsedName')) {
                            this.isNameValid = false;
                            resolve();
                            return;
                        }
                    }
                    this.popupService.close<OrganizationRenamePopupResult>('renameError');
                    this.clearForm();
                    resolve();
                });
        });
    };

    public cancel(): void {
        this.popupService.close();
    }

    private clearForm(): void {
        this.formData.controls['name'].setValue('');
    }
}
