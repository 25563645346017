import {Injectable} from '@angular/core';
import {OrganizationService} from '../organization.service';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ResourceHelper {
    public activeOrganizationUrl$: Observable<string>;

    constructor(private organizationService: OrganizationService) {
        this.activeOrganizationUrl$ = organizationService.activeOrganizationId$.pipe(map(organizationId => `${environment.serverUrl}/api/website/organization/${organizationId}`));
    }
}
