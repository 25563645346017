import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, of} from 'rxjs';
import {EditorQuestionService} from './editor-question.service';
import {shareReplay, switchMap, take} from 'rxjs/operators';
import {EditorLearningPackageService} from './editor-learning-package.service';
import {ActivationStart, Router} from '@angular/router';
import {QuizMediaEditor} from './types/QuizMediaEditor';
import {QuestionParamsFormGroupData} from '../../app-editor/learnpackages-quizzes/QuestionParamsFormGroupData';
import {QuestionParamsData} from '../../app-editor/learnpackages-quizzes/QuestionParamsData';
import {CancelSavingPopupData} from '../../components/cancel-saving-popup/CancelSavingPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {CancelSavePopupResult} from '../../components/cancel-saving-popup/CancelSavePopupResult';
import {CancelSavingPopupComponent} from '../../components/cancel-saving-popup/cancel-saving-popup.component';
import {QuizTranslation} from '../api-types/quizTranslation';
import {PopupService} from '../../components/popup/popup.service';
import {EditorResource} from '../rest/editor-resource.service';
import {ToastrService} from 'ngx-toastr';
import {ImageAndMediaPopupData} from '../../app-editor/image-and-media-popup/ImageAndMediaPopupData';
import {ImageAndMediaPopupComponent} from '../../app-editor/image-and-media-popup/image-and-media-popup.component';

@Injectable({
    providedIn: 'root'
})
export class EditorQuestionsService {
    public quizSaved = new BehaviorSubject<boolean>(true);
    public editorNavigationClicked = new BehaviorSubject<string>(undefined);
    public releasedVersion = new BehaviorSubject<string>(undefined);
    public newQuizPage = new BehaviorSubject<boolean>(false);

    public editingFormData = new BehaviorSubject<FormData>(undefined);
    public editingFormDataValid = new BehaviorSubject<boolean>(undefined);

    public quizQuestionMediaEditor = new BehaviorSubject<QuizMediaEditor>(undefined);
    public quizExplanationMediaEditor = new BehaviorSubject<QuizMediaEditor>(undefined);

    public quizQuestionMediaEditorChanged = new BehaviorSubject<boolean>(false);
    public quizExplanationMediaEditorChanged = new BehaviorSubject<boolean>(false);

    public formDataChanged = new BehaviorSubject<boolean>(false);
    public imageAndMediaPopupOpen = new BehaviorSubject<boolean>(false);

    public tagsChanged = new BehaviorSubject<boolean>(false);
    public tagsChanged$ = this.tagsChanged.asObservable;

    public filters = new BehaviorSubject<{ filterText: string; sort: string; filterLanguage?: string; language?: string; }>(undefined);

    constructor(private editorQuizService: EditorQuestionService,
                private editorLearningPackageService: EditorLearningPackageService,
                private router: Router,
                private popupService: PopupService,
                private editorService: EditorResource,
                private toastr: ToastrService) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.routeConfig.path === 'neue-frage') {
                    this.newQuizPage.next(true);
                } else {
                    if (this.newQuizPage.value) {
                        this.newQuizPage.next(false);
                    }
                }
            }
        });

        this.editorQuizService.newQuiz$.subscribe(newQuiz => {
            if (newQuiz !== undefined) {
                this.setQuestionDataChanged(true);
            }
        });
    }

    public quizzesOverviewFullScreen$ = combineLatest([this.editorQuizService.selectedQuizId$, this.newQuizPage])
        .pipe(
            switchMap(([selectedQuizId, newQuizPageStatus]: [string, boolean]) => {
                return of(!newQuizPageStatus && selectedQuizId === undefined);
            }), shareReplay(1));

    public async checkIfQuizIsSaved(url?: string): Promise<boolean> {
        if (this.quizSaved.value) {
            this.filters.next(undefined);
            return true;
        }
        await this.openCancelQuestion(url);
        return false;
    }

    public setEditingQuizFormData(data: FormData, valid?: boolean): void {
        this.editingFormData.next(data);
        this.editingFormDataValid.next(valid);
    }

    public setQuestionQuizMediaEditor(data: QuizMediaEditor): void {
        const quizMediaEditor = new QuizMediaEditor(data.quizElement, data.type, data.mediaUrl, data.imageUrl, data.imageFile);
        this.quizQuestionMediaEditor.next(quizMediaEditor);
    }

    public setExplanationQuizMediaEditor(data: QuizMediaEditor): void {
        const quizMediaEditor = new QuizMediaEditor(data.quizElement, data.type, data.mediaUrl, data.imageUrl, data.imageFile);
        this.quizExplanationMediaEditor.next(quizMediaEditor);
    }

    public getQuestionQuizMediaEditor(): QuizMediaEditor {
        return this.quizQuestionMediaEditor.value;
    }

    public getExplanationQuizMediaEditor(): QuizMediaEditor {
        return this.quizExplanationMediaEditor.value;
    }

    public getQueryParams(data: QuestionParamsFormGroupData, learningPackageHasTranslation: boolean): QuestionParamsData {
        const queryParams = this.createQuestionParamsData(data, learningPackageHasTranslation);
        this.filters.next(queryParams);
        return queryParams;
    }

    public createQuestionParamsData(data: QuestionParamsFormGroupData, learningPackageHasTranslation: boolean): QuestionParamsData {
        const queryParams: QuestionParamsData = {
            // NULL allow to remove parameter from url
            // eslint-disable-next-line no-null/no-null, @typescript-eslint/strict-boolean-expressions
            filterText: !data.filterText || data.filterText.length === 0 ? null : data.filterText,
            sort: data.sort,
            filterFeedback: data.filterFeedback === true ? 'active' : 'inactive'
        };
        if (learningPackageHasTranslation) {
            queryParams.filterLanguage = data.filterLanguage === true ? 'active' : 'inactive';
        }
        queryParams.tags = data.tags !== undefined && data.tags.length > 0 ? data.tags.map(tag => tag.id) : undefined;
        return queryParams;
    }

    public setQuestionDataChanged(value: boolean): void {
        this.formDataChanged.next(value);
    }

    public async openCancelQuestion(url?: string): Promise<void> {
        const cancelSavingPopupData = new CancelSavingPopupData(this.editingFormDataValid.value);
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<CancelSavingPopupData, CancelSavePopupResult>(CancelSavingPopupComponent, cancelSavingPopupData, popupOptions);
        if (popupServiceResult === 'Save') {
            this.saveQuestion(url);
        } else if (popupServiceResult === 'Discard') {
            this.discardQuestion(url);
        }
    }

    private discardQuestion(url?: string): void {
        this.editorLearningPackageService.selectedLearningPackageId.pipe(take(1)).subscribe(learningPackageId => {
            this.redirect(learningPackageId, url);
        });
    }


    private saveQuestion(url?: string): void {
        this.editorQuizService.activeTranslationQuiz$.pipe(take(1)).subscribe((activeTranslationQuiz: QuizTranslation) => {
            const editingQuizData = this.editingFormData.getValue();
            if (activeTranslationQuiz !== undefined && editingQuizData !== undefined) {
                this.editorService.uploadQuiz(activeTranslationQuiz.leaningPackageId, editingQuizData).then(() => {
                    this.toastr.success('Die Frage wurde gespeichert');
                    this.editorLearningPackageService.selectedLearningPackageId.next(activeTranslationQuiz.mainLeaningPackageId);
                    this.setEditingQuizFormData(undefined, undefined);
                    this.redirect(activeTranslationQuiz.mainLeaningPackageId, url);
                }).catch(() => this.toastr.error('Könnte nicht gespeichert werden'));
            }
        });
    }

    private redirect(learningPackageId: string, url?: string): void {
        this.setQuestionDataChanged(false);
        this.quizSaved.next(true);
        const queryParams = this.filters.value;
        if (url !== undefined && url.length > 0) {
            const targetUrl = url.split('?');
            void this.router.navigate([targetUrl[0]], {
                queryParams: {...queryParams, language: this.readLanguageFromUrl(url)}
            });
        } else {
            void this.router.navigate([`/editor/lernpakete/${learningPackageId}/fragen`], {
                queryParams: queryParams
            });
        }
    }

    private readLanguageFromUrl(url: string): string {
        const matchResult = url.match(/language=(?<language>.{5})/);
        // eslint-disable-next-line no-null/no-null
        if (matchResult === null) {
            return undefined;
        }
        return matchResult.groups.language;
    }

    public openImageAndMediaPopup(type: 'question' | 'explanation'): void {
        const popupOptions = new PopupOptions('custom', 'notClosable', 1000, 450);
        const imageAndMediaPopupData = new ImageAndMediaPopupData(type);
        void this.popupService.open<ImageAndMediaPopupData>(ImageAndMediaPopupComponent, imageAndMediaPopupData, popupOptions);
    }
}
