import {VideoDisplay, VideoDisplayType, VideoFormat, VideoFormatType} from './VideoFormat';
import {VideoLink} from './VideoLink';

export class GoogleDriveVideo {
    public static TYPE: VideoFormatType = VideoFormat.GOOGLE_DRIVE;
    public static DISPLAY: VideoDisplayType = VideoDisplay.IFRAME;


    public static createVideoLink(): VideoLink {
        return new VideoLink(GoogleDriveVideo.TYPE, GoogleDriveVideo.DISPLAY);
    }

    public static isVideoFormat(url: string): boolean {
        const regex = new RegExp('^(https?\\:\\/\\/)?((www\\.)?drive\\.google\\.com)\\/.+$');
        return regex.test(url);
    }

    public static crateVideoLink(media: string): string {
        const regExp = /^.*(drive\.google\.com\/file\/d\/)(.*)\/.*/;
        const match = media.match(regExp);
        // eslint-disable-next-line no-null/no-null
        return (match !== null && match.length >= 3) ? `https://drive.google.com/file/d/${match[2]}/preview` : undefined;
    }
}
