import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Tag} from '../../../../core/api-types/Tag';
import {SelectedTag} from '../../../../core/api-types/SelectedTag';
import {SelectedNewTag} from '../../../../core/api-types/SelectedNewTag';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-tags-selector',
    templateUrl: './tags-selector.component.html',
    styleUrls: ['./tags-selector.component.scss']
})
export class TagsSelectorComponent {
    @Input()
    public availableTags$: Observable<Tag[]>;

    @Input()
    public selectedAvailableTags: SelectedTag[] = [];

    @Input()
    public selectedNewTags: SelectedNewTag[] = [];

    @Output()
    public selectedAvailableTagsChange = new EventEmitter<SelectedTag[]>();

    @Output()
    public selectedNewTagsChange = new EventEmitter<SelectedNewTag[]>();

    public selectedAvailableTagsChanged(event: SelectedTag[]): void {
        this.selectedAvailableTagsChange.emit(event);
    }

    public selectedNewTagsChanged(event: SelectedNewTag[]): void {
        this.selectedNewTagsChange.emit(event);
    }
}
