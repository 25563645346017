import {VideoDisplay, VideoDisplayType, VideoFormat, VideoFormatType} from './VideoFormat';
import {VideoLink} from './VideoLink';

export class OneDriveVideo {
    public static TYPE: VideoFormatType = VideoFormat.ONE_DRIVE;
    public static DISPLAY: VideoDisplayType = VideoDisplay.VIDEO;


    public static createVideoLink(): VideoLink {
        return new VideoLink(OneDriveVideo.TYPE, OneDriveVideo.DISPLAY);
    }

    public static isVideoFormat(url: string): boolean {
        const regex = new RegExp('^(https?\\:\\/\\/)?((www\\.)?onedrive\\.live\\.com)\\/.+$');
        return regex.test(url);
    }

    public static crateVideoLink(media: string): string {
        const regExp = /^.*(onedrive\.live\.com\/|embed\?|download\?)(.*).*/;
        const match = media.match(regExp);
        console.log(match);
        const parameterRegex = /[\?]?([\w]+)=([\w-]+)/g;
        const match1 = media.match(parameterRegex);
        console.log(match1);
        // eslint-disable-next-line no-null/no-null
        return (match !== null && match.length >= 3) ? `https://onedrive.live.com/download?${match[2]}` : undefined;
    }
}
