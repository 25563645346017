import {Injectable} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {QuestionValidatorService} from './question-validator.service';

@Injectable({
    providedIn: 'root'
})
export class ReflectionFreeTextQuestionValidatorService extends QuestionValidatorService {

    public validateFullQuiz(formData: UntypedFormGroup): boolean {
        return formData.valid;
    }
}
