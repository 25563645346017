<div class="micromate-card">
    <div class="micromate-card-header">
        <h1>Reports</h1>
    </div>

    <div class="micromate-card-content">
        <form [formGroup]="formData" id="ngFormLearningPackage" class="micromate-report-form">
            <div class="micromate-report-form-element">
                <label class="micromate-report-form-element-title" i18n>Organisations-Id:
                </label>
                <input type="text" formControlName="organizationId" class="micormate-form-input">
            </div>
            <div
                *ngIf="formData.touched && formData.controls.organizationId?.errors?.required"
                class="micormate-form-validation-error" i18n>
                Organisations-Id ist erforderlich
            </div>

            <div class="micromate-report-form-element">
                <label class="micromate-report-form-element-title" i18n>Lernpaket-Id:
                </label>
                <input type="text" formControlName="learningPackageId" class="micormate-form-input">
            </div>

            <div class="micromate-report-form-element">
                <label class="micromate-report-form-element-title" i18n>Lerner-Id:
                </label>
                <input type="text" formControlName="learnerId" class="micormate-form-input">
            </div>

            <div class="micromate-report-form-element">
                <label class="micromate-report-form-element-title" i18n>Zeitspanne:
                </label>
                <div class="micromate-report-form-element-data">
                    <div class="micromate-report-form-element-data-info micromate-report-form-element-data-multiple">
                        <label class="micromate-report-form-element-title " i18n>Von
                        </label>
                        <input type="text" formControlName="periodFrom" class="micormate-form-input">
                    </div>
                    <div class="micromate-report-form-element-data-info micromate-report-form-element-data-multiple">
                        <label class="micromate-report-form-element-title" i18n>bis
                        </label>
                        <input type="text" formControlName="periodTo" class="micormate-form-input">
                    </div>
                </div>
            </div>
            <div
                *ngIf="formData.touched && (formData.controls.periodFrom?.invalid || formData.controls.periodTo?.invalid)"
                class="micormate-form-validation-error" i18n>
                Falsches Datumsformat (Format: YYYY-MM-DD HH:mm:ss)
            </div>
        </form>

        <div class="micromate-report-form-buttons">
            <div class="micromate-button"
                 *ngIf="shouldShowOrganizationLearningTimeReportGenerator(formData.getRawValue())">
                <div tabindex="0" class="micromate-buttont-text"
                     [class.micromate-buttont-text-disabled]="checkIfFormInvalid()"
                     (click)="generateTimeReport(formData.getRawValue())">
                    <div i18n class="micromate-button-title">Zeitreport generieren</div>
                </div>
            </div>
            <div class="micromate-button">
                <div tabindex="0" class="micromate-buttont-text"
                     [class.micromate-buttont-text-disabled]="checkIfFormInvalid()"
                     (click)="generateReport(formData.getRawValue())">
                    <div i18n class="micromate-button-title">Report generieren</div>
                </div>
            </div>
        </div>
    </div>

</div>
