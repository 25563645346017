<div class="micromate-card" *ngIf="viewModel !== undefined">
    <div class="micromate-card-header">
        <h1>{{viewModel.groupName}}</h1>
        <div class="micromate-card-selection">
            <select [(ngModel)]="activeLearningPackageId" (ngModelChange)="changeActiveLearningPackage($event)"
                    class="micromate-single-selection">
                <option [ngValue]="undefined" i18n>Alle Lernpakete</option>
                <option *ngFor="let learningPackage of learningPackages"
                        [ngValue]="learningPackage._id">{{learningPackage.name}}</option>
            </select>
        </div>
    </div>

    <div class="micromate-scroll-wrapper">
        <div class="micromate-small-cards ">
            <div class="micromate-small-card micromate-small-card-full-flex-width">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#ballot-check-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title">
                    <p class="micromate-small-card-title-text"> {{formatPoints(staticKpis?.totalQuizzes ? staticKpis.totalQuizzes : 0)}}</p>
                    <p class="micromate-small-card-title-subtext" i18n>beantwortete Fragen</p>
                </div>
            </div>
            <div class="micromate-small-card micromate-small-card-full-flex-width">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#calender-day-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title">
                    <p class="micromate-small-card-title-text"> {{latestTimestampSentence ? latestTimestampSentence : "-"}}</p>
                    <p class="micromate-small-card-title-subtext" i18n>wurde die letzte Frage beantwortet</p>
                </div>
            </div>
            <div class="micromate-small-card micromate-small-card-full-flex-width micromate-small-card-button"
                 *ngIf=" !activeLearningPackageId"
                 [class.micromate-small-card-button-no-event]="!(featuresService.hasFeaturePersonalInsights() | async)"
                 [routerLink]="['lernziele']">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#user-friends-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title ">
                    <p class="micromate-small-card-title-text"> {{formatPoints(staticKpis?.totalLearners ? staticKpis.totalLearners : 0)}}</p>
                    <p class="micromate-small-card-title-subtext" i18n>Lernende</p>
                </div>
                <div class="micromate-small-card-icon-button"
                     *ngIf="(featuresService.hasFeaturePersonalInsights() | async)">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#chevron-right-light"></use>
                    </svg>
                </div>
            </div>
            <div class="micromate-small-card micromate-small-card-full-flex-width" *ngIf="!activeLearningPackageId">
                <div class="micromate-small-card-icon">
                    <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                        <use href="./assets/icons.svg#boxes-alt-light"></use>
                    </svg>
                </div>
                <div class="micromate-small-card-title">
                    <p class="micromate-small-card-title-text"> {{formatPoints(staticKpis?.totalLearningPackages ? staticKpis.totalLearningPackages : 0)}}</p>
                    <p class="micromate-small-card-title-subtext" i18n>aktive Lernpakete</p>
                </div>
            </div>
        </div>

        <ng-container *ngIf="timeSplitMode !== undefined">
            <div class="micromate-analytics-filter-wrapper">
                <div
                    class="micromate-analytics-filter-box micromate-analytics-filter-left {{splitMode === 'Daily' ? 'micromate-analytics-filter-active' : ''}}"
                    (click)="setTimePeriod(timeSplitMode.Daily)">
                    Tag
                </div>
                <div
                    class="micromate-analytics-filter-box {{splitMode === 'Weekly' ? 'micromate-analytics-filter-active' : ''}}"
                    (click)="setTimePeriod(timeSplitMode.Weekly)">
                    Woche
                </div>
                <div
                    class="micromate-analytics-filter-box micromate-analytics-filter-right {{splitMode === 'Monthly' ? 'micromate-analytics-filter-active' : ''}}"
                    (click)="setTimePeriod(timeSplitMode.Monthly)">
                    Monat
                </div>
            </div>

            <div class="micromate-charts">
                <div class="micromate-chart-wrap micromate-chart-wrap-margin">
                    <p class="micromate-chart-title" i18n>Engagement</p>
                    <p class="micromate-chart-text" i18n>Durchschnittlich beantwortete Fragen pro Lernender pro Tag</p>
                    <div class="micromate-chart-container">
                        <canvas [chartType]="'bar'"
                                [colors]="lineChartColorsBlue"
                                [datasets]="engagementChartData"
                                [labels]="chartLabels"
                                [legend]="false"
                                [options]="createChartOptions('engagement')"
                                baseChart
                                class="canvas-chart">
                        </canvas>
                    </div>
                </div>

                <div class="micromate-chart-wrap" *ngIf="lernfortschrittChartData[0].data.length > 0">
                    <p class="micromate-chart-title" i18n>Lernstand</p>
                    <p class="micromate-chart-text" i18n>Erfolgsrate der beantworteten Fragen</p>
                    <div class="micromate-chart-container">
                        <canvas [chartType]="'bar'"
                                [colors]="lineChartColorsViolet"
                                [datasets]="lernfortschrittChartData"
                                [labels]="chartLabels"
                                [legend]="false"
                                [options]="createChartOptions('lernfortschritt')"
                                baseChart
                                class="canvas-chart">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="micromate-charts" *ngIf="activeLearnersChartData[0].data.length > 0">
                <div class="micromate-chart-wrap micromate-chart-wrap-margin">
                    <p class="micromate-chart-title" i18n>Aktive Lernende</p>
                    <p class="micromate-chart-text" i18n>Lernende die mindestens eine Frage beantwortet haben</p>
                    <div class="micromate-chart-container">
                        <canvas [chartType]="'bar'"
                                [colors]="lineChartColorsGreen"
                                [datasets]="activeLearnersChartData"
                                [labels]="chartLabels"
                                [legend]="false"
                                [options]="createChartOptions('activeLearners')"
                                baseChart
                                class="canvas-chart">
                        </canvas>
                    </div>
                </div>
                <div class="micromate-chart-placeholder">

                </div>
            </div>
        </ng-container>

    </div>
</div>
