import {Component, Input} from '@angular/core';
import {Popup} from '../../../../components/popup/Popup';

@Component({
    selector: 'app-help-quiz',
    templateUrl: './help-quiz.component.html',
    styleUrls: ['./help-quiz.component.scss']
})
export class HelpQuizComponent implements Popup {
    @Input()
    public data: undefined;
}
