import {Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {Learner} from '../api-types/learner';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ResourceHelper} from './resource-helper.service';
import {ListPagination} from '../api-types/listPagination';
import {ListPaginationRequest} from '../api-types/listPaginationRequest';

@Injectable({
    providedIn: 'root'
})
export class LearnerResource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public getAllForPagination(listPaginationRequest: ListPaginationRequest): Observable<ListPagination<Learner>> {
        return this.http.get<ListPagination<Learner>>(`${environment.serverUrl}/api/website/admin/learners?filter=${listPaginationRequest.filter}&pageNumber=${listPaginationRequest.pageNumber}&pageAmount=${listPaginationRequest.pageAmount}`);
    }

    public getAllAvailableForGroup(groupId: string): Observable<Learner[]> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<Learner[]>(`${url}/learners/group/${groupId}`)));
    }

    public getLearner(learnerId: string): Observable<Learner> {
        return this.http.get<Learner>(`${environment.serverUrl}/api/website/admin/learners/${learnerId}`);
    }
}
