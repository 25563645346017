import {Component, Input} from '@angular/core';
import {Icons} from '../../icon/icon.component';

@Component({
    selector: 'app-dropdown-button-option',
    templateUrl: './dropdown-button-option.component.html',
    styleUrls: ['./dropdown-button-option.component.scss']
})
export class DropdownButtonOptionComponent {
    @Input()
    public text: string;

    @Input()
    public icon: Icons;

    @Input()
    public viewBox: string;
}
