<app-dropdown-button class="micromate-editor-tag-button"
                     [shouldCloseOnContentClick]="false"
                     icon="tags-light" title="Tags" i18n-title>
    <app-tags-dropdown
        [availableTags$]="availableTags$"
        [selectedAvailableTags]="selectedAvailableTags"
        [selectedNewTags]="selectedNewTags"
        (selectedAvailableTagsChange)="selectedAvailableTagsChanged($event)"
        (selectedNewTagsChange)="selectedNewTagsChanged($event)"></app-tags-dropdown>
</app-dropdown-button>
