import {Component, OnDestroy, OnInit} from '@angular/core';
import {LearnerInfoAdminService} from '../../core/admin/learner-info-admin.service';
import {Learner} from '../../core/api-types/learner';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
import {LearnerOrganization} from '../../core/api-types/learnerOrganization';

@Component({
    selector: 'app-learner-info',
    templateUrl: './learner-info.component.html',
    styleUrls: ['./learner-info.component.scss']
})
export class LearnerInfoComponent implements OnInit, OnDestroy {
    public learner: Learner;
    public selectedLearner$ = new Subscription();
    public activeOrganization: LearnerOrganization;
    public inactiveOrganization: LearnerOrganization[];

    constructor(private learnerInfoAdminService: LearnerInfoAdminService,
                public multilanguageService: MultilanguageService) {
    }

    public ngOnInit(): void {
        this.selectedLearner$ = this.learnerInfoAdminService.selectedLearner$.subscribe((selectedLearner: Learner) => {
            if (selectedLearner !== undefined) {
                this.learner = selectedLearner;
                if (selectedLearner.organizations !== undefined) {
                    this.activeOrganization = (selectedLearner.organizations as LearnerOrganization[]).find(organization => organization.status === 'active');
                    this.inactiveOrganization = (selectedLearner.organizations as LearnerOrganization[]).filter(organization => organization.status === 'deactivated');
                }

            }
        });
    }

    public getProfileUrl(): string {
        return environment.learnUrl;
    }

    public ngOnDestroy(): void {
        this.selectedLearner$.unsubscribe();
    }
}
