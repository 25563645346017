import {Component, OnDestroy, OnInit} from '@angular/core';
import {combineLatest, Observable, Subscription, switchMap} from 'rxjs';
import {FeedbackDto, FeedbackRatingType, FeedbackStatusType} from './FeedbackDto';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {EditorFeedbackService} from '../../../../core/editor/editor-feedback.service';
import {FormBuilder, FormControl} from '@angular/forms';
import {QueryParamService} from '../../../../core/QueryParamService';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {QuizTranslation} from '../../../../core/api-types/quizTranslation';
import {filter} from 'rxjs/operators';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {EditorFeedbackResource} from '../../../../core/rest/editor/editor-feedback-resource.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-feedback-question',
    templateUrl: './feedback-question.component.html',
    styleUrls: ['./feedback-question.component.scss']
})
export class FeedbackQuestionComponent implements OnInit, OnDestroy {
    public selectedFeedback$: Observable<FeedbackDto>;
    public pageNumber: number;
    public numberOfPages: number;

    public openFeedbackFilter: FormControl<boolean>;

    private combineLatestSubscription$ = new Subscription();

    constructor(private editorFeedbackService: EditorFeedbackService,
                public route: ActivatedRoute,
                private formBuilder: FormBuilder,
                private router: Router,
                private editorQuestionService: EditorQuestionService,
                private editorLearningPackageService: EditorLearningPackageService,
                private editorFeedbackRestService: EditorFeedbackResource,
                private toastr: ToastrService) {
        this.openFeedbackFilter = formBuilder.control<boolean>(false);
    }


    public ngOnInit(): void {
        const feedbackIds$ = combineLatest([
            this.editorQuestionService.activeQuiz$,
            this.openFeedbackFilter.valueChanges]).pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter(([quiz]: [QuizTranslation[], boolean]) => !!quiz && quiz.length > 0),
            switchMap(([quiz, value]: [QuizTranslation[], boolean]) => this.editorFeedbackService.getAllIdsForQuestion(quiz[0].quizId, value)));

        this.combineLatestSubscription$ = combineLatest([feedbackIds$, this.route.queryParamMap]).subscribe(
            ([feedbacksIds, queryParams]: [string[], ParamMap]) => {
                this.numberOfPages = feedbacksIds.length;
                this.pageNumber = QueryParamService.getQueryParamValueInt(queryParams, 'pageNumber', 1);
                if (this.pageNumber > this.numberOfPages) {
                    this.navigateToFeedback(1);
                } else {
                    const feedbackId = feedbacksIds[this.pageNumber - 1];
                    this.selectedFeedback$ = this.editorFeedbackService.getFeedback(feedbackId);
                }
            }
        );
        this.openFeedbackFilter.setValue(true);
    }

    public ngOnDestroy(): void {
        this.combineLatestSubscription$.unsubscribe();
    }

    public getFeedbackFilterTitle(openFeedbackFilterValue: boolean): string {
        return openFeedbackFilterValue ? $localize`offene Feedbacks` : $localize`alle Feedbacks`;
    }

    public navigateToFeedback(pageNumber: number): void {
        void this.router.navigate(
            [],
            {
                relativeTo: this.route,
                queryParams: {
                    pageNumber: pageNumber
                },
                queryParamsHandling: 'merge'
            });
    }


    public async reopenFeedback(feedbackId: string): Promise<void> {
        await this.changeFeedbackState(feedbackId, FeedbackStatusType.Open);
    }

    public async acceptFeedback(value: { feedbackId: string; rating?: FeedbackRatingType; }): Promise<void> {
        await this.changeFeedbackState(value.feedbackId, FeedbackStatusType.Accept, value.rating);
    }

    private async changeFeedbackState(feedbackId: string, state: FeedbackStatusType, rating?: FeedbackRatingType): Promise<void> {
        const currentActiveLearningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        try {
            await this.editorFeedbackRestService.changeFeedbackState(currentActiveLearningPackageId, feedbackId, state, rating);
            this.editorLearningPackageService.reloadSelectedLearningPackage();
        } catch (e) {
            this.toastr.error($localize`Könnte nicht gespeichert werden`);
        }

    }
}
