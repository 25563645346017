<div class="micromate-card" *ngIf="group !== undefined">
    <div class="micromate-card-header">
        <h1 i18n>Einstellungen</h1>
        <div class="micromate-button" (click)="editGroupName(formData.getRawValue())"
             [class.micromate-button-disabled]="!formData.valid || (groupSettingsService.groupSettingSaved$ | async)">
            <div class="micromate-buttont-text">
                <div i18n class="micromate-button-title">Speichern</div>
            </div>
        </div>
    </div>

    <div class="micromate-card-content">
        <form [formGroup]="formData" class="micromate-learnpackage-overview-content">
            <div class="micromate-group-info">
                <div class="micromate-group-info-title" i18n>
                    Lerngruppenname:
                </div>
                <div class="micromate-group-name-data micormate-form-element">
                    <input type="text" formControlName="name" class="micormate-form-input"
                           [class.micormate-form-input-validation-error]="formData.controls.name.errors?.trimmedMaxLength">
                    <div *ngIf="formData.controls.name.errors?.trimmedMaxLength" class="micormate-form-validation-error" i18n>Maximal 50
                        Zeichen
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
