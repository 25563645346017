import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AdminAnalyticsOverview} from './AdminAnalyticsOverview';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsAdminRestService {

    constructor(private http: HttpClient) {
    }

    public getAdminAnalyticsOverview(): Observable<AdminAnalyticsOverview> {
        return this.http.get<AdminAnalyticsOverview>(`${environment.serverUrl}/api/website/admin/analytics/overview`);
    }

    public getLearningGoalsExport(groupId: string): Promise<{ csvData: string; }> {
        return this.http.get<{ csvData: string; }>(`${environment.serverUrl}/api/website/admin/analytics/group/${groupId}/learning-goals-export`).toPromise();
    }
}
