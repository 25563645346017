<div class="micromate-card">
    <div class="micromate-card-header">
        <h1 i18n>Lerngruppen</h1>
        <div class="micromate-button">
            <app-button type="primary"
                        [icon]="'plus-light'"
                        (click)="openNewGroupForm()" i18n>Neue Lerngruppe
            </app-button>
        </div>
    </div>


    <div class="micromate-table" *ngIf="defaultGroup !== undefined && groups !== undefined">
        <div class="table-container table-container-header">
            <ng-container>
                <div class="row-header" i18n>Lerngruppen</div>
                <div class="row-header" i18n>Lernende</div>
                <div class="row-header" i18n>Lernpakete</div>
                <div class="row-header" i18n></div>
            </ng-container>
        </div>
        <div class="table-container-data">
            <div class="table-container table-content micromate-default-group">
                <div class="row row-title" [routerLink]="[defaultGroup._id]">{{defaultGroup.name}}</div>
                <div class="row group-info">
                    <div class="micromate-group-button"
                         [routerLink]="[defaultGroup._id, 'lernende']">
                        <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#user-friends-light"></use>
                        </svg>
                        {{defaultGroup?.learnerIds ? defaultGroup.learnerIds.length : 0}}</div>
                </div>

                <div class="row group-info">
                    <div class="micromate-group-button"
                         [routerLink]="[defaultGroup._id, 'lernpakete']">
                        <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#boxes-alt-light"></use>
                        </svg>
                        {{defaultGroup?.learningPackages ? defaultGroup.learningPackages.length : 0}}</div>
                </div>
                <div class="row group-info">
                </div>
            </div>
            <div class="table-container table-content" *ngFor="let group of groups">
                <a class="row row-title"
                   [routerLink]="[group._id]">{{group?.name}}</a>
                <div class="row group-info">
                    <div class="micromate-group-button"
                         [routerLink]="[group._id, 'lernende']">
                        <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#user-friends-light"></use>
                        </svg>
                        {{group?.learnerIds ? group.learnerIds.length : 0}}</div>
                </div>

                <div class="row group-info">
                    <div class="micromate-group-button"
                         [routerLink]="[group._id, 'lernpakete']">
                        <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#boxes-alt-light"></use>
                        </svg>
                        {{group?.learningPackages ? group.learningPackages.length : 0}}</div>
                </div>
                <div class="row group-info">
                    <div class="micromate-group-button" [routerLink]="[group._id, 'einstellungen']">
                        <app-icon icon="cog-light" class="micromate-group-form-app-icon"></app-icon>
                    </div>
                    <div class="micromate-group-button" (click)="deleteLearningGroup(group)">
                        <app-icon icon="trash-light" class="micromate-group-form-app-icon"></app-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
