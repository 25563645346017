import {Component, OnInit} from '@angular/core';
import {take} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AiRessource} from '../../../../../core/rest/ai-ressource.service';
import {EditorLearningPackageService} from '../../../../../core/editor/editor-learning-package.service';
import {ImportWizardService} from '../../import-wizard.service';
import {Resource} from '../../../../../core/api-types/Resource';
import {DocumentState} from '../../../../../core/api-types/DocumentState';
import {environment} from '../../../../../../environments/environment';
import {GeneratedQuestionResponseDto} from '../../../../../core/rest/generated-question-response.dto';

@Component({
    selector: 'app-ressource-wizard-import',
    templateUrl: './ressource-wizard-import.component.html',
    styleUrls: ['./ressource-wizard-import.component.scss']
})
export class RessourceWizardImportComponent implements OnInit {
    public documents$: Observable<Resource[]>;
    public hasNoDocuments: boolean = false;
    public learningPackageId: string;

    constructor(private editorLearningPackageService: EditorLearningPackageService,
                private aiService: AiRessource,
                private importWizardService: ImportWizardService) {
    }

    public async ngOnInit(): Promise<void> {
        this.learningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        this.documents$ = this.aiService.getResources(this.learningPackageId, DocumentState.PROCESSED);
        this.documents$.pipe(take(1)).subscribe(docs => this.hasNoDocuments = docs.length === 0);
    }

    public async getResourceQuestions(ressourceId: string): Promise<void> {
        this.importWizardService.showSpinner();
        this.aiService.getQuestionsForDocument(this.learningPackageId, ressourceId)
            .subscribe((generatedQuestions: GeneratedQuestionResponseDto[]) => {
                this.documents$.pipe(take(1))
                    .subscribe(docs => {
                        const resourceName = docs.find(doc => doc.id === ressourceId).name;

                        const questions = generatedQuestions.map(generatedQuestion => {
                            const question = generatedQuestion.question;
                            question.sourceUrl = `${environment.learnUrl}/resources/${ressourceId}/selections/${generatedQuestion.selectionId}`;
                            question.sourceLabel = resourceName;
                            return question;
                        });

                        this.importWizardService.hideSpinner();
                        this.importWizardService.addQuestions(questions);
                        this.importWizardService.currentStep = 'questions-summary';
                    });
            });
    }
}
