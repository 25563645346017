import {Component} from '@angular/core';

@Component({
    selector: 'app-styleguide',
    templateUrl: './styleguide.component.html',
    styleUrls: ['./styleguide.component.scss']
})
export class StyleguideComponent {

}
