import {Component, OnInit} from '@angular/core';
import {Popup} from '../../components/popup/Popup';
import {PopupService} from '../../components/popup/popup.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {QuestionGenerationService} from '../../core/rest/question-generation.service';
import {EditorQuestionService} from '../../core/editor/editor-question.service';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {filter, take} from 'rxjs/operators';
import {QuizTranslation} from '../../core/api-types/quizTranslation';
import {takeUntil} from 'rxjs';
import {LearningPackageQuizzesOverview} from '../../core/api-types/learningPackageQuizzesOverview';
import {Quiz} from '../../core/api-types/quiz';
import {QuestionType} from '../../core/editor/question.type';

@Component({
    selector: 'app-paste-text-popup',
    templateUrl: './paste-text-popup.component.html',
    styleUrls: ['./paste-text-popup.component.scss']
})
export class PasteTextPopupComponent implements Popup<QuestionType>, OnInit {

    public data: QuestionType;

    public possibleQuestionTypes: QuestionType[];
    public selectedQuestionType: QuestionType;

    public hasError: boolean = false;

    public pasteForm: FormGroup = new FormGroup({
        text: new FormControl<string>('', [Validators.required, Validators.maxLength(2000), Validators.minLength(100)])
    });

    constructor(private popupService: PopupService,
                public editorQuestionService: EditorQuestionService,
                private editorLearningPackageService: EditorLearningPackageService,
                private questionGenerationService: QuestionGenerationService) {

        this.possibleQuestionTypes = [
            {
                type: undefined,
                title: 'Zufälliger Fragetyp',
                icon: 'shuffle',
                questionType: 'standard'
            },
            ...editorQuestionService.standardQuestionTypes
        ];

    }

    public ngOnInit(): void {
        this.selectedQuestionType = this.data;

        this.pasteForm.valueChanges.subscribe(() => {
            if (this.hasError) {
                this.hasError = false;
            }
        });
    }

    public cancel(): void {
        this.popupService.close();
    }

    public paste: () => Promise<void> = async () => {
        this.hasError = false;
        return new Promise(resolve => {
            this.pasteForm.disable();
            const text = this.pasteForm.get('text').value as string;
            this.editorLearningPackageService.activeLearningPackage$.pipe(take(1)).subscribe((activeLearningPackage) => {
                const mainLearningPackage = activeLearningPackage.find(packageData => packageData.mainLearningPackage);
                const locale = mainLearningPackage.language;
                this.questionGenerationService.generateQuestionFromText(text, this.selectedQuestionType?.type, locale)
                    .pipe(takeUntil(this.popupService.isPopupOpen$.pipe(filter(isOpen => !isOpen))))
                    .subscribe((question) => {
                            if (question === undefined) {
                                this.showError();
                            } else {
                                this.addQuestionToLearningPackage(mainLearningPackage, locale, question);
                            }
                            resolve();
                        },
                        () => {
                            this.showError();
                            resolve();
                        });
            });
        });


    };

    private showError(): void {
        this.pasteForm.enable();
        this.hasError = true;
    }

    private addQuestionToLearningPackage(mainLearningPackage: LearningPackageQuizzesOverview, locale: string, question: Quiz): void {
        const currentNewQuestion = this.editorQuestionService.newQuiz$.value;
        const questionTranslation = this.mapToQuizTranslation(mainLearningPackage, locale, currentNewQuestion, question);
        this.editorQuestionService.newQuiz$.next(questionTranslation);
        this.popupService.close();
    }

    private mapToQuizTranslation(mainLearningPackage: LearningPackageQuizzesOverview, locale: string, currentNewQuestion: QuizTranslation, question: Quiz): QuizTranslation {
        return new QuizTranslation(
            mainLearningPackage._id,
            mainLearningPackage.learningPackageId,
            locale,
            mainLearningPackage.language,
            currentNewQuestion?.id,
            currentNewQuestion.quizId,
            question.quiz,
            question.quizType,
            [],
            question?.answer,
            question?.answers,
            question?.explanation,
            question?.positiveAnswer,
            question?.negativeAnswer
        );
    }

    public selectQuestionType(type: QuestionType): void {
        this.selectedQuestionType = type;
    }
}
