import {VideoDisplay, VideoDisplayType, VideoFormat, VideoFormatType} from './VideoFormat';
import {VideoLink} from './VideoLink';

export class DartfishVideo {
    public static TYPE: VideoFormatType = VideoFormat.DARTFISH;
    public static DISPLAY: VideoDisplayType = VideoDisplay.IFRAME;


    public static createVideoLink(): VideoLink {
        return new VideoLink(DartfishVideo.TYPE, DartfishVideo.DISPLAY);
    }

    public static isVideoFormat(url: string): boolean {
        const regex = new RegExp('^(https?\\:\\/\\/)?((www\\.)?dartfish\\.tv)\\/.+$');
        if (regex.test(url)) {
            const parameterRegex = /[\?]?([\w]+)=([\w-]+)/g;
            const match = url.match(parameterRegex);
            // eslint-disable-next-line no-null/no-null
            return match !== null && match.some(data => data.includes('CR='));
        }
        return false;
    }

    public static crateVideoLink(media: string): string {
        const parameterRegex = /[\?]?([\w]+)=([\w-]+)/g;
        const match = media.match(parameterRegex);
        // eslint-disable-next-line no-null/no-null
        if (match !== null) {
            const videoId = match.find(data => data.includes('CR=')).replace('?', '');
            const videoSh = match.find(data => data.includes('sh='));
            const videoAid = match.find(data => data.includes('aid='));

            if (videoId !== undefined) {
                const link = `https://www.dartfish.tv/Embed?${videoId}&VW=100%&VH=100%`;
                return videoSh !== undefined && videoAid !== undefined ? `${link}&${videoSh}&${videoAid}` : link;
            }
        }
        return undefined;
    }
}
