<div class="micromate-info">
    <svg aria-hidden="true" focusable="false" viewBox="0 0 576 512"
         class="micromate-info-icon">
        <use href="./assets/icons.svg#circle-info-light"></use>
    </svg>
    <div class="micromate-info-box-content">
        <div *ngIf="header !== undefined" class="micromate-info-header" [innerHTML]="header"></div>
        <div *ngIf="content !== undefined" class="micromate-info-message" [innerHTML]="content"></div>
        <ng-content></ng-content>
    </div>
</div>
