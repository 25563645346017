import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GroupActivationTag, TagQuestionInGroupStatus} from '../../core/api-types/GroupActivationTag';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-tag-activation',
    templateUrl: './tag-activation.component.html',
    styleUrls: ['./tag-activation.component.scss']
})
export class TagActivationComponent implements OnInit {
    @Input()
    public manualActivation: boolean;

    @Input()
    public manualActivationTags: GroupActivationTag[];

    @Input()
    public form: boolean = false;

    @Output()
    public activationTagChanged = new EventEmitter<{ manualActivation: boolean; changedManualActivationTags: GroupActivationTag[]; }>();

    public displayManualActivationTags: GroupActivationTag[];
    public changedManualActivationTags: GroupActivationTag[] = [];

    public manualActivationForm = new FormControl<boolean>(false);

    public changeState(groupActivationTag: GroupActivationTag, state: TagQuestionInGroupStatus): void {
        if (groupActivationTag.status !== state) {
            groupActivationTag.status = state;
            this.serChangedManualActivationTags(groupActivationTag, state);
            this.emitChanges();
        }
    }

    public ngOnInit(): void {
        this.displayManualActivationTags = structuredClone(this.manualActivationTags) as GroupActivationTag[];
        this.manualActivationForm.setValue(this.manualActivation);
    }

    public manualActivationChanged(): void {
        this.emitChanges();
    }

    private serChangedManualActivationTags(groupActivationTag: GroupActivationTag, state: TagQuestionInGroupStatus): void {
        const tagId = groupActivationTag.id;
        const manualActivationTag = this.manualActivationTags.find(tag => tag.id === tagId);
        if (manualActivationTag.status !== state) {
            const index = this.changedManualActivationTags.findIndex(tag => tag.id === tagId);
            if (index !== -1) {
                this.changedManualActivationTags[index] = groupActivationTag;
            } else {
                this.changedManualActivationTags.push(groupActivationTag);
            }
        } else {
            if (this.changedManualActivationTags.find(tag => tag.id === tagId) !== undefined) {
                this.changedManualActivationTags = this.changedManualActivationTags.filter(tag => tag.id !== tagId);
            }
        }
    }

    private emitChanges(): void {
        this.activationTagChanged.emit({
            manualActivation: this.manualActivationForm.getRawValue(),
            changedManualActivationTags: this.changedManualActivationTags
        });
    }
}
