import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EditorQuestionsService} from '../../../core/editor/editor-questions.service';
import {EditorQuestionService} from '../../../core/editor/editor-question.service';
import {ActivatedRoute, Router} from '@angular/router';
import {QuizOverview} from '../../../core/api-types/quizOverview';
import {QuestionType} from '../../../core/editor/question.type';
import {Tag} from '../../../core/api-types/Tag';
import {SelectedTag} from '../../../core/api-types/SelectedTag';
import {EditorLearningPackageService} from '../../../core/editor/editor-learning-package.service';
import {Subscription} from 'rxjs';
import {Icons} from '../../../components/icon/icon.component';
import {QuestionParamsFormGroupData} from '../QuestionParamsFormGroupData';

@Component({
    selector: 'app-quizzes-overview',
    templateUrl: './quizzes-list-overview.component.html',
    styleUrls: ['./quizzes-list-overview.component.scss']
})
export class QuizzesListOverviewComponent implements OnInit, OnDestroy {
    @Input() learningPackageId: string;
    @Input() learningPackageHasTranslation: boolean;
    @Input() questionsPresentation: QuizOverview[];
    @Input() sortAndFilter: QuestionParamsFormGroupData;

    public questionTypes: QuestionType[];
    public availableTags: Tag[];
    public selectedNewTags = [];
    private learningPackageAvailableTagsSubscription$ = new Subscription();

    constructor(public editorQuestionsService: EditorQuestionsService,
                public editorQuestionService: EditorQuestionService,
                public editorLearningPackageService: EditorLearningPackageService,
                private router: Router,
                private activatedRoute: ActivatedRoute) {
    }

    public ngOnDestroy(): void {
        this.learningPackageAvailableTagsSubscription$.unsubscribe();
    }

    public ngOnInit(): void {
        this.questionTypes = this.editorQuestionService.quizTypes;
        this.learningPackageAvailableTagsSubscription$ = this.editorLearningPackageService.learningPackageAvailableTags$.subscribe((availableTags: Tag[]) => {
            this.availableTags = availableTags;
            this.addSelectedTagsToQuestions();
        });
    }

    private addSelectedTagsToQuestions(): void {
        this.questionsPresentation.map(question => {
            if (question.tags !== undefined && question.tags.length > 0) {
                question.selectedTags = [...question.tags.map(tag => new SelectedTag(tag))];
            }
            return question;
        });
    }

    public async openQuestion(quizId: string): Promise<void> {
        const queryParams = this.editorQuestionsService.getQueryParams(this.sortAndFilter, this.learningPackageHasTranslation);
        if (this.editorQuestionsService.quizSaved.value) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate(
                [`/editor/lernpakete/${this.learningPackageId}/fragen/${quizId}`],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: queryParams
                });
        } else {
            const url = `/editor/lernpakete/${this.learningPackageId}/fragen/${quizId}`;
            await this.editorQuestionsService.openCancelQuestion(url);
        }
    }


    public findQuestionType(questionType: string): { type: string; title: string; icon: Icons; } {
        return this.questionTypes.find(type => type.type === questionType);
    }

    public getFeedbackStatusTitle(hasOpenFeedback: boolean): string {
        return hasOpenFeedback ? $localize`Offenes Feedback` : $localize`Kein offenes Feedback`;
    }

    public getTranslationsStatusTitle(haveTranslations: boolean): string {
        return haveTranslations ? $localize`Übersetzungen komplett` : $localize`Übersetzungen unvollständig`;
    }
}
