import * as moment from 'moment';

export class TimestampSentenceCreator {
    public create(date: string, currentDate: Date): string {
        let timestampSentence = '';
        const minutes: number = moment(currentDate).diff(date, 'minutes');
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (date !== undefined && (minutes || minutes === 0)) {
            if (minutes <= 60) {
                if (minutes === 1) {
                    timestampSentence = 'Vor 1 Minute';
                } else if (minutes === 0) {
                    timestampSentence = 'Gerade eben';
                } else {
                    timestampSentence = `Vor ${minutes} Minuten`;
                }
            } else {
                const hours = moment().diff(date, 'hours');
                if (hours <= 24) {
                    if (hours === 1) {
                        timestampSentence = 'Vor 1 Stunde';
                    } else {
                        timestampSentence = `Vor ${hours} Stunden`;
                    }
                } else {
                    const days = moment().diff(date, 'days');
                    if (days === 1) {
                        timestampSentence = `Vor ${days} Tag`;
                    } else {
                        timestampSentence = `Vor ${days} Tage`;
                    }
                }
            }
        } else {
            timestampSentence = '-';
        }
        return timestampSentence;
    }
}
