<div class="micromate-card">
    <div class="micromate-card-header">
        <h1>API Call Details</h1>
    </div>

    <div class="micromate-card-content micromate-api-info">
        <div class="micromate-api-info-request" *ngIf="apiRequest !== undefined">
            <div class="micromate-api-info-title" i18n>Request</div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>Request Zeitpunkt:</div>
                <div
                    class="micromate-organization-info-data">{{apiRequest.timestamp | date:'M.d.yy, HH:mm:ss.SSS a'}}</div>
            </div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>URL:</div>
                <div class="micromate-organization-info-data micromate-organization-info-title-url"
                     [class.micromate-organization-info-title-url-error]="apiResponse === undefined">{{apiRequest.payload.url}}</div>
            </div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>HTTP-Methode:</div>
                <div class="micromate-organization-info-data">{{apiRequest.payload.httpMethod}}</div>
            </div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>Body:</div>
                <pre><div
                    class="micromate-organization-info-data micromate-organization-info-title-json">
                    {{apiRequest.payload.body | json }}</div></pre>
            </div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>CURL:</div>
                <div class="micromate-organization-info-data micromate-organization-info-data-curl">
                    <div class="micromate-organization-info-data-curl-text" [innerHtml]="getCurlString()"></div>
                    <div class="micromate-organization-info-data-curl-copy-button">
                        <div class="micromate-icon-button" (click)="copyCurl()">
                            <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"
                                 class="micromate-organization-info-icon">
                                <use href="./assets/icons.svg#copy-light"></use>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="micromate-api-info-response" *ngIf="apiResponse !== undefined">
            <div class="micromate-api-info-title" i18n>Response</div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>Response Zeitpunkt:</div>
                <div
                    class="micromate-organization-info-data">{{apiResponse.timestamp | date:'M.d.yy, HH:mm:ss.SSS a'}}</div>
            </div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>Response body:</div>
                <pre><div
                    class="micromate-organization-info-data micromate-organization-info-title-json">{{apiResponse.payload.response | json}}</div></pre>
            </div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>HTTP-Status:</div>
                <div class="micromate-organization-info-data"
                     [class.micromate-api-history-success]="apiResponse.payload?.httpState >= 200 && apiResponse.payload?.httpState < 400"
                     [class.micromate-api-history-error]="!(apiResponse.payload?.httpState >= 200 && apiResponse.payload?.httpState < 400)">{{apiResponse.payload.httpState}}</div>
            </div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>UserId:</div>
                <div
                    class="micromate-organization-info-data">{{apiResponse.payload.userId !== undefined ? apiResponse.payload.userId : '-'}}</div>
            </div>
            <div class="micromate-organization-info">
                <div class="micromate-organization-info-title" i18n>Internal Error:</div>
                <pre><div class="micromate-organization-info-data micromate-organization-info-title-json">
                    {{(apiResponse.payload.internalError !== undefined ? (apiResponse.payload.internalError | json) : '-') }}</div></pre>
            </div>
        </div>
    </div>
</div>
