import {Component, OnInit} from '@angular/core';
import {ImportWizardService} from '../import-wizard.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ImportableQuestion} from '../../../../core/editor/types/QuestionConversion/ImportableQuestion';
import {FormControl} from '@angular/forms';
import {FormCustomValidation} from '../../../../core/form-custom-validation';
import {format} from 'date-fns';
import {LearningPackageTagsPreparator} from '../../../learning-package-tags/LearningPackageTagsPreparator';

@Component({
    selector: 'app-import-tag',
    templateUrl: './import-tag.component.html',
    styleUrls: ['./import-tag.component.scss']
})
export class ImportTagComponent implements OnInit {
    public activeQuestions$: Observable<ImportableQuestion[]>;
    public tagFormControl = new FormControl<string>(
        `${$localize`Import`} ${format(new Date(), 'dd.MM.yyyy, HH:mm')}`,
        [FormCustomValidation.trimmedRequired, FormCustomValidation.trimmedMaxLengthValidator(50)]);

    constructor(public importWizardService: ImportWizardService) {
    }

    public ngOnInit(): void {
        this.activeQuestions$ = this.importWizardService.questionsForDefaultLocale$.pipe(map(x => x.filter(q => q.isActive)));
    }

    public async import(): Promise<void> {
        const importTag = LearningPackageTagsPreparator.removeSpacesInText(this.tagFormControl.getRawValue());
        await this.importWizardService.importQuestions(importTag);
    }

    public goBackToQuestionsSummary(): void {
        this.importWizardService.currentStep = 'questions-summary';
    }
}
