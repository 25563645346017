import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {GroupLearningPackageSettingComponent} from '../app-learncenter/group-learning-package-setting/group-learning-package-setting.component';
import {OrganizationSettingService} from '../app-administration/organization-setting/organization-setting.service';

@Injectable()
export class OrganizationSettingsGuard implements CanDeactivate<GroupLearningPackageSettingComponent> {
    public component: Object;
    public route: ActivatedRouteSnapshot;

    constructor(private organizationSettingServiceService: OrganizationSettingService) {
    }

    public canDeactivate(component: GroupLearningPackageSettingComponent,
                         route: ActivatedRouteSnapshot,
                         state: RouterStateSnapshot,
                         nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.organizationSettingServiceService.checkIfOrganizationSettingIsSaved(nextState.url);
    }

}
