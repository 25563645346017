import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {ResolveEnd, Router} from '@angular/router';
import {AppNavigationItem} from './api-types/app-navigation-item';
import {AppPageNavigationItem} from './api-types/appPageNavigationItem';
import {NavigationMode} from './navigationMode';

/* eslint-disable @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-assignment */

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public mobileNavigationOpen$ = new BehaviorSubject<boolean>(false);

    public adminNavigation: AppNavigationItem = {
        label: $localize`Admin`,
        url: 'administration',
        appPageNavigation: [
            {
                label: $localize`Lernende`,
                url: 'lernende',
                icon: 'user-friends-light'
            },
            {
                label: $localize`Organisationen`,
                url: 'organisationen',
                icon: 'sitemap-light'
            },
            {
                label: $localize`Lernpakete`,
                url: 'lernpakete',
                icon: 'boxes-alt-light'
            },
            {
                label: $localize`Analytics`,
                url: 'analytics',
                icon: 'analytics-light'
            },
            {
                label: $localize`Reports`,
                url: 'reports',
                icon: 'file-chart-column-light'
            },
            {
                label: $localize`API History`,
                url: 'api-history',
                icon: 'history-light'
            }
        ]
    };

    public learnCenterNavigation: AppNavigationItem = {
        label: $localize`Lerncenter`,
        url: 'lerncenter',
        appPageNavigation: [
            {
                label: $localize`Lerngruppen`,
                url: 'lerngruppen',
                icon: 'users-class-light'
            }
        ]
    };

    public editorNavigation: AppNavigationItem =
        {
            label: $localize`Editor`,
            url: 'editor',
            appPageNavigation: [
                {
                    label: $localize`Lernpakete`,
                    url: 'lernpakete',
                    icon: 'boxes-alt-light'
                }
            ]
        };

    public analyticsNavigation: AppNavigationItem = {
        label: $localize`Analytics`,
        url: 'analytics',
        appPageNavigation: [
            {
                label: $localize`Übersicht`,
                url: 'uebersicht',
                icon: 'tachometer-alt-fast-light'
            },
            {
                label: $localize`Lerngruppen`,
                url: 'lerngruppen',
                icon: 'users-class-light'
            }
        ]
    };

    public editorLearningPackageNavigation: AppPageNavigationItem[] = [
        {
            label: $localize`Fragen`,
            url: 'fragen',
            icon: 'ballot-check-light'
        },
        {
            label: $localize`Tags`,
            url: 'tags',
            icon: 'tags-light'
        },
        {
            label: $localize`Inbox`,
            url: 'inbox',
            icon: 'inbox-light'
        },
        {
            label: $localize`Ressourcen`,
            url: 'ressourcen',
            icon: 'files-light'
        },
        {
            label: $localize`Import`,
            url: 'import',
            icon: 'file-import-light'
        },
        {
            label: $localize`Versionen`,
            url: 'versionen',
            icon: 'layer-group-light'
        },
        {
            label: $localize`Einstellungen`,
            url: 'einstellungen',
            icon: 'cog-light'
        }];

    public organizationLearnersNavigation: AppNavigationItem = {
        url: 'organisation',
        appPageNavigation: [
            {
                label: $localize`Lernende`,
                url: 'lernende',
                icon: 'user-friends-light'
            },
            {
                label: $localize`Manager`,
                url: 'manager',
                icon: 'user-group-crown'
            },
            {
                label: $localize`Einstellungen`,
                url: 'einstellungen',
                icon: 'cog-light'
            }
        ]
    };


    private navigationMode = new Subject<NavigationMode>();
    public navigationMode$: Observable<NavigationMode> = this.navigationMode.asObservable();

    public mobileNavigationDisplay$ = new BehaviorSubject<boolean>(false);
    public editorNavigation$ = new BehaviorSubject<boolean>(false);
    public activeAppNavigationItem$ = new BehaviorSubject<string>(undefined);
    public activeAppPageNavigationItem$ = new BehaviorSubject<string>(undefined);
    public activeAdminAppPageNavigation$ = new BehaviorSubject<AppPageNavigationItem[]>(undefined);
    public activeAppPageNavigation$ = new BehaviorSubject<AppPageNavigationItem[]>(undefined);
    public organizationAdminNavigation$ = new BehaviorSubject<boolean>(false);
    private appNavigation: AppNavigationItem[] = [this.adminNavigation, this.learnCenterNavigation, this.editorNavigation, this.analyticsNavigation];

    constructor(private router: Router) {

        router.events.subscribe(path => {
            if (path instanceof ResolveEnd) {

                if (path.state.root?.firstChild?.data?.navigationMode !== undefined) {
                    this.navigationMode.next(path.state.root.firstChild.data.navigationMode as NavigationMode);
                } else {
                    this.navigationMode.next('with-navigation');
                }

                const stateUrl = path.state.url.split('/');
                const appNavigationUrl = stateUrl[1];
                const appPageNavigationUrl = stateUrl[2];
                this.organizationAdminNavigation$.next(appNavigationUrl === 'organisation');
                if (this.organizationAdminNavigation$.value) {
                    this.setOrganizationAdminNavigation(appPageNavigationUrl);
                } else {
                    this.setNavigation(appNavigationUrl, appPageNavigationUrl, stateUrl);
                }
            }
        });
    }

    private setNavigation(appNavigationUrl: string, appPageNavigationUrl: string, stateUrl: string[]): void {
        const activeNavigation = this.getAppNavigationItem(appNavigationUrl);
        this.activeAppNavigationItem$.next(activeNavigation?.url);
        this.activeAppPageNavigationItem$.next(appPageNavigationUrl);
        this.activeAdminAppPageNavigation$.next(activeNavigation?.appPageNavigation);
        this.activeAppPageNavigation$.next(this.getActiveAppPageNavigation(activeNavigation));
        this.mobileNavigationDisplay$.next(this.activeAppNavigationItem$.getValue()?.length > 0);
        this.editorNavigation$.next(this.recognizeEditorNavigation(stateUrl));
        if (this.editorNavigation$.getValue()) {
            this.activeAppPageNavigationItem$.next(this.setActiveAppPageNavigationItemForEditorNavigations(stateUrl));
        }
    }

    private recognizeEditorNavigation(stateUrl: string[]): boolean {
        return stateUrl.includes('editor') && stateUrl.includes('lernpakete') && stateUrl.length > 3;
    }

    private getActiveAppPageNavigation(activeNavigation: AppNavigationItem): AppPageNavigationItem[] {
        if (activeNavigation?.appPageNavigation !== undefined) {
            return activeNavigation.appPageNavigation.filter(nav => !nav.onlyAdmin);
        }
        return undefined;
    }

    private setOrganizationAdminNavigation(appPageNavigationUrl: string): void {
        this.editorNavigation$.next(false);
        const activeNavigation = this.organizationLearnersNavigation;
        this.activeAppNavigationItem$.next(activeNavigation?.url);
        this.activeAppPageNavigationItem$.next(appPageNavigationUrl);
        this.activeAdminAppPageNavigation$.next(activeNavigation?.appPageNavigation);
        this.activeAppPageNavigation$.next(this.getActiveAppPageNavigation(activeNavigation));
        this.mobileNavigationDisplay$.next(this.activeAppNavigationItem$.getValue()?.length > 0);

    }

    private getAppNavigationItem(url: string): AppNavigationItem {
        return this.appNavigation.find(navigation => navigation.url === url);
    }

    private setActiveAppPageNavigationItemForEditorNavigations(url: string[]): string {
        let activeAppPageNavigation: string;
        for (const navigation of this.editorLearningPackageNavigation) {
            if (url.some(urlElement => urlElement.includes(navigation.url))) {
                activeAppPageNavigation = navigation.url;
            }
        }
        return activeAppPageNavigation;
    }
}
