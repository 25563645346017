import {Injectable} from '@angular/core';
import {QuestionValidatorService} from './question-validator.service';
import {AbstractControl, FormGroup, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {SingleChoiceTextQuizFormGroup} from '../quiz-makers/standard-question/single-choice-text-quiz/SingleChoiceTextQuizFormGroup';

@Injectable({
    providedIn: 'root'
})
export class SingleChoiceTextQuestionValidatorService extends QuestionValidatorService {

    public validateDuplicatedAnswer(formData: UntypedFormGroup, answerText: string): boolean {
        const answersFormData = formData.get('answers') as UntypedFormArray;
        const answers = answersFormData.controls.map((control: AbstractControl) => (control.get('answerText').value as string).trim());
        const text = answerText.trim();
        return text.length > 0 && answers.filter(answer => answer === text).length > 1;
    }

    public validateIfCorrectAnswer(formData: UntypedFormGroup): boolean {
        const answers = formData.get('answers') as UntypedFormArray;
        for (const control of answers.controls) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (control.get('isCorrectAnswer').value) {
                return true;
            }
        }
        return false;
    }

    public validateFullQuiz(formData: FormGroup<SingleChoiceTextQuizFormGroup>): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
        return formData.valid && this.validateAnswers((formData.getRawValue()).answers);
    }

    private  validateAnswers(data: { isCorrectAnswer: boolean; answerText: string; }[]): boolean {
        const answersTextTable = data.map(control => control.answerText.trim().toLowerCase());
        const numberOfAnswers = data.length >= this.MIN_ANSWER_NUMBER || data.length <= this.MAX_ANSWER_NUMBER;
        const correctAnswer = data.some(answer => answer.isCorrectAnswer);
        const noDuplicatesAnswers = (new Set(answersTextTable)).size === answersTextTable.length;
        return numberOfAnswers && correctAnswer && noDuplicatesAnswers;
    }
}
