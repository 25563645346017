<h3 class="micromate-question-preview-answers-title" i18n>Antworten</h3>
<div class="micromate-answer">
    <div [class.micromate-answer-correct]="questionPreview.negativeAnswer.isCorrectAnswer"
         [class.micromate-answer-wrong]="!questionPreview.negativeAnswer.isCorrectAnswer"
         class="micromate-answer-true-false" i18n>
        Falsch
    </div>
    <div [class.micromate-answer-correct]="questionPreview.positiveAnswer.isCorrectAnswer"
         [class.micromate-answer-wrong]="!questionPreview.positiveAnswer.isCorrectAnswer"
         class="micromate-answer-true-false" i18n>
        Richtig
    </div>
</div>
<app-explanation-question-preview
    *ngIf="questionPreview.explanation !== undefined && (questionPreview.explanation.mediaType !== undefined || (questionPreview.explanation.text !== undefined && questionPreview.explanation.text.length > 0))"
    [explanation]="questionPreview.explanation"></app-explanation-question-preview>
<app-source-question-preview *ngIf="questionPreview.sourceUrl !== undefined"
                             [sourceUrl]="questionPreview.sourceUrl"
                             [sourceLabel]="questionPreview.sourceLabel"></app-source-question-preview>
