import {Injectable} from '@angular/core';
import {FormGroup, UntypedFormGroup} from '@angular/forms';
import {QuestionValidatorService} from './question-validator.service';
import {TrueFalseQuizFormGroup} from '../quiz-makers/standard-question/true-false-quiz/TrueFalseQuizFormGroup';

@Injectable({
    providedIn: 'root'
})
export class TrueFalseQuestionValidatorService extends QuestionValidatorService {

    public validateFullQuiz(formData: FormGroup<TrueFalseQuizFormGroup>): boolean {
        return formData.valid && this.checkIfOneAnswerCorrect(formData);
    }

    public checkIfOneAnswerCorrect(data: FormGroup<TrueFalseQuizFormGroup>): boolean {
        const controlPositiveAnswerIsCorrect = (data.get('positiveAnswer') as UntypedFormGroup).controls['isCorrectAnswer'].value as boolean;
        const controlNegativeAnswerIsCorrect = (data.get('negativeAnswer') as UntypedFormGroup).controls['isCorrectAnswer'].value as boolean;
        const correctAnswerNumber = [controlPositiveAnswerIsCorrect, controlNegativeAnswerIsCorrect].filter(answer => answer).length;
        return correctAnswerNumber === 1;
    }

}
