import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {QuizMakerService} from '../../../../core/editor/quiz-maker.service';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {FormControl, FormGroup, UntypedFormGroup} from '@angular/forms';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {EditorQuestionsService} from '../../../../core/editor/editor-questions.service';
import {EditorQuizCreatorService} from '../../../../core/editor/editor-quiz-creator.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FileHandle} from '../../../../core/drag-and-drop/drag-and-drop.directive';
import {DragAndDropService} from '../../../../core/drag-and-drop/drag-and-drop.service';
import {DomSanitizer} from '@angular/platform-browser';
import {QuizQuestionForm} from '../../../../core/editor/types/QuizQuestionForm';
import {QuizExplanationForm} from '../../../../core/editor/types/QuizExplanationForm';
import {TrueFalseQuizEditor} from '../../../../core/editor/types/TrueFalseQuiz/TrueFalseQuizEditor';
import {TrueFalseQuiz} from '../../../../core/editor/types/TrueFalseQuiz/TrueFalseQuiz';
import {SingleTextAnswersQuizEditor} from '../../../../core/editor/types/TrueFalseQuiz/TrueFalseAnswersQuizEditor';
import {EditorResource} from '../../../../core/rest/editor-resource.service';
import {Subject, Subscription, takeUntil} from 'rxjs';
import {QuizTranslation} from '../../../../core/api-types/quizTranslation';
import {TrueFalseQuestionValidatorService} from '../../../validation/true-false-question-validator.service';
import {QuizMediaEditor} from '../../../../core/editor/types/QuizMediaEditor';
import {QuestionType} from '../../../../core/editor/question.type';
import {QuizTypeName} from '../../../../core/editor/types/QuizType';
import {ToastrService} from 'ngx-toastr';
import {filter, take} from 'rxjs/operators';
import {TranslationService} from '../../../../core/rest/translation.service';
import {FeaturesService} from '../../../../core/features/features.service';
import {PopupService} from '../../../../components/popup/popup.service';
import {PasteTextPopupComponent} from '../../../paste-text-popup/paste-text-popup.component';
import {Quiz} from '../../../../core/api-types/quiz';
import {Tag} from '../../../../core/api-types/Tag';
import {SelectedTag} from '../../../../core/api-types/SelectedTag';
import {SelectedNewTag} from '../../../../core/api-types/SelectedNewTag';
import {TrueFalseQuizFormGroup} from './TrueFalseQuizFormGroup';

@Component({
    selector: 'app-true-false-quiz',
    templateUrl: './true-false-quiz.component.html',
    styleUrls: ['./true-false-quiz.component.scss']
})
export class TrueFalseQuizComponent implements OnInit, OnDestroy {
    @Input() queryParams: { filterText: string; sort: string; filterLanguage?: string; };

    public quiz: TrueFalseQuiz;
    public quizQuestionMedia: QuizMediaEditor;
    public quizExplanationMedia: QuizMediaEditor;
    public quizTypeData: QuestionType;
    public selectedLearningPackageId: string;
    public questionFileDrop: boolean = false;
    public explanationFileDrop: boolean = false;
    public questionHovering = false;
    public explanationHovering = false;
    public languages: string[];
    public activeQuizSubscription = new Subscription();
    public activeTranslationQuiz: QuizTranslation;
    public formDataChangedSubscription = new Subscription();
    public quizQuestionMediaEditorChangedSubscription = new Subscription();
    public quizExplanationMediaEditorChangedSubscription = new Subscription();
    public learningPackageAvailableTagsSubscription = new Subscription();

    public availableTags: Tag[];
    public selectedAvailableTags: SelectedTag[] = [];
    public selectedNewTags: SelectedNewTag[] = [];

    public alreadyTranslated: boolean = false;
    public advancedPanelOpen: boolean = false;

    constructor(public quizMakerService: QuizMakerService,
                public editorQuizzesService: EditorQuestionsService,
                public editorLearningPackageService: EditorLearningPackageService,
                private router: Router,
                public editorQuizService: EditorQuestionService,
                private popupService: PopupService,
                private editorQuizCreatorService: EditorQuizCreatorService,
                private translationService: TranslationService,
                public dragAndDropService: DragAndDropService,
                private sanitizer: DomSanitizer,
                public featuresService: FeaturesService,
                private editorService: EditorResource,
                public validator: TrueFalseQuestionValidatorService,
                private activatedRoute: ActivatedRoute,
                private toastr: ToastrService) {
    }

    public formData: FormGroup<TrueFalseQuizFormGroup> = this.createFormGroup();

    private createFormGroup(): FormGroup<TrueFalseQuizFormGroup> {
        return new FormGroup<TrueFalseQuizFormGroup>({
            question: new FormControl<string>('', {
                validators: this.validator.questionFormValidation,
                nonNullable: true
            }),
            explanation: new FormControl<string>('', this.validator.explanationFormValidation),
            positiveAnswer: new FormGroup<{ isCorrectAnswer: FormControl<boolean>; }>({
                isCorrectAnswer: new FormControl<boolean>(false, this.validator.isCorrectAnswerValidation)
            }),
            negativeAnswer: new FormGroup<{ isCorrectAnswer: FormControl<boolean>; }>({
                isCorrectAnswer: new FormControl<boolean>(false, this.validator.isCorrectAnswerValidation)
            }),
            sourceUrl: new FormControl<string>('', this.validator.sourceUrl),
            sourceLabel: new FormControl<string>('', this.validator.sourceLabel)
        }, {validators: this.validator.sourceUrlRequired});
    }

    public ngOnInit(): void {
        this.selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        if (this.selectedLearningPackageId !== undefined) {
            this.editorService.getLearningPackageLanguages(this.selectedLearningPackageId).subscribe((languages: string[]) => this.languages = languages);
        }
        this.activeQuizSubscription = this.editorQuizService.activeTranslationQuiz$.pipe(filter(quiz => quiz !== undefined)).subscribe((quiz: QuizTranslation) => {
            this.availableTags = undefined;
            this.selectedNewTags = [];
            this.learningPackageAvailableTagsSubscription = this.editorLearningPackageService.learningPackageAvailableTags$.subscribe((availableTags: Tag[]) => this.availableTags = availableTags);
            if (quiz !== undefined && quiz.quizType === QuizTypeName.TRUE_FALSE_TYPE) {
                this.activeTranslationQuiz = quiz;
                this.quiz = new TrueFalseQuiz(quiz.id, quiz.quizId, quiz.quiz, quiz.positiveAnswer, quiz.negativeAnswer, quiz?.explanation, quiz.sourceUrl, quiz.sourceLabel, quiz.originalContentHash);
                this.quizQuestionMedia = new QuizMediaEditor('question', this.quiz.quiz?.mediaType, this.quiz.quiz?.mediaUrl, this.quiz.quiz?.imageUrl);
                this.quizExplanationMedia = new QuizMediaEditor('explanation', this.quiz.explanation?.mediaType, this.quiz.explanation?.mediaUrl, this.quiz.explanation?.imageUrl);
                this.selectedAvailableTags = quiz.tags !== undefined && quiz.tags.length > 0 ? quiz.tags.map(tag => new SelectedTag(tag)) : [];
            }
            this.quizTypeData = this.editorQuizService.quizTypes.find(quizType => quizType.type === this.quiz.quizType);
            this.clearQuizForm();
            this.cleanFiles();
            this.setQuizDataToForm();

            this.formData.valueChanges.subscribe(() => {
                this.editorQuizzesService.setQuestionDataChanged(true);
            });
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.formDataChangedSubscription = this.editorQuizzesService.formDataChanged.subscribe(async (status: boolean) => {
            if (status) {
                await this.saveCurrentQuizState(this.formData);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizQuestionMediaEditorChangedSubscription = this.editorQuizzesService.quizQuestionMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizQuestionMedia = this.editorQuizzesService.getQuestionQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizQuestionMediaEditorChanged.next(false);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizExplanationMediaEditorChangedSubscription = this.editorQuizzesService.quizExplanationMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizExplanationMedia = this.editorQuizzesService.getExplanationQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizExplanationMediaEditorChanged.next(false);
            }
        });
    }

    private destroy$ = new Subject();

    public ngOnDestroy(): void {
        this.activeQuizSubscription.unsubscribe();
        this.formDataChangedSubscription.unsubscribe();
        this.quizQuestionMediaEditorChangedSubscription.unsubscribe();
        this.quizExplanationMediaEditorChangedSubscription.unsubscribe();
        this.learningPackageAvailableTagsSubscription.unsubscribe();

        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public async changeLanguage(language: string): Promise<void> {
        if (this.editorQuizzesService.quizSaved.value) {
            this.alreadyTranslated = false;
            void this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                        language: language
                    },
                    queryParamsHandling: 'merge'
                });
        } else {
            const url = `/editor/lernpakete/${this.selectedLearningPackageId}/fragen/${this.quiz.quizId}?language=${language}`;
            await this.editorQuizzesService.openCancelQuestion(url);
        }
    }

    public isDisabled: boolean = false;

    private enable(): void {
        this.formData.enable();
        this.isDisabled = false;
    }

    private disable(): void {
        this.formData.disable();
        this.isDisabled = true;
    }

    public translate: () => Promise<void> = async () => {

        return new Promise(resolve => {
            this.disable();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            this.editorQuizService.activeQuestionMainLanguage$.pipe(take(1))
                .subscribe((questionTranslation: QuizTranslation) => {
                    this.translationService.translateQuestion(questionTranslation, this.activeTranslationQuiz.language)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((translatedQuiz: Quiz | undefined) => {
                            if (translatedQuiz !== undefined) {
                                this.formData.get('question').setValue(translatedQuiz.quiz.text);
                                this.formData.get('explanation').setValue(translatedQuiz.explanation?.text);
                                this.formData.get('positiveAnswer').get('isCorrectAnswer').setValue(questionTranslation.positiveAnswer.isCorrectAnswer);
                                this.formData.get('negativeAnswer').get('isCorrectAnswer').setValue(questionTranslation.negativeAnswer.isCorrectAnswer);
                                this.quizQuestionMedia.mediaUrl = questionTranslation.quiz.mediaUrl;
                                this.quizQuestionMedia.imageUrl = questionTranslation.quiz.imageUrl;
                                this.quizQuestionMedia.type = questionTranslation.quiz.mediaType;

                                this.quizExplanationMedia.mediaUrl = questionTranslation.explanation?.mediaUrl;
                                this.quizExplanationMedia.imageUrl = questionTranslation.explanation?.imageUrl;
                                this.quizExplanationMedia.type = questionTranslation.explanation?.mediaType;

                                this.alreadyTranslated = true;
                            }

                            this.enable();
                            resolve();
                        });
                });
        });
    };

    public async filesDropped(files: FileHandle[], type: 'question' | 'explanation'): Promise<void> {
        const file = files[0];
        const fileAllowed = this.dragAndDropService.isFileAllowed(file.file);
        if (!fileAllowed.error) {
            if (type === 'question') {
                this.quizQuestionMedia.imageFile = file;
                this.questionFileDrop = true;
                if (this.quizQuestionMedia.type === undefined) {
                    this.quizQuestionMedia.type = 'image';
                }
            }

            if (type === 'explanation') {
                this.quizExplanationMedia.imageFile = file;
                this.explanationFileDrop = true;
                if (this.quizExplanationMedia.type === undefined) {
                    this.quizExplanationMedia.type = 'image';
                }
            }
            this.editorQuizzesService.setQuestionDataChanged(true);
        } else {
            for (const errorText of fileAllowed.text) {
                this.toastr.error(errorText);
            }
        }
    }

    public async removeMedia(type: 'question' | 'explanation'): Promise<void> {
        if (type === 'question') {
            this.quizQuestionMedia = new QuizMediaEditor('question', undefined);
            this.questionFileDrop = false;
        } else {
            this.quizExplanationMedia = new QuizMediaEditor('explanation', undefined);
            this.explanationFileDrop = false;
        }

        this.editorQuizzesService.setQuestionDataChanged(true);
    }

    public keytab(event: KeyboardEvent): void {
        event.preventDefault();
    }

    public cancelQuiz(): void {
        if (this.editorQuizzesService.formDataChanged.value) {
            this.editorQuizzesService.quizSaved.next(true);
            this.editorQuizzesService.setQuestionDataChanged(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${this.selectedLearningPackageId}/fragen`],
                {queryParams: this.queryParams});
        }
    }

    public async saveQuiz(data: FormGroup<TrueFalseQuizFormGroup>): Promise<void> {
        if (this.validator.validateFullQuiz(data)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuiz(quizEditor, this.quiz,
                this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);
            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    public async saveAndCreateNevQuiz(data: FormGroup<TrueFalseQuizFormGroup>): Promise<void> {
        if (this.validator.validateFullQuiz(data)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuizAndAddNew(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);
            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    public setFalseAnswerAsCorrect(): void {
        const controlPositiveAnswer = this.formData.get('positiveAnswer') as UntypedFormGroup;
        controlPositiveAnswer.controls['isCorrectAnswer'].setValue(false);
    }

    public setTrueAnswerAsCorrect(): void {
        const controlNegativeAnswer = this.formData.get('negativeAnswer') as UntypedFormGroup;
        controlNegativeAnswer.controls['isCorrectAnswer'].setValue(false);
    }


    public translationNotExist(quizzes: QuizTranslation[]): boolean {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return !quizzes.find(quiz => quiz.language === this.activeTranslationQuiz.language);
    }

    private clearQuizForm(): void {
        this.formData = this.createFormGroup();
    }

    private setQuizDataToForm(): void {
        this.formData.controls['question'].setValue(this.quiz.quiz.text);
        const controlPositiveAnswer = this.formData.get('positiveAnswer') as UntypedFormGroup;
        controlPositiveAnswer.controls['isCorrectAnswer'].setValue(this.quiz?.positiveAnswer?.isCorrectAnswer);
        const controlNegativeAnswer = this.formData.get('negativeAnswer') as UntypedFormGroup;
        controlNegativeAnswer.controls['isCorrectAnswer'].setValue(this.quiz?.negativeAnswer?.isCorrectAnswer);
        if (this.quiz?.explanation?.text !== undefined) {
            this.formData.controls['explanation'].setValue(this.quiz.explanation.text);
        }
        this.formData.get('sourceUrl').setValue(this.quiz.sourceUrl);
        this.formData.get('sourceLabel').setValue(this.quiz.sourceLabel);
    }

    private async saveCurrentQuizState(data: UntypedFormGroup): Promise<void> {
        const quizEditor = this.buildChangedQuiz(data);
        const quizData = await this.editorQuizCreatorService.createQuizData(quizEditor, this.quiz, this.quizQuestionMedia, this.quizExplanationMedia);
        this.editorQuizzesService.setEditingQuizFormData(quizData, this.validator.validateFullQuiz(this.formData));
        if (this.editorQuizzesService.quizSaved.value) {
            this.editorQuizzesService.quizSaved.next(false);
        }
    }

    private buildChangedQuiz(data: UntypedFormGroup): TrueFalseQuizEditor {
        const positiveAnswer = data.get('positiveAnswer')?.value as SingleTextAnswersQuizEditor;
        const negativeAnswer = data.get('negativeAnswer')?.value as SingleTextAnswersQuizEditor;

        const questionMedia = this.editorQuizzesService.quizQuestionMediaEditorChanged.value ? this.editorQuizzesService.getQuestionQuizMediaEditor() : this.quizQuestionMedia;
        const quizQuestionForm = new QuizQuestionForm(data.get('question').value as string, questionMedia);

        const explanationMedia = this.editorQuizzesService.quizExplanationMediaEditorChanged.value ? this.editorQuizzesService.getExplanationQuizMediaEditor() : this.quizExplanationMedia;
        const quizExplanationForm = new QuizExplanationForm(data.get('explanation').value as string, explanationMedia);

        const sourceUrl = data.controls.sourceUrl.value as string;
        const sourceLabel = data.controls.sourceLabel.value as string;

        return new TrueFalseQuizEditor(positiveAnswer, negativeAnswer, quizExplanationForm, quizQuestionForm, this.selectedAvailableTags, this.selectedNewTags, sourceUrl, sourceLabel);
    }


    private cleanFiles(): void {
        this.questionFileDrop = undefined;
        this.explanationFileDrop = undefined;
    }

    public getFormGroup(control: string): UntypedFormGroup {
        return this.formData.get(control) as UntypedFormGroup;
    }

    public async openImageAndMediaPopup(quizMediaElement: 'question' | 'explanation'): Promise<void> {
        if (quizMediaElement === 'question') {
            this.editorQuizzesService.setQuestionQuizMediaEditor(this.quizQuestionMedia);
        } else {
            this.editorQuizzesService.setExplanationQuizMediaEditor(this.quizExplanationMedia);
        }
        this.editorQuizzesService.openImageAndMediaPopup(quizMediaElement);
    }

    public startPasteFlow(): void {
        void this.popupService.open(PasteTextPopupComponent, this.quizTypeData);
    }
}
