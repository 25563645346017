<div class="mm-card-container"  [class.mm-card-square]="type === 'square'">
    <div class="mm-card"
         [title]="title"
         [class.mm-is-selected]="isSelected"
         [class.mm-ai-feature]="isAiFeature"
         [class.mm-card-flat]="type === 'flat'"
         (click)="cardClicked.emit()">
        <app-icon [icon]="icon" class="mm-card-icon" *ngIf="icon"></app-icon>
        <div class="mm-card-infos">
            <div class="mm-card-title" title="{{title}}">
                {{title}}
            </div>
            <div class="mm-card-description">
                {{description}}
            </div>
        </div>
    </div>
</div>
