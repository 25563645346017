import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CancelSavingPopupData} from '../../components/cancel-saving-popup/CancelSavingPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {CancelSavePopupResult} from '../../components/cancel-saving-popup/CancelSavePopupResult';
import {CancelSavingPopupComponent} from '../../components/cancel-saving-popup/cancel-saving-popup.component';
import {PopupService} from '../../components/popup/popup.service';
import {HttpErrorResponse} from '@angular/common/http';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LearningPackageSettingsService {
    public learningPackageSettingSaved = new BehaviorSubject<boolean>(true);
    public learningPackageSettingSaved$ = this.learningPackageSettingSaved.asObservable();

    public learningPackageSettings: { learningPackageTranslationId: string; name: string; introduction: string; };

    constructor(private popupService: PopupService,
                public editorLearningPackageService: EditorLearningPackageService,
                private editorService: EditorResource,
                private toastr: ToastrService,
                private router: Router) {
    }

    public setLearningPackageSettingSavedStatus(value: boolean): void {
        this.learningPackageSettingSaved.next(value);
    }

    public async checkIfLearningPackageSettingIsSaved(url?: string): Promise<boolean> {
        if (this.learningPackageSettingSaved.value) {
            return true;
        }
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<CancelSavingPopupData, CancelSavePopupResult>(CancelSavingPopupComponent, this.getCancelSavingPopupData(), popupOptions);
        if (popupServiceResult === 'Save') {
            this.saveLearningPackageSettings();
        } else if (popupServiceResult === 'Discard') {
            this.setLearningPackageSettingSavedStatus(true);
            this.setLearningPackage(undefined);
            this.redirect(url);
        }
        return false;
    }

    public getCancelSavingPopupData(): CancelSavingPopupData {
        return new CancelSavingPopupData(true, $localize`Möchtest du deine Änderungen speichern? Änderungen die hier vorgenommen werden, werden dem Lernenden automatisch im Chat mit Micromate angezeigt.`);
    }

    private redirect(url: string): void {
        const urlData = url.split('?language=');
        if (urlData.length > 1) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([urlData[0]], {
                queryParams: {
                    language: urlData[1]
                }
            });
        } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([url]);
        }
    }

    public setLearningPackage(learningPackage: { learningPackageTranslationId: string; name: string; introduction: string; }): void {
        this.learningPackageSettings = learningPackage;
    }

    public getLearningPackage(): { learningPackageTranslationId: string; name: string; introduction: string; } {
        return this.learningPackageSettings;
    }

    public saveLearningPackageSettings(): void {
        const learningPackageData = this.getLearningPackage();
        const selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        if (learningPackageData !== undefined) {
            this.editorService.editLearningPackageBasicInfo(selectedLearningPackageId, learningPackageData).then(() => {
                this.toastr.success($localize`Lernpakete wurden gespeichert`);
                this.editorLearningPackageService.selectedLearningPackageId.next(selectedLearningPackageId);
                this.setLearningPackageSettingSavedStatus(true);
            }).catch((error: HttpErrorResponse) => {
                this.toastr.error(error.error as string);
            });
        }
    }
}
