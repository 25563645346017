import {AuthService} from '../auth.service';
import {CanActivate} from '@angular/router';
import {Injectable} from '@angular/core';
import {User} from '../../api-types/user';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authService: AuthService) {
    }

    public async canActivate(): Promise<boolean> {
        const authenticatedUser: User = this.authService.getAuthenticatedUser();
        if (authenticatedUser === undefined) {
            this.authService.startLoginFlow();
            return false;
        }

        return true;
    }
}

