import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AnalyticsResource} from '../../core/rest/analytics-rest/analytics-resource.service';
import {AnalyticsGroupOverview} from '../../core/viewmodel/analyticsGroupOverview';
import {BaseChartDirective, Color, Label} from 'ng2-charts';
import {ChartDataSets, ChartOptions, ChartTooltipItem} from 'chart.js';
import * as moment from 'moment';
import {KPIsDto} from '../../core/rest/analytics-rest/KPIsDto';
import {combineLatest, Subscription} from 'rxjs';
import {TimeSplitMode} from '../../core/rest/analytics-rest/TimeSplitMode';
import {DatePeriodDataPointDto} from '../../core/rest/analytics-rest/DatePeriodDataPointDto';
import {Group} from '../../core/api-types/group';
import {OrganizationService} from '../../core/organization.service';
import {GroupService} from '../../core/group.service';
import {AnalyticsGroupLearningPackageService} from '../../core/analytics/analytics-group-learning-package.service';
import {LearningPackageResource} from '../../core/rest/learning-package-resource.service';
import {Router} from '@angular/router';
import {LearningPackageGroupOverview} from '../../core/api-types/learningPackageGroupOverview';
import {filter} from 'rxjs/operators';
import {TimestampSentenceCreator} from '../../core/analytics/TimestampSentenceCreator';
import {FeaturesService} from '../../core/features/features.service';

@Component({
    selector: 'app-analytics-group-detail',
    templateUrl: './analytics-group-detail.component.html',
    styleUrls: ['./analytics-group-detail.component.scss']
})
export class AnalyticsGroupDetailComponent implements OnInit, OnDestroy {

    @ViewChild(BaseChartDirective, {static: true}) userChart: BaseChartDirective;
    @ViewChild(BaseChartDirective, {static: true}) convoChart: BaseChartDirective;

    public viewModel: AnalyticsGroupOverview;
    public viewModelObservable = new Subscription();
    public staticKpis: KPIsDto;
    public latestTimestampSentence: string;
    public chartLabels: Label[] = [];
    public splitMode: TimeSplitMode;
    public timeSplitMode: typeof TimeSplitMode;
    public currentDate: Date;

    constructor(public featuresService: FeaturesService,
                private analyticsRestService: AnalyticsResource,
                private organizationService: OrganizationService,
                private groupService: GroupService,
                private analyticsGroupLearningPackageService: AnalyticsGroupLearningPackageService,
                private learningPackageRestService: LearningPackageResource,
                private router: Router) {
    }

    public chartOptions: (ChartOptions & { annotation: unknown; }) = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                ticks: {
                    autoSkip: false,
                    maxRotation: 90,
                    minRotation: 20,
                }
            }],
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true
                    }
                }
            ]
        },
        elements: {
            line: {
                tension: 0
            }
        },
        annotation: {
            annotations: [
                {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x-axis-0',
                    value: 'March',
                    borderColor: 'orange',
                    borderWidth: 2,
                    label: {
                        enabled: true,
                        fontColor: 'orange',
                        content: 'LineAnno'
                    }
                },
            ],
        },
        hover: {
            mode: 'single',
            intersect: false
        },
        tooltips: {
            mode: 'single',
            intersect: false,
            backgroundColor: 'rgb(0, 0, 0)',
            callbacks: {
                label: (tooltipItem: ChartTooltipItem): string => {
                    return ` ${tooltipItem.yLabel.toLocaleString()} mmm`;
                }
            }
        }
    };

    public lineChartColorsBlue: Color[] = [
        {
            backgroundColor: '#b5eae8',
            borderColor: '#b5eae8',
            pointBackgroundColor: '#b5eae8',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#b5eae8'
        }
    ];
    public lineChartColorsViolet: Color[] = [
        {
            backgroundColor: '#d1a9cd',
            borderColor: '#d1a9cd',
            pointBackgroundColor: '#d1a9cd',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#d1a9cd'
        }
    ];
    public lineChartColorsGreen: Color[] = [
        {
            backgroundColor: '#bedfae',
            borderColor: '#bedfae',
            pointBackgroundColor: '#bedfae',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#bedfae'
        }
    ];

    public engagementChartData: ChartDataSets[] = [
        {
            data: [],
            fill: false
        }
    ];
    public lernfortschrittChartData: ChartDataSets[] = [
        {
            data: [],
            fill: false
        }
    ];
    public activeLearnersChartData: ChartDataSets[] = [
        {
            data: [],
            fill: false
        }
    ];

    public activeLearningPackageId: string;
    public activeGroup: Group;
    public learningPackages: LearningPackageGroupOverview[];

    public ngOnInit(): void {
        this.analyticsGroupLearningPackageService.getActivatedRouteParams();
        this.timeSplitMode = TimeSplitMode;
        this.currentDate = new Date();

        this.viewModelObservable = combineLatest([this.groupService.activeGroup$, this.analyticsGroupLearningPackageService.activeLearnPackageId$]).pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter(([group]: [Group, string]) => !!group))
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            .subscribe(async ([group, activeLearnPackageId]: [Group, string]) => {
                this.activeGroup = group;
                this.activeLearningPackageId = activeLearnPackageId;
                await this.getAnalyticsData();

                this.learningPackageRestService.getAllActiveForGroup(this.activeGroup._id).subscribe(data => {
                    this.learningPackages = data.filter(learningPackage => learningPackage.mainLearningPackage);
                });
            });
    }

    private async getAnalyticsData(): Promise<void> {
        this.viewModel = await this.analyticsRestService.getAnalyticsGroupOverview(this.activeGroup._id, this.activeLearningPackageId);
        void this.getChartsData();
        void this.getKpis();
    }

    private async getKpis(): Promise<void> {
        this.staticKpis = await this.analyticsRestService.getKPIs(this.viewModel.groupId, this.activeLearningPackageId);
        this.latestTimestampSentence = new TimestampSentenceCreator().create(this.staticKpis?.latestQuizAnswerDate, this.currentDate);
    }

    public ngOnDestroy(): void {
        this.viewModelObservable.unsubscribe();
        this.analyticsGroupLearningPackageService.unSubscribeActiveRoute();
    }

    public async setTimePeriod(mode: TimeSplitMode): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['analytics', 'lerngruppen', this.activeGroup._id], {
            queryParams: {
                learningPackageId: this.activeLearningPackageId,
                timePeriod: mode
            }
        });
    }

    private async getChartsData(): Promise<void> {
        this.splitMode = this.analyticsGroupLearningPackageService.getTimePeriod();
        const engagementDataPoints = await this.getEngagementChartData();
        this.chartLabels = this.getChartLabels(engagementDataPoints);
        void this.getLernFortSchittChartData();
        void this.getActiveLearnersChartData();
    }

    private async getEngagementChartData(): Promise<DatePeriodDataPointDto[]> {
        const engagementDataPoints = await this.analyticsRestService.getEngagementForPreviousTimePeriod(this.viewModel.groupId, this.splitMode, this.activeLearningPackageId);
        this.engagementChartData[0].data = engagementDataPoints.map(engagement => engagement.value);
        return engagementDataPoints;
    }

    private async getActiveLearnersChartData(): Promise<void> {
        const datePeriodDataPointDtos = await this.analyticsRestService.getActiveLearnersForPreviousTimePeriod(this.viewModel.groupId, this.splitMode, this.activeLearningPackageId);
        this.activeLearnersChartData[0].data = datePeriodDataPointDtos.map(learners => learners.value);
    }

    private async getLernFortSchittChartData(): Promise<void> {
        const datePeriodDataPointDtos = await this.analyticsRestService.getSuccessRatioForPreviousTimePeriod(this.viewModel.groupId, this.splitMode, this.activeLearningPackageId);
        this.lernfortschrittChartData[0].data = datePeriodDataPointDtos.map(successRatio => successRatio.value);
    }

    private getChartLabels(datePeriodDataPointDtos: DatePeriodDataPointDto[]): string[] {
        if (this.splitMode === TimeSplitMode.Daily) {
            return datePeriodDataPointDtos.map(dataPoint => moment(dataPoint.from).format('DD.MM').toString());
        } else if (this.splitMode === TimeSplitMode.Monthly) {
            return datePeriodDataPointDtos.map(dataPoint => moment(dataPoint.from).format('MMM YY').toString());
        } else {
            return datePeriodDataPointDtos.map(dataPoint => `KW ${moment(dataPoint.from).isoWeek().toString()}`);
        }
    }

    public changeActiveLearningPackage(selectValue: string): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['analytics', 'lerngruppen', this.activeGroup._id], {
            queryParams: {
                learningPackageId: selectValue,
                timePeriod: this.analyticsGroupLearningPackageService.getTimePeriod()
            }
        });
    }

    public createChartOptions(chartType: 'engagement' | 'lernfortschritt' | 'activeLearners'): (ChartOptions & { annotation: unknown; }) {
        const options = this.chartOptions;
        let tooltipsInfo: string = '';
        if (chartType === 'engagement') {
            tooltipsInfo = ' Fragen';
        }
        if (chartType === 'lernfortschritt') {
            tooltipsInfo = '%';
        }
        if (chartType === 'activeLearners') {
            tooltipsInfo = ' Lernende';
        }
        options.tooltips.callbacks.label = (tooltipItem: ChartTooltipItem): string => {
            const tooltipValue = tooltipItem.yLabel as number;
            const value: string = chartType === 'engagement' || chartType === 'lernfortschritt' ? tooltipValue.toFixed(1).toLocaleString() : tooltipValue.toLocaleString();
            return ` ${value}${tooltipsInfo}`;
        };
        return options;
    }

    public formatPoints(points: number): string {
        return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    }
}
