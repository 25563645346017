import {Component, Input} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LearnerResource} from '../../../core/rest/learner-resource.service';
import {OrganizationResources} from '../../../core/rest/organization-resources.service';
import {AdminService} from '../../../core/admin/admin.service';
import {OrganizationAdminService} from '../../../core/admin/organization-admin.service';
import {Popup} from '../../../components/popup/Popup';
import {PopupService} from '../../../components/popup/popup.service';
import {OrganizationEventRemovalPopupResult} from './OrganizationEventRemovalPopupResult';
import {OrganizationEventRemovalPopupData} from './OrganizationEventRemovalPopupData';

@Component({
    selector: 'app-remove-organization-events-popup',
    templateUrl: './remove-organization-events-popup.component.html',
    styleUrls: ['./remove-organization-events-popup.component.scss']
})
export class RemoveOrganizationEventsPopupComponent implements Popup<OrganizationEventRemovalPopupData> {
    @Input()
    public data: OrganizationEventRemovalPopupData;

    public formData = new UntypedFormGroup({
        name: new UntypedFormControl('', [Validators.required, Validators.maxLength(50)])
    });

    constructor(private learnerRestService: LearnerResource,
                private organizationRestService: OrganizationResources,
                private adminService: AdminService,
                private organizationAdminService: OrganizationAdminService,
                private popupService: PopupService) {
    }

    public removeOrganizationEvents: () => Promise<void> = async () => {
        return new Promise(resolve => {
            this.organizationRestService.removeEvents(this.data.id).subscribe(
                () => {
                    this.popupService.close<OrganizationEventRemovalPopupResult>('eventsRemoved');
                    this.clearForm();
                    resolve();
                },
                () => {
                    this.popupService.close<OrganizationEventRemovalPopupResult>('removingEventsError');
                    this.clearForm();
                    resolve();
                });
        });
    };

    public cancel(): void {
        this.popupService.close();
    }

    private clearForm(): void {
        this.formData.controls['name'].setValue('');
    }
}
