<div class="micromate-app-navigation-options"
     *ngIf="!(navigationService.organizationAdminNavigation$ | async) &&
            (authService.authentication$ | async)">
    <a [routerLink]="getUrl(navigationService.adminNavigation, false)"
       *ngIf="(userRoleService.hasSuperAdminRole() | async)"
       class="micromate-app-navigation-option micromate-app-navigation-option-admin"
       (click)="showAppPageNavigation(navigationService.adminNavigation)"
       [class.micromate-app-navigation-option-active]="(navigationService.activeAppNavigationItem$ | async) === navigationService.adminNavigation.url">
        <svg class="micromate-app-navigation-icon" viewBox="0 -100 640 512">
            <use href="./assets/icons.svg#users-crown-light"></use>
        </svg>
        <div class="micromate-app-navigation-title">{{navigationService.adminNavigation.label}}</div>
    </a>
    <a [routerLink]="getUrl(navigationService.learnCenterNavigation, false)"
       class="micromate-app-navigation-option" *ngIf="(userOrganizationRoleService.hasSupervisorRole() | async)"
       (click)="showAppPageNavigation(navigationService.learnCenterNavigation)"
       [class.micromate-app-navigation-option-active]="(navigationService.activeAppNavigationItem$ | async) === navigationService.learnCenterNavigation.url">
        <svg class="micromate-app-navigation-icon" viewBox="0 -125 640 512">
            <use href="./assets/icons.svg#graduation-cap-light"></use>
        </svg>
        <div class="micromate-app-navigation-title">{{navigationService.learnCenterNavigation.label}}</div>
    </a>

    <a [routerLink]="getUrl(navigationService.editorNavigation, false)"
       class="micromate-app-navigation-option" *ngIf="(userOrganizationRoleService.hasContentCreatorRole() | async)"
       (click)="showAppPageNavigation(navigationService.editorNavigation)"
       [class.micromate-app-navigation-option-active]="(navigationService.activeAppNavigationItem$ | async) === navigationService.editorNavigation.url">
        <svg class="micromate-app-navigation-icon" viewBox="0 -90 512 600">
            <use href="./assets/icons.svg#pencil-light"></use>
        </svg>
        <div class="micromate-app-navigation-title">{{navigationService.editorNavigation.label}}</div>
    </a>

    <a [routerLink]="getUrl(navigationService.analyticsNavigation, false)"
       class="micromate-app-navigation-option" *ngIf="(userOrganizationRoleService.hasSupervisorRole() | async)"
       (click)="showAppPageNavigation(navigationService.analyticsNavigation)"
       [class.micromate-app-navigation-option-active]="(navigationService.activeAppNavigationItem$ | async) ===
    navigationService.analyticsNavigation.url">
        <svg class="micromate-app-navigation-icon" viewBox="0 -30 576 512">
            <use href="./assets/icons.svg#analytics-light"></use>
        </svg>
        <div class="micromate-app-navigation-title">{{navigationService.analyticsNavigation.label}}</div>
    </a>
</div>
