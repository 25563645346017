<div class="micromate-card">
    <div class="micromate-card-header">
        <h1 i18n>Manager</h1>
        <app-button type="primary" [action]="saveManagers"
                    [disabled]="(organizationMangerService.organizationManagersSaved$ | async) || !(organizationMangerService.organizationManagersAllowToSave$ | async)"
                    i18n>
            Speichern
        </app-button>
    </div>

    <div *ngIf="managers !== undefined" class="micromate-card-content">
        <div class="micromate-table"
             [class.mm-empty-table]="managers.length === 0">
            <div class="table-container table-container-header">
                <ng-container>
                    <div class="row-header" i18n>Name</div>
                    <div class="row-header" i18n>Organisationseigentümer</div>
                    <div class="row-header" i18n>Supervisor</div>
                    <div class="row-header" i18n>Content-Ersteller</div>
                    <div class="row-header" i18n></div>
                </ng-container>
            </div>
            <div class="table-container-data" *ngIf="managers.length > 0; else emptyTable"
                 [class.table-container-data-with-add-button]="availableNewLearners !== undefined && availableNewLearners.length > 0">
                <div class="table-container table-content" *ngFor="let manager of managers">
                    <div class="row row-name">
                        <div class="row-sub micromate-table-link">
                            {{manager.displayName}}
                        </div>
                    </div>
                    <div class="row mm-form-role-icon">
                        <app-icon icon="user-crown-light" class="mm-manager-role"
                                  [class.micromate-manager-role-disable]="!manager.organizationAdminRole"
                                  (click)="changeRoleStatus(manager, 'organizationAdminRole')"></app-icon>
                    </div>
                    <div class="row mm-form-role-icon">
                        <app-icon icon="user-tie-light" class="mm-manager-role"
                                  [class.micromate-manager-role-disable]="!manager.supervisorRole"
                                  (click)="changeRoleStatus(manager, 'supervisorRole')"></app-icon>
                    </div>
                    <div class="row mm-form-role-icon">
                        <app-icon icon="user-pen-light" class="mm-manager-role"
                                  [class.micromate-manager-role-disable]="!manager.contentCreatorRole"
                                  (click)="changeRoleStatus(manager, 'contentCreatorRole')"></app-icon>
                    </div>
                    <div class="row form-actions">
                        <app-button *ngIf="manager.iamId !== authenticatedUserIamId"
                                    icon='times-light' type="transparent"
                                    (click)="removeManager(manager.iamId)"></app-button>
                    </div>
                </div>
            </div>
            <ng-template #emptyTable>
                <div class="table-empty-filter-results" i18n>
                    Keine Manager vorhanden
                </div>
            </ng-template>
            <div *ngIf="availableNewLearners !== undefined && availableNewLearners.length > 0"
                 class="mm-manager-add-new"
                 [class.mm-manager-add-new-with-dropdown]="selectionOfLearnerOpen">
                <app-button icon="plus-light"
                            *ngIf="!selectionOfLearnerOpen"
                            type="transparent" (click)="selectionOfLearnerOpen = true">
                </app-button>
                <ng-multiselect-dropdown *ngIf="selectionOfLearnerOpen"
                                         class="mm-manager-add-new-dropdown single-selection"
                                         [settings]="newManagerDropdownSettings"
                                         [data]="availableNewLearners"
                                         [placeholder]="'Manager auswählen'"
                                         (onSelect)="addManager($event)">
                </ng-multiselect-dropdown>
            </div>
        </div>
        <app-error-box
            class="mm-organization-manager-error-box"
            *ngIf="managers.length > 0 && (missingAdmin || selfAdminUnselected || emptyRole)">
            <ul class="micromate-error-box-message-list">
                <li *ngIf="missingAdmin" class="micromate-error-box-message-list-element" i18n>
                    Es muss mindestens ein Organisationseigentümer ausgewählt sein.
                </li>
                <li *ngIf="selfAdminUnselected" class="micromate-error-box-message-list-element" i18n>
                    Nur andere Organisationseigentümer können dich als Organisationseigentümer entfernen.
                </li>
                <li *ngIf="emptyRole" class="micromate-error-box-message-list-element" i18n>
                    Jedem Manager muss mindestens eine Rolle zugeteilt sein.
                </li>
            </ul>
        </app-error-box>
        <app-error-box *ngIf="managers.length === 0"
                       class="mm-organization-manager-error-box">
            <div class="mm-manager-error" i18n>
                Es muss mindestens ein Manager mit der Rolle Organisationseigentümer erfasst sein.
            </div>
        </app-error-box>
    </div>
</div>
