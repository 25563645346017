import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {QuizAnalyticsOverview} from '../../../../core/rest/analytics-rest/QuizAnalyticsOverview';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {QuizTranslation} from '../../../../core/api-types/quizTranslation';
import {EditorResource} from '../../../../core/rest/editor-resource.service';
import {Popup} from '../../../../components/popup/Popup';

@Component({
    selector: 'app-question-analytics',
    templateUrl: './question-analytics.component.html',
    styleUrls: ['./question-analytics.component.scss']
})
export class QuestionAnalyticsComponent implements Popup, OnInit, OnDestroy {
    @Input()
    public data: undefined;

    public questionAnalytics: QuizAnalyticsOverview;
    public activeTranslationQuiz$ = new Subscription();

    constructor(private editorQuizService: EditorQuestionService,
                private editorService: EditorResource) {
    }

    public ngOnInit(): void {
        this.activeTranslationQuiz$ = this.editorQuizService.activeTranslationQuiz$.pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter((quiz: QuizTranslation) => !!quiz),
            switchMap((quiz: QuizTranslation) => this.editorService.getQuizAnalytics(quiz.mainLeaningPackageId, quiz.quizId, quiz.language))
        ).subscribe((questionAnalytics: QuizAnalyticsOverview) => this.questionAnalytics = questionAnalytics);
    }

    public ngOnDestroy(): void {
        this.activeTranslationQuiz$.unsubscribe();
    }

}
