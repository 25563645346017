import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    public setItem(key: string, value: string): void {
        localStorage.setItem(key, value);
    }

    public getItem(key: string): string {
        return localStorage.getItem(key);
    }

    public removeItem(key: string): void {
        localStorage.removeItem(key);
    }

    public clear(): void {
        localStorage.clear();
    }

    public setActiveUser(activeUserName: string): void {
        return this.setItem('activeUserName', activeUserName);
    }

    public getActiveUser(): string {
        return this.getItem('activeUserName');
    }

    public removeActiveUser(): void {
        return this.removeItem('activeUserName');
    }

    public setActiveOrganizationId(organizationId: string): void {
        return this.setItem('activeOrganizationId', organizationId);
    }
}
