import {Injectable} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {BehaviorSubject, of} from 'rxjs';
import {shareReplay, switchMap} from 'rxjs/operators';
import {OrganizationResources} from '../rest/organization-resources.service';

@Injectable({
    providedIn: 'root'
})
export class OrganizationAdminService {

    constructor(private router: Router, private organizationRestService: OrganizationResources) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.params.organizationId !== undefined) {
                    const organizationId = path.snapshot.params.organizationId as string;
                    this.selectedOrganizationId.next(organizationId);
                }
            }
        });
    }

    public selectedOrganizationId = new BehaviorSubject<string>(undefined);
    public selectedOrganizationId$ = this.selectedOrganizationId.asObservable();

    public selectedOrganization$ = this.selectedOrganizationId$.pipe(
        switchMap((selectedOrganization: string) => {
            if (selectedOrganization !== undefined) {
                return this.organizationRestService.getOrganization(selectedOrganization);
            }
            return of(undefined);
        }),
        shareReplay(1)
    );

    public refreshOrganizationInfo(): void {
        this.selectedOrganizationId.next(this.selectedOrganizationId.value);
    }
}
