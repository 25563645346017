<div class="micromate-card" *ngIf="groupData !== undefined">
    <h1>{{groupData.name}}</h1>

    <div class="micromate-small-cards">
        <div class="micromate-small-card micromate-small-card-buttons micromate-overview-card-lernende"
             [routerLink]="['lernende']">
            <div class="micromate-small-card-icon">
                <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                    <use href="./assets/icons.svg#user-friends-light"></use>
                </svg>
            </div>
            <div class="micromate-small-card-title">
                <p class="micromate-small-card-title-text"> {{groupData?.learnerIds ? groupData.learnerIds.length : 0}}</p>
                <p class="micromate-small-card-title-subtext">Lernende</p>
            </div>
        </div>

        <div class="micromate-small-card micromate-small-card-buttons micromate-overview-card-lernpakete"
             [routerLink]="['lernpakete']">
            <div class="micromate-small-card-icon">
                <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                    <use href="./assets/icons.svg#boxes-alt-light"></use>
                </svg>
            </div>
            <div class="micromate-small-card-title">
                <p class="micromate-small-card-title-text"> {{groupData?.learningPackages ? groupData.learningPackages.length : 0}}</p>
                <p class="micromate-small-card-title-subtext">Lernpakete</p>
            </div>
        </div>
    </div>
</div>
