import {Component, OnDestroy, OnInit} from '@angular/core';
import {LearningPackage} from '../../core/api-types/learningPackage';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {LearningPackageEditorOverview} from '../../core/api-types/learningPackageEditorOverview';
import {OrganizationService} from '../../core/organization.service';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {PopupService} from '../../components/popup/popup.service';
import {DeleteLearningPackagePopupData} from './delete-learning-package-popup/DeleteLearningPackagePopupData';
import {DeleteLearningPackageErrorPopupComponent} from './delete-learning-package-popup/delete-learning-package-error-popup.component';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {ListPagination} from '../../core/api-types/listPagination';
import {TablePaginationParamsService} from '../../core/table-pagination-params.service';
import {Subscription} from 'rxjs';
import {DeleteLearningPackagePopupResult} from './delete-learning-package-popup/DeleteLearningPackagePopupResult';
import {NewLearningPackagePopupComponent} from './new-learning-package-popup/new-learning-package-popup.component';
import {NewLearningPackagePopupData} from './new-learning-package-popup/NewLearningPackagePopupData';

@Component({
    selector: 'app-learnpackages-overview',
    templateUrl: './learning-packages.component.html',
    styleUrls: ['./learning-packages.component.scss']
})
export class LearningPackagesComponent implements OnInit, OnDestroy {
    public learningPackages: LearningPackageEditorOverview[];
    public paginationParams: ListPaginationRequest;
    public listPagination: ListPagination<LearningPackageEditorOverview>;
    public navigationUrl: string[] = ['editor', 'lernpakete'];
    public activeOrganizationIdSubscription = new Subscription();
    public subscription = new Subscription();
    public learningPackagesListRefreshed: boolean = false;

    constructor(private editorService: EditorResource,
                private organizationService: OrganizationService,
                private editorLearningPackageService: EditorLearningPackageService,
                public multilanguageService: MultilanguageService,
                private popupService: PopupService,
                private tablePaginationParamsService: TablePaginationParamsService) {
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.activeOrganizationIdSubscription.unsubscribe();
        this.tablePaginationParamsService.unSubscribeActiveRoute();
    }

    public ngOnInit(): void {
        this.tablePaginationParamsService.updateFromActivatedRouteParams();

        this.activeOrganizationIdSubscription = this.organizationService.activeOrganizationId$.subscribe(activeOrganizationId => {
            if (activeOrganizationId !== undefined) {
                this.getLearningPackagesData();

                this.editorLearningPackageService.addedNewLearningPackage$.subscribe(status => {
                    if (status) {
                        this.getLearningPackagesData();
                        this.editorLearningPackageService.addedNewLearningPackage.next(false);
                    }
                });
            }
        });
    }

    private getLearningPackagesData(): void {
        this.subscription = this.tablePaginationParamsService.paginationParams$.subscribe((paginationParams: ListPaginationRequest) => {
            this.paginationParams = paginationParams;
            this.learningPackagesListRefreshed = false;
            this.editorService.getAllLearningPackagesForContentCreator(paginationParams).subscribe((listPagination: ListPagination<LearningPackageEditorOverview>) => {
                this.listPagination = listPagination;
                this.learningPackages = listPagination.data.sort((a, b) => new Date(b.lastUpdateDate).valueOf() - new Date(a.lastUpdateDate).valueOf());
                this.learningPackagesListRefreshed = true;
            });
        });
    }

    public checkIfActiveVersionExist(learningPackages: LearningPackage): boolean {
        const activeVersion = learningPackages.versions.find(version => version.active);
        return activeVersion !== undefined;
    }

    public async createNewLearningPackage(): Promise<void> {
        const newLearningPackagePopupData = new NewLearningPackagePopupData();
        await this.popupService.open<NewLearningPackagePopupData>(NewLearningPackagePopupComponent, newLearningPackagePopupData, new PopupOptions());
    }

    public async createNewLearningTranslationPackage(learningPackageId: string): Promise<void> {
        const newLearningPackagePopupData = new NewLearningPackagePopupData(learningPackageId);
        await this.popupService.open<NewLearningPackagePopupData>(NewLearningPackagePopupComponent, newLearningPackagePopupData, new PopupOptions());
    }

    public getLeanPackageStatusText(status: string): string {
        switch (status) {
            case 'published':
                return $localize`veröffentlicht`;
            case 'not published':
                return $localize`nicht veröffentlicht`;
            case 'open changes':
                return $localize`offene Änderungen`;
            default:
                throw new Error(`Unknown learning package status: ${status}`);
        }
    }

    public getLanguages(languages: string[]): string {
        if (languages !== undefined && languages.length > 0) {
            return `, ${languages.map(language => this.multilanguageService.createShortName(language)).join(', ')}`;
        }
        return '';
    }

    public async deleteLearningPackage(learningPackage: LearningPackageEditorOverview): Promise<void> {
        const deleteLearningPackagePopupData = new DeleteLearningPackagePopupData(learningPackage.learningPackageId, learningPackage.name);
        const popupOptions = new PopupOptions();
        const result = await this.popupService.open<DeleteLearningPackagePopupData, DeleteLearningPackagePopupResult>(DeleteLearningPackageErrorPopupComponent, deleteLearningPackagePopupData, popupOptions);
        if (result === 'deleted') {
            this.getLearningPackagesData();
        }
    }
}
