<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Analytics" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <div class="mm-quiz-analytics-group"
             *ngIf="questionAnalytics !== undefined && questionAnalytics.allTime !== undefined">
            <div class="mm-quiz-analytics-info">
                <div class="mm-quiz-analytics-info-title" i18n>Anzahl Versuche:</div>
                <div class="mm-quiz-analytics-info-data">{{questionAnalytics.allTime.attempts}}</div>
            </div>
            <div class="mm-quiz-analytics-info">
                <div class="mm-quiz-analytics-info-title" i18n>Aktuelle Platzierung:</div>
                <div
                    class="mmm-quiz-analytics-info-data">{{questionAnalytics.allTime?.ranking ? (questionAnalytics.allTime.ranking + ' / ' + questionAnalytics.allTime.numberOfAllQuestions) : '-'}}</div>
            </div>
            <div class="mm-quiz-analytics-group-last-days-text" i18n>Die am besten beantwortete Frage ist auf dem
                ersten Platz klassifiziert.
            </div>
        </div>
        <div class="mm-quiz-analytics-group mm-quiz-analytics-group-last-days"
             *ngIf="questionAnalytics !== undefined &&  questionAnalytics.last30Days !== undefined">
            <h2 class="mm-quiz-analytics-group-last-days-title" i18n>Letzte 30 Tage</h2>
            <div class="mm-quiz-analytics-info">
                <div class="mm-quiz-analytics-info-title" i18n>Anzahl Versuche:</div>
                <div class="mm-quiz-analytics-info-data">{{questionAnalytics.last30Days.attempts}}</div>
            </div>
            <div class="mm-quiz-analytics-info">
                <div class="mm-quiz-analytics-info-title" i18n>Erfolgsrate:</div>
                <div
                    class="mm-quiz-analytics-info-data">{{questionAnalytics.last30Days?.successRate === undefined ? '-' : questionAnalytics.last30Days.successRate + '%'}}</div>
            </div>
        </div>
    </app-popup-content>
</app-popup-container>
