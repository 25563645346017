import {VideoDisplay, VideoDisplayType, VideoFormat, VideoFormatType} from './VideoFormat';
import {VideoLink} from './VideoLink';

export class YouTubeVideo {
    public static TYPE: VideoFormatType = VideoFormat.YOUTUBE;
    public static DISPLAY: VideoDisplayType = VideoDisplay.IFRAME;


    public static createVideoLink(): VideoLink {
        return new VideoLink(YouTubeVideo.TYPE, YouTubeVideo.DISPLAY);
    }

    public static isVideoFormat(url: string): boolean {
        const regex = new RegExp('^(https?\\:\\/\\/)?((www\\.)?youtube\\.com|youtu\\.be)\\/.+$');
        const regexNoCookie = new RegExp('^(https?\\:\\/\\/)?((www\\.)?youtube-nocookie\\.com|youtu\\.be)\\/.+$');
        return regex.test(url) || regexNoCookie.test(url);
    }

    public static crateVideoLink(media: string): string {
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = media.match(regExp);
        // eslint-disable-next-line no-null/no-null
        return (match !== null && match.length >= 3) ? `https://www.youtube-nocookie.com/embed/${match[2]}` : undefined;
    }
}
