<div class="micromate-card">
    <div class="micromate-card-header">
        <h1 i18n>Einstellungen</h1>
        <app-editor-language-switcher [currentLanguage]="learningPackage?.language"
                                      [mainLanguage]="learningPackage?.mainLanguage"
                                      (activeLanguage)="changeLanguage($event)"></app-editor-language-switcher>
        <app-button type="primary" (click)="saveLearningPackage(formData.value)"
                    [disabled]="!formData.valid || (learningPackageSettingsService.learningPackageSettingSaved$ | async)"
                    i18n>Speichern
        </app-button>
    </div>

    <ng-container *ngIf="learningPackage !== undefined">
        <form [formGroup]="formData" class="mm-learnpackage-overview-content">
            <div class="mm-learnpackage-info">
                <div class="mm-learnpackage-name-title" i18n>
                    Name:
                    <span class="micormate-form-label-required">*</span>
                </div>
                <div class="mm-learnpackage-name-data micormate-form-element">
                    <input type="text" formControlName="name" class="micormate-form-input"
                           [class.micormate-form-input-validation-error]="formData.controls.name.errors?.trimmedMaxLength">
                    <div *ngIf="formData.controls.name.errors?.trimmedMaxLength" class="micormate-form-validation-error" i18n>Maximal 30
                        Zeichen
                    </div>
                </div>
            </div>

            <div class="mm-learnpackage-info">
                <div class="mm-learnpackage-name-title" i18n>
                    Beschreibung:
                </div>
                <div class="mm-learnpackage-name-data micormate-form-element">
                <textarea type="text" formControlName="introduction" class="micormate-form-textarea"
                          [class.micormate-form-input-validation-error]="formData.value?.introduction !== undefined && formData.controls.introduction.errors?.trimmedMaxLength"></textarea>
                    <div *ngIf="formData.value?.introduction !== undefined && formData.controls.introduction.errors?.trimmedMaxLength"
                         class="micormate-form-validation-error" i18n>
                        Maximal 500 Zeichen
                    </div>
                </div>
            </div>

            <div class="mm-learnpackage-info">
                <div class="mm-learnpackage-name-title" i18n>
                    Sprache:
                </div>
                <div class="mm-learnpackage-name-data">
                    <div class="micormate-form-text">
                        {{getLanguage(learningPackage.language)}}
                    </div>
                </div>
            </div>

            <div class="mm-learnpackage-info">
                <div class="mm-learnpackage-name-title" i18n>
                    Ersteller:
                </div>
                <div class="mm-learnpackage-name-data">
                    <div class="micormate-form-text">
                        {{learningPackage.ownerContentCreator}}
                    </div>
                </div>
            </div>

            <div class="mm-learnpackage-info">
                <div class="mm-learnpackage-name-title" i18n>
                    Erstellungsdatum:
                </div>
                <div class="mm-learnpackage-name-data">
                    <div class="micormate-form-text">
                        {{learningPackage.creationDate  | date:'short'}}
                    </div>
                </div>
            </div>

            <div class="mm-learnpackage-info">
                <div class="mm-learnpackage-name-title" i18n>
                    Letztes Update:
                </div>
                <div class="mm-learnpackage-name-data">
                    <div class="micormate-form-text">
                        {{learningPackage.lastUpdateDate  | date:'short'}}
                    </div>
                </div>
            </div>
        </form>
        <div class="mm-learnpackage-export">
            <app-button class="mm-learnpackage-export-button"
                        type="primary" (click)="exportLearningPackage()"
                        [disabled]="!allowExportFile"
                        i18n>Fragen exportieren
            </app-button>
        </div>
    </ng-container>
</div>
