import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NewResourcePopupService} from '../new-resource-popup.service';
import {AiRessource} from '../../../../core/rest/ai-ressource.service';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {PopupService} from 'src/app/components/popup/popup.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {ResourceUploadError} from '../ResourceuploadError';

@Component({
    selector: 'app-new-resource-settings-popup-page',
    templateUrl: './new-resource-settings-popup-page.component.html',
    styleUrls: ['./new-resource-settings-popup-page.component.scss']
})
export class NewResourceSettingsPopupPageComponent {
    public formData = new FormGroup<{ name: FormControl<string>; }>({
        name: new FormControl<string>('', [Validators.required, Validators.maxLength(100)]),
    });

    constructor(private newResourcePopupService: NewResourcePopupService,
                private aiService: AiRessource,
                private editorLearningPackageService: EditorLearningPackageService,
                private popupService: PopupService,
                private router: Router) {
    }

    public async uploadResource(): Promise<void> {
        this.newResourcePopupService.showSpinner();
        const learningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        const name = this.formData.value.name;
        this.newResourcePopupService.setResourceName(name);

        const file = this.newResourcePopupService.getResourceFile();
        if (file.type === 'text/html' || file.type === 'application/pdf') {
            this.uploadFile(learningPackageId, name, file);
        }
    }

    private uploadFile(learningPackageId: string, name: string, file: File): void {
        this.aiService.importResource(learningPackageId, name, file).subscribe(
            () => {
                this.newResourcePopupService.hideSpinner();
                const selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
                this.editorLearningPackageService.selectedLearningPackageId.next(selectedLearningPackageId);
                void this.router.navigate([`/editor/lernpakete/${selectedLearningPackageId}/ressourcen`]);
                void this.popupService.close();
            },
            (error: HttpErrorResponse) => {
                const resourceUploadError = error.status === 400 ? (error.error as ResourceUploadError) : {unknownError: true};
                this.newResourcePopupService.setUploadFileErrors(resourceUploadError);
                this.newResourcePopupService.hideSpinner();
                this.newResourcePopupService.currentStep = 'result';
            });
    }
}
