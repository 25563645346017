import {MediaType} from './QuizQuestion';
import {FileHandle} from '../../drag-and-drop/drag-and-drop.directive';

export class QuizMediaEditor {
    constructor(public quizElement: 'question' | 'explanation',
                public type: MediaType,
                public mediaUrl?: string,
                public imageUrl?: string,
                public imageFile?: FileHandle) {
    }

}
