import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import {OrganizationLearnerInfoAdminService} from './organization-learner-info-admin.service';
import {OrganizationLearnerBasicInfo} from '../../core/api-types/OrganizationLearnerBasicInfo';
import {OrganizationAdminPanelResource} from '../../core/rest/organization-admin/organization-admin-panel-resource.service';
import {ToastrService} from 'ngx-toastr';
import {take} from 'rxjs/operators';
import {ConfirmationPopupData} from '../../components/confirmation-popup/ConfirmationPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {ConfirmationPopupResult} from '../../components/confirmation-popup/ConfirmationPopupResult';
import {ConfirmationPopupComponent} from '../../components/confirmation-popup/confirmation-popup.component';
import {PopupService} from '../../components/popup/popup.service';

@Component({
    selector: 'app-organization-learner-info',
    templateUrl: './organization-learner-info.component.html',
    styleUrls: ['./organization-learner-info.component.scss']
})
export class OrganizationLearnerInfoComponent {
    public selectedLearner$: Observable<OrganizationLearnerBasicInfo>;
    public deactivationInProgress: boolean = false;

    constructor(private organizationLearnerInfoAdminService: OrganizationLearnerInfoAdminService,
                private organizationAdminPanelRestService: OrganizationAdminPanelResource,
                private toastr: ToastrService,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.selectedLearner$ = this.organizationLearnerInfoAdminService.selectedLearner$;
    }

    public deactivateLearner: () => Promise<void> = async () => {
        this.deactivationInProgress = true;
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.selectedLearner$.pipe(take(1)).subscribe(async learner => {
            try {
                const text = $localize`Lernender wird deaktiviert und aus allen Lerngruppen entfernt. Wollen Sie den Lernenden deaktivieren?`;
                const confirmationPopupData = new ConfirmationPopupData(text, $localize`Deaktivieren`, $localize`Abbrechen`);
                const popupOptions = new PopupOptions('small');
                const popupServiceResult = await this.popupService.open<ConfirmationPopupData, ConfirmationPopupResult>(ConfirmationPopupComponent, confirmationPopupData, popupOptions);
                if (popupServiceResult === 'Ok') {
                    await this.organizationAdminPanelRestService.removeLearner(learner.id);
                    this.organizationLearnerInfoAdminService.refreshLearnerInfo();
                    this.toastr.success($localize`Lernender wurde deaktiviert`);
                }
            } catch (e) {
                this.deactivationInProgress = false;
                this.toastr.error($localize`Lernender können nicht deaktiviert werden`);
            }
        });
    };

    public getStatusTitle(activeInOrganization: boolean): string {
        return activeInOrganization ? $localize`aktiv` : $localize`deaktiviert`;
    }

}
