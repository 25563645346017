<div class="micromate-card" *ngIf="analyticsOverview !== undefined">
    <div class="micromate-card-header">
        <h1 i18n>Analytics</h1>
    </div>

    <div class="micromate-scroll-wrapper">
        <div *ngIf="analyticsOverview.allTime !== undefined">
            <div class="micromate-small-cards ">
                <div class="micromate-small-card micromate-small-card-full-flex-width">
                    <div class="micromate-small-card-icon">
                        <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#ballot-check-light"></use>
                        </svg>
                    </div>
                    <div class="micromate-small-card-title">
                        <p class="micromate-small-card-title-text">
                            {{formatPoints(analyticsOverview.allTime.totalQuizzes)}}</p>
                        <p class="micromate-small-card-title-subtext" i18n>beantwortete Fragen</p>
                    </div>
                </div>
                <div class="micromate-small-card micromate-small-card-full-flex-width">
                    <div class="micromate-small-card-icon">
                        <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#calender-day-light"></use>
                        </svg>
                    </div>
                    <div class="micromate-small-card-title">
                        <p class="micromate-small-card-title-text">
                            {{getTimestampSentence(analyticsOverview.allTime?.latestQuizAnswerDate)}}</p>
                        <p class="micromate-small-card-title-subtext" i18n>wurde die letzte Frage beantwortet</p>
                    </div>
                </div>
                <div class="micromate-small-card micromate-small-card-full-flex-width">
                    <div class="micromate-small-card-icon">
                        <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#user-friends-light"></use>
                        </svg>
                    </div>
                    <div class="micromate-small-card-title ">
                        <p class="micromate-small-card-title-text">
                            {{formatPoints(analyticsOverview.allTime.totalLearners)}}</p>
                        <p class="micromate-small-card-title-subtext" i18n>aktive Lernende</p>
                    </div>
                </div>
            </div>

        </div>


        <ng-container *ngIf="analyticsOverview.last30Days !== undefined">
            <div class="micromate-card-header micromate-card-header-block">
                <h2 i18n>Letzte 30 Tage</h2>
            </div>
            <div class="micromate-small-cards ">
                <div class="micromate-small-card micromate-small-card-full-flex-width">
                    <div class="micromate-small-card-icon">
                        <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#ballot-check-light"></use>
                        </svg>
                    </div>
                    <div class="micromate-small-card-title">
                        <p class="micromate-small-card-title-text">
                            {{formatPoints(analyticsOverview.last30Days.totalQuizzes)}}</p>
                        <p class="mm-small-card-progress-text mm-progress"
                           [class.mm-progress-negative]="isProgressNegative(analyticsOverview.last30Days.totalQuizzesProgress)">
                            {{formatProgressPoints(analyticsOverview.last30Days.totalQuizzesProgress)}}</p>
                        <p class="micromate-small-card-title-subtext" i18n>beantwortete Fragen</p>
                    </div>
                </div>
                <div class="micromate-small-card micromate-small-card-full-flex-width">
                    <div class="micromate-small-card-icon">
                        <svg class="micromate-small-card-icon-svg" viewBox="0 0 640 512">
                            <use href="./assets/icons.svg#user-friends-light"></use>
                        </svg>
                    </div>
                    <div class="micromate-small-card-title ">
                        <p class="micromate-small-card-title-text">
                            {{formatPoints(analyticsOverview.last30Days.activeLearners)}}</p>
                        <p class="mm-small-card-progress-text mm-progress"
                           [class.mm-progress-negative]="isProgressNegative(analyticsOverview.last30Days.activeLearnersProgress)">
                            {{formatProgressPoints(analyticsOverview.last30Days.activeLearnersProgress)}}</p>
                        <p class="micromate-small-card-title-subtext" i18n>aktive Lernende</p>
                    </div>
                </div>
            </div>
            <div class="micromate-small-cards ">
                <div class="micromate-table-card">
                    <p class="micromate-table-card-title-text" i18n>Aktive Organisationen</p>
                    <div class="micromate-table"
                         *ngIf="analyticsOverview.last30Days.activeOrganizations !== undefined">
                        <div class="table-container table-container-header">
                            <ng-container>
                                <div class="row-header" i18n>Name</div>
                                <div class="row-header" i18n>Beantwortete Fragen</div>
                                <div class="row-header" i18n>Entwicklung</div>
                            </ng-container>
                        </div>
                        <div *ngIf="analyticsOverview.last30Days.activeOrganizations.length > 0; else emptyTable"
                             class="table-container-data">
                            <div class="table-container table-content"
                                 *ngFor="let activeOrganization of analyticsOverview.last30Days.activeOrganizations">
                                <div class="row">{{activeOrganization.orgName}}</div>
                                <div class="row">{{activeOrganization.events}}</div>
                                <div class="row mm-progress"
                                     [class.mm-progress-negative]="isProgressNegative(activeOrganization.progress)"
                                >{{formatProgressPoints(activeOrganization.progress)}}</div>
                            </div>
                        </div>

                        <ng-template #emptyTable>
                            <div class="table-empty-filter-results" i18n>
                                keine aktive Organisationen
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
