<app-popup-container>
    <app-popup-wizard-header header="Importeinstellungen" i18n-header></app-popup-wizard-header>

    <app-popup-content>
        <div class="mm-import-tag">
            <div class="mm-import-tag-info-title" i18n>
                Tag-Bezeichnung:
            </div>
            <div class="mm-import-tag-input">
                <input type="text" class="micormate-form-input" [formControl]="tagFormControl"
                       [class.micormate-form-input-validation-error]="tagFormControl.value.length > 50">
                <div *ngIf="tagFormControl.errors?.trimmedMaxLength"
                     class="micormate-form-validation-error" i18n>
                    Maximal 50 Zeichen
                </div>
            </div>
        </div>
    </app-popup-content>

    <app-popup-footer>
        <app-wizard-page-footer
            [isBackVisible]="true"
            [isForwardVisible]="true"
            [hasCustomContent]="false"
            forwardText="{{(this.importWizardService.importActiveQuestionButtonText$ | async)}}"
            [isForwardDisabled]="(this.importWizardService.activeQuestions$ | async).length === 0 || tagFormControl.invalid"
            (navigateForwardClicked)="import()"
            (navigateBackClicked)="goBackToQuestionsSummary()">
        </app-wizard-page-footer>
    </app-popup-footer>
</app-popup-container>
