<div class="micromate-card">
    <h1 i18n>Versionen</h1>

    <div class="micromate-warning"
         *ngIf="notPublishedLearningPackagesLanguages !== undefined && notPublishedLearningPackagesLanguages.length > 0">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 576 512"
             class="micromate-warning-icon">
            <use href="./assets/icons.svg#exclamation-triangle-light"></use>
        </svg>
        <div>
            <div class="micromate-warning-title" i18n>Warnung</div>
            <ul class="micromate-warning-message-list">
                <li *ngFor="let language of notPublishedLearningPackagesLanguages" class="micromate-warning-message"
                    i18n>
                    Die Übersetzung <strong>{{language}}</strong> wurde nicht veröffentlicht, da sie weniger als 6
                    Quizfragen enthält.
                </li>
            </ul>
        </div>
    </div>

    <div class="micromate-table" *ngIf="learningPackageWorkingVersions !== undefined && contentCreators !== undefined ">
        <div class="table-container table-container-header">
            <ng-container>
                <div class="row-header" i18n>Versionsname</div>
                <div class="row-header" i18n>Veröffentlicht durch</div>
                <div class="row-header" i18n>Veröffentlichung</div>
                <div class="row-header">
                    <div i18n>
                        Standardfragen
                    </div>
                    <p class="micromate-text-tooltip" i18n>min. 6 Standardfragen</p>
                </div>
                <div class="row-header" i18n></div>
            </ng-container>
        </div>
        <div class="table-container-data">
            <div class="table-container table-content micromate-learnpackage-working-group">
                <div class="row row-name">
                    <div>
                        Arbeitsversion
                    </div>
                    <div class="row-only-mobile-email">
                        {{learningPackageWorkingVersions.releaseDate | date:'short'}}
                    </div>
                </div>
                <div class="row row-only-web"></div>
                <div class="row row-only-web"></div>
                <div class="row row-only-web">{{getNumberOfStandardQuestions(learningPackageWorkingVersions)}}</div>
                <div class="row row-button">
                    <app-button type="primary" [disabled]="learningPackageIsNotValid()" [action]="publishPackage" i18n>Veröffentlichen</app-button>
                </div>
            </div>

            <div class="table-container table-content micromate-learnpackage-active"
                 *ngIf="learningPackageActiveVersions !== undefined">
                <div class="row row-name">
                    <div>
                        Version {{learningPackageActiveVersions.version}}
                    </div>
                    <div class="row-only-mobile-email">
                        {{learningPackageActiveVersions.releaseDate | date:'short'}}
                    </div>
                </div>
                <div class="row row-only-web">{{getContentCreator(learningPackageActiveVersions.contentCreator)}}</div>
                <div class="row row-only-web">{{learningPackageActiveVersions.releaseDate | date:'short'}}</div>
                <div class="row row-only-web">{{getNumberOfStandardQuestions(learningPackageActiveVersions)}}</div>
                <div class="row  micormate-learnpackage-version-status">veröffentlicht</div>
            </div>

            <div class="table-container table-content" *ngFor="let learningPackage of learningPackageVersions">
                <div class="row row-name">
                    <div class="row-sub">
                        Version {{learningPackage.version}}
                    </div>
                    <div class="row-only-mobile-email">
                        {{learningPackage.releaseDate | date:'short'}}
                    </div>
                </div>
                <div class="row row-only-web">{{getContentCreator(learningPackage.contentCreator)}}</div>
                <div class="row row-only-web">{{learningPackage.releaseDate | date:'short'}}</div>
                <div class="row row-only-web">{{getNumberOfStandardQuestions(learningPackage)}}</div>
                <div class="row "></div>
            </div>
        </div>
    </div>
</div>
