import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OrganizationAdminManager} from '../../core/api-types/organizationManager';
import {CancelSavingPopupData} from '../../components/cancel-saving-popup/CancelSavingPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {CancelSavePopupResult} from '../../components/cancel-saving-popup/CancelSavePopupResult';
import {CancelSavingPopupComponent} from '../../components/cancel-saving-popup/cancel-saving-popup.component';
import {PopupService} from '../../components/popup/popup.service';
import {OrganizationAdminPanelResource} from '../../core/rest/organization-admin/organization-admin-panel-resource.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class OrganizationMangerService {
    public organizationManagersSaved = new BehaviorSubject<boolean>(true);
    public organizationManagersSaved$ = this.organizationManagersSaved.asObservable();

    private organizationManagers: OrganizationAdminManager[];

    private organizationManagersAllowToSave = new BehaviorSubject<boolean>(true);
    public organizationManagersAllowToSave$ = this.organizationManagersAllowToSave.asObservable();

    constructor(private popupService: PopupService,
                private organizationAdminPanelRestService: OrganizationAdminPanelResource,
                private toastr: ToastrService,
                private router: Router) {
    }

    public setOrganizationManagersSavedStatus(value: boolean): void {
        this.organizationManagersSaved.next(value);
    }

    public setOrganizationManagersAllowToSave(value: boolean): void {
        this.organizationManagersAllowToSave.next(value);
    }

    public setOrganizationManagers(managers: OrganizationAdminManager[]): void {
        this.organizationManagers = managers;
    }

    public async checkIfOrganizationSettingIsSaved(url?: string): Promise<boolean> {
        if (this.organizationManagersSaved.value) {
            return true;
        }
        const cancelSavingPopupData = new CancelSavingPopupData(this.organizationManagersAllowToSave.value);
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<CancelSavingPopupData, CancelSavePopupResult>(CancelSavingPopupComponent, cancelSavingPopupData, popupOptions);
        if (popupServiceResult === 'Save') {
            await this.saveOrganizationManagers(url);
        } else if (popupServiceResult === 'Discard') {
            this.redirect(url);
        }
        return false;
    }

    private async saveOrganizationManagers(url: string): Promise<void> {
        try {
            await this.organizationAdminPanelRestService.addOrganizationManagers(this.organizationManagers);
            this.toastr.success($localize`Änderungen gespeichert`);
            this.redirect(url);
        } catch (e) {
            this.toastr.error($localize`Managers konnten nicht geändert werden`);
        }
    }

    private redirect(url: string): void {
        this.setOrganizationManagersSavedStatus(true);
        this.organizationManagers = undefined;
        void this.router.navigate([url]);
    }
}
