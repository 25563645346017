<div class="micromate-card" *ngIf="learningPackageGroupSettings !== undefined && manualActivationTags !== undefined">
    <div class="micromate-group-learnpakete-title">
        <h1 i18n>Einstellungen {{learningPackageGroupSettings.name}}</h1>
        <app-button [action]="saveChanges" [disabled]="(groupLearningPackageSettingService.groupLearningPackageSettingSaved$| async)" i18n>Speichern</app-button>
    </div>

    <div class="micromate-card-content">
        <app-learning-goals-slider [startLearningGoal]="learningPackageGroupSettings.learningGoal"
                                   (newLearningGoal)="changedLearningGoal($event)"></app-learning-goals-slider>

        <app-tag-activation
            [manualActivation]="learningPackageGroupSettings.manualActivation"
            [manualActivationTags]="manualActivationTags"
            (activationTagChanged)="manualActivationChanged($event)">
            <ng-container
                *ngIf="!(groupLearningPackageSettingService.groupLearningPackageSettingSaved$| async) && manualActivationValueChanged && manualActivationTags.length > 0 && initialManualActivation !== learningPackageGroupSettings.manualActivation">
                <app-warning-box *ngIf="!learningPackageGroupSettings.manualActivation"
                                 content="Solange die Option ausgeschaltet ist, sind alle Fragen im Lernpaket für alle Lernenden in der Gruppe freigeschalten."
                                 i18n-content></app-warning-box>
                <app-warning-box *ngIf="learningPackageGroupSettings.manualActivation"
                                 content="Nach der Aktivierung der “Manuellen Aktivierung” sind gewisse Fragen, je nach Konfiguration, nicht mehr für alle Lernenden freigeschalten."
                                 i18n-content></app-warning-box>
            </ng-container>

        </app-tag-activation>
    </div>
</div>
