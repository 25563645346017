import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {ActivatedRoute, ActivationStart, Router} from '@angular/router';
import {LearningGroupResource} from './rest/learning-group-resource.service';
import {shareReplay, switchMap} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    constructor(public router: Router, private groupRestService: LearningGroupResource, private route: ActivatedRoute) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.params.lerngruppeId !== undefined) {
                    const groupId = path.snapshot.params.lerngruppeId as string;
                    if (this.selectedGroupId.value !== groupId) {
                        this.selectedGroupId.next(groupId);
                    }
                } else {
                    this.selectedGroupId.next(undefined);
                }
            }
        });
    }

    public selectedGroupId = new BehaviorSubject<string>(undefined);
    public selectedGroupId$ = this.selectedGroupId.asObservable();

    public activeGroup$ = this.selectedGroupId$.pipe(
        switchMap((selectedGroupId: string) => {
            if (selectedGroupId !== undefined) {
                return this.groupRestService.getGroup(selectedGroupId);
            }
            return of(undefined);
        }),
        shareReplay(1)
    );

    public cleanActiveGroup(): void {
        this.selectedGroupId.next(undefined);
    }
}
