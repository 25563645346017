import {Component, Input} from '@angular/core';
import {Quiz} from '../../../../../../../../core/api-types/quiz';

@Component({
    selector: 'app-multiple-choice-question',
    templateUrl: './multiple-choice-question.component.html',
    styleUrls: ['./multiple-choice-question.component.scss']
})
export class MultipleChoiceQuestionComponent {
    @Input()
    public questionPreview: Quiz;
}
