import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ListPagination} from '../../core/api-types/listPagination';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {OrganizationService} from '../../core/organization.service';
import {Subscription} from 'rxjs';
import {OrganizationAdminPanelResource} from '../../core/rest/organization-admin/organization-admin-panel-resource.service';
import {OrganizationLearner} from 'src/app/core/api-types/organizationLearner';
import {OrganizationAdminService} from '../../core/ogranizationAdmin/organization-admin.service';
import {OrganizationAdminRestService} from '../../core/rest/organization-admin/organization-admin-rest.service';
import {LEARNER_STATE_ACTIVE, LEARNER_STATE_DEACTIVATED, LearnerStatus} from '../../core/api-types/learnerOrganization';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {PopupService} from '../../components/popup/popup.service';
import {OrganizationAddLearnerFormComponent} from './organization-add-learner-form/organization-add-learner-form.component';
import {OrganizationAddLearnerPopupData} from './organization-add-learner-form/OrganizationAddLearnerPopupData';
import {ConfirmationPopupData} from '../../components/confirmation-popup/ConfirmationPopupData';
import {ConfirmationPopupResult} from '../../components/confirmation-popup/ConfirmationPopupResult';
import {ConfirmationPopupComponent} from '../../components/confirmation-popup/confirmation-popup.component';
import {ToastrService} from 'ngx-toastr';
import {OrganizationLearnersTablePaginationParamsService} from './organization-learners-table-pagination-params.service';
import {
    OrganizationLearnersListPaginationRequest,
    OrganizationStatusFilter
} from './OrganizationLearnersListPaginationRequest';
import {Router} from '@angular/router';
import {ActiveOrganizationResponseDto} from '../../core/api-types/ActiveOrganizationResponseDto';

@Component({
    selector: 'app-organisation-learners-overview',
    templateUrl: './organisation-learners-overview.component.html',
    styleUrls: ['./organisation-learners-overview.component.scss']
})
export class OrganisationLearnersOverviewComponent implements OnInit, OnDestroy {
    public learners: OrganizationLearner[];
    public paginationParams: OrganizationLearnersListPaginationRequest;
    public listPagination: ListPagination<OrganizationLearner>;
    public navigationUrl: string[] = ['organisation', 'lernende'];
    public viewModelObservable = new Subscription();
    public getActiveOrganizationDataSubscription = new Subscription();
    public paginationParamsSubscription = new Subscription();
    public addedNewLearnerSubscription = new Subscription();
    public organization: ActiveOrganizationResponseDto;

    @ViewChild('tableContentRef') tableContentRef: ElementRef;

    constructor(public multilanguageService: MultilanguageService,
                private organizationAdminPanelRestService: OrganizationAdminPanelResource,
                private organizationLearnersTablePaginationParamsService: OrganizationLearnersTablePaginationParamsService,
                private organizationService: OrganizationService,
                private organizationAdminService: OrganizationAdminService,
                private organizationAdminRestService: OrganizationAdminRestService,
                private popupService: PopupService,
                private toastr: ToastrService,
                private router: Router) {
    }

    public ngOnInit(): void {
        this.organizationLearnersTablePaginationParamsService.getActivatedRouteParams();

        this.viewModelObservable = this.organizationService.activeOrganizationId$.subscribe(activeOrganizationId => {
            if (activeOrganizationId !== undefined) {
                this.getActiveOrganizationDataSubscription = this.organizationAdminRestService.getActiveOrganizationData(activeOrganizationId).subscribe(organization => {
                    this.organization = organization;
                    this.getLearner();

                    this.addedNewLearnerSubscription = this.organizationAdminService.addedNewLearner$.subscribe(status => {
                        if (status) {
                            this.getLearner();
                            this.organizationAdminService.addedNewLearner.next(false);
                        }
                    });
                });

            }
        });
    }

    public ngOnDestroy(): void {
        this.viewModelObservable.unsubscribe();
        this.organizationLearnersTablePaginationParamsService.unSubscribeActiveRoute();
        this.getActiveOrganizationDataSubscription.unsubscribe();
        this.paginationParamsSubscription.unsubscribe();
        this.addedNewLearnerSubscription.unsubscribe();
    }

    public async openAddLearnerForm(): Promise<void> {
        const organizationAddLearnerPopupData = new OrganizationAddLearnerPopupData(this.organization.domainVerification, this.organization.domains, this.organization.mainChannel, this.organization.activeChannels);
        await this.popupService.open(OrganizationAddLearnerFormComponent, organizationAddLearnerPopupData, new PopupOptions());
    }

    public async openDeactivateLearnerPopup(leanerId: string): Promise<void> {
        const text = $localize`Lernender wird deaktiviert und aus allen Lerngruppen entfernt. Wollen Sie den Lernenden deaktivieren?`;
        const confirmationPopupData = new ConfirmationPopupData(text, $localize`Deaktivieren`, $localize`Abbrechen`);
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<ConfirmationPopupData, ConfirmationPopupResult>(ConfirmationPopupComponent, confirmationPopupData, popupOptions);
        if (popupServiceResult === 'Ok') {
            await this.deactivateLearner(leanerId);
        }
    }

    private async deactivateLearner(leanerId: string): Promise<void> {
        try {
            await this.organizationAdminPanelRestService.removeLearner(leanerId);
            this.organizationAdminService.addedNewLearner.next(true);
            this.toastr.success($localize`Lernender wurde deaktiviert`);
        } catch (e) {
            this.toastr.error($localize`Lernender können nicht deaktiviert werden`);
        }
    }

    public getStatusTitle(status: LearnerStatus): string {
        switch (status) {
            case LEARNER_STATE_ACTIVE:
                return $localize`aktiv`;
            case LEARNER_STATE_DEACTIVATED:
                return $localize`deaktiviert`;
            default:
                return status;
        }
    }

    public changeOrganizationStatusFilter(status: OrganizationStatusFilter): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(
            [],
            {
                queryParams: {organizationStatus: status},
                queryParamsHandling: 'merge'
            }
        );

    }

    private getLearner(): void {
        this.paginationParamsSubscription = this.organizationLearnersTablePaginationParamsService.paginationParams$.subscribe((paginationParams: OrganizationLearnersListPaginationRequest) => {
            this.paginationParams = paginationParams;

            this.organizationAdminPanelRestService.getAllLearners(this.paginationParams).subscribe((listPagination: ListPagination<OrganizationLearner>) => {
                this.listPagination = listPagination;
                this.learners = listPagination.data.map(learner => {
                    learner.languages = this.multilanguageService.getLearnerLanguages(learner?.channels);
                    return learner;
                });
                if (this.tableContentRef !== undefined) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    this.tableContentRef.nativeElement.scrollTop = 0;
                }
            });
        });
    }
}
