import {Component, OnDestroy, OnInit} from '@angular/core';
import {Group} from '../../core/api-types/group';
import {GroupService} from '../../core/group.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-group-overview',
    templateUrl: './group-overview.component.html',
    styleUrls: ['./group-overview.component.scss']
})
export class GroupOverviewComponent implements OnInit, OnDestroy {
    public groupData: Group;
    private activeGroup$ = new Subscription();

    constructor(private groupService: GroupService) {
    }

    public ngOnInit(): void {
        this.activeGroup$ = this.groupService.activeGroup$.subscribe((group: Group) => this.groupData = group);
    }

    public ngOnDestroy(): void {
        this.activeGroup$.unsubscribe();
    }

}
