import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {OrganizationAdminSettings} from './OrganizationAdminSettings';
import {PopupOptions} from '../components/popup/PopupOptions';
import {PopupService} from '../components/popup/popup.service';
import {CancelSavingPopupData} from '../components/cancel-saving-popup/CancelSavingPopupData';
import {CancelSavePopupResult} from '../components/cancel-saving-popup/CancelSavePopupResult';
import {CancelSavingPopupComponent} from '../components/cancel-saving-popup/cancel-saving-popup.component';
import {Router} from '@angular/router';
import {OrganizationAdminPanelResource} from '../core/rest/organization-admin/organization-admin-panel-resource.service';
import {ToastrService} from 'ngx-toastr';
import {OrganizationSettingsFormData} from './organization-settings/OrganizationSettingsFormData';
import {
    ORGANIZATION_CHANNEL_LEARN_MICROMATE,
    ORGANIZATION_CHANNEL_MICROSOFT_TEAMS,
    OrganizationChannelTypes
} from '../core/api-types/organizationChannelTypes';
import {LanguageModel, OrganizationAICredential} from '../core/features/OrganizationAICredential';

@Injectable({
    providedIn: 'root'
})
export class OrganizationAdminSettingService {
    public organizationAdminSettingSaved = new BehaviorSubject<boolean>(true);
    public organizationAdminSettingSaved$ = this.organizationAdminSettingSaved.asObservable();
    public organizationAdminSettings: OrganizationAdminSettings;

    constructor(private popupService: PopupService,
                private router: Router,
                private organizationAdminPanelRestService: OrganizationAdminPanelResource,
                private toastr: ToastrService) {
    }

    public setOrganizationAdminSettingSavedStatus(value: boolean): void {
        this.organizationAdminSettingSaved.next(value);
    }

    public setOrganizationAdminSetting(organizationSettingsFormData: OrganizationSettingsFormData, code: string): void {
        this.organizationAdminSettings = new OrganizationAdminSettings(
            organizationSettingsFormData.joinWithOrganizationCode,
            code,
            organizationSettingsFormData.domainVerification,
            this.getActiveChannels(organizationSettingsFormData),
            organizationSettingsFormData.mainChannel,
            this.getNewAiCredential(organizationSettingsFormData.aiCredential));
    }

    public getOrganizationAdminSetting(): OrganizationAdminSettings {
        return this.organizationAdminSettings;
    }

    public async checkIfOrganizationSettingIsSaved(url?: string): Promise<boolean> {
        if (this.organizationAdminSettingSaved.value) {
            return true;
        }
        const cancelSavingPopupData = new CancelSavingPopupData(true);
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<CancelSavingPopupData, CancelSavePopupResult>(CancelSavingPopupComponent, cancelSavingPopupData, popupOptions);
        if (popupServiceResult === 'Save') {
            await this.saveOrganizationSettings(url);
        } else if (popupServiceResult === 'Discard') {
            this.redirect(url);
        }
        return false;
    }

    private async saveOrganizationSettings(url: string): Promise<void> {
        const settings = this.getOrganizationAdminSetting();
        try {
            await this.organizationAdminPanelRestService.saveOrganizationSettings(settings);
            this.toastr.success($localize`Änderungen gespeichert`);
            this.redirect(url);
        } catch (e) {
            this.toastr.error($localize`Einstellungen konnten nicht geändert werden`);
        }
    }

    private redirect(url: string): void {
        this.setOrganizationAdminSettingSavedStatus(true);
        this.organizationAdminSettings = undefined;
        void this.router.navigate([url]);
    }

    public getActiveChannels(organizationSettingsFormData: OrganizationSettingsFormData): OrganizationChannelTypes[] {
        const activeChannels: OrganizationChannelTypes[] = [];
        if (organizationSettingsFormData.channelMicrosoftTeams) {
            activeChannels.push(ORGANIZATION_CHANNEL_MICROSOFT_TEAMS);
        }
        if (organizationSettingsFormData.channelLernMicromate) {
            activeChannels.push(ORGANIZATION_CHANNEL_LEARN_MICROMATE);
        }
        return activeChannels;
    }

    public getNewAiCredential(aiCredential: OrganizationAICredential): OrganizationAICredential {
        return new OrganizationAICredential(
            aiCredential.languageModel,
            aiCredential.apiKey.trim().length > 0 ? aiCredential.apiKey.trim() : undefined,
            aiCredential.languageModel === LanguageModel.AzureOpenAI && aiCredential.apiUrl.trim().length > 0 ? aiCredential.apiUrl.trim() : undefined,
            aiCredential.languageModel === LanguageModel.AzureOpenAI && aiCredential.completionModelDeploymentName.trim().length > 0 ? aiCredential.completionModelDeploymentName.trim() : undefined,
            aiCredential.languageModel === LanguageModel.AzureOpenAI && aiCredential.embeddingModelDeploymentName.trim().length > 0 ? aiCredential.embeddingModelDeploymentName.trim() : undefined);
    }
}
