import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validators} from '@angular/forms';

@Injectable({
    providedIn: 'root'
})

// Backend quiz validation => https://bitbucket.org/paixon/micromate-bot/src/04ae1393d9602a69bf73d955e1942e2532f86591/source/micromateManage/editor/quizValidator/QuizValidator.ts?at=feature%2FquizImportApi

export class QuestionValidatorService {
    public QUESTION_MAX_LENGTH: number = 1000;
    public EXPLANATION_MAX_LENGTH: number = 3000;
    public ANSWER_MAX_LENGTH: number = 1000;
    public TAG_MAX_LENGTH: number = 50;

    public MIN_ANSWER_NUMBER: number = 3;
    public MAX_ANSWER_NUMBER: number = 5;

    public SOURCE_URL_MAX_LENGTH: number = 2048;
    public SOURCE_LABEL_MAX_LENGTH: number = 100;

    public questionFormValidation = [Validators.required, Validators.maxLength(this.QUESTION_MAX_LENGTH)];
    public explanationFormValidation = [Validators.maxLength(this.EXPLANATION_MAX_LENGTH)];
    public answerTextFormValidation = [Validators.required, Validators.maxLength(this.ANSWER_MAX_LENGTH)];
    public isCorrectAnswerValidation = [Validators.required];
    public sourceUrl = [Validators.maxLength(this.SOURCE_URL_MAX_LENGTH), this.validUrl];
    public sourceLabel = [Validators.maxLength(this.SOURCE_LABEL_MAX_LENGTH)];

    public validateInput(data: AbstractControl): boolean {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return !!data && data.touched && data.invalid;
    }

    public validateInputLength(data: AbstractControl): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/strict-boolean-expressions
        return !!data && data.touched && data.invalid && data.errors?.maxlength;
    }

    public validateInputRequired(data: AbstractControl): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/strict-boolean-expressions
        return !!data && data.touched && data.invalid && data.errors?.required;
    }

    public validUrl(control: AbstractControl<string>): ValidationErrors | null {
        // eslint-disable-next-line no-null/no-null
        return control.value !== undefined && control.value !== null && control.value.length > 0 && !QuestionValidatorService.validateUrl(control.value.trim()) ? {validUrl: true} : null;
    }

    public static validateUrl(text: string): boolean {
        const regex = new RegExp(/^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6})?\b(?:[-a-zA-Z0-9()@:%_\+.;,!*'~$#?&\/=]*)$/);
        return regex.test(text);
    }

    public sourceUrlRequired(group: AbstractControl): ValidationErrors | null {
        const sourceUrl = group.get('sourceUrl');
        const sourceLabel = group.get('sourceLabel');

        const sourceLabelValue = sourceLabel.value as string;
        const sourceUrlValue = sourceUrl.value as string;
        // eslint-disable-next-line no-null/no-null
        if (sourceLabelValue !== undefined && sourceLabelValue !== null && sourceLabelValue.length > 0 && (sourceUrlValue === undefined || sourceUrlValue === null || sourceUrlValue.length === 0)) {
            const currentErrors = sourceLabel.errors;
            // eslint-disable-next-line no-null/no-null
            if (currentErrors === null) {
                sourceLabel.setErrors({mustSourceUrl: true});
            } else {
                currentErrors['mustSourceUrl'] = true;
                sourceLabel.setErrors(currentErrors);
            }
        } else {
            QuestionValidatorService.removeErrorFromControlErrors(sourceLabel, 'mustSourceUrl');
        }
        // eslint-disable-next-line no-null/no-null
        return null;
    }

    private static removeErrorFromControlErrors(control: AbstractControl, errorName: string): void {
        const currentErrors = control.errors;
        // eslint-disable-next-line no-null/no-null
        if (currentErrors !== null) {
            delete currentErrors[errorName];
            // eslint-disable-next-line no-null/no-null
            const newErrors = Object.keys(currentErrors).length === 0 ? null : currentErrors;
            control.setErrors(newErrors);
        }
    }
}
