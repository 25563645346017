<h3 class="micromate-question-preview-answers-title" i18n>Antworten</h3>
<div class="micromate-answer micromate-answer-correct" [innerHTML]="questionPreview.answer | markdown">
</div>
<ng-container *ngIf="questionPreview.answers !== undefined && questionPreview.answers.length > 0">
    <div *ngFor="let answer of questionPreview.answers" class="micromate-answer micromate-answer-correct"
         [innerHTML]="answer | markdown">
    </div>
</ng-container>

<app-explanation-question-preview
    *ngIf="questionPreview.explanation !== undefined && (questionPreview.explanation.mediaType !== undefined || (questionPreview.explanation.text !== undefined && questionPreview.explanation.text.length > 0))"
    [explanation]="questionPreview.explanation"></app-explanation-question-preview>
<app-source-question-preview *ngIf="questionPreview.sourceUrl !== undefined"
                             [sourceUrl]="questionPreview.sourceUrl"
                             [sourceLabel]="questionPreview.sourceLabel"></app-source-question-preview>
