<div class="micromate-card">
    <div class="micromate-card-header">
        <h1 i18n>Tags</h1>
        <div class="micromate-button">
            <app-button type="primary"
                        [icon]="'plus-light'"
                        (click)="createNewTag()" i18n>Neuer Tag
            </app-button>
        </div>
    </div>
    <div class="micromate-table" *ngIf="(tags$ | async)?.length > 0; else emptyTable">
        <div class="table-container table-container-header">
            <ng-container>
                <div class="row-header" i18n></div>
                <div class="row-header" i18n>Bezeichnung</div>
                <div class="row-header" i18n>Aktivierungstag</div>
                <div class="row-header" i18n></div>
            </ng-container>
        </div>
        <div class="table-container-data">
            <div class="table-container table-content" *ngFor="let tag of (tags$ | async)">
                <div class="row row-tag-icon">
                    <app-icon icon="tag-solid"
                              [style.color]="tag.color"></app-icon>
                </div>
                <div class="row row-name">{{tag.label}}</div>
                <div class="row row-tag-active">
                    <app-icon *ngIf="tag.activationTag" icon="check-light"></app-icon>
                </div>
                <div class="row group-info">
                    <div class="micromate-group-button" (click)="editTag(tag)">
                        <app-icon icon="cog-light" class="micromate-group-form-app-icon"></app-icon>
                    </div>
                    <div class="micromate-group-button" (click)="deleteTag(tag)">
                        <app-icon icon="trash-light" class="micromate-group-form-app-icon"></app-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #emptyTable>
        <div class="micromate-empty-table" i18n>
            Es sind noch keine Tags vorhanden
        </div>
    </ng-template>

</div>
