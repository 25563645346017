<div class="micromate-dropdown" tabindex="-1"
     *ngIf="question$ | async as quiz"
     (focusout)="openQuizOptionDropDown = false">
    <div
        class="micromate-dropdown-icon-button"
        [class.micromate-dropdown-icon-button-disabled]="disabled"
        (click)="toggle()"
        [class.micromate-dropdown-icon-button-active]="openQuizOptionDropDown">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"
             class="micromate-organization-info-icon">
            <use href="./assets/icons.svg#ellipsis-h-light"></use>
        </svg>
    </div>
    <div class="micromate-dropdown-button-content"
         [class.micromate-dropdown-button-content-open]="openQuizOptionDropDown">
        <div *ngIf="!(editorQuizzesService.newQuizPage | async) && showAnalytics && translationNotExist"
             (click)="openQuestionAnalyticsPopup()"
             class="micromate-dropdown-button-content-element ">
            <svg aria-hidden="true" focusable="false" viewBox="0 0 544 512"
                 class="micromate-dropdown-button-content-element-icon micromate-dropdown-button-content-element-icon-analytics">
                <use href="./assets/icons.svg#chart-pie-light"></use>
            </svg>
            <div class="micromate-dropdown-button-content-element-text">
                Analytics
            </div>
        </div>
        <div *ngIf="!(editorQuizzesService.newQuizPage | async) && translationNotExist"
             (click)="openDeleteQuizPopup(quiz)"
             class="micromate-dropdown-button-content-element">
            <app-icon icon="trash-light" class="micromate-dropdown-button-content-element-icon"></app-icon>
            <div class="micromate-dropdown-button-content-element-text">
                Löschen
            </div>
        </div>
        <div (click)="openHelpQuizPopup()" class="micromate-dropdown-button-content-element">
            <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"
                 class="micromate-dropdown-button-content-element-icon">
                <use href="./assets/icons.svg#question-square-light"></use>
            </svg>
            <div class="micromate-dropdown-button-content-element-text">
                Hilfe
            </div>
        </div>
    </div>
</div>
