import {Quiz} from '../../../api-types/quiz';
import {QuizType, QuizTypeName} from '../QuizType';
import {QuizQuestion} from '../QuizQuestion';
import {QuizExplanation} from '../QuizExplanation';

export class FreeTextQuiz implements Quiz {
    public quizType: QuizType = QuizTypeName.FREE_TEXT_TYPE;

    constructor(public id: string,
                public quizId: string,
                public quiz: QuizQuestion,
                public answer: string,
                public answers: string[],
                public explanation?: QuizExplanation,
                public sourceUrl?: string,
                public sourceLabel?: string,
                public originalContentHash?: string) {
    }
}

