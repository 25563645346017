<app-popup-container>
    <app-popup-wizard-header header="Importübersicht" i18n-header></app-popup-wizard-header>

    <app-popup-content [class.mm-import-question-summary-content]="!localeNotSpecified"
                       [class.mm-import-without-activation-toggle]="!shouldShowAlreadyImportedQuestionsToggle(importWizardService.questionsForDefaultLocale$ | async)">
        <app-info-box *ngIf="localeNotSpecified"
                      i18n-header header="Das CSV enthält keine Sprachinformationen"
                      i18n-content
                      content="Daher werden die Fragen in der Standardsprache des Lernpakets <strong>{{defaultLocale}}</strong> importiert."></app-info-box>

        <app-search-input [formControl]="searchFormControl" (searchCleared)="clearSearch()"
                          [elementsCount]="(numberOfQuestions$ | async)"
                          *ngIf="(hasSomeQuestionsToImport$ | async)"></app-search-input>

        <div class="micromate-table"
             *ngIf="(displayedQuestionsForDefaultLocale$ | async) !== undefined && (hasSomeQuestionsToImport$ | async)"
             [class.mm-table-with-info-box]="localeNotSpecified">
            <div class="table-container table-container-header table-container-header-static">
                <ng-container>
                    <div class="row-header row-header-number" i18n>Nr.</div>
                    <div class="row-header row-header-type" i18n>Typ</div>
                    <div class="row-header row-header-question" i18n>Frage</div>
                    <div class="row-header"></div>
                    <div class="row-header micromate-row-center" i18n>
                        <input type="checkbox" [checked]="selectAll"
                               [disabled]="searchFormControl.getRawValue() !== null &&
                        searchFormControl.getRawValue().length > 0"
                               [indeterminate]="indeterminate"
                               (click)="selectAllQuestion($event)"/>
                    </div>
                </ng-container>
            </div>

            <div class="table-container-data"
                 *ngIf="(displayedQuestionsForDefaultLocale$ | async).length > 0; else emptyTable">
                <div class="table-container table-content"
                     *ngFor="let question of displayedQuestionsForDefaultLocale$ | async; let i = index"
                     [class.mm-question-summary-deactivated]="!question.isActive">
                    <div class="row micromate-row-center">
                        {{i + 1}}.
                    </div>
                    <div class="row micromate-row-center">
                        <app-icon [icon]="findIconForQuizType(question.quizType).icon"
                                  [title]="findIconForQuizType(question.quizType).title"
                                  class="mm-quiz-icon"></app-icon>
                    </div>
                    <div class="row micromate-row-text">
                        <a class="micromate-question-link" [routerLink]=""
                           (click)="navigateToQuestion(question)"
                           [innerHTML]="question.quiz.text | markdown">
                        </a>
                    </div>
                    <div class="row mm-row-already-imported-question">
                        <app-icon class="mm-row-already-imported-question-icon"
                                  icon="imported-question"
                                  *ngIf="question.alreadyImported"
                                  title="Diese Frage wurde bereits importiert" i18n-title></app-icon>
                    </div>
                    <div class="row micromate-row-center">
                        <input type="checkbox" [ngModel]="question.isActive"
                               (click)="changeQuestionActiveStatus(question)"/>
                    </div>
                </div>
            </div>

            <ng-template #emptyTable>
                <div class="table-empty-filter-results" i18n>
                    keine Suchresultate
                </div>
            </ng-template>
        </div>

        <app-message-box type="info"
                         *ngIf="(displayedQuestionsForDefaultLocale$ | async) !== undefined && !(hasSomeQuestionsToImport$ | async)">
            <div class="mm-question-summary-page-all-imported">
                <div i18n>Alle Fragen wurden bereits importiert.</div>
                <a class="mm-question-summary-page-all-imported-link" (click)="showAllQuestions()" i18n>Alle Fragen
                    anzeigen.</a>
            </div>

        </app-message-box>
    </app-popup-content>

    <app-popup-footer>
        <app-wizard-page-footer
            [isBackVisible]="false"
            forwardText="{{(importWizardService.importActiveQuestionButtonText$ | async)}}"
            [isForwardDisabled]="importButtonDisabled || (importWizardService.activeQuestions$ | async).length === 0"
            (navigateForwardClicked)="import()"
            [isForwardVisible]="true"
            [hasCustomContent]="true"
            [forwardWidth]="215">
            <div class="mm-question-summary-footer">
                <div class="mm-question-summary-toggle"
                     *ngIf="shouldShowAlreadyImportedQuestionsToggle(importWizardService.questionsForDefaultLocale$ | async)">
                    <app-switch-toggle [formControl]="showAlreadyImportedQuestions"></app-switch-toggle>
                    <div i18n>Bereits importierte Fragen exkludieren</div>
                </div>
                <app-button
                    type="secondary" (click)="goToImportTag()"
                    [disabled]="importButtonDisabled || (importWizardService.activeQuestions$ | async).length === 0"
                    class="mm-question-summary-tag-button" i18n>
                    Taggen und importieren…
                </app-button>
            </div>
        </app-wizard-page-footer>
    </app-popup-footer>
</app-popup-container>
