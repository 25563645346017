<div class="micromate-card">
    <div class="micromate-card-title">
        <h1 i18n>Details zum Lernenden</h1>
    </div>

    <div class="micromate-card-content" *ngIf="selectedLearner$ | async as learner">
        <div class="mm-organization-learner-info">
            <app-info-box *ngIf="learner.displayName === undefined"
                          content="Die Angaben sind unvollständig, da Micromate noch nicht verwendet wurde"
                          i18n-content></app-info-box>
            <div class="mm-learner-info">
                <div class="mm-learner-info-title" i18n>
                    E-Mail:
                </div>
                <div class="mm-learner-info-data">
                    {{learner.email ? learner.email : '-'}}
                </div>
            </div>
            <div class="mm-learner-info">
                <div class="mm-learner-info-title" i18n>
                    Benutzer:
                </div>
                <div class="mm-learner-info-data">
                    {{learner.identifier ? learner.identifier : '-'}}
                </div>
            </div>

            <div class="mm-learner-info">
                <div class="mm-learner-info-title" i18n>
                    Name:
                </div>
                <div class="mm-learner-info-data">
                    {{learner.displayName !== undefined ? learner.displayName : '-'}}
                </div>
            </div>

            <div class="mm-learner-info">
                <div class="mm-learner-info-title" i18n>
                    Status:
                </div>
                <div class="mm-learner-info-data">
                    {{getStatusTitle(learner.activeInOrganization)}}
                </div>
            </div>
        </div>
        <app-button class="mm-organization-learner-deactivate-button" type="primary"
                    *ngIf="learner.activeInOrganization"
                    [disabled]="deactivationInProgress"
                    [action]="deactivateLearner" i18n>Deaktivieren
        </app-button>
    </div>
</div>

