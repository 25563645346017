<div class="micromate-card" [class.micromate-editor-mobile-quizzes]="mobileQuery.matches">
    <div class='micromate-editor-web-quizzes' *ngIf="!mobileQuery.matches"
         [class.micromate-editor-web-quizzes-small]="!(editorQuizzesService.quizzesOverviewFullScreen$ | async)">
        <div class="micromate-editor-quiz-filters micromate-editor-quizzes-overview"
             [@move]="(editorQuizzesService.quizzesOverviewFullScreen$ | async)"
             [class.micromate-editor-quiz-filter-small]="!(editorQuizzesService.quizzesOverviewFullScreen$ | async)">
            <div class="micormate-form-element micromate-editor-quiz-settings">
                <app-search-input class="micromate-editor-quiz-filter"
                                  [formControl]="filterText" (searchCleared)="clearSearch()"
                                  [disabled]="!(listOfQuizzes !== undefined && listOfQuizzes.length > 0)"
                                  [elementsCount]="learningPackageQuizzesService.numberOfQuestions$ | async"></app-search-input>
                <div class="micromate-editor-quiz-sort">
                    <select class="micromate-single-selection" [formControl]="sort">
                        <option [ngValue]="'creationDate'" class="micromate-single-selection-option" i18n>
                            &#8679; Erstellungsdatum
                        </option>
                        <option [ngValue]="'ranking'" class="micromate-single-selection-option" i18n>
                            &#8679; Platzierung
                        </option>
                    </select>
                    <app-filter-button
                        *ngIf="learningPackageHasTranslation && (editorQuizzesService.quizzesOverviewFullScreen$ | async)"
                        [control]="filterLanguage"
                        [icon]="'language-light'"
                        [title]="getLanguageFilterTitle(filterLanguage.value)"></app-filter-button>
                    <div class="filter-button filter-active-info" (click)="openQuizzesOverview()"
                         *ngIf="learningPackageHasTranslation &&  !(editorQuizzesService.quizzesOverviewFullScreen$ | async) && filterLanguage.value"
                         title="Übersetzungsfilter aktiv" i18n-title>
                        <app-icon icon="language-light" class="filter-active-info-icon"></app-icon>
                    </div>
                    <app-filter-button
                        *ngIf="(editorQuizzesService.quizzesOverviewFullScreen$ | async)"
                        [control]="filterFeedback"
                        [icon]="'message-pen-light'"
                        [title]="getFeedbackFilterTitle(filterFeedback.value)"></app-filter-button>
                    <div class="filter-button filter-active-info" (click)="openQuizzesOverview()"
                         *ngIf="!(editorQuizzesService.quizzesOverviewFullScreen$ | async) && filterFeedback.value"
                         title="Offenes Feedback-Filter aktiv" i18n-title>
                        <app-icon icon="message-pen-light" class="filter-active-info-icon"></app-icon>
                    </div>
                    <div *ngIf="shouldShowTagFilter()"
                         class="mm-filter-multiselect-tag">
                        <div *ngIf="(editorQuizzesService.quizzesOverviewFullScreen$ | async)"
                             class="mm-filter-multiselect-dropdown-tag">
                            <ng-multiselect-dropdown
                                [settings]="tagsDropdownSettings"
                                [data]="availableTags"
                                [formControl]="filterTags"
                                [placeholder]="'Tags'">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="filter-button" (click)="openQuizzesOverview()"
                             *ngIf="!(editorQuizzesService.quizzesOverviewFullScreen$ | async) && filterTags.value.length > 0"
                             [title]="filterTags.value.length > 0 ? 'Tagfilter aktiv' : 'Tagfilter inaktiv'">
                            <app-icon [icon]="'tags-light'"></app-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="micromate-editor-quizzes-button"
                 [class.micromate-editor-quiz-button]="!(editorQuizzesService.quizzesOverviewFullScreen$ | async)">
                <div class="micromate-button micromate-editor-quiz">
                    <app-button type="primary"
                                [icon]="'plus-light'"
                                (click)="createNewQuiz()" i18n>Neue Frage
                    </app-button>
                </div>
            </div>

            <app-button *ngIf="!(editorQuizzesService.quizzesOverviewFullScreen$ | async)"
                        class="micromate-editor-quiz-back-button"
                        type="secondary"
                        icon="chevron-right-light"
                        (click)="openQuizzesOverview()"></app-button>
        </div>

        <div class="micromate-editor-quizzes"
             *ngIf="listOfQuizzes !== undefined && listOfQuizzes.length > 0"
             [@slideInOutLeft]="(editorQuizzesService.quizzesOverviewFullScreen$ | async)"
             [class.micromate-editor-quiz-list-small]="!(editorQuizzesService.quizzesOverviewFullScreen$ | async)">
            <app-quizzes-overview
                *ngIf="hasQuestions$ | async"
                class="micromate-editor-quiz-list micromate-editor-quizzes-overview"
                [learningPackageId]="learningPackage?._id"
                [learningPackageHasTranslation]="learningPackageHasTranslation"
                [questionsPresentation]="(learningPackageQuizzesService.questionsPresentation$ | async)"
                [sortAndFilter]="formData.value"></app-quizzes-overview>
            <div class="micromate-empty-table"
                 *ngIf="(learningPackageQuizzesService.questionsPresentation$ | async) === undefined || (learningPackageQuizzesService.questionsPresentation$ | async).length === 0"
                 i18n>
                Keine Fragen für diese Filter verfügbar
            </div>
        </div>

        <div class="micromate-editor-quiz-list-no-quizzes micromate-empty-table"
             *ngIf="listOfQuizzes === undefined || listOfQuizzes.length === 0" i18n>
            Keine Fragen vorhanden
        </div>
    </div>

    <div *ngIf="mobileQuery.matches"
         [@slideMobileInOutLeft]="(editorQuizzesService.quizzesOverviewFullScreen$ | async)"
         class="micromate-editor-quizzes-overview-mobile">
        <div class="micromate-editor-quiz-filters micromate-editor-quizzes-overview"
             [class.micromate-editor-quizzes-overview-no-filter]="listOfQuizzes === undefined || listOfQuizzes.length === 0">
            <div class="micormate-form-element micromate-editor-quiz-settings"
                 *ngIf="listOfQuizzes !== undefined && listOfQuizzes.length > 0 && filteredQuizzes !== undefined"
                 [class.micromate-editor-quiz-filter-small]="!(editorQuizzesService.quizzesOverviewFullScreen$ | async)">
                <app-search-input class="micromate-editor-quiz-filter"
                                  [formControl]="filterText" (searchCleared)="clearSearch()"
                                  [elementsCount]="filteredQuizzes.length"></app-search-input>
                <div class="micromate-editor-quiz-sort">
                    <select class="micromate-single-selection" [formControl]="sort">
                        <option [ngValue]="'creationDate'" class="micromate-single-selection-option" i18n>
                            &#8679; Erstellungsdatum
                        </option>
                        <option [ngValue]="'ranking'" class="micromate-single-selection-option" i18n>
                            &#8681; Platzierung
                        </option>
                    </select>
                    <app-filter-button
                        *ngIf="learningPackageHasTranslation"
                        [control]="filterLanguage"
                        [icon]="'language-light'"
                        [title]="getLanguageFilterTitle(filterLanguage.value)"></app-filter-button>
                    <app-filter-button
                        [control]="filterFeedback"
                        [icon]="'message-pen-light'"
                        [title]="getFeedbackFilterTitle(filterFeedback.value)"></app-filter-button>
                    <div *ngIf="shouldShowTagFilter()"
                         class="mm-filter-multiselect-tag">
                        <div *ngIf="(editorQuizzesService.quizzesOverviewFullScreen$ | async)"
                             class="mm-filter-multiselect-dropdown-tag">
                            <ng-multiselect-dropdown
                                [settings]="tagsDropdownSettings"
                                [data]="availableTags"
                                [formControl]="filterTags"
                                [placeholder]="'Tags'">
                            </ng-multiselect-dropdown>
                        </div>
                        <div class="filter-button filter-active-info" (click)="openQuizzesOverview()"
                             *ngIf="!(editorQuizzesService.quizzesOverviewFullScreen$ | async) && filterTags.value.length > 0"
                             [title]="filterTags.value.length > 0 ? 'Tagfilter aktiv' : 'Tagfilter inaktiv'">
                            <app-icon [icon]="'tags-light'" class="filter-active-info-icon"></app-icon>
                        </div>
                    </div>
                </div>
            </div>

            <div class="micromate-editor-quizzes-button"
                 [class.micromate-editor-quiz-button]="!(editorQuizzesService.quizzesOverviewFullScreen$ | async)">
                <div class="micromate-button micromate-editor-quiz">
                    <app-button type="primary"
                                [icon]="'plus-light'"
                                (click)="createNewQuiz()" i18n>Neue Frage
                    </app-button>
                </div>
            </div>
        </div>

        <div class="micromate-editor-quizzes"
             *ngIf="listOfQuizzes !== undefined && listOfQuizzes.length > 0"
             [class.micromate-editor-quiz-list-small]="!(editorQuizzesService.quizzesOverviewFullScreen$ | async)">
            <app-quizzes-overview
                *ngIf="hasQuestions$ | async"
                class="micromate-editor-quiz-list"
                [learningPackageId]="learningPackage?._id"
                [learningPackageHasTranslation]="learningPackageHasTranslation"
                [questionsPresentation]="(learningPackageQuizzesService.questionsPresentation$ | async)"
                [sortAndFilter]="formData.value"></app-quizzes-overview>
            <div class="micromate-empty-table"
                 *ngIf="(learningPackageQuizzesService.questionsPresentation$ | async) === undefined || (learningPackageQuizzesService.questionsPresentation$ | async).length === 0"
                 i18n>
                Keine Fragen für diese Filter verfügbar
            </div>
        </div>

        <div class="micromate-empty-table" *ngIf="listOfQuizzes === undefined || listOfQuizzes.length === 0" i18n>
            Keine Fragen vorhanden
        </div>
    </div>

    <app-quiz-edition class="micromate-editor-quizzes-edition"
                      [@slideInOutRight]="mobileQuery.matches ? (editorQuizzesService.quizzesOverviewFullScreen$ | async): (editorQuizzesService.quizzesOverviewFullScreen$ | async)"
                      [queryParams]="queryParams"
    ></app-quiz-edition>
</div>

