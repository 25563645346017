import {ChangeDetectorRef, Component} from '@angular/core';
import {NavigationService} from '../../core/navigation.service';
import {Router} from '@angular/router';
import {mobileMediaMatch} from '../../global-variables';
import {MediaMatcher} from '@angular/cdk/layout';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';

@Component({
    selector: 'app-page-navigation',
    templateUrl: './app-page-navigation.component.html',
    styleUrls: ['./app-page-navigation.component.scss']
})
export class AppPageNavigationComponent {

    public mobileQuery: MediaQueryList;
    private readonly mobileQueryListener: () => void;

    constructor(public navigationService: NavigationService,
                public router: Router,
                public media: MediaMatcher,
                public userRoleService: AuthUntilUserRoleService,
                public changeDetectorRef: ChangeDetectorRef) {
        this.mobileQuery = media.matchMedia(mobileMediaMatch);
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        // IE and Edge support only this! Also not deprecated according to https://github.com/microsoft/TypeScript/issues/32210
        this.mobileQuery.addListener(this.mobileQueryListener);
    }

    public navigateTo(link: string): string {
        return `/${this.navigationService.activeAppNavigationItem$.getValue()}/${link}`;
    }

}
