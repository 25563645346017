<div class="micromate-editor-dropzone"
     [class.micromate-editor-dropzone-disabled]="disabled"
     *ngIf="media?.type === undefined && media?.imageUrl === undefined && media?.imageFile === undefined"
     (click)="!disabled && openImageAndMediaPopup.emit()">
    <div class="micromate-editor-dropzone-text-wrapper">
        <div class="micromate-editor-dropzone-centered">
            <svg aria-hidden="true" focusable="false" viewBox="0 0 640 512"
                 class="micromate-editor-dropzone-centered-icon">
                <use href="./assets/icons.svg#photo-video-light"></use>
            </svg>
        </div>
    </div>
</div>
<div class="micormate-editor-drop-preview"
     *ngIf="media?.imageUrl !== undefined || media?.imageFile !== undefined || media?.type !== undefined">
    <img class="micormate-editor-drop-preview-image"
         *ngIf="media?.imageFile !== undefined  || media?.imageUrl !== undefined"
         [src]="fileDrop? media?.imageFile.url: media?.imageUrl!== undefined? media?.imageUrl: media?.imageFile.url"
         (click)="!disabled && openImageAndMediaPopup.emit()">
    <div class="micormate-editor-drop-preview-blank"
         *ngIf="media?.imageFile === undefined && media?.imageUrl === undefined"
         (click)="!disabled && openImageAndMediaPopup.emit()"></div>
    <app-media-type-icon [mediaType]="media?.type" (click)="!disabled && openImageAndMediaPopup.emit()"></app-media-type-icon>
    <div class="micormate-editor-drop-preview-remove" (click)="!disabled && moveMedia.emit()">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 320 512"
             class="image-close-button-svg">
            <use href="./assets/icons.svg#times-light"></use>
        </svg>
    </div>
</div>

