import {Injectable} from '@angular/core';
import {combineLatest, switchMap} from 'rxjs';
import {distinctUntilChanged, shareReplay} from 'rxjs/operators';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {TablePaginationParamsService} from '../../core/table-pagination-params.service';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../core/rest/editor-resource.service';

@Injectable({
    providedIn: 'root'
})
export class InboxService {
    constructor(private tablePaginationParamsService: TablePaginationParamsService,
                private editorLearningPackageService: EditorLearningPackageService,
                private editorRestService: EditorResource) {
    }


    public learningPackageInbox$ = combineLatest([this.tablePaginationParamsService.paginationParams$, this.editorLearningPackageService.selectedLearningPackageId$]).pipe(
        distinctUntilChanged(),
        switchMap(([paginationParams, selectedLearningPackageId]: [ListPaginationRequest, string]) => this.editorRestService.getLearningPackageInbox(selectedLearningPackageId, paginationParams)),
        shareReplay(1)
    );

}
