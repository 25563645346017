<app-popup-container>
    <app-popup-content>
        <div *ngIf="data.text === undefined" class="mm-cancel-save-text" i18n>Möchtest du deine Änderungen speichern?
        </div>
        <div *ngIf="data.text !== undefined" class="mm-cancel-save-text">{{data.text}}</div>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button (click)="close()" type="secondary" i18n>Abbrechen</app-popup-action-button>
            <app-popup-action-button (click)="discard()" type="secondary" i18n>Verwerfen</app-popup-action-button>
            <app-popup-action-button (click)="save()" [disabled]="!data.isAllowToSave" i18n>Speichern</app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
