import {Injectable} from '@angular/core';
import {EditorLearningPackageService} from '../../../core/editor/editor-learning-package.service';
import {BehaviorSubject, combineLatest, concat, defer, Observable, of, Subject} from 'rxjs';
import {EditorResource} from '../../../core/rest/editor-resource.service';
import {distinctUntilChanged, filter, map, take} from 'rxjs/operators';
import {ImportableQuestion} from '../../../core/editor/types/QuestionConversion/ImportableQuestion';
import {SelectedNewTag} from '../../../core/api-types/SelectedNewTag';
import {LearningPackageQuestionsService} from '../../learnpackages-quizzes/learning-package-questions.service';
import {FormControl} from '@angular/forms';
import {PopupService} from '../../../components/popup/popup.service';
import {Router} from '@angular/router';

export type ImportStep =
    'csv-question-import'
    | 'ressource-question-import'
    | 'learning-package-import'
    | 'questions-summary'
    | 'question-preview'
    | 'import-tag';
export type ImportMethod = 'csv-file' | 'ressource' | 'learning-package';

@Injectable({providedIn: 'root'})

export class ImportWizardService {
    public currentStep: ImportStep;
    public importMethod: ImportMethod;
    public locales: string[];

    private questions: ImportableQuestion[] = [];

    private questionsForDefaultLocaleSubject: BehaviorSubject<ImportableQuestion[]> = new BehaviorSubject<ImportableQuestion[]>(this.questions);
    public questionsForDefaultLocale$: Observable<ImportableQuestion[]> = this.questionsForDefaultLocaleSubject.asObservable();

    public spinnerVisibleSubject = new BehaviorSubject<boolean>(false);
    public spinnerVisible$: Observable<boolean> = this.spinnerVisibleSubject.asObservable();

    private selectedQuestionIndex: Subject<number> = new BehaviorSubject(undefined);
    public selectedQuestionIndex$ = this.selectedQuestionIndex.asObservable();

    private searchValue: string = '';

    public showAlreadyImportedQuestions: FormControl<boolean> = new FormControl<boolean>(true);

    public showAlreadyImportedQuestions$ = concat(
        defer(() => of(this.showAlreadyImportedQuestions.value)),
        this.showAlreadyImportedQuestions.valueChanges
    );

    public displayedSummaryQuestions$ = combineLatest([this.questionsForDefaultLocale$, this.showAlreadyImportedQuestions$]).pipe(
        distinctUntilChanged(),
        filter(([questions]: [ImportableQuestion[], boolean]) => questions !== undefined),
        map(([questions, alreadyImportedQuestions]: [ImportableQuestion[], boolean]) => alreadyImportedQuestions ? questions.filter(question => !question.alreadyImported) : questions)
    );

    public activeQuestions$ = this.questionsForDefaultLocale$.pipe(
        filter((questions: ImportableQuestion[]) => questions !== undefined),
        map((questions: ImportableQuestion[]) => questions.filter(question => question.isActive)));

    public importActiveQuestionButtonText$ = this.activeQuestions$.pipe(
        map((questions: ImportableQuestion[]) => `${questions.length} ${questions.length === 1 ? $localize`Frage` : $localize`Fragen`} ${$localize`importieren`}`));

    constructor(public editorLearningPackageService: EditorLearningPackageService,
                private editorRestService: EditorResource,
                private learningPackageQuestionsService: LearningPackageQuestionsService,
                private popupService: PopupService,
                private router: Router) {
    }

    public addQuestions(questions: ImportableQuestion[]): void {
        this.questions = questions.map(question => {
            question.isActive = question.alreadyImported ? !this.showAlreadyImportedQuestions.getRawValue() : true;
            return question;
        });
        this.locales = [...new Set(this.questions.map(question => question.language))].filter(x => x !== undefined);
        this.editorLearningPackageService.defaultLocaleSubject$.pipe(take(1)).subscribe(defaultLocale => {
            const questionsForDefaultLocale = this.locales.length > 0 ? this.questions.filter(question => question.language === defaultLocale) : this.questions;
            this.questionsForDefaultLocaleSubject.next(questionsForDefaultLocale);
        });
    }

    public toggleQuestionActiveStatus(questionToDeactivate: ImportableQuestion): void {
        questionToDeactivate.isActive = !questionToDeactivate.isActive;
        this.questionsForDefaultLocaleSubject.next(this.questionsForDefaultLocaleSubject.value);
    }

    public toggleAllQuestionActiveStatus(status: boolean): void {
        const value = this.questionsForDefaultLocaleSubject.value.map(question => ({
            ...question,
            isActive: this.getQuestionStatusWhenChangedForAll(status, question)
        }));
        this.questionsForDefaultLocaleSubject.next(value);
    }

    private getQuestionStatusWhenChangedForAll(status: boolean, question: ImportableQuestion): boolean {
        if (status) {
            const isAlreadyImported = this.showAlreadyImportedQuestions.getRawValue();
            return isAlreadyImported && question.alreadyImported ? false : status;
        }
        return status;
    }

    public isAllQuestionActive(): boolean {
        const questions = this.getAllForDefaultLocale();
        return questions.every(question => question.isActive);
    }

    public isAllQuestionInActive(): boolean {
        const questions = this.getAllForDefaultLocale();
        return questions.every(question => !question.isActive);
    }

    private getQuestionsToImport(importTag?: string): ImportableQuestion[] {
        const activeQuestionsForImport = this.getAllForDefaultLocale().filter(question => question.isActive);
        return this.questions.filter(question => activeQuestionsForImport.some(activeQuestion => activeQuestion.questionNumber === question.questionNumber)).map(question => {
            question.selectedNewTags = this.getQuestionSelectedNewTags(question.tags, importTag);
            return question;
        });
    }

    private getQuestionSelectedNewTags(questionTags: string[], importTag?: string): SelectedNewTag[] {
        const selectedNewTags: SelectedNewTag[] = [];
        if (importTag !== undefined) {
            selectedNewTags.push(new SelectedNewTag(importTag));
        }

        if (questionTags !== undefined) {
            selectedNewTags.push(...questionTags.filter(tag => tag !== importTag).map(tag => new SelectedNewTag(tag)));
        }
        console.log(selectedNewTags);
        return selectedNewTags;
    }

    public getQuestionLocales(): string[] {
        return this.locales;
    }

    public reset(): void {
        this.questions = [];
        this.currentStep = undefined;
        this.importMethod = undefined;
    }

    public async executeImport(importTag?: string): Promise<void> {
        const activeQuestions = this.getQuestionsToImport(importTag);
        const learningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        await this.editorRestService.bulkAddQuestions(learningPackageId, activeQuestions);
    }

    public selectQuestionForPreview(question: ImportableQuestion): void {
        const value = question === undefined ? undefined : this.getAllForDefaultLocale().findIndex(x => x === question);
        this.selectedQuestionIndex.next(value);
    }

    public getAllQuestions(): ImportableQuestion[] {
        return this.questions;
    }

    public getAllForDefaultLocale(): ImportableQuestion[] {
        const importableQuestions = this.questionsForDefaultLocaleSubject.value;
        if (this.showAlreadyImportedQuestions.getRawValue()) {
            return importableQuestions.filter(importableQuestion => !importableQuestion.alreadyImported);
        }
        return importableQuestions;
    }

    public showSpinner(): void {
        this.spinnerVisibleSubject.next(true);
    }

    public hideSpinner(): void {
        this.spinnerVisibleSubject.next(false);
    }

    public setSearchValue(value: string): void {
        this.searchValue = value;
    }

    public getSearchValue(): string {
        return this.searchValue;
    }

    public filterQuestions(search: string, questions: ImportableQuestion[]): ImportableQuestion[] {
        if (search.length === 0) {
            return questions;
        }
        const filteredTranslationQuizzes = this.questions.filter(question => {
            if (this.learningPackageQuestionsService.isFilterInQuestionOrExplanation(question, search)) {
                return true;
            }
            if (question.tags !== undefined && question.tags.length > 0) {
                return question.tags.some(tagLabel => tagLabel.toLowerCase().includes(search.toLowerCase()));
            }
            return this.learningPackageQuestionsService.filterAnswers(question, search);
        });
        return questions.filter(question => filteredTranslationQuizzes.map(quizData => quizData.questionNumber).includes(question.questionNumber));
    }

    public clear(): void {
        this.searchValue = '';
        this.showAlreadyImportedQuestions.setValue(true);
    }

    public setAlreadyImportedQuestionsFromControl(value: boolean): void {
        this.showAlreadyImportedQuestions.setValue(value);
    }

    public resetActiveStateForAlreadyImportedQuestions(): void {
        const importableQuestions = this.questionsForDefaultLocaleSubject.value;
        for (const question of importableQuestions) {
            if (question.alreadyImported) {
                question.isActive = false;
            }
        }
        this.questionsForDefaultLocaleSubject.next(importableQuestions);
    }

    public async importQuestions(importTag?: string): Promise<void> {
        await this.executeImport(importTag);
        this.reset();
        this.popupService.close();

        const selectedLearningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        this.editorLearningPackageService.selectedLearningPackageId.next(selectedLearningPackageId);
        await this.router.navigate([`/editor/lernpakete/${selectedLearningPackageId}/fragen`]);
    }
}
