import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Quiz} from '../api-types/quiz';
import {AiRessource} from './ai-ressource.service';
import {QuizTypeName} from '../editor/types/QuizType';

@Injectable({
    providedIn: 'root'
})
export class QuestionGenerationService {

    constructor(private aiRessource: AiRessource) {
    }

    public generateQuestionFromText(text: string, questionType: QuizTypeName, locale: string): Observable<Quiz> {
        return this.aiRessource.generateQuestionFromText(text, questionType, locale);
    }
}
