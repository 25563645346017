import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, QueryList, ViewChildren} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormControl,
    UntypedFormGroup
} from '@angular/forms';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {ActivatedRoute, Router} from '@angular/router';
import {EditorQuestionsService} from '../../../../core/editor/editor-questions.service';
import {EditorQuizCreatorService} from '../../../../core/editor/editor-quiz-creator.service';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {EditorResource} from '../../../../core/rest/editor-resource.service';
import {DOCUMENT} from '@angular/common';
import {QuizMakerService} from '../../../../core/editor/quiz-maker.service';
import {FileHandle} from '../../../../core/drag-and-drop/drag-and-drop.directive';
import {DragAndDropService} from '../../../../core/drag-and-drop/drag-and-drop.service';
import {DomSanitizer} from '@angular/platform-browser';
import {QuizQuestionForm} from '../../../../core/editor/types/QuizQuestionForm';
import {QuizExplanationForm} from '../../../../core/editor/types/QuizExplanationForm';
import {MultipleTextChoiceQuizEditor} from '../../../../core/editor/types/MultipleTextQuiz/MultipleTextChoiceQuizEditor';
import {MultipleTextChoiceQuiz} from '../../../../core/editor/types/MultipleTextQuiz/MultipleTextChoiceQuiz';
import {MultipleTextAnswersQuizEditor} from '../../../../core/editor/types/MultipleTextQuiz/MultipleTextAnswersQuizEditor';
import {Answer} from '../../../../core/api-types/answer';
import {Subject, Subscription, takeUntil} from 'rxjs';
import {QuizTranslation} from '../../../../core/api-types/quizTranslation';
import {MultipleChoiceTextQuestionValidatorService} from '../../../validation/multiple-choice-text-question-validator.service';
import {QuizMediaEditor} from '../../../../core/editor/types/QuizMediaEditor';
import {QuestionType} from '../../../../core/editor/question.type';
import {QuizTypeName} from '../../../../core/editor/types/QuizType';
import {ToastrService} from 'ngx-toastr';
import {filter, take} from 'rxjs/operators';
import {TranslationService} from '../../../../core/rest/translation.service';
import {FeaturesService} from '../../../../core/features/features.service';
import {PasteTextPopupComponent} from '../../../paste-text-popup/paste-text-popup.component';
import {PopupService} from '../../../../components/popup/popup.service';
import {Tag} from '../../../../core/api-types/Tag';
import {SelectedTag} from '../../../../core/api-types/SelectedTag';
import {SelectedNewTag} from '../../../../core/api-types/SelectedNewTag';
import {MultipleChoiceTextQuizFormGroup} from './MultipleChoiceTextQuizFormGroup';
import {MultipleChoiceTextQuizAnswerFormGroup} from './MultipleChoiceTextQuizAnswerFormGroup';

@Component({
    selector: 'app-multiple-choice-text-quiz',
    templateUrl: './multiple-choice-text-quiz.component.html',
    styleUrls: ['./multiple-choice-text-quiz.component.scss']
})
export class MultipleChoiceTextQuizComponent implements OnInit, OnDestroy {
    @Input() queryParams: { filterText: string; sort: string; filterLanguage?: string; };

    public quiz: MultipleTextChoiceQuiz;
    public quizQuestionMedia: QuizMediaEditor;
    public quizExplanationMedia: QuizMediaEditor;
    public selectedLearningPackageId: string;
    public quizTypeData: QuestionType;
    public questionFileDrop: boolean = false;
    public explanationFileDrop: boolean = false;
    public questionHovering = false;
    public explanationHovering = false;
    public languages: string[];
    public activeQuizSubscription = new Subscription();
    public activeTranslationQuiz: QuizTranslation;
    public formDataChangedSubscription = new Subscription();
    public quizQuestionMediaEditorChangedSubscription = new Subscription();
    public quizExplanationMediaEditorChangedSubscription = new Subscription();
    public learningPackageAvailableTagsSubscription = new Subscription();

    public availableTags: Tag[];
    public selectedAvailableTags: SelectedTag[] = [];
    public selectedNewTags: SelectedNewTag[] = [];

    public alreadyTranslated: boolean = false;

    @ViewChildren('answersForm') answersForm: QueryList<ElementRef>;

    public newAnswer = new UntypedFormControl('');
    public disableNewAnswerInput = false;
    public advancedPanelOpen: boolean = false;

    constructor(public editorLearningPackageService: EditorLearningPackageService,
                private router: Router,
                public quizMakerService: QuizMakerService,
                public editorQuizzesService: EditorQuestionsService,
                private editorQuizCreatorService: EditorQuizCreatorService,
                public editorQuizService: EditorQuestionService,
                private popupService: PopupService,
                private editorService: EditorResource,
                @Inject(DOCUMENT) private document: Document,
                public dragAndDropService: DragAndDropService,
                private translationService: TranslationService,
                public featuresService: FeaturesService,
                private sanitizer: DomSanitizer,
                public validator: MultipleChoiceTextQuestionValidatorService,
                private activatedRoute: ActivatedRoute,
                private toastr: ToastrService) {
    }

    public formData: FormGroup<MultipleChoiceTextQuizFormGroup> = this.createFormGroup();

    private createFormGroup(): FormGroup<MultipleChoiceTextQuizFormGroup> {
        return new FormGroup<MultipleChoiceTextQuizFormGroup>({
            question: new FormControl<string>('', this.validator.questionFormValidation),
            explanation: new FormControl<string>('', this.validator.explanationFormValidation),
            answers: new FormArray<FormGroup<MultipleChoiceTextQuizAnswerFormGroup>>([]),
            sourceUrl: new FormControl<string>('', this.validator.sourceUrl),
            sourceLabel: new FormControl<string>('', this.validator.sourceLabel)
        }, {validators: this.validator.sourceUrlRequired});
    }

    public ngOnInit(): void {
        this.selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        if (this.selectedLearningPackageId !== undefined) {
            this.editorService.getLearningPackageLanguages(this.selectedLearningPackageId).subscribe((languages: string[]) => this.languages = languages);
        }
        this.activeQuizSubscription = this.editorQuizService.activeTranslationQuiz$.pipe(filter(quiz => quiz !== undefined)).subscribe((quiz: QuizTranslation) => {
            this.availableTags = undefined;
            this.selectedNewTags = [];
            this.learningPackageAvailableTagsSubscription = this.editorLearningPackageService.learningPackageAvailableTags$.subscribe((availableTags: Tag[]) => this.availableTags = availableTags);
            if (quiz !== undefined && quiz.quizType === QuizTypeName.MULTIPLE_CHOICE_TYPE) {
                this.activeTranslationQuiz = quiz;
                this.quiz = new MultipleTextChoiceQuiz(quiz.id, quiz.quizId, quiz.quiz, quiz.answers as Answer[], quiz?.explanation, quiz.sourceUrl, quiz.sourceLabel, quiz.originalContentHash);
                this.quizQuestionMedia = new QuizMediaEditor('question', this.quiz.quiz?.mediaType, this.quiz.quiz?.mediaUrl, this.quiz.quiz?.imageUrl);
                this.quizExplanationMedia = new QuizMediaEditor('explanation', this.quiz.explanation?.mediaType, this.quiz.explanation?.mediaUrl, this.quiz.explanation?.imageUrl);
                this.disableNewAnswerInput = quiz.answers.length === 5;
                this.selectedAvailableTags = quiz.tags !== undefined && quiz.tags.length > 0 ? quiz.tags.map(tag => new SelectedTag(tag)) : [];
            }
            this.quizTypeData = this.editorQuizService.quizTypes.find(quizType => quizType.type === this.quiz.quizType);
            this.clearQuizForm();
            this.cleanFiles();
            this.setQuizDataToForm();

            this.formData.valueChanges.subscribe(() => {
                this.editorQuizzesService.setQuestionDataChanged(true);
            });
        });

        this.newAnswer.valueChanges.subscribe((data: string) => {
            this.createNewAnswer(data);
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.formDataChangedSubscription = this.editorQuizzesService.formDataChanged.subscribe(async (status: boolean) => {
            if (status) {
                await this.saveCurrentQuizState(this.formData);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizQuestionMediaEditorChangedSubscription = this.editorQuizzesService.quizQuestionMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizQuestionMedia = this.editorQuizzesService.getQuestionQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizQuestionMediaEditorChanged.next(false);
            }
        });

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.quizExplanationMediaEditorChangedSubscription = this.editorQuizzesService.quizExplanationMediaEditorChanged.subscribe(async (status: boolean) => {
            if (status) {
                this.quizExplanationMedia = this.editorQuizzesService.getExplanationQuizMediaEditor();
                this.editorQuizzesService.setQuestionDataChanged(true);
                this.editorQuizzesService.quizExplanationMediaEditorChanged.next(false);
            }
        });
    }

    private destroy$ = new Subject();

    public ngOnDestroy(): void {
        this.activeQuizSubscription.unsubscribe();
        this.formDataChangedSubscription.unsubscribe();
        this.quizQuestionMediaEditorChangedSubscription.unsubscribe();
        this.quizExplanationMediaEditorChangedSubscription.unsubscribe();
        this.learningPackageAvailableTagsSubscription.unsubscribe();

        this.destroy$.next(true);
        this.destroy$.complete();
    }

    public async changeLanguage(language: string): Promise<void> {
        if (this.editorQuizzesService.quizSaved.value) {
            this.alreadyTranslated = false;
            void this.router.navigate(
                [],
                {
                    relativeTo: this.activatedRoute,
                    queryParams: {
                        language: language
                    },
                    queryParamsHandling: 'merge'
                });
        } else {
            const url = `/editor/lernpakete/${this.selectedLearningPackageId}/fragen/${this.quiz.quizId}?language=${language}`;
            await this.editorQuizzesService.openCancelQuestion(url);
        }
    }

    public isDisabled: boolean = false;

    private enable(): void {
        this.formData.enable();
        this.isDisabled = false;
    }

    private disable(): void {
        this.formData.disable();
        this.isDisabled = true;
    }

    public translate: () => Promise<void> = async () => {

        return new Promise(resolve => {
            this.disable();
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            this.editorQuizService.activeQuestionMainLanguage$.pipe(take(1)).subscribe(async (questionTranslation: QuizTranslation) => {

                this.translationService.translateQuestion(questionTranslation, this.activeTranslationQuiz.language)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((translatedQuiz: MultipleTextChoiceQuiz | undefined) => {
                        if (translatedQuiz !== undefined) {
                            this.formData.get('question').setValue(translatedQuiz.quiz.text);
                            this.formData.get('explanation').setValue(translatedQuiz.explanation?.text);
                            this.formData.get('sourceUrl').setValue(translatedQuiz.sourceUrl);
                            this.formData.get('sourceLabel').setValue(translatedQuiz.sourceLabel);

                            this.setQuizAnswersToForm(translatedQuiz);

                            this.quizQuestionMedia.mediaUrl = questionTranslation.quiz.mediaUrl;
                            this.quizQuestionMedia.imageUrl = questionTranslation.quiz.imageUrl;
                            this.quizQuestionMedia.type = questionTranslation.quiz.mediaType;

                            this.quizExplanationMedia.mediaUrl = questionTranslation.explanation?.mediaUrl;
                            this.quizExplanationMedia.imageUrl = questionTranslation.explanation?.imageUrl;
                            this.quizExplanationMedia.type = questionTranslation.explanation?.mediaType;

                            this.alreadyTranslated = true;
                        }

                        this.enable();
                        resolve();
                    });
            });
        });
    };

    public async filesDropped(files: FileHandle[], type: 'question' | 'explanation'): Promise<void> {
        const file = files[0];
        const fileAllowed = this.dragAndDropService.isFileAllowed(file.file);
        if (!fileAllowed.error) {
            if (type === 'question') {
                this.quizQuestionMedia.imageFile = file;
                this.questionFileDrop = true;
                if (this.quizQuestionMedia.type === undefined) {
                    this.quizQuestionMedia.type = 'image';
                }
            }

            if (type === 'explanation') {
                this.quizExplanationMedia.imageFile = file;
                this.explanationFileDrop = true;
                if (this.quizExplanationMedia.type === undefined) {
                    this.quizExplanationMedia.type = 'image';
                }
            }
            this.editorQuizzesService.setQuestionDataChanged(true);
        } else {
            for (const errorText of fileAllowed.text) {
                this.toastr.error(errorText);
            }
        }
    }

    public async removeMedia(type: 'question' | 'explanation'): Promise<void> {
        if (type === 'question') {
            this.quizQuestionMedia = new QuizMediaEditor('question', undefined);
            this.questionFileDrop = false;
        } else {
            this.quizExplanationMedia = new QuizMediaEditor('explanation', undefined);
            this.explanationFileDrop = false;
        }

        this.editorQuizzesService.setQuestionDataChanged(true);
    }

    public cancelQuiz(): void {
        if (this.editorQuizzesService.formDataChanged.value) {
            this.editorQuizzesService.quizSaved.next(true);
            this.editorQuizzesService.setQuestionDataChanged(false);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${this.selectedLearningPackageId}/fragen`],
                {queryParams: this.queryParams});
        }
    }

    public async saveQuiz(data: FormGroup<MultipleChoiceTextQuizFormGroup>): Promise<void> {
        if (this.validator.validateFullQuiz(data)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuiz(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);
            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    public async saveAndCreateNevQuiz(data: FormGroup<MultipleChoiceTextQuizFormGroup>): Promise<void> {
        if (this.validator.validateFullQuiz(data)) {
            const quizEditor = this.buildChangedQuiz(data);
            await this.editorQuizCreatorService.saveQuizAndAddNew(quizEditor, this.quiz, this.activeTranslationQuiz.mainLeaningPackageId, this.activeTranslationQuiz.leaningPackageId, this.queryParams);
            this.editorQuizzesService.setQuestionDataChanged(false);
        }
    }

    public getAnswersControls(): AbstractControl[] {
        const answers = this.formData.get('answers') as UntypedFormArray;
        return answers.controls;
    }

    public removeAnswer(answerIndex: number): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        control.removeAt(answerIndex);
        this.disableNewAnswerInput = false;
    }

    public keytab(event: KeyboardEvent): void {
        event.preventDefault();
    }

    public translationNotExist(quizzes: QuizTranslation[]): boolean {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return !quizzes.find(quiz => quiz.language === this.activeTranslationQuiz.language);
    }

    private async saveCurrentQuizState(data: UntypedFormGroup): Promise<void> {
        const quizEditor = this.buildChangedQuiz(data);
        const quizData = await this.editorQuizCreatorService.createQuizData(quizEditor, this.quiz, this.quizQuestionMedia, this.quizExplanationMedia);
        this.editorQuizzesService.setEditingQuizFormData(quizData, this.validator.validateFullQuiz(this.formData));
        if (this.editorQuizzesService.quizSaved.value) {
            this.editorQuizzesService.quizSaved.next(false);
        }
    }

    private buildChangedQuiz(data: UntypedFormGroup): MultipleTextChoiceQuizEditor {
        const answers = data.get('answers')?.value as MultipleTextAnswersQuizEditor[];

        const questionMedia = this.editorQuizzesService.quizQuestionMediaEditorChanged.value ? this.editorQuizzesService.getQuestionQuizMediaEditor() : this.quizQuestionMedia;
        const quizQuestionForm = new QuizQuestionForm(data.get('question').value as string, questionMedia);

        const explanationMedia = this.editorQuizzesService.quizExplanationMediaEditorChanged.value ? this.editorQuizzesService.getExplanationQuizMediaEditor() : this.quizExplanationMedia;
        const quizExplanationForm = new QuizExplanationForm(data.get('explanation').value as string, explanationMedia);

        const sourceUrl = data.controls.sourceUrl.value as string;
        const sourceLabel = data.controls.sourceLabel.value as string;

        return new MultipleTextChoiceQuizEditor(answers, quizExplanationForm, quizQuestionForm, this.selectedAvailableTags, this.selectedNewTags, sourceUrl, sourceLabel);
    }

    private createNewAnswer(data: string): void {
        if (data !== undefined && data.length > 0) {
            const control = this.formData.get('answers') as UntypedFormArray;
            const controlLength = control.controls.length;

            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            if (control && controlLength < 5) {
                control.push(this.createAnswerFormGroup(data));

                setTimeout(() => {
                    document.getElementById(`Answar_${(controlLength).toString()}`).focus();
                });

                this.newAnswer.setValue('');
            }
        }

        this.updateNewAnswerDisabledState();
    }

    private updateNewAnswerDisabledState(): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        this.disableNewAnswerInput = control.controls.length >= 5;
    }

    private clearQuizForm(): void {
        this.formData = this.createFormGroup();
    }

    private setQuizDataToForm(): void {
        this.formData.controls['question'].setValue(this.quiz.quiz.text);
        if (this.quiz?.explanation?.text !== undefined) {
            this.formData.controls['explanation'].setValue(this.quiz.explanation.text);
        }
        this.setQuizAnswersToForm(this.quiz);
        this.formData.get('sourceUrl').setValue(this.quiz.sourceUrl);
        this.formData.get('sourceLabel').setValue(this.quiz.sourceLabel);
    }

    private setQuizAnswersToForm(question: MultipleTextChoiceQuiz): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        control.clear();
        if (question.answers !== undefined) {
            for (const answer of question.answers) {
                control.push(this.createAnswerFormGroup(answer.answerText, answer?.isCorrectAnswer));
            }

            if (control.length < 3) {
                this.createEmptyAnswers(control.length);
            }
        } else {
            this.createEmptyAnswers();
        }

        this.updateNewAnswerDisabledState();
    }

    private createEmptyAnswers(numberOfExistingAnswers?: number): void {
        const control = this.formData.get('answers') as UntypedFormArray;
        for (let index = (numberOfExistingAnswers !== undefined ? numberOfExistingAnswers : 0); index < 3; index++) {
            if (index === 0) {
                control.push(this.createAnswerFormGroup(undefined, true));
            } else {
                control.push(this.createAnswerFormGroup());
            }
        }
    }

    private createAnswerFormGroup(answerText?: string, isCorrectAnswer?: boolean): UntypedFormGroup {
        return new FormGroup<MultipleChoiceTextQuizAnswerFormGroup>({
            isCorrectAnswer: new FormControl<boolean>((isCorrectAnswer !== undefined ? isCorrectAnswer : false), this.validator.isCorrectAnswerValidation),
            answerText: new FormControl<string>((answerText !== undefined ? answerText : ''), this.validator.answerTextFormValidation)
        });
    }

    private cleanFiles(): void {
        this.questionFileDrop = undefined;
        this.explanationFileDrop = undefined;
    }

    public async openImageAndMediaPopup(quizMediaElement: 'question' | 'explanation'): Promise<void> {
        if (quizMediaElement === 'question') {
            this.editorQuizzesService.setQuestionQuizMediaEditor(this.quizQuestionMedia);
        } else {
            this.editorQuizzesService.setExplanationQuizMediaEditor(this.quizExplanationMedia);
        }
        this.editorQuizzesService.openImageAndMediaPopup(quizMediaElement);
    }

    public startPasteFlow(): void {
        void this.popupService.open(PasteTextPopupComponent, this.quizTypeData);
    }
}
