import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Popup} from '../../../components/popup/Popup';
import {PopupService} from '../../../components/popup/popup.service';
import {FormControl, FormGroup} from '@angular/forms';
import {EditTagPopupResult} from './EditTagPopupResult';
import {filter} from 'rxjs/operators';
import {EditorLearningPackageService} from '../../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../../core/rest/editor-resource.service';
import {EditTagPopupData} from './EditTagPopupData';
import {Subscription, takeUntil} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {FormCustomValidation} from 'src/app/core/form-custom-validation';
import {TagFormGroup} from '../new-tag-popup/TagFormGroup';
import {LearningPackageTagsPreparator} from '../LearningPackageTagsPreparator';

@Component({
    selector: 'app-edit-tag-popup',
    templateUrl: './edit-tag-popup.component.html',
    styleUrls: ['./edit-tag-popup.component.scss']
})
export class EditTagPopupComponent implements Popup<EditTagPopupData>, OnInit, OnDestroy {

    @Input()
    public data: EditTagPopupData;

    public hasError: boolean = false;
    public valueChanged: boolean = false;
    public activationTagValueChanges: boolean = false;

    public tagForm: FormGroup<TagFormGroup> = new FormGroup<TagFormGroup>({
        label: new FormControl<string>('', [FormCustomValidation.trimmedRequired, FormCustomValidation.trimmedMaxLengthValidator(50)]),
        activationTag: new FormControl<boolean>(false)
    });

    private tagFormValueChanged$ = new Subscription();
    private activationTagValueChanged$ = new Subscription();

    constructor(private popupService: PopupService,
                private editorLearningPackageService: EditorLearningPackageService,
                private editorRestService: EditorResource) {
    }

    public ngOnInit(): void {
        this.tagForm.controls.label.setValue(this.data.tag.label);
        this.tagForm.controls.activationTag.setValue(this.data.tag.activationTag);
        this.tagFormValueChanged$ = this.tagForm.valueChanges.subscribe(() => {
            this.hasError = false;
            this.valueChanged = true;
        });
        this.activationTagValueChanged$ = this.tagForm.controls.activationTag.valueChanges.subscribe(() => {
            this.activationTagValueChanges = true;
        });
    }

    public editTag: () => Promise<void> = async () => {
        this.hasError = false;
        return new Promise(resolve => {
            const tagFormData = this.tagForm.getRawValue();
            const tag = {...this.data.tag};
            tag.label = LearningPackageTagsPreparator.removeSpacesInText(tagFormData.label);
            tag.activationTag = tagFormData.activationTag;
            this.editorRestService.updateLearningPackageTag(this.data.selectedLearningPackageId, tag)
                .pipe(takeUntil(this.popupService.isPopupOpen$.pipe(filter(isOpen => !isOpen))))
                .subscribe(() => {
                        this.popupService.close<EditTagPopupResult>('tagUpdated');
                        this.valueChanged = false;
                        resolve();
                    },
                    (error: HttpErrorResponse) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        if (error.error?.duplicatedTag as boolean) {
                            this.hasError = true;
                        }
                        resolve();
                    });
        });
    };

    public cancel(): void {
        this.popupService.close();
    }

    public ngOnDestroy(): void {
        this.tagFormValueChanged$.unsubscribe();
        this.activationTagValueChanged$.unsubscribe();
    }

}
