import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {LearningPackageSettingsService} from './learning-package-settings.service';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {LearningPackageSettings} from '../../core/api-types/learningPackageSettings';
import {Router} from '@angular/router';
import {combineLatest, Subscription} from 'rxjs';
import {filter, switchMap, take} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';
import {CancelSavingPopupData} from '../../components/cancel-saving-popup/CancelSavingPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {CancelSavePopupResult} from '../../components/cancel-saving-popup/CancelSavePopupResult';
import {CancelSavingPopupComponent} from '../../components/cancel-saving-popup/cancel-saving-popup.component';
import {PopupService} from '../../components/popup/popup.service';
import {EditorQuestionService} from '../../core/editor/editor-question.service';
import {FormCustomValidation} from '../../core/form-custom-validation';

@Component({
    selector: 'app-learnpackage-settings',
    templateUrl: './learning-package-settings.component.html',
    styleUrls: ['./learning-package-settings.component.scss']
})
export class LearningPackageSettingsComponent implements OnInit, OnDestroy {
    public learningPackage: LearningPackageSettings;
    public formData = new UntypedFormGroup({
        name: new UntypedFormControl('', [FormCustomValidation.trimmedRequired, FormCustomValidation.trimmedMaxLengthValidator(30)]),
        introduction: new UntypedFormControl('', [FormCustomValidation.trimmedMaxLengthValidator(500)])
    });
    public allowExportFile = true;
    public selectedLearningPackageId$ = new Subscription();
    private mainLearningPackageName: string;

    constructor(public editorLearningPackageService: EditorLearningPackageService,
                public userRoleService: AuthUntilUserRoleService,
                private toastr: ToastrService,
                public multilanguageService: MultilanguageService,
                public learningPackageSettingsService: LearningPackageSettingsService,
                private editorService: EditorResource,
                private router: Router,
                private popupService: PopupService,
                private editorQuestionService: EditorQuestionService) {
    }

    @HostListener('window:beforeunload', ['$event'])
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    unloadHandler($event) {
        if (!this.learningPackageSettingsService.learningPackageSettingSaved.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return $event.returnValue = 'Your changes will not be saved';
        }
    }

    public ngOnInit(): void {
        this.selectedLearningPackageId$ = combineLatest([this.editorLearningPackageService.selectedLearningPackageId$, this.editorQuestionService.activeLanguage$]).pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter(([selectedLearningPackageId]: [string, string]) => !!selectedLearningPackageId),
            switchMap(([selectedLearningPackageId, language]: [string, string]) => {
                this.learningPackageSettingsService.setLearningPackageSettingSavedStatus(true);
                return this.editorService.getLearningPackageSettings(selectedLearningPackageId, language);
            })).subscribe((learningPackageSettings: LearningPackageSettings) => {
            this.learningPackage = learningPackageSettings;
            this.setFormData();
        });
        this.editorLearningPackageService.activeLearningPackage$.pipe(take(1)).subscribe(activeLearningPackages => {
                if (activeLearningPackages !== undefined) {
                    this.mainLearningPackageName = activeLearningPackages.find(learningPackage => learningPackage.mainLearningPackage).name;
                }
            }
        );
    }

    public ngOnDestroy(): void {
        this.selectedLearningPackageId$.unsubscribe();
    }

    private setSettingWhenValueChange(data: { name: string; introduction: string; }): void {
        if (data.name !== this.learningPackage.name || data.introduction !== this.learningPackage.introduction) {
            this.setNewSettings(data);
            this.learningPackageSettingsService.setLearningPackageSettingSavedStatus(false);
        } else {
            this.learningPackageSettingsService.setLearningPackageSettingSavedStatus(true);
        }
    }

    public changeLanguage(language: string): void {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(['editor', 'lernpakete', this.learningPackage.learningPackageId, 'einstellungen'], {
            queryParams: {
                language: language
            }
        });
    }

    public async saveLearningPackage(learningPackage: { name: string; introduction: string; }): Promise<void> {
        if (this.formData.valid && !this.learningPackageSettingsService.learningPackageSettingSaved.value) {
            this.setNewSettings(learningPackage);
            const popupOptions = new PopupOptions('small');
            const popupServiceResult = await this.popupService.open<CancelSavingPopupData, CancelSavePopupResult>(CancelSavingPopupComponent, this.learningPackageSettingsService.getCancelSavingPopupData(), popupOptions);
            if (popupServiceResult === 'Save') {
                this.learningPackageSettingsService.saveLearningPackageSettings();
            } else if (popupServiceResult === 'Discard') {
                this.learningPackageSettingsService.setLearningPackageSettingSavedStatus(true);
                this.learningPackageSettingsService.setLearningPackage(undefined);
                this.setFormData();
            }
        }
    }

    private setFormData(): void {
        this.formData.controls['name'].setValue(this.learningPackage.name);
        this.formData.controls['introduction'].setValue(this.learningPackage.introduction);
        this.formData.valueChanges.subscribe((data: { name: string; introduction: string; }) => {
            this.setSettingWhenValueChange(data);
        });
    }

    private setNewSettings(learningPackage: { name: string; introduction: string; }): void {
        const learningPackageSettingData = {
            learningPackageTranslationId: this.learningPackage._id,
            name: learningPackage.name.trim(),
            introduction: learningPackage.introduction.trim()
        };
        this.learningPackageSettingsService.setLearningPackage(learningPackageSettingData);
    }

    public exportLearningPackage(): void {
        if (this.allowExportFile) {
            this.allowExportFile = false;
            if (this.learningPackage !== undefined) {
                this.editorService.getLearningPackageQuizzesExport(this.learningPackage.learningPackageId).then((data) => {
                    const filename = this.mainLearningPackageName;
                    LearningPackageSettingsComponent.downloadFile(data.csvData, filename);
                    setTimeout(() => {
                        this.allowExportFile = true;
                    }, 3000);
                }).catch(e => {
                    const error = e as HttpErrorResponse;
                    this.toastr.error(error.error as string);
                    this.allowExportFile = true;
                });
            }
        }
    }

    public getLanguage(language: string): string {
        const languageAndLocation = language.split('-');
        const languageInfo = this.multilanguageService.languagesList.find(languageData => languageData.language === languageAndLocation[0] && languageData.local === languageAndLocation[1]);
        return languageInfo.name;
    }

    private static downloadFile(data: string, filename: string): void {
        const blob = new Blob([`\ufeff${data}`], {type: 'text/csv;charset=utf-8;'});
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', `${filename}.csv`);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
}
