<div class="micromate-card micromate-card-editor-question">
    <div class="micromate-card-mobile-title">
        <div class="micromate-button micromate-editor-quiz-back-button">
            <div class="come-back-micromate-buttont-text" (click)="quizMakerService.openQuizzesOverview()">
                <svg aria-hidden="true" focusable="false" viewBox="0 0 384 512"
                     class="micromate-buttont-text-icon">
                    <use href="./assets/icons.svg#chevron-left-light"></use>
                </svg>
            </div>
        </div>
        <div class="micromate-editor-quiz-type">
            <h1 i18n>Frage</h1>

            <app-icon *ngIf="quizTypeData !== undefined"
                      class="micromate-editor-quiz-type-icon"
                      [title]="quizTypeData.title"
                      [icon]="quizTypeData.icon"></app-icon>
        </div>

        <div class="micromate-editor-question-actions" *ngIf="activeTranslationQuiz !== undefined">
            <editor-drop-down class="editor-drop-down"
                              [disabled]="isDisabled"
                              [language]="activeTranslationQuiz.language"
                              [translationNotExist]="!translationNotExist((editorQuizService.activeQuiz$ | async))"
                              [showAnalytics]="true"></editor-drop-down>
        </div>
    </div>

    <div class="mm-editor-question-card">
        <div class="mm-editor-question-card-actions">
            <app-editor-language-switcher class="mm-editor-question-card-language-switcher"
                                          [disabled]="isDisabled"
                                          *ngIf="(editorQuizService.newQuiz$ | async) === undefined"
                                          [currentLanguage]="activeTranslationQuiz?.language"
                                          [mainLanguage]="activeTranslationQuiz?.mainLanguage"
                                          (activeLanguage)="changeLanguage($event)"></app-editor-language-switcher>

            <div class="mm-editor-question-card-actions-buttons">
                <div class="mm-editor-question-card-actions-buttons-content"
                     [class.micromate-editor-question-actions-new-question]="(editorQuizService.newQuiz$ | async) !== undefined"
                     *ngIf="activeTranslationQuiz !== undefined">
                    <app-button type="ai" (click)="startPasteFlow()"
                                icon="ai"
                                *ngIf="(featuresService.hasFeatureAI() | async) && (editorQuizService.newQuiz$ | async) !== undefined"
                                i18n>Frage aus Text
                    </app-button>
                    <app-button type="ai" [action]="translate"
                                *ngIf="(featuresService.hasFeatureAI() | async) && translationNotExist((editorQuizService.activeQuiz$ | async)) && !alreadyTranslated"
                                i18n>Übersetzen
                    </app-button>
                    <app-tags-selector
                        *ngIf="activeTranslationQuiz?.language === activeTranslationQuiz?.mainLanguage && !translationNotExist(editorQuizService.activeQuiz$ | async)  && availableTags !== undefined"
                        [availableTags$]="editorLearningPackageService.learningPackageAvailableTags$"
                        [(selectedAvailableTags)]="selectedAvailableTags"
                        [(selectedNewTags)]="selectedNewTags"></app-tags-selector>
                </div>
            </div>

        </div>

        <div class="micromate-editor-tags-question">
            <app-tags-list
                *ngIf="availableTags !== undefined"
                [availableTags]="availableTags"
                [(selectedAvailableTags)]="selectedAvailableTags"
                [(selectedNewTags)]="selectedNewTags"
                [allowRemoveTag]="activeTranslationQuiz?.language === activeTranslationQuiz?.mainLanguage && !translationNotExist(editorQuizService.activeQuiz$ | async)"></app-tags-list>
        </div>

        <div *ngIf="quiz !== undefined" class="micromate-editor-quiz-creator">
            <form [formGroup]="formData" id="ngFormOrganization" class="micormate-form-quiz">

                <div class="micormate-form-quiz-element-question"
                     (dragenter)="questionHovering = true"
                     (dragleave)="questionHovering = false"
                     (drop)="questionHovering = false"
                     appDragAndDrop
                     (files)="filesDropped($event, 'question')">
                    <div class="micromate-editor-drag-area"
                         *ngIf="!editorQuizzesService.imageAndMediaPopupOpen.value && (dragAndDropService.dragOnPage$ | async)"
                         i18n
                         [class.micromate-editor-drag-area-active]="questionHovering">
                        Bild hier ablegen
                    </div>
                    <div class="micormate-form-quiz-element-question-input">
                        <div class="micormate-form-quiz-element micormate-form-quiz-element-question-textarea">
                        <textarea id="question" autosize type="text"
                                  formControlName="question"
                                  placeholder="Frage"
                                  class="micormate-form-quiz-maker-textarea"
                                  (keydown.enter)="keytab($event)"
                                  (keydown.shift.enter)="keytab($event)"
                                  [class.micormate-form-input-validation-error]="validator.validateInput(formData.controls.question)">
                        </textarea>
                        </div>

                        <app-micromate-editor-image-drop-down
                            class="micromate-editor-drop-down"
                            [disabled]="isDisabled"
                            [class.micromate-editor-drop-down-preview]="quizQuestionMedia?.imageUrl !== undefined || quizQuestionMedia?.imageFile !== undefined"
                            [media]="quizQuestionMedia"
                            [fileDrop]="questionFileDrop"
                            (moveMedia)="removeMedia('question')"
                            (openImageAndMediaPopup)="openImageAndMediaPopup('question')">
                        </app-micromate-editor-image-drop-down>
                    </div>

                    <div>
                        <div *ngIf="validator.validateInputRequired(formData.controls.question)"
                             class="micormate-form-validation-error" i18n>
                            Fragetext ist erforderlich
                        </div>
                        <div *ngIf="validator.validateInputLength(formData.controls.question)"
                             class="micormate-form-validation-error" i18n>
                            Maximal {{validator.QUESTION_MAX_LENGTH}} Zeichen
                        </div>
                    </div>
                </div>

                <div
                    class="micormate-form-quiz-element micormate-form-quiz-element-free-text-answer micormate-form-quiz-answers">
                <textarea id="Answer" autosize type="text" formControlName="answer"
                          class="micormate-form-quiz-maker-textarea"
                          placeholder="Antwort"
                          (keydown.enter)="keytab($event)"
                          (keydown.shift.enter)="keytab($event)"
                          [class.micormate-form-input-validation-error]="validator.validateInput(formData.controls.answer)"></textarea>
                    <div *ngIf="validator.validateInputRequired(formData.controls.answer)"
                         class="micormate-form-validation-error" i18n>
                        Antworttext ist erforderlich
                    </div>
                    <div *ngIf="validator.validateInputLength(formData.controls.answer)"
                         class="micormate-form-validation-error" i18n>
                        Maximal {{validator.ANSWER_MAX_LENGTH}} Zeichen
                    </div>
                    <div *ngIf="validator.validateDuplicatedAnswer(formData, formData.value.answer)"
                         class="micormate-form-validation-error" i18n>
                        Antwort bereits vorhanden
                    </div>
                </div>

                <div #answersForm class="micormate-form-quiz-element micormate-form-quiz-answers" formArrayName="answers">
                    <div *ngFor="let answer of getAnswersControls(); let i = index"
                         class="micormate-form-quiz-maker-answers">
                        <div
                            class="micormate-form-quiz-answer-element micormate-form-quiz-answer-element-text micormate-form-quiz-answer-element-text-remove-button">
                       <textarea autosize type="text" formControlName="{{i}}"
                                 class="micormate-form-quiz-maker-textarea"
                                 [id]="'Alternative_Answar_'+ i" placeholder="Alternative Antwort"
                                 (keydown.enter)="keytab($event)"
                                 (keydown.shift.enter)="keytab($event)"
                                 [class.micormate-form-input-validation-error]="validator.validateInput(answer)"></textarea>
                            <div *ngIf="validator.validateInputRequired(answer)"
                                 class="micormate-form-validation-error" i18n>
                                Antworttext ist erforderlich
                            </div>
                            <div *ngIf="validator.validateInputLength(answer)"
                                 class="micormate-form-validation-error" i18n>
                                Maximal {{validator.ANSWER_MAX_LENGTH}} Zeichen
                            </div>
                            <div *ngIf="validator.validateDuplicatedAnswer(formData, answer.value, true)"
                                 class="micormate-form-validation-error" i18n>
                                Antwort bereits vorhanden
                            </div>
                        </div>

                        <div class="micormate-form-quiz-maker-remove-answer" (click)="!isDisabled && removeAnswer(i)">
                            <svg aria-hidden="true" focusable="false" viewBox="0 0 448 512"
                                 class="micromate-organization-info-icon"
                                 [class.micromate-remove-icon-disabled]="isDisabled">
                                <use href="./assets/icons.svg#times-light"></use>
                            </svg>
                        </div>
                    </div>

                    <div class="micormate-form-quiz-maker-new-answers" *ngIf="!disableNewAnswerInput && !isDisabled">
                        <div class="micormate-form-quiz-element micormate-form-quiz-element-next-answer">
                       <textarea autosize type="text" [formControl]="newAnswer"
                                 class="micormate-form-quiz-maker-textarea"
                                 placeholder="Neue alternative Antwort"></textarea>
                        </div>
                    </div>
                </div>

                <div class="micormate-form-quiz-element-question"
                     (dragenter)="explanationHovering = true"
                     (dragleave)="explanationHovering = false"
                     (drop)="explanationHovering = false"
                     appDragAndDrop
                     (files)="filesDropped($event, 'explanation')">
                    <div class="micromate-editor-drag-area"
                         *ngIf="!editorQuizzesService.imageAndMediaPopupOpen.value && (dragAndDropService.dragOnPage$ | async)"
                         i18n
                         [class.micromate-editor-drag-area-active]="explanationHovering">
                        Bild hier ablegen
                    </div>
                    <div class="micormate-form-quiz-element-question-input">
                        <div class="micormate-form-quiz-element micormate-form-quiz-element-question-textarea">
                        <textarea id="explanation" autosize type="text"
                                  class="micormate-form-quiz-maker-textarea micromate-editor-quiz-explanation micormate-form-quiz-maker-textarea-drag-and-drop"
                                  placeholder="Erklärung zur Frage"
                                  formControlName="explanation"
                                  (keydown.shift.enter)="keytab($event)"
                                  [class.micormate-form-input-validation-error]="validator.validateInput(formData.controls.explanation)"></textarea>
                        </div>

                        <app-micromate-editor-image-drop-down
                            class="micromate-editor-drop-down"
                            [disabled]="isDisabled"
                            [class.micromate-editor-drop-down-preview]="quizExplanationMedia?.imageUrl !== undefined || quizExplanationMedia?.imageFile !== undefined"
                            [media]="quizExplanationMedia"
                            [fileDrop]="explanationFileDrop"
                            (moveMedia)="removeMedia('explanation')"
                            (openImageAndMediaPopup)="openImageAndMediaPopup('explanation')">
                        </app-micromate-editor-image-drop-down>
                    </div>

                    <div>
                        <div *ngIf="validator.validateInputLength(formData.controls.explanation)"
                             class="micormate-form-validation-error" i18n>
                            Maximal {{validator.EXPLANATION_MAX_LENGTH}} Zeichen
                        </div>
                    </div>
                </div>

                <app-advanced-info [sourceUrl]="formData.controls['sourceUrl']"
                                   [sourceLabel]="formData.controls['sourceLabel']">
                </app-advanced-info>
            </form>

            <div class="micromate-editor-quiz-creator-buttons">
                <app-button type="secondary"
                            [disabled]="!(editorQuizzesService.formDataChanged | async)"
                            (click)="cancelQuiz()">Änderungen verwerfen
                </app-button>
                <div class="micromate-editor-quiz-creator-save-buttons">
                    <app-button type="primary"
                                [disabled]="!(editorQuizzesService.formDataChanged | async) || !validator.validateFullQuiz(formData)"
                                (keydown.enter)="saveQuiz(formData)"
                                (click)="saveQuiz(formData)">Speichern
                    </app-button>
                    <app-button type="primary"
                                [disabled]="!(editorQuizzesService.formDataChanged | async) || !validator.validateFullQuiz(formData)"
                                (keydown.enter)="saveAndCreateNewQuiz(formData)"
                                (click)="saveAndCreateNewQuiz(formData)">Speichern und Neu
                    </app-button>
                </div>
            </div>
        </div>
    </div>

    <app-feedback-question *ngIf="(editorQuizService.newQuiz$ | async) === undefined"></app-feedback-question>
</div>
