<div class="mm-question-answer-documents">
    <div *ngIf="sourceLabel !== undefined && sourceLabel.length > 0; else noLabel"
         class="mm-question-answer-documents-text" i18n>
        Quelle:
        <a class="mm-question-answer-documents-text-link" target="_blank" href="{{sourceUrl}}">{{sourceLabel}}</a>
    </div>

    <ng-template #noLabel>
        <div class="mm-question-answer-documents-text">
            <a class="mm-question-answer-documents-text-link" target="_blank" href="{{sourceUrl}}" i18n>Quelle</a>
        </div>
    </ng-template>

</div>
