import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {SourceLearningPackage} from '../../../../../core/api-types/SourceLearningPackage';
import {take} from 'rxjs/operators';
import {EditorLearningPackageService} from '../../../../../core/editor/editor-learning-package.service';
import {ImportWizardService} from '../../import-wizard.service';
import {ImportableQuestion} from '../../../../../core/editor/types/QuestionConversion/ImportableQuestion';
import {EditorResource} from '../../../../../core/rest/editor-resource.service';

@Component({
    selector: 'app-learning-package-wizard-import',
    templateUrl: './learning-package-wizard-import.component.html',
    styleUrls: ['./learning-package-wizard-import.component.scss']
})
export class LearningPackageWizardImportComponent implements OnInit {
    public learningPackages$: Observable<SourceLearningPackage[]>;
    public hasNoLearningPackages: boolean = false;
    public learningPackageId: string;

    constructor(private editorLearningPackageService: EditorLearningPackageService,
                private importWizardService: ImportWizardService,
                private editorRestService: EditorResource) {
    }

    public ngOnInit(): void {
        this.learningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        this.learningPackages$ = this.editorRestService.getLearningPackagesForQuestionImport(this.learningPackageId);
        this.learningPackages$.pipe(take(1)).subscribe(docs => this.hasNoLearningPackages = docs.length === 0);
    }

    public async getLearningPackageQuestions(sourceLearningPackageId: string): Promise<void> {
        this.importWizardService.showSpinner();
        this.editorRestService.getImportableQuestionsForLearningPackage(this.learningPackageId, sourceLearningPackageId)
            .subscribe((questions: ImportableQuestion[]) => {
                this.importWizardService.hideSpinner();
                this.importWizardService.addQuestions(questions);
                this.importWizardService.currentStep = 'questions-summary';
            });
    }
}
