<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Lernende hinzufügen" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <div class="mm-add-learners">
            <label class="micormate-form-label" i18n>E-Mails
                <span class="micormate-form-label-required">*</span>
            </label>
            <div class="mm-add-learners-input">
                <app-textarea class="mm-add-learners-form-textarea"
                              [class.mm-add-learners-form-textarea-bigger]="data.activeChannels === undefined || data.activeChannels.length <= 1"
                              [formControl]="learnerEmails"
                              placeholder="hans@glueck.ch, peter@pan.com"
                              [autoresize]="true"
                              [minRow]="1"
                              [maxRow]="4"
                              [class.micormate-form-input-validation-error]="(learnerEmails.touched && !learnerEmails.valid) || errors !== undefined"
                              (keydown.enter)="addEmailsOnEnter($event)">
                </app-textarea>
                <div class="mm-add-learners-channel"
                     *ngIf="data.activeChannels !== undefined && data.activeChannels.length > 1"
                     (click)="changeChannel()">
                    <app-logos *ngIf="channel === 'microsoft-teams'" logo="mirosoft-teams-logo" title="Microsoft Teams"
                               class="mm-add-learners-channel-logo" i18n-title></app-logos>
                    <app-logos *ngIf="channel === 'learn-micromate'" logo="micromate-logo" title="Learn Micromate (Web)"
                               class="mm-add-learners-channel-logo" i18n-title></app-logos>
                    <app-icon icon="arrows-rotate-regular" class="mm-add-learners-channel-logo-switch"></app-icon>
                </div>
                <app-button type="secondary" icon="plus-light" class="mm-add-learner-input-button"
                            [disabled]="!validateAddEmailsButton()"
                            (click)="addEmails()"></app-button>
            </div>
            <ng-container *ngIf="singleEmail">
                <div *ngIf="learnerEmails.getRawValue() === undefined || getRawValue().length === 0"
                     class="micormate-form-validation-error" i18n>E-Mail ist erforderlich
                </div>
                <div *ngIf="getRawValue().length > 0 && !validateEmail(getRawValue())"
                     class="micormate-form-validation-error" i18n>Ungültige E-Mail
                </div>
                <div
                    *ngIf="getRawValue().length > 0 && !validateDuplicates(getRawValue(), true)"
                    class="micormate-form-validation-error" i18n>Diese E-Mail wurde bereits erfasst
                </div>
                <div *ngIf="getRawValue().length > 0 && validateEmail(getRawValue()) && !validateDomain(getRawValue())"
                     class="micormate-form-validation-error" i18n>Es sind nur E-Mailadressen einer verifizierten Domain
                    zulässig
                </div>
            </ng-container>
        </div>
        <div class="mm-add-learners-list" *ngIf="learnersToAdd.length > 0">
            <div *ngFor="let learner of learnersToAdd; let i = index" class="mm-add-learners-list-element">
                <div class="mm-add-learners-list-element-input-data">
                    <input type="text" class="micormate-form-input mm-add-learners-list-element-input"
                           [(ngModel)]="learner.email" (ngModelChange)="clearForm()">
                    <div class="mm-add-learners-channel" (click)="changeChannelForLearner(i)"
                         *ngIf="data.activeChannels !== undefined && data.activeChannels.length > 1">
                        <app-logos *ngIf="learner.mainChannel === 'microsoft-teams'"
                                   class="mm-add-learners-channel-logo"
                                   logo="mirosoft-teams-logo" title="Microsoft Teams" i18n-title></app-logos>
                        <app-logos *ngIf="learner.mainChannel === 'learn-micromate'"
                                   class="mm-add-learners-channel-logo"
                                   logo="micromate-logo" title="Learn Micromate (Web)" i18n-title></app-logos>
                        <app-icon icon="arrows-rotate-regular" class="mm-add-learners-channel-logo-switch"></app-icon>
                    </div>
                    <app-button type="transparent" icon="times-light" class="mm-add-learners-list-element-remove-button"
                                (click)="removeLearner(i)"></app-button>
                </div>
                <div *ngIf="learner.email === undefined || learner.email.length === 0"
                     class="micormate-form-validation-error" i18n>E-Mail ist erforderlich
                </div>
                <div *ngIf="learner.email.length > 0 && !validateEmail(learner.email)"
                     class="micormate-form-validation-error" i18n>Ungültige E-Mail
                </div>
                <div *ngIf="learner.email.length > 0 && !validateDuplicates(learner.email)"
                     class="micormate-form-validation-error" i18n>Diese E-Mail wurde bereits erfasst
                </div>
                <div *ngIf="learner.email.length > 0 && validateEmail(learner.email) && !validateDomain(learner.email)"
                     class="micormate-form-validation-error" i18n>Es sind nur E-Mailadressen einer verifizierten Domain
                    zulässig
                </div>
            </div>
        </div>
        <app-message-box type="info" class="mm-add-learners-box" *ngIf="showSendRegistrationMail">
            <div *ngIf="false" class="micromate-registration-mail-info">
                <div class="micromate-registration-mail-info-title" i18n>
                    Registrierungsmail senden:
                </div>
                <app-switch-toggle [formControl]="sendRegistrationMailForm"></app-switch-toggle>
            </div>
            <div *ngIf="sendRegistrationMailForm.getRawValue()" i18n>
                Den Lernende mit dem Kanal <strong>Learn Micromate (Web)</strong> wird <strong>eine
                Registrierungsmail</strong> zugestellt. Sollte der Benutzer bereits existieren, wird keine E-Mail
                gesendet.
            </div>
            <div *ngIf="!sendRegistrationMailForm.getRawValue()" i18n>
                Den Lernende mit dem Kanal <strong>Learn Micromate (Web)</strong> wird <strong>keine
                Registrierungsmail</strong> zugestellt. Diese kann jederzeit im Profil manuell ausgelöst werden.
            </div>
        </app-message-box>
        <app-error-box class="mm-add-learners-box"
                       *ngIf="errors !== undefined &&  (errors.invalidLearnerInOrganization.length > 0 || errors.invalidLearnerNotInAnotherOrganization.length > 0 || errors.invalidLearnerDomainBelongsToAnotherOrganization.length > 0)">
            <div class="mm-add-learners-validation-error-box-content">
                <div class="mm-add-learners-validation-error" i18n
                     *ngIf="errors.invalidLearnerInOrganization.length > 0">
                    Die folgenden Lernenden sind bereits ein Teil der Organisation:
                    <ul class="mm-add-learners-validation-error-list">
                        <li *ngFor="let invalidLearner of errors.invalidLearnerInOrganization"
                            class="mm-add-learners-duplicates-error-list-element">
                            {{invalidLearner}}
                        </li>
                    </ul>
                </div>
                <div class="mm-add-learners-validation-error" i18n
                     *ngIf="errors.invalidLearnerNotInAnotherOrganization.length > 0 || errors.invalidLearnerDomainBelongsToAnotherOrganization.length > 0">
                    Die folgenden Lernenden können nicht hinzugefügt werden, da deiner Organisation die Berechtigung für
                    die
                    Domain oder die angegebene E-Mail fehlt:
                    <ul class="mm-add-learners-validation-error-list">
                        <li *ngFor="let invalidLearner of getInvalidLearnersList(errors.invalidLearnerNotInAnotherOrganization, errors.invalidLearnerDomainBelongsToAnotherOrganization) "
                            class="mm-add-learners-duplicates-error-list-element">
                            {{invalidLearner}}
                        </li>
                    </ul>
                </div>
            </div>
        </app-error-box>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                type="secondary"
                (click)="popupService.close()" i18n>
                Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                [action]="saveOrganization"
                type="primary"
                [disabled]="!validateSaveButton()" i18n>
                Speichern
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
