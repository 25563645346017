import {Component, HostListener, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {PopupService} from './popup.service';
import {PopupOptions} from './PopupOptions';
import {PopupClosable} from './PopupClosable';

@Component({
    selector: 'app-popup',
    templateUrl: './popup.component.html',
    styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
    @ViewChild('popupContent', {static: true, read: ViewContainerRef})
    public viewContainerRef: ViewContainerRef;

    public isOpen: boolean = false;
    public popupOptions: PopupOptions = new PopupOptions();
    public workInProgress: boolean = false;

    constructor(private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.popupService.initialize(this);
    }

    public setOptions(popupOptions: PopupOptions): void {
        this.popupOptions = popupOptions;
    }

    public setIsClosable(isClosable: PopupClosable): void {
        this.popupOptions.isClosable = isClosable;
    }

    public setWorkInProgress(state: boolean): void {
        this.workInProgress = state;
    }

    @HostListener('document:keydown.escape', ['$event'])
    public escClose(): void {
        if (!this.workInProgress && this.popupOptions.isClosable !== 'closableWithoutEsc') {
            this.close();
        }
    }

    @HostListener('window:popstate', ['$event'])
    public onPopState(): void {
        if (this.isOpen) {
            this.close();
        }
    }

    public close(): void {
        if (!this.workInProgress && this.isOpen && (this.popupOptions.isClosable === 'closable' || this.popupOptions.isClosable === 'closableWithoutEsc')) {
            this.popupService.close();
        }
    }
}
