import {Injectable} from '@angular/core';
import {AbstractControl, FormGroup, UntypedFormArray, UntypedFormGroup} from '@angular/forms';
import {QuestionValidatorService} from './question-validator.service';
import {FreeTextQuizFormGroup} from '../quiz-makers/standard-question/free-text-quiz/FreeTextQuizFormGroup';

@Injectable({
    providedIn: 'root'
})
export class FreeTextQuestionValidatorService extends QuestionValidatorService {

    public validateDuplicatedAnswer(formData: UntypedFormGroup, answerText: string, alternativeAnswer?: boolean): boolean {
        const answersFormData = formData.get('answers') as UntypedFormArray;
        const answers = answersFormData.controls.map((control: AbstractControl) => (control.value as string).trim().toLowerCase());
        const text = answerText.trim().toLowerCase();
        if (alternativeAnswer) {
            const answerValue = (formData.controls.answer.value as string).trim().toLowerCase();
            return text.length > 0 && (answers.filter(answer => answer === text).length > 1 || answerValue === text);
        }
        return text.length > 0 && answers.some(answer => answer === text);
    }

    public validateFullQuiz(formData: FormGroup<FreeTextQuizFormGroup>): boolean {
        return formData.valid && this.validateAllUniqueAnswers(formData);
    }

    public validateAllUniqueAnswers(formData: UntypedFormGroup): boolean {
        const answers = (formData.get('answers') as UntypedFormArray).controls.map((control: AbstractControl) => (control.value as string).trim().toLowerCase());
        answers.push((formData.controls.answer.value as string).trim().toLowerCase());
        return (new Set(answers)).size === answers.length;
    }
}
