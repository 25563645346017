import {Injectable} from '@angular/core';
import {SortOption, SortType} from '../../core/editor/types/Sort';
import {QuizTypeName} from '../../core/editor/types/QuizType';
import {QuizOverview} from '../../core/api-types/quizOverview';
import {QuestionParamsData} from './QuestionParamsData';
import {BehaviorSubject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Quiz} from '../../core/api-types/quiz';
import {Answer} from '../../core/api-types/answer';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LearningPackageQuestionsService {

    private questionsPresentation: BehaviorSubject<QuizOverview[] | undefined> = new BehaviorSubject<QuizOverview[] | undefined>(undefined);
    public questionsPresentation$ = this.questionsPresentation.asObservable();

    public numberOfQuestions$ = this.questionsPresentation$.pipe(map(questionsPresentation => questionsPresentation?.length));

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router) {
    }

    public setQuestions(questions: QuizOverview[], sortParam: SortType): void {
        switch (sortParam) {
            case SortOption.CREATION_DATE:
                this.questionsPresentation.next([...questions]);
                break;
            case SortOption.RANKING:
                this.questionsPresentation.next(this.sortQuestionsSortedByRanking(questions));
                break;
            default:
                this.updateUrl({sort: SortOption.CREATION_DATE});
                break;
        }
    }

    private sortQuestionsSortedByRanking(filteredQuestions: QuizOverview[]): QuizOverview[] {
        return [...filteredQuestions].map(question => {
            if (question.rankingPlace === undefined) {
                question.rankingPlaceNumber = question.quizType === QuizTypeName.REFLECTION_FREE_TEXT_TYPE ? (filteredQuestions.length + 2) : (filteredQuestions.length + 1);
            } else {
                question.rankingPlaceNumber = parseInt(question.rankingPlace);
            }
            return question;
        }).sort((x: QuizOverview, y: QuizOverview) => x.rankingPlaceNumber - y.rankingPlaceNumber);
    }

    public updateUrl(queryParams: { [key: string]: string; } | QuestionParamsData): void {
        void this.router.navigate(
            [],
            {
                relativeTo: this.activatedRoute,
                queryParams: queryParams,
                queryParamsHandling: 'merge',
                replaceUrl: true
            });
    }

    public getQuestionIdToRedirectAfterDeletion(language: string, mainLanguage: string, questionId: string): string {
        if (language !== mainLanguage) {
            return questionId;
        }

        const questions = this.questionsPresentation.value;
        if (questions.length <= 1) {
            return undefined;
        }

        const questionIndex = questions.findIndex(question => question.quizId === questionId);
        if (questionIndex === 0) {
            return questions[1].quizId;
        }
        return questions[questionIndex - 1].quizId;
    }

    public filterAnswers(question: Quiz, textFilter: string): boolean {
        if ((question.quizType === QuizTypeName.SINGLE_CHOICE_TYPE || question.quizType === QuizTypeName.MULTIPLE_CHOICE_TYPE) && this.filterChoiceQuiz(question.answers as Answer[], textFilter)) {
            return true;
        }
        return question.quizType === QuizTypeName.FREE_TEXT_TYPE && this.filterFreeTextQuiz(question.answer, question.answers as string[], textFilter);
    }

    public isFilterInQuestionOrExplanation(question: Quiz, textFilter: string): boolean {
        return question.quiz.text.toLowerCase().includes(textFilter.toLowerCase()) || (question.explanation?.text !== undefined && question.explanation.text.toLowerCase().includes(textFilter.toLowerCase()));
    }

    private filterChoiceQuiz(answers: Answer[], textFilter: string): boolean {
        return answers.some((answer: Answer) => answer.answerText.toLowerCase().includes(textFilter.toLowerCase()) || (answer.explanation !== undefined && answer.explanation.toLowerCase().includes(textFilter.toLowerCase())));
    }

    private filterFreeTextQuiz(answer: string, answers: string[], textFilter: string): boolean {
        return answer.toLowerCase().includes(textFilter.toLowerCase()) || (answers.some(answerInfo => answerInfo.toLowerCase().includes(textFilter.toLowerCase())));
    }
}
