import {Injectable} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {BehaviorSubject, of} from 'rxjs';
import {shareReplay, switchMap} from 'rxjs/operators';
import {ApiHistoryService} from '../rest/api-history.service';

@Injectable({
    providedIn: 'root'
})
export class ApiHistoryInfoService {

    constructor(private router: Router, private apiHistoryService: ApiHistoryService) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.params.apiId !== undefined) {
                    const apiId = path.snapshot.params.apiId as string;
                    this.selectedApiId.next(apiId);
                }
            }
        });
    }

    public selectedApiId = new BehaviorSubject<string>(undefined);
    public selectedApiId$ = this.selectedApiId.asObservable();

    public selectedApi$ = this.selectedApiId$.pipe(
        switchMap((selectedApiId: string) => {
            if (selectedApiId !== undefined) {
                return this.apiHistoryService.getApiInfo(selectedApiId);
            }
            return of(undefined);
        }),
        shareReplay(1)
    );
}
