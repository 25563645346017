import {Component, Input} from '@angular/core';
import {Icons} from '../../../icon/icon.component';
import {PopupService} from '../../popup.service';

@Component({
    selector: 'app-popup-action-button',
    templateUrl: './popup-action-button.component.html'
})
export class PopupActionButtonComponent {

    public isActionInProgress: boolean;

    @Input()
    public icon: Icons;

    @Input()
    public type: 'primary' | 'secondary' | 'secondary-dark' | 'ai' | 'transparent' | 'dangerous' = 'primary';

    @Input()
    public disabled: boolean = false;

    @Input()
    public action: () => Promise<void>;

    constructor(public popupService: PopupService) {
    }

    public execute: () => Promise<void> = async () => {
        if (!this.disabled && this.action !== undefined) {
            this.popupService.setWorkInProgress(true);
            await this.action();
            this.popupService.setWorkInProgress(false);
        }
    };
}
