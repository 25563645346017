import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';

export type OrganizationStatusFilter = 'active' | 'deactivated';

export class OrganizationLearnersListPaginationRequest implements ListPaginationRequest {
    constructor(public filter: string,
                public pageNumber: number,
                public pageAmount: string,
                public organizationStatusFilter: OrganizationStatusFilter) {
    }
}
