import {Component, Input, OnInit} from '@angular/core';
import {Popup} from '../../../components/popup/Popup';
import {InboxFeedbackPopupData} from './InboxFeedbackPopupData';
import {BehaviorSubject, Observable, switchMap} from 'rxjs';
import {
    FeedbackDto,
    FeedbackRatingType,
    FeedbackStatusType
} from '../../quiz-makers/components/feedback-question/FeedbackDto';
import {EditorFeedbackService} from '../../../core/editor/editor-feedback.service';
import {PopupService} from '../../../components/popup/popup.service';
import {EditorLearningPackageService} from '../../../core/editor/editor-learning-package.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-inbox-feedback-popup',
    templateUrl: './inbox-feedback-popup.component.html',
    styleUrls: ['./inbox-feedback-popup.component.scss']
})
export class InboxFeedbackPopupComponent implements Popup<InboxFeedbackPopupData>, OnInit {
    @Input()
    public data: InboxFeedbackPopupData;

    private readFeedbackDataTrigger: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

    public feedback$: Observable<FeedbackDto>;
    public currentActiveLearningPackageId: string;

    constructor(public popupService: PopupService,
                private editorFeedbackService: EditorFeedbackService,
                public editorLearningPackageService: EditorLearningPackageService,
                private toastr: ToastrService) {
    }

    public ngOnInit(): void {
        this.currentActiveLearningPackageId = this.editorLearningPackageService.getCurrentActiveLearningPackageId();
        this.feedback$ = this.readFeedbackDataTrigger.asObservable().pipe(
            switchMap(() => this.editorFeedbackService.getFeedback(this.data.feedbackId)));
    }

    public async reopenFeedback(feedbackId: string): Promise<void> {
        await this.changeFeedbackState(feedbackId, FeedbackStatusType.Open);
    }

    public async acceptFeedback(value: { feedbackId: string; rating?: FeedbackRatingType; }): Promise<void> {
        await this.changeFeedbackState(value.feedbackId, FeedbackStatusType.Accept, value.rating);
    }

    private async changeFeedbackState(feedbackId: string, state: FeedbackStatusType, rating?: FeedbackRatingType): Promise<void> {
        try {
            await this.editorFeedbackService.changeFeedbackState(feedbackId, state, rating);
            this.readFeedbackDataTrigger.next();
        } catch (e) {
            this.toastr.error($localize`Könnte nicht gespeichert werden`);
        }

    }
}
