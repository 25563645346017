<app-popup-container *ngIf="feedback$ | async as feedback">
    <app-popup-header class="mm-inbox-feedback-header">
        <app-popup-header-title *ngIf="feedback.quizTranslationId !== undefined"
                                title="Feedback zu einer Frage" i18n-title></app-popup-header-title>
        <app-popup-header-title *ngIf="feedback.quizTranslationId === undefined"
                                title="Feedback zum Lernpaket" i18n-title></app-popup-header-title>
        <a *ngIf="feedback.quizTranslationId !== undefined" class="mm-inbox-feedback-header-question-link"
           [routerLink]="['editor', 'lernpakete', this.currentActiveLearningPackageId, 'fragen', feedback.quizId]">
            <app-icon icon="arrow-up-right-from-square"
                      class="mm-inbox-feedback-header-question-link-icon"></app-icon>
        </a>

    </app-popup-header>

    <app-popup-content>
        <app-feedback-preview [feedback]="feedback"
                              (reopenClicked)="reopenFeedback($event)"
                              (acceptClicked)="acceptFeedback($event)"></app-feedback-preview>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons class="mm-inbox-feedback-button">
            <app-popup-action-button
                type="secondary"
                (click)="popupService.close('refresh')"
                i18n>Schliessen
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
