import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Icons} from '../icon/icon.component';

@Component({
    selector: 'app-card',
    templateUrl: './card.component.html',
    styleUrls: ['./card.component.scss']
})
export class CardComponent {

    @Input()
    public type: 'square' | 'flat' | 'custom' = 'square';

    @Input()
    public title: string;

    @Input()
    public description: string;

    @Input()
    public icon: Icons;

    @Input()
    public isSelected: boolean;

    @Input()
    public isAiFeature: boolean = false;

    @Output()
    public cardClicked: EventEmitter<void> = new EventEmitter<void>();

}
