import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Organization} from '../api-types/organization';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {OrganizationApi} from '../api-types/organizationApi';
import {ListPaginationRequest} from '../api-types/listPaginationRequest';
import {ListPagination} from '../api-types/listPagination';
import {Features} from '../features/Features';
import {SuperAdminOrganizationSettings} from '../api-types/superAdminOrganizationSettings';

@Injectable({
    providedIn: 'root'
})
export class OrganizationResources {

    constructor(private http: HttpClient) {
    }

    public getOrganizations(): Observable<Organization[]> {
        return this.http.get<Organization[]>(`${environment.serverUrl}/api/website/admin/organizations`);
    }

    public getOrganizationsList(listPaginationRequest: ListPaginationRequest): Observable<ListPagination<Organization>> {
        return this.http.get<ListPagination<Organization>>(`${environment.serverUrl}/api/website/admin/organizations/list?filter=${listPaginationRequest.filter}&pageNumber=${listPaginationRequest.pageNumber}&pageAmount=${listPaginationRequest.pageAmount}`);
    }

    public createOrganization(organization: OrganizationApi): Observable<string> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return this.http.post(`${environment.serverUrl}/api/website/admin/organizations/`, organization, {responseType: 'text'});
    }

    public getOrganization(organizationId: string): Observable<SuperAdminOrganizationSettings> {
        return this.http.get<SuperAdminOrganizationSettings>(`${environment.serverUrl}/api/website/admin/organizations/${organizationId}`);
    }

    public saveOrganizationFeatures(organizationId: string, features: Features): Promise<string> {
        return this.http.post(`${environment.serverUrl}/api/website/admin/organizations/${organizationId}/features`, features, {responseType: 'text'}).toPromise();
    }

    public saveLicenceForOrganization(organizationId: string, licence: number): Promise<string> {
        return this.http.post(`${environment.serverUrl}/api/website/admin/organizations/${organizationId}/licence`, {licence: licence}, {responseType: 'text'}).toPromise();
    }

    public changeDomainForOrganization(organizationId: string, domains: string[]): Promise<string> {
        return this.http.post(`${environment.serverUrl}/api/website/admin/organizations/${organizationId}/domain`, domains, {responseType: 'text'}).toPromise();
    }

    public changeDomainsForOrganization(organizationId: string, domains: string[]): Promise<{ validationResult: 'valid' | 'invalidLearnerDomains' | 'emptyDomains'; }> {
        return this.http.post<{ validationResult: 'valid' | 'invalidLearnerDomains' | 'emptyDomains'; }>(`${environment.serverUrl}/api/website/admin/organizations/${organizationId}/domain/validate`, domains).toPromise();
    }

    public removeEvents(organizationId: string): Observable<string> {
        return this.http.delete(`${environment.serverUrl}/api/website/admin/organizations/${organizationId}/events`, {responseType: 'text'});
    }

    public renameOrganization(organizationId: string, name: string): Observable<string> {
        return this.http.put(`${environment.serverUrl}/api/website/admin/organizations/${organizationId}/rename`, {name: name}, {responseType: 'text'});
    }
}
