import {Component} from '@angular/core';
import {ImportWizardService} from '../import-wizard.service';

@Component({
    selector: 'app-import-questions',
    templateUrl: './import-questions.component.html',
    styleUrls: ['./import-questions.component.scss']
})
export class ImportQuestionsComponent {
    constructor(public importWizardService: ImportWizardService) {
    }

}
