import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DragAndDropService} from '../../core/drag-and-drop/drag-and-drop.service';

@Component({
    selector: 'app-drag-and-drop',
    templateUrl: './drag-and-drop.component.html',
    styleUrls: ['./drag-and-drop.component.scss']
})
export class DragAndDropComponent {
    @Input()
    public acceptFileType: string;

    @Output()
    public files: EventEmitter<FileList> = new EventEmitter<FileList>();

    public hoverDragAndDrop: boolean = false;

    constructor(private dragAndDropService: DragAndDropService) {
    }

    public onDragOver(event: DragEvent): void {
        this.hoverDragAndDrop = true;
        event.preventDefault();
        event.stopPropagation();
    }

    public onDragLeave(event: DragEvent): void {
        this.hoverDragAndDrop = false;
        event.preventDefault();
        event.stopPropagation();
    }

    public onDrop(event: DragEvent): void {
        this.hoverDragAndDrop = false;
        event.preventDefault();
        event.stopPropagation();
        const droppedFiles = event.dataTransfer.files;
        const numberOfFiles = droppedFiles.length;
        if (numberOfFiles > 0) {
            //const file = event.dataTransfer.files.item(0);
            const files = event.dataTransfer.files;
            this.emitFile(files);
        }
        this.dragAndDropService.set(false);
    }


    public onSelectedFile(event: EventTarget): void {
        const element = event as HTMLInputElement;
        const fileList: FileList = element.files;
        if (fileList.length > 0) {

            this.emitFile(fileList);
        }
    }

    private emitFile(files: FileList): void {
        this.files.emit(files);
    }
}
