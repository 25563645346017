import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, UrlTree} from '@angular/router';
import {AuthService} from '../auth.service';
import {AuthUntilUserOrganizationRoleService} from '../auth-until-user-organization-role.service';

@Injectable({
    providedIn: 'root'
})
export class RoleGuard implements CanActivate {

    constructor(private authUntilUserOrganizationRoleService: AuthUntilUserOrganizationRoleService,
                private authService: AuthService,
                private router: Router) {
    }

    public async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
        const expectedRoles = next.data?.['mustBe'] as string[] ?? [];
        const availableRoles = this.authService.getAuthenticatedUser().roles;
        const availableOrganisations = this.authService.getAuthenticatedUser().organisations;
        const hasRole = await this.authUntilUserOrganizationRoleService.hasRoles(expectedRoles, availableRoles, availableOrganisations);
        if (!hasRole) {
            this.authService.removeSelectedUser();
            return this.router.createUrlTree(['/missing-role']);
        }

        return true;
    }
}
