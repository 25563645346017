import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {QuizTranslation} from '../../core/api-types/quizTranslation';
import {EditorQuestionService} from '../../core/editor/editor-question.service';
import {Subscription} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {MultilanguageService} from '../../core/editor/multilanguage.service';

@Component({
    selector: 'app-editor-language-switcher',
    templateUrl: './editor-language-switcher.component.html',
    styleUrls: ['./editor-language-switcher.component.scss']
})
export class EditorLanguageSwitcherComponent implements OnInit, OnDestroy {
    @Input() currentLanguage: string;
    @Input() mainLanguage: string;
    @Input() disabled: boolean = false;
    @Input() readQuestionsFromSelectedLearningPackage: boolean = true;
    @Input() availableLanguages: string[];

    @Output() activeLanguage = new EventEmitter<string>();

    public languages: string[];
    public selectedLearningPackageId$ = new Subscription();

    constructor(public editorQuizService: EditorQuestionService,
                private editorLearningPackageService: EditorLearningPackageService,
                private editorService: EditorResource,
                public multilanguageService: MultilanguageService) {
    }

    public ngOnInit(): void {
        if (this.readQuestionsFromSelectedLearningPackage) {
            this.selectedLearningPackageId$ = this.editorLearningPackageService.selectedLearningPackageId$.pipe(
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                filter((selectedLearningPackageId: string) => !!selectedLearningPackageId),
                switchMap((selectedLearningPackageId: string) => this.editorService.getLearningPackageLanguages(selectedLearningPackageId)))
                .subscribe((languages: string[]) => this.languages = languages);
        } else {
            this.languages = this.availableLanguages;
        }
    }

    public ngOnDestroy(): void {
        this.selectedLearningPackageId$.unsubscribe();
    }

    public changeLanguage(language: string): void {
        if (!this.disabled) {
            this.activeLanguage.next(language);
        }
    }

    public translationNotExist(quizzes: QuizTranslation[] | null, language: string): boolean {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!quizzes) {
            return false;
        }
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        return !quizzes.find(quiz => quiz.language === language);
    }
}
