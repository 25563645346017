<div class="micromate-app-page-navigation" *ngIf="activeLearningPackage !== undefined">
    <div class="micromate-app-page-navigation-editor-learnpackage">
        <div
            class="micromate-app-page-navigation-editor-learnpackage-name">{{activeLearningPackage.name}}</div>
        <div class="micromate-app-page-navigation-editor-learnpackage-language">
            {{multilanguageService.createShortName(activeLearningPackage.language)}}
        </div>
    </div>

    <div class="micromate-app-page-navigation-editor" *ngIf="editorLearningPackageNavigation !== undefined">

        <a *ngFor="let options of editorLearningPackageNavigation"
           [routerLink]="['editor', 'lernpakete', (editorLearningPackageService.selectedLearningPackageId$ | async), options.url]"
           [class.micromate-app-page-navigation-options-active]="(navigationService.activeAppPageNavigationItem$ | async) === options.url"
           class="micromate-app-page-navigation-options">
            <app-icon [icon]="options.icon" class="micromate-app-page-navigation-options-icon"></app-icon>
            <div class="micromate-app-page-navigation-options-title">{{options.label}}</div>
            <div class="micromate-app-page-navigation-editor-status" *ngIf="options.url === 'versionen'"
                 [title]="learningPackageStatusText">
                <app-icon icon="dot-circle-regular"
                          [class.micromate-learnpackage-status-published]="learningPackageStatus === 'published'"
                          [class.micromate-learnpackage-status-open-changes]="learningPackageStatus === 'open changes'"
                          [class.micromate-learnpackage-status-not-published]="learningPackageStatus === 'not published'"></app-icon>
            </div>
        </a>
    </div>

</div>
