import {Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {OverlayService} from '../overlay/overlay.service';
import {Subscription} from 'rxjs';
import {Icons} from '../icon/icon.component';

@Component({
    selector: 'app-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit, OnDestroy {
    @Input()
    public icon: Icons = 'ellipsis-h-light';

    @Input()
    public shouldCloseOnContentClick: boolean = true;

    @ViewChild('overlayContent', {static: true, read: TemplateRef})
    public contentTpl: TemplateRef<unknown>;

    private isOpen: boolean = false;
    private isOpenSubscription = new Subscription();

    constructor(private overlayService: OverlayService,
                private element: ElementRef<HTMLElement>) {
    }

    public ngOnInit(): void {
        this.isOpenSubscription = this.overlayService.isOpen$.subscribe(isOpen => {
            if (!isOpen) {
                this.isOpen = false;
            }
        });
    }

    public clickDropdownOptionButton(event: MouseEvent): void {
        const nativeElement = this.element.nativeElement;
        event.stopPropagation();
        if (!this.isOpen) {
            this.overlayService.open(this.contentTpl, nativeElement, this.isOpen);
            this.isOpen = true;
        } else {
            this.overlayService.close();
        }
    }

    public contentClicked(event: MouseEvent): void {
        if (!this.shouldCloseOnContentClick) {
            event.stopPropagation();
        }
    }

    public ngOnDestroy(): void {
        this.isOpenSubscription.unsubscribe();
    }

}
