import {Injectable} from '@angular/core';
import {Quiz} from '../api-types/quiz';
import {EditorResource} from '../rest/editor-resource.service';
import {EditorQuestionsService} from './editor-questions.service';
import {EditorLearningPackageService} from './editor-learning-package.service';
import {EditorQuestionService} from './editor-question.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {QuizEditor} from './types/QuizEditor';
import {QuizTypeName} from './types/QuizType';
import {MultipleTextChoiceQuiz} from './types/MultipleTextQuiz/MultipleTextChoiceQuiz';
import {FreeTextQuiz} from './types/FreeTextQuiz/FreeTextQuiz';
import {TrueFalseQuiz} from './types/TrueFalseQuiz/TrueFalseQuiz';
import {SingleTextChoiceQuiz} from './types/SingleTextChoice/SingleTextChoiceQuiz';
import {Answer} from '../api-types/answer';
import {QuizMediaEditor} from './types/QuizMediaEditor';
import {QuizExplanation} from './types/QuizExplanation';
import {QuizQuestion} from './types/QuizQuestion';
import {ReflectionFreeTextQuestion} from './types/ReflectionFreeTextQuestion/ReflectionFreeTextQuestion';
import {EditorQuestion} from '../api-types/EditorQuestion';

@Injectable({
    providedIn: 'root'
})
export class EditorQuizCreatorService {

    constructor(private editorService: EditorResource,
                private editorQuizzesService: EditorQuestionsService,
                private editorLearningPackageService: EditorLearningPackageService,
                private editorQuizService: EditorQuestionService,
                private router: Router,
                private toastr: ToastrService) {
    }

    public async saveQuiz(quizEditor: QuizEditor, quiz: Quiz, mainLearningPackageId: string, learningPackageId: string, queryParams: { filterText: string; sort: string; filterLanguage?: string; }): Promise<void> {
        const quizData = await this.createQuizData(quizEditor, quiz, quizEditor.question?.media, quizEditor?.explanation?.media);
        this.editorService.uploadQuiz(learningPackageId, quizData).then(() => {
            this.toastr.success('Die Frage wurde gespeichert');
            this.editorQuizzesService.quizSaved.next(true);
            this.editorLearningPackageService.selectedLearningPackageId.next(mainLearningPackageId);
            if (this.editorQuizService.newQuiz$.value !== undefined) {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                this.router.navigate([`/editor/lernpakete/${learningPackageId}/fragen/${quiz.id}`],
                    {queryParams: queryParams});
            }
        }).catch(() => this.toastr.error('Frage konnte nicht gespeichert werden'));

    }

    public async saveQuizAndAddNew(quizEditor: QuizEditor, quiz: Quiz, mainLearningPackageId: string, learningPackageId: string, queryParams: { filterText: string; sort: string; filterLanguage?: string; }): Promise<void> {
        const quizData = await this.createQuizData(quizEditor, quiz, quizEditor.question?.media, quizEditor?.explanation?.media);
        this.editorService.uploadQuiz(learningPackageId, quizData).then(() => {
            this.toastr.success('Die Frage wurde gespeichert');
            this.editorQuizzesService.quizSaved.next(true);
            this.editorLearningPackageService.selectedLearningPackageId.next(mainLearningPackageId);
            this.editorQuizService.newQuiz$.next(undefined);
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([`/editor/lernpakete/${mainLearningPackageId}/fragen/neue-frage`], {queryParams: queryParams});
        }).catch(() => this.toastr.error('Frage konnte nicht gespeichert werden'));

    }

    public async createQuizData(quizEditor: QuizEditor, quiz: Quiz, quizMedia: QuizMediaEditor, explanationMedia: QuizMediaEditor,): Promise<FormData> {
        const formData = new FormData();
        const editorQuestion = this.getEditorQuestion(quizEditor, quiz);
        formData.append('editorQuestion', JSON.stringify(editorQuestion));

        formData.append('quizFileName', quizMedia?.imageFile?.file?.name);
        if (quizMedia?.imageFile !== undefined) {
            formData.append('quizFile', quizMedia.imageFile.file, quizMedia.imageFile.file.name.toLowerCase());
        }

        if (explanationMedia !== undefined) {
            formData.append('explanationFileName', explanationMedia?.imageFile?.file?.name);
            if (explanationMedia?.imageFile !== undefined) {
                formData.append('explanationFile', explanationMedia.imageFile.file, explanationMedia.imageFile.file.name.toLowerCase());
            }
        }

        return formData;
    }

    private getEditorQuestion(quizEditor: QuizEditor, quiz: Quiz): EditorQuestion {
        const quizData = EditorQuizCreatorService.createQuiz(quizEditor, quiz);
        const editorQuestion = quizData as EditorQuestion;
        editorQuestion.selectedTags = quizEditor.selectedAvailableTags;
        editorQuestion.selectedNewTags = quizEditor.selectedNewTags;
        return editorQuestion;
    }

    public static createQuiz(quizEditor: QuizEditor, quiz: Quiz): Quiz {
        let answers;
        const question = this.buildQuizQuestion(quizEditor);
        const explanation = this.buildQuizExplanation(quizEditor);

        switch (quiz.quizType) {
            case QuizTypeName.MULTIPLE_CHOICE_TYPE:
                answers = (quizEditor.answers as { isCorrectAnswer: boolean; answerText: string; }[]).map(answer => {
                    answer.answerText = answer.answerText.trim();
                    return answer;
                });
                return new MultipleTextChoiceQuiz(quiz.id, quiz.quizId, question, answers as Answer[], explanation, quizEditor.sourceUrl, quizEditor.sourceLabel, quiz.originalContentHash);
            case QuizTypeName.SINGLE_CHOICE_TYPE:
                answers = (quizEditor.answers as { isCorrectAnswer: boolean; answerText: string; explanation: string; }[]).map(answer => {
                    answer.answerText = answer.answerText.trim();
                    if (answer?.explanation !== undefined) {
                        answer.explanation = answer.explanation.trim();
                    }
                    return answer;
                });
                return new SingleTextChoiceQuiz(quiz.id, quiz.quizId, question, answers as Answer[], explanation, quizEditor.sourceUrl, quizEditor.sourceLabel, quiz.originalContentHash);
            case QuizTypeName.FREE_TEXT_TYPE:
                answers = (quizEditor.answers as string[]).map(answer => answer.trim());
                return new FreeTextQuiz(quiz.id, quiz.quizId, question, quizEditor.answer.trim(), answers as string[], explanation, quizEditor.sourceUrl, quizEditor.sourceLabel, quiz.originalContentHash);
            case QuizTypeName.TRUE_FALSE_TYPE:
                return new TrueFalseQuiz(quiz.id, quiz.quizId, question, quizEditor.positiveAnswer, quizEditor.negativeAnswer, explanation, quizEditor.sourceUrl, quizEditor.sourceLabel, quiz.originalContentHash);
            case QuizTypeName.REFLECTION_FREE_TEXT_TYPE:
                return new ReflectionFreeTextQuestion(quiz.id, quiz.quizId, question, quiz.originalContentHash);
            default:
                console.log('Not implemented', quiz.quizType);
        }
    }

    private static buildQuizExplanation(quiz: QuizEditor): QuizExplanation {
        let explanation: QuizExplanation;
        if (quiz.explanation?.text !== undefined || quiz.explanation?.media !== undefined) {
            explanation = {};
            if (quiz.explanation?.text !== undefined) {
                explanation.text = quiz.explanation.text.trim();
            }

            const explanationMedia = quiz.explanation?.media;
            if (explanationMedia !== undefined) {
                explanation.imageUrl = explanationMedia?.imageUrl;
                explanation.mediaType = explanationMedia?.type;
                explanation.mediaUrl = explanationMedia?.mediaUrl;
            }
        }

        return explanation;
    }

    private static buildQuizQuestion(quiz: QuizEditor): QuizQuestion {
        const question: QuizQuestion = {text: quiz.question.text.trim()};

        const questionMedia = quiz.question?.media;
        if (questionMedia !== undefined) {
            question.imageUrl = questionMedia?.imageUrl;
            question.mediaType = questionMedia?.type;
            question.mediaUrl = questionMedia?.mediaUrl;
        }

        return question;
    }
}
