<div class="mm-search-input">
    <div class="micormate-form-element">
        <input class="micormate-form-input micromate-editor-input-filter"
               [class.micormate-form-input-with-value]="hasValue()"
               type="text" [value]="value" (input)="handleInput($event)"
               (blur)="onTouched()" [disabled]="disabled"
               i18n-placeholder placeholder="Suchen">
        <span class="micormate-form-input-remove-button" *ngIf="hasValue()"
              (click)="clearSearch()">
               <app-icon icon="times-light" class="micormate-form-input-remove-button-icon"></app-icon>
        </span>
    </div>
    <div class="mm-search-input-data-amount" *ngIf="elementsCount !== undefined">
        {{elementsCount}}
    </div>
</div>
