<div class="micromate-error-box-question-errors">
    <div class="micromate-error-box-subtitle" i18n>Frage {{activeInvalidQuestion.index + 1}}</div>
    <ul class="micromate-error-box-message-list">
        <!-- Property Errors-->
        <ng-container>
            <!-- Question errors-->
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.propertyErrors.question.includes('required')" i18n>
                Fragetext ist erforderlich
            </li>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.propertyErrors.question.includes('maxLength')" i18n>
                Fragetext ist zu lang (maximal {{validator.QUESTION_MAX_LENGTH}} Zeichen)
            </li>
            <!-- Answers errors-->
            <ng-container *ngFor="let answer of answersErrors; let i = index;">
                <li class="micromate-error-box-message-list-element"
                    *ngIf="answer.answerText.includes('required')" i18n>
                    Antwort {{i + 1}}: Antworttext ist erforderlich
                </li>
                <li class="micromate-error-box-message-list-element"
                    *ngIf="answer.answerText.includes('maxLength')" i18n>
                    Antwort {{i + 1}}: Antworttext ist zu lang (maximal {{validator.ANSWER_MAX_LENGTH}} Zeichen)
                </li>
                <li class="micromate-error-box-message-list-element"
                    *ngIf="answer.explanationText !== undefined &&  answer.explanationText.includes('maxLength')" i18n>
                    Antwort {{i + 1}}: Erklärungstext ist zu lang (maximal {{validator.EXPLANATION_MAX_LENGTH}} Zeichen)
                </li>
            </ng-container>
            <!-- Explanation errors-->
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.propertyErrors.explanation !== undefined
                && activeInvalidQuestion.propertyErrors.explanation.includes('maxLength')" i18n>
                Erklärungtext ist zu lang (maximal {{validator.EXPLANATION_MAX_LENGTH}} Zeichen)
            </li>
            <!-- Source URL errors-->
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.propertyErrors.sourceUrl !== undefined
                && activeInvalidQuestion.propertyErrors.sourceUrl.includes('maxLength')" i18n>
                Quellen-URL ist zu lang (maximal {{validator.SOURCE_URL_MAX_LENGTH}} Zeichen)
            </li>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.propertyErrors.sourceUrl !== undefined
                && activeInvalidQuestion.propertyErrors.sourceUrl.includes('format')" i18n>
                Quellen-URL hat ein falsches Linkformat
            </li>
            <!-- Source Label errors-->
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.propertyErrors.sourceLabel !== undefined
                && activeInvalidQuestion.propertyErrors.sourceLabel.includes('maxLength')" i18n>
                Quellenbezeichnung ist zu lang (maximal {{validator.SOURCE_LABEL_MAX_LENGTH}} Zeichen)
            </li>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.propertyErrors.sourceLabel !== undefined
                && activeInvalidQuestion.propertyErrors.sourceLabel.includes('missingSourceUrl')" i18n>
                Quellenbezeichnung muss zusammen mit einer Quellen-URL angegeben werden
            </li>
            <!-- Tag errors-->
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.propertyErrors.tags.includes('maxLength')" i18n>
                Tags sind zu lang (maximal {{validator.TAG_MAX_LENGTH}} Zeichen)
            </li>
        </ng-container>
        <!-- Logic Errors-->
        <ng-container>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.logicErrors.includes('minNumberOfAnswers')" i18n>
                Frage enthält nicht genügend Antworten (mindestens {{validator.MIN_ANSWER_NUMBER}} Antworten)
            </li>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.logicErrors.includes('maxNumberOfAnswers')" i18n>
                Frage enthält zu viele Antworten (maximal {{validator.MAX_ANSWER_NUMBER}} Antworten)
            </li>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.logicErrors.includes('correctAnswers')" i18n>
                Mindestens eine Antwort muss als korrekt markiert sein
            </li>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.logicErrors.includes('correctAnswer')" i18n>
                Eine Antwort muss als korrekt markiert sein
            </li>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.logicErrors.includes('oneCorrectAnswer')" i18n>
                Maximal eine Antwort darf als korrekt markiert sein
            </li>
            <li class="micromate-error-box-message-list-element"
                *ngIf="activeInvalidQuestion.logicErrors.includes('duplicateAnswers')" i18n>
                Dieselbe Antwort ist mehrfach vorhanden
            </li>
        </ng-container>
    </ul>
</div>
