<div class="micromate-card">
    <div class="micromate-card-header">
        <h1>API History</h1>
    </div>

    <div *ngIf="listPagination !== undefined">
        <div class="micromate-api-history-info">
            <div class="micromate-table-checkbox">
                <input id="checkbox" name="checkbox" type="checkbox" [(ngModel)]="errorMessages"
                       (ngModelChange)='onOnlyErrorMessagesChanged()'>
                <label for="checkbox" i18n>Nur fehlgeschlagene Request</label>
            </div>
            <div class="micromate-data-amount" *ngIf="numberOfCalls !== undefined" i18n>
                {{numberOfCalls}} Requests (letzte 24 h)
            </div>
        </div>

        <div class="micromate-table">
            <div class="table-container table-container-header">
                <ng-container>
                    <div class="row-header" i18n>Request Zeitpunkt</div>
                    <div class="row-header" i18n>URL</div>
                    <div class="row-header" i18n>HTTP-Methode</div>
                    <div class="row-header" i18n>HTTP-Status</div>
                </ng-container>
            </div>
            <div class="table-container-data" #tableContentRef>
                <div class="table-container table-content" *ngFor="let api of apis">
                    <div class="row row-name">
                        <div class="row-sub micromate-table-link row-title" [routerLink]="[api._id]">
                            {{api.timestamp | date:'M.d.yy, HH:mm:ss.SSS a'}}
                        </div>
                    </div>
                    <div class="row row-only-web">{{api.url}}</div>
                    <div class="row row-only-web">{{api.httpMethod}}</div>
                    <div class="row group-info">
                        <div class="micromate-api-history-status"
                             [class.micromate-api-history-success]="api?.httpState >= 200 && api?.httpState < 400"
                             [class.micromate-api-history-error]="!(api?.httpState >= 200 && api?.httpState < 400)">
                            {{api?.httpState !== undefined && api.httpState > 0 ? api.httpState : '-'}}</div>
                    </div>
                </div>
            </div>
        </div>
        <app-table-pagination [paginationParams]="paginationParams"
                              [totalDataAmount]="listPagination.totalDataAmount"
                              [navigateUrl]="navigationUrl"></app-table-pagination>
    </div>
</div>
