<div class="mm-pagination">
    <div class="mm-pagination-page-amount">
        <div class="mm-pagination-page-amount-text" i18n>
            Elemente pro Seite:
        </div>
        <select [(ngModel)]="paginationParams.pageAmount" (ngModelChange)="redirectWithPaginationParameter(1, $event)"
                class="mm-single-selection"
                [class.mm-single-selection-with-value]="paginationParams.pageAmount.length > 0">
            <option *ngFor="let pageAmountOption of pageAmountOptions"
                    [ngValue]="pageAmountOption.id">{{pageAmountOption.name}}</option>
        </select>
    </div>
    <app-pagination [pageNumber]="paginationParams.pageNumber"
                    [numberOfPages]="numberOfPages"
                    [title]="'Seite'"
                    (redirectToNextEvent)="redirectWithPaginationParameter($event, paginationParams.pageAmount)"></app-pagination>

</div>
