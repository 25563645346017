import {Injectable} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {shareReplay, switchMap} from 'rxjs/operators';
import {LearnerResource} from '../rest/learner-resource.service';
import {Learner} from '../api-types/learner';

@Injectable({
    providedIn: 'root'
})
export class LearnerInfoAdminService {

    constructor(private router: Router, private learnerRestService: LearnerResource) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.params.learnerId !== undefined) {
                    const learnerId = path.snapshot.params.learnerId as string;
                    this.selectedLearnerId.next(learnerId);
                }
            }
        });
    }

    public selectedLearnerId = new BehaviorSubject<string>(undefined);
    public selectedLearnerId$ = this.selectedLearnerId.asObservable();

    public selectedLearner$: Observable<Learner> = this.selectedLearnerId$.pipe(
        switchMap((selectedOrganization: string) => {
            if (selectedOrganization !== undefined) {
                return this.learnerRestService.getLearner(selectedOrganization);
            }
            return of(undefined);
        }),
        shareReplay(1)
    );
}
