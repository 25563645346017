import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {LearncenterService} from '../../../core/learncenter/learncenter.service';
import {LearningGroupResource} from '../../../core/rest/learning-group-resource.service';
import {Popup} from '../../../components/popup/Popup';
import {PopupService} from 'src/app/components/popup/popup.service';
import {FormCustomValidation} from '../../../core/form-custom-validation';

@Component({
    selector: 'app-new-learning-group-popup',
    templateUrl: './new-learning-group-popup.component.html',
    styleUrls: ['./new-learning-group-popup.component.scss']
})
export class NewLearningGroupPopupComponent implements Popup, OnInit {
    @Input()
    public data: undefined;

    public saveButtonClick = false;
    public cancelButtonClick = false;
    public nameInvalid = false;

    public formData = new FormGroup<{ name: FormControl<string>; }>({
        name: new FormControl<string>('', [FormCustomValidation.trimmedRequired, FormCustomValidation.trimmedMaxLengthValidator(50)]),
    });

    constructor(private learningGroupService: LearncenterService,
                private groupRestService: LearningGroupResource,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.formData.controls['name'].valueChanges.subscribe(() => {
            this.cancelButtonClick = false;
            this.saveButtonClick = false;
            this.nameInvalid = false;
        });
    }

    public createNewGroup: () => Promise<void> = async () => {
        this.nameInvalid = false;
        return new Promise(resolve => {
            const name = this.formData.controls.name.getRawValue().trim();
            this.groupRestService.createNewGroup(name).subscribe(() => {
                this.learningGroupService.updateGroupTable();
                this.popupService.close();
                this.formData.controls['name'].setValue('');
                resolve();
            }, () => {
                this.nameInvalid = true;
                resolve();
            });
        });
    };

    public cancel(): void {
        this.popupService.close();
    }
}
