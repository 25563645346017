import {Component, OnInit} from '@angular/core';
import {TimestampSentenceCreator} from '../../core/analytics/TimestampSentenceCreator';
import {AdminAnalyticsOverview} from '../../core/rest/analytics-rest/AdminAnalyticsOverview';
import {AnalyticsAdminRestService} from '../../core/rest/analytics-rest/analytics-admin-rest.service';

@Component({
    selector: 'app-analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
    public analyticsOverview: AdminAnalyticsOverview;
    public currentDate: Date;

    constructor(private analyticsAdminRestService: AnalyticsAdminRestService) {
    }

    public ngOnInit(): void {
        this.currentDate = new Date();
        this.analyticsAdminRestService.getAdminAnalyticsOverview().subscribe((analyticsOverview: AdminAnalyticsOverview) => this.analyticsOverview = analyticsOverview);
    }

    public getTimestampSentence(date: string): string {
        return new TimestampSentenceCreator().create(date, this.currentDate);
    }

    public formatPoints(points: number): string {
        return points.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    }

    public formatProgressPoints(points: number): string {
        return `${points < 0 ? '' : '+'}${this.formatPoints(points)}`;
    }

    public isProgressNegative(points: number): boolean {
        return points < 0;
    }
}
