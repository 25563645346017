<div class="micromate-language-filter-wrapper" *ngIf="languages !== undefined && languages.length > 1">
    <div *ngFor="let language of languages; let i = index"
         class="micromate-language-filter-box"
         [class.micromate-language-disabled]="disabled"
         [class.micromate-language-main]="language === mainLanguage"
         [class.micromate-language-filter-active]="currentLanguage !== undefined && language === currentLanguage"
         [class.micromate-language-filter-missing-translation]="translationNotExist((editorQuizService.activeQuiz$ | async), language)"
         (click)="changeLanguage(language)">
        {{multilanguageService.createShortName(language)}}
    </div>
</div>
