import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LearncenterService {
    public addedNewLearnGroup: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public addedNewLearnGroup$ = this.addedNewLearnGroup.asObservable();

    public updateGroupTable(): void {
        this.addedNewLearnGroup.next(true);
    }

    public updatedGroupTable(): void {
        this.addedNewLearnGroup.next(false);
    }

}
