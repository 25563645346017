import {Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SearchInputComponent),
            multi: true,
        }
    ]
})
export class SearchInputComponent implements ControlValueAccessor {
    @Input()
    public disabled: boolean = false;

    @Input()
    public elementsCount: number;

    @Output()
    public searchCleared = new EventEmitter<void>();

    public value: string = '';

    public onTouched: () => void;
    public onChange: (value: string) => void;

    public writeValue(value: string): void {
        this.value = value;
    }

    public registerOnChange(fn: (param: string) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public handleInput(event: Event): void {
        if (!this.disabled) {
            const input = event.target as HTMLInputElement;
            this.value = input.value;
            this.onChange(this.value);
            this.onTouched();
        }
    }

    public clearSearch(): void {
        if (!this.disabled) {
            this.value = '';
            this.searchCleared.emit();
        }
    }

    public hasValue(): boolean {
        // eslint-disable-next-line no-null/no-null
        return this.value !== null && this.value !== undefined && this.value.length > 0;
    }
}
