import {Component, Input} from '@angular/core';
import {PopupService} from '../../../components/popup/popup.service';
import {Popup} from '../../../components/popup/Popup';
import {DeleteLearningPackagePopupData} from './DeleteLearningPackagePopupData';
import {EditorResource} from '../../../core/rest/editor-resource.service';
import {HttpErrorResponse} from '@angular/common/http';
import {LearningPackageDeleteError} from '../../../core/api-types/learningPackageDeleteError';
import {DeleteLearningPackagePopupResult} from './DeleteLearningPackagePopupResult';

@Component({
    selector: 'app-delete-learning-package-error-popup',
    templateUrl: './delete-learning-package-error-popup.component.html',
    styleUrls: ['./delete-learning-package-error-popup.component.scss']
})
export class DeleteLearningPackageErrorPopupComponent implements Popup<DeleteLearningPackagePopupData> {
    @Input()
    public data: DeleteLearningPackagePopupData;

    public errorOccurred: boolean = false;

    public deleteError: LearningPackageDeleteError;

    constructor(private popupService: PopupService,
                private editorRestService: EditorResource) {
    }

    public cancel(): void {
        this.popupService.close<DeleteLearningPackagePopupResult>();
    }

    public confirm(): void {
        if (!this.errorOccurred) {
            this.editorRestService.deleteLearningPackage(this.data.mainLearningPackageId).subscribe(() => {
                this.popupService.close<DeleteLearningPackagePopupResult>('deleted');
            }, (error: HttpErrorResponse) => {
                this.errorOccurred = true;
                this.deleteError = error.error as LearningPackageDeleteError;
            });
        }
    }

}
