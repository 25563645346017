import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
    @Input()
    public pageNumber: number;

    @Input()
    public numberOfPages: number;

    @Input()
    public testPosition: 'start' | 'middle' = 'start';

    @Input()
    public title?: string;

    @Output()
    public redirectToNextEvent = new EventEmitter<number>;

    public redirectToNext(next: number): void {
        this.redirectToNextEvent.emit(next);
    }

}
