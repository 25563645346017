import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-switch-toggle',
    templateUrl: './switch-toggle.component.html',
    styleUrls: ['./switch-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SwitchToggleComponent),
            multi: true,
        }
    ],
})

export class SwitchToggleComponent implements ControlValueAccessor {
    @Input()
    public disabled: boolean = false;

    public checked: boolean;

    public onTouched: (param: boolean) => void;
    public onChange: (param: boolean) => void;

    public writeValue(checked: boolean): void {
        this.checked = checked;
    }

    public registerOnChange(fn: (param: boolean) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: (param: boolean) => void): void {
        this.onTouched = fn;
    }

    public onModelChange(value: boolean): void {
        if (!this.disabled) {
            this.checked = value;
            this.onChange(value);
        }
    }
}
