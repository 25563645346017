import {QuizQuestionForm} from '../QuizQuestionForm';
import {QuizExplanationForm} from '../QuizExplanationForm';
import {QuizEditor} from '../QuizEditor';
import {SingleTextAnswersQuizEditor} from './SingleTextAnswersQuizEditor';
import {SelectedTag} from '../../../api-types/SelectedTag';
import {SelectedNewTag} from '../../../api-types/SelectedNewTag';

export class SingleTextChoiceQuizEditor implements QuizEditor {
    constructor(public answers: SingleTextAnswersQuizEditor[],
                public question: QuizQuestionForm,
                public selectedAvailableTags: SelectedTag[],
                public selectedNewTags: SelectedNewTag[],
                public explanation?: QuizExplanationForm,
                public sourceUrl?: string,
                public sourceLabel?: string) {
    }
}
