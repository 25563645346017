import {Component, HostListener, Input, OnInit} from '@angular/core';
import {FileHandle} from '../../core/drag-and-drop/drag-and-drop.directive';
import {DragAndDropService} from '../../core/drag-and-drop/drag-and-drop.service';
import {EditorQuestionsService} from '../../core/editor/editor-questions.service';
import {DomSanitizer} from '@angular/platform-browser';
import {QuizMedia} from '../../core/api-types/quizMedia';
import {QuizMediaEditor} from '../../core/editor/types/QuizMediaEditor';
import {AbstractControl, FormControl, ValidationErrors} from '@angular/forms';
import {MovieService} from './movie.service';
import {DefaultVideo} from './videoLinkFormats/DefaultVideo';
import {VideoLink} from './videoLinkFormats/VideoLink';
import {VideoDisplay} from './videoLinkFormats/VideoFormat';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationPopupResult} from '../../components/confirmation-popup/ConfirmationPopupResult';
import {PopupService} from '../../components/popup/popup.service';
import {Popup} from 'src/app/components/popup/Popup';
import {ImageAndMediaPopupData} from './ImageAndMediaPopupData';

@Component({
    selector: 'app-image-and-media-popup',
    templateUrl: './image-and-media-popup.component.html',
    styleUrls: ['./image-and-media-popup.component.scss'],
})
export class ImageAndMediaPopupComponent implements Popup<ImageAndMediaPopupData>, OnInit {
    @Input()
    public data: ImageAndMediaPopupData;

    public imageHovering: boolean = false;
    public imageFileDrop: boolean = false;
    public runningMediaValidation: boolean = false;
    public notSupportedMedia: boolean = false;
    public invalidWebsiteMedia: boolean = false;

    public quizMediaEditor: QuizMediaEditor;
    public media: QuizMedia;
    public medaUrlFormControl = new FormControl<string>('', [this.urlPattern]);

    public mediaChanged: boolean = false;
    public invalidMedia: boolean = false;
    public invalidUrl: boolean = false;

    public videoLink: VideoLink = DefaultVideo.createVideoLink();

    constructor(public dragAndDropService: DragAndDropService,
                public editorQuizzesService: EditorQuestionsService,
                private sanitizer: DomSanitizer,
                private videoService: MovieService,
                private editorRestService: EditorResource,
                private toastr: ToastrService,
                private popupService: PopupService) {
    }

    @HostListener('document:keydown.tab', ['$event'])
    public onKeydownHandler(event: KeyboardEvent): void {
        event.preventDefault();
    }

    public ngOnInit(): void {
        this.editorQuizzesService.imageAndMediaPopupOpen.next(true);
        this.quizMediaEditor = this.data.type === 'question' ? this.editorQuizzesService.getQuestionQuizMediaEditor() : this.editorQuizzesService.getExplanationQuizMediaEditor();
        if (this.quizMediaEditor.mediaUrl !== undefined) {
            this.validateMedia();
            this.medaUrlFormControl.setValue(this.quizMediaEditor.mediaUrl);
        }
        this.medaUrlFormControl.valueChanges.pipe(
            debounceTime(1000),
            distinctUntilChanged())
            .subscribe((media: string) => {
                this.mediaChanged = true;
                this.notSupportedMedia = false;
                this.invalidWebsiteMedia = false;
                this.invalidUrl = false;
                // eslint-disable-next-line no-null/no-null,@typescript-eslint/strict-boolean-expressions
                if (!this.medaUrlFormControl.errors && media !== null && media.trim() !== '') {
                    this.runningMediaValidation = true;
                    this.validateVideoElement(media.trim());
                }
            });
    }

    private validateMedia(): void {
        if (this.quizMediaEditor.type === 'website') {
            this.editorRestService.validateWebsiteMedia(this.quizMediaEditor.mediaUrl).subscribe(validationResult => this.invalidMedia = !validationResult.isValid);
        } else {
            this.videoLink = this.videoService.getVideoType(this.quizMediaEditor.mediaUrl);
            if (this.videoLink.display === VideoDisplay.VIDEO) {
                const videoElement = document.createElement('video');
                // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
                videoElement.onerror = (() => {
                    if (videoElement.error.code === 4) {
                        this.invalidMedia = true;
                    }
                    videoElement.remove();
                });
                videoElement.src = this.quizMediaEditor.mediaUrl;
            }
        }
    }

    private validateVideoElement(media: string): void {
        this.videoLink = this.videoService.getVideoType(media);
        const videoLink = this.videoService.createVideoLink(this.videoLink.format, media);

        if (this.videoLink.display === VideoDisplay.IFRAME) {
            this.setIframeVideoLink(videoLink);
        }

        if (this.videoLink.display === VideoDisplay.VIDEO) {
            this.setVideoLink(videoLink);
        }
    }

    private setVideoLink(media: string): void {
        const videoElement = document.createElement('video');
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        videoElement.onerror = (() => {
            if (videoElement.error.code === 4) {
                this.validateWebsiteElement(media);
            }
            videoElement.remove();
        });

        videoElement.addEventListener('loadedmetadata', () => {
            this.runningMediaValidation = false;
            this.quizMediaEditor.mediaUrl = media;

            if (videoElement.videoWidth > 0) {
                this.runningMediaValidation = false;
                this.quizMediaEditor.mediaUrl = media;
                this.quizMediaEditor.type = 'movie';
            } else {
                this.validateAudioElement(media);
                videoElement.remove();
            }
        });
        videoElement.src = media;
    }

    private setIframeVideoLink(mediaUrl: string): void {
        if (mediaUrl !== undefined) {
            this.runningMediaValidation = false;
            this.quizMediaEditor.mediaUrl = mediaUrl;
            this.quizMediaEditor.type = 'movie';
        } else {
            this.runningMediaValidation = false;
            this.invalidUrl = true;
        }
    }

    private validateAudioElement(media: string): void {
        const audioElement = document.createElement('audio');
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        audioElement.onerror = (() => {
            this.runningMediaValidation = false;
            if (audioElement.error.code === 4) {
                this.notSupportedMedia = true;
            }
            audioElement.remove();
        });
        audioElement.addEventListener('loadedmetadata', () => {
            this.runningMediaValidation = false;
            this.quizMediaEditor.mediaUrl = media;
            this.quizMediaEditor.type = 'audio';
            audioElement.remove();
        });
        audioElement.src = media;
    }

    private validateWebsiteElement(media: string): void {
        this.editorRestService.validateWebsiteMedia(media).subscribe(validationResult => {
            if (validationResult.isValid) {
                this.quizMediaEditor.mediaUrl = media;
                this.quizMediaEditor.type = 'website';
            } else {
                this.invalidWebsiteMedia = true;
            }
            this.runningMediaValidation = false;
        });
    }

    public async filesDropped(files: FileHandle[]): Promise<void> {
        const file = files[0];
        const fileAllowed = this.dragAndDropService.isFileAllowed(file.file);
        if (!fileAllowed.error) {
            this.quizMediaEditor.imageFile = file;
            this.imageFileDrop = true;
            this.mediaChanged = true;
            if (this.quizMediaEditor.type === undefined) {
                this.quizMediaEditor.type = 'image';
            }
        } else {
            for (const errorText of fileAllowed.text) {
                this.toastr.error(errorText);
            }
        }
    }

    public async uploadFile(event: HTMLInputElement): Promise<void> {
        const file = event.files[0];
        const fileAllowed = this.dragAndDropService.isFileAllowed(file);
        if (!fileAllowed.error) {
            const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
            this.quizMediaEditor.imageFile = {file, url};
            this.mediaChanged = true;
            if (this.quizMediaEditor.type === undefined) {
                this.quizMediaEditor.type = 'image';
            }
        } else {
            for (const errorText of fileAllowed.text) {
                this.toastr.error(errorText);
            }
        }
    }

    public async removeImage(): Promise<void> {
        this.quizMediaEditor.imageFile = undefined;
        this.quizMediaEditor.imageUrl = undefined;
        this.mediaChanged = true;
        if (this.quizMediaEditor.type === 'image') {
            this.quizMediaEditor.type = undefined;
        }
    }

    public async removeMedia(): Promise<void> {
        this.medaUrlFormControl.setValue('');
        this.invalidMedia = false;
        this.invalidUrl = false;
        this.runningMediaValidation = false;
        this.mediaChanged = true;
        this.notSupportedMedia = false;
        this.invalidWebsiteMedia = false;
        this.quizMediaEditor.mediaUrl = undefined;
        this.quizMediaEditor.type = this.quizMediaEditor.imageUrl !== undefined || this.quizMediaEditor.imageFile !== undefined ? 'image' : undefined;
    }


    public discardMedia(): void {
        this.editorQuizzesService.imageAndMediaPopupOpen.next(false);
        this.popupService.close();
    }

    public saveMedia: () => Promise<void> = async () => {
        return new Promise(resolve => {
            if (this.data.type === 'question') {
                this.editorQuizzesService.setQuestionQuizMediaEditor(this.quizMediaEditor);
                this.editorQuizzesService.quizQuestionMediaEditorChanged.next(true);
            } else {
                this.editorQuizzesService.setExplanationQuizMediaEditor(this.quizMediaEditor);
                this.editorQuizzesService.quizExplanationMediaEditorChanged.next(true);
            }
            this.editorQuizzesService.imageAndMediaPopupOpen.next(false);
            this.popupService.close();
            resolve();
        });
    };

    public cancel(): void {
        this.popupService.close<ConfirmationPopupResult>('Cancel');
    }

    private urlPattern(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp(/^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)*\s*$/);

        // eslint-disable-next-line no-null/no-null
        if (control.value !== null) {
            const value = (control.value as string).trim();
            if (value.length > 0 && !regex.test(value)) {
                return {pattern: false};
            }
        }
        // eslint-disable-next-line no-null/no-null
        return null;
    }
}
