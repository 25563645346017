<div class="micromate-card" *ngIf="organization !== undefined">
    <div class="micromate-card-header">
        <h1 i18n>Lernende</h1>
        <app-button type="primary"
                    [icon]="'plus-light'"
                    (click)="openAddLearnerForm()" i18n>Lernende hinzufügen
        </app-button>
    </div>
    <div *ngIf="listPagination !== undefined">
        <div class="mm-table-search-and-activation-filter">
            <app-table-search class="mm-table-search"
                              [class.mm-table-seatch-hide]="learners === undefined"
                              [paginationParams]="paginationParams"
                              [navigateUrl]="navigationUrl"
                              [totalDataAmount]="listPagination?.totalDataAmount"></app-table-search>
            <div class="mm-activation-filter">
                <div class="mm-activation-filter-button mm-activation-filter-button-active-value"
                     [class.mm-activation-filter-button-active]="paginationParams.organizationStatusFilter === 'active'"
                     (click)="changeOrganizationStatusFilter('active')" i18n>aktive
                </div>
                <div class="mm-activation-filter-button mm-activation-filter-button-inactive-value"
                     [class.mm-activation-filter-button-active]="paginationParams.organizationStatusFilter === 'deactivated'"
                     (click)="changeOrganizationStatusFilter('deactivated')" i18n>deaktivierte
                </div>
            </div>
        </div>

        <div class="micromate-table" *ngIf="learners !== undefined">
            <div class="table-container table-container-header">
                <ng-container>
                    <div class="row-header" i18n>Name</div>
                    <div class="row-header" i18n>Benutzer</div>
                    <div class="row-header" i18n>Sprache</div>
                    <div class="row-header" i18n>Status</div>
                    <div class="row-header" i18n></div>
                </ng-container>
            </div>

            <div class="table-container-data" *ngIf="learners.length > 0; else emptyTable" #tableContentRef>
                <div class="table-container table-content" *ngFor="let member of learners"
                     [class.table-content-gray]="member.organizations.status === 'deactivated'">
                    <div class="row row-name">
                        <div class="row-sub micromate-table-link">
                            {{member?.displayName ? member.displayName : '-'}}
                        </div>
                        <div class="row-only-mobile-email">
                            {{member?.identifier ? member.identifier : '-'}}
                        </div>
                    </div>
                    <div class="row row-only-web">{{member?.identifier ? member.identifier : '-'}}</div>
                    <div class="row row-only-web">{{member.languages}}</div>
                    <div class="row row-status">
                        <app-icon class="micromate-editor-table-icon"
                                  [title]="getStatusTitle(member.organizations.status)"
                                  [class.micromate-learner-status-active]="member.organizations.status === 'active'"
                                  [class.micromate-learner-status-deactivated]="member.organizations.status === 'deactivated'"
                                  icon='dot-circle-regular'></app-icon>
                    </div>
                    <div class="row form-actions">
                        <app-button type="transparent" icon="info-circle-light"
                                    [routerLink]="[member._id]">
                        </app-button>
                        <app-button
                            *ngIf="member.organizations.status !== 'deactivated'"
                            icon='times-light' type="transparent"
                            (click)="openDeactivateLearnerPopup(member._id)"></app-button>
                    </div>
                </div>
            </div>
            <ng-template #emptyTable>
                <div class="table-empty-filter-results"
                     *ngIf="paginationParams.organizationStatusFilter === 'active'" i18n>
                    Keine Lernenden vorhanden
                </div>
                <div class="table-empty-filter-results"
                     *ngIf="paginationParams.organizationStatusFilter === 'deactivated'" i18n>
                    Keine deaktivierten Lernenden vorhanden
                </div>
            </ng-template>
        </div>
        <app-table-pagination *ngIf="learners !== undefined"
                              [paginationParams]="paginationParams"
                              [totalDataAmount]="listPagination.totalDataAmount"
                              [navigateUrl]="navigationUrl"></app-table-pagination>
    </div>
</div>
