import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DragAndDropService {
    private dragOnPage = new BehaviorSubject<boolean>(false);
    public dragOnPage$ = this.dragOnPage.asObservable();
    public dragCounter = new BehaviorSubject<number>(0);

    public set(status: boolean): void {
        this.dragOnPage.next(status);
    }

    public get(): boolean {
        return this.dragOnPage.getValue();
    }

    public setDragCounter(status: number): void {
        this.dragCounter.next(status);
    }

    public getDragCounter(): number {
        return this.dragCounter.getValue();
    }

    public isFileAllowed(file: File): { error: boolean; text: string[]; } {
        const errors: string[] = this.validateFile(file);
        return {error: errors.length > 0, text: errors};
    }

    public validateFile(file: File): string[] {
        if (file === undefined) {
            return ['Diese Datei kann nicht hinzugefügt werden.'];
        }
        const errors: string[] = [];
        if (!this.isFileSizeAllowed(file)) {
            if (file.type === 'image/gif') {
                errors.push('Die Datei ist zu gross. Bitte verwende ein GIF mit maximal 1 MB.');
            } else {
                errors.push('Die Datei ist zu gross. Bitte verwende ein Bild mit maximal 4 MB.');
            }
        }
        if (!this.isFileFormatAllowed(file)) {
            errors.push('Nicht unterstütztes Dateiformat. Bitte verwende eines der Formate jpg, jpeg, png oder gif.');
        }
        return errors;
    }

    private isFileFormatAllowed(file: File): boolean {
        let isFileAllowed = false;
        const allowedFiles = ['.jpg', '.jpeg', '.png', '.gif'];
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(file.name);
        // eslint-disable-next-line no-null/no-null
        if (undefined !== extension && null !== extension) {
            for (const ext of allowedFiles) {
                if (ext === extension[0].toLowerCase()) {
                    isFileAllowed = true;
                }
            }
        }
        return isFileAllowed;
    }

    private isFileSizeAllowed(file: File): boolean {
        if (file.type === 'image/gif') {
            return file.size < 1000000;
        }
        return file.size < 4000000;
    }

}
