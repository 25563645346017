import {Component, OnDestroy, OnInit} from '@angular/core';
import {ImportWizardService} from '../import-wizard.service';
import {ImportableQuestion} from '../../../../core/editor/types/QuestionConversion/ImportableQuestion';
import {Answer} from '../../../../core/api-types/answer';
import {EditorQuestionService} from '../../../../core/editor/editor-question.service';
import {Subscription} from 'rxjs';
import {MediaData} from './question-preview/components/question-media-preview/MediaData';
import {QuizQuestion} from '../../../../core/editor/types/QuizQuestion';
import {take} from 'rxjs/operators';
import {EditorLearningPackageService} from '../../../../core/editor/editor-learning-package.service';
import {SelectedNewTag} from '../../../../core/api-types/SelectedNewTag';
import {QuestionType} from '../../../../core/editor/question.type';

@Component({
    selector: 'app-question-preview-page',
    templateUrl: './question-preview-page.component.html',
    styleUrls: ['./question-preview-page.component.scss']
})
export class QuestionPreviewPageComponent implements OnInit, OnDestroy {
    public currentQuestionIndex: number;
    public allQuestions: ImportableQuestion[] = [];
    public allQuestionsForDefaultLanguage: ImportableQuestion[] = [];
    public questionPreview: ImportableQuestion;
    public defaultLanguageQuestionPreview: ImportableQuestion;
    public questionTranslations: ImportableQuestion[];
    public questionLanguages: string[];
    public showLanguageSwitcher: boolean = false;
    public mainLocale: string;
    public selectedNewTag: SelectedNewTag[] = [];
    public selectedAvailableTags = [];
    public availableTags = [];
    public quizTypeData: QuestionType;

    private selectedQuestionIndex$ = new Subscription();

    constructor(private importWizardService: ImportWizardService,
                private editorQuestionService: EditorQuestionService,
                private editorLearningPackageService: EditorLearningPackageService) {
    }

    public ngOnInit(): void {
        this.allQuestions = this.importWizardService.getAllQuestions();
        this.allQuestionsForDefaultLanguage = this.importWizardService.getAllForDefaultLocale();
        this.selectedQuestionIndex$ = this.importWizardService.selectedQuestionIndex$.subscribe(selectedQuestionIndex => {
            this.currentQuestionIndex = selectedQuestionIndex;
            this.defaultLanguageQuestionPreview = this.allQuestionsForDefaultLanguage[this.currentQuestionIndex];
            this.editorLearningPackageService.defaultLocaleSubject$.pipe(take(1)).subscribe(defaultLocale => {
                this.mainLocale = defaultLocale;
                if (this.defaultLanguageQuestionPreview !== undefined) {
                    this.updateQuestionInPreview();
                }
            });
        });
    }

    public changeLanguage(language: string): void {
        this.questionPreview = this.questionTranslations.find(x => x.language === language);
    }

    public ngOnDestroy(): void {
        this.selectedQuestionIndex$.unsubscribe();
    }

    public getPageTitle(): string {
        switch (this.importWizardService.importMethod) {
            case 'csv-file':
                return 'Import aus CSV-Datei';
            case 'ressource':
                return 'Import aus Ressource';
            case 'learning-package':
                return 'Import aus Lernpaket';
            default:
                return '';
        }
    }

    public getQuestionPreviewAnswer(index: number): Answer {
        return this.questionPreview.answers[index] as Answer;
    }

    public navigateToSummary(): void {
        this.importWizardService.selectQuestionForPreview(undefined);
        this.importWizardService.currentStep = 'questions-summary';
    }

    public nextQuestion(): void {
        this.currentQuestionIndex = Math.min(this.allQuestionsForDefaultLanguage.length - 1, ++this.currentQuestionIndex);
        this.switchQuestion();
    }

    public previousQuestion(): void {
        this.currentQuestionIndex = Math.max(0, --this.currentQuestionIndex);
        this.switchQuestion();
    }

    public toggleQuestionActiveStatus(): void {
        this.importWizardService.toggleQuestionActiveStatus(this.allQuestionsForDefaultLanguage[this.currentQuestionIndex]);
    }

    public hasQuestionMedia(question: QuizQuestion): boolean {
        return question.mediaType !== undefined && (question.mediaUrl !== undefined || question.imageUrl !== undefined);
    }

    public getMediaData(question: QuizQuestion): MediaData {
        return new MediaData(question.mediaType, question.mediaUrl, question.imageUrl);
    }

    private switchQuestion(): void {
        this.defaultLanguageQuestionPreview = this.allQuestionsForDefaultLanguage[this.currentQuestionIndex];
        this.updateQuestionInPreview();
    }

    private updateQuestionInPreview(): void {
        if (this.defaultLanguageQuestionPreview.language === undefined) {
            this.questionPreview = this.defaultLanguageQuestionPreview;
            this.quizTypeData = this.editorQuestionService.quizTypes.find(quizType => quizType.type === this.questionPreview.quizType);
        } else {
            this.questionTranslations = this.allQuestions.filter(question => question.questionNumber === this.defaultLanguageQuestionPreview.questionNumber);
            this.showLanguageSwitcher = this.questionTranslations.length > 1;
            this.questionPreview = this.questionTranslations.find(x => x.language === this.mainLocale);
            this.quizTypeData = this.editorQuestionService.quizTypes.find(quizType => quizType.type === this.questionPreview.quizType);
            this.questionLanguages = this.questionTranslations.map(translation => translation.language);
            this.selectedNewTag = this.questionPreview.tags !== undefined ? this.questionPreview.tags.map(tag => new SelectedNewTag(tag)) : [];
        }
    }
}
