import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {LearningGroupResource} from '../../core/rest/learning-group-resource.service';
import {ToastrService} from 'ngx-toastr';
import {GroupService} from '../../core/group.service';
import {LearningPackageService} from '../../core/learning-package.service';
import {GroupLearningPackageSettingService} from './group-learning-package-setting.service';
import {firstValueFrom, Subscription} from 'rxjs';
import {GroupActivationTag} from '../../core/api-types/GroupActivationTag';
import {GroupLearningPackageEditSetting} from '../../core/api-types/groupLearningPackageEditSetting';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'app-group-learning-package-setting',
    templateUrl: './group-learning-package-setting.component.html',
    styleUrls: ['./group-learning-package-setting.component.scss']
})
export class GroupLearningPackageSettingComponent implements OnInit, OnDestroy {
    public learningPackageGroupSettings: GroupLearningPackageEditSetting;
    private activeGroupId: string;
    private selectedLearningPackageId: string;
    private selectedGroupId$ = new Subscription();
    private selectedLearningPackageId$ = new Subscription();

    public manualActivationTags: GroupActivationTag[];
    public changedManualActivationTags: GroupActivationTag[];

    public manualActivationValueChanged: boolean = false;
    public initialManualActivation: boolean;

    constructor(private groupRestService: LearningGroupResource,
                private toastr: ToastrService,
                public groupService: GroupService,
                public groupLearningPackageSettingService: GroupLearningPackageSettingService,
                private learningPackageService: LearningPackageService) {
    }

    public ngOnDestroy(): void {
        this.selectedGroupId$.unsubscribe();
        this.selectedLearningPackageId$.unsubscribe();
    }

    @HostListener('window:beforeunload', ['$event'])
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    unloadHandler($event) {
        if (!this.groupLearningPackageSettingService.groupLearningPackageSettingSaved.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return $event.returnValue = 'Your changes will not be saved';
        }
    }

    public ngOnInit(): void {
        this.selectedGroupId$ = this.groupService.selectedGroupId$.subscribe(selectedGroupId => {
            if (selectedGroupId !== undefined) {
                this.activeGroupId = selectedGroupId;
                this.selectedLearningPackageId$ = this.learningPackageService.selectedLearningPackageId$.subscribe(selectedLearningPackageId => {
                    if (selectedLearningPackageId !== undefined) {
                        this.selectedLearningPackageId = selectedLearningPackageId;
                        this.groupRestService.getLearningPackageSettings(this.activeGroupId, this.selectedLearningPackageId).subscribe(learningPackageSettings => {
                            this.learningPackageGroupSettings = new GroupLearningPackageEditSetting(learningPackageSettings.id, learningPackageSettings.name, learningPackageSettings.learningGoal, learningPackageSettings.manualActivation);
                            this.initialManualActivation = this.learningPackageGroupSettings.manualActivation;
                        });
                        this.groupLearningPackageSettingService.groupLearningPackageSettingSaved$.pipe(filter(value => value)).subscribe(() => {
                            this.groupRestService.getLearningPackageActivationTags(this.activeGroupId, this.selectedLearningPackageId).subscribe(tags => this.manualActivationTags = tags);
                            if (this.learningPackageGroupSettings !== undefined) {
                                this.initialManualActivation = this.learningPackageGroupSettings.manualActivation;
                            }
                        });
                    }
                });
            }
        });
    }

    public saveChanges: () => Promise<void> = async () => {
        try {
            await firstValueFrom(this.groupRestService.updateLearningPackageSettings(this.activeGroupId, this.selectedLearningPackageId, this.learningPackageGroupSettings));
        } catch (e) {
            this.toastr.error($localize`Könnte nicht gespeichert werden`);
            return;
        }

        this.toastr.success($localize`Lernpaketeinstellungen wurden gespeichert`);
        this.groupLearningPackageSettingService.setGroupLearningPackageSettingSavedStatus(true);
        this.manualActivationValueChanged = false;
        this.groupLearningPackageSettingService.setSetting(undefined, undefined);
    };

    public changedLearningGoal(learningGoal: number): void {
        this.learningPackageGroupSettings.learningGoal = learningGoal;
        this.setSettingAndSetStatus();
    }

    public manualActivationChanged(event: { manualActivation: boolean; changedManualActivationTags: GroupActivationTag[]; }): void {
        if (event.manualActivation !== this.learningPackageGroupSettings.manualActivation) {
            this.manualActivationValueChanged = true;
        }
        this.changedManualActivationTags = event.changedManualActivationTags;
        this.learningPackageGroupSettings.manualActivation = event.manualActivation;
        this.learningPackageGroupSettings.groupActivationTags = this.changedManualActivationTags;
        this.setSettingAndSetStatus();
    }

    private setSettingAndSetStatus(): void {
        this.groupLearningPackageSettingService.setGroupLearningPackageSettingSavedStatus(false);
        this.groupLearningPackageSettingService.setSetting(this.activeGroupId, this.learningPackageGroupSettings);
    }
}
