import {Component, HostListener, OnInit} from '@angular/core';
import {OrganizationAdminManager} from '../../core/api-types/organizationManager';
import {OrganizationAdminPanelResource} from '../../core/rest/organization-admin/organization-admin-panel-resource.service';
import {OrganizationActiveLearner} from '../../core/api-types/organizationActiveLearner';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {AuthService} from '../../core/authentication/auth.service';
import {ToastrService} from 'ngx-toastr';
import {OrganizationMangerService} from './organization-manger.service';

@Component({
    selector: 'app-organization-manager',
    templateUrl: './organization-manager.component.html',
    styleUrls: ['./organization-manager.component.scss']
})
export class OrganizationManagerComponent implements OnInit {
    public managers: OrganizationAdminManager[];
    public allLearners: OrganizationActiveLearner[];
    public availableNewLearners: OrganizationActiveLearner[];
    public selectionOfLearnerOpen: boolean = false;
    public newManagerDropdownSettings: IDropdownSettings = {
        singleSelection: true,
        idField: 'iamId',
        textField: 'displayName',
        enableCheckAll: false,
        allowSearchFilter: true,
        searchPlaceholderText: 'Manager suchen',
        noDataAvailablePlaceholderText: 'Keine Manager verfügbar',
        closeDropDownOnSelection: true,
        maxHeight: 100
    };

    public emptyRole: boolean = false;
    public missingAdmin: boolean = false;
    public selfAdminUnselected: boolean = false;
    public authenticatedUserIamId: string;

    constructor(public organizationMangerService: OrganizationMangerService,
                private organizationAdminPanelRestService: OrganizationAdminPanelResource,
                private authService: AuthService,
                private toastr: ToastrService) {
    }

    @HostListener('window:beforeunload', ['$event'])
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/explicit-function-return-type
    public unloadHandler($event): string {
        if (!this.organizationMangerService.organizationManagersSaved.value) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return $event.returnValue = 'Your changes will not be saved';
        }
    }

    public async ngOnInit(): Promise<void> {
        this.managers = await this.organizationAdminPanelRestService.getOrganizationManagers();
        this.allLearners = await this.organizationAdminPanelRestService.getOrganizationActiveLearners();
        this.availableNewLearners = this.allLearners.filter(learner => this.managers.find(manager => manager.iamId === learner.iamId) === undefined);
        this.authenticatedUserIamId = this.authService.getAuthenticatedUser().sub;
        this.validateManagers();
    }

    public addManager(event: { iamId: string; }): void {
        const learner = this.availableNewLearners.find(data => event.iamId === data.iamId);
        this.managers.push(new OrganizationAdminManager(learner.iamId, learner.displayName, learner.email));
        this.availableNewLearners = this.availableNewLearners.filter(data => data.iamId !== event.iamId);
        this.selectionOfLearnerOpen = false;
        this.setOrganizationMangersSavedStatus();
        this.validateManagers();
    }

    public changeRoleStatus(manager: OrganizationAdminManager, role: string): void {
        const currentState = manager[role] as boolean;
        manager[role] = !currentState;
        this.setOrganizationMangersSavedStatus();
        this.validateManagers();
    }

    public removeManager(managerIdIamId: string): void {
        this.managers = this.managers.filter(manager => manager.iamId !== managerIdIamId);
        const learner = this.allLearners.find(data => data.iamId === managerIdIamId);
        if (learner !== undefined) {
            this.availableNewLearners.push(learner);
        }
        this.selectionOfLearnerOpen = false;
        this.setOrganizationMangersSavedStatus();
        this.validateManagers();
    }


    public saveManagers: () => Promise<void> = async () => {
        try {
            await this.organizationAdminPanelRestService.addOrganizationManagers(this.managers);
            this.organizationMangerService.setOrganizationManagersSavedStatus(true);
            this.organizationMangerService.setOrganizationManagers(undefined);
            this.toastr.success($localize`Manager gespeichert`);
        } catch (e) {
            this.toastr.error($localize`Könnte nicht gespeichert werden`);
        }
    };

    private validateManagers(): void {
        this.emptyRole = this.managers.some(manager => {
            return !manager.organizationAdminRole && !manager.supervisorRole && !manager.contentCreatorRole;
        });
        this.missingAdmin = this.managers.every(data => !data.organizationAdminRole);
        const organizationAdminManager = this.managers.find(manager => manager.iamId === this.authenticatedUserIamId);
        if (organizationAdminManager !== undefined) {
            this.selfAdminUnselected = !organizationAdminManager.organizationAdminRole;
        }
        this.organizationMangerService.setOrganizationManagersAllowToSave(this.managers !== undefined && this.managers.length > 0 && !this.emptyRole && !this.missingAdmin && !this.selfAdminUnselected);
    }

    private setOrganizationMangersSavedStatus(): void {
        this.organizationMangerService.setOrganizationManagersSavedStatus(false);
        this.organizationMangerService.setOrganizationManagers(this.managers);
    }
}
