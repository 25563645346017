import {Answer} from '../../../api-types/answer';
import {Quiz} from '../../../api-types/quiz';
import {QuizType, QuizTypeName} from '../QuizType';
import {QuizQuestion} from '../QuizQuestion';
import {QuizExplanation} from '../QuizExplanation';

export class SingleTextChoiceQuiz implements Quiz {
    public quizType: QuizType = QuizTypeName.SINGLE_CHOICE_TYPE;

    constructor(public id: string,
                public quizId: string,
                public quiz: QuizQuestion,
                public answers: Answer[],
                public explanation?: QuizExplanation,
                public sourceUrl?: string,
                public sourceLabel?: string,
                public originalContentHash?: string) {
    }
}
