<h3 class="micromate-question-preview-answers-title" i18n>Antworten</h3>
<div *ngFor="let answer of questionPreview.answers" class="micromate-answer"
     [class.micromate-answer-correct]="answer.isCorrectAnswer"
     [class.micromate-answer-wrong]="!answer.isCorrectAnswer"
     [innerHTML]="answer.answerText | markdown">
</div>
<app-explanation-question-preview
    *ngIf="questionPreview.explanation !== undefined && (questionPreview.explanation.mediaType !== undefined || (questionPreview.explanation.text !== undefined && questionPreview.explanation.text.length > 0))"
    [explanation]="questionPreview.explanation"></app-explanation-question-preview>
<app-source-question-preview *ngIf="questionPreview.sourceUrl !== undefined"
                             [sourceUrl]="questionPreview.sourceUrl"
                             [sourceLabel]="questionPreview.sourceLabel"></app-source-question-preview>
