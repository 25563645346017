import {Injectable} from '@angular/core';
import {VideoFormat, VideoFormatType} from './videoLinkFormats/VideoFormat';
import {YouTubeVideo} from './videoLinkFormats/YouTubeVideo';
import {GoogleDriveVideo} from './videoLinkFormats/GoogleDriveVideo';
import {OneDriveVideo} from './videoLinkFormats/OneDriveVideo';
import {DefaultVideo} from './videoLinkFormats/DefaultVideo';
import {VideoLink} from './videoLinkFormats/VideoLink';
import {DartfishVideo} from './videoLinkFormats/DartfishVideo';

@Injectable({
    providedIn: 'root'
})
export class MovieService {
    public getVideoType(url: string): VideoLink {
        if (YouTubeVideo.isVideoFormat(url)) {
            return YouTubeVideo.createVideoLink();
        }

        if (GoogleDriveVideo.isVideoFormat(url)) {
            return GoogleDriveVideo.createVideoLink();
        }

        if (OneDriveVideo.isVideoFormat(url)) {
            return OneDriveVideo.createVideoLink();
        }

        if (DartfishVideo.isVideoFormat(url)) {
            return DartfishVideo.createVideoLink();
        }

        return DefaultVideo.createVideoLink();
    }

    public createVideoLink(format: VideoFormatType, media: string): string {
        switch (format) {
            case VideoFormat.YOUTUBE:
                return YouTubeVideo.crateVideoLink(media);
            case VideoFormat.GOOGLE_DRIVE:
                return GoogleDriveVideo.crateVideoLink(media);
            case VideoFormat.ONE_DRIVE:
                return OneDriveVideo.crateVideoLink(media);
            case VideoFormat.DARTFISH:
                return DartfishVideo.crateVideoLink(media);
            default:
                return media;
        }
    }
}
