<div class="micromate-tag-dropdown"
     [class.micromate-tag-dropdown-min-height]="(hasAnyTags$ | async) && ((filteredSelectedTags$ | async).length > 0 || (filteredAvailableTags$ | async).length > 0)">
    <div class="micromate-tag-dropdown-label" i18n>Tag hinzufügen:</div>
    <div class="micromate-tag-dropdown-search">
        <input #filterInput type="text" class="micormate-form-input micromate-tag-dropdown-search-input"
               [formControl]="filterText">
        <div *ngIf="filterText.errors?.trimmedMaxLength" class="micormate-form-validation-error" i18n>Maximal 50 Zeichen
        </div>
    </div>
    <div class="micromate-tag-dropdown-tag-list">
        <div class="micromate-tag-dropdown-list"
             *ngIf="hasAnyTags$ | async">
            <div *ngFor="let selectedAvailableTag of (filteredSelectedTags$ | async)"
                 class="micromate-tag-dropdown-tag"
                 (click)="unselectTag(selectedAvailableTag)">
                <div class="micromate-tag-dropdown-checked-background"
                     [style.background-color]="selectedAvailableTag.color"></div>
                <app-icon icon="square-check-light"
                          class="micromate-tag-dropdown-tag-check"></app-icon>
                <div class="micromate-tag-dropdown-tag-label"
                     [innerHTML]="formatFilterTagLabel(selectedAvailableTag.label)">
                </div>
            </div>
            <div *ngFor="let availableTag of (filteredAvailableTags$ | async)"
                 class="micromate-tag-dropdown-tag"
                 (click)="selectTag(availableTag.id)">
                <app-icon icon="square-light"
                          class="micromate-tag-dropdown-tag-check"></app-icon>
                <div class="micromate-tag-dropdown-tag-label" [innerHTML]="formatFilterTagLabel(availableTag.label)">
                </div>
            </div>
        </div>
        <div class="micromate-tag-dropdown-new-tag"
             *ngIf="(shouldShowNewTag$ | async) && filterText.value.trim().length > 0"
             (click)="createNewTag()">
            <strong>{{filterText.value}}</strong>
            <div i18n>erstellen</div>
        </div>
    </div>
</div>
