<div class="micromate-tags-questions"
     *ngIf="questionTags !== undefined"
     [class.micromate-tags-questions-small]="size === 'small'">
    <div *ngFor="let questionTag of questionTags"
         class="micromate-tags-questions-tag"
         [style.background-color]="questionTag.color"
         [title]="questionTag.label">
        <div class="micromate-tags-questions-tag-text">
            {{questionTag.label}}
        </div>
        <app-icon *ngIf="allowRemoveTag"
                  icon="times-light"
                  (click)="unselectTag(questionTag)"
                  class="micromate-tags-questions-tag-remove"></app-icon>
    </div>
</div>
