import {QuizQuestionForm} from '../QuizQuestionForm';
import {QuizExplanationForm} from '../QuizExplanationForm';
import {QuizEditor} from '../QuizEditor';
import {SingleTextAnswersQuizEditor} from './TrueFalseAnswersQuizEditor';
import {SelectedTag} from '../../../api-types/SelectedTag';
import {SelectedNewTag} from '../../../api-types/SelectedNewTag';

export class TrueFalseQuizEditor implements QuizEditor {
    constructor(public positiveAnswer: SingleTextAnswersQuizEditor,
                public negativeAnswer: SingleTextAnswersQuizEditor,
                public explanation: QuizExplanationForm,
                public question: QuizQuestionForm,
                public selectedAvailableTags: SelectedTag[],
                public selectedNewTags: SelectedNewTag[],
                public sourceUrl?: string,
                public sourceLabel?: string) {
    }
}
