<div class="color-sample">
    <div class="color-sample-dark">
        <div class="sample-red"></div>
        <div class="sample-yellow"></div>
        <div class="sample-green"></div>
        <div class="sample-darkgreen"></div>
        <div class="sample-orange"></div>
        <div class="sample-violet"></div>
        <div class="sample-magenta"></div>
        <div class="sample-blue"></div>
        <div class="sample-darkblue"></div>
        <div class="sample-grey"></div>
        <div class="sample-darkgrey"></div>
    </div>

    <div class="color-sample-standard">
        <div class="sample-red">
            <div class="sample-number">#fa5557</div>
        </div>
        <div class="sample-yellow">
            <div class="sample-number">#FFCE4A</div>
        </div>
        <div class="sample-green">
            <div class="sample-number">#A1D189</div>
        </div>
        <div class="sample-darkgreen">
            <div class="sample-number">#5C8A45</div>
        </div>
        <div class="sample-orange">
            <div class="sample-number">#F5A836</div>
        </div>
        <div class="sample-violet">
            <div class="sample-number"> #bf88b9</div>
        </div>
        <div class="sample-magenta">
            <div class="sample-number">#8DDFDC</div>
        </div>
        <div class="sample-blue">
            <div class="sample-number">#95abbf</div>
        </div>
        <div class="sample-darkblue">
            <div class="sample-number sample-number-light">#334455</div>
        </div>
        <div class="sample-grey">
            <div class="sample-number">#d2d2d2</div>
        </div>
        <div class="sample-darkgrey">
            <div class="sample-number sample-number-light">#323232</div>
        </div>
    </div>

    <div class="color-sample-light">
        <div class="sample-red"></div>
        <div class="sample-yellow"></div>
        <div class="sample-green"></div>
        <div class="sample-darkgreen"></div>
        <div class="sample-orange"></div>
        <div class="sample-violet"></div>
        <div class="sample-magenta"></div>
        <div class="sample-blue"></div>
        <div class="sample-darkblue"></div>
        <div class="sample-grey"></div>
        <div class="sample-darkgrey"></div>
    </div>

    <div class="color-sample-lighter">
        <div class="sample-red"></div>
        <div class="sample-yellow"></div>
        <div class="sample-green"></div>
        <div class="sample-darkgreen"></div>
        <div class="sample-orange"></div>
        <div class="sample-violet"></div>
        <div class="sample-magenta"></div>
        <div class="sample-blue"></div>
        <div class="sample-darkblue"></div>
        <div class="sample-grey"></div>
        <div class="sample-darkgrey"></div>
    </div>
</div>
