import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'app-wizard-page-footer',
    templateUrl: './wizard-page-footer.component.html',
    styleUrls: ['./wizard-page-footer.component.scss']
})
export class WizardPageFooterComponent {

    @Output()
    public navigateForwardClicked: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public navigateBackClicked: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public isBackVisible: boolean;

    @Input()
    public isForwardVisible: boolean;

    @Input()
    public isForwardDisabled: boolean;

    @Input()
    public hasCustomContent: boolean;

    @Input()
    public forwardText: boolean;

    @Input()
    public forwardWidth: number;

}
