<app-popup-container>
    <app-popup-wizard-header header="Neue Ressource" i18n-header></app-popup-wizard-header>

    <app-popup-content>
        <div class="mm-import-new-resource-wizard-page-container">
            <app-info-box
                i18n-content
                content="Erlaubte Dateiformate sind PDF oder HTML. HTML-Dateien sind nur im Micromate Resource HTML-Format zulässig."></app-info-box>
            <app-drag-and-drop class="mm-import-new-resource-page-drag-and-drop"
                               [class.micromate-wizard-page-drag-and-drop-small]="invalidFile"
                               acceptFileType=".html, .pdf"
                               (files)="sendFile($event)"></app-drag-and-drop>

            <app-error-box *ngIf="invalidFile" class="mm-import-new-resource-page-error-box">
                <ul class="mm-error-box-message-list">
                    <li *ngIf="invalidFileType" class="mm-error-box-message-list-element" i18n>
                        Dateiformat wird nicht unterstützt. Wähle eine PDF- oder Resource HTML-Datei aus.
                    </li>
                    <li *ngIf="invalidFileToBig" class="mm-error-box-message-list-element" i18n>
                        Datei ist zu gross. Es sind Dateien bis maximal 30 MBs erlaubt.
                    </li>
                    <li *ngIf="invalidFileToManyFiles" class="mm-error-box-message-list-element" i18n>
                        Mehrere Dateien sind nicht zulässig. Es muss genau eine Datei ausgewählt werden.
                    </li>
                </ul>
            </app-error-box>
        </div>
    </app-popup-content>
</app-popup-container>
