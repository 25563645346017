import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {GroupLearningPackageSettingService} from '../app-learncenter/group-learning-package-setting/group-learning-package-setting.service';
import {GroupLearningPackageSettingComponent} from '../app-learncenter/group-learning-package-setting/group-learning-package-setting.component';

@Injectable()
export class GroupLearningPackageSettingsGuard implements CanDeactivate<GroupLearningPackageSettingComponent> {
    public component: Object;
    public route: ActivatedRouteSnapshot;

    constructor(private groupLearningPackageSettingService: GroupLearningPackageSettingService) {
    }

    public async canDeactivate(component: GroupLearningPackageSettingComponent,
                         route: ActivatedRouteSnapshot,
                         state: RouterStateSnapshot,
                         nextState: RouterStateSnapshot): Promise<boolean> {
        return await this.groupLearningPackageSettingService.checkIfGroupLearningPackageSettingIsSaved(nextState.url);
    }

}
