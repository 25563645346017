import {Component, OnDestroy, OnInit} from '@angular/core';
import {LearningPackageResource} from '../../core/rest/learning-package-resource.service';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {ListPagination} from '../../core/api-types/listPagination';
import {TablePaginationParamsService} from '../../core/table-pagination-params.service';
import {LearningPackageAdminOverview} from '../../core/api-types/learningPackageAdminOverview';
import {Subscription} from 'rxjs';
import {PopupService} from '../../components/popup/popup.service';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {LearningPackageOrganizationConnectionComponent} from './learning-package-organization-connection/learning-package-organization-connection.component';
import {LearningPackageOrganizationPopupData} from './learning-package-organization-connection/LearningPackageOrganizationPopupData';
import {LearningPackageOrganizationPopupResult} from './learning-package-organization-connection/LearningPackageOrganizationPopupResult';

@Component({
    selector: 'app-learn-package-overview',
    templateUrl: './learning-package-overview.component.html',
    styleUrls: ['./learning-package-overview.component.scss']
})
export class LearningPackageOverviewComponent implements OnInit, OnDestroy {
    public learningPackages: LearningPackageAdminOverview[];
    public paginationParams: ListPaginationRequest;
    public listPagination: ListPagination<LearningPackageAdminOverview>;
    public navigationUrl: string[] = ['administration', 'lernpakete'];
    public subscription = new Subscription();

    constructor(private learningPackageRestService: LearningPackageResource,
                public multilanguageService: MultilanguageService,
                private tablePaginationParamsService: TablePaginationParamsService,
                private popupService: PopupService) {
    }

    public ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.tablePaginationParamsService.unSubscribeActiveRoute();
    }

    public ngOnInit(): void {
        this.tablePaginationParamsService.updateFromActivatedRouteParams();
        this.getLearningPackagesData();
    }

    public async openLearningPackageConnectionPopup(learningPackageId: string): Promise<void> {
        const popupOptions = new PopupOptions();
        const result = await this.popupService.open<LearningPackageOrganizationPopupData, LearningPackageOrganizationPopupResult>(LearningPackageOrganizationConnectionComponent, new LearningPackageOrganizationPopupData(learningPackageId), popupOptions);
        if (result === 'connectedOrganization') {
            this.getLearningPackagesData();
        }
    }

    private getLearningPackagesData(): void {
        this.subscription = this.tablePaginationParamsService.paginationParams$.subscribe((paginationParams: ListPaginationRequest) => {
            this.paginationParams = paginationParams;
            this.learningPackageRestService.getAllActive(this.paginationParams).subscribe((listPagination: ListPagination<LearningPackageAdminOverview>) => {
                this.listPagination = listPagination;
                this.learningPackages = listPagination.data.filter(learningPackage => learningPackage.mainLearningPackage).sort((a, b) =>
                    new Date(b.lastUpdateDate).valueOf() - new Date(a.lastUpdateDate).valueOf());
            });
        });

    }

    public getLanguages(languages: string[]): string {
        if (languages !== undefined && languages.length > 0) {
            return `, ${languages.map(language => this.multilanguageService.createShortName(language)).join(', ')}`;
        }
        return '';
    }
}
