import {Injectable, Injector} from '@angular/core';
import {OrganizationService} from '../organization.service';
import {Router} from '@angular/router';
import {FrontendError} from './frontendError';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {FrontendErrorUser} from './frontendErrorUser';
import * as StackTrace from 'stacktrace-js';
import {AuthService} from '../authentication/auth.service';
import {AuthenticationError} from '../authentication/authenticationError';

/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call*/

@Injectable({
    providedIn: 'root'
})
export class FrontendErrorHandler {
    constructor(private injector: Injector) {
    }

    public async handleError(rawError: any): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const error: Error = rawError.rejection !== undefined ? rawError.rejection as Error : rawError as Error;

        console.error(error);
        const frontendError = await this.createFrontendErrorFromError(error);
        await this.sendError(frontendError);

        if (error instanceof AuthenticationError) {
            window.location.href = environment.authErrorUrl;
        }
    }

    private async createFrontendErrorFromError(error: Error): Promise<FrontendError> {
        const userData = this.getUserData();
        const innerError: FrontendError = error.cause !== undefined && error.cause instanceof Error ? await this.createFrontendErrorFromError(error.cause) : undefined;
        return new FrontendError(
            new Date(),
            error.message.split('at ')[0],
            await this.getStackTrace(error),
            innerError,
            this.injector.get(Router)?.url,
            this.detectBrowser(),
            userData?.id,
            userData?.data,
            this.getOrganizationId()
        );
    }

    private sendError(frontendError: FrontendError): Promise<string> {
        const http = this.injector.get(HttpClient);
        return http.post(`${environment.serverUrl}/api/frontend-error`, frontendError, {responseType: 'text'}).toPromise();
    }

    private async getStackTrace(error: Error): Promise<string> {
        if (error.stack !== undefined) {
            const stackTraces = await StackTrace.fromError(error);
            if (stackTraces !== undefined) {
                const stack: string[] = [];
                for (const stackTrace of stackTraces) {
                    stack.push(`    at ${stackTrace.functionName} (${stackTrace.fileName}:${stackTrace.lineNumber}:${stackTrace.columnNumber})`);
                }
                return stack.join('\n');
            }
        }
        return 'undefined';
    }

    private getOrganizationId(): string {
        const organizationService = this.injector.get(OrganizationService);
        return organizationService.getActiveOrganizationId();
    }

    private getUserData(): { id: string; data: FrontendErrorUser; } {
        const authService = this.injector.get(AuthService);
        const user = authService.getAuthenticatedUser();

        const userData = {
            email: user?.email,
            name: `${user?.given_name} ${user?.family_name}`,
            organizations: user?.organisations
        };
        return {id: user?.sub, data: userData};
    }

    private detectBrowser(): string {
        const userAgent = navigator.userAgent;
        let tem;
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        let matchTest = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

        if (/trident/i.test(matchTest[1])) {
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            return `IE ${tem[1] || ''}`;
        }

        if (matchTest[1] === 'Chrome') {
            tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
            // eslint-disable-next-line no-null/no-null
            if (tem != null) {
                return tem.slice(1).join(' ').replace('OPR', 'Opera') as string;
            }
        }

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        matchTest = matchTest[2] ? [matchTest[1], matchTest[2]] : [navigator.appName, navigator.appVersion, '-?'];
        // eslint-disable-next-line no-null/no-null
        if ((tem = userAgent.match(/version\/(\d+)/i)) != null) {
            matchTest.splice(1, 1, tem[1]);
        }
        return matchTest.join(' ');
    }
}
