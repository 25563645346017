import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ResourceHelper} from './resource-helper.service';
import {Quiz} from '../api-types/quiz';
import {Observable, switchMap} from 'rxjs';

import {TranslateQuestionResult} from './translate-question.result';

@Injectable({
    providedIn: 'root'
})
export class TranslationRessource {
    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public translateQuestion(question: Quiz, targetLocale: string): Observable<TranslateQuestionResult | undefined> {
        return this.restHelperService.activeOrganizationUrl$.pipe(switchMap(baseUrl => {
            const body = {question: question};
            const queryParams = {locale: targetLocale};
            const url = `${baseUrl}/translation/question`;

            return this.http.put<TranslateQuestionResult>(url, body, {params: queryParams});
        }));
    }
}
