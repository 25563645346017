import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {OrganizationService} from '../organization.service';
import {Features} from './Features';
import {LanguageModel} from './OrganizationAICredential';
import {OrganizationAITypes} from './OrganizationAITypes';

@Injectable({
    providedIn: 'root'
})
export class FeaturesService {

    constructor(private organizationService: OrganizationService) {
    }

    public async hasExpectedFeatures(expectedFeatures: string[]): Promise<boolean> {
        const organizationFeatures = await this.organizationService.getOrganizationFeatures();
        for (const allowFeature of expectedFeatures) {
            if (organizationFeatures[allowFeature] === false) {
                return false;
            }
        }
        return true;
    }

    public hasFeatureNotification(): Observable<boolean> {
        return this.organizationService.organizationFeatures$.pipe(
            map((features: Features) => features.notifications)
        );
    }

    public hasFeatureScoreboardRanking(): Observable<boolean> {
        return this.organizationService.organizationFeatures$.pipe(
            map((features: Features) => features.scoreboardRanking)
        );
    }

    public hasFeatureAI(): Observable<boolean> {
        return this.organizationService.organizationFeatures$.pipe(
            map((features: Features) => {
                const aiFeature = features.ai;
                if (aiFeature.type === OrganizationAITypes.MicromateLlm) {
                    return true;
                }
                if (aiFeature.type === OrganizationAITypes.CustomLlm) {
                    if (aiFeature.credential === undefined) {
                        return false;
                    }
                    if (aiFeature.credential.apiKey === undefined || aiFeature.credential.apiKey.trim().length === 0) {
                        return false;
                    }
                    if (aiFeature.credential.languageModel === LanguageModel.AzureOpenAI) {
                        const hasApiUrl = aiFeature.credential.apiUrl !== undefined && aiFeature.credential.apiUrl.trim().length > 0;
                        const hasCompletionModelDeploymentName = aiFeature.credential.completionModelDeploymentName !== undefined && aiFeature.credential.completionModelDeploymentName.trim().length > 0;
                        const hasEmbeddingModelDeploymentName = aiFeature.credential.embeddingModelDeploymentName !== undefined && aiFeature.credential.embeddingModelDeploymentName.trim().length > 0;
                        return hasApiUrl && hasCompletionModelDeploymentName && hasEmbeddingModelDeploymentName;
                    }
                    return true;
                }
                return false;
            })
        );
    }

    public hasFeaturePersonalInsights(): Observable<boolean> {
        return this.organizationService.organizationFeatures$.pipe(
            map((features: Features) => features.personalInsights)
        );
    }

}
