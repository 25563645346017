import {Injectable} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LearningPackageService {

    constructor(public router: Router) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.params.learningPackageId !== undefined) {
                    const learningPackageId = path.snapshot.params.learningPackageId as string;
                    this.selectedLearningPackageId$.pipe(take(1)).subscribe(selectedLearningPackage => {
                        if (selectedLearningPackage !== learningPackageId) {
                            this.selectedLearningPackageId.next(learningPackageId);
                        }
                    });
                } else {
                    this.selectedLearningPackageId.next(undefined);
                }
            }
        });
    }

    public selectedLearningPackageId = new BehaviorSubject<string>(undefined);
    public selectedLearningPackageId$ = this.selectedLearningPackageId.asObservable();
}
