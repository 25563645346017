import {FrontendErrorUser} from './frontendErrorUser';

export class FrontendError {
    public readonly frontend = 'MANAGE';

    constructor(public date: Date,
                public message: string,
                public stack: string,
                public innerError: FrontendError,
                public url: string,
                public browser: string,
                public userId: string,
                public userData: FrontendErrorUser,
                public organizationId: string) {
    }

}
