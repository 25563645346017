import {Component} from '@angular/core';
import {AuthUntilUserOrganizationRoleService} from '../core/authentication/auth-until-user-organization-role.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent {

    constructor(public userRoleService: AuthUntilUserOrganizationRoleService) {
    }
}
