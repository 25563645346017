import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Popup} from '../../../components/popup/Popup';
import {PopupService} from '../../../components/popup/popup.service';
import {FormControl, FormGroup} from '@angular/forms';
import {NewTagPopupResult} from './NewTagPopupResult';
import {filter} from 'rxjs/operators';
import {EditorLearningPackageService} from '../../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../../core/rest/editor-resource.service';
import {NewTagPopupData} from './NewTagPopupData';
import {Subscription, takeUntil} from 'rxjs';
import {TagFormGroup} from './TagFormGroup';
import {HttpErrorResponse} from '@angular/common/http';
import {FormCustomValidation} from 'src/app/core/form-custom-validation';
import {NewTag} from '../../../core/api-types/NewTag';
import {LearningPackageTagsPreparator} from '../LearningPackageTagsPreparator';

@Component({
    selector: 'app-new-tag-popup',
    templateUrl: './new-tag-popup.component.html',
    styleUrls: ['./new-tag-popup.component.scss']
})
export class NewTagPopupComponent implements Popup<NewTagPopupData>, OnInit, OnDestroy {

    @Input()
    public data: NewTagPopupData;

    public hasError: boolean = false;

    public tagForm: FormGroup<TagFormGroup> = new FormGroup<TagFormGroup>({
        label: new FormControl<string>('', [FormCustomValidation.trimmedRequired, FormCustomValidation.trimmedMaxLengthValidator(50)]),
        activationTag: new FormControl<boolean>(false)
    });

    private tagFormValueChanged$ = new Subscription();

    constructor(private popupService: PopupService,
                private editorLearningPackageService: EditorLearningPackageService,
                private editorRestService: EditorResource) {
    }

    public ngOnInit(): void {
        this.tagFormValueChanged$ = this.tagForm.valueChanges.subscribe(() => this.hasError = false);
    }

    public createTag: () => Promise<void> = async () => {
        this.hasError = false;
        return new Promise(resolve => {
            const tagFormData = this.tagForm.getRawValue();
            const label = LearningPackageTagsPreparator.removeSpacesInText(tagFormData.label);
            const newTag = new NewTag(label, tagFormData.activationTag);
            this.editorRestService.createNewLearningPackageTag(this.data.selectedLearningPackageId, newTag)
                .pipe(takeUntil(this.popupService.isPopupOpen$.pipe(filter(isOpen => !isOpen))))
                .subscribe(() => {
                        this.popupService.close<NewTagPopupResult>('tagCreated');
                        resolve();
                    },
                    (error: HttpErrorResponse) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        if (error.error?.duplicatedTag as boolean) {
                            this.hasError = true;
                        }
                        resolve();
                    });
        });
    };

    public cancel(): void {
        this.popupService.close();
    }

    public ngOnDestroy(): void {
        this.tagFormValueChanged$.unsubscribe();
    }
}
