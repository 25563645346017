import {Component, OnDestroy, OnInit} from '@angular/core';
import {Group} from '../../core/api-types/group';
import {Router} from '@angular/router';
import {GroupService} from '../../core/group.service';
import {LearningGroupResource} from '../../core/rest/learning-group-resource.service';
import {LearncenterService} from '../../core/learncenter/learncenter.service';
import {OrganizationService} from '../../core/organization.service';
import {Subscription} from 'rxjs';
import {ConfirmationPopupData} from '../../components/confirmation-popup/ConfirmationPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {ConfirmationPopupResult} from '../../components/confirmation-popup/ConfirmationPopupResult';
import {ConfirmationPopupComponent} from '../../components/confirmation-popup/confirmation-popup.component';
import {PopupService} from '../../components/popup/popup.service';
import {NewLearningGroupPopupComponent} from './new-learning-group-popup/new-learning-group-popup.component';

@Component({
    selector: 'app-learning-groups-overview',
    templateUrl: './learning-groups-overview.component.html',
    styleUrls: ['./learning-groups-overview.component.scss']
})
export class LearningGroupsOverviewComponent implements OnInit, OnDestroy {
    public defaultGroup: Group;
    public groups: Group[];
    public viewModelObservable = new Subscription();

    constructor(private groupRestService: LearningGroupResource,
                private router: Router,
                private groupService: GroupService,
                private learningGroupService: LearncenterService,
                private organizationService: OrganizationService,
                private popupService: PopupService) {
    }

    public ngOnInit(): void {
        this.viewModelObservable = this.organizationService.activeOrganizationId$.subscribe(activeOrganizationId => {
            if (activeOrganizationId !== undefined) {
                this.groupRestService.getDefaultOrganizationGroups().subscribe(groups => {
                    this.defaultGroup = groups;
                });
                this.groupRestService.getOrganizationGroups().subscribe(groups => {
                    this.groups = groups;
                });
                this.learningGroupService.addedNewLearnGroup$.subscribe(status => {
                    if (status) {
                        this.groupRestService.getOrganizationGroups().subscribe(groups => {
                            this.groups = groups;
                            this.learningGroupService.updatedGroupTable();
                        });
                    }
                });
            }
        });
    }

    public openNewGroupForm(): void {
        void this.popupService.open(NewLearningGroupPopupComponent, undefined, new PopupOptions());
    }

    public async deleteLearningGroup(group: Group): Promise<void> {
        const text = `Eine gelöschte Lerngruppe kann nicht wiederhergestellt werden. Die Lerngruppe ist nach dem Löschen auch im Analytics nicht mehr ersichtlich. Möchtest du die Lerngruppe <strong>${group.name}</strong> definitiv löschen?`;
        const confirmationPopupData = new ConfirmationPopupData(text, 'Löschen', 'Abbrechen', async () => {
            await this.groupRestService.removeGroup(group._id);
            this.learningGroupService.updateGroupTable();
        });
        const popupOptions = new PopupOptions('small');
        await this.popupService.open<ConfirmationPopupData, ConfirmationPopupResult>(ConfirmationPopupComponent, confirmationPopupData, popupOptions);
    }

    public ngOnDestroy(): void {
        this.viewModelObservable.unsubscribe();
    }
}
