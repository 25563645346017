import {Injectable, OnDestroy} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ListPaginationRequest} from './api-types/listPaginationRequest';
import {QueryParamService} from './QueryParamService';

@Injectable({
    providedIn: 'root'
})
export class TablePaginationParamsService implements OnDestroy {
    public paramsSubscription = new Subscription();
    public filter = new BehaviorSubject<string>('');
    public filter$ = this.filter.asObservable();

    public pageNumber = new BehaviorSubject<number>(1);
    public pageNumber$ = this.pageNumber.asObservable();

    public pageAmount = new BehaviorSubject<string>('50');

    public paginationParams = new BehaviorSubject<ListPaginationRequest>(new ListPaginationRequest('', 1, '50'));
    public paginationParams$ = this.paginationParams.asObservable();

    constructor(private activatedRoute: ActivatedRoute) {
    }

    public updateFromActivatedRouteParams(): void {
        this.paramsSubscription = this.activatedRoute.queryParamMap.subscribe(params => {
            let hasChanged = false;

            const activeLanguageInfo = QueryParamService.getQueryParamValueString(params, 'filter', '');
            if (activeLanguageInfo !== this.filter.value) {
                this.filter.next(activeLanguageInfo);
                hasChanged = true;
            }

            const pageNumberInfo = QueryParamService.getQueryParamValueInt(params, 'pageNumber', 1);
            if (pageNumberInfo !== this.pageNumber.value) {
                this.pageNumber.next(pageNumberInfo);
                hasChanged = true;
            }

            const pageAmountInfo = QueryParamService.getQueryParamValueString(params, 'pageAmount', '50');
            if (pageAmountInfo !== this.pageAmount.value) {
                this.pageAmount.next(pageAmountInfo);
                hasChanged = true;
            }

            if (hasChanged) {
                this.paginationParams.next(new ListPaginationRequest(this.filter.value, this.pageNumber.value, this.pageAmount.value));
            }
        });
    }

    public unSubscribeActiveRoute(): void {
        this.paramsSubscription.unsubscribe();
    }

    public getPaginationParams(): ListPaginationRequest {
        return this.paginationParams.value;
    }

    public ngOnDestroy(): void {
        this.paramsSubscription.unsubscribe();
    }

}
