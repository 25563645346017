<div class="micromate-card">
    <h1 i18n>Lernpakete</h1>

    <div *ngIf="listPagination !== undefined">
        <app-table-search [paginationParams]="paginationParams" [navigateUrl]="navigationUrl" [totalDataAmount]="listPagination?.totalDataAmount"></app-table-search>
        <div class="micromate-table" *ngIf="learningPackages !== undefined">
            <div class="table-container table-container-header">
                <ng-container>
                    <div class="row-header" i18n>Name</div>
                    <div class="row-header" i18n>Organisation</div>
                    <div class="row-header" i18n>Contentersteller</div>
                    <div class="row-header" i18n>Erstellungsdatum</div>
                    <div class="row-header" i18n>Letztes Update</div>
                    <div class="row-header" i18n>Sprache</div>
                    <div class="row-header" i18n></div>
                </ng-container>
            </div>
            <div class="table-container-data"  *ngIf="learningPackages.length > 0; else emptyTable" #tableContentRef>
                <div class="table-container table-content" *ngFor="let learningPackage of learningPackages">
                    <div class="row row-name">
                        <div class="row-sub micromate-table-link">
                            {{learningPackage.name}}
                        </div>
                        <div class="row-only-mobile-email">
                            {{learningPackage.creationDate | date:'short'}}
                        </div>
                        <div class="row-only-mobile-email">
                            {{learningPackage.ownerOrganizationName}}
                        </div>
                    </div>

                    <div class="row row-only-web">{{learningPackage.ownerOrganizationName}}</div>
                    <div class="row row-only-web">{{learningPackage.ownerContentCreatorName}}</div>
                    <div class="row row-only-web">{{learningPackage.creationDate | date:'short'}}</div>
                    <div class="row row-only-web">{{learningPackage.lastUpdateDate | date:'short'}}</div>
                    <div class="row row-only-web">
                        <strong>{{multilanguageService.createShortName(learningPackage.mainLanguage)}}</strong>{{getLanguages(learningPackage?.languages)}}
                    </div>
                    <div class="row group-info">
                        <div class="micromate-group-button"
                             (click)="openLearningPackageConnectionPopup(learningPackage._id)">
                            <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                                <use href="./assets/icons.svg#sitemap-light"></use>
                            </svg>
                            {{learningPackage.assignedToOrganization.length}}</div>
                    </div>
                </div>
            </div>
            <ng-template #emptyTable>
                <div class="table-empty-filter-results" i18n>
                    keine Suchresultate
                </div>
            </ng-template>
        </div>
        <app-table-pagination [paginationParams]="paginationParams"
                              [totalDataAmount]="listPagination.totalDataAmount"
                              [navigateUrl]="navigationUrl"></app-table-pagination>
    </div>

</div>
