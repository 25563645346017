import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {GroupLearningPackageEditSetting} from '../../core/api-types/groupLearningPackageEditSetting';
import {CancelSavingPopupData} from '../../components/cancel-saving-popup/CancelSavingPopupData';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {CancelSavePopupResult} from '../../components/cancel-saving-popup/CancelSavePopupResult';
import {CancelSavingPopupComponent} from '../../components/cancel-saving-popup/cancel-saving-popup.component';
import {PopupService} from '../../components/popup/popup.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {LearningGroupResource} from '../../core/rest/learning-group-resource.service';

@Injectable({
    providedIn: 'root'
})
export class GroupLearningPackageSettingService {
    public groupLearningPackageSettingSaved = new BehaviorSubject<boolean>(true);
    public groupLearningPackageSettingSaved$ = this.groupLearningPackageSettingSaved.asObservable();

    public groupLearningPackageSetting: { groupId: string; settings: GroupLearningPackageEditSetting; };

    constructor(private popupService: PopupService,
                private router: Router,
                private groupRestService: LearningGroupResource,
                private toastr: ToastrService) {
    }

    public setGroupLearningPackageSettingSavedStatus(value: boolean): void {
        this.groupLearningPackageSettingSaved.next(value);
    }

    public async checkIfGroupLearningPackageSettingIsSaved(url?: string): Promise<boolean> {
        if (this.groupLearningPackageSettingSaved.value) {
            return true;
        }
        const cancelSavingPopupData = new CancelSavingPopupData(true);
        const popupOptions = new PopupOptions('small');
        const popupServiceResult = await this.popupService.open<CancelSavingPopupData, CancelSavePopupResult>(CancelSavingPopupComponent, cancelSavingPopupData, popupOptions);
        if (popupServiceResult === 'Save') {
            this.saveSettings();
        } else if (popupServiceResult === 'Discard') {
            this.redirect(url);
        }
        return false;
    }


    public saveSettings(): void {
        const settings = this.getSetting();
        this.groupRestService.updateLearningPackageSettings(settings.groupId, settings.settings.id, settings.settings).subscribe(() => {
            this.toastr.success($localize`Lernpaketeinstellungen wurden gespeichert`);
            this.redirect();
        }, () => {
            this.toastr.error($localize`Könnte nicht gespeichert werden`);
        });
    }

    public redirect(url?: string): void {
        this.setGroupLearningPackageSettingSavedStatus(true);
        this.setSetting(undefined, undefined);
        if (url !== undefined) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate([url]);
        } else {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.router.navigate(['/lerncenter/lerngruppen']);
        }
    }

    public setSetting(groupId: string, settings: GroupLearningPackageEditSetting): void {
        this.groupLearningPackageSetting = {
            groupId: groupId,
            settings: settings
        };
    }

    public getSetting(): { groupId: string; settings: GroupLearningPackageEditSetting; } {
        return this.groupLearningPackageSetting;
    }
}
