import { Component } from '@angular/core';
import {AuthService} from '../../auth.service';

@Component({
  selector: 'app-missing-role',
  templateUrl: './missing-role.component.html',
  styleUrls: ['./missing-role.component.scss']
})
export class MissingRoleComponent {
    constructor(private authService: AuthService) {
    }

    public logout(): void {
        void this.authService.startLogoutFlow();
    }
}
