<app-popup-container>
    <app-popup-wizard-header header="Wähle eine Ressource" i18n-header></app-popup-wizard-header>

    <app-popup-content>
        <div class="mm-resource-list" *ngIf="!hasNoDocuments">
            <app-card type="flat" *ngFor="let document of documents$ | async"
                      class="mm-resource-list-card"
                      [title]="document.name"
                      [description]="document.numberOfSelections + ' Artikel'"
                      (cardClicked)="getResourceQuestions(document.id)"
                      icon="book-light"></app-card>
        </div>
        <div *ngIf="hasNoDocuments" class="mm-resource-empty-list" i18n>Das Lernpaket enthält keine verarbeiteten
            Ressourcen.
        </div>
    </app-popup-content>
</app-popup-container>
