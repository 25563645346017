<div class="micromate-error-box">
    <svg aria-hidden="true" focusable="false" viewBox="0 0 576 512"
         class="micromate-error-box-icon">
        <use href="./assets/icons.svg#circle-exclamation-light"></use>
    </svg>
    <div class="micromate-error-box-content">
        <div *ngIf="header !== undefined" class="micromate-error-box-title" [innerHTML]="header"></div>
        <div *ngIf="content !== undefined" class="micromate-error-box-content" [innerHTML]="content"></div>
        <ng-content></ng-content>
    </div>
</div>
