<h1 i18n class="mm-title">Import</h1>
<div class="mm-learning-package-import-cards">
    <app-card title="CSV-Datei"
              class="mm-learning-package-import-card"
              icon="file-spreadsheet-thin"
              (cardClicked)="importCSVFile()"></app-card>

    <app-card title="Lernpaket"
              class="mm-learning-package-import-card"
              icon="box-taped-thin"
              (cardClicked)="importLearningPackage()"></app-card>

    <app-card title="Ressource"
              class="mm-learning-package-import-card"
              *ngIf="(featuresService.hasFeatureAI() | async)"
              icon="book-light"
              [isAiFeature]="true"
              (cardClicked)="importRessource()"></app-card>
</div>

