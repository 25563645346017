<ng-container *ngIf="(navigationService.navigationMode$ | async) === 'with-navigation'">
    <div class="micromate-container"
         *ngIf="(authService.authentication$ | async) && (organizationService.hasOrganizationId$ | async)">
        <div class="micromate-headerbar"
             [class.micromate-headerbar-opacity]="(navigationService.mobileNavigationOpen$ | async)"
             [class.micromate-headerbar-organization-admin]="(navigationService.organizationAdminNavigation$ | async)">
            <a *ngIf="!mobileQuery.matches" [routerLink]="'/'" class="micromate-headerbar-logo-link">
                <img src="../assets/logo-without-text.svg" class="micromate-headerbar-logo" alt="Micromate Logo">
            </a>
            <app-breadcrumb class="micromate-headerbar-breadcrumb" *ngIf="!mobileQuery.matches"></app-breadcrumb>
        </div>
        <div *ngIf="mobileQuery.matches" class="micromate-breadcrumb-mobile-bar">
            <app-breadcrumb></app-breadcrumb>
        </div>
        <app-navigation></app-navigation>
        <div class="micromate-content">
            <app-page-navigation
                *ngIf="
            (navigationService.mobileNavigationDisplay$ | async) &&
            (authService.authentication$ | async) &&
            (navigationService.activeAppNavigationItem$ | async) !== undefined &&
            (navigationService.activeAppPageNavigation$ | async) !== undefined &&
            !(navigationService.editorNavigation$ | async) "
                class="micromate-app-page-navigation"></app-page-navigation>
            <app-editor-navigation
                *ngIf="
            (navigationService.mobileNavigationDisplay$ | async) &&
            (authService.authentication$ | async) &&
            (navigationService.activeAppNavigationItem$ | async) !== undefined &&
            (navigationService.activeAppPageNavigation$ | async) !== undefined &&
            (navigationService.editorNavigation$ | async) "
                class="micromate-app-page-navigation"></app-editor-navigation>
            <div class="micromate-content-container"
                 [class.micromate-content-container-loggIn]="
             (navigationService.mobileNavigationDisplay$ | async) &&
             (authService.authentication$ | async) &&
             (navigationService.activeAppNavigationItem$ | async) !== undefined &&
             (navigationService.activeAppPageNavigation$ | async) !== undefined">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="(navigationService.navigationMode$ | async) === 'no-navigation'">
    <router-outlet></router-outlet>
</ng-container>

<div *ngIf="(navigationService.mobileNavigationOpen$ | async)"
     class="micromate-container micromate-navigation-opacity"></div>

<app-popup></app-popup>
<app-overlay></app-overlay>
