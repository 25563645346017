<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Lernpaket mit Organisation verknüpfen" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content class="mm-learning-package-organization-connection-popup-content">
        <form [formGroup]="formData" id="ngFormOrganization"
              class="micormate-form mm-learning-package-organization-connection-popup-form"
              *ngIf="this.availableOrganizations !== undefined">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Organisation
                </label>
                <ng-multiselect-dropdown
                    [settings]="organizationsDropdownSettings"
                    [data]="allOrganizations"
                    formControlName="organizations"
                    [placeholder]="'Organisation auswählen'">
                </ng-multiselect-dropdown>
            </div>

        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>
                Abbrechen
            </app-popup-action-button>

            <app-popup-action-button
                type="primary"
                [action]="activateLearningPackageForOrganization"
                [disabled]="formData.value.organizations.length === 0" i18n>
                Speichern
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
