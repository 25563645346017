import {Component, OnDestroy, OnInit} from '@angular/core';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {LearningPackageVersion} from '../../core/api-types/learningPackageVersion';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {LearningPackage} from '../../core/api-types/learningPackage';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {QuizTypeName} from '../../core/editor/types/QuizType';

@Component({
    selector: 'app-learning-package-versions',
    templateUrl: './learning-package-versions.component.html',
    styleUrls: ['./learning-package-versions.component.scss']
})
export class LearningPackageVersionsComponent implements OnInit, OnDestroy {
    public learningPackageWorkingVersions: LearningPackageVersion;
    public learningPackageActiveVersions: LearningPackageVersion;
    public learningPackageVersions: LearningPackageVersion[];
    public learningPackageStatus: string;
    public contentCreators: { id: string; name: string; }[];
    public notPublishedLearningPackagesLanguages: string[];
    private selectedLearningPackageId: string;
    private selectedLearningPackageId$ = new Subscription();
    private learningPackages: LearningPackage[];
    private selectedLearningPackageStatusSubscription = new Subscription();

    constructor(public editorLearningPackageService: EditorLearningPackageService,
                private editorService: EditorResource,
                private router: Router,
                private multilanguageService: MultilanguageService) {
    }

    public ngOnInit(): void {
        this.selectedLearningPackageId$ = this.editorLearningPackageService.selectedLearningPackageId$.pipe(
            // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
            filter((selectedLearningPackageId: string) => !!selectedLearningPackageId),
            map((selectedLearningPackageId: string) => this.selectedLearningPackageId = selectedLearningPackageId),
            switchMap((selectedLearningPackageId: string) => this.editorService.getOneLearningPackage(selectedLearningPackageId))
        ).subscribe((learningPackages: LearningPackage[]) => {
            this.learningPackages = learningPackages;
            this.getMainLearningPackageInfo(learningPackages);
            this.editorService.getAllContentCreatorsForPackage(this.selectedLearningPackageId).subscribe(contentCreatorsData => this.contentCreators = contentCreatorsData);
            this.selectedLearningPackageStatusSubscription = this.editorLearningPackageService.selectedLearningPackageStatus$.subscribe((data: { status: string; }) => {
                if (data?.status !== undefined) {
                    this.learningPackageStatus = data.status;
                }
            });
        });
    }

    private getMainLearningPackageInfo(learningPackages: LearningPackage[]): void {
        const mainLearningPackage = learningPackages.find(learningPackage => learningPackage.mainLearningPackage);
        this.learningPackageWorkingVersions = mainLearningPackage.versions.find(version => !version.released);
        this.learningPackageActiveVersions = mainLearningPackage.versions.find(version => version.active);
        this.learningPackageVersions = mainLearningPackage.versions.filter(version => version.released && !version.active).sort((a, b) => b.version - a.version);
    }

    public ngOnDestroy(): void {
        this.selectedLearningPackageId$.unsubscribe();
        this.selectedLearningPackageStatusSubscription.unsubscribe();
    }

    public publishPackage: () => Promise<void> = async () => {
        const data: { publishedLearningPackages: string[]; } = await this.editorService.releaseLearningPackage(this.selectedLearningPackageId);
        this.checkPublishment(data);
        this.learningPackageStatus = 'published';
        this.editorLearningPackageService.selectedLearningPackageId.next(this.selectedLearningPackageId);
        void this.router.navigate(['editor', 'lernpakete', this.selectedLearningPackageId, 'versionen']);
    };

    public getContentCreator(contentCreatorId: string): string {
        if (this.contentCreators !== undefined) {
            const contentCreator = this.contentCreators.find(contentCreatorInfo => contentCreatorInfo.id === contentCreatorId);
            if (contentCreator !== undefined) {
                return contentCreator.name;
            }
        }
        return '';
    }

    private checkPublishment(data: { publishedLearningPackages: string[]; }): void {
        if (data.publishedLearningPackages.length !== this.learningPackages.length) {
            this.notPublishedLearningPackagesLanguages = this.learningPackages.filter(learningPackage => !data.publishedLearningPackages.includes(learningPackage._id)).map(learningPackage => {
                const languageData = learningPackage.language.split('-');
                const languages = this.multilanguageService.languagesList.filter(languageInfo => languageInfo.language === languageData[0]);
                if (languages.length > 1) {
                    const languageLocation = languages.find(language => language.local === languageData[1]);
                    return languageLocation !== undefined ? languageLocation.name : languages[0].name;
                }
                return languages[0].name;
            });
        }
    }

    public learningPackageIsNotValid(): boolean {
        return this.learningPackageStatus === 'published' ||
            this.getNumberOfStandardQuestions(this.learningPackageWorkingVersions) <= 5;
    }

    public getNumberOfStandardQuestions(learningPackageVersion: LearningPackageVersion): number {
        const standardQuizzes = learningPackageVersion?.quizzes.filter(quiz => quiz.quizType !== QuizTypeName.REFLECTION_FREE_TEXT_TYPE);
        return standardQuizzes?.length;
    }

}
