import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {EditorQuestionsService} from '../../../core/editor/editor-questions.service';
import {EditorQuestionService} from '../../../core/editor/editor-question.service';
import {Router} from '@angular/router';
import {EditorLearningPackageService} from '../../../core/editor/editor-learning-package.service';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {EditorQuizCreatorService} from '../../../core/editor/editor-quiz-creator.service';
import {EditorResource} from '../../../core/rest/editor-resource.service';
import {Subscription} from 'rxjs';
import {QuizTranslation} from '../../../core/api-types/quizTranslation';
import {QuestionType} from '../../../core/editor/question.type';

@Component({
    selector: 'app-quiz-edition',
    templateUrl: './quiz-edition.component.html',
    styleUrls: ['./quiz-edition.component.scss']
})
export class QuizEditionComponent implements OnInit, OnDestroy {
    @Input() queryParams: { filterText: string; sort: string; filterLanguage?: string; };

    public quiz: QuizTranslation[];
    public quizStandardTypes: QuestionType[];
    public quizReflectionTypes: QuestionType[];
    public activeQuizSubscription = new Subscription();

    public selectedLearningPackageId: string;
    public openQuizOptionDropDown: boolean = false;

    public formData = new UntypedFormGroup({
        quizJson: new UntypedFormControl('', [Validators.required])
    });

    constructor(public editorQuizzesService: EditorQuestionsService,
                private router: Router,
                public editorQuizService: EditorQuestionService,
                private editorLearningPackageService: EditorLearningPackageService,
                private editorQuizCreatorService: EditorQuizCreatorService,
                private editorService: EditorResource) {
    }

    public ngOnInit(): void {
        this.selectedLearningPackageId = this.editorLearningPackageService.selectedLearningPackageId.value;
        this.quizStandardTypes = this.editorQuizService.quizTypes.filter(question => question.questionType === 'standard');
        this.quizReflectionTypes = this.editorQuizService.quizTypes.filter(question => question.questionType === 'reflection');
        this.activeQuizSubscription = this.editorQuizService.activeQuiz$.subscribe((quiz: QuizTranslation[]) => {
            if (quiz !== undefined) {
                this.quiz = quiz;
            }
        });
    }

    public ngOnDestroy(): void {
        this.activeQuizSubscription.unsubscribe();
    }

    public createNewQuiz(quizType: string): void {
        this.editorService.createNewQuiz(this.selectedLearningPackageId, quizType).subscribe((quiz: QuizTranslation) => {
            if (quiz !== undefined) {
                this.editorQuizService.newQuiz$.next(quiz);
            }
        });
    }

    public openQuizzesOverview(): void {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.editorLearningPackageService.selectedLearningPackageId.subscribe((learningPackageId: string) => this.router.navigate([`/editor/lernpakete/${learningPackageId}/fragen`]));
    }
}
