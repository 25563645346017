import {QuizEditor} from '../QuizEditor';
import {QuizQuestionForm} from '../QuizQuestionForm';
import {SelectedTag} from '../../../api-types/SelectedTag';
import {SelectedNewTag} from '../../../api-types/SelectedNewTag';

export class ReflectionFreeTextQuestionEditor implements QuizEditor {
    constructor(public question: QuizQuestionForm,
                public selectedAvailableTags: SelectedTag[],
                public selectedNewTags: SelectedNewTag[]) {
    }
}
