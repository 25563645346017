export enum LanguageModel {
    OpenAI = 'OpenAI',
    AzureOpenAI = 'AzureOpenAI',
}

export class OrganizationAICredential {
    constructor(public languageModel: LanguageModel,
                public apiKey: string,
                public apiUrl?: string,
                public completionModelDeploymentName?: string,
                public embeddingModelDeploymentName?: string) {
    }
}
