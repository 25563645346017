import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {ListPaginationRequest} from '../../core/api-types/listPaginationRequest';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
    selector: 'app-table-search',
    templateUrl: './table-search.component.html',
    styleUrls: ['./table-search.component.scss']
})
export class TableSearchComponent implements OnInit {
    @Input() paginationParams: ListPaginationRequest;
    @Input() navigateUrl: string[];
    @Input() totalDataAmount?: number;

    public filter: UntypedFormControl = new UntypedFormControl('');

    constructor(private router: Router) {
    }

    public ngOnInit(): void {
        this.filter.valueChanges.pipe(
            debounceTime(300),
            distinctUntilChanged())
            .subscribe((filterValue: string) => this.redirectWithFilter(filterValue, filterValue.length > 0));
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (changes['paginationParams']) {
            this.filter.setValue(this.paginationParams.filter);
        }
    }

    public redirectWithFilter(filter: string, redirectToFirstPaginationPage: boolean = false): void {
        const queryParams = {
            filter: filter
        };
        if (redirectToFirstPaginationPage) {
            queryParams['pageNumber'] = 1;
            queryParams['pageAmount'] = this.paginationParams.pageAmount;
        }
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        this.router.navigate(this.navigateUrl, {
            queryParams: queryParams,
            queryParamsHandling: 'merge'
        });
    }

}
