import {Component} from '@angular/core';
import {NewResourcePopupService} from '../new-resource-popup.service';

@Component({
    selector: 'app-import-new-resource-popup-page',
    templateUrl: './import-new-resource-popup-page.component.html',
    styleUrls: ['./import-new-resource-popup-page.component.scss']
})
export class ImportNewResourcePopupPageComponent {
    public invalidFile: boolean = false;
    public invalidFileType: boolean = false;
    public invalidFileToBig: boolean = false;
    public invalidFileToManyFiles: boolean = false;

    constructor(private resourceWizardService: NewResourcePopupService) {
    }

    public sendFile(files: FileList): void {
        this.clearPreviousImport();
        if (files.length > 1) {
            this.invalidFile = true;
            this.invalidFileToManyFiles = true;
        } else {
            const file = files.item(0);
            this.resourceWizardService.showSpinner();
            this.validateFile(file);
            if (this.invalidFileType || this.invalidFileToBig) {
                this.invalidFile = true;
                this.resourceWizardService.hideSpinner();
            } else {
                this.importHTML(file);
            }
        }
    }

    private validateFile(file: File): void {
        this.invalidFileType = file.type !== 'text/html' && file.type !== 'application/pdf';
        const sizeInMB = file.size / (1024 * 1024);
        this.invalidFileToBig = sizeInMB > 30;
    }

    private importHTML(file: File): void {
        this.invalidFile = false;
        this.resourceWizardService.setResourceFile(file);
        this.resourceWizardService.hideSpinner();
        this.resourceWizardService.currentStep = 'settings';
    }

    private clearPreviousImport(): void {
        this.invalidFile = false;
        this.invalidFileType = false;
        this.invalidFileToManyFiles = false;
        this.invalidFileToBig = false;
    }

}
