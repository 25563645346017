<div class="micormate-form-element">
    <div class="micromate-learning-goals">
        <div [class.micormate-form-label]="form"
             [class.micormate-micromate-learning-goals-label-form]="form"
             [class.micormate-micromate-learning-goals-label]="!form" i18n>
            Lernziel:
        </div>
        <div class="micromate-organization-info-data">
            <app-switch-toggle [formControl]="addLearningGoal"
                               (change)="activateLearningGoals()"></app-switch-toggle>
        </div>
    </div>

    <div class="micromate-learning-goals-slider" *ngIf="addLearningGoal.value">
        <div class="micromate-learning-goals-slider-start">
            <div
                class="micromate-learning-goals-slider-start-element micromate-learning-goals-slider-start-element-first">
            </div>
            <div class="micromate-learning-goals-slider-start-element">
            </div>
            <div class="micromate-learning-goals-slider-start-element">
            </div>
        </div>
        <div *ngFor="let goal of learningGoalsRange, let i = index"
             class="micromate-learning-goals-slider-block">
            <div class="micromate-learning-goals-slider-block-value">
                {{goal.value}}%
            </div>
            <div class="micromate-learning-goals-slider-block-bar"
                 [class.micromate-learning-goals-slider-block-bar-last]="i === learningGoalsRange.length-1"
                 [class.micromate-learning-goals-slider-block-bar-active]="(learningGoal | async) === goal.value"
                 [ngStyle]="{'background-color': (learningGoal | async) === goal.value? '#FFCE4A': 'rgba(115, 171, 87,'+ goal.opacity +')'}"
                 (click)="setLearningGoal(goal.value)">
                <div class="micromate-learning-goals-slider-block-bar-title">
                    {{goal.legend}}
                </div>
            </div>
        </div>
    </div>
</div>
