<div class="micromate-card">
    <div class="micromate-card-header">
        <h1 i18n>Organisationen</h1>
        <div class="micromate-button">
            <app-button type="primary"
                        [icon]="'plus-light'"
                        (click)="openNewOrganizationForm()" i18n>Neue Organisation
            </app-button>
        </div>
    </div>

    <div *ngIf="listPagination !== undefined">
        <app-table-search [paginationParams]="paginationParams" [navigateUrl]="navigationUrl"
                          [totalDataAmount]="listPagination?.totalDataAmount"></app-table-search>
        <div class="micromate-table">
            <div class="table-container table-container-header">
                <ng-container>
                    <div class="row-header" i18n>Name</div>
                    <div class="row-header" i18n>Organisationscode</div>
                    <!-- <div class="row-header" i18n>Supervisor</div>
                     <div class="row-header" i18n>Contentersteller</div>!-->
                    <div class="row-header" i18n>Erstellungsdatum</div>
                    <div class="row-header" i18n></div>
                </ng-container>
            </div>
            <div class="table-container-data" *ngIf="organizations.length > 0; else emptyTable" #tableContentRef>
                <div class="table-container table-content" *ngFor="let organization of organizations">
                    <div class="row row-name">
                        <div class="row-sub micromate-table-link">
                            {{organization.name}}
                        </div>
                        <div class="row-only-mobile-email">
                            {{organization.code}}
                        </div>
                    </div>
                    <div class="row row-only-web">{{organization.code}}</div>
                    <!--<div class="row group-info">
                        <div class="micromate-group-button micromate-group-button-not-active">
                            <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                                <use href="./assets/icons.svg#user-cog-light"></use>
                            </svg>
                            {{organization.supervisorIds.length}}
                        </div>
                    </div>

                    <div class="row group-info">
                        <div class="micromate-group-button micromate-group-button-not-active">
                            <svg class="micromate-group-form-icon" viewBox="0 0 640 512">
                                <use href="./assets/icons.svg#user-edit-light"></use>
                            </svg>
                            {{organization.contentCreatorIds.length}}
                        </div>
                    </div>!-->
                    <div class="row row-only-web">{{organization.creationDate | date:'short'}}</div>
                    <div class="row group-info">
                        <div class="micromate-group-button"
                             (click)="setActiveOrganization(organization.zitadelId)">
                            <app-icon icon="repeat-light" class="micromate-group-form-app-icon"></app-icon>
                        </div>
                        <div class="micromate-group-button"
                             (click)="redirectToOrganizationCoinfig(organization._id)">
                            <app-icon icon="cog-light" class="micromate-group-form-app-icon"></app-icon>
                        </div>
                    </div>
                </div>
            </div>
            <ng-template #emptyTable>
                <div class="table-empty-filter-results" i18n>
                    keine Suchresultate
                </div>
            </ng-template>
        </div>
        <app-table-pagination [paginationParams]="paginationParams"
                              [totalDataAmount]="listPagination.totalDataAmount"
                              [navigateUrl]="navigationUrl"></app-table-pagination>
    </div>
</div>
