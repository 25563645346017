import {AbstractControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

export class FormCustomValidation {

    public static trimmedMaxLengthValidator(maxLength: number): ValidatorFn {
        return (control: AbstractControl<string>): ValidationErrors | null => {
            const trimmedValue = control.value.length > 0 ? control.value.trim().replace(/\s+/g, ' ').toLowerCase() : '';

            if (trimmedValue.length > maxLength) {
                return {trimmedMaxLength: {max: maxLength, actual: trimmedValue.length}};
            }
            // eslint-disable-next-line no-null/no-null
            return null;
        };
    }

    public static trimmedRequired(control: AbstractControl<string>): ValidationErrors | null {
        const value = control.value;
        // eslint-disable-next-line no-null/no-null
        const trimmedValue = value !== undefined && value !== null ? value.trim() : undefined;

        // eslint-disable-next-line no-null/no-null
        if (Validators.required(control) !== null || trimmedValue === undefined || trimmedValue.length === 0) {
            return {required: true};
        }
        // eslint-disable-next-line no-null/no-null
        return null;
    }
}
