<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Neue Lerngruppe" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <form [formGroup]="formData" class="micormate-form">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Lerngruppenname
                    <span class="micormate-form-label-required">*</span>
                </label>
                <input type="text" formControlName="name" class="micormate-form-input"
                       [class.micormate-form-input-validation-error]="formData.controls.name.errors?.trimmedMaxLength || nameInvalid">
                <div *ngIf="formData.controls.name.errors?.trimmedMaxLength" class="micormate-form-validation-error" i18n>
                    Maximal 50
                    Zeichen
                </div>
                <div *ngIf="nameInvalid" class="micormate-form-validation-error" i18n>Es existiert bereits eine
                    Lerngruppe mit diesem Namen
                </div>
            </div>
        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>
                Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [disabled]="formData.invalid"
                [action]="createNewGroup" i18n>
                Einfügen
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>



