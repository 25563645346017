<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Neue Organisation" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <form [formGroup]="formData" class="micormate-form">
            <div class="micormate-form-element">
                <label class="micormate-form-label" i18n>Organisationsname
                    <span class="micormate-form-label-required">*</span>
                </label>
                <input type="text" formControlName="name"
                       class="micormate-form-input micormate-form-input-organization-name"
                       [class.micormate-form-input-validation-error]="formData.controls.name.errors?.trimmedMaxLength">
                <div *ngIf="formData.controls.name.errors?.trimmedMaxLength" class="micormate-form-validation-error"
                     i18n>Maximal 50
                    Zeichen
                </div>
                <div *ngIf="!isNameValid" class="micormate-form-validation-error" i18n>Eine Organisation mit dem Namen
                    existiert bereits
                </div>
            </div>

            <div class="micormate-form-element mm-organization-form-toggle">
                <div class="micormate-form-label" i18n>
                    Demolernpaket hinzufügen:
                </div>
                <app-switch-toggle formControlName="createDemoLearningPackage"></app-switch-toggle>
            </div>
        </form>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>
                Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                type="primary"
                [action]="saveOrganization"
                [disabled]="!formData.valid"
                i18n>Speichern
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
