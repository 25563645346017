<app-popup-container>
    <app-popup-header>
        <app-popup-header-title title="Frage aus Text" i18n-title></app-popup-header-title>
    </app-popup-header>

    <app-popup-content>
        <form [formGroup]="pasteForm" id="pasteForm">
        <textarea formControlName="text" placeholder="Füge Text ein..."
                  class="micormate-form-textarea mm-paste-area"
                  [class.mm-paste-area-small]="hasError"></textarea>
            <div class="micormate-form-validation-error">
                <ng-container *ngIf="pasteForm.controls['text'].errors?.minlength">Text muss mindestens 100 Zeichen lang
                    sein.
                </ng-container>
                <ng-container *ngIf="pasteForm.controls['text'].errors?.maxlength">Text darf maximal 2'000 Zeichen lang
                    sein.
                </ng-container>
            </div>
        </form>

        <div class="mm-question-types">
            <app-card *ngFor="let questionType of possibleQuestionTypes"
                      class="mm-question-type"
                      type="custom"
                      [title]="questionType.title"
                      (cardClicked)="selectQuestionType(questionType)"
                      [isSelected]="selectedQuestionType === questionType"
                      [icon]="questionType.icon"></app-card>
        </div>

        <app-error-box
            class="mm-paste-text-popup-error-box"
            *ngIf="hasError"
            header="Frage konnte nicht generiert werden." i18n-header
            content="Versuche es erneut oder mit anderem Text." i18n-content></app-error-box>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button
                (click)="cancel()"
                type="secondary" i18n>
                Abbrechen
            </app-popup-action-button>
            <app-popup-action-button
                [action]="paste"
                type="primary"
                [disabled]="pasteForm.invalid" i18n>
                Einfügen
            </app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>


