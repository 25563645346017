<div class="micromate-question-preview-media" *ngIf="mediaData !== undefined">
    <div class="micromate-question-preview-media-element"
         *ngIf="mediaData.imageUrl !== undefined && mediaData.imageUrl.length > 0">
        <img [src]="mediaData.imageUrl" class="micromate-question-preview-media-image">
        <app-media-type-icon *ngIf="mediaData.mediaUrl === undefined || mediaData.mediaUrl.length === 0"
                             [mediaType]="mediaData.mediaType"></app-media-type-icon>
    </div>

    <div class="micromate-question-preview-media-element-media-block"
         [class.micromate-question-preview-media-element-media-block-valid]="invalidMedia === false"
         *ngIf="mediaData.mediaUrl !== undefined && mediaData.mediaUrl.length > 0">
        <div *ngIf="invalidMedia === false"
             class="micromate-question-preview-media-element-media"
             [class.micromate-question-preview-media-element-media-iframe]="(mediaData.mediaType === 'movie' && videoLink.display === 'iframe') || mediaData.mediaType === 'website'">
            <ng-container
                *ngIf="mediaData.mediaType === 'movie'">
                <video *ngIf="videoLink.display === 'video'" controls
                       class="micromate-question-preview-media-element-audio-and-video">
                    <source [src]="mediaData.mediaUrl">
                    Ihr Browser unterstützt kein HTML-Video.
                </video>
                <app-iframe-media
                    *ngIf="videoLink.display === 'iframe'"
                    class="micromate-question-preview-media-element-audio-and-video"
                    [mediaLink]="mediaData.mediaUrl"></app-iframe-media>
            </ng-container>

            <audio controls class="micormate-popup-content-media-audio"
                   *ngIf="mediaData.mediaType === 'audio'">
                <source [src]="mediaData.mediaUrl">
                Ihr Browser unterstützt kein HTML-Audio.
            </audio>
            <app-iframe-media *ngIf="mediaData.mediaType === 'website'"
                              [mediaLink]="mediaData.mediaUrl"
                              class="micromate-question-preview-media-element-audio-and-video"></app-iframe-media>
        </div>
        <div *ngIf="invalidMedia"
             class="micormate-popup-content-media-error">
            <div *ngIf="mediaData.mediaType === 'movie'"
                 class="micormate-popup-content-media-invalid" i18n>
                Es ist ein Fehler aufgetreten. Das Video ist derzeit nicht verfügbar.
            </div>
            <div *ngIf="mediaData.mediaType === 'audio'"
                 class="micormate-popup-content-media-invalid" i18n>
                Es ist ein Fehler aufgetreten. Audio ist derzeit nicht verfügbar.
            </div>
            <div *ngIf="mediaData.mediaType === 'website'"
                 class="micormate-popup-content-media-invalid" i18n>
                Es ist ein Fehler aufgetreten. Website ist derzeit nicht verfügbar.
            </div>
        </div>
        <app-media-type-icon [mediaType]="mediaData.mediaType"></app-media-type-icon>
    </div>
</div>
