import {User} from '../api-types/user';

export class ZitadelUserMapper {

    private static readonly NOT_ACCEPTED_USER_ROLE = 'LEARNER';

    public static map(zitadelUser: object): User {
        // We get null values from zitadel for empty fields, we do want to remove them
        // eslint-disable-next-line no-null/no-null
        const entries = Object.entries(zitadelUser).filter(([, value]) => value !== null);
        const user = Object.fromEntries(entries) as User;

        const roles = user['urn:zitadel:iam:org:project:roles'] as Map<string, Map<string, string>>;
        user.organisations = ZitadelUserMapper.getOrganizationsRoles(roles);
        user.roles = Object.keys(roles).filter(role => role !== ZitadelUserMapper.NOT_ACCEPTED_USER_ROLE);

        return user;
    }

    private static getOrganizationsRoles(roles: Map<string, Map<string, string>>): { [organization: string]: string[]; } {
        const organizationsRoles: { [organization: string]: string[]; } = {};

        for (const [key, value] of Object.entries(roles)) {
            if (key !== ZitadelUserMapper.NOT_ACCEPTED_USER_ROLE) {
                for (const data of Object.keys(value as Map<string, string>)) {
                    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                    if (organizationsRoles[data]) {
                        organizationsRoles[data].push(key);
                    } else {
                        organizationsRoles[data] = [key];
                    }
                }
            }
        }
        return organizationsRoles;
    }

}
