import {Injectable} from '@angular/core';
import {ListPaginationRequest} from '../api-types/listPaginationRequest';
import {Observable} from 'rxjs';
import {ListPagination} from '../api-types/listPagination';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {ApiHistory} from '../api-types/apiHistory';
import {ApiHistoryRecord} from '../api-types/apiHistoryRecord';
import {ApiRequest} from '../api-types/apiRequest';
import {ApiResponse} from '../api-types/apiResponse';

@Injectable({
    providedIn: 'root'
})
export class ApiHistoryService {

    constructor(private http: HttpClient) {
    }

    public getHistory(listPaginationRequest: ListPaginationRequest): Observable<ListPagination<ApiHistory>> {
        return this.http.get<ListPagination<ApiHistory>>(`${environment.serverUrl}/api/website/admin/api-history?filter=${listPaginationRequest.filter}&pageNumber=${listPaginationRequest.pageNumber}&pageAmount=${listPaginationRequest.pageAmount}&errorApis=${listPaginationRequest.errorApis}`);
    }

    public getApiInfo(apiId: string): Observable<{ request: ApiHistoryRecord<ApiRequest>; response: ApiHistoryRecord<ApiResponse>; }> {
        return this.http.get<{ request: ApiHistoryRecord<ApiRequest>; response: ApiHistoryRecord<ApiResponse>; }>(`${environment.serverUrl}/api/website/admin/api-history/${apiId}`);
    }

    public getNumberOfCalls(): Observable<{ value: number; }> {
        return this.http.get<{ value: number; }>(`${environment.serverUrl}/api/website/admin/api-history/number-of-calls`);
    }
}
