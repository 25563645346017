import {ChangeDetectorRef, Component, HostListener, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {MediaMatcher} from '@angular/cdk/layout';
import {NavigationService} from './core/navigation.service';
import {Router} from '@angular/router';
import {mobileMediaMatch} from './global-variables';
import {EditorLearningPackageService} from './core/editor/editor-learning-package.service';
import {GroupService} from './core/group.service';
import {DragAndDropService} from './core/drag-and-drop/drag-and-drop.service';
import {OrganizationService} from './core/organization.service';
import {OrganizationAdminService} from './core/admin/organization-admin.service';
import {LearningPackageService} from './core/learning-package.service';
import {EditorQuestionService} from './core/editor/editor-question.service';
import {ApiHistoryInfoService} from './core/admin/api-history-info.service';
import {LearnerInfoAdminService} from './core/admin/learner-info-admin.service';
import {AuthService} from './core/authentication/auth.service';
import {AuthUntilUserRoleService} from './core/authentication/auth-until-user-role.service';
import {OrganizationLearnerInfoAdminService} from './app-organisation/organization-learner-info/organization-learner-info-admin.service';

@Component({
    selector: 'micromate-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public mobileQuery: MediaQueryList;
    private mobileQueryListener: () => void;

    private activeLearningPackage$;
    private selectedQuizId$;
    private activeGroup$;
    private learningPackage$;
    public organizationService$;
    private organizationAdminService$;
    private learnerAdminService$;
    private apiHistory$;
    public userRoleService$;
    public organizationLearnerInfoAdminService$;

    private dragCounter = 0;

    constructor(@Inject(DOCUMENT) private doc: Document,
                public changeDetectorRef: ChangeDetectorRef,
                public media: MediaMatcher,
                public navigationService: NavigationService,
                public router: Router,
                private editorLearningPackageService: EditorLearningPackageService,
                private editorQuizService: EditorQuestionService,
                private groupService: GroupService,
                private learningPackageService: LearningPackageService,
                private dragAndDropService: DragAndDropService,
                public organizationService: OrganizationService,
                public authService: AuthService,
                public userRoleService: AuthUntilUserRoleService,
                private organizationAdminService: OrganizationAdminService,
                private apiHistoryInfoService: ApiHistoryInfoService,
                private learnerInfoAdminService: LearnerInfoAdminService,
                private organizationLearnerInfoAdminService: OrganizationLearnerInfoAdminService) {
        this.mobileQuery = media.matchMedia(mobileMediaMatch);
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        // IE and Edge support only this! Also not deprecated according to https://github.com/microsoft/TypeScript/issues/32210
        this.mobileQuery.addListener(this.mobileQueryListener);
    }

    public ngOnInit(): void {
        this.activeLearningPackage$ = this.editorLearningPackageService.activeLearningPackage$;
        this.selectedQuizId$ = this.editorQuizService.selectedQuizId$;
        this.activeGroup$ = this.groupService.activeGroup$;
        this.organizationService$ = this.organizationService.activeOrganizationId$;
        this.organizationAdminService$ = this.organizationAdminService.selectedOrganization$;
        this.learnerAdminService$ = this.learnerInfoAdminService.selectedLearner$;
        this.learningPackage$ = this.learningPackageService.selectedLearningPackageId$;
        this.apiHistory$ = this.apiHistoryInfoService.selectedApi$;
        this.organizationLearnerInfoAdminService$ = this.organizationLearnerInfoAdminService.selectedLearnerId$;
    }

    @HostListener('window:dragenter', ['$event'])
    public onDragEnter(): void {
        this.dragAndDropService.setDragCounter(this.dragAndDropService.getDragCounter() + 1);
        if (this.dragAndDropService.getDragCounter() > 2) {
            this.dragAndDropService.setDragCounter(2);
        }
        this.dragAndDropService.set(true);
    }

    @HostListener('window:dragleave', ['$event'])
    public onDragLeave(): void {
        this.dragAndDropService.setDragCounter(this.dragAndDropService.getDragCounter() - 1);
        if (this.dragCounter === this.dragAndDropService.getDragCounter()) {
            this.dragAndDropService.set(false);
        }
    }

    @HostListener('window:drop', ['$event'])
    public onDrop(): void {
        this.dragAndDropService.setDragCounter(0);
        this.dragAndDropService.set(false);
    }

    @HostListener('window:dragover', ['$event'])
    public onDragOver(evt: DragEvent): void {
        evt.dataTransfer.effectAllowed = 'none';
        evt.dataTransfer.dropEffect = 'none';
        evt.preventDefault();
    }
}
