import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';
import {ValidationError} from './core/api-types/ValidationError';
import {ValidationErrorItem} from './core/api-types/ValidationErrorItem';

@Injectable()
export class ValidationHttpInterceptor implements HttpInterceptor {

    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 422) {
                    const errorData = JSON.parse(error.error as string) as { validationErrors: ValidationErrorItem[]; };
                    return throwError(() => new ValidationError(errorData.validationErrors));
                }

                return throwError(() => error);
            })
        );
    }

}
