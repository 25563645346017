import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-learning-goals-slider',
    templateUrl: './learning-goals-slider.component.html',
    styleUrls: ['./learning-goals-slider.component.scss']
})
export class LearningGoalsSliderComponent implements OnInit {
    @Input()
    public startLearningGoal: number;

    @Input()
    public form: boolean = false;

    @Output()
    public newLearningGoal = new EventEmitter<number>();

    public learningGoal: BehaviorSubject<number> = new BehaviorSubject(70);
    public learningGoal$ = this.learningGoal.asObservable();

    public addLearningGoal = new FormControl<boolean>(false);
    public learningGoalsRange = [
        {
            value: 40,
            legend: 'Anfänger',
            opacity: 0.5
        },
        {
            value: 55,
            legend: 'Fortgeschrittener',
            opacity: 0.6
        },
        {
            value: 70,
            legend: 'Könner',
            opacity: 0.7
        },
        {
            value: 80,
            legend: 'Profi',
            opacity: 0.9
        },
        {
            value: 90,
            legend: 'Experte',
            opacity: 1
        }
    ];

    public ngOnInit(): void {
        if (this.startLearningGoal !== undefined) {
            this.addLearningGoal.setValue(true);
            this.learningGoal.next(this.startLearningGoal);
        }
    }

    public setLearningGoal(learningGoal: number): void {
        this.learningGoal.next(learningGoal);
        this.newLearningGoal.emit(this.learningGoal.getValue());
    }

    public activateLearningGoals(): void {
        this.learningGoal.next(!this.addLearningGoal.value ? undefined : 70);
        this.newLearningGoal.emit(this.learningGoal.getValue());
    }
}
