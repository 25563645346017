import {Component, EventEmitter, Input, Output} from '@angular/core';
import {QuizMediaEditor} from '../../../../core/editor/types/QuizMediaEditor';

@Component({
    selector: 'app-micromate-editor-image-drop-down',
    templateUrl: './micromate-editor-image-drop-down.component.html',
    styleUrls: ['./micromate-editor-image-drop-down.component.scss']
})
export class MicromateEditorImageDropDownComponent {
    @Input()
    public media: QuizMediaEditor;

    @Input()
    public fileDrop: boolean;

    @Output()
    public moveMedia: EventEmitter<void> = new EventEmitter<void>();

    @Output()
    public openImageAndMediaPopup: EventEmitter<void> = new EventEmitter<void>();

    @Input()
    public disabled: boolean;
}
