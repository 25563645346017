<div class="micormate-form-element micoromate-tag-activation-manual">
    <div [class.micormate-form-label]="form"
         [class.micoromate-tag-activation-manual-label-form]="form"
         [class.micoromate-tag-activation-manual-label]="!form" i18n>
        Manuelle Aktivierung:
    </div>
    <app-switch-toggle [formControl]="manualActivationForm"
                       (change)="manualActivationChanged()"></app-switch-toggle>
</div>

<ng-content></ng-content>

<div *ngIf="manualActivationForm.getRawValue() && displayManualActivationTags.length > 0" class="micormate-tags-activation">
    <div class="micormate-tag-activation">
        <div class="micormate-form-label micormate-tag-activation-label" i18n>Fragen ohne Tag</div>
        <div class="micormate-tag-activation-states">
            <div></div>
            <div
                class="micormate-tag-activation-state micormate-tag-activation-state-active micormate-tag-activation-state-marked"
                i18n>
                aktiviert
            </div>
            <div></div>
        </div>
    </div>
    <div class="micormate-tag-activation" *ngFor="let manualActivationTag of displayManualActivationTags">
        <div class="micormate-form-label micormate-tag-activation-label">{{manualActivationTag.label}}</div>
        <div class="micormate-tag-activation-states">
            <div class="micormate-tag-activation-state micormate-tag-activation-state-inactive"
                 [class.micormate-tag-activation-state-marked]="manualActivationTag.status === 'inactive'"
                 (click)="changeState(manualActivationTag, 'inactive')"
                 i18n>
                inaktiv
            </div>
            <div class="micormate-tag-activation-state micormate-tag-activation-state-active"
                 [class.micormate-tag-activation-state-marked]="manualActivationTag.status === 'active'"
                 (click)="changeState(manualActivationTag, 'active')"
                 i18n>
                aktiviert
            </div>
            <div class="micormate-tag-activation-state micormate-tag-activation-state-individual "
                 [class.micormate-tag-activation-state-marked]="manualActivationTag.status === 'individual'"
                 (click)="changeState(manualActivationTag, 'individual')"
                 i18n>
                individuelle Aktivierung
            </div>
        </div>
    </div>
</div>

<app-info-box *ngIf="manualActivationForm.getRawValue() && displayManualActivationTags.length === 0"
              content="Das Lernpaket beinhaltet keine Aktivierungstags." i18n-content></app-info-box>
