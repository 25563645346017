import {Component, Input} from '@angular/core';
import {Quiz} from '../../../../../../../../core/api-types/quiz';

@Component({
    selector: 'app-single-choice-question',
    templateUrl: './single-choice-question.component.html',
    styleUrls: ['./single-choice-question.component.scss']
})
export class SingleChoiceQuestionComponent {
    @Input()
    public questionPreview: Quiz;
}
