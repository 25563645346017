import {Pipe, PipeTransform} from '@angular/core';
import {marked} from 'marked';

@Pipe({
    name: 'markdown'
})
export class MarkdownPipe implements PipeTransform {

    private readonly renderer: marked.Renderer;

    constructor() {
        this.renderer = new marked.Renderer();
        this.renderer.link = (href: string, title: string, text: string): string => {
            return `<a target="_blank" href="${href}">${text}</a>`;
        };

        marked.setOptions({
            renderer: this.renderer,
            mangle: false,
            headerIds: false
        });
    }

    public transform(value: string): string {
        if (value !== undefined && value.length > 0) {
            return marked(value);
        }
        return value;
    }
}
