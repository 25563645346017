<app-popup-container>
    <app-popup-content>
        <div class="mm-confirmation-popup-text" [innerHTML]="data.text"></div>
    </app-popup-content>

    <app-popup-footer>
        <app-popup-footer-buttons>
            <app-popup-action-button (click)="cancel()" type="secondary">{{data.cancelButton}}</app-popup-action-button>
            <app-popup-action-button [action]="confirm" type="primary">{{data.confirmationButton}}</app-popup-action-button>
        </app-popup-footer-buttons>
    </app-popup-footer>
</app-popup-container>
