import {ErrorHandler, Injectable} from '@angular/core';
import {FrontendErrorHandler} from './frontendErrorHandler';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private frontendErrorHandler: FrontendErrorHandler) {
    }

    public async handleError(error: undefined): Promise<void> {
        await this.frontendErrorHandler.handleError(error);
    }
}
