import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ResourceHelper} from './resource-helper.service';
import {firstValueFrom, switchMap} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BreadCrumbsResource {

    constructor(private http: HttpClient, private restHelperService: ResourceHelper) {
    }

    public async getLearningPackageName(learningPackageId: string): Promise<{ name: string; }> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ name: string; }>(`${url}/breadcrumb/name/learningpackage/${learningPackageId}`))));
    }

    public async getGroupName(groupId: string): Promise<{ name: string; }> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ name: string; }>(`${url}/breadcrumb/name/group/${groupId}`))));
    }

    public async getOrganizationName(organizationId: string): Promise<{ name: string; }> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ name: string; }>(`${url}/breadcrumb/name/organization/${organizationId}`))));
    }

    public async getOrganizationTeamsName(organizationId: string): Promise<{ name: string; }> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ name: string; }>(`${url}/breadcrumb/name/organization/organizationZitadelId/${organizationId}`))));
    }

    public async getLearnerName(learningPackageId: string): Promise<{ name: string; }> {
        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ name: string; }>(`${url}/breadcrumb/name/learner/${learningPackageId}`))));
    }

    public async getOrganizationLearnerName(learningPackageId: string): Promise<{ name: string; }> {

        return firstValueFrom(this.restHelperService.activeOrganizationUrl$.pipe(switchMap(url => this.http.get<{ name: string; }>(`${url}/breadcrumb/name/organization/learner/${learningPackageId}`))));
    }
}
