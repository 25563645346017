import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {NavigationService} from '../../core/navigation.service';
import {Router} from '@angular/router';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {EditorQuestionService} from '../../core/editor/editor-question.service';
import {EditorQuestionsService} from '../../core/editor/editor-questions.service';
import {mobileMediaMatch} from '../../global-variables';
import {MediaMatcher} from '@angular/cdk/layout';
import {Subscription} from 'rxjs';
import {MultilanguageService} from '../../core/editor/multilanguage.service';
import {LearningPackageQuizzesOverview} from '../../core/api-types/learningPackageQuizzesOverview';
import {AppPageNavigationItem} from '../../core/api-types/appPageNavigationItem';
import {FeaturesService} from '../../core/features/features.service';
import {AuthUntilUserRoleService} from '../../core/authentication/auth-until-user-role.service';

@Component({
    selector: 'app-editor-navigation',
    templateUrl: './editor-navigation.component.html',
    styleUrls: ['./editor-navigation.component.scss']
})
export class EditorNavigationComponent implements OnInit, OnDestroy {
    public learningPackageStatus: string;
    public learningPackageStatusText: string;
    public activeLearningPackage: LearningPackageQuizzesOverview;
    public mobileQuery: MediaQueryList;
    public editorLearningPackageNavigation: AppPageNavigationItem[];

    private readonly mobileQueryListener: () => void;
    private selectedLearningPackageStatusSubscription = new Subscription();
    private userRoleService$ = new Subscription();

    constructor(public navigationService: NavigationService,
                public router: Router,
                public editorLearningPackageService: EditorLearningPackageService,
                public editorQuizService: EditorQuestionService,
                public editorQuizzesService: EditorQuestionsService,
                public media: MediaMatcher,
                public changeDetectorRef: ChangeDetectorRef,
                public multilanguageService: MultilanguageService,
                public userRoleService: AuthUntilUserRoleService,
                public featuresService: FeaturesService) {
        this.mobileQuery = media.matchMedia(mobileMediaMatch);
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        // IE and Edge support only this! Also not deprecated according to https://github.com/microsoft/TypeScript/issues/32210
        this.mobileQuery.addListener(this.mobileQueryListener);
    }

    public ngOnInit(): void {
        this.assertRolesToEditorNavigation();
        this.editorLearningPackageService.activeLearningPackage$.subscribe((activeLearningPackages: LearningPackageQuizzesOverview[]) => {
            if (activeLearningPackages !== undefined && activeLearningPackages.length > 0) {
                this.activeLearningPackage = activeLearningPackages.find(activeLearningPackage => activeLearningPackage.mainLearningPackage);
            }
        });
        this.selectedLearningPackageStatusSubscription = this.editorLearningPackageService.selectedLearningPackageStatus$.subscribe((data: { status: string; }) => {
            if (data?.status !== undefined) {
                this.learningPackageStatus = data.status;
                // eslint-disable-next-line default-case
                switch (this.learningPackageStatus) {
                    case 'published':
                        this.learningPackageStatusText = 'veröffentlicht';
                        break;
                    case 'not published':
                        this.learningPackageStatusText = 'nicht veröffentlicht';
                        break;
                    case 'open changes':
                        this.learningPackageStatusText = 'offene Änderungen';
                        break;
                }
            }
        });
    }

    public ngOnDestroy(): void {
        this.selectedLearningPackageStatusSubscription.unsubscribe();
        this.userRoleService$.unsubscribe();
    }

    private assertRolesToEditorNavigation(): void {
        this.userRoleService$ = this.featuresService.hasFeatureAI().subscribe((hasFeatureAI: boolean) => {
            const allNavigations = this.navigationService.editorLearningPackageNavigation;
            this.editorLearningPackageNavigation = !hasFeatureAI ? allNavigations.filter(nav => nav.url !== 'ressourcen') : allNavigations;
        });
    }
}
