import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-popup-wizard-header',
  templateUrl: './popup-wizard-header.component.html',
  styleUrls: ['./popup-wizard-header.component.scss']
})
export class PopupWizardHeaderComponent {

    @Input()
    public header: string;

    @Input()
    public description: string;
}
