import {Component, OnInit} from '@angular/core';
import {Tag} from '../../core/api-types/Tag';
import {filter, map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {EditorLearningPackageService} from '../../core/editor/editor-learning-package.service';
import {EditorResource} from '../../core/rest/editor-resource.service';
import {PopupService} from '../../components/popup/popup.service';
import {NewTagPopupComponent} from './new-tag-popup/new-tag-popup.component';
import {PopupOptions} from '../../components/popup/PopupOptions';
import {NewTagPopupResult} from './new-tag-popup/NewTagPopupResult';
import {NewTagPopupData} from './new-tag-popup/NewTagPopupData';
import {ToastrService} from 'ngx-toastr';
import {EditTagPopupComponent} from './edit-tag-popup/edit-tag-popup.component';
import {EditTagPopupData} from './edit-tag-popup/EditTagPopupData';
import {EditTagPopupResult} from './edit-tag-popup/EditTagPopupResult';
import {ConfirmationPopupResult} from '../../components/confirmation-popup/ConfirmationPopupResult';
import {DeleteTagPopupComponent} from './delete-tag/delete-tag-popup.component';
import {DeleteTagPopupData} from './delete-tag/DeleteTagPopupData';

@Component({
    selector: 'app-learning-package-tags',
    templateUrl: './learning-package-tags.component.html',
    styleUrls: ['./learning-package-tags.component.scss']
})
export class LearningPackageTagsComponent implements OnInit {
    public tags$: Observable<Tag[]>;
    public selectedLearningPackageId: string;

    constructor(private editorLearningPackageService: EditorLearningPackageService,
                private editorRestService: EditorResource,
                private popupService: PopupService,
                private toastr: ToastrService) {
    }

    public ngOnInit(): void {
        this.tags$ = this.editorLearningPackageService.selectedLearningPackageId$.pipe(
            filter((selectedLearningPackageId: string) => selectedLearningPackageId !== undefined),
            map((selectedLearningPackageId: string) => this.selectedLearningPackageId = selectedLearningPackageId),
            switchMap(() => this.editorLearningPackageService.learningPackageAvailableTags$));
    }

    public async createNewTag(): Promise<void> {
        const popupOptions = new PopupOptions();
        const result = await this.popupService.open<NewTagPopupData, NewTagPopupResult>(NewTagPopupComponent, new NewTagPopupData(this.selectedLearningPackageId), popupOptions);
        if (result === 'tagCreated') {
            this.toastr.success('Tag gespeichert');
            this.editorLearningPackageService.reloadSelectedLearningPackage();
        }
    }

    public async editTag(tag: Tag): Promise<void> {
        const popupOptions = new PopupOptions();
        const result = await this.popupService.open<EditTagPopupData, EditTagPopupResult>(EditTagPopupComponent, new EditTagPopupData(this.selectedLearningPackageId, tag), popupOptions);
        if (result === 'tagUpdated') {
            this.toastr.success('Tag aktualisiert');
            this.editorLearningPackageService.reloadSelectedLearningPackage();
        }
    }

    public async deleteTag(tag: Tag): Promise<void> {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        this.editorRestService.canDeleteLearningPackageTag(this.selectedLearningPackageId, tag.id).subscribe(async (validationResult: { allowToDelete: boolean; }) => {
            const allowToDelete = validationResult.allowToDelete;
            const popupOptions = new PopupOptions(validationResult.allowToDelete ? 'small' : 'normal');
            const popupServiceResult = await this.popupService.open<DeleteTagPopupData, ConfirmationPopupResult>(DeleteTagPopupComponent, new DeleteTagPopupData(this.selectedLearningPackageId, tag, allowToDelete), popupOptions);
            if (popupServiceResult === 'Ok') {
                this.editorLearningPackageService.reloadSelectedLearningPackage();
            }
        });
    }
}
