<app-button class="mm-dropdown-icon-button"
            type="secondary"
            (click)="clickDropdownOptionButton($event)"
            [icon]="icon">
</app-button>

<div>
    <ng-template #overlayContent>
        <div class="micromate-dropdown-button-content" (click)="contentClicked($event)">
            <ng-content></ng-content>
        </div>
    </ng-template>
</div>
