import {Injectable} from '@angular/core';
import {ActivationStart, Router} from '@angular/router';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {BehaviorSubject, empty, Observable, of} from 'rxjs';
import {EditorResource} from '../rest/editor-resource.service';
import {LearningPackageQuizzesOverview} from '../api-types/learningPackageQuizzesOverview';
import {Tag} from '../api-types/Tag';

@Injectable({
    providedIn: 'root'
})
export class EditorLearningPackageService {
    public addedNewLearningPackage: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public addedNewLearningPackage$ = this.addedNewLearningPackage.asObservable();

    public selectedLearningPackageId = new BehaviorSubject<string>(undefined);
    public selectedLearningPackageId$ = this.selectedLearningPackageId.asObservable();

    constructor(public router: Router, private editorService: EditorResource) {
        this.router.events.subscribe(path => {
            if (path instanceof ActivationStart) {
                if (path.snapshot.params.learningPackageId !== undefined) {
                    const learningPackageId = path.snapshot.params.learningPackageId as string;
                    if (this.selectedLearningPackageId.value !== learningPackageId && learningPackageId !== 'undefined') {
                        this.selectedLearningPackageId.next(learningPackageId);
                    }
                } else {
                    if (path.snapshot.params.quizId === undefined && path.snapshot.url[0]?.path !== 'neue-frage') {
                        this.selectedLearningPackageId.next(undefined);
                    }
                }
            }
        });
    }

    public activeLearningPackage$: Observable<LearningPackageQuizzesOverview[]> = this.selectedLearningPackageId$.pipe(
        switchMap((selectedLearningPackageId: string) => {
            if (selectedLearningPackageId !== undefined) {
                return this.editorService.getOneLearningPackageQuizzesOverview(selectedLearningPackageId);
            }
            return of(undefined as LearningPackageQuizzesOverview[]);
        }),
        shareReplay(1)
    );

    public learningPackageAvailableTags$: Observable<Tag[]> = this.activeLearningPackage$.pipe(
        switchMap((learningPackageQuizzesOverview: LearningPackageQuizzesOverview[]) => {
            if (learningPackageQuizzesOverview !== undefined && learningPackageQuizzesOverview.length > 0) {
                const selectedLearningPackageId = learningPackageQuizzesOverview[0].learningPackageId;
                return this.editorService.getLearningPackageAvailableTags(selectedLearningPackageId);
            }
            return of(undefined as Tag[]);
        }),
        shareReplay(1)
    );

    public defaultLocaleSubject$: Observable<string> = this.activeLearningPackage$.pipe(
        map((learningPackageQuizzesOverview: LearningPackageQuizzesOverview[]) => {
            if (learningPackageQuizzesOverview !== undefined) {
                return learningPackageQuizzesOverview.find(learningPackage => learningPackage.mainLearningPackage).language;
            }
            return undefined;
        }),
        shareReplay(1)
    );

    public checkLearningPackageStatus = new BehaviorSubject<string>(undefined);

    public selectedLearningPackageStatus$ = this.selectedLearningPackageId$.pipe(
        switchMap((selectedLearningPackageId: string) => {
            if (selectedLearningPackageId !== undefined && selectedLearningPackageId.length > 0) {
                return this.editorService.getLearningPackageStatus(selectedLearningPackageId);
            }
            return empty();
        }),
        shareReplay(1)
    );

    public cleanActiveLearningPackage(): void {
        this.selectedLearningPackageId.next(undefined);
    }

    public getCurrentActiveLearningPackageId(): string {
        return this.selectedLearningPackageId.value;
    }

    public reloadSelectedLearningPackage(): void {
        const selectedLearningPackageId = this.selectedLearningPackageId.value;
        this.selectedLearningPackageId.next(selectedLearningPackageId);
    }
}
