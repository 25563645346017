import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiHistoryInfoService} from '../../../core/admin/api-history-info.service';
import {Subscription} from 'rxjs';
import {ApiHistoryRecord} from '../../../core/api-types/apiHistoryRecord';
import {ApiRequest} from '../../../core/api-types/apiRequest';
import {ApiResponse} from '../../../core/api-types/apiResponse';
import {environment} from '../../../../environments/environment';
import {Curl} from '../../../core/api-types/curl';

@Component({
    selector: 'app-api-info',
    templateUrl: './api-info.component.html',
    styleUrls: ['./api-info.component.scss']
})
export class ApiInfoComponent implements OnInit, OnDestroy {
    public apiRequest: ApiHistoryRecord<ApiRequest>;
    public apiResponse: ApiHistoryRecord<ApiResponse>;
    public selectedApi$ = new Subscription();
    public curlString: string;

    constructor(private apiHistoryInfoService: ApiHistoryInfoService) {
    }

    public ngOnInit(): void {
        this.selectedApi$ = this.apiHistoryInfoService.selectedApi$.subscribe((selectedApi: { request: ApiHistoryRecord<ApiRequest>; response: ApiHistoryRecord<ApiResponse>; }) => {
            if (selectedApi !== undefined) {
                this.apiRequest = selectedApi.request;
                this.apiResponse = selectedApi.response;
                this.createCurl();
            }
        });
    }

    private createCurl(): void {
        const curl: Curl = {
            curl: 'curl',
            locationFlag: '--location',
            requestFlag: '--request',
            requestMethod: `${this.apiRequest.payload.httpMethod}`,
            url: `'${environment.serverUrl}${this.apiRequest.payload.url}'`,
            headerFlag: '--header',
            headerData: '\'Authorization: {Bearer Token}\''
        };
        if (this.apiRequest.payload.httpMethod !== 'GET') {
            curl.contentTypeHeaderFlag = '--header';
            curl.contentTypeHeader = '\'Content-Type: application/json\'';
            curl.dataFlag = '--data';
            curl.dataData = `'${this.apiRequest.payload.body}'`;
        }
        this.curlString = Object.values(curl).join(' ');
    }

    public copyCurl(): void {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = this.curlString;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    public getCurlString(): string {
        const text = this.curlString;
        return text.replace('{Bearer Token}', '<strong>{Bearer Token}</strong>');
    }

    public ngOnDestroy(): void {
        this.selectedApi$.unsubscribe();
    }

}
