<div class="micromate-card micromate-card-editor-question">
    <div class="micromate-card-mobile-title"
         [class.micromate-card-mobile-title-no-language]="(editorQuizzesService.newQuizPage | async)">
        <div class="micromate-button micromate-editor-quiz-back-button">
            <div class="come-back-micromate-buttont-text" (click)="quizMakerService.openQuizzesOverview()">
                <svg aria-hidden="true" focusable="false" viewBox="0 0 384 512"
                     class="micromate-buttont-text-icon">
                    <use href="./assets/icons.svg#chevron-left-light"></use>
                </svg>
            </div>
        </div>
        <div class="micromate-editor-quiz-type">
            <h1 i18n>Frage</h1>
            <app-icon [icon]="quizTypeData?.icon"
                      class="micromate-editor-quiz-type-icon"
                      [title]="quizTypeData.title"
                      *ngIf="quizTypeData !== undefined"></app-icon>
        </div>

        <div class="micromate-editor-question-actions" *ngIf="activeTranslationQuiz !== undefined">
            <editor-drop-down class="editor-drop-down"
                              [disabled]="isDisabled"
                              [language]="activeTranslationQuiz.language"
                              [translationNotExist]="!translationNotExist((editorQuizService.activeQuiz$ | async))"
                              [showAnalytics]="false"></editor-drop-down>
        </div>
    </div>

    <div class="mm-editor-question-card">
        <div class="mm-editor-question-card-actions">
            <app-editor-language-switcher class="mm-editor-question-card-language-switcher"
                                          [disabled]="isDisabled"
                                          *ngIf="(editorQuizService.newQuiz$ | async) === undefined"
                                          [currentLanguage]="activeTranslationQuiz?.language"
                                          [mainLanguage]="activeTranslationQuiz?.mainLanguage"
                                          (activeLanguage)="changeLanguage($event)"></app-editor-language-switcher>
            <div class="mm-editor-question-card-actions-buttons">
                <div class="mm-editor-question-card-actions-buttons-content"
                     [class.micromate-editor-question-actions-new-question]="(editorQuizService.newQuiz$ | async) !== undefined"
                     *ngIf="activeTranslationQuiz !== undefined">
                    <app-button type="ai" [action]="translate"
                                *ngIf="(featuresService.hasFeatureAI() | async) && translationNotExist((editorQuizService.activeQuiz$ | async)) && !alreadyTranslated"
                                i18n>Übersetzen
                    </app-button>
                    <app-tags-selector
                        *ngIf="activeTranslationQuiz?.language === activeTranslationQuiz?.mainLanguage && !translationNotExist(editorQuizService.activeQuiz$ | async) && availableTags !== undefined"
                        [availableTags$]="editorLearningPackageService.learningPackageAvailableTags$"
                        [(selectedAvailableTags)]="selectedAvailableTags"
                        [(selectedNewTags)]="selectedNewTags"></app-tags-selector>
                </div>
            </div>
        </div>

        <div class="micromate-editor-tags-question">
            <app-tags-list
                *ngIf="availableTags !== undefined"
                [availableTags]="availableTags"
                [(selectedAvailableTags)]="selectedAvailableTags"
                [(selectedNewTags)]="selectedNewTags"
                [allowRemoveTag]="activeTranslationQuiz?.language === activeTranslationQuiz?.mainLanguage && !translationNotExist(editorQuizService.activeQuiz$ | async)"></app-tags-list>
        </div>

        <div *ngIf="quiz !== undefined" class="micromate-editor-quiz-creator">
            <form [formGroup]="formData" id="ngFormOrganization" class="micormate-form-quiz">
                <div class="micormate-form-quiz-element-question"
                     (dragenter)="questionHovering = true"
                     (dragleave)="questionHovering = false"
                     (drop)="questionHovering = false"
                     appDragAndDrop
                     (files)="filesDropped($event)">
                    <div class="micromate-editor-drag-area"
                         *ngIf="!editorQuizzesService.imageAndMediaPopupOpen.value && (dragAndDropService.dragOnPage$ | async)"
                         i18n
                         [class.micromate-editor-drag-area-active]="questionHovering">
                        Bild hier ablegen
                    </div>
                    <div class="micormate-form-quiz-element-question-input">
                        <div class="micormate-form-quiz-element micormate-form-quiz-element-question-textarea">
                        <textarea id="question" autosize type="text"
                                  formControlName="question"
                                  placeholder="Frage"
                                  class="micormate-form-quiz-maker-textarea"
                                  (keydown.enter)="keytab($event)"
                                  (keydown.shift.enter)="keytab($event)"
                                  [class.micormate-form-input-validation-error]="validator.validateInput(formData.controls.question)">
                        </textarea>
                        </div>

                        <app-micromate-editor-image-drop-down
                            class="micromate-editor-drop-down"
                            [disabled]="isDisabled"
                            [class.micromate-editor-drop-down-preview]="quizQuestionMedia?.imageUrl !== undefined || quizQuestionMedia?.imageFile !== undefined"
                            [media]="quizQuestionMedia"
                            [fileDrop]="questionFileDrop"
                            (moveMedia)="removeMedia()"
                            (openImageAndMediaPopup)="openImageAndMediaPopup()">
                        </app-micromate-editor-image-drop-down>
                    </div>

                    <div>
                        <div *ngIf="validator.validateInputRequired(formData.controls.question)"
                             class="micormate-form-validation-error" i18n>
                            Fragetext ist erforderlich
                        </div>
                        <div *ngIf="validator.validateInputLength(formData.controls.question)"
                             class="micormate-form-validation-error" i18n>
                            Maximal {{validator.QUESTION_MAX_LENGTH}} Zeichen
                        </div>
                    </div>
                </div>
            </form>

            <div class="micromate-editor-quiz-creator-buttons">
                <app-button type="secondary"
                            [disabled]="!(editorQuizzesService.formDataChanged | async)"
                            (click)="cancelQuiz()">Änderungen verwerfen
                </app-button>
                <div class="micromate-editor-quiz-creator-save-buttons">
                    <app-button type="primary"
                                [disabled]="!(editorQuizzesService.formDataChanged | async) || !validator.validateFullQuiz(formData)"
                                (keydown.enter)="saveQuiz(formData)"
                                (click)="saveQuiz(formData)">Speichern
                    </app-button>
                    <app-button type="primary"
                                [disabled]="!(editorQuizzesService.formDataChanged | async) || !validator.validateFullQuiz(formData)"
                                (keydown.enter)="saveAndCreateNewQuiz(formData)"
                                (click)="saveAndCreateNewQuiz(formData)">Speichern und Neu
                    </app-button>
                </div>
            </div>
        </div>
    </div>

    <app-feedback-question *ngIf="(editorQuizService.newQuiz$ | async) === undefined"></app-feedback-question>
</div>
