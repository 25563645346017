<app-button [type]="type" [disabled]="disabled">

    <div class="mm-button-icon-container">
        <svg aria-hidden="true" focusable="false" viewBox="0 0 384 512"
             class="mm-button-icon"
             [class.mm-button-icon-style-primary]="type === 'primary'"
             [class.mm-button-icon-style-secondary]="type === 'secondary'"
             [class.mm-button-icon-style-secondary-dark]="type === 'secondary-dark'"
             [class.mm-button-icon-style-disabled]="disabled">
            <use [attr.href]="'./assets/icons.svg#' + icon"></use>
        </svg>

    </div>

</app-button>
