import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import * as moment from 'moment';
import {ReportsAdminRestService} from '../../core/rest/analytics-rest/reports-admin-rest.service';
import {ToastrService} from 'ngx-toastr';
import {HttpErrorResponse} from '@angular/common/http';
import {ReportForm} from './ReportForm';
import {ReportData} from './ReportData';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements OnInit {
    public allowGenerateReport = true;

    public formData = new FormGroup<ReportForm>({
        organizationId: new FormControl<string>('', [Validators.required]),
        learningPackageId: new FormControl<string>(''),
        learnerId: new FormControl<string>(''),
        periodFrom: new FormControl<string>('2020-01-01 00:00:00', [Validators.required, this.validateDate]),
        periodTo: new FormControl<string>('', [Validators.required, this.validateDate])
    });

    constructor(private reportsAdminRestService: ReportsAdminRestService,
                private toastr: ToastrService) {
    }

    public ngOnInit(): void {
        const currentDate = moment().format('YYYY-MM-DD HH:mm:ss');
        this.formData.controls.periodTo.setValue(currentDate);
    }

    public validateDate(control: AbstractControl): ValidationErrors | null {
        const regex = new RegExp(/^\d{4}-\d{2}-\d{2} \d{1,2}:\d{2}:\d{2}$/);
        // eslint-disable-next-line no-null/no-null
        return control.value !== null && !regex.test((control.value as string).trim()) ? {pattern: true} : null;
    }

    public shouldShowOrganizationLearningTimeReportGenerator(formData: ReportData): boolean {
        // eslint-disable-next-line no-null/no-null
        return this.formData.valid && formData.organizationId.length > 0 && (formData.learnerId === null || formData.learnerId.length === 0) && (formData.learningPackageId === null || formData.learningPackageId.length === 0);
    }

    public checkIfFormInvalid(): boolean {
        const learnerId = this.formData.getRawValue().learnerId;
        const learningPackageId = this.formData.getRawValue().learningPackageId;
        // eslint-disable-next-line no-null/no-null
        return this.formData.invalid || !this.allowGenerateReport || ((learnerId !== null && learnerId.length > 0) && (learningPackageId === null || learningPackageId.length === 0));
    }

    public generateReport(formData: ReportData): void {
        if (!this.checkIfFormInvalid()) {
            this.allowGenerateReport = false;
            this.getReportData(formData).then((data) => {
                ReportsComponent.downloadFile(data.csvData, ReportsComponent.getFileName(formData), 'csv');
                setTimeout(() => {
                    this.allowGenerateReport = true;
                }, 3000);
            }).catch((error: HttpErrorResponse) => {
                for (const errorMessage of (error.error as string[])) {
                    this.toastr.error(errorMessage);
                }
                this.allowGenerateReport = true;
            });
        }
    }

    public generateTimeReport(formData: ReportData): void {
        if (!this.checkIfFormInvalid()) {
            this.allowGenerateReport = false;
            this.reportsAdminRestService.getOrganizationTimeReport(formData.organizationId, formData.periodFrom, formData.periodTo).then((data) => {
                ReportsComponent.downloadFile(data.csvData, `Organization-${formData.organizationId}-Time-Report`, 'csv');
                setTimeout(() => {
                    this.allowGenerateReport = true;
                }, 3000);
            }).catch((error: HttpErrorResponse) => {
                for (const errorMessage of (error.error as string[])) {
                    this.toastr.error(errorMessage);
                }
                this.allowGenerateReport = true;
            });
        }
    }

    private getReportData(formData: ReportData): Promise<{ csvData: string; }> {
        if (formData.learnerId.length > 0 && formData.learningPackageId.length > 0) {
            return this.reportsAdminRestService.getLearnerReport(formData.learnerId, formData.organizationId, formData.learningPackageId, formData.periodFrom, formData.periodTo);
        } else if (formData.learningPackageId.length > 0) {
            return this.reportsAdminRestService.getOrganizationLearningPackageReport(formData.organizationId, formData.learningPackageId, formData.periodFrom, formData.periodTo);
        }
        return this.reportsAdminRestService.getOrganizationLearnersReport(formData.organizationId, formData.periodFrom, formData.periodTo);
    }

    private static getFileName(formData: ReportData): string {
        if (formData.learnerId.length > 0 && formData.learningPackageId.length > 0) {
            return `Organization-${formData.organizationId}-LearningPackage-${formData.learningPackageId}-Learner-${formData.learnerId}-Report`;
        } else if (formData.learningPackageId.length > 0) {
            return `Organization-${formData.organizationId}-LearningPackage-${formData.learningPackageId}-Report`;
        }
        return `Organization-${formData.organizationId}-Learners-Report`;
    }

    private static downloadFile(data: string, name: string, fileType: 'csv' | 'json'): void {
        const blob = new Blob([`\ufeff${data}`], {type: 'text/csv;charset=utf-8;'});
        const dwldLink = document.createElement('a');
        const url = URL.createObjectURL(blob);
        const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
        if (isSafariBrowser) {
            dwldLink.setAttribute('target', '_blank');
        }
        dwldLink.setAttribute('href', url);
        dwldLink.setAttribute('download', `${name}.${fileType}`);
        dwldLink.style.visibility = 'hidden';
        document.body.appendChild(dwldLink);
        dwldLink.click();
        document.body.removeChild(dwldLink);
    }
}
