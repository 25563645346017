import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-text-overflow-ellipsis',
    templateUrl: './text-overflow-ellipsis.component.html',
    styleUrls: ['./text-overflow-ellipsis.component.scss']
})
export class TextOverflowEllipsisComponent {
    @Input()
    public text: string;
}
