import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {GroupSettingComponent} from '../app-learncenter/group-setting/group-setting.component';
import {GroupSettingsService} from '../app-learncenter/group-setting/group-settings.service';

@Injectable()
export class GroupSettingsGuard implements CanDeactivate<GroupSettingComponent> {
    public component: Object;
    public route: ActivatedRouteSnapshot;

    constructor(private groupSettingService: GroupSettingsService) {
    }

    public async canDeactivate(component: GroupSettingComponent,
                               route: ActivatedRouteSnapshot,
                               state: RouterStateSnapshot,
                               nextState: RouterStateSnapshot): Promise<boolean> {
        return await this.groupSettingService.checkIfGroupSettingIsSaved(nextState.url);
    }

}
