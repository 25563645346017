<h3 class="micromate-question-preview-answers-title" i18n>Antworten</h3>
<div *ngFor="let answer of questionPreview.answers" class="micromate-answer micromate-single-question-answers"
     [class.micromate-answer-correct]="answer.isCorrectAnswer"
     [class.micromate-answer-wrong]="!answer.isCorrectAnswer">
    <div class="micromate-single-question-answer" [innerHTML]="answer.answerText | markdown"></div>
    <div *ngIf="answer.explanation !== undefined && answer.explanation.length > 0"
         class="micromate-single-question-answer-explanation">
        <div class="micromate-single-question-answer-explanation-icon">
            <svg aria-hidden="true" focusable="false" viewBox="0 0 640 512"
                 class="micromate-single-question-answer-explanation-icon-svg">
                <use href="./assets/icons.svg#lightbulb-exclamation-light"></use>
            </svg>
        </div>
        <div class="micromate-single-question-answer-explanation-text"
             [innerHTML]="answer.explanation | markdown"></div>
    </div>
</div>
<app-explanation-question-preview
    *ngIf="questionPreview.explanation !== undefined && questionPreview.explanation.mediaType !== undefined"
    [explanation]="questionPreview.explanation"
    showExplanationText="false"></app-explanation-question-preview>
<app-source-question-preview *ngIf="questionPreview.sourceUrl !== undefined"
                             [sourceUrl]="questionPreview.sourceUrl"
                             [sourceLabel]="questionPreview.sourceLabel"></app-source-question-preview>
