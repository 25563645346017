import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdminService {
    public addedNewOrganization: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public addedNewOrganization$ = this.addedNewOrganization.asObservable();
}
