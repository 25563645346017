import {Component, Input} from '@angular/core';
import {MediaType} from '../../../../core/editor/types/QuizQuestion';

@Component({
    selector: 'app-media-type-icon',
    templateUrl: './media-type-icon.component.html',
    styleUrls: ['./media-type-icon.component.scss']
})
export class MediaTypeIconComponent {
    @Input()
    public mediaType: MediaType;
}
