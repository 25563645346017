import {Injectable} from '@angular/core';
import {BreadCrumbsResource} from '../../core/rest/bread-crumbs-resource.service';
import {OrganizationService} from '../../core/organization.service';

@Injectable({
    providedIn: 'root'
})
export class BreadcrumbService {

    constructor(private breadCrumbsRestService: BreadCrumbsResource, private organizationService: OrganizationService) {
    }

    public async getDynamicLabel(key: string, url: string[]): Promise<string> {
        const urlElement = url[url.length - 1];
        let activeOrganizationId: string;
        switch (key) {
            case 'learningGroupDetails':
                return (await this.breadCrumbsRestService.getGroupName(urlElement)).name;
            case 'learningPackageDetails':
                return (await this.breadCrumbsRestService.getLearningPackageName(urlElement)).name;
            case 'adminOrganizationDetails':
                return `Einstellungen ${(await this.breadCrumbsRestService.getOrganizationName(urlElement)).name}`;
            case 'groupLearningPackageDetails':
                return `Einstellungen ${(await this.breadCrumbsRestService.getLearningPackageName(urlElement)).name}`;
            case 'adminLearnerDetails':
                return (await this.breadCrumbsRestService.getLearnerName(urlElement)).name;
            case 'organizationAdminLearnerDetails':
                return (await this.breadCrumbsRestService.getOrganizationLearnerName(urlElement)).name;
            case 'organizationDetails':
                // TODO CLEANUP TASK: We shoulduse the observable here
                activeOrganizationId = this.organizationService.getActiveOrganizationId();
                if (activeOrganizationId !== undefined) {
                    return `Organisation (${(await this.breadCrumbsRestService.getOrganizationTeamsName(activeOrganizationId)).name})`;
                }
                return 'Organisation';
            default:
                throw new Error('Missing breadcrumb dynamic label resolver in BreadcrumbService');
        }
    }
}
