import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class ReportsAdminRestService {

    constructor(private http: HttpClient) {
    }

    public getOrganizationLearnersReport(organizationId: string, periodFrom: string, periodTo: string): Promise<{ csvData: string; }> {
        return this.http.get<{ csvData: string; }>(`${environment.serverUrl}/api/website/admin/reports/organization/${organizationId}/learners?periodFrom=${periodFrom}&periodTo=${periodTo}`).toPromise();
    }

    public getOrganizationLearningPackageReport(organizationId: string, learningPackageId: string, periodFrom: string, periodTo: string): Promise<{ csvData: string; }> {
        return this.http.get<{ csvData: string; }>(`${environment.serverUrl}/api/website/admin/reports/organization/${organizationId}/learning-package/${learningPackageId}?periodFrom=${periodFrom}&periodTo=${periodTo}`).toPromise();
    }

    public getLearnerReport(learnerId: string, organizationId: string, learningPackageId: string, periodFrom: string, periodTo: string): Promise<{ csvData: string; }> {
        return this.http.get<{ csvData: string; }>(`${environment.serverUrl}/api/website/admin/reports/learner/${learnerId}/organization/${organizationId}/learning-package/${learningPackageId}?periodFrom=${periodFrom}&periodTo=${periodTo}`).toPromise();
    }

    public getOrganizationTimeReport(organizationId: string, periodFrom: string, periodTo: string): Promise<{ csvData: string; }> {
        return this.http.get<{ csvData: string; }>(`${environment.serverUrl}/api/website/admin/reports/organization/${organizationId}/time?periodFrom=${periodFrom}&periodTo=${periodTo}`).toPromise();
    }
}
