import {Component, Input} from '@angular/core';
import {NewResourcePopupData} from './NewResourcePopupData';
import {Popup} from '../../../components/popup/Popup';
import {NewResourcePopupService} from './new-resource-popup.service';

@Component({
    selector: 'app-new-resource-popup',
    templateUrl: './new-resource-popup.component.html',
    styleUrls: ['./new-resource-popup.component.scss']
})
export class NewResourcePopupComponent implements Popup<NewResourcePopupData> {
    @Input() public data: NewResourcePopupData;

    constructor(public resourceWizardService: NewResourcePopupService) {
    }
}
